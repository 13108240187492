import React from 'react';
//Components
import {DataSheet} from "components";

const InsuredLives = (props) => {
  const { 
  	parentRef, 
  	notifyChanges, 
  	configurations,
  	handleChange,
  } = props;
  return (
    <DataSheet
      ref={parentRef}
      notifyChanges={notifyChanges}
      data={configurations.insuredLives}
      handleChange={handleChange}/>
  );
};

export default InsuredLives;