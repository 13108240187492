//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "coveragesLabel",
    "type": "label"
  },
  {
    "key": "coveragesPlaceholder",
    "type": "label"
  },
  {
    "key": "assistancesLabel",
    "type": "label"
  },
  {
    "key": "assistancesPlaceholder",
    "type": "label"
  }
];
export default prepareTranslations(keys, translation);