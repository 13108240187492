import React from "react";
//Custom Styles
import './Styles.less'
//Custom Components
import {Spin, Empty} from 'antd'
import UserDetails from "../UserDetails/index";
//Translation config
import Translation from './translation';

const UserList = (props) => {
    const {
        loading, users, selectedUsers, selectUser, selectUsers, updateUser, allProfiles, loadingAllProfiles
    } = props;
    return (
        <Spin spinning={loading} tip={Translation.spinLabel} className="usersList-spinWrapper">
            <div className="gx-contact-main-content">
                {users.length === 0 && !loading ?
                    <div className="usersList-emptyWrapper">
                        <Empty imageStyle={{height: 120}}
                               description={Translation.emptyLabel}/>
                    </div>
                    :
                    users.map((user, index) =>
                        <UserDetails key={index}
                                     user={user}
                                     selectUser={selectUser}
                                     selectUsers={selectUsers}
                                     selectedUsers={selectedUsers}
                                     updateUser={updateUser}
                                     users={users}
                                     allProfiles={allProfiles}
                                     loadingAllProfiles={loadingAllProfiles}/>)}
            </div>
        </Spin>
    )
};
export default UserList;

