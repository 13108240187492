// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../../util/axios";
// translation
import i18n from "../../../../translation/i18n";
// antd
import { message } from "antd";
// common
import { setIsLoading } from "../../common";

export const manageUsersAccessSlice = createSlice({
  name: "manageUsersAccess",
  initialState: {
    isSubmitingAccessAprove: false,
    isLoadingBlockUnblock: false,
    usersList: {},
    userData: [],
    filtersData: {},
  },
  reducers: {
    setIsSubmitingAccessAprove: (state, action) => {
      state.isSubmitingAccessAprove = action.payload;
    },
    setIsLoadingBlockUnblock: (state, action) => {
      state.isLoadingBlockUnblock = action.payload;
    },
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    },
    setUserData: (state, action) => {
      state.userData = action.payload;
    },
    setFiltersData: (state, action) => {
      state.filtersData = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const {
  setUsersList,
  setUserData,
  setIsSubmitingAccessAprove,
  setFiltersData,
  setIsLoadingBlockUnblock,
} = manageUsersAccessSlice.actions;

export default manageUsersAccessSlice.reducer;

// -----------------------------------------------------------------

export const fetchClientAppUsers = (params) => (dispatch) => {
  dispatch(setIsLoading(true));

  const queryString = params ? new URLSearchParams(params).toString() : "";

  return new Promise((resolve, reject) => {
    axios
      .get(`auth/public/v1/user/?sort=status,DESC&${queryString}`)
      .then((response) => {
        if (response?.data) {
          dispatch(setUsersList({ ...response.data }));
        }
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const fetchClientAppUserData = (params) => (dispatch) => {
  dispatch(setIsLoading(true));

  const queryString = params ? new URLSearchParams(params).toString() : "";

  return new Promise((resolve, reject) => {
    axios
      .get(`auth/public/v1/user/?${queryString}`)
      .then((response) => {
        if (response?.data) {
          dispatch(setUserData([...response.data.content]));
        }
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const submitAccessAprove = (params) => (dispatch) => {
  dispatch(setIsSubmitingAccessAprove(true));

  const queryString = params ? new URLSearchParams(params).toString() : "";

  return new Promise((resolve, reject) => {
    axios
      .put(`auth/public/v1/user/?${queryString}`)
      .then((response) => {
        message.success("Acesso aprovado com sucesso!");
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsSubmitingAccessAprove(false)));
  });
};

export const blockClientAppUser = (params) => (dispatch) => {
  dispatch(setIsLoadingBlockUnblock(true));

  const queryString = params ? new URLSearchParams(params).toString() : "";

  return new Promise((resolve, reject) => {
    axios
      .put(`auth/public/v1/user/lock?${queryString}`)
      .then((response) => {
        message.success("Usuário bloqueado com sucesso.");
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoadingBlockUnblock(false)));
  });
};

export const unBlockClientAppUser = (params) => (dispatch) => {
  dispatch(setIsLoadingBlockUnblock(true));

  const queryString = params ? new URLSearchParams(params).toString() : "";

  return new Promise((resolve, reject) => {
    axios
      .put(`auth/public/v1/user/unblock/?${queryString}`)
      .then((response) => {
        message.success("Usuário desbloqueado com sucesso.");
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error?.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoadingBlockUnblock(false)));
  });
};
