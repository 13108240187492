import React, { useState } from 'react';
import {
  Row,
  Col,
  Upload,
  Icon
} from "antd";
import { uploadConfig } from "helpers";

const { Dragger } = Upload;

const UploadTab = (props) => {
  const { configurations, type, code, fileExtensions } = props;

  const files = configurations.files;

  const [listOfFiles, setListOfFiles] = useState({
     fileList: files ? files.map(item => ({
          ...item,
          uid: Math.random(),
        })) : null,
   });

  const handleFileChange = ({file, fileList}) => {
    const { status } = file;

    if (status === 'done') {
      fileList = fileList.map((item, index) => {
        if(item.response){
          return {
            ...item.response,
            uid: Math.random()
          }
        }
        return item;
      });

    }

    setListOfFiles({fileList: [...fileList]});
  };

  const handleProgress = (event, file) => {
    let fileList = listOfFiles.fileList;
    let added;
    fileList = fileList ? fileList.map(item => {
      if(item.name === file.name){
        added = true;
        item.percent = event.percent;
      }
      return item;
    }) : [];
    if(!added) {
      file.status = 'uploading';
      fileList.push(file);
    }
    setListOfFiles({fileList:fileList});
  };

  const uploadApi = {
    LIFE: `${window._env_.API_GATEWAY_URL}/life/v1/${code}/upload-data`,
    SURETY: `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/upload-data`,
    CARGO_SINGLE_BOARDING: `${window._env_.API_GATEWAY_URL}/cargo/v1/single-boarding/${code}/upload-data`,
  }

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={24}>
          <Dragger
            {...uploadConfig(handleFileChange,handleProgress)}
            action={uploadApi[type]}
            fileList={listOfFiles.fileList}
          >
            <p className="ant-upload-drag-icon">
              <Icon type="upload" />
            </p>
            <p className="ant-upload-text">Clique ou arraste os documentos para esta área</p>
            <p className="ant-upload-hint">{fileExtensions}</p>
          </Dragger>
          {/*<LngInput
            disabled={false}
            placeholder={Translation.contactNamePlaceholder}
            onChange={onChange("name")}
            value={getValue("name")}/>*/}
        </Col>
      </Row>
    </section>
  );
};

export default UploadTab;