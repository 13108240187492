//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("groups");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}


export default {
    groupLabel: getTranslation("groupLabel"),
    groupPlaceholder: getTranslation("groupPlaceholder"),
    partnerOptionLabel: getTranslation("partnerOptionLabel"),
    employeeOptionLabel: getTranslation("employeeOptionLabel"),
    internsOptionLabel: getTranslation("internsOptionLabel"),
    serviceProvidersOptionLabel: getTranslation("serviceProvidersOptionLabel"),
    associatesOptionLabel: getTranslation("associatesOptionLabel"),
    membersOptionLabel: getTranslation("membersOptionLabel"),
    studentsOptionLabel: getTranslation("studentsOptionLabel"),
    othersOptionLabel: getTranslation("othersOptionLabel"),
    remove: getTranslation("remove", true),
    add: getTranslation("add", true),
    amountPlaceholder: getTranslation("amountPlaceholder", false, true),
    groupRemove: getTranslation("groupRemove"),
    groupAdd: getTranslation("groupAdd"),
    noGroup: getTranslation("noGroup"),
    groupEmptyLabel: getTranslation("groupEmptyLabel")
}

