//Helper functions`
import { setInitialTranslation } from "helpers";
import { prepareTranslations } from "../../../../../../helpers";
//Constants
const translation = setInitialTranslation("taxes");

const keys = [
    {
        "key": "errorMessage",
        "type": "label"
    }, {
        "key": "processedAtLabel",
        "type": "label"
    },
    {
        "key": "reservedAtLabel",
        "type": "label"
    },
    {
        "key": "reprocessLabel",
        "type": "label"
    },
    {
        "key": "expiryOnLabel",
        "type": "label"
    },
    {
        "key": "modalTitle",
        "type": "label"
    },
    {
        "key": "modalContent",
        "type": "label"
    },
    {
        "key": "bondTabLabel",
        "type": "label"
    },
    {
        "key": "bondTypeLabel",
        "type": "label"
    },
    {
        "key": "taxRateLabel",
        "type": "label"
    },
    {
        "key": "availableLimitLabel",
        "type": "label"
    },
    {
        "key": "totalLimitLabel",
        "type": "label"
    },
    {
        "key": "premiumLabel",
        "type": "label"
    },
    {
        "key": "taxRateLabel",
        "type": "warningMessageLabel"
    }
];

export default prepareTranslations(keys, translation);