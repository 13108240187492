import settings from 'config';

export * from './translation';
export * from './date';
export * from './randoms';
export * from './notifications';
export * from './response';
export * from './formatters';
export * from './custom';
export * from './stringFormatters';
export * from './upload';
export * from './apiHelper';
export * from './errors';

export const stopPropagation = event => {
    event.stopPropagation()
};

export const getUrl = (url) => {
    return settings.apiUrl + url;
};

export function capitalize(word) {
    return word.charAt(0).toUpperCase() + word.substring(1);
}


export function isEmptyObject(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}

export function filterById(data, ids) {
    return data.filter(rec => ids.includes(rec.id));
}


export function equalArrays(array1, array2) {
    if (array1.length !== array2.length)
        return false;
    for (let index = 0; index < array1.length; index++)
        if (array1[index] !== array2[index])
            return false;
    return true;
}


export function cloneObject(src) {
    return JSON.parse(JSON.stringify(src));
}

export function cloneArray(src, type) {
    if (type === "object")
        return src.map(entry => Object.assign({}, entry));
    return src.slice(0);
}

