import React from "react";
// antd
import { Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// custom components
import { InsurerLogo, LoadQuotations } from "components";
// translation
import translation from "./translation";
// helpers
import { formatMoney } from "helpers";
// constants
import { quotationStatus } from "../../constants";

const RadioGroupItem = ({ item, bestChoice }) => {
  const { insurer, status } = item;

  const rate = item.rate || null,
    premium = item.premium || null;

  return (
    <Radio
      className="radio-group-item"
      value={insurer}
      premium={premium}
      disabled={status !== "RESERVED"}
    >
      {status !== "PROCESSING" ? (
        <div className="logoAndInsurerContainer">
          <>
            <div className="InsurerLogo">
              <InsurerLogo insurer={insurer} />
            </div>
            <div
              className={`issueType ${
                item.emissionType === "EXPRESS" && "online"
              }`}
            >
              {item.emissionType === "EXPRESS" ? (
                <>
                  <FontAwesomeIcon icon={["fas", "bolt"]} /> Express
                </>
              ) : (
                "Online"
              )}
            </div>
          </>
        </div>
      ) : (
        <LoadQuotations active={true} />
      )}
      {status !== "PROCESSING" ? (
        <TableData status={status} premium={premium} rate={rate} />
      ) : null}
      {bestChoice ? (
        <div className="bestChoice">
          <FontAwesomeIcon icon={["fas", "star"]} />
        </div>
      ) : null}
    </Radio>
  );
};
const TableData = (props) => {
  const { status, premium, rate } = props;
  if (status === "RESERVED" && rate) {
    return (
      <div className="tableData">
        <div className="text">{rate}%</div>
        <div className="content">{formatMoney(premium)}</div>
      </div>
    );
  } else {
    return (
      <>
        <div className="content-text">{getStatusMsg(status)}</div>
      </>
    );
  }
};
const getStatusMsg = (status) => {
  switch (status) {
    case quotationStatus.DOCUMENTATION_NEEDED:
      return translation.DOCUMENTATION_NEEDED;
    case quotationStatus.DESIGNATION_LETTER_NEEDED:
      return translation.DESIGNATION_LETTER_NEEDED;
    case quotationStatus.PENDING_ANALYSIS:
      return translation.PENDING_ANALYSIS;
    case quotationStatus.REFUSED:
      return translation.REFUSED;
    case quotationStatus.CCG_PENDING:
      return translation.CCG_PENDING;
    case quotationStatus.INSURER_REGISTER_NEEDED:
      return translation.INSURER_REGISTER_NEEDED;
    case quotationStatus.BLOCKED:
      return translation.BLOCKED;
    default:
      return translation.defaultStatus;
  }
};

export default RadioGroupItem;
