import moment from "moment";

export function getDaysDifference(startDate, endDate) {
  if (startDate && endDate)
    return endDate.diff(startDate, 'days');
  return null;
}

export function getCurrentTime() {
  return new Date();
}

export function getFormattedDate(date, format = "DD/MM/YYYY") {
  if (date)
    return moment(date).format(format);
  return null;
}

export function formatDate(date, type = "front") {
  if (date) {
    const formattedDate = date.split(/-/g);
    if (type === "front") {
      return moment(date).format("DD/MM/YYYY")
    } else if (type === "back") {
      return moment(date).format("YYYY/MM/DD")
    }
  }
  return null;
}

export function formatDateTime(date, type = "front") {
  if (date) {
    if (type === "front") {
      return moment(date).format("DD/MM/YYYY HH:mm:ss")
    } else if (type === "back") {
      return moment(date).format("YYYY-mm-ddTHH:mm:ss")
    }
  }
  return null;
}

export const frontEndDate = "DD/MM/YYYY";
export const DateAndTime = "DD/MM/YYYY HH:mm:ss";
export const dateFormat = 'YYYY-MM-DD';

export const monthFormat = "YYYY-MM";
export const frontEndMonth = "MM/YYYY";

export const convertToIsoFormat = (date) => {
  return date ? date.split("/").reverse().join("-") : null
}
