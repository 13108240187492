//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("insurableGroup");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    insurableGroupLabel: getTranslation("insurableGroupLabel"),
    add: getTranslation("add", true),
    remove: getTranslation("remove", true),
    insurableGroupRemove: getTranslation("insurableGroupRemove"),
    insurableGroupAdd: getTranslation("insurableGroupAdd"),
    insurableGroupEmptyLabel: getTranslation("insurableGroupEmptyLabel"),
    groupLabel: getTranslation("groupLabel"),
    groupPlaceholder: getTranslation("groupPlaceholder", false, true),
    valueLabel: getTranslation("valueLabel"),
    valuePlaceholder: getTranslation("valuePlaceholder", false, true),
    partnersGroup: getTranslation("partnersGroup", false, true),
    internsGroup: getTranslation("internsGroup", false, true),
    serviceProvidersGroup: getTranslation("serviceProvidersGroup", false, true),
    associatesGroup: getTranslation("associatesGroup", false, true),
    employeesGroup: getTranslation("employeesGroup", false, true),
    membersGroup: getTranslation("membersGroup", false, true),
    studentsGroup: getTranslation("studentsGroup", false, true),
    othersGroup: getTranslation("othersGroup", false, true),
}