import {
    HIDE_MESSAGE,
    INIT_URL,
    SHOW_MESSAGE,
    SIGNIN_GOOGLE_USER_SUCCESS,
    SIGNIN_USER_FAILED,
    SIGN_IN_SHOW_LOADER,
    SIGNIN_USER_SUCCESS,
    SIGNOUT_USER_SUCCESS
} from "../../actionTypes";

import {getStoredUser, removeStoredUser, storeUser} from "../../../helpers/localStorage";

const INIT_STATE = {
    loader: false,
    alertMessage: '',
    showMessage: false,
    initURL: '',
    authUser: getStoredUser()
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SIGNIN_USER_SUCCESS: {
            const {payload} = action;
            storeUser(payload);
            return {
                ...state,
                loader: false,
                authUser: payload
            }
        }
        case INIT_URL: {
            return {
                ...state,
                initURL: action.payload
            }
        }
        case SIGNOUT_USER_SUCCESS: {
            removeStoredUser();
            return {
                ...state,
                authUser: null,
                initURL: '/',
                loader: false
            }
        }

        case SHOW_MESSAGE: {
            return {
                ...state,
                alertMessage: action.payload,
                showMessage: true,
                loader: false
            }
        }
        case HIDE_MESSAGE: {
            return {
                ...state,
                alertMessage: '',
                showMessage: false,
                loader: false
            }
        }

        case SIGNIN_GOOGLE_USER_SUCCESS: {
            return {
                ...state,
                loader: false,
                authUser: action.payload
            }
        }
        case SIGN_IN_SHOW_LOADER: {
            return {
                ...state,
                loader: true
            }
        }
        case SIGNIN_USER_FAILED: {
            return {
                ...state,
                loader: false
            }
        }
        default:
            return state;
    }
}
