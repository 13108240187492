import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHANGE_MINUTE_DATA,
  REGISTER_EXPRESS_ERRORS,
} from "../../../../../../appRedux/actionTypes";
import axios from "axios";
// antd
import { Row, Col, Upload, Button, Spin, Select, message } from "antd";
import {
  PrinterOutlined,
  InboxOutlined,
  SendOutlined,
} from "@ant-design/icons";
// helpers
import {
  uploadConfig,
  displayError,
  formatDateTime,
  getAcceptedInsurer,
} from "helpers";
// moment
import moment from "moment";
// components
import { QuotationData } from "./QuotationData";
//Translations
import Translation from "./translation";
import { useTranslation } from "react-i18next";
//style
import "./style.less";
//constants
import selections from "constants/selections";
const { Dragger } = Upload;
const { Option } = Select;
const config = {
  insurers: Object.entries(selections.taxesInsurer),
};

const Minute = ({
  code,
  type,
  quotations,
  configurations,
  hasExpressEmission,
  superiorSelectedTab,
}) => {
  const dispatch = useDispatch();
  const minute = configurations.minute || {};
  let insurerData;

  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [minuteInsurer, setMinuteInsurer] = useState(minute.insurer);
  const [isSending, setIsSending] = useState(false);

  const { t: translation } = useTranslation();

  const expressErrorMessages = useSelector(
    (store) => store.crm.order.expressErrors
  );

  const registerChosenInsurer = () => {
    let chosen = getAcceptedInsurer(quotations);
    chosen &&
      dispatch({
        type: CHANGE_MINUTE_DATA,
        payload: { ...minute, insurer: chosen },
      });
  };

  const handleFileChange = ({ file, fileList }) => {
    const { status } = file;

    if (status === "done") {
      fileList = fileList.map((item, index) => {
        if (item.response) {
          return {
            ...item.response,
            uid: Math.random(),
          };
        }
        return item;
      });
    }

    return { fileList: [...fileList] };
  };

  const getMinuteFile = (reprocess = false) => {
    if (hasExpressEmission) {
      const url = `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/minute-data?reprocess=${reprocess}`;
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      setLoading(true);
      dispatch({
        type: REGISTER_EXPRESS_ERRORS,
        expressErrors: [],
      });
      dispatch({
        type: CHANGE_MINUTE_DATA,
        payload: {},
      });
      axios
        .get(url, config)
        .then((response) => {
          setErrorMessages([]);
          if (!response.data.messages) {
            dispatch({
              type: CHANGE_MINUTE_DATA,
              payload: response.data,
            });
          } else {
            dispatch({
              type: REGISTER_EXPRESS_ERRORS,
              expressErrors: response.data.messages,
            });
            dispatch({
              type: CHANGE_MINUTE_DATA,
              payload: response.data,
            });
            response.data.messages.map((msg) => {
              if (msg !== "Not Found") {
                return displayError(msg);
              }
              return null;
            });
          }
          setLoading(false);
        })
        .catch((error) => {
          dispatch({
            type: REGISTER_EXPRESS_ERRORS,
            expressErrors: [],
          });
          if (error.response) {
            displayError(error.response.data.message);
            if (
              errorMessages.every((message) => {
                return error.response.data.message !== message;
              })
            ) {
              setErrorMessages([error.response.data.message, ...errorMessages]);
            }
          }
          setLoading(false);
        });
    }
  };

  const sendMinuteEmail = () => {
    setIsSending(true);
    const minuteEmailUrl = `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/send-document/customer-minute`;
    axios
      .post(minuteEmailUrl, {})
      .then(() => {
        message.success(translation("order.surety.minute.emailSentSuccess"));
      })
      .catch((error) => {
        if (error.response) {
          message.success(error.response.data.message);
        }
      })
      .finally(() => {
        setIsSending(false);
      });
  };

  const evidencesFileChange = ({ file, fileList }) => {
    const list = handleFileChange({ file, fileList });
    const item = list.fileList[0];

    dispatch({
      type: CHANGE_MINUTE_DATA,
      payload: {
        ...minute,
      },
    });
  };

  const handleEvidenceSuccess = (data) => {
    dispatch({
      type: CHANGE_MINUTE_DATA,
      payload: {
        ...minute,
        proposalAccepted: true,
        evidence: {
          ...data,
          acceptedAt: data.uploadAt,
        },
      },
    });
  };

  const handleMinuteSuccess = (data) => {
    dispatch({
      type: CHANGE_MINUTE_DATA,
      payload: {
        ...minute,
        ...data,
        proposalAccepted: false,
      },
    });
  };

  const minutesFileChange = ({ file, fileList }) => {
    const list = handleFileChange({ file, fileList });
    const item = list.fileList[0];
    dispatch({
      type: REGISTER_EXPRESS_ERRORS,
      expressErrors: [],
    });
    dispatch({
      type: CHANGE_MINUTE_DATA,
      payload: {
        ...minute,
        insurer: item.insurer,
        origin: item.origin,
        url: item.url,
        uploadAt: item.uploadAt,
      },
    });
  };
  const uploadApi = {
    SURETY: {
      evidences: `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/evidence-data`,
      minute: `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/minute-data`,
    },
  };

  quotations.map((quotation) => {
    if (quotation.insurer === minute.insurer) {
      return (insurerData = quotation);
    }

    return null;
  });

  const UploadDocument = ({
    url,
    buttonText,
    uploadLabel,
    documentType,
    uploadPlaceholder,
  }) => {
    const evidenceDraggerProps = {
      ...uploadConfig(evidencesFileChange),
      action: uploadApi[type].evidences,
      onSuccess: handleEvidenceSuccess,
    };
    const minuteDraggerProps = {
      ...uploadConfig(minutesFileChange, null, { insurer: minuteInsurer }),
      action: uploadApi[type].minute,
      onSuccess: handleMinuteSuccess,
    };

    return (
      <Row
        gutter={12}
        className={`uploadContainer ${
          url ? "gx-d-flex gx-align-items-center" : ""
        }`}
      >
        <Col sm={24} md={6}>
          <h5>{uploadLabel}:</h5>
        </Col>
        <Col sm={24} md={url ? 8 : 18}>
          {url ? (
            <Button
              ghost
              type="primary"
              target="_blank"
              href={`${url}`}
              rel="noopener noreferrer"
              className="gx-mb-1 gx-w-100"
            >
              <PrinterOutlined /> {buttonText}
            </Button>
          ) : (
            <Dragger
              {...(documentType === "minute"
                ? minuteDraggerProps
                : evidenceDraggerProps)}
              showUploadList={false}
            >
              <p className="ant-upload-text">{uploadPlaceholder}</p>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
            </Dragger>
          )}
        </Col>
      </Row>
    );
  };

  function handleSelectInsurer(value) {
    setMinuteInsurer(value);
    dispatch({
      type: CHANGE_MINUTE_DATA,
      payload: {},
    });
  }

  const getStatus = () => {
    if (!minute.url) {
      return <h5 className="gx-mb-0">{Translation.minutePendingLabel}</h5>;
    }
    return minute.proposalAccepted ? (
      <h5 className="gx-mb-0">
        {Translation.minuteAcceptedAt}{" "}
        {minute.evidence
          ? formatDateTime(minute.evidence.acceptedAt)
          : formatDateTime(minute.acceptedAt)}
      </h5>
    ) : (
      <h5 className="gx-mb-0">{Translation.acceptPendingLabel}</h5>
    );
  };

  useEffect(() => {
    registerChosenInsurer();
  }, [quotations]);

  useEffect(() => {
    if (superiorSelectedTab === "4") {
      // getMinuteFile();
    }
  }, [superiorSelectedTab]);

  return (
    <Spin spinning={loading}>
      <div className="minuteSection">
        {expressErrorMessages.length > 0 && (
          <div className="errorContainer">
            {expressErrorMessages.map((message) => {
              return <p className="gx-mb-1">{message}</p>;
            })}
          </div>
        )}

        {!(errorMessages.length > 0) ? (
          <>
            <Row gutter={12} type="flex" align="middle" className="gx-mb-3">
              <Col xs={24} md={6}>
                {Translation.statusLabel}:
              </Col>
              <Col xs={24} md={18}>
                {getStatus()}
              </Col>
            </Row>
            {!hasExpressEmission ? (
              <Row gutter={12} type="flex" align="middle" className="gx-mb-3">
                <Col xs={24} md={6}>
                  {Translation.minuteSelectPlaceholder}:
                </Col>
                <Col xs={24} md={8}>
                  <Select
                    style={{ width: 220 }}
                    value={minuteInsurer}
                    className="gx-w-100"
                    onChange={handleSelectInsurer}
                    placeholder={Translation.minuteSelectPlaceholder}
                  >
                    {config.insurers.map((option) => {
                      return (
                        <Option key={option[0]} value={option[0]}>
                          {option[1].label}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            ) : (
              <>
                <Row gutter={12}>
                  <Col sm={24} md={24}>
                    <QuotationData
                      insurerData={insurerData}
                      minutePremium={minute.premium}
                      chosenInsurer={minute.insurer}
                    />
                  </Col>
                </Row>
                {!minute.url ? (
                  <Row gutter={12}>
                    <Col sm={24} md={6}>
                      <Button type="primary" onClick={() => getMinuteFile()}>
                        {Translation.generateMinuteButton}
                      </Button>
                    </Col>
                  </Row>
                ) : null}
              </>
            )}
            <UploadDocument
              url={minute.url}
              documentType="minute"
              uploadLabel={Translation.uploadMinuteLabel}
              buttonText={Translation.downloadMinutePFDButton}
              uploadPlaceholder={Translation.uploadMinutePlaceholder}
            />
            {minute.url && !minute?.proposalAccepted ? (
              <>
                <UploadDocument
                  url={minute.evidence ? minute.evidence.url : null}
                  documentType="evidences"
                  uploadLabel={Translation.uploadEvidenceLabel}
                  buttonText={Translation.downloadEvidencePFDButton}
                  uploadPlaceholder={Translation.uploadEvidencePlaceholder}
                />
                <Row
                  gutter={12}
                  type="flex"
                  align="middle"
                  className="minuteHistory"
                >
                  <Col sm={24} md={24}>
                    <Button
                      ghost
                      type="primary"
                      className="gx-mb-1"
                      loading={isSending}
                      onClick={sendMinuteEmail}
                    >
                      {Translation.sendminuteButton} <SendOutlined />
                    </Button>
                  </Col>
                </Row>
              </>
            ) : null}
          </>
        ) : (
          <div className="errorContainer">
            {errorMessages.map((message) => {
              return <p className="gx-mb-1">{message}</p>;
            })}
          </div>
        )}
      </div>
    </Spin>
  );
};

export default Minute;
