//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "previousCoveragesLabel",
    "type": "label"
  },
  {
    "key": "previousCoveragesPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "previousInsurerLabel",
    "type": "label"
  },
  {
    "key": "previousInsurerPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "maxAmountLabel",
    "type": "label"
  },
  {
    "key": "maxAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "minPremiumLabel",
    "type": "label"
  },
  {
    "key": "minPremiumPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "monthlyBillLabel",
    "type": "label"
  },
  {
    "key": "monthlyBillPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "taxRateLabel",
    "type": "label"
  },
  {
    "key": "taxRatePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "additionalDataLabel",
    "type": "label"
  },
  {
    "key": "additionalDataPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "hasPreviousInsuranceLabel",
    "type": "label"
  }
  
];
export default prepareTranslations(keys, translation);