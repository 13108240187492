import React, {Component} from 'react';
//Translations
import Translation from "./translation"
//Components
import {Row, Col, Card} from "antd";
//Custom Components
import {Switch, NumberInput, FormField} from "components"
import {cloneObject} from "../../../../../../../helpers";
//Config
const fleetTypes = [
  {type: 'THIRD_PARTY', label: Translation.hasThirtyFleetLabel},
  {type: 'INDEPENDENT', label: Translation.hasIndependentFleetLabel},
  {type: 'OWN', label: Translation.hasOwnFleetLabel},
];

const FleetCard = (props) => {
  const {label, checked, config, onChange, onToggle, OrderErrors, FleetType} = props;
  return (
    <Card size="small" className="fadein">
      <Row type="flex" className="gx-flex-column">
        <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Switch checked={checked} onChange={onToggle}/>
            </Col>
            <Col>
              {label}
            </Col>
          </Row>
        </Col>
        <Col className="gx-mt-2">
          {/* <Row type="flex" gutter={8} align="middle" justify="space-between" className="gx-mt-2">
            <Col>
              {Translation.totalVehiclesLabel}
            </Col>
            <Col>
              <FormField error={OrderErrors[`operational.fleets[${FleetType}].vehicleQuantity`]}>
                <NumberInput
                  fullWidth
                  disabled={!checked}
                  value={config.vehicleQuantity}
                  onChange={onChange("vehicleQuantity")}
                  placeholder={Translation.totalVehiclesPlaceholder}
                />
              </FormField>
            </Col>
          </Row> */}
          <Row type="flex" gutter={8} align="middle" justify="space-between" className="gx-mt-2">
            <Col>
              {Translation.averageAgeLabel}
            </Col>
            <Col>
            <FormField error={OrderErrors[`operational.fleets[${FleetType}].averageYearsOld`]}>
              <NumberInput
                fullWidth
                disabled={!checked}
                value={config.averageYearsOld}
                onChange={onChange("averageYearsOld")}
                placeholder={Translation.averageAgePlaceholder}/>
            </FormField>
            </Col>
          </Row>
          <Row type="flex" gutter={8} align="middle" justify="space-between" className="gx-mt-2">
            <Col>
              {Translation.maximumAgeLabel}
            </Col>
            <Col>
            <FormField error={OrderErrors[`operational.fleets[${FleetType}].maximumYearsOld`]}>
              <NumberInput
                fullWidth
                disabled={!checked}
                value={config.maximumYearsOld}
                onChange={onChange("maximumYearsOld")}
                placeholder={Translation.averageAgePlaceholder}/>
            </FormField>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
};


class Fleets extends Component {

  constructor(props) {
    super(props);
    this.state = this.initializeState()
  }

  static emptyFleet() {
    return {
      vehicleQuantity: null,
      averageYearsOld: null,
      maximumYearsOld: null
    }
  }

  reset = () => {
    const initialState = this.initializeState();
    this.setState(initialState);
  };

  initializeState = () => {
    const switchers = {};
    const data = {};
    const config = this.props.data;

    fleetTypes.forEach(fleetConfig => {
      const type = fleetConfig.type;
      const exist = config.hasOwnProperty(type);
      if (exist) {
        switchers[type] = true;
        data[type] = config[type];
      } else {
        switchers[type] = false;
        data[type] = Fleets.emptyFleet();
      }
    });
    return {
      data: data,
      switchers: switchers
    }
  };

  handleChange = (type) => (attr) => (event) => {
    const { RemoveError } = this.props
    RemoveError(`operational.fleets[${type}].${attr}`)
    this.setState(prevState => {
      const draft = cloneObject(prevState.data);
      draft[type][attr] = event;
      return {
        data: draft
      }
    }, this.notifyChanges)
  };

  handleToggle = (type) => (event) => {
    const { RemoveError } = this.props
    RemoveError(`operational.fleets[${type}].vehicleQuantity`)
    RemoveError(`operational.fleets[${type}].averageYearsOld`)
    RemoveError(`operational.fleets[${type}].maximumYearsOld`)

    this.setState(prevState => {
      const draft = {...prevState.switchers};
      draft[type] = !draft[type];
      return {
        switchers: draft
      }
    }, this.notifyChanges)
  };

  notifyChanges = () => {
    if (this.props.notifyChanges)
      return this.props.notifyChanges();
  };

  getChanges = () => {
    const {switchers, data} = this.state;
    const updatedData = {};

    fleetTypes.forEach(fleet => {
      const type = fleet.type;
      if (switchers[type])
        updatedData[type] = data[type];
    });

    return updatedData;
  };


  render() {
    const {data, switchers} = this.state;

    return (
      <Row type="flex" gutter={8}>
        {fleetTypes.map(fleet => (
          <Col xs={24} md={8}>
            <FleetCard
              label={fleet.label}
              config={data[fleet.type]}
              checked={switchers[fleet.type]}
              onChange={this.handleChange(fleet.type)}
              onToggle={this.handleToggle(fleet.type)}
              OrderErrors={this.props.OrderErrors}
              FleetType={fleet.type}/>
          </Col>
        ))}
      </Row>
    );
  }
}

export default Fleets;