import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation("customers");

const keys = [
  {
    key: "customerContactNameLabel",
    type: "label",
  },
  {
    key: "customerContactPhoneLabel",
    type: "label",
  },
  {
    key: "customerContactMobileLabel",
    type: "label",
  },
  {
    key: "customerContactEmailLabel",
    type: "label",
  },
  {
    key: "customerContactCollectionContactLabel",
    type: "label",
  },
  {
    key: "customerContactAllowsRegisterDataContactLabel",
    type: "label",
  },
  {
    key: "customerContactAllowsPortalAccessContactLabel",
    type: "label",
  },
  {
    key: "customerContactRequestContactLabel",
    type: "label",
  },
  {
    key: "customerContactBillingContactLabel",
    type: "label",
  },
  {
    key: "customerContactDeleteContactLabel",
    type: "label",
  },
  {
    key: "registerCancelButtonLabel",
    type: "label",
  },
  {
    key: "registerSaveButtonLabel",
    type: "label",
  },
  {
    key: "customerContactNamePlaceholder",
    type: "placeholder",
  },
  {
    key: "customerContactEmailPlaceholder",
    type: "placeholder",
  },
  {
    key: "customerContactFetchingLabel",
    type: "label",
  },
  {
    key: "customerContactEmptyContacts",
    type: "label",
  },
  {
    key: "customerallowsWhatsAppMessageLabel",
    type: "label",
  },
  {
    key: "customerContactAllowsIndicativeQuotationLabel",
    type: "label",
  },
];

export default prepareTranslations(keys, translation);
