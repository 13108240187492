import React, {Component} from "react";
// router
import { Link } from "react-router-dom"; 
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actionCreators/Auth";

class UserInfo extends Component {
    state = {
        openUserProfile: false
    };

    handleSignOut = () => {
        this.props.userSignOut();
    };

    render() {
        const userMenuOptions = (
            <ul className="gx-user-popover">
                <li>Meu perfil</li>
                <li><Link to="/change-password">Alterar senha</Link></li>
                <li onClick={this.handleSignOut}>Sair do sistema</li>
            </ul>
        );

        return (
            <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
                     trigger="click">
                <Avatar src='https://via.placeholder.com/150x150'
                        className="gx-avatar gx-pointer" alt=""/>
            </Popover>
        )

    }
}

export default connect(null, {userSignOut})(UserInfo);
