import React, { useState, useEffect } from "react";
// prop types
import PropTypes from "prop-types";
// tranlation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Select, Button, Checkbox } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
// components
import FormField from "../../../../../FormField";
import CurrencyInput from "../../../../../CurrencyInput";
// hook
import useCoverage from "../../../../../../hooks/useCoverages";
// helpers
import { getErrorMessage } from "../../../../../../helpers";

const { Option } = Select;

const CoverageForm = ({
  policyIndex,
  coverageIndex,
  errors = [],
  coverageData = {},
  policyType,
  onChange,
}) => {
  const [coverageTypes, setCoverageTypes] = useState([]);

  const { t: translation } = useTranslation();
  const getCoverageTypes = useCoverage();

  const handleChange = (field, value) => {
    return onChange && onChange(field, value);
  };

  const getIsChecked = () => {
    return (
      coverageData.rate === 0 &&
      coverageData.maximumGuaranteeLimit === 0 &&
      coverageData.franchise === 0
    );
  };

  useEffect(() => {
    if (policyType) {
      getCoverageTypes(policyType).then((coverages) => {
        setCoverageTypes(coverages);
      });
    }
  }, [policyType]);

  return (
    <>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3}>
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.additionalCoverageLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].coverages[${coverageIndex}].coverage`,
              errors
            )}
          >
            <Select
              className="gx-w-100"
              value={coverageData.coverage}
              onChange={(value) => handleChange("coverage", value)}
              placeholder={translation(
                "proposal.additionalCoveragePlaceholder"
              )}
            >
              {coverageTypes.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
        <Col sm={24} md={3}>
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.franchiseLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].coverages[${coverageIndex}].franchise`,
              errors
            )}
          >
            <CurrencyInput
              prefix="R$"
              decimalSeparator=","
              className="gx-w-100"
              value={coverageData.franchise}
              onChange={(value) => handleChange("franchise", value)}
              placeholder={translation("proposal.franchisePlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={3}>
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.limitLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].coverages[${coverageIndex}].maximumGuaranteeLimit`,
              errors
            )}
          >
            <CurrencyInput
              prefix="R$"
              className="gx-w-100"
              value={coverageData.maximumGuaranteeLimit}
              onChange={(value) => handleChange("maximumGuaranteeLimit", value)}
              placeholder={translation("proposal.limitPlaceholder")}
            />
          </FormField>
        </Col>
        <Col sm={24} md={3}>
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.rateLabel")}
          </p>
        </Col>
        <Col sm={24} md={9}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `policies[${policyIndex}].coverages[${coverageIndex}].rate`,
              errors
            )}
          >
            <CurrencyInput
              suffix="%"
              prefix=""
              decimalScale={3}
              className="gx-w-100"
              value={coverageData.rate}
              onChange={(value) => handleChange("rate", value)}
              placeholder={translation("proposal.ratePlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={10}>
          <p className="gx-m-0 gx-text-left">
            {translation("proposal.feeFreeCoverageLabel")}
          </p>
        </Col>
        <Col sm={24} md={2}>
          <FormField className="gx-m-0 gx-text-right">
            <Checkbox
              checked={getIsChecked()}
              onChange={(value) => handleChange("feeFreeCoverage", value)}
            />
          </FormField>
        </Col>
      </Row>
    </>
  );
};

CoverageForm.propTypes = {
  policyIndex: PropTypes.number,
  coverageIndex: PropTypes.number,
  coverageData: PropTypes.object,
  errors: PropTypes.array,
  policyType: PropTypes.string,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
};

export default CoverageForm;
