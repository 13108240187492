// Users
export const extractAllUsers = state => state.users.all;
export const extractSelectedUsers = state => state.users.selected;
export const isFetchingUsers = state => state.users.isFetching;
export const extractUserSearch = state => state.users.search;

//Profiles
export const extractAllProfiles = state => state.common.allProfiles.all;
export const isFetchingAllProfiles = state => state.common.allProfiles.isFetching;

//Authentication
export const extractAuthUser = state => state.auth.authUser;

/*Orders*/
export const extractAllOrders = state => state.crm.orders.all;
export const hasFetchedOrders = state => state.crm.orders.all.length > 0;
export const isFetchingOrders = state => state.crm.orders.isFetching;
export const extractOrdersFilter = state => state.crm.orders.filters;
export const areFiltersInitialized = state => state.crm.orders.initializedFilters;
export const extractOrdersSearch = state => state.crm.orders.searches;
export const isFilterOrdersUpdated = state => state.crm.orders.filtersUpdated;
export const extractOrderListCurrentPage = state => state.crm.orders.currentPage;

/*Order*/
export const fetchedOrder = (state, id) => {
    return state.crm.order.details.hasOwnProperty(id);
};
export const extractAvailableOrders = (state) => {
    return state.crm.order.details;
};
export const extractOrder = (state, id) => {
    return state.crm.order.details[id];
};
export const extractOrderConfigurations = (state) => state.crm.order.configurations;
export const isFetchingOrder = state => state.crm.order.isFetching;
export const isResettingOrder = state => state.crm.order.isResetting;
export const isSavingOrder = state => state.crm.order.isSaving;
export const isPreparingOrder = state => state.crm.order.isPreparing;

export const getSelectedTab = state => state.crm.order.selectedTab;
export const getOrderErrors = state => state.crm.order.orderErrors;

//customers
export const getFilterParams = state => state.crm.customers.filters
export const getRegisterData = state => state.crm.customers.customerTabs.registerData
export const getCustomersData = state => state.crm.customers

//policy
export const getCustomerPolicyData = state => state.crm.policy.data

//invoice
export const getInvoiceData = state => state.crm.invoice.data;
