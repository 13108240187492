import React from 'react';
//Custom Components
import { default as InputMask } from 'react-text-mask';
//Translation
import Translation from "../Contacts/translation";
//helpers
import {formatMaskInput} from 'helpers'
//Others
import {injectIntl, intlShape} from 'react-intl';

const phoneMask = function (rawValue) {
  const numbers = rawValue ? formatMaskInput(rawValue) : '';
  let mask = [];
  if(numbers){
    if(numbers.length < 11){
      mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    } else {
      mask = ['(', /[0-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    }
  }
  return mask;
}



const Input = (props) => {
  const {value, type, onChange, pipe, intl} = props;

  return (
    <InputMask  mask={phoneMask} 
                value={value} 
                pipe={pipe} 
                className="maskedInput ant-input"
                disabled={!type}
                onChange={onChange}
                placeholder={intl.formatMessage(Translation.contactPhoneNumberPlaceholder)}
    />
  );
};

Input.propTypes = {
    intl: intlShape.isRequired
};

export default injectIntl(Input);