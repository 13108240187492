//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("dataSheet");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}


export default {
    "group": getTranslation("group", true),
    "name": getTranslation("name", true),
    "birthDate": getTranslation("birthDate", true),
    "documentNumber": getTranslation("cpf", true),
    "salary": getTranslation("salary", true),
    "groupOption.partners": getTranslation("groupOption.partners"),
    "groupOption.employees": getTranslation("groupOption.employees"),
    "groupOption.interns": getTranslation("groupOption.interns"),
    "groupOption.service_providers": getTranslation("groupOption.service_providers"),
    "groupOption.associates": getTranslation("groupOption.associates"),
    "groupOption.members": getTranslation("groupOption.members"),
    "groupOption.students": getTranslation("groupOption.students"),
    "groupOption.others": getTranslation("groupOption.others")
};