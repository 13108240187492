import React, { Component } from "react";
//Translation
import Translation from './translation';
//Custom Css
import './Styles.less'
//Components
import { Tabs, Button, Card, Row, Col, Tooltip } from "antd";
import { LngInput, EmptyWrapper, FormField } from "components";
//Other Libs
import produce from "immer";
import CurrencyInput from "../../../../../../CurrencyInput";
// intl
import {injectIntl } from 'react-intl';
//Constants
const { TabPane } = Tabs;

const groupTypes = {
    PARTNERS: Translation.partnersGroup,
    EMPLOYEES: Translation.employeesGroup,
    INTERNS: Translation.internsGroup,
    SERVICE_PROVIDERS: Translation.serviceProvidersGroup,
    ASSOCIATES: Translation.associatesGroup,
    MEMBERS: Translation.membersGroup,
    STUDENTS: Translation.studentsGroup,
    OTHERS: Translation.othersGroup,
};

const HeaderContent = (props) => {
    const { isEmpty, onCreate, onRemove } = props;
    return (
        <section style={isEmpty ? { float: "right" } : {}}>
            {!isEmpty && <Tooltip placement="bottom" title={Translation.insurableGroupRemove}>
                <Button onClick={onRemove} icon="minus" className="gx-btn-outline-danger" htmlType="button">
                    {Translation.remove}
                </Button>
            </Tooltip>}
            <Tooltip placement="bottom" title={Translation.insurableGroupAdd}>
                <Button icon="plus" onClick={onCreate} className="gx-btn-outline-primary" htmlType="button">
                    {Translation.add}
                </Button>
            </Tooltip>
        </section>
    )
};

const InsurableGroupContent = injectIntl((props) => {
    const { index, onChange, data, OrderErrors, intl } = props;
    const { group, value } = data;

    const getGroupTypeValue = (type) => {
        return groupTypes[type] ? intl.formatMessage(groupTypes[type]) : null;
    }

    return (
        <section key={index}>
            <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
                <Col xs={24} md={8}>{Translation.groupLabel}:</Col>
                <Col xs={24} md={16}>
                    <FormField error={OrderErrors[`details.insurableGroups[${index}].group`]}>
                        <LngInput
                            value={getGroupTypeValue(group)}
                            placeholder={Translation.groupPlaceholder}
                            onChange={onChange("group", index)} />
                    </FormField>
                </Col>
            </Row>
            <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
                <Col xs={24} md={8}>{Translation.valueLabel}:</Col>
                <Col xs={24} md={16}>
                    <FormField error={OrderErrors[`details.insurableGroups[${index}].value`]}>
                        <CurrencyInput
                            value={value}
                            placeholder={Translation.valuePlaceholder}
                            onChange={onChange("value", index, true)} />
                    </FormField>
                </Col>
            </Row>
        </section>
    )
});

const InsurableGroupsList = (props) => {
    const { header, data, onTabChange, onInsurableGroupChange, activeTabKey,OrderErrors } = props;
    return (
        <Tabs
            hideAdd
            type="card"
            onChange={onTabChange}
            activeKey={activeTabKey}
            tabBarExtraContent={header}>
            {data.map((entry, index) => {
                return (
                    <TabPane tab={<p>Grupo segurável #{index + 1} </p>} key={`insurable-group-${index}`}>
                        <InsurableGroupContent
                            index={index}
                            data={entry}
                            onChange={onInsurableGroupChange}
                            OrderErrors={OrderErrors} />
                    </TabPane>
                )
            })}
        </Tabs>
    )
};

class InsurableGroups extends Component {
    constructor(props) {
        super(props);
        this.state = InsurableGroups.emptyState(props.data);
    }

    static emptyState(data) {
        const draft = data.map(InsurableGroups.formatInsurableGroup);
        return {
            activeTabKey: `insurable-group-0`,
            data: draft
        }
    }

    static formatInsurableGroup(insurableGroup) {
        return {
            group: insurableGroup.group || null,
            value: insurableGroup.value || null
        }
    }

    static emptyInsurableGroup() {
        return {
            group: null,
            value: null
        }
    }

    reset = () => {
        const { data } = this.props;
        this.setState(InsurableGroups.emptyState(data));
    };

    getChanges = () => {
        const { data } = this.state;

        return data.map(entry => {
            return {
                group: entry.group,
                value: entry.value
            }
        });
    };

    createInsurableGroup = () => {
        this.setState(prevState => {
            const updatedData = [...prevState.data, InsurableGroups.emptyInsurableGroup()];
            return {
                data: updatedData,
                activeTabKey: `insurable-group-${updatedData.length - 1}`,
            }
        }, this.notifyChanges);
    };

    onTabChange = (tabKey) => {
        this.setState({
            activeTabKey: tabKey
        });
    };

    handleInsurableGroupRemove = () => {
        this.setState(prevState => {
            let removedIndex = parseInt(prevState.activeTabKey.split('-')[2]);
            const updatedData = prevState.data.filter((insurableGroup, index) => index !== removedIndex);
            if (removedIndex === 0) removedIndex = 1;
            const activeTabKey = `insurable-group-${removedIndex - 1}`;
            return {
                data: updatedData,
                activeTabKey: activeTabKey
            }
        }, this.notifyChanges)
    };

    notifyChanges = () => {
        if (this.props.notifyChanges)
            this.props.handleChange({
                attr:'insurableGroups',
                value: this.state.data
            })
            return this.props.notifyChanges();
    };

    handleInsurableGroupChange = (attr, index, isValue = false) => (event) => {
        let value;
        value = isValue ? event.floatValue : event.target.value;

        this.setState(produce(draft => {
            draft.data[index][attr] = value;
        }), this.notifyChanges);
    };

    render() {
        const { data, activeTabKey } = this.state;
        const {OrderErrors} = this.props
        const showEmptyInsurableGroups = data.length === 0;

        const header = (
            <HeaderContent
                isEmpty={showEmptyInsurableGroups}
                onRemove={this.handleInsurableGroupRemove}
                onCreate={this.createInsurableGroup} />
        );
        return (
            <section>
                <Card size="small">
                    {showEmptyInsurableGroups && <EmptyWrapper
                        header={header}
                        height={200}
                        description={Translation.insurableGroupEmptyLabel}
                    />}
                    {!showEmptyInsurableGroups && <InsurableGroupsList
                        header={header}
                        data={data}
                        onTabChange={this.onTabChange}
                        onInsurableGroupChange={this.handleInsurableGroupChange}
                        activeTabKey={activeTabKey} 
                        OrderErrors={OrderErrors} />}
                </Card>
            </section>
        );
    }
}

export default InsurableGroups;