import React from "react";
//Custom Css
import './Styles.less';
//Components
import {Select} from "antd";
import {FormField} from "components"
//Constants
const Option = Select.Option;

//Helper functions
function renderSelectOptions(options) {
    return options.map(option => {
        const {value, label} = option;
        return (
            <Option key={value} value={value} label={label}>
                {label}
            </Option>
        )
    })
}

class TagSelect extends Select {
    disableInput() {
        const selects = document.getElementsByClassName(`ant-select-search__field`);
        for (let el of selects) {
            el.setAttribute(`maxlength`, 0);
        }
    }

    componentDidMount() {
        this.disableInput();
    }
}

const MultipleSelect = (props) => {
    const {options, fullWidth, mode,OrderErrors, validationParam, showArrow, ...others} = props;
    return (
        <section className="customMultipleSelect">
            <FormField error={OrderErrors ? OrderErrors[validationParam] : null}>
                <TagSelect
                    showArrow={showArrow}
                    mode={mode}
                    // getPopupContainer={trigger => trigger.parentNode}
                    {...others}>
                    {renderSelectOptions(options)}
                </TagSelect>
            </FormField>
            </section>
    );
};

export default MultipleSelect;

MultipleSelect.defaultProps = {
    fullWidth: true,
    showArrow: true,
    mode: "multiple"
};