import React from 'react';
import IntlMessages from "util/IntlMessages";


export function setInitialTranslation(componentTitle) {
  return {
    componentTitle: componentTitle,
    getLabel(title) {
      const prefix = this.componentTitle || "app";
      return <IntlMessages id={`${prefix}.${title}`} stringFormat={true}/>
    },
    getPlaceholder(title) {
      const prefix = this.componentTitle || "app";
      return {id: `${prefix}.${title}`};
    },
    setLabel(title, global = false) {
      const id = global ? `app.${title}` : `${this.componentTitle}.${title}`;
      return <IntlMessages id={id} stringFormat={true}/>
    },
    setPlaceholder(title, global = false) {
      const id = global ? `app.${title}` : `${this.componentTitle}.${title}`;
      return {id: id};
    },
    set(title, global = false, stringFormat = false) {
      const configId = global ? `app.${title}` : `${this.componentTitle}.${title}`;
      if (stringFormat)
        return {id: configId};
      return (
        <IntlMessages id={configId} stringFormat={stringFormat}/>
      )
    }
  };
}

export function prepareTranslations(keys, translation) {
  const translated = {};
  keys.forEach(config => {
    const {key, type} = config;
    if (type === "label")
      translated[key] = translation.getLabel(key);
    else if (type === "placeholder")
      translated[key] = translation.getPlaceholder(key);
  });
  return translated;
}