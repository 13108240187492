import React, { Component } from "react";
//Custom Styles
import "./Styles.less";
//Custom Components
import {
  Row,
  Col,
  Modal,
  Form,
  Avatar,
  Input,
  Icon,
  Select,
  Button,
  Radio,
  Checkbox,
} from "antd";
import { MaskedInput, MultipleSelect } from "components";
//Translation config
import Translation from "./translation";
//Custom Images
import placeholderImage from "assets/images/placeholder.jpg";
//Custom Helpers
import { prepareUserCreate } from "../helpers";
//Constants
import selections from "constants/selections";
//Others
const { Option } = Select;
const fullWidth = { width: "100%" };
const formStyle = { xs: { span: 24 } };
const iconStyle = { color: "rgba(0,0,0,.25)" };
const modalBodyStyle = { padding: 0 };

function getIcon(icon) {
  return {
    prefix: <Icon type={icon} style={iconStyle} />,
  };
}

class CreateUser extends Component {
  componentDidMount() {
    this.setInitialState();
  }

  componentWillUnmount() {
    this.resetForm();
  }

  resetForm = () => {
    return this.props.form.resetFields();
  };

  setInitialState() {
    this.props.form.setFieldsValue({
      whatsapp: "+55 (11) 3003-8387",
    });

    this.props.form.setFieldsValue({
      phoneType: "PHONE_NUMBER",
    });
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { form, closeCreateMode, createUser } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) createUser(prepareUserCreate(values), closeCreateMode);
    });
  };

  validateWhatsAppNumber = (rule, value, callback) => {
    if (
      (this.props.form.getFieldValue("phoneType") === "PHONE_NUMBER" &&
        value &&
        value.length !== 18) ||
      value.charAt(17) === "_"
    ) {
      return callback(Translation.whatsAppTypeValidation);
    } else if (
      (this.props.form.getFieldValue("phoneType") === "MOBILE_PHONE_NUMBER" &&
        value &&
        value.length !== 19) ||
      value.charAt(18) === "_"
    ) {
      return callback(Translation.whatsAppTypeValidation);
    } else return callback();
  };

  render() {
    const {
      form: { getFieldDecorator },
      closeCreateMode,
      open,
      allProfiles,
      loadingAllProfiles,
    } = this.props;

    return (
      <Modal
        title={Translation.title}
        visible={open}
        closable={false}
        centered={true}
        footer={null}
        bodyStyle={modalBodyStyle}
        onCancel={closeCreateMode}
      >
        <main className="addUser">
          <Form wrapperCol={formStyle} onSubmit={this.handleSubmit}>
            <section className="addUser-formSection">
              <section className="addUser-avatarSection">
                <input
                  accept="image/*"
                  className="addUser-avatarSection-input"
                  id="icon-button-file"
                  type="file"
                />
                <label htmlFor="icon-button-file">
                  <Avatar size={64} src={placeholderImage} />
                  <small className="addUser-avatarSection-label">
                    {Translation.uploadImageTitle}
                  </small>
                </label>
              </section>
              <Form.Item
                label={Translation.nameEntity}
                hasFeedback
                className="addUser-formItem"
              >
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: true,
                      message: Translation.nameEntityValidation,
                    },
                  ],
                })(<Input {...getIcon("user")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.positionEntity}
                hasFeedback
                className="addUser-formItem"
              >
                {getFieldDecorator("position", {
                  rules: [
                    {
                      required: true,
                      message: Translation.positionEntityValidation,
                    },
                  ],
                })(<Input {...getIcon("form")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.profileEntity}
                hasFeedback
                className="addUser-formItem"
              >
                {getFieldDecorator("key", {
                  rules: [
                    {
                      required: true,
                      message: Translation.profileKeyEntityValidation,
                    },
                  ],
                })(
                  <Select
                    loading={loadingAllProfiles}
                    placeholder={Translation.profileKeyEntityPlaceholder}
                  >
                    {!loadingAllProfiles &&
                      allProfiles.map(({ key, description }) => (
                        <Option value={key} key={key}>
                          {description}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label={Translation.emailEntity}
                hasFeedback
                className="addUser-formItem"
              >
                {getFieldDecorator("email", {
                  rules: [
                    {
                      type: "email",
                      message: Translation.emailEntityTypeValidation,
                    },
                    {
                      required: true,
                      message: Translation.emailEntityReqValidation,
                    },
                  ],
                })(<Input {...getIcon("form")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.phoneTypeLabel}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator(
                  "phoneType",
                  {}
                )(
                  <Radio.Group name="phoneType">
                    <Radio value="PHONE_NUMBER">
                      {Translation.phoneTypePhoneLabel}
                    </Radio>
                    <Radio value="MOBILE_PHONE_NUMBER">
                      {Translation.phoneTypeMobileLabel}
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item
                label={Translation.whatsAppEntity}
                hasFeedback
                className="addUser-formItem"
              >
                {getFieldDecorator("whatsapp", {
                  rules: [
                    {
                      required: true,
                      message: Translation.whatsAppEntityReqValidation,
                    },
                    { validator: this.validateWhatsAppNumber },
                  ],
                })(
                  <MaskedInput
                    mask={
                      this.props.form.getFieldValue("phoneType") ===
                      "PHONE_NUMBER"
                        ? "+55 (99) 9999-9999"
                        : "+55 (99) 99999-9999"
                    }
                  />
                )}
              </Form.Item>
              <Form.Item
                label={Translation.extensionNumberLabel}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("extensionNumber", {
                  rules: [
                    {
                      required: true,
                      message: Translation.extensionNumberValidation,
                    },
                  ],
                })(<Input {...getIcon("phone")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.orderProductsLabel}
                className="addUser-formItem"
              >
                {getFieldDecorator("products", {
                  rules: [
                    {
                      required: true,
                      message: Translation.orderProductsValidation,
                    },
                  ],
                })(
                  <MultipleSelect
                    className="gx-w-100"
                    options={selections.orderProducts}
                    placeholder={Translation.orderProductsPlaceholder}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={Translation.hasHubspotLabel}
                className="addUser-formItem gx-mt-3"
              >
                {getFieldDecorator("hasHubspotUser", {
                  initialValue: false,
                })(
                  <Radio.Group>
                    <Radio value={true}>{Translation.hasHubspotYesLabel}</Radio>
                    <Radio value={false}>{Translation.hasHubspotNoLabel}</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="" className="addUser-formItem gx-mt-3">
                {getFieldDecorator("preSalesProfile", {
                  initialValue: false,
                })(<Checkbox>Usuário é um pré-vendedor?</Checkbox>)}
              </Form.Item>
              <Form.Item label="" className="addUser-formItem">
                {getFieldDecorator("salesProfile", {
                  initialValue: false,
                })(<Checkbox>O usuário é um vendedor?</Checkbox>)}
              </Form.Item>
            </section>
            <Row
              type="flex"
              justify="end"
              gutter={8}
              className="addUser-actionSection"
            >
              <Col>
                <Button onClick={closeCreateMode}>{Translation.cancel}</Button>
              </Col>
              <Col>
                <Form.Item className="addUser-formItem">
                  <Button type="primary" htmlType="submit">
                    {Translation.ok}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </main>
      </Modal>
    );
  }
}

const WrappedCreateUserForm = Form.create({ name: "create_user" })(CreateUser);

export default WrappedCreateUserForm;
