//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation("quotations");
const keys = [
  {
    "key": "showQuotationsLabel",
    "type": "label"
  },
  {
    "key": "hideQuotationsLabel",
    "type": "label"
  },
  {
    "key": "insurerHeaderLabel",
    "type": "label"
  },
  {
    "key": "typeHeaderLabel",
    "type": "label"
  },
  {
    "key": "taxHeaderLabel",
    "type": "label"
  },
  {
    "key": "priceHeaderLabel",
    "type": "label"
  },
  {
    "key": "DOCUMENTATION_NEEDED",
    "type": "label"
  },
  {
    "key": "DESIGNATION_LETTER_NEEDED",
    "type": "label"
  },
  {
    "key": "PENDING_ANALYSIS",
    "type": "label"
  },
  {
    "key": "REFUSED",
    "type": "label"
  },
  {
    "key": "CCG_PENDING",
    "type": "label"
  },
  {
    "key": "INSURER_REGISTER_NEEDED",
    "type": "label"
  },
  {
    "key": "BLOCKED",
    "type": "label"
  },
  {
    "key": "defaultStatus",
    "type": "label"
  },
  {
    "key": "caption",
    "type": "label"
  }
];
export default prepareTranslations(keys, translation);