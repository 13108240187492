import cargoOpenPolicyFieldsPrepare from "./cargoOpenPolicy";
import cargoSingleBoardingFieldsPrepare from "./cargoSingleBoarding";
import suretyFieldsPrepare from "./surety";
import lifeFieldsPrepare from './life';

export default {
    CARGO_SINGLE_BOARDING: cargoSingleBoardingFieldsPrepare,
    CARGO_OPEN_POLICY: cargoOpenPolicyFieldsPrepare,
    SURETY: suretyFieldsPrepare,
    LIFE: lifeFieldsPrepare
}