export function storeUser(user) {
    return localStorage.setItem('user_id', JSON.stringify(user));
}

export function getStoredUser() {
    return JSON.parse(localStorage.getItem('user_id'));
}

export function removeStoredUser() {
    return localStorage.removeItem('user_id');
}