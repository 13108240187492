//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [  
  {
    "key": "personTypeLabel",
    "type": "label"
  },
  {
    "key": "documentNumberLabel",
    "type": "label"
  },
  {
    "key": "contactNameLabel",
    "type": "label"
  },
  {
    "key": "contactNamePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "emailLabel",
    "type": "label"
  },
  {
    "key": "emailPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "companyLabel",
    "type": "label"
  },
  {
    "key": "companyPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "zipCodeLabel",
    "type": "label"
  },
  {
    "key": "searchCompany",
    "type": "label"
  },
  {
    "key": "phoneTypeLabel",
    "type": "label"
  },
  {
    "key": "phoneTypePlaceholder",
    "type": "label"
  },
  {
    "key": "phoneNumberLabel",
    "type": "label"
  },
  {
    "key": "phoneNumberPlaceholder",
    "type": "label"
  },
  {
    "key": "receiveWhatsAppMessageLabel",
    "type": "label"
  },
  {
    "key": "yes",
    "type": "label"
  },
  {
    "key": "no",
    "type": "label"
  },
  {
    "key": "companyTypeLabel",
    "type": "label"
  },
  {
    "key": "companySizeLabel",
    "type": "label"
  },
  {
    "key": "companyTypePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "companySizePlaceholder",
    "type": "placeholder"
  }
];
export default prepareTranslations(keys, translation);