import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { AutoComplete, Icon } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { useCustomersSearch, useDebounce } from "../../../hooks";
import {
  getSearchData,
  setRequestUrl,
  setResults,
  setSearchErrors,
  setSearchValue,
  setSelectedFilter,
} from "../../../store/slices/customer";
import { FormField } from "components";
import { SearchBar } from "../../ContainerComponents/SearchBar";
import { getErrorMessage } from "helpers";

const {
  Container,
  TitleWrapper,
  IconWrapper,
  Title,
  SearchWrapper,
  SearchSelect,
  Autocomplete,
  ActionButton,
} = SearchBar;

export const CustomerSearchBar = () => {
  const { t: translation } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const { selectedFilter, searchValue, results, errors } = useSelector(
    (state) => state.customer.searchData
  );
  const debouncedSearchValue = useDebounce(searchValue, 500);
  const {
    searchFilterOptions,
    getSearchRequest,
    getRedirectPath,
    customerSearchSchema,
    DEFAULT_VALUE,
  } = useCustomersSearch();

  function formatParameter(parameter = "", prefix = "") {
    const formatedPrefix = prefix.toLowerCase();
    const formatedParameter = parameter.toLowerCase();
    const result = formatedParameter.replace(formatedPrefix, "");
    return result;
  }

  const renderAutocompleteOptions = {
    POLICY_CARD: (data) => (
      <AutoComplete.Option
        key={data.parameter}
        value={data?.policy.toString()}
        style={{
          margin: "4px 8px",
        }}
      >
        <span>
          <span style={{ fontFamily: "NoirPro medium" }}>{searchValue}</span>
          {formatParameter(data.parameter, searchValue)}
        </span>
        <br />
        <span>{data.companyName}</span>
        <br />
        <span>
          <NumberFormat
            format="##.###.###/####-##"
            displayType="text"
            value={data.documentNumber}
          />
        </span>
        <br />
        <span>{data.typeDescription}</span>
        <br />
        <span>{data.insurer}</span>
      </AutoComplete.Option>
    ),
    PERSON_CARD: (data) => (
      <AutoComplete.Option
        key={data.id || data.person.toString()}
        value={data.id || data.person.toString()}
        style={{
          margin: "4px 8px",
        }}
      >
        <span>
          <span style={{ fontFamily: "NoirPro medium" }}>{searchValue}</span>
          {formatParameter(data.parameter, searchValue)}
        </span>
        <br />
        <span>{data.companyName}</span>
        <br />
        <span>
          <NumberFormat
            format="##.###.###/####-##"
            displayType="text"
            value={data.documentNumber}
          />
        </span>
      </AutoComplete.Option>
    ),
    NO_RESULTS_FOUND: (data) => (
      <AutoComplete.Option
        key={data.description}
        value={data.description}
        style={{
          margin: "4px 8px",
          display: "flex",
          justifyItems: "center",
        }}
      >
        <span
          style={{
            margin: "0 auto",
            padding: "16px 0",
          }}
        >
          {translation("customer.noResultsFound")}
        </span>
      </AutoComplete.Option>
    ),
  };

  const dataSource = results.map((result) => {
    if (renderAutocompleteOptions[result?.description]) {
      return renderAutocompleteOptions[result?.description](result);
    }
    return !result?.policy
      ? renderAutocompleteOptions.PERSON_CARD(result)
      : renderAutocompleteOptions.POLICY_CARD(result);
  });

  const handleSelectFilter = (filter) => {
    dispatch(setSelectedFilter(filter));
    dispatch(setRequestUrl(""));
    dispatch(setSearchValue(""));
    dispatch(setResults([]));
    dispatch(setSearchErrors([]));
  };

  const handleNewClient = () => {
    return history.push(`/crm/customers/new`);
  };

  const getIsEnableAutocomplete = () => {
    if (selectedFilter === DEFAULT_VALUE) {
      return false;
    }
    return true;
  };

  const handleChangeAutocomplete = (value) => {
    if (!getIsEnableAutocomplete()) return null;
    return customerSearchSchema
      .validate({ selectedFilter, searchValue: value }, { abortEarly: false })
      .then(() => {
        dispatch(setSearchErrors([]));
        dispatch(setSearchValue(value));
        dispatch(setRequestUrl(getSearchRequest(selectedFilter, value)));
      })
      .catch((err) => {
        dispatch(setSearchValue(value));
        dispatch(
          setSearchErrors([
            { field: "searchValue", message: err.inner[0].message },
          ])
        );
      });
  };

  const handleSelectSearched = (value) => {
    if (value === "NO_RESULTS_FOUND") return null;
    const selectedItem = results.find(
      (result) =>
        result?.id?.toString() === value ||
        result?.policy?.toString() === value ||
        result?.person?.toString() === value
    );
    const redirectPath = getRedirectPath(selectedFilter, {
      optionValue: value,
      currentPath: pathname,
      ...selectedItem,
    });
    return window.location.replace(redirectPath);
  };

  useEffect(() => {
    if (
      debouncedSearchValue &&
      debouncedSearchValue.length > 2 &&
      !errors.length
    ) {
      dispatch(getSearchData());
    } else {
      dispatch(setResults([]));
    }
  }, [debouncedSearchValue]);

  return (
    <Container>
      <TitleWrapper>
        <IconWrapper>
          <Icon type="appstore" />
        </IconWrapper>
        <Title>{translation("customer.searchBar.title")}</Title>
      </TitleWrapper>
      <SearchWrapper>
        <SearchSelect
          onChange={handleSelectFilter}
          selectOptions={searchFilterOptions}
          value={selectedFilter}
        />
        <FormField error={getErrorMessage("searchValue", errors)}>
          <Autocomplete
            dataSource={dataSource}
            onSelect={handleSelectSearched}
            onSearch={handleChangeAutocomplete}
            disabled={!getIsEnableAutocomplete()}
            name="searchValue"
            placeholder={
              !getIsEnableAutocomplete()
                ? translation("customer.searchBar.disabledPlaceholder")
                : translation("customer.searchBar.searchPlaceholder")
            }
            value={searchValue}
            allowClear
          />
        </FormField>
      </SearchWrapper>
      <ActionButton onClick={handleNewClient}>
        {translation("customer.searchBar.newCustomerButtonLabel")}
      </ActionButton>
    </Container>
  );
};
