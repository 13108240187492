//Configurations
import {initialConfig, fieldsPrepareConfig} from './configurations';

//Custom Helpers
export function updateOrdersFilter(filterType, value, previousFilters) {
    return {
        ...previousFilters,
        [filterType]: value
    }
}

export function getInitialFilters() {
    return {
        stage: "CREATED",
        types: {
            CARGO_OPEN_POLICY: true,
            CARGO_SINGLE_BOARDING: true,
            LIFE: true,
            SURETY: true
        },
        searches: {
            companyName: "",
            documentNumber: "",
            contactName: "",
            email: "",
            phoneNumber: ""
        }
    }
}

export function getInitialConfigurations(type) {
  return initialConfig[type]
}

export function prepareOrderConfigurations(data, prevConfig) {
  const prepareOrder = fieldsPrepareConfig[data.type];
  return prepareOrder(data, prevConfig)
}


