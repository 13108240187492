import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// components
import { Card, Modal, Row, Button } from "antd";
import "./styles.less";

const RefuseAccessClientPortalModal = ({
  visible,
  onClose,
  index,
  onConfirmAllowsAccessClientPortal,
}) => {
  const { t: translation } = useTranslation();

  const confirmCloseModal = () => {
    onClose && onClose();
  };

  const handleCancel = () => {
    confirmCloseModal();
  };

  const handleConfirmAllowsAccessClientPortal = () => {
    onConfirmAllowsAccessClientPortal(index);
    confirmCloseModal();
  };

  return (
    <Modal
      width={725}
      footer={null}
      centered={true}
      visible={visible}
      destroyOnClose={true}
      closable={false}
      wrapClassName="refuse-access-client-portal-modal-container"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "24px",
          padding: "24px",
        }}
      >
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <span
            style={{
              fontSize: "14px",
              lineHeight: "24px",
              fontWeight: 700,
              textAlign: "center",
              width: "100%",
              marginBottom: "16px",
            }}
          >
            {translation(
              "customer.contacts.refuseAccessClientPortalModal.title"
            )}
          </span>

          {translation(
            "customer.contacts.refuseAccessClientPortalModal.description"
          )
            .split("\n")
            .map((item, key) => {
              return (
                <p
                  style={{
                    textAlign: "center",
                    margin: 0,
                    fontSize: "14px",
                    lineHeight: "24px",
                  }}
                  key={key}
                >
                  {item}
                  <br />
                </p>
              );
            })}
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            gap: "100px",
          }}
        >
          <Button
            type="primary"
            ghost
            onClick={handleCancel}
            className="gx-m-0"
          >
            {translation(
              "customer.contacts.refuseAccessClientPortalModal.cancelButton"
            )}
          </Button>
          <Button
            type="primary"
            onClick={handleConfirmAllowsAccessClientPortal}
            className="gx-m-0"
          >
            {translation(
              "customer.contacts.refuseAccessClientPortalModal.acceptButton"
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

RefuseAccessClientPortalModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  index: PropTypes.number,
  onConfirmAllowsAccessClientPortal: PropTypes.func,
};

export default RefuseAccessClientPortalModal;
