import React, { Component } from 'react';
import { Icon, Tabs, Timeline } from "antd";

import { withRef } from 'util/forwardRef';

import GeneralTab from "./tabs/General";
import CoveragesTab from './tabs/Coverages';
import DetailsTab from './tabs/Details';
import InsuredLivesTab from './tabs/InsuredLives';
import { InitialTab, InsuredTab, UploadTab } from "../CommonTabs"
import Translation from './translation';
import WhatsAppSender from '../../../WhatsAppTemplateComponents/WhatsAppSender';
import WhatsAppHistory from '../../../WhatsAppTemplateComponents/WhatsAppHistory';

// new redux
import store from "../../../../store";
import { Provider } from "react-redux";
// proposal
import Proposal from "../../../../containers/Orders/Orders/Life/Proposal";

const EnhancedCoveragesTab = withRef(CoveragesTab);
const EnhancedDetailsTab = withRef(DetailsTab);

//Constants
const TabPane = Tabs.TabPane;

class LifeTabs extends Component {
    resetTabs = () => {

    };

    notifyChanges = () => {
        const { modified, setModified } = this.props;
        if (!modified)
            setModified(true);
    };

    getChanges = () => {
        const changes = {};
        return changes;
    };


    render() {
        const { data, configurations, extraContent, handleCompanySearch, handleChange, errors,tabValue,superiorTabValue} = this.props;

        return (
            <Tabs tabPosition="top" type="card" tabBarExtraContent={extraContent} onChange={this.props.changeSuperiorTab} activeKey={superiorTabValue}>
                <TabPane tab={Translation.applicationDataTabPaneLabel} key="1">
                    <Tabs tabPosition="left" activeKey={tabValue} onChange={this.props.actualTab}>
                        <TabPane tab={Translation.initialTabLabel} key={"initial"}>
                            <InitialTab
                                type="single"
                                notifyChanges={this.notifyChanges}
                                handleChange={handleChange("initial")}
                                configurations={configurations.initial}
                                searchCompany={handleCompanySearch("initial")}
                                OrderErrors={errors}
                            />
                        </TabPane>
                        <TabPane tab={Translation.generalTabLabel} key="general">
                            <GeneralTab
                                configurations={configurations.general}
                                handleChange={handleChange("general")}
                                OrderErrors={errors} />
                        </TabPane>
                        <TabPane tab={Translation.coveragesTabLabel} key="coverages">
                            <EnhancedCoveragesTab
                                handleChange={handleChange("coverages")}
                                configurations={configurations.coverages}
                                notifyChanges={this.notifyChanges}
                                OrderErrors={errors} />
                        </TabPane>
                        <TabPane tab={Translation.coveragesDetailsTabLabel} key="details">
                            <EnhancedDetailsTab
                                handleChange={handleChange("details")}
                                configurations={configurations.details}
                                notifyChanges={this.notifyChanges}
                                OrderErrors={errors} />
                        </TabPane>
                        <TabPane tab={Translation.customerTabLabel} key={"customer"}>
                            <InsuredTab
                                notifyChanges={this.notifyChanges}
                                handleChange={handleChange("customer")}
                                configurations={configurations.customer}
                                searchCompany={handleCompanySearch("customer")}
                                OrderErrors={errors} />
                        </TabPane>
                        <TabPane tab={Translation.insuredLivesTabLabel} key={"insured"}>
                            <InsuredLivesTab
                                notifyChanges={this.notifyChanges}
                                handleChange={handleChange("insured")}
                                configurations={configurations.insured}
                                OrderErrors={errors} />
                        </TabPane>
                        <TabPane tab={Translation.uploadTabLabel} key="files">
                            <UploadTab
                                configurations={configurations.files}
                                type={data.type}
                                code={data.code}
                                handleChange={handleChange("files")}
                                fileExtensions={"xls, xlsx, csv"}
                                OrderErrors={errors} />
                        </TabPane>
                    </Tabs>
                </TabPane>
                <TabPane tab={Translation.whatsAppLabel} key="2">
                    <Tabs tabPosition="left" activeKey={tabValue} onChange={this.props.actualTab}>
                        <TabPane tab={'chat'} key={"initial"}>
                            <WhatsAppSender code={data.code} phoneNumber={configurations.initial.phoneNumber} activeKey={tabValue} />                            
                        </TabPane>
                        <TabPane tab={'Histórico de mensagens'} key={"history"}>
                            <WhatsAppHistory code={data.code} activeKey={tabValue} />
                        </TabPane>
                    </Tabs>
                </TabPane>
                <TabPane tab={Translation.lifeProposalTabLabel} key="3">
                    <Provider store={store}>
                        <Proposal code={data.code} />
                    </Provider>
                </TabPane>
            </Tabs>
        );
    }
}

export default LifeTabs;