import React, { useState, useEffect } from "react";
//router
import { useHistory, useParams } from "react-router-dom";
//redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPolicyData,
  setCurrentTab,
} from "../../../../../store/slices/policy";
import { fetchCustomerData } from "../../../../../store/slices/customer";
import {
  setErrors,
  fetchInvoice,
  setInvoiceData,
  createNewInvoice,
  newInvoiceUploadFile,
  changeInvoiceData,
  updateInvoiceData,
  removeInvoiceFile,
  sendManualEmail,
  sendInvoiceByEmail,
  setInvoiceDataIsChanged,
  deleteInvoice,
  setEnableChange,
  markAsDelayedInvoice,
  markAsPaidInvoice,
} from "../../../../../store/slices/invoice";
//components
import { Col, Row, Modal, Card, Spin, Button } from "antd";
import { LabelField, FormattedValue } from "components";
import InvoiceForm from "../../../../../components/Customers/Policy/InvoiceForm";
//helpers
import { getFormattedDate, monthFormat, dateFormat, clearError } from "helpers";
//translation
import translation from "./translation";
import { useTranslation } from "react-i18next";
//constants
import { taxByProduct } from "../../../../../constants";
import selections from "constants/selections";

import "./styles.less";

export default ({ children }) => {
  const [modalVisible, setModalVisible] = useState(true);
  const {
    customer: { registerData },
    invoice: { invoice, invoiceDataIsChanged, errors, enableChange },
    policy: { policy },
    common: { isLoading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams();
  const { invoice: invoiceId } = params;
  const { t: Translation } = useTranslation();

  const uploadUrlBase = `${window._env_.API_GATEWAY_URL}/policy/v1/${params.policy}/invoice/${invoiceId}/upload`;

  const getTaxValue = (type) => {
    return type ? taxByProduct[type.product] : 0;
  };

  const handleRemoveFile = (fileType) => {
    const modalTitle = {
      invoice: "da fatura",
      payment: "do boleto",
    };
    const modal = Modal.confirm({
      maskClosable: true,
      title: `Tem certeza que deseja remover o arquivo ${modalTitle[fileType]}?`,
      onOk: onOk,
      onCancel: () => modal.destroy(),
      okText: "Confirmar",
      cancelText: "Cancelar",
    });
    function onOk() {
      modal.destroy();
      dispatch(
        removeInvoiceFile(
          fileType === "payment" ? "payment-slip" : "",
          params.policy,
          invoiceId
        )
      );
    }
  };

  const handleUploadSuccess = (fileType) => {
    dispatch(fetchInvoice(params.policy, invoiceId));
  };

  const getValue = (entity, property) => {
    return entity ? entity[property] : null;
  };

  const getInsurerLabel = (insurer) => {
    if (policy.type) {
      const insurerList =
        selections.insurerByProduct[getValue(policy.type, "product")];
      const selectedInsurer =
        insurerList && insurerList.find((i) => i.value === insurer);
      return selectedInsurer ? selectedInsurer.label : "";
    }
    return "";
  };

  const confirmCloseModal = () => {
    setModalVisible(false);
    dispatch(fetchPolicyData(params.id, params.policy));
    dispatch(setInvoiceDataIsChanged(false));
    return history.push(`/crm/customers/${params.id}/policy/${params.policy}`);
  };

  const onCloseModal = () => {
    if (invoiceDataIsChanged) {
      const modal = Modal.confirm({
        maskClosable: true,
        title:
          "Ao sair sem salvar perderá suas alterações. Confirma que deseja sair?",
        onOk: confirmCloseModal,
        onCancel: () => modal.destroy(),
        okText: "Confirmar",
        cancelText: "Cancelar",
      });
    } else {
      confirmCloseModal();
    }
  };

  const formatValues = (name, value) => {
    if (name === "premium") {
      return null;
    }
    if (name === "netPremium") {
      value = value.floatValue;
      const tax = policy.type ? getTaxValue(policy.type) : 0;
      const minP = value ? value + (value * tax) / 100 : 0;
      return {
        netPremium: value,
        premium: minP,
        tax,
      };
    }

    if (name === "merchandiseTotalBoardedAmount") {
      value = value.floatValue;
    }

    if (name === "recurrenceMonth") {
      return {
        recurrenceMonth: getFormattedDate(value, monthFormat),
      };
    }

    if (name === "dueDate" || name === "startAt" || name === "finishAt") {
      return {
        [name]: getFormattedDate(value, dateFormat),
      };
    }

    return { [name]: value };
  };

  const onChange = (property) => (event) => {
    dispatch(setErrors(clearError(property, errors)));
    let value;
    if (event) {
      event.hasOwnProperty("target")
        ? (value = event.target.value)
        : (value = event);
    } else {
      value = event;
    }

    let data = formatValues(property, value);

    dispatch(changeInvoiceData(data));
  };

  const onSubmit = () => {
    if (invoiceId === "new") {
      dispatch(createNewInvoice(params.policy)).then((response) => {
        dispatch(fetchInvoice(params.policy, response.data.id));
        history.push(
          `/crm/customers/${params.id}/policy/${params.policy}/invoice/${response.data.id}`
        );
      });
    } else {
      dispatch(updateInvoiceData(params.policy, invoiceId)).then(() => {
        dispatch(setEnableChange(false));
        dispatch(setInvoiceDataIsChanged(false));
        dispatch(fetchPolicyData(params.id, params.policy));
      });
    }
  };

  const onDelete = () => {
    const modal = Modal.confirm({
      maskClosable: true,
      className: "delete-invoice-confirm",
      content: (
        <>
          <Row className="gx-mb-3">
            {Translation("invoice.deleteModalLabel")}
          </Row>
          <Row type="flex" justify="end">
            <Button onClick={() => modal.destroy()}>
              {Translation("invoice.deleteButtonCancelLabel")}
            </Button>
            <Button onClick={onOk} type="primary">
              {Translation("invoice.deleteButtonConfirmLabel")}
            </Button>
          </Row>
        </>
      ),
    });

    function onOk() {
      dispatch(deleteInvoice(params.policy, invoiceId))
        .then(() => {
          redirectToInvoices();
        })
        .catch(() => {
          handleDeleteError();
        });
    }

    function redirectToInvoices() {
      dispatch(fetchPolicyData(params.id, params.policy));
      dispatch(setCurrentTab(3));
      modal.destroy();
      history.push(`/crm/customers/${params.id}/policy/${params.policy}`);
    }

    function handleDeleteError(error) {
      modal.destroy();
      return Promise.reject(error);
    }
  };

  const onSendInvoice = () => {
    dispatch(sendInvoiceByEmail(params.policy, invoiceId));
  };

  const onManualSendInvoice = () => {
    dispatch(sendManualEmail(params.policy, invoiceId));
  };

  const handleEnableChange = (value) => {
    dispatch(setEnableChange(value));
  };

  const onMarkAsDelayed = (value) => {
    dispatch(markAsDelayedInvoice(params.policy, invoiceId));
  };

  const onMarkAsPaid = (value) => {
    dispatch(markAsPaidInvoice(params?.policy, invoiceId, value));
  };

  const handleUploadNewInvoiceFiles = (options, type) => {
    const { file, onSuccess, onError, onProgress } = options;

    if (invoiceId === "new") {
      dispatch(createNewInvoice(params.policy)).then((response) => {
        const { id } = response?.data;

        const _baseURL = `${window._env_.API_GATEWAY_URL}/policy/v1/${params.policy}/invoice/${id}/upload`;
        if (id) {
          history.push(
            `/crm/customers/${params.id}/policy/${params.policy}/invoice/${id}`
          );
          dispatch(
            newInvoiceUploadFile(
              type === "invoice" ? `${_baseURL}` : `${_baseURL}/payment-slip`,
              file,
              onSuccess,
              onError,
              onProgress
            )
          ).then(() => {
            dispatch(fetchInvoice(params.policy, id));
          });
        }
      });
    }
  };

  useEffect(() => {
    dispatch(setErrors([]));
    dispatch(fetchCustomerData(params.id));
    dispatch(fetchPolicyData(params.id, params.policy));
    if (invoiceId === "new") {
      dispatch(setInvoiceData({}));
      dispatch(setEnableChange(true));
    } else {
      dispatch(fetchInvoice(params.policy, invoiceId)).then(() => {
        dispatch(setInvoiceDataIsChanged(false));
      });
    }
  }, []);

  useEffect(() => {
    return () => {
      handleEnableChange(false);
      dispatch(setCurrentTab(3));
    };
  }, []);

  return (
    <>
      <Modal
        width={960}
        footer={null}
        centered={true}
        visible={modalVisible}
        onCancel={onCloseModal}
        wrapClassName="policy-modal-container"
      >
        <Spin spinning={isLoading}>
          <Card className="gx-mt-5">
            <Row>
              <Col xs={24} md={12}>
                <LabelField label={translation.customerLabel}>
                  <span>{registerData.companyName}</span>
                </LabelField>
              </Col>
              <Col xs={24} md={12}>
                <LabelField label={translation.documentNumberLabel}>
                  <FormattedValue
                    mask="_"
                    displayType="text"
                    value={registerData.documentNumber}
                    format={"COMPANY"}
                  />
                </LabelField>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <LabelField label={translation.policyNumberLabel}>
                  <span>{policy.policyNumber}</span>
                </LabelField>
              </Col>
              <Col xs={24} md={12}>
                <LabelField label={translation.validityLabel}>
                  <span className="gx-pr-1">
                    {getFormattedDate(policy.initialTermAt)}
                  </span>
                  a
                  <span className="gx-pl-1">
                    {getFormattedDate(policy.finalTermAt)}
                  </span>
                </LabelField>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12}>
                <LabelField label={translation.insurerLabel}>
                  <span>{getInsurerLabel(policy.insurer)}</span>
                </LabelField>
              </Col>
            </Row>
            <InvoiceForm
              policy={policy}
              invoice={{
                ...invoice,
                tax: getTaxValue(policy.type) || 0,
                productType: policy.type || null,
              }}
              errors={errors}
              uploadUrlBase={uploadUrlBase}
              enableChange={enableChange}
              onSaveNewInvoiceFiles={handleUploadNewInvoiceFiles}
              setEnableChange={handleEnableChange}
              onChange={onChange}
              onSubmit={onSubmit}
              onSendInvoice={onSendInvoice}
              handleRemoveFile={handleRemoveFile}
              handleUploadSuccess={handleUploadSuccess}
              onDelete={onDelete}
              onManualSendInvoice={onManualSendInvoice}
              onMarkAsDelayed={onMarkAsDelayed}
              onMarkAsPaid={onMarkAsPaid}
            />
          </Card>
        </Spin>
      </Modal>
      {children}
    </>
  );
};
