import moment from "moment";

function formatDate(date) {
  return date ? moment(date) : null;
}

export default function (serverData, prevConfig) {

    function updateInitial() {
        const data = serverData.initial;
        const prevState = prevConfig.initial;
        return {
            name: data.name || prevState.name,
            email: data.email || prevState.email,
            phoneNumber: data.phoneNumber || prevState.phoneNumber,
            phoneType: data.phoneType || prevState.phoneType,
            receiveWhatsAppMessage: data.receiveWhatsAppMessage || prevState.receiveWhatsAppMessage,
            contactType: data.contactType || prevState.contactType,
            documentNumber: data.documentNumber || prevState.documentNumber,
            hasDocumentNumber: data.hasDocumentNumber || prevState.hasDocumentNumber,
        }
    }

    function updateBondType() {
        const data = serverData.bond || prevConfig.bond;
        const prevState = prevConfig.bond;
        return {
            type: data.type || prevState.type,
            jsonTerm: data.jsonTerm || prevState.jsonTerm,
        }
    }

    function updateSuretyData() {
        const data = serverData.term || prevConfig.term;
        const prevState = prevConfig.term;
        return {
            type: data.type || prevState.type,
            daysBetweenTerm: data.daysBetweenTerm || prevState.daysBetweenTerm,
            depositAppeal: data.depositAppeal || prevState.depositAppeal,
            initialTermAt: formatDate(data.initialTermAt) || formatDate(prevState.initialTermAt),
            finalTermAt: formatDate(data.finalTermAt) || formatDate(prevState.finalTermAt),
            processAmount: data.processAmount || prevState.processAmount,
            suretyAmount: data.suretyAmount || prevState.suretyAmount,
            contractType: data.contractType || prevState.contractType,
            contractAmount: data.contractAmount || prevState.contractAmount,
            labourCoverage: data.labourCoverage || prevState.labourCoverage,
            penaltyCoverage: data.penaltyCoverage || prevState.penaltyCoverage,
            suretyPercentage: data.suretyPercentage || prevState.suretyPercentage, 
            appealType: data.appealType || prevState.appealType,
            liabilityInsurance : data.liabilityInsurance || prevState.liabilityInsurance,    
            engineeringRiskInsurance: data.engineeringRiskInsurance || prevState.engineeringRiskInsurance,
            groupLifeInsurance: data.groupLifeInsurance || prevState.groupLifeInsurance
        }
    }

    function updateObligee() {
        const data = serverData.obligee || prevConfig.obligee;
        const prevState = prevConfig.obligee;
        return {
            documentNumber: data.documentNumber || prevState.documentNumber,
            type: data.type || prevState.type,
            name: data.name || prevState.name,
            zipCode: data.zipCode || prevState.zipCode,
            address: data.address || prevState.address,
            number: data.number || prevState.number,
            neighborhood: data.neighborhood || prevState.neighborhood,
            state: data.state || prevState.state,
            city: data.city || prevState.city,
            additionalData: data.additionalData || prevState.additionalData,
        }
    }

    function updateDetails() {
        const data = serverData.detail || prevConfig.detail;
        const prevState = prevConfig.detail;
        return {
            additionalData: data.additionalData || prevState.additionalData,
            actionType: data.actionType || prevState.actionType,
            contractDate: data.contractDate || prevState.contractDate,
            contractNumber: data.contractNumber || prevState.contractNumber,
            obligation: data.obligation || prevState.obligation,
            installment: data.installment || prevState.installment,
            bidNumber: data.bidNumber || prevState.bidNumber,
            bidDate: data.bidDate || prevState.bidDate,
            processNumber: data.processNumber || prevState.processNumber,
            tribunal: data.tribunal || prevState.tribunal,
            court: data.court || prevState.court,
            proposalAccepted: data.proposalAccepted || prevState.proposalAccepted,
            receiveDraftContract: data.receiveDraftContract || prevState.receiveDraftContract,
        }
    }

    function updatePayment() {
        const data = serverData.payments;
        const prevState = prevConfig.payments.payment;
        return {
            payment: data || prevState
        }
    }

    function updateSimulations() {
        const data = serverData.simulations;
        const prevState = prevConfig.simulations;
        return data || prevState;       
    }

    function updateQuotations() {
        const data = serverData.quotations;
        const prevState = prevConfig.quotations;
        return data || prevState;       
    }

    function updateHasExpressEmission() {
        const data = serverData.hasExpressEmission;
        const prevState = prevConfig.hasExpressEmission;
        return data || prevState;       
    }

    function updateChosenInsurer() {
        const data = serverData.chosenInsurer;
        const prevState = prevConfig.chosenInsurer;
        return data || prevState;       
    }

    function filesDetails() {
        const data = serverData.files;
        const prevState = prevConfig.files;
        return {
            files: data || prevState,
        }
    }

    function minuteData() {
        const data = serverData.minute;
        const prevState = prevConfig.minute;
        return {
            minute: data || prevState,
        }
    }

    function policyData() {
        const data = serverData.policy;
        const prevState = prevConfig.policy;
        return {
            policy: data || prevState,
        }
    }

    function paymentSlipsData() {
        const data = serverData.paymentSlips;
        const prevState = prevConfig.paymentSlips;
        return {
            paymentSlips: data || prevState,
        }
    }

    return {
        initial: updateInitial(),
        bond: updateBondType(),
        term: updateSuretyData(),
        obligee: updateObligee(),
        payments: updatePayment(),
        detail: updateDetails(),
        files: filesDetails(),
        minute: minuteData(),
        simulations: updateSimulations(),
        hasExpressEmission: updateHasExpressEmission(),
        chosenInsurer: updateChosenInsurer(),
        quotations: updateQuotations(),
        policy: policyData(),
        paymentSlips: paymentSlipsData(),
    };
}
