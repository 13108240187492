import React from "react";
// translation
import { useTranslation } from "react-i18next";
// antd
import {
  CheckCircleFilled,
  WarningFilled,
  CloseCircleFilled,
  MinusCircleFilled,
  ExclamationCircleFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
// styles
import "./styles.less";

const StatusLabels = () => {
  const { t: translation } = useTranslation();
  return (
    <div className="status-container">
      <div className="status-label">
        <CheckCircleFilled style={{ color: "#91BF1D" }} />
        <p>{translation("policy.status.current")}</p>
      </div>
      <div className="status-label">
        <WarningFilled style={{ color: "#E9DC21" }} />
        <p>{translation("policy.status.thirtyDaysToExpire")}</p>
      </div>
      <div className="status-label">
        <PauseCircleFilled style={{ color: "#E9DC21" }} />
        <p>{translation("policy.status.paused")}</p>
      </div>
      <div className="status-label">
        <ExclamationCircleFilled style={{ color: "#EB6662" }} />
        <p>{translation("policy.status.overdue")}</p>
      </div>
      <div className="status-label">
        <MinusCircleFilled style={{ color: "#EB6662" }} />
        <p>{translation("policy.status.cancelled")}</p>
      </div>
      <div className="status-label">
        <CloseCircleFilled style={{ color: "#EB6662" }} />
        <p>{translation("policy.status.invoiceOpened")}</p>
      </div>
    </div>
  );
};

export default StatusLabels;
