import React from "react";
import { useHistory } from "react-router-dom";
//components
import { Button, Col, Row, Select, DatePicker, Modal, Input } from "antd";
import {
  CheckCircleFilled,
  WarningFilled,
  CloseCircleFilled,
  MinusCircleFilled,
  ExclamationCircleFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
import {
  FormField,
  LngInput,
  CurrencyInput,
  UploadFile,
  Switch,
} from "components";
//styles
import "./styles.less";
//helpers
import {
  dateFormat,
  uploadConfig,
  frontEndDate,
  downloadFile,
  formatDateTime,
  getErrorMessage,
  inputMasks,
} from "helpers";
import moment from "moment";
//translation
import { useTranslation } from "react-i18next";
import translation from "./translation";
//constants
import { orderProductTypes, taxByProduct } from "../../../../constants";
import selections from "constants/selections";
import { useDispatch } from "react-redux";

import { fetchPolicyList, deletePolicy } from "../../../../store/slices/policy";
import MaskedInput from "../../../MaskedInput";

const config = {
  insurers: Object.entries(selections.taxesInsurer),
  products: Object.entries(selections.orderProducts),
  partners: Object.entries(selections.bussinessPartners),
  registerApplicationTypes: selections.registerApplicationTypes,
};
const { Option } = Select;

export default (props) => {
  const {
    policy,
    getValue,
    savePolicyData,
    params,
    types,
    errors,
    sales,
    presales,
    updatedBy,
    enableChange,
    setEnableChange,
    onChange,
    onSendByEmail,
    onSuspendInvoice,
    onCancelSuspendInvoice,
    onUploadFile,
    onRemoveFile,
    onFileChange,
  } = props;

  const uploadFileUrl = `${window._env_.API_GATEWAY_URL}/policy/v1/${params.id}/${params.policy}/upload-file`;

  const history = useHistory();

  const isPolicySuspened = policy?.invoicesSuspendedAt ? true : false;

  const getTaxValue = (type) => {
    return type ? taxByProduct[type.product] : 0;
  };

  const getMinimumPremiumLabel = (type) => {
    if (type) {
      return type.product === orderProductTypes.CARGO_OPEN_POLICY
        ? translation.policyNetMinimumPremiumLabel
        : translation.policyNetPremiumLabel;
    }
    return null;
  };

  const dispatch = useDispatch();
  const { t: Translation } = useTranslation();

  const handleDeletePolicy = () => {
    const modal = Modal.confirm({
      maskClosable: true,
      className: "remove-policy-comfirm",
      content: (
        <>
          <Row className="gx-mb-3">
            {Translation("customers.policyDeleteConfirmLabel")}
          </Row>
          <Row type="flex" justify="end">
            <Button onClick={() => modal.destroy()}>
              {Translation("customers.policyDeleteCancelButtonLabel")}
            </Button>
            <Button onClick={onOk} type="primary">
              {Translation("customers.policyDeleteConfirmButtonLabel")}
            </Button>
          </Row>
        </>
      ),
    });
    function onOk() {
      dispatch(deletePolicy(params.id, params.policy))
        .catch(destroyModal)
        .then(redirectToPolicies);
    }

    function redirectToPolicies() {
      dispatch(fetchPolicyList({ code: params.id, pageSize: 15 }));
      modal.destroy();
      history.push(`/crm/customers/${params.id}`);
    }

    function destroyModal(error) {
      modal.destroy();
      return Promise.reject(error);
    }
  };

  const getProductPolicyTypes = () => {
    if (types.length > 0 && policy.type) {
      const policyTypes = [
        ...types.filter((item) => item.product === policy.type.product),
      ];
      return policyTypes.sort((a, b) =>
        a.description.localeCompare(b.description)
      );
    }
    return [];
  };

  const getProductInsurers = () => {
    return selections.insurerByProduct[getValue(policy.type, "product")];
  };

  const handleRemove = () => {
    return onRemoveFile && onRemoveFile();
  };

  const policyFileChange = () => {
    return onFileChange && onFileChange();
  };

  const handleUploadSuccess = (file) => {
    return onUploadFile && onUploadFile(file);
  };

  const handleSendPolicyByEmail = () => {
    return onSendByEmail && onSendByEmail();
  };

  const handleSuspendInvoices = () => {
    return onSuspendInvoice && onSuspendInvoice();
  };

  const handleCancelSuspendInvoices = () => {
    return onCancelSuspendInvoice && onCancelSuspendInvoice();
  };

  const redirectPolicyNotificationModal = () => {
    history.push(
      `/crm/customers/${params.id}/policy/${params.policy}/notification-history`
    );
  };

  const getDisableValue = () =>
    params.policy === "newpolicy" ? false : !enableChange;

  const getStatusLabel = ({
    finalTermAt,
    cancelledAt,
    invoicesSuspendedAt,
    invoices,
  }) => {
    if (cancelledAt) {
      // cancelled
      return (
        <div className="status-label">
          <MinusCircleFilled style={{ color: "#EB6662" }} />
          <p>
            {Translation("policy.cancelledAtLabel")} -&nbsp;
            {formatDateTime(policy.cancelledAt)}
          </p>
        </div>
      );
    }
    if (invoicesSuspendedAt) {
      // suspended
      return (
        <div className="status-label">
          <PauseCircleFilled style={{ color: "#E9DC21" }} />
          <p>{Translation("policy.policyData.paused")}</p>
        </div>
      );
    }
    if (invoices && invoices.length > 0) {
      // invoice opened
      const openInvoices = invoices.filter(
        (invoice) => invoice.status === "DELAYED"
      );
      if (openInvoices && openInvoices.length > 0) {
        return (
          <div className="status-label">
            <CloseCircleFilled style={{ color: "#EB6662" }} />
            <p>{Translation("policy.status.invoiceOpened")}</p>
          </div>
        );
      }
    }
    if (moment(finalTermAt) - moment() < 0) {
      // overdue
      return (
        <div className="status-label">
          <ExclamationCircleFilled style={{ color: "#EB6662" }} />
          <p>{Translation("policy.policyData.overdue")}</p>
        </div>
      );
    }
    if (moment(finalTermAt).diff(moment(), "days") <= 30) {
      // 30 days to expire
      return (
        <div className="status-label">
          <WarningFilled style={{ color: "#E9DC21" }} />
          <p>{Translation("policy.status.thirtyDaysToExpire")}</p>
        </div>
      );
    }
    if (moment(finalTermAt) - moment() >= 0) {
      // active
      return (
        <div className="status-label">
          <CheckCircleFilled style={{ color: "#91BF1D" }} />
          <p>{Translation("policy.policyData.current")}</p>
        </div>
      );
    }
    return null;
  };

  const getProductLabel = () => {
    if (getValue(policy, "type")) {
      const productConfig = selections.orderProducts.find(
        (item) => item.value == getValue(policy.type, "product")
      );
      return <div className="product-tag">{productConfig?.label || null}</div>;
    }
  };

  return (
    <section>
      {params.policy !== "newpolicy" && (
        <Row
          type="flex"
          align="middle"
          gutter={24}
          className="gx-mt-2 gx-mb-2 gx-mr-0"
        >
          <Col xs={24} md={12} className="gx-d-flex gx-justify-content-start ">
            {getProductLabel()}
            {getStatusLabel(policy)}
          </Col>
        </Row>
      )}

      <Row
        type="flex"
        align="middle"
        gutter={8}
        className="gx-mt-4 gx-d-flex gx-justify-content-start"
      >
        <Col xs={24} className="gx-d-flex gx-justify-content-start">
          <h5>
            <strong>{Translation("policy.policyData.title")}</strong>
          </h5>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className=" gx-d-flex gx-justify-content-start ">
          {translation.policyNumberLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("policyNumber", errors)}>
            <LngInput
              disabled={getDisableValue()}
              onChange={onChange("policyNumber")}
              value={getValue(policy, "policyNumber")}
              placeholder={translation.policyNumberPlaceholder}
            />
          </FormField>
        </Col>
        <Col
          xs={24}
          md={4}
          className="gx-pl-4 gx-d-flex gx-justify-content-start "
        >
          {Translation("policy.emissionDateLabel")}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("issuedAt", errors)}>
            <DatePicker
              disabled={getDisableValue()}
              className="gx-w-100"
              onChange={onChange("issuedAt")}
              allowClear={false}
              format={frontEndDate}
              value={
                getValue(policy, "issuedAt")
                  ? moment(getValue(policy, "issuedAt"), dateFormat)
                  : undefined
              }
            />
          </FormField>
        </Col>
      </Row>
      {params.policy === "newpolicy" && (
        <>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
            <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
              {translation.policyProductLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField error={getErrorMessage("type.product", errors)}>
                <Select
                  disabled={getDisableValue()}
                  onChange={onChange("product")}
                  className="gx-w-100"
                  placeholder={translation.policyProductPlaceholder}
                  value={
                    getValue(policy, "type")
                      ? getValue(policy.type, "product")
                      : null
                  }
                >
                  <Option value={null}>
                    {translation.policyProductPlaceholder}
                  </Option>
                  {config.products
                    .sort((a, b) => a[1].label.localeCompare(b[1].label))
                    .map((option, index) => {
                      return (
                        <Option key={index} value={option[1].value}>
                          {option[1].label}
                        </Option>
                      );
                    })}
                </Select>
              </FormField>
            </Col>
          </Row>
        </>
      )}
      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
          {translation.policyInsurerLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("insurer", errors)}>
            <Select
              disabled={getDisableValue()}
              onChange={onChange("insurer")}
              placeholder={translation.policyInsurerPlaceholder}
              className="gx-w-100"
              value={getValue(policy, "insurer")}
            >
              <Option value={null}>
                {translation.policyInsurerPlaceholder}
              </Option>
              {getValue(policy.type, "product")
                ? getProductInsurers() &&
                  getProductInsurers()
                    .sort((a, b) => a.label.localeCompare(b.label))
                    .map((option, index) => {
                      return (
                        <Option key={index} value={option.value}>
                          {option.label}
                        </Option>
                      );
                    })
                : null}
            </Select>
          </FormField>
        </Col>
        <Col
          xs={24}
          md={4}
          className="gx-pl-4 gx-d-flex gx-justify-content-start "
        >
          {translation.policyTypeLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("type.id", errors)}>
            <Select
              disabled={getDisableValue()}
              onChange={onChange("type")}
              className="gx-w-100"
              placeholder={translation.policyTypePlaceholder}
              value={
                getValue(policy, "type") ? getValue(policy.type, "id") : null
              }
            >
              <Option value={null}>{translation.policyTypePlaceholder}</Option>
              {getProductPolicyTypes().map((option, index) => {
                return (
                  <Option key={index} value={option.id}>
                    {option.description}
                  </Option>
                );
              })}
            </Select>
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
          {translation.policyValidityLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("initialTermAt", errors)}>
            <DatePicker
              disabled={getDisableValue()}
              className="gx-w-100"
              onChange={onChange("initialTermAt")}
              allowClear={false}
              format={frontEndDate}
              value={
                getValue(policy, "initialTermAt")
                  ? moment(getValue(policy, "initialTermAt"), dateFormat)
                  : undefined
              }
            />
          </FormField>
        </Col>
        <Col
          xs={24}
          md={4}
          className="gx-pl-4 gx-d-flex gx-justify-content-start "
        >
          {translation.policyExpirationLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("finalTermAt", errors)}>
            <DatePicker
              disabled={getDisableValue()}
              allowClear={false}
              onChange={onChange("finalTermAt")}
              format={frontEndDate}
              className="gx-w-100"
              value={
                getValue(policy, "finalTermAt")
                  ? moment(getValue(policy, "finalTermAt"), dateFormat)
                  : undefined
              }
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        {policy.type && getValue(policy.type, "product") !== "SURETY" ? (
          <>
            <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
              {getMinimumPremiumLabel(policy.type) ||
                translation.policyNetPremiumLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField error={getErrorMessage("netPremium", errors)}>
                <CurrencyInput
                  disabled={getDisableValue()}
                  value={getValue(policy, "netPremium")}
                  placeholder={translation.policyNetPremiumPlaceholder}
                  onChange={onChange("netPremium")}
                />
              </FormField>
            </Col>
          </>
        ) : (
          <>
            <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
              {translation.policyGrossPremiumLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField gx-m error={getErrorMessage("premium", errors)}>
                <CurrencyInput
                  disabled={getDisableValue()}
                  value={getValue(policy, "netPremium")}
                  onChange={onChange("netPremium")}
                  placeholder={translation.policyGrossPremiumPlaceholder}
                />
              </FormField>
            </Col>
          </>
        )}
      </Row>

      {policy.type && getValue(policy.type, "product") !== "SURETY" && (
        <>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
            <Col
              xs={24}
              md={4}
              className=" gx-d-flex gx-justify-content-start "
            >
              {translation.policyIOFLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField error={getErrorMessage("tax", errors)}>
                <CurrencyInput
                  disabled={getDisableValue()}
                  value={getTaxValue(policy.type)}
                  suffix="%"
                  prefix=""
                  readOnly={true}
                />
              </FormField>
            </Col>
            <Col
              xs={24}
              md={4}
              className="gx-pl-4 gx-d-flex gx-justify-content-start "
            >
              {translation.policyMinGrossPremiumLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField gx-m error={getErrorMessage("premium", errors)}>
                <CurrencyInput
                  disabled={getDisableValue()}
                  readOnly={true}
                  value={policy.premium}
                  placeholder={translation.policyGrossPremiumPlaceholder}
                />
              </FormField>
            </Col>
          </Row>
        </>
      )}

      {policy.type && getValue(policy.type, "product") === "SURETY" && (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
          <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
            {translation.policySuretyAmountLabel}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("suretyAmount", errors)}>
              <CurrencyInput
                disabled={getDisableValue()}
                value={getValue(policy, "suretyAmount")}
                placeholder={translation.policySuretyAmountPlaceholder}
                onChange={onChange("suretyAmount")}
              />
            </FormField>
          </Col>

          <Col
            xs={24}
            md={4}
            className="gx-pl-4 gx-d-flex gx-justify-content-start "
          >
            {translation.policySuretyRateLabel}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("rate", errors)}>
              <CurrencyInput
                disabled={getDisableValue()}
                value={getValue(policy, "rate")}
                placeholder={translation.policySuretyRatePlaceholder}
                onChange={onChange("rate")}
                suffix="%"
                prefix=""
              />
            </FormField>
          </Col>
        </Row>
      )}

      {policy.type && getValue(policy.type, "product") === "CARGO_OPEN_POLICY" && (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
          <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
            {translation.maximumGuaranteeLimitLabel}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("maximumGuaranteeLimit", errors)}>
              <CurrencyInput
                disabled={getDisableValue()}
                value={getValue(policy, "maximumGuaranteeLimit")}
                placeholder={translation.maximumGuaranteeLimitPlaceholder}
                onChange={onChange("maximumGuaranteeLimit")}
              />
            </FormField>
          </Col>

          <Col
            xs={24}
            md={4}
            className="gx-pl-4 gx-d-flex gx-justify-content-start "
          >
            {Translation("policy.rateLabel")}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("rate", errors)}>
              <CurrencyInput
                suffix="%"
                prefix=""
                decimalScale={3}
                className="gx-w-100"
                value={policy.rate}
                onChange={onChange("rate")}
                placeholder={Translation("policy.ratePlaceholder")}
                disabled={getDisableValue()}
              />
            </FormField>
          </Col>
        </Row>
      )}

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
          {translation.policyInstallmentsLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("installmentsToBePaid", errors)}>
            <LngInput
              disabled={getDisableValue()}
              onChange={onChange("installmentsToBePaid")}
              value={getValue(policy, "installmentsToBePaid")}
              placeholder={translation.policyInstallmentsPlaceholder}
            />
          </FormField>
        </Col>
        <Col
          xs={24}
          md={4}
          className="gx-pl-4 gx-d-flex gx-justify-content-start "
        >
          {translation.policyComissionLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("commission", errors)}>
            <CurrencyInput
              disabled={getDisableValue()}
              onChange={onChange("commission")}
              value={getValue(policy, "commission")}
              placeholder={translation.policyComissionPlaceholder}
              suffix="%"
              prefix=""
            />
          </FormField>
        </Col>
      </Row>

      {policy.type && getValue(policy.type, "product") === "LIFE" && (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
          <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
            {translation.movimentLimitDayLabel}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("movementLimitDay", errors)}>
              <LngInput
                disabled={getDisableValue()}
                onChange={onChange("movementLimitDay")}
                value={getValue(policy, "movementLimitDay")}
                placeholder={translation.movimentLimitDayPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      )}

      {policy.type && getValue(policy.type, "product") === "SURETY" ? (
        <>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-">
            <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
              {translation.policyInsuredDocumentNumberLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField
                error={getErrorMessage("insuredDocumentNumber", errors)}
              >
                <MaskedInput
                  disabled={getDisableValue()}
                  mask={inputMasks["COMPANY"]}
                  onChange={onChange("insuredDocumentNumber")}
                  value={getValue(policy, "insuredDocumentNumber")}
                />
              </FormField>
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
            <Col
              xs={24}
              md={4}
              className=" gx-d-flex gx-justify-content-start "
            >
              {translation.policyInsuredNameLabel}
            </Col>
            <Col xs={24} md={20}>
              <FormField error={getErrorMessage("insuredName", errors)}>
                <LngInput
                  disabled={getDisableValue()}
                  onChange={onChange("insuredName")}
                  value={getValue(policy, "insuredName")}
                />
              </FormField>
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
            <Col
              xs={24}
              md={4}
              className=" gx-d-flex gx-justify-content-start "
            >
              {translation.policyContractNumberLabel}
            </Col>
            <Col xs={24} md={8}>
              <FormField error={getErrorMessage("contractNumber", errors)}>
                <LngInput
                  disabled={getDisableValue()}
                  onChange={onChange("contractNumber")}
                  value={getValue(policy, "contractNumber")}
                />
              </FormField>
            </Col>
          </Row>
        </>
      ) : null}

      {policy.type &&
      getValue(policy.type, "product") === "CARGO_OPEN_POLICY" ? (
        <>
          <Row
            type="flex"
            align="middle"
            gutter={8}
            className="gx-mt-3 gx-d-flex gx-justify-content-start"
          >
            <Col xs={24} className="gx-d-flex gx-justify-content-start">
              <h5>
                <strong>
                  {Translation("policy.registerResponsible.title")}
                </strong>
              </h5>
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
            <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start">
              <p className="gx-m-0">{Translation("policy.registerName")}</p>
            </Col>
            <Col xs={24} md={8}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("registerName", errors)}
              >
                <Input
                  className="gx-w-100"
                  value={policy.registerName}
                  onChange={onChange("registerName")}
                  disabled={getDisableValue()}
                  placeholder={Translation("policy.registerName.placeholder")}
                />
              </FormField>
            </Col>
            <Col
              xs={24}
              md={4}
              className="gx-pl-4 gx-d-flex gx-justify-content-start"
            >
              <p className="gx-m-0">{Translation("policy.registerEmail")}</p>
            </Col>
            <Col xs={24} md={8}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("registerEmail", errors)}
              >
                <Input
                  className="gx-w-100"
                  type="email"
                  value={policy.registerEmail}
                  onChange={onChange("registerEmail")}
                  disabled={getDisableValue()}
                  placeholder={Translation("policy.registerEmail.placeholder")}
                />
              </FormField>
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
            <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start">
              <p className="gx-m-0">
                {Translation("policy.registerPhoneNumber")}
              </p>
            </Col>
            <Col xs={24} md={8}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("registerPhoneNumber", errors)}
              >
                <MaskedInput
                  className="gx-w-100"
                  mask={inputMasks.mobileNumber}
                  value={policy.registerPhoneNumber}
                  onChange={onChange("registerPhoneNumber")}
                  disabled={getDisableValue()}
                  placeholder={Translation(
                    "policy.registerPhoneNumber.placeholder"
                  )}
                />
              </FormField>
            </Col>
            <Col
              xs={24}
              md={4}
              className="gx-pl-4 gx-d-flex gx-justify-content-start"
            >
              <p className="gx-m-0">
                {Translation("policy.registerApplicationLabel")}
              </p>
            </Col>
            <Col xs={24} md={8}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("registerApplication", errors)}
              >
                <Select
                  className="gx-w-100"
                  onChange={onChange("registerApplication")}
                  value={policy.registerApplication}
                  disabled={getDisableValue()}
                  placeholder={Translation(
                    "policy.registerApplication.placeholder"
                  )}
                >
                  {config.registerApplicationTypes.map((item, index) => (
                    <Option key={index} value={item.value}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </FormField>
            </Col>
          </Row>
        </>
      ) : null}

      <Row
        type="flex"
        align="middle"
        gutter={8}
        className="gx-mt-3 gx-d-flex gx-justify-content-start"
      >
        <Col xs={24} className="gx-d-flex gx-justify-content-start">
          <h5>
            <strong>{Translation("policy.salesData.title")}</strong>
          </h5>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
          {translation.policySalesLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("salesRepresentativeId", errors)}>
            <Select
              disabled={getDisableValue()}
              onChange={onChange("salesRepresentativeId")}
              placeholder={translation.policySalesPlaceholder}
              className="gx-w-100"
              value={getValue(policy, "salesRepresentativeId")}
            >
              <Option value={null}>{translation.policySalesPlaceholder}</Option>
              {sales.map((user, index) => {
                return (
                  <Option key={index} value={user.id}>
                    {user.name}
                  </Option>
                );
              })}
            </Select>
          </FormField>
        </Col>
        <Col
          xs={24}
          md={4}
          className="gx-pl-4 gx-d-flex gx-justify-content-start "
        >
          {translation.policyPreSalesLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField
            error={getErrorMessage("preSalesRepresentativeId", errors)}
          >
            <Select
              disabled={getDisableValue()}
              onChange={onChange("preSalesRepresentativeId")}
              placeholder={translation.policyPreSalesPlaceholder}
              className="gx-w-100"
              value={getValue(policy, "preSalesRepresentativeId")}
            >
              <Option value={null}>
                {translation.policyPreSalesPlaceholder}
              </Option>
              {presales.map((user, index) => {
                return (
                  <Option key={index} value={user.id}>
                    {user.name}
                  </Option>
                );
              })}
            </Select>
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
          {Translation("policy.hasCrossSellingLabel")}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("hasCrossSellingSales", errors)}>
            <Switch
              disabled={getDisableValue()}
              checked={getValue(policy, "hasCrossSellingSales")}
              onChange={onChange("hasCrossSellingSales")}
            />
          </FormField>
        </Col>
      </Row>

      {getValue(policy, "hasCrossSellingSales") ? (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
          <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
            {Translation("policy.crossSellingRepresentativeIdLabel")}
          </Col>
          <Col xs={24} md={8}>
            <FormField
              error={getErrorMessage("crossSellingRepresentativeId", errors)}
            >
              <Select
                disabled={getDisableValue()}
                onChange={onChange("crossSellingRepresentativeId")}
                placeholder={Translation(
                  "policy.crossSellingRepresentativeIdPlaceholder"
                )}
                className="gx-w-100"
                value={getValue(policy, "crossSellingRepresentativeId")}
              >
                <Option value={null}>
                  {Translation(
                    "policy.crossSellingRepresentativeIdPlaceholder"
                  )}
                </Option>
                {sales.map((user, index) => {
                  return (
                    <Option key={index} value={user.id}>
                      {user.name}
                    </Option>
                  );
                })}
              </Select>
            </FormField>
          </Col>
          <Col
            xs={24}
            md={4}
            className="gx-pl-4 gx-d-flex gx-justify-content-start "
          >
            {Translation("policy.crossSellingCommissionLabel")}
          </Col>
          <Col xs={24} md={8}>
            <FormField
              error={getErrorMessage("crossSellingCommission", errors)}
            >
              <CurrencyInput
                disabled={getDisableValue()}
                onChange={onChange("crossSellingCommission")}
                value={getValue(policy, "crossSellingCommission")}
                placeholder={Translation(
                  "policy.crossSellingCommissionPlaceholder"
                )}
                suffix="%"
                prefix=""
              />
            </FormField>
          </Col>
        </Row>
      ) : null}

      <Row type="flex" align="middle" gutter={8} className="gx-mt-2">
        <Col xs={24} md={4} className="gx-d-flex gx-justify-content-start ">
          {translation.policyPartnerLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("businessPartner", errors)}>
            <Select
              disabled={getDisableValue()}
              onChange={onChange("businessPartner")}
              placeholder={translation.policyPartnerPlaceholder}
              className="gx-w-100"
              value={getValue(policy, "businessPartner")}
            >
              <Option value={null}>
                {translation.policyPartnerPlaceholder}
              </Option>
              {config.partners
                .sort((a, b) => a[1].label.localeCompare(b[1].label))
                .map((option, index) => {
                  return (
                    <Option key={index} value={option[0]}>
                      {option[1].label}
                    </Option>
                  );
                })}
            </Select>
          </FormField>
        </Col>
        <Col
          xs={24}
          md={4}
          className="gx-pl-4 gx-d-flex gx-justify-content-start "
        >
          {translation.policyBusinessPartnerComissionLabel}
        </Col>
        <Col xs={24} md={8}>
          <FormField
            error={getErrorMessage("businessPartnerCommission", errors)}
          >
            <CurrencyInput
              disabled={getDisableValue()}
              onChange={onChange("businessPartnerCommission")}
              value={getValue(policy, "businessPartnerCommission")}
              placeholder={
                translation.policyBusinessPartnerComissionPlaceholder
              }
              suffix="%"
              prefix=""
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" className="policyDataUpload">
        <UploadFile
          uploadText={translation.policyUploadButtonLabel}
          downloadText={translation.policyDownloadButtonLabel}
          disabledUpload={params.policy === "newpolicy" || policy.policyFile}
          disabledRemove={params.policy === "newpolicy"}
          hasFile={policy.policyFile}
          uploadProps={{
            ...uploadConfig(policyFileChange),
            showUploadList: false,
            multiple: false,
            action: uploadFileUrl,
            onSuccess: handleUploadSuccess,
          }}
          onRemove={handleRemove}
          onDownload={() =>
            downloadFile(policy.policyFile.url, `policy-${params.policy}`)
          }
        />
        {params.policy !== "newpolicy" && (
          <>
            <Col xs={24} md={12} className="gx-p-0">
              {policy.emailStatus === "SENT" ? (
                <>
                  {translation.policyEmailStatusSent} -&nbsp;
                  {formatDateTime(policy.emailSentAt)}
                </>
              ) : (
                <>{translation.policyEmailStatusPending}</>
              )}
              <Button
                type="link"
                onClick={redirectPolicyNotificationModal}
                className="gx-mb-0 gx-p-0 gx-ml-2"
              >
                {Translation("policy.notificationHistory.buttonLabel")}
              </Button>
            </Col>
          </>
        )}
      </Row>

      {policy.updatedBy && (
        <Row
          type="flex"
          align="middle"
          gutter={24}
          className="gx-mt-1 gx-mb-3 gx-mr-0"
        >
          <Col xs={24} md={24}>
            {`${Translation(
              "policy.updatedByLabel"
            )} ${updatedBy} ${Translation(
              "policy.updatedAtLabel"
            )} ${formatDateTime(policy.updatedAt)}`}
          </Col>
        </Row>
      )}
      <Row type="flex" align="end" gutter={8} className="gx-mt-0 gx-mr-0">
        <Col xs={24} md={12} className="gx-d-flex gx-justify-content-end">
          {!(params.policy === "newpolicy") && !policy.cancelledAt && (
            <>
              <Button
                disabled={!getDisableValue()}
                onClick={() => {
                  handleDeletePolicy();
                }}
                ghost
                type="primary"
              >
                {Translation("customers.policyDeleteButtonLabel")}
              </Button>

              {isPolicySuspened ? (
                <Button
                  disabled={!getDisableValue()}
                  onClick={handleCancelSuspendInvoices}
                  ghost
                  type="primary"
                >
                  {translation.cancelSuspendNewInvoicesLabel}
                </Button>
              ) : (
                <Button
                  disabled={!getDisableValue()}
                  onClick={handleSuspendInvoices}
                  ghost
                  type="primary"
                >
                  {translation.suspendNewInvoicesLabel}
                </Button>
              )}

              <Button
                disabled={!getDisableValue()}
                onClick={handleSendPolicyByEmail}
                ghost
                type="primary"
              >
                {translation.policySendButtonLabel}
              </Button>

              {enableChange ? (
                <Button
                  disabled={getDisableValue()}
                  onClick={() => {
                    savePolicyData();
                  }}
                  type="primary"
                >
                  {translation.policySaveButtonLabel}
                </Button>
              ) : (
                <Button
                  onClick={() => setEnableChange(true)}
                  ghost
                  type="primary"
                >
                  {Translation("policy.enableChangeButton")}
                </Button>
              )}
            </>
          )}
          {!policy.cancelledAt && params.policy === "newpolicy" && (
            <Button
              disabled={getDisableValue()}
              onClick={() => {
                savePolicyData();
              }}
              type="primary"
            >
              {translation.policySaveButtonLabel}
            </Button>
          )}
        </Col>
      </Row>
    </section>
  );
};
