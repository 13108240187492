import React, { useState } from "react";
// antd
import { Card, Row, Col, Tag, Button, Table } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
// translation
import { useTranslation } from "react-i18next";
// components
import CurrencyInput from "../../../../components/CurrencyInput";
// helpers
import { getFormattedDate } from "../../../../helpers";
// styles
import "./styles.less";
// contants
import selections from "../../../../constants/selections";
import { bondTypes } from "../../../../constants";

const LimitCard = ({ limit = {}, isProcessing, handleProcess }) => {
  const [showMore, setShowMore] = useState(true);

  const { t: translation } = useTranslation();

  const columns = [
    {
      title: translation("limits.modalityLabel"),
      dataIndex: "modality",
    },
    {
      title: translation("limits.rateLabel"),
      dataIndex: "rate",
      align: "right",
    },
    {
      title: translation("limits.totalLimitLabel"),
      dataIndex: "totalLimit",
      align: "right",
      render: (value) => <CurrencyInput value={value} displayType="text" />,
    },
    {
      title: translation("limits.limitAvailableLabel"),
      dataIndex: "availableLimit",
      align: "right",
      render: (value) => <CurrencyInput value={value} displayType="text" />,
    },
  ];

  const getInsurerLabel = (insurer) => {
    const insurerLabel = selections.insurerByProduct.SURETY.filter(
      (item) => item.value === insurer
    );
    return insurerLabel && insurerLabel.length > 0 ? insurerLabel[0].label : "";
  };

  const getDataSource = (data) => {
    return (
      data &&
      data.map((item, index) => {
        return {
          ...item,
          key: index,
          modality: `${bondTypes[item.modality] || item.modality} - ${
            item.isPublic
              ? translation("limits.publicLimitLabel")
              : translation("limits.privateLimitLabel")
          }`,
        };
      })
    );
  };

  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Card className="limitCard">
      <Row type="flex" align="middle" justify="space-between">
        <Col
          sm={24}
          md={8}
          onClick={handleShowMore}
          className="gx-d-flex gx-p-0 gx-pointer"
        >
          {showMore ? <UpOutlined /> : <DownOutlined />}
          <span className="gx-px-2">{getInsurerLabel(limit.insurer)}</span>
        </Col>
        <Col sm={24} md={10} className="gx-p-0">
          <Tag
            className="gx-w-100 gx-m-0 gx-py-1 gx-text-center gx-text-uppercase"
            color={limit.status && selections.taxesStatus[limit.status].color}
          >
            {limit.messages && limit.messages.length > 0
              ? limit.messages.map((message, index) => (
                  <p key={index}>{message}</p>
                ))
              : limit.status && selections.taxesStatus[limit.status].label}
          </Tag>
        </Col>
      </Row>

      {showMore && (
        <>
          <hr />
          {limit.processedAt && (
            <Row type="flex" align="middle" className="gx-mb-1">
              {translation("limits.processedAtLabel")}:&nbsp;
              {getFormattedDate(limit.processedAt, "DD/MM/YYYY hh:mm:ss")}
            </Row>
          )}
          {limit.reservedAt && (
            <Row type="flex" align="middle" className="gx-mb-1">
              {translation("limits.reservedAtLabel")}:&nbsp;
              {getFormattedDate(limit.reservedAt)}
            </Row>
          )}
          {limit.expireAt && (
            <Row type="flex" align="middle" className="gx-mb-1">
              {translation("limits.expireAtLabel")}:&nbsp;
              {getFormattedDate(limit.expireAt)}
            </Row>
          )}

          {limit.limits && (
            <Table
              columns={columns}
              className="gx-table-responsive gx-my-3"
              pagination={false}
              dataSource={getDataSource(limit.limits)}
            />
          )}

          <Row type="flex" align="middle" justify="end">
            <Button
              ghost
              type="primary"
              disabled={!limit.insurer}
              loading={isProcessing}
              className="gx-text-uppercase gx-m-0"
              onClick={() => handleProcess(limit.insurer)}
            >
              {translation("limits.processButton")}
            </Button>
          </Row>
        </>
      )}
    </Card>
  );
};

export default LimitCard;
