import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { REQUEST_CARGO_QUOTATIONS, REGISTER_SELECTED_CARGO_QUOTATION } from '../../../appRedux/actionTypes'
import './styles.less'
import translation from './translation'
import OtherQuotationItem from './components/OtherQuotationItem' 
import { Radio, Icon } from 'antd';
import { companyActivitiesList } from '../../../constants' 

const OtherQuotations = ({ code, onChangeTab, superiorTabValue }) => {

   const {loading, bestQuotation,hasAvailable, quotations, error, chosenInsurer} = useSelector(state => state.crm.order.configurations.quotationData)
   const { companyActivity } = useSelector(state => state.crm.order.configurations.general);
   const dispatch = useDispatch()

   useEffect(() => {
      if(superiorTabValue === 'quotationData'){
         dispatch({type:REQUEST_CARGO_QUOTATIONS, code: code})
      }
   },[superiorTabValue])

   const handleQuotationSelection = (chosenInsurer) => {
      dispatch({
         type:REGISTER_SELECTED_CARGO_QUOTATION, 
         payload:{ code:code, insurer:chosenInsurer}
      })
   }
   return (
      <>
         { quotations.length > 0 && !loading ?
            <Radio.Group defaultValue={chosenInsurer} onChange={()=> handleQuotationSelection(bestQuotation.insurer)}>
               <div className="other-quotations-wrapper">
                  {hasAvailable &&
                     <>
                        <div className="main-label">
                           <span>{translation.ourIndicationLabel}</span>
                        </div>
                        <OtherQuotationItem quoteValue={bestQuotation} hasAvailable={hasAvailable} value={bestQuotation.insurer} best={true} isMobile={false}
                           tableOpen={false} hideIcons={false} customBestStyle={false}
                        />
                     </>
                  }
                  <div className="main-label others-label">
                     <span>{ hasAvailable ? translation.otherQuotationLabel : translation.displayedQuotations }</span>
                     <div className="quotations-header">
                        {
                           quotations[0].accident &&
                           <span>Acidente</span>
                        }
                        {
                           quotations[0].theft && companyActivitiesList[companyActivity] !== companyActivitiesList.SHIPPER &&
                           <span>Roubo</span>
                        }
                        {
                           quotations[0].life &&
                           <span>Seguro de Vida Motorista</span>
                        }
                        {
                           quotations[0].additional &&
                           <span>Coberturas adicionais</span>
                        }
                     </div>
                  </div>
                  {quotations.map(quote => {
                     return hasAvailable ?
                        !quote.bestChoice ?
                           <OtherQuotationItem key={quote.insurer} hasAvailable={hasAvailable} quoteValue={quote} best={false} isMobile={false}
                              tableOpen={false} hideIcons={true} onChange={()=> handleQuotationSelection(quote.insurer)}
                           />
                           : null
                        :
                        <OtherQuotationItem key={quote.insurer} hasAvailable={hasAvailable} quoteValue={quote} best={false} isMobile={false}
                           tableOpen={false} hideIcons={true} onChange={()=> handleQuotationSelection(quote.insurer)}
                        />
                  })}
               </div>
            </Radio.Group>
            : !quotations.length  && !loading && !error ?
                  <div className="layout-quotations empty-list">
                     <Icon type="file-search" className="icon" />
                     <div className="error-message-wrapper">
                        <span >{translation.emptyOpenPolicyQuotationsLabel}</span>
                     </div>
                  </div>
            : error && !loading ?
               <div className="layout-quotations quotation-error-layout">
                  <Icon type="frown" className="icon" />
                  <div className="error-message-wrapper">
                     <span>{translation.openPolicyErrorLabel} {error.status}</span>
                     <p>{error.message}</p>
                  </div>
               </div>
               : loading &&
               <div className="layout-quotations">
                  <div className="logo"></div>
                  <div className="loading-tax">
                     <div className="tax"></div>
                     <div className="tax"></div>
                     <div className="tax"></div>
                  </div>
               </div>
         }
      </>
   )
}

export default OtherQuotations