import React, { Component, createRef } from "react";
//Translation
import Translation from "./translation";
//Utils
import { withRef } from "util/forwardRef";
//Components
import { Tabs } from "antd";
import { CargoOpenPolicySimulation, MuTabs } from "../../../../components";

//Custom Components
import CoverageTab from "./tabs/Coverage";
import GeneralTab from "./tabs/General";
import RoutesTab from "./tabs/Routes";
import HistoricTab from "./tabs/Historic";
import OperationalTab from "./tabs/Operational";
import WhatsAppSender from "../../../WhatsAppTemplateComponents/WhatsAppSender";
import WhatsAppHistory from "../../../WhatsAppTemplateComponents/WhatsAppHistory";
import CargoOpenPolicyQuotations from "../../../CargoOpenPolicyQuotations/OtherQuotations";
import CoveragePlans from "./tabs/CoveragePlans";

// containers
import Proposal from "../../../../containers/Orders/Orders/CargoOpenPolicy/Proposal";

//Common Components
import { GoodsTab, InsuredTab, InitialTab } from "../CommonTabs";
import translation from "./translation";
// new redux store
import store from "../../../../store";
import { Provider } from "react-redux";

//Constants
const TabPane = Tabs.TabPane;
//Reference Controlled
const EnhancedGeneralTab = withRef(GeneralTab);
const EnhancedRoutesTab = withRef(RoutesTab);
const EnhancedHistoricTab = withRef(HistoricTab);
const EnhancedOperationalTab = withRef(OperationalTab);

class CargoOpenPolicy extends Component {
  tabRefs = {
    goods: createRef(),
    routes: createRef(),
    accidents: createRef(),
    fleets: createRef(),
  };

  resetTabs = () => {
    for (let tabRef in this.tabRefs) {
      const reference = this.getReference(tabRef);
      if (reference) reference.reset();
    }
  };
  processMonthlyMovement = (data) => {
    // calculate monthly movement
    return (
      data.general.averageAmountPerBoarding *
      data.general.monthlyShipmentQuantity
    );
  };
  notifyChanges = () => {
    const { modified, setModified } = this.props;
    if (!modified) setModified(true);
  };

  getReference = (referenceType) => {
    const tabReference = this.tabRefs[referenceType];
    return tabReference && tabReference.current;
  };

  getChange = (type) => {
    const reference = this.getReference(type);
    return reference ? reference.getChanges() : null;
  };

  getChanges = () => {
    const changes = {};
    for (let tabRef in this.tabRefs) {
      const tabChanges = this.getChange(tabRef);
      if (tabChanges) changes[tabRef] = tabChanges;
    }
    return changes;
  };

  render() {
    const {
      configurations,
      extraContent,
      handleCompanySearch,
      handleChange,
      data,
      errors,
      removeError,
      tabValue,
      superiorTabValue,
    } = this.props;
    return (
      <MuTabs
        product="CARGO_OPEN_POLICY"
        tabPosition="top"
        type="card"
        onChange={this.props.changeSuperiorTab}
        activeKey={superiorTabValue}
      >
        <TabPane tab={Translation.applicationDataTabPaneLabel} key="1">
          <Tabs
            tabPosition="left"
            onChange={this.props.actualTab}
            activeKey={tabValue}
          >
            <TabPane tab={Translation.initialTabLabel} key="initial">
              <InitialTab
                type="multiple"
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("initial")}
                configurations={configurations.initial}
                searchCompany={handleCompanySearch("initial")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.generalTabLabel} key="general">
              <EnhancedGeneralTab
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("generalV2", "CARGO_OPEN_POLICY")}
                configurations={configurations.generalV2}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.routesTabLabel} key="routes">
              <EnhancedRoutesTab
                ref={this.tabRefs.routes}
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("routes")}
                configurations={configurations}
                OrderErrors={errors}
                RemoveError={removeError}
              />
            </TabPane>
            <TabPane tab={Translation.coverageTabLabel} key="coverage">
              {/* <CoverageTab
                handleChange={handleChange("coverage")}
                configurations={configurations.coverage}
                OrderErrors={errors} /> */}
              <Provider store={store}>
                <CoveragePlans code={data.code} plan={configurations.plan} />
              </Provider>
            </TabPane>
            <TabPane tab={Translation.insuredTabLabel} key="insured">
              <InsuredTab
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("insured")}
                configurations={configurations.insured}
                searchCompany={handleCompanySearch("insured")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.goodsTabLabel} key="goods">
              <GoodsTab
                type="multiple"
                ref={this.tabRefs.goods}
                data={configurations.goods}
                notifyChanges={this.notifyChanges}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.operationalTabLabel} key="operational">
              <EnhancedOperationalTab
                ref={this.tabRefs.fleets}
                handleChange={handleChange("operational")}
                configurations={configurations.operational}
                notifyChanges={this.notifyChanges}
                OrderErrors={errors}
                RemoveError={removeError}
              />
            </TabPane>
            <TabPane tab={Translation.historicTabLabel} key="historic">
              <EnhancedHistoricTab
                ref={this.tabRefs.accidents}
                handleChange={handleChange("historic")}
                configurations={configurations.historic}
                notifyChanges={this.notifyChanges}
                OrderErrors={errors}
              />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab={translation.simulationsTabLabel} key="2">
          <CargoOpenPolicySimulation
            simulationData={data.simulation}
            monthlyMovement={this.processMonthlyMovement(data)}
          ></CargoOpenPolicySimulation>
        </TabPane>
        <TabPane
          tab={translation.openPolicyQuotationsLabel}
          key="quotationData"
        >
          <CargoOpenPolicyQuotations
            code={data.code}
            handleChange={handleChange("quotationData")}
            onChangeTab={this.props.actualTab}
            superiorTabValue={superiorTabValue}
          />
        </TabPane>
        <TabPane tab={Translation.whatsAppLabel} key="4">
          <Tabs
            tabPosition="left"
            activeKey={tabValue}
            onChange={this.props.actualTab}
          >
            <TabPane tab={"chat"} key={"initial"}>
              <WhatsAppSender
                code={data.code}
                phoneNumber={configurations.initial.phoneNumber}
                activeKey={tabValue}
              />
            </TabPane>
            <TabPane tab={"Histórico de mensagens"} key={"history"}>
              <WhatsAppHistory code={data.code} activeKey={tabValue} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Proposta" key="5">
          <Provider store={store}>
            <Proposal code={data.code} />
          </Provider>
        </TabPane>
      </MuTabs>
    );
  }
}

export default CargoOpenPolicy;
