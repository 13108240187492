import React, { useState } from "react";
//Translation
import { useTranslation } from "react-i18next";
//Components
import { Button, message } from "antd";
import settings from "config";
import axios from "../../util/axios";
import { PrinterOutlined } from "@ant-design/icons";

export const getUrl = (url) => {
  return settings.apiUrl + url;
};

const PrintReport = (props) => {
  const [loader, setLoader] = useState(false);
  const { t: translation } = useTranslation();
  const {
    id,
    buttonProps,
    reportUrl,
    buttonChildren,
    printIcon = true,
  } = props;

  async function handleRedirect() {
    setLoader(true);
    let apiEndPoint = getUrl("/report/v1");
    const todayDate = new Date().toJSON().slice(0, 10).replace(/-/g, "-");
    await axios
      .get(reportUrl || `${apiEndPoint}/${id}`, { responseType: "arraybuffer" })
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(
          new Blob([response.data])
        );
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `${todayDate}_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        message.error(translation("printReport.downloadErrorMessage"));
      });
  }

  return (
    <Button
      {...buttonProps}
      type="primary"
      onClick={handleRedirect}
      className={`gx-btn-outline-primary ${buttonProps.className}`}
    >
      {loader ? (
        translation("printReport.downloading")
      ) : (
        <>
          {printIcon ? <PrinterOutlined className="gx-mr-2" /> : null}
          {buttonChildren || translation("printReport.downloadLabel")}
        </>
      )}
    </Button>
  );
};

export default PrintReport;
