import {notification} from "antd";

export const displayError = (description, message = "Erro") => {
    notification.error({message, description})
};

export const displayWarning = (description, message = "Atenção") => {
    notification.warning({message, description})
};

export const displaySuccess = (description, message = "Sucesso") => {
    notification.success({message, description})
};
