import React from "react";
// prop types
import PropTypes from "prop-types";
// antd
import { Row, Col } from "antd";
// translation
import { useTranslation } from "react-i18next";
// helpers
import { formatDateTime } from "../../../../helpers";

const Header = ({ data = {} }) => {
  const { t: translation } = useTranslation();
  return (
    <Row type="flex" className="gx-mb-2">
      <Col sm={24} md={8}>
        <h5 className="gx-font-weight-bold">
          {translation("endorsement.header.policyNumberLabel")}
        </h5>
        <h5>{data.policyNumber}</h5>
      </Col>
      <Col sm={24} md={6}>
        <h5 className="gx-font-weight-bold">
          {translation("endorsement.header.createdAtLabel")}
        </h5>
        <h5>{formatDateTime(data.createdAt)}</h5>
      </Col>
      <Col sm={24} md={10}>
        <h5 className="gx-font-weight-bold">
          {translation("endorsement.header.attachedDocumentLabel")}
        </h5>
        <h5>
          {data.policyFile && data.policyFile.url ? (
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={data.policyFile && data.policyFile.url}
            >
              {data.policyFile && data.policyFile.name}
            </a>
          ) : (
            <>{translation("endorsement.header.noDocumentLabel")}</>
          )}
        </h5>
      </Col>
    </Row>
  );
};

Header.propTypes = {
  policy: PropTypes.object,
};

export default Header;
