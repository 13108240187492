import React, { useState } from "react";
// prop types
import PropTypes from "prop-types";
// router
import { useHistory } from "react-router";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Form, Input, Button, Row } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import { changePassword, setErrors } from "../../store/slices/auth";
// components
import CircularProgress from "../../components/CircularProgress";
// helpers
import { getErrorProps, clearError } from "../../helpers/errors";
// logo
import mutuusLogo from "assets/images/logo.png";

const ChangePassword = ({ userId }) => {
  const [passwordData, setPasswordData] = useState({
    currentPassword: "",
    newPassword: ""
  });

  const {
    common: { isLoading },
    auth: { errors },
  } = useSelector((state) => state);

  const { t: translation } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (field, e) => {
    setPasswordData({ ...passwordData, [field]: e.target.value });
    dispatch(setErrors(clearError("filed", errors)));
  };

  const handleUpdatePassword = () => {
    if (!userId) {
      return;
    }
    dispatch(changePassword(userId, passwordData)).then(() => {
      localStorage.removeItem("user_id");
      history.push("/signin");
    });
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="gx-login-container">
          <div className="gx-login-content">
            <div className="gx-login-header">
              <img alt="Logo Mutuus Seguros" src={mutuusLogo} />
            </div>
            <div className="gx-mb-3">
              <h2 className="gx-text-center gx-mb-3">
                {translation("auth.changePassword.title")}
              </h2>
            </div>
            <div className="gx-px-3">
              <Form.Item
                className="gx-mb-0"
                label={translation("auth.changePassword.currentPasswordLabel")}
                {...getErrorProps("currentPassword", errors)}
              >
                <Input.Password
                  placeholder={translation(
                    "auth.changePassword.currentPasswordPlaceholder"
                  )}
                  onChange={(e) => handleChange("currentPassword", e)}
                />
              </Form.Item>
              <Form.Item
                label={translation("auth.changePassword.newPasswordLabel")}
                className="gx-mb-5"
                {...getErrorProps("newPassword", errors)}
              >
                <Input.Password
                  placeholder={translation(
                    "auth.changePassword.newPasswordPlaceholder"
                  )}
                  onChange={(e) => handleChange("newPassword", e)}
                />
              </Form.Item>
              <Row type="flex" justify="center">
                <Button
                  ghost
                  type="primary"
                  htmlType="submit"
                  onClick={() => history.push("/main/dashboard/crm")}
                >
                  {translation("auth.changePassword.backButton")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleUpdatePassword}
                >
                  {translation("auth.forgotPassword.sendButton")}
                </Button>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

ChangePassword.propTypes = {
  userId: PropTypes.number
};

export default ChangePassword;
