import cargoOpenPolicyConfig from "./cargoOpenPolicy";
import cargoSingleBoardingConfig from "./cargoSingleBoarding";
import lifeConfig from './life';
import suretyConfig from "./surety";

export default {
    CARGO_SINGLE_BOARDING: cargoSingleBoardingConfig,
    CARGO_OPEN_POLICY: cargoOpenPolicyConfig,
    SURETY: suretyConfig,
    LIFE: lifeConfig
}