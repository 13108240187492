import React from "react";
// prop types
import PropTypes from "prop-types";
// antd
import { InputNumber } from "antd";
// styles
import "./styles.less";

const FormattedNumberInput = ({
  prefix = "",
  suffix = "",
  value,
  controls = false,
  className,
  decimalSeparator = ",",
  onChange,
  ...props
}) => {
  const onChangeHandler = (value) => {
    if(typeof value === "number") {
      return onChange && onChange(value);
    }
    return onChange && onChange("");
  };
  const parseHandler = (value) => {
    return value.replace(suffix, "").replace(prefix, "").replace(/[^\d.-]/g, '');
  };
  return (
    <InputNumber
      value={value}
      className={`${!controls && "noHandler"} ${className}`}
      decimalSeparator={decimalSeparator}
      formatter={(value) => `${prefix}${value}${suffix}`}
      parser={parseHandler}
      onChange={(value) => onChangeHandler(value)}
      {...props}
    />
  );
};

FormattedNumberInput.propTypes = {
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  value: PropTypes.number,
  hasHandler: PropTypes.bool,
  decimalSeparator: PropTypes.string,
  onChange: PropTypes.func,
};

export default FormattedNumberInput;
