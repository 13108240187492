import Translation from "./translation";

export default {
    MOBILE_PHONE_NUMBER: {
        label: Translation.contactMobilePhoneLabel,
        type: 'MOBILE_PHONE_NUMBER',
        placeholder: Translation.contactMobilePhonePlaceholder,
        tooltipTitle: Translation.contactMobilePhoneTooltipTitle,
        mask: '(99) 99999-9999'
    },
    PHONE_NUMBER: {
        label: Translation.contactPhoneLabel,
        type: 'PHONE_NUMBER',
        placeholder: Translation.contactPhonePlaceholder,
        tooltipTitle: Translation.contactPhoneTooltipTitle,
        mask: '(99) 9999-9999'
    },
    WHATSAPP_PHONE_NUMBER: {
        label: Translation.contactWhatsappLabel,
        type: 'WHATSAPP_PHONE_NUMBER',
        placeholder: Translation.contactWhatsappPlaceholder,
        tooltipTitle: Translation.contactWhatsappTooltipTitle,
        mask: '(99) 99999-9999'
    }
}