import React from 'react'
import DetailsQuotations from '../../../DetailsQuotations'
import { Radio } from 'antd'
import {formatMoney} from 'helpers'
import './styles.less'
import translation from '../../translation'
const OtherQuotationItem = ({ best, isMobile, tableOpen, hideIcons, isLandscape, quoteValue, hasAvailable }) => {
    const { insurer, premium } = quoteValue
    const insurerImage = require(`assets/insurers/${insurer}.svg`)
    
    return (
        <label htmlFor={insurer} className={`${best && "best-quotation"} no-select  other-quotation-item `}>
            <div className={`checkbox-area ${!hasAvailable && 'hide-checkbox'}`}>
                <Radio value={insurer} id={insurer} disabled={!hasAvailable}/>
            </div>
            <div className="invoice-wrapper">
                <div className="insurer-img">
                    <img alt="" src={insurerImage} />
                </div>
                <div className="invoice-info">
                    <span>{translation.monthlyMinimalInvoiceLabel}</span>
                    <span className="invoice-value">{
                        premium && premium !== 0 ?
                        formatMoney(premium)+"*"
                        :
                        translation.underConsultLabel
                    }
                    </span>
                </div>
            </div>
            <div className="divider"></div>
            <DetailsQuotations quoteValue={quoteValue} tableOpen={tableOpen} customBestStyle={best && !isLandscape}
                isMobile={isMobile} hideIcons={hideIcons} />
        </label>
    )
}

export default OtherQuotationItem