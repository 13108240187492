//Helper functions`
import {setInitialTranslation} from "helpers";
import {prepareTranslations} from "../../../../helpers";
//Constants
const translation = setInitialTranslation("order");

const keys = [
  {
    "key": "applicationDataTabPaneLabel",
    "type": "label"
  }, {
    "key": "orderLabel",
    "type": "label"
  },
  {
    "key": "applicationDataTabPaneLabel",
    "type": "label"
  },
  {
    "key": "insuredTabLabel",
    "type": "label"
  },
  {
    "key": "initialTabLabel",
    "type": "label"
  },
  {
    "key": "goodsTabLabel",
    "type": "label"
  },
  {
    "key": "generalTabLabel",
    "type": "label"
  },
  {
    "key": "detailsTabLabel",
    "type": "label"
  },
  {
    "key": "uploadTabLabel",
    "type": "label"
  },
  {
    "key": "simulationsTabLabel",
    "type": "label"
  },
  {
    "key": "whatsAppLabel",
    "type": "label"
  },
];

export default prepareTranslations(keys, translation);