import React, {Component} from 'react';
//PropTypes
import PropTypes from 'prop-types';
//Components
import InputMask from 'react-input-mask';
//Others
import {injectIntl, intlShape} from 'react-intl';
//Custom Lib
import {findDOMNode} from 'react-dom';

class MaskedInput extends Component {
    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const {autoFocus} = this.props;
        if (autoFocus)
            this.input.focus();
    }

    render() {
        const {alwaysShowMask, intl, placeholder, onChange, ...others} = this.props;
        let currentPlaceholder;
        if (placeholder) {
            switch (typeof placeholder) {
                case "object": {
                    currentPlaceholder = intl.formatMessage(placeholder);
                    break;
                }
                case "string": {
                    currentPlaceholder = placeholder;
                    break;
                }
                default:
                    currentPlaceholder = null;
            }
        }

        return (
            <InputMask {...others}
                       placeholder={currentPlaceholder}
                       alwaysShowMask={alwaysShowMask}
                       onChange={onChange}
                       ref={ref => this.input = findDOMNode(ref)}
                       className="maskedInput ant-input"/>
        );
    }
}

MaskedInput.defaultProps = {
    alwaysShowMask: true,
    autoFocus: false
};

MaskedInput.propTypes = {
    intl: intlShape.isRequired,
    placeholder: PropTypes.object,
    mask: PropTypes.string,
    alwaysShowMask: PropTypes.bool,
    autoFocus: PropTypes.bool
};
export default injectIntl(MaskedInput);