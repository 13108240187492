//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "add",
    "type": "label"
  },
  {
    "key": "remove",
    "type": "label"
  },
  {
    "key": "singleBoarding.goods.title",
    "type": "label"
  },
  {
    "key": "empty",
    "type": "label"
  },
  {
    "key": "productCategoryLabel",
    "type": "label"
  },
  {
    "key": "productCategoryPlaceholder",
    "type": "label"
  },
  {
    "key": "productSubCategoryLabel",
    "type": "label"
  },
  {
    "key": "productSubCategoryPlaceholder",
    "type": "label"
  },
  {
    "key": "productLabel",
    "type": "label"
  },
  {
    "key": "productOtherLabel",
    "type": "label"
  },
  {
    "key": "productOtherPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "frequencyLabel",
    "type": "label"
  },
  {
    "key": "frequencyPlaceholder",
    "type": "label"
  },
  {
    "key": "amountLabel",
    "type": "label"
  },
  {
    "key": "usedMerchandiseLabel",
    "type": "label"
  },
  {
    "key": "amountPlaceholder",
    "type": "placeholder"
  }
];
export default prepareTranslations(keys, translation);