// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// common
import { setIsLoading } from "../common";
// antd
import { message } from "antd";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    userList: [],
  },
  reducers: {
    setUserList: (state, action) => {
      state.userList = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const { setUserList } = userSlice.actions;

export default userSlice.reducer;

// ------------------------------------------------------------------

export const fetchUsers = () => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/user/v1`)
      .then((response) => {
        dispatch(setUserList(response.data));
        return resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        return reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};
