import React from 'react';
//Components
import {Empty} from "antd";
//Constants
const contentClassName = "gx-flex-row gx-w-100 gx-align-items-center gx-justify-content-center";

const EmptyWrapper = (props) => {
  const {header, height, description} = props;
  const contentStyle = {height: 'auto'};

  if (height)
    contentStyle.height = height;

  return (
    <section>
      {header && <section style={{float: "right"}}>{header}</section>}
      <section className={contentClassName} style={contentStyle}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={description}/>
      </section>
    </section>
  )
};

export default EmptyWrapper;