import React from 'react';
//Translation
import Translation from "../CrossSell/translation";
import { useTranslation } from 'react-i18next';
//Components
import { Row, Col, Select } from "antd";
import { prepareOptions } from "helpers";
import selections from "constants/selections";

const CrossSell = (props) => {
  const { configurations } = props;

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
  };

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.groupLifeInsuranceLabel}
        </Col>
        <Col xs={24} md={18}>
          <Select disabled
            value={getValue("groupLifeInsurance")}
            className="gx-w-100">
            {prepareOptions(selections.crossSellSelection)}
          </Select>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.engineeringRiskInsuranceLabel}
        </Col>
        <Col xs={24} md={18}>
          <Select disabled
            value={getValue("engineeringRiskInsurance")}
            className="gx-w-100">
            {prepareOptions(selections.crossSellSelection)}
          </Select>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.liabilityInsuranceLabel}
        </Col>
        <Col xs={24} md={18}>
          <Select disabled
            value={getValue("liabilityInsurance")}
            className="gx-w-100">
            {prepareOptions(selections.crossSellSelection)}
          </Select>
        </Col>
      </Row>
    </section>
  );
};

export default CrossSell;