import { MODALITIES } from '../../../../../constants'

const GROUP_ONE = MODALITIES.CONTRACT
    .concat(MODALITIES.GENERAL)
    .concat(MODALITIES.PAYMENT_RETENTION);

const GROUP_TWO = MODALITIES.LABOUR_APPEAL
    .concat(MODALITIES.LABOUR_SURETY)
    .concat(MODALITIES.LEGAL);

const ALL_MODALITIES = MODALITIES.ALL;

export const fieldsList = {
    'term-data': {
        "contractType": GROUP_ONE,
        "contractAmount": GROUP_ONE,
        "suretyAmount": GROUP_ONE
            .concat(MODALITIES.RENT_GUARANTEE),
        "suretyPercentage": GROUP_ONE,
        "initialTermAt": ALL_MODALITIES,
        "finalTermAt": GROUP_ONE
            .concat(MODALITIES.RENT_GUARANTEE),
        "daysBetweenTerm": GROUP_ONE
            .concat(MODALITIES.RENT_GUARANTEE),
        "daysBetweenTermSelect": GROUP_TWO,
        "labourCoverage": [
            "CONSTRUCTION_BOND",
            "BUSINESS_SERVICES_BOND",
            "SUPPLY_BOND",
            "GENERAL_BOND"
        ],
        "penaltyCoverage": [
            "CONSTRUCTION_BOND",
            "BUSINESS_SERVICES_BOND",
            "SUPPLY_BOND",
            "ADVANCED_PAYMENT_BOND"
        ],
        "liabilityInsurance": [
            "BUSINESS_SERVICES_BOND",
            "CONSTRUCTION_BOND"
        ],
        "engineeringRiskInsurance": [
            "CONSTRUCTION_BOND"
        ],
        "groupLifeInsurance": MODALITIES.CONTRACT
            .filter((modal) => !["DEALER_PERFORMER_BOND", "REAL_ESTATE_BOND"]
                .includes(modal)),
        "depositAppeal": MODALITIES.LABOUR_APPEAL,
        "courtSuretyAmount": GROUP_TWO,
        "processAmount": GROUP_TWO,
    },
    'detail-data': {
        "contractDate": MODALITIES.CONTRACT,
        "contractNumber": MODALITIES.CONTRACT
            .concat(MODALITIES.PAYMENT_RETENTION)
            .concat(["ADVANCED_PAYMENT_BOND"]),
        "tribunalAndCourtField": GROUP_TWO,
        "processNumber": GROUP_TWO,
        "obligation": GROUP_ONE,
        "installment": [
            "ADVANCED_PAYMENT_BOND"
        ],
        "bidNumber": [
            "BIDDING_BOND"
        ],
        "bidDate": [
            "BIDDING_BOND"
        ],
        "actionType": [
            "LABOUR_SURETY",
            "TAX_ENFORCEMENT_BOND",
        ],
        "appealType": [
            "LABOUR_APPEAL_FILLING_BOND"
        ],
        "contactName": GROUP_TWO,
        "contactType": GROUP_TWO,
        "contactDocumentNumber": GROUP_TWO,
        "additionalData": ALL_MODALITIES,
        "proposalAccepted": ALL_MODALITIES,
        "receiveDraftContract": ALL_MODALITIES,
    },
    'quotation-data': {
        "suretyAmount": GROUP_ONE,
        "processAmount": GROUP_TWO,
        "labourCoverage": [
            "CONSTRUCTION_BOND",
            "BUSINESS_SERVICES_BOND",
            "SUPPLY_BOND",
            "GENERAL_BOND"
        ],
        "suretyAmountAdditionalPercentageApplied": MODALITIES.LABOUR_APPEAL
    }
};

export const labelsList = {
    'term-data': {
        'contractAmount': [
            {
                label: "Valor do Contrato (em R$)",
                jsonTerms: [
                    "GENERAL_BOND",
                    "CONSTRUCTION_BOND",
                    "BUSINESS_SERVICES_BOND",
                    "SUPPLY_BOND",
                    "ADVANCED_PAYMENT_BOND",
                ]
            },
            {
                label: "Valor da Licitação (em R$)",
                jsonTerms: [
                    "BIDDING_BOND"
                ],
            }
        ],
        'suretyPercentage': [
            {
                label: "% da garantia",
                jsonTerms: [
                    "GENERAL_BOND",
                    "CONSTRUCTION_BOND",
                    "BUSINESS_SERVICES_BOND",
                    "SUPPLY_BOND",
                    "BIDDING_BOND",
                ]
            },
            {
                label: "% de adiantamento",
                jsonTerms: [
                    "ADVANCED_PAYMENT_BOND"
                ]
            }
        ]
    }
}