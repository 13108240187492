import React from "react";
// propTypes
import PropTypes from "prop-types";
// components
import { ClientAppSidebar } from "components";

const ClientAppContainer = ({ children }) => {
  return (
    <div className="gx-main-content">
      <div className="gx-app-module">
        <ClientAppSidebar />
        <div className="gx-module-box">{children || null}</div>
      </div>
    </div>
  );
};

ClientAppContainer.propTypes = {
  children: PropTypes.node,
};

export default ClientAppContainer;
