import {put, takeLatest, all} from "redux-saga/effects";
import * as types from '../../actionTypes';
//Helpers
import Request from 'helpers/Request';
import {validateResponse, displayError} from 'helpers';

// fetch the user's list
export function* fetchAllProfiles() {
    yield put({
        type: types.FETCHING_ALL_PROFILES
    });
    try {
        const response = yield Request.get(`/user/profile/v1`);
        const {success, payloadData} = yield  validateResponse({response, displaySuccessMessage: false});
        if (success) {
            yield put({
                type: types.FETCH_ALL_PROFILES_SUCCESS,
                payload: payloadData
            });
        }
    } catch (error) {
        displayError("Please check your internet connection!");
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(types.FETCH_ALL_PROFILES, fetchAllProfiles),
    ]);
}