import React from "react";
//Translation
import Translation from "../Term/translation";
//Components
import { Row, Col, Select, DatePicker, Radio } from "antd";
import { CurrencyInput, NumberInput, Switch, FormField } from "components";
import { TermDataWrapper } from "../../DataWrapper";
import selections from "constants/selections";
//Helpers
import { prepareOptions, frontEndDate } from "helpers";
import moment from "moment";
//constants
import {
  suretyPercentageValue,
  valueByAppealType,
} from "../../../../../../constants";

const { Option } = Select;

const Term = (props) => {
  const { handleChange, configurations, OrderErrors } = props;
  const {
    daysBetweenTerm,
    suretyAmount,
    processAmount,
    contractAmount,
    suretyPercentage,
  } = configurations;

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity)) {
      return configurations[entity];
    } else {
      return "";
    }
  };
  const toggleProperty = (attr) => () => {
    handleChange({
      attr: attr,
      value: !getValue(attr),
    });
  };

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;
    if (attr === "type" && !getValue("contractType")) {
      handleChange({
        attr: "contractType",
        value: "PUBLIC_CONTRACT",
      });
    }
    if (attr === "processAmount") {
      handleChange({
        attr: "suretyAmount",
        value: value.floatValue + value.floatValue * suretyPercentageValue,
      });
    }
    if (!isDirect && typeof event === "object") {
      if (event instanceof Array) value = event;
      else value = event.target.value;
    }

    if (attr === "appealType") {
      handleChange({
        attr: "processAmount",
        value: valueByAppealType[event],
      });
    }

    return handleChange({
      attr: attr,
      value: value,
    });
  };

  const calculateAmount = (suretyAmount, contractAmount, suretyPercentage) => {
    if (suretyAmount && suretyPercentage) {
      return Number(
        parseFloat(suretyAmount / (suretyPercentage / 100)).toFixed(2)
      );
    } else if (contractAmount && suretyPercentage) {
      return Number(
        parseFloat((contractAmount * suretyPercentage) / 100).toFixed(2)
      );
    } else {
      return Number(
        parseFloat((suretyAmount / contractAmount) * 100).toFixed(2)
      );
    }
  };

  const onAmountChange = (attr) => (event) => {
    let amount = event.floatValue;
    let suretyAmountAux, contractAmountAux;
    let computePercentage = true;
    const contractAmount =
      getValue("contractAmount") ||
      getValue("processAmount") ||
      getValue("courtSuretyAmount");
    const suretyAmount = getValue("suretyAmount");
    const suretyPercentage = getValue("suretyPercentage");

    if (attr === "suretyAmount") {
      handleChange({
        attr: "suretyAmount",
        value: amount,
      });
      suretyAmountAux = amount;
      if (!contractAmount && suretyPercentage) {
        computePercentage = false;
        contractAmountAux = calculateAmount(
          suretyAmountAux,
          null,
          suretyPercentage
        );
        handleChange({
          attr: "contractAmount",
          value: contractAmountAux,
        });
      } else {
        contractAmountAux = contractAmount;
      }
    } else {
      handleChange({
        attr: "contractAmount",
        value: amount,
      });
      contractAmountAux = amount;
      if (!suretyAmount && suretyPercentage) {
        computePercentage = false;
        suretyAmountAux = calculateAmount(
          null,
          contractAmountAux,
          suretyPercentage
        );
        handleChange({
          attr: "suretyAmount",
          value: suretyAmountAux,
        });
      } else {
        suretyAmountAux = suretyAmount;
      }
    }

    if (computePercentage) {
      let percentage = calculateAmount(suretyAmountAux, contractAmountAux);
      handleChange({
        attr: "suretyPercentage",
        value: percentage,
      });
    }
  };

  const onPercentageChange = (attr) => (event) => {
    let percentage = event;
    const contractAmount =
      getValue("contractAmount") ||
      getValue("processAmount") ||
      getValue("courtSuretyAmount");
    const suretyAmount = getValue("suretyAmount");

    if (percentage < 0) return;

    handleChange("suretyPercentage", percentage);
    if (percentage && contractAmount) {
      let surety = calculateAmount(null, contractAmount, percentage);
      handleChange({
        attr: "suretyAmount",
        value: surety,
      });
    } else if (percentage && suretyAmount) {
      let contract = calculateAmount(suretyAmount, null, percentage);
      handleChange({
        attr: "contractAmount",
        value: contract,
      });
    }
  };

  function updateDate(currentDate, days, type) {
    if (type === "remove") days = -1 * days;
    currentDate = moment(currentDate).clone();
    currentDate.add(days, "days");
    return currentDate;
  }

  function getDaysDifference(startDate, endDate) {
    if (startDate && endDate) return moment(endDate).diff(startDate, "days");
    return null;
  }

  const handleDateDifferenceChange = (attr) => (event) => {
    const initialTermAt = getValue("initialTermAt");
    const finalTermAt = getValue("finalTermAt");
    let days = event ? (event.target ? event.target.value : event) : 0;
    if (days < 0) days = 0;

    handleChange({
      attr: "daysBetweenTerm",
      value: days,
    });
    if (days && initialTermAt) {
      let endDate = updateDate(initialTermAt, days, "add");
      handleChange({
        attr: "finalTermAt",
        value: endDate,
      });
    } else if (days && finalTermAt) {
      let startDate = updateDate(finalTermAt, days, "remove");
      handleChange({
        attr: "initialTermAt",
        value: startDate,
      });
    }
  };

  const onDateChange = (attr, isMobile) => (date) => {
    const value = isMobile
      ? date.target.value
        ? moment(date.target.value, "DD/MM/YYYY")
        : ""
      : date;
    const initialTermAt = getValue("initialTermAt");
    const finalTermAt = getValue("finalTermAt");
    let startDate, endDate;
    let computeDays = true;
    if (attr === "initialTermAt") {
      handleChange({
        attr: "initialTermAt",
        value: value,
      });
      startDate = value;
      if (initialTermAt && daysBetweenTerm) {
        computeDays = false;
        endDate = updateDate(startDate, daysBetweenTerm, "add");
        handleChange({
          attr: "finalTermAt",
          value: endDate,
        });
      } else if (finalTermAt) {
        endDate = finalTermAt;
      }
    } else {
      handleChange({
        attr: "finalTermAt",
        value: value,
      });
      endDate = value;
      if (!initialTermAt && daysBetweenTerm) {
        computeDays = false;
        startDate = updateDate(endDate, daysBetweenTerm, "remove");
        handleChange({
          attr: "initialTermAt",
          value: startDate,
        });
      } else {
        startDate = initialTermAt;
      }
    }

    if (computeDays)
      handleChange({
        attr: "daysBetweenTerm",
        value: getDaysDifference(startDate, endDate),
      });
  };

  return (
    <section>
      <TermDataWrapper
        step="term-data"
        field="contractType"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.contractTypeLabel}:
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["contractType"]}>
              <Radio.Group
                autoFocus="true"
                buttonStyle="solid"
                defaultValue={getValue("contractType")}
                onChange={onChange("contractType")}
                className="radio-group-full-width"
              >
                <Radio.Button value="PUBLIC_CONTRACT">Público</Radio.Button>
                <Radio.Button value="PRIVATE_CONTRACT">Privado</Radio.Button>
              </Radio.Group>
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.bondTypeLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["type"]}>
            <Select
              value={getValue("type")}
              className="gx-w-100"
              placeholder={Translation.bondTypePlaceholder}
              onChange={onChange("type")}
            >
              {prepareOptions(selections.bondTypes)}
            </Select>
          </FormField>
        </Col>
      </Row>
      <TermDataWrapper
        step="detail-data"
        field="appealType"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.appealTypeLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["appealType"]}>
              <Select
                value={getValue("appealType")}
                className="gx-w-100"
                placeholder={Translation.appealTypePlaceholder}
                onChange={onChange("appealType")}
              >
                {prepareOptions(selections.appealTypes)}
              </Select>
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="processAmount"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.processAmountLabel}:
          </Col>
          <Col xs={24} md={8}>
            <FormField error={OrderErrors["processAmount"]}>
              <CurrencyInput
                value={processAmount}
                prefix={"R$"}
                onChange={onChange("processAmount", true)}
                placeholder={Translation.processAmountPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="courtSuretyAmount"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.courtSuretyAmountLabel}:
          </Col>
          <Col xs={24} md={8}>
            <FormField error={OrderErrors["suretyAmount"]}>
              <CurrencyInput
                readOnly={true}
                value={suretyAmount}
                prefix={"R$"}
                onChange={onChange("suretyAmount", true)}
                placeholder={Translation.processAmountPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="initialTermAt"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.initialDateLabel}:
          </Col>
          <Col xs={24} md={8}>
            <FormField error={OrderErrors["initialTermAt"]}>
              <DatePicker
                showToday={true}
                allowClear={false}
                className="gx-w-100"
                format={frontEndDate}
                value={getValue("initialTermAt")}
                onChange={onDateChange("initialTermAt")}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="daysBetweenTerm"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.daysBetweenLabel}:
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["daysBetweenTerm"]}>
              <NumberInput
                value={daysBetweenTerm}
                onChange={handleDateDifferenceChange("daysBetweenTerm")}
                placeholder={Translation.daysBetweenPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="daysBetweenTermSelect"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.daysBetweenLabel}
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["daysBetweenTerm"]}>
              <Select
                defaultValue={daysBetweenTerm}
                style={{ width: 120 }}
                onChange={handleDateDifferenceChange("daysBetweenTerm")}
                id="daysBetweenTerm"
              >
                <Option value={1095}>3 anos</Option>
                <Option value={1460}>4 anos</Option>
                <Option value={1825}>5 anos</Option>
              </Select>
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="finalTermAt"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.finalDateLabel}:
          </Col>
          <Col xs={24} md={8}>
            <FormField error={OrderErrors["finalTermAt"]}>
              <DatePicker
                showToday={true}
                allowClear={false}
                className="gx-w-100"
                format={frontEndDate}
                value={getValue("finalTermAt")}
                onChange={onDateChange("finalTermAt")}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="contractAmount"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.contractAmountLabel}:
          </Col>
          <Col xs={24} md={8}>
            <FormField error={OrderErrors["contractAmount"]}>
              <CurrencyInput
                value={contractAmount}
                prefix={"R$"}
                onChange={onAmountChange("contractAmount")}
                placeholder={Translation.contractAmountPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="suretyPercentage"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.suretyPercentageLabel}:
          </Col>
          <Col xs={24} md={18}>
            <FormField error={OrderErrors["suretyPercentage"]}>
              <NumberInput
                type="percent"
                value={suretyPercentage}
                placeholder={Translation.suretyPercentagePlaceholder}
                onChange={onPercentageChange("suretyPercentage")}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="suretyAmount"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.suretyAmountLabel}:
          </Col>
          <Col xs={24} md={8}>
            <FormField error={OrderErrors["suretyAmount"]}>
              <CurrencyInput
                value={suretyAmount}
                prefix={"R$"}
                onChange={onAmountChange("suretyAmount")}
                placeholder={Translation.suretyAmountPlaceholder}
              />
            </FormField>
          </Col>
        </Row>
      </TermDataWrapper>
      {/* <TermDataWrapper step="term-data" field="depositAppeal" type={configurations.type}>
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.depositAppealLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("depositAppeal")}
              onChange={toggleProperty("depositAppeal")} />
          </Col>
        </Row>
      </TermDataWrapper> */}
      <TermDataWrapper
        step="term-data"
        field="labourCoverage"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.labourCoverageLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("labourCoverage")}
              onChange={toggleProperty("labourCoverage")}
            />
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="penaltyCoverage"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.penaltyCoverageLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("penaltyCoverage")}
              onChange={toggleProperty("penaltyCoverage")}
            />
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="liabilityInsurance"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.liabilityInsuranceLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("liabilityInsurance")}
              onChange={toggleProperty("liabilityInsurance")}
            />
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="engineeringRiskInsurance"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.engineeringRiskInsuranceLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("engineeringRiskInsurance")}
              onChange={toggleProperty("engineeringRiskInsurance")}
            />
          </Col>
        </Row>
      </TermDataWrapper>
      <TermDataWrapper
        step="term-data"
        field="groupLifeInsurance"
        type={configurations.type}
      >
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.groupLifeInsuranceLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("groupLifeInsurance")}
              onChange={toggleProperty("groupLifeInsurance")}
            />
          </Col>
        </Row>
      </TermDataWrapper>
    </section>
  );
};

export default Term;
