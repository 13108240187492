//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "capitalTypeLabel",
    "type": "label"
  }
];
export default prepareTranslations(keys, translation);