//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "add",
    "type": "label"
  },
  {
    "key": "remove",
    "type": "label"
  },
  {
    "key": "openPolicy.routes.title",
    "type": "label"
  },
  {
    "key": "empty",
    "type": "label"
  },
  {
    "key": "originPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "destinationPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "modalPlaceholder",
    "type": "label"
  },
  {
    "key": "frequencyPlaceholder",
    "type": "label"
  }
];
export default prepareTranslations(keys, translation);