import React, { useEffect } from "react";
// propTypes
import PropTypes from "prop-types";
// translations
import { useTranslation } from "react-i18next";
// antd
import { Button, Col, Row, Spin } from "antd";
// components
import { ManageUsersAccessTable, FormField, MaskedInput } from "components";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchClientAppUsers,
  setFiltersData,
} from "store/slices/clientApp/manageUsersAccess/index.js";
//helpers
import { inputMasks, formatMaskInput, getErrorMessage } from "helpers";
// custom styles
import "./styles.less";

const ManageClientAppAccess = () => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const {
    common: { isLoading },
  } = useSelector((state) => state);
  const { usersList, filtersData } = useSelector(
    (state) => state.clientApp.manageUsersAccess
  );

  const paginationConfig = {
    current: usersList?.pageable?.pageNumber + 1,
    total: usersList?.totalElements,
    pageSize: usersList?.size,
    onChange: (page) => {
      dispatch(
        fetchClientAppUsers({
          page: page - 1,
          size: usersList?.size,
          ...filtersData,
        })
      );
    },
  };

  const handleChange = (event) => {
    if (!event.target) {
      return null;
    }
    const { name, value } = event.target;
    dispatch(setFiltersData({ [name]: formatMaskInput(value) }));
  };

  const handleSearch = () => dispatch(fetchClientAppUsers({ ...filtersData }));

  const handleCleanFilter = () => {
    dispatch(setFiltersData({}));
    dispatch(fetchClientAppUsers());
  };

  useEffect(() => {
    dispatch(fetchClientAppUsers());
  }, []);

  return (
    <React.Fragment>
      <div className="gx-module-box-header">
        <Row type="flex" align="middle" gutter={8}>
          <Col
            xs={24}
            md={3}
            className="gx-d-flex gx-justify-content-start gx-font-weight-bold"
          >
            {translation("clientApp.filterUsersByDocumentNumber.label")}
          </Col>
          <Col xs={24} md={8}>
            <FormField>
              <MaskedInput
                disabled={false}
                name="documentNumber"
                mask={inputMasks["COMPANY"]}
                onChange={handleChange}
                value={filtersData?.documentNumber || ""}
              />
            </FormField>
          </Col>
          <Col md={4} className="gx-d-flex gx-justify-content-start">
            <Button
              className="gx-m-0"
              type="primary"
              disabled={false}
              onClick={handleSearch}
            >
              {translation("customer.getCustomersDataButton")}
            </Button>
            <Button
              className="gx-m-0 gx-ml-2"
              type="primary"
              ghost
              disabled={!filtersData?.documentNumber}
              onClick={handleCleanFilter}
            >
              {translation("clientApp.cleanFilters.button.label")}
            </Button>
          </Col>
        </Row>
      </div>
      <div className="gx-module-box-content">
        <Spin spinning={isLoading}>
          <ManageUsersAccessTable
            data={usersList?.content}
            pagination={paginationConfig}
          />
        </Spin>
      </div>
    </React.Fragment>
  );
};

export default ManageClientAppAccess;
