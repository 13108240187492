//Helper functions`
import {setInitialTranslation} from "helpers";
import {prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation("order");

const keys = [
    {
        "key": "whatsAppTemplateOptionPlaceholder",
        "type": "label"
    },
    {
        "key": "whatsAppContactMotiveLabel",
        "type": "label"
    },
    {
        "key": "whatsAppContactMotivePlaceholder",
        "type": "placeholder"
    },
    {
        "key": "whatsAppInputLabel",
        "type": "label"
    },
    {
        "key": "whatsAppSuccessSend",
        "type": "label"
    },
    {
        "key": "whatsAppHistoryLoadingLabel",
        "type": "label"
    },
    {
        "key": "whatsAppHistoryColumnDateLabel",
        "type": "label"
    },
    {
        "key": "whatsAppHistoryColumnTemplateLabel",
        "type": "label"
    },
    {
        "key": "whatsAppHistoryColumnMotiveLabel",
        "type": "label"
    },
    {
        "key": "whatsAppHistoryColumnSellerLabel",
        "type": "label"
    },
    {
        "key": "whatsAppHistoryEmptyMessage",
        "type": "label"
    },
];

export default prepareTranslations(keys, translation);