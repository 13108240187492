import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation("customers");

const keys = [
  {
    key: "newPolicyButtonLabel",
    type: "label",
  },
  {
    key: "productTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyNumberTableHeaderLabel",
    type: "label",
  },
  {
    key: "validityTableHeaderLabel",
    type: "label",
  },
  {
    key: "premiumTableHeaderLabel",
    type: "label",
  },
  {
    key: "comissionTableHeaderLabel",
    type: "label",
  },
  {
    key: "customerHeaderLabel",
    type: "label",
  },
  {
    key: "documentNumberHeaderLabel",
    type: "label",
  },
  {
    key: "LIFEproductLabel",
    type: "label",
  },
  {
    key: "CARGO_OPEN_POLICYproductLabel",
    type: "label",
  },
  {
    key: "CARGO_SINGLE_BOARDINGproductLabel",
    type: "label",
  },

  {
    key: "AUTO_INSURANCEproductLabel",
    type: "label",
  },
  {
    key: "SURETYproductLabel",
    type: "label",
  },
  {
    key: "fetchingPoliciesLabel",
    type: "label",
  },
  {
    key: "emptyPolicyList",
    type: "label",
  },
  {
    key: "noData",
    type: "label",
  },
  {
    key: "policyExpirationLabel",
    type: "label",
  },
  {
    key: "updatePolicyListButton",
    type: "label",
  },
];

export default prepareTranslations(keys, translation);
