import React, { useState, useEffect } from "react";
//router
import { useParams, useHistory } from "react-router-dom";
// translation
import { useTranslation } from "react-i18next";
//components
import {
  Card,
  Row,
  Col,
  DatePicker,
  Dropdown,
  Button,
  Tag,
  Menu,
  notification,
} from "antd";
import { HistoryOutlined } from "@ant-design/icons";

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {
  FormField,
  LabelField,
  CurrencyInput,
  NumberInput,
  UploadFile,
} from "components";
//helpers
import {
  dateFormat,
  uploadConfig,
  frontEndDate,
  getErrorMessage,
  frontEndMonth,
  monthFormat,
  downloadFile,
  formatDateTime,
} from "helpers";
import moment from "moment";
//custom styles
import "./styles.less";
//constants
import { orderProductTypes } from "../../../../constants";
import InvoicePaymentModal from "./InvoicePaymentModal";

const InvoiceForm = ({
  policy,
  invoice,
  errors,
  uploadUrlBase,
  enableChange,
  setEnableChange,
  onChange,
  onSubmit,
  onSendInvoice,
  handleRemoveFile,
  handleUploadSuccess,
  onDelete,
  onManualSendInvoice,
  onMarkAsDelayed,
  onMarkAsPaid,
  onSaveNewInvoiceFiles,
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const params = useParams();
  const { invoice: invoiceId } = params;
  const history = useHistory();
  const { t: translation } = useTranslation();

  const redirectToEmailHistory = () => {
    const { id, policy, invoice } = params;
    history.push(
      `/crm/customers/${id}/policy/${policy}/invoice/${invoice}/email-history`
    );
  };

  const newInvoiceUploadConfig = (type) => ({
    customRequest: (options) => handleSaveFilesNewInvoice(options, type),
    onProgress: ({ percent }, file) => {
      notification.info({
        key: "progress",
        message: `${percent}% - Enviando '${file.name}' ...`,
      });
    },
    multiple: false,
    showUploadList: false,
  });

  const getDisabledValue = () => (invoiceId === "new" ? false : !enableChange);

  const handleManualEmail = () => {
    onManualSendInvoice(params.id, params.policy, params.invoice);
  };

  const handleSaveFilesNewInvoice = (type, file) => {
    onSaveNewInvoiceFiles && onSaveNewInvoiceFiles(type, file);
  };

  const handleMarAsDelayedInvoice = () => {
    onMarkAsDelayed(params.id, params.policy, params.invoice);
  };

  const handleOpenMarkInvoiceAsPaidModal = () => {
    setModalVisible(true);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<HistoryOutlined />}
        onClick={redirectToEmailHistory}
      >
        {translation("invoice.showEmailHistoryButton")}
      </Menu.Item>
      <Menu.Item key="2" icon={<HistoryOutlined />} onClick={handleManualEmail}>
        {translation("invoice.manualEmailButton")}
      </Menu.Item>
    </Menu>
  );

  const markAsPaidButtonGroup = (
    <Menu>
      <Menu.Item
        key="1"
        disabled={!getDisabledValue()}
        onClick={handleMarAsDelayedInvoice}
      >
        {translation("invoice.markAsDelayedButton")}
      </Menu.Item>
    </Menu>
  );

  return (
    <Card className="invoiceForm gx-mt-3">
      <Row>
        <Col xs={24} md={12}>
          <LabelField label={translation("invoice.recurrenceMonthLabel")}>
            <FormField error={getErrorMessage("recurrenceMonth", errors)}>
              <DatePicker.MonthPicker
                disabled={getDisabledValue()}
                onChange={onChange("recurrenceMonth")}
                allowClear={false}
                format={frontEndMonth}
                value={
                  invoice.recurrenceMonth
                    ? moment(invoice.recurrenceMonth, monthFormat)
                    : undefined
                }
              />
            </FormField>
          </LabelField>
        </Col>

        <Col xs={24} md={12}>
          <LabelField label={translation("invoice.dueDateLabel")}>
            <FormField error={getErrorMessage("dueDate", errors)}>
              <DatePicker
                disabled={getDisabledValue()}
                onChange={onChange("dueDate")}
                allowClear={false}
                format={frontEndDate}
                value={
                  invoice.dueDate
                    ? moment(invoice.dueDate, dateFormat)
                    : undefined
                }
              />
            </FormField>
          </LabelField>
        </Col>
      </Row>

      {policy.type &&
        (policy.type.product === orderProductTypes.CARGO_OPEN_POLICY ||
          policy.type.product === orderProductTypes.LIFE) && (
          <Row>
            <Col xs={24} md={12}>
              <LabelField label={translation("invoice.recurrenceLabel")}>
                <FormField error={getErrorMessage("startAt", errors)}>
                  <DatePicker
                    disabled={getDisabledValue()}
                    onChange={onChange("startAt")}
                    allowClear={false}
                    format={frontEndDate}
                    value={
                      invoice.startAt
                        ? moment(invoice.startAt, dateFormat)
                        : undefined
                    }
                  />
                </FormField>
              </LabelField>
            </Col>
            <Col xs={24} md={12}>
              <LabelField label={translation("invoice.finalTermAtLabel")}>
                <FormField error={getErrorMessage("finishAt", errors)}>
                  <DatePicker
                    disabled={getDisabledValue()}
                    onChange={onChange("finishAt")}
                    allowClear={false}
                    format={frontEndDate}
                    value={
                      invoice.finishAt
                        ? moment(invoice.finishAt, dateFormat)
                        : undefined
                    }
                  />
                </FormField>
              </LabelField>
            </Col>
          </Row>
        )}

      {policy.type &&
        policy.type.product === orderProductTypes.CARGO_OPEN_POLICY && (
          <Row>
            <Col xs={24} md={12}>
              <LabelField
                label={translation(
                  "invoice.merchandiseTotalBoardedAmountLabel"
                )}
              >
                <FormField
                  error={getErrorMessage(
                    "merchandiseTotalBoardedAmount",
                    errors
                  )}
                >
                  <CurrencyInput
                    disabled={getDisabledValue()}
                    value={invoice.merchandiseTotalBoardedAmount}
                    placeholder={translation(
                      "invoice.merchandiseTotalBoardedAmountPlaceholder"
                    )}
                    onChange={onChange("merchandiseTotalBoardedAmount")}
                  />
                </FormField>
              </LabelField>
            </Col>
            <Col xs={24} md={12}>
              <LabelField
                label={translation("invoice.totalTravelsAmountLabel")}
              >
                <FormField
                  error={getErrorMessage("totalTravelsAmount", errors)}
                >
                  <NumberInput
                    disabled={getDisabledValue()}
                    min={0}
                    value={invoice.totalTravelsAmount}
                    onChange={onChange("totalTravelsAmount")}
                  />
                </FormField>
              </LabelField>
            </Col>
          </Row>
        )}

      <Row>
        <Col xs={24} md={12}>
          <LabelField label={translation("invoice.netPremiumLabel")}>
            <FormField error={getErrorMessage("netPremium", errors)}>
              <CurrencyInput
                allowNegative
                disabled={getDisabledValue()}
                value={invoice.netPremium}
                placeholder={translation("invoice.netPremiumPlaceholder")}
                onChange={onChange("netPremium")}
              />
            </FormField>
          </LabelField>
        </Col>
        <Col xs={24} md={8}>
          <LabelField label={translation("invoice.taxLabel")}>
            <FormField error={getErrorMessage("rate", errors)}>
              <CurrencyInput
                disabled={getDisabledValue()}
                value={invoice.tax}
                suffix="%"
                prefix=""
                readOnly={true}
              />
            </FormField>
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>
          <LabelField label={translation("invoice.premiumLabel")}>
            <FormField error={getErrorMessage("premium", errors)}>
              <CurrencyInput
                allowNegative
                disabled={getDisabledValue()}
                readOnly={true}
                placeholder={translation("invoice.premiumPlaceholder")}
                value={invoice.premium || 0}
                onChange={onChange("premium")}
              />
            </FormField>
          </LabelField>
        </Col>
      </Row>

      <Row>
        <Col xs={24} md={12} className="uploadFile">
          <LabelField label={translation("invoice.uploadInvoiceLabel")}>
            <UploadFile
              uploadText={translation("invoice.uploadInvoiceButton")}
              downloadText={translation("invoice.downloadInvoiceButton")}
              disabledUpload={invoice.hasInvoice || !enableChange}
              disabledRemove={getDisabledValue()}
              hasFile={invoice.hasInvoice}
              uploadProps={
                invoiceId === "new"
                  ? { ...newInvoiceUploadConfig("invoice") }
                  : {
                      ...uploadConfig(),
                      showUploadList: false,
                      multiple: false,
                      action: `${uploadUrlBase}`,
                      onSuccess: () => handleUploadSuccess("invoice"),
                    }
              }
              onRemove={() => handleRemoveFile("invoice")}
              onDownload={() =>
                downloadFile(
                  invoice.invoiceURL,
                  `invoice-${invoice.recurrenceMonth}`
                )
              }
            />
          </LabelField>
        </Col>
        <Col xs={24} md={12} className="uploadFile">
          <LabelField label={translation("invoice.uploadPaymentLabel")}>
            <UploadFile
              uploadText={translation("invoice.uploadPaymentButton")}
              downloadText={translation("invoice.downloadPaymentButton")}
              disabledUpload={invoice.hasPaymentSlip || !enableChange}
              disabledRemove={getDisabledValue()}
              hasFile={invoice.hasPaymentSlip}
              uploadProps={
                invoiceId === "new"
                  ? { ...newInvoiceUploadConfig("payment-slip") }
                  : {
                      ...uploadConfig(),
                      showUploadList: false,
                      multiple: false,
                      action: `${uploadUrlBase}/payment-slip`,
                      onSuccess: () => handleUploadSuccess("payment"),
                    }
              }
              onRemove={() => handleRemoveFile("payment")}
              onDownload={() =>
                downloadFile(
                  invoice.paymentSlipURL,
                  `payment-${invoice.recurrenceMonth}`
                )
              }
            />
          </LabelField>
        </Col>
      </Row>
      {invoiceId !== "new" && (
        <Row type="flex" align="middle" className="gx-mt-2">
          <Col xs={0} md={5} />
          <Col xs={24} md={7}>
            <Tag
              color={
                invoice.emailStatus === "SENT" ||
                invoice.emailStatus === "MANUAL_SENT"
                  ? "green"
                  : "orange"
              }
            >
              {invoice.emailStatus === "SENT" ||
              invoice.emailStatus === "MANUAL_SENT" ? (
                <>
                  <CheckCircleOutlined />{" "}
                  {translation("invoice.emailStatusSent")} -{" "}
                  {formatDateTime(invoice.emailSentAt)}
                </>
              ) : (
                <>
                  <ExclamationCircleOutlined />{" "}
                  {translation("invoice.emailStatusPending")}
                </>
              )}
            </Tag>
          </Col>
        </Row>
      )}
      <Row type="flex" align="middle" justify="end" className="gx-mt-3">
        {invoiceId !== "new" && (
          <>
            <Button
              disabled={!getDisabledValue()}
              ghost
              onClick={onDelete}
              type="danger"
            >
              {translation("invoice.deleteButton")}
            </Button>
            <Dropdown.Button
              disabled={!getDisabledValue()}
              overlay={markAsPaidButtonGroup}
              onClick={handleOpenMarkInvoiceAsPaidModal}
            >
              {translation("invoice.markAsPaidButton")}
            </Dropdown.Button>
            <Dropdown.Button
              disabled={!getDisabledValue()}
              onClick={onSendInvoice}
              overlay={menu}
            >
              {translation("invoice.sendInvoiceEmailButton")}
            </Dropdown.Button>
            <Button onClick={() => setEnableChange(true)}>
              {translation("invoice.editButton")}
            </Button>
          </>
        )}
        <Button disabled={getDisabledValue()} onClick={onSubmit} type="primary">
          {translation("invoice.saveButton")}
        </Button>
      </Row>
      <InvoicePaymentModal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        onMarkAsPaid={onMarkAsPaid}
      />
    </Card>
  );
};

export default InvoiceForm;
