// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../../util/axios";
// translation
import i18n from "../../../../translation/i18n";
// antd
import { message } from "antd";

export const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    proposal: {
      policies: [],
    },
    errors: [],
    isLoading: false,
    needSaveProposal: false
  },
  reducers: {
    setPolicyList: (state, action) => {
      state.proposal.policies = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setProposal: (state, action) => {
      state.proposal = action.payload;
    },
    changeProposal: (state, action) => {
      state.proposal = {
        ...state.proposal,
        ...action.payload,
      };
    },
    setNeedSaveProposal: (state, action) => {
      state.needSaveProposal = action.payload;
    }
  },
});

// ------------------------------------------------------------------

export const {
  setPolicyList,
  setLoading,
  setErrors,
  setProposal,
  changeProposal,
  setNeedSaveProposal
} = proposalSlice.actions;

export default proposalSlice.reducer;

// -----------------------------------------------------------------

export const fetchCoverageTypes = (policyType) => (dispatch) => {
  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`cargo/v1/open-policy/coverages/${policyType}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const fetchProposal = (code) => (dispatch) => {
  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`cargo/v1/open-policy/${code}/proposal`)
      .then((response) => {
        if (response.data.policies) {
          dispatch(setProposal(response.data));
        } else {
          dispatch(setProposal({ policies: [] }));
        }
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const saveProposal = (code) => (dispatch, getState) => {
  const {
    cargoProposal: { proposal },
  } = getState().proposal;

  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`cargo/v1/open-policy/${code}/proposal`, proposal)
      .then((response) => {
        message.success(i18n.t("proposal.saveProposalSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
          message.error(i18n.t("proposal.emptyFieldErrorMessage"));
        }
        reject(error);
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const acceptProposal = (code) => (dispatch, getState) => {
  const {
    cargoProposal: { proposal },
  } = getState().proposal;

  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`cargo/v1/open-policy/${code}/proposal/accepted`, {
        ...proposal,
        accepted: true,
      })
      .then((response) => {
        message.success(i18n.t("proposal.accepteProposalSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? (error.response.data.message || error.response.data.exceptionMessage)
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
//          message.error(i18n.t("proposal.emptyFieldErrorMessage"));
        }
        reject(error);
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const launchIntegrationAverbamais = (code) => (dispatch) => {
  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`cargo/v1/open-policy/${code}/averbamais`)
      .then((response) => {
        message.success(i18n.t("proposal.integrationSuccessMessage"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setLoading(false)));
  });
};

export const removePolicyFile = (code, policyId) => (dispatch) => {
  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`cargo/v1/open-policy/${code}/proposal/${policyId}/signed`)
      .then((response) => {
        message.success(i18n.t("proposal.removePolicyFileSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setLoading(false)));
  });
};
