import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Table } from "antd";
// helpers
import {
  formatDateTime,
  getFormattedDate,
  formatMoney,
} from "../../../../helpers";
// styles
import "./styles.less";
// constants
import { endorsementTypes } from "../../../../constants";

const Historical = ({
  policy = {},
  endorsements = [],
  users = [],
  pagination = {},
}) => {
  const { t: translation } = useTranslation();

  const getChangeTypeLabel = (record) => {
    if (
      (record.netPremium || record.premium) &&
      !record.maximumGuaranteeLimit &&
      !(record.initialTermAt || record.initialTermAt)
    ) {
      return `${translation(
        "endorsement.historical.changePremiumLabel"
      )}: ${formatMoney(record.premium || record.netPremium)}`;
    }
    if (
      !(record.netPremium || record.premium) &&
      record.maximumGuaranteeLimit &&
      !(record.initialTermAt || record.initialTermAt)
    ) {
      return `${translation(
        "endorsement.historical.changemaximumGuaranteeLimitLabel"
      )}: ${formatMoney(record.maximumGuaranteeLimit)}`;
    }
  };

  const getEndorsementDescription = (type, record) => {
    switch (type) {
      case endorsementTypes[0].value:
        return record.cancelledAt
          ? `${translation(
              "endorsement.description.cancelLabel"
            )} ${getFormattedDate(record.cancelledAt)}`
          : "";
      case endorsementTypes[1].value:
        return record.alteration || getChangeTypeLabel(record);
      case endorsementTypes[2].value:
        return record.initialTermAt && record.finalTermAt
          ? `${getFormattedDate(record.initialTermAt)} ${translation(
              "endorsement.description.extensionLabel"
            )} ${getFormattedDate(record.finalTermAt)}`
          : "";
      default:
        break;
    }
  };

  const getEndorsementType = (type, record) => {
    const endorsement = endorsementTypes.find(
      (endorsementType) => endorsementType.value === type
    );
    return (
      <>
        <p className="gx-mb-0">{endorsement ? endorsement.label : ""}</p>
        <p className="gx-mb-0">{getEndorsementDescription(type, record)}</p>
        <p className="gx-m-0">{record.observation}</p>
      </>
    );
  };

  const columns = [
    {
      title: translation("endorsement.historical.endorsementTypeLabel"),
      dataIndex: "type",
      key: "type",
      render: (type, record) => getEndorsementType(type, record),
    },
    {
      title: translation("endorsement.historical.registeredAtLabel"),
      dataIndex: "uploadAt",
      key: "uploadAt",
      render: (uploadAt) => formatDateTime(uploadAt),
    },
    {
      title: translation("endorsement.historical.createdByLabel"),
      dataIndex: "createdBy",
      key: "createdBy",
      render: (createdBy) => {
        const user = users.find((user) => user.id === createdBy);
        return user ? user.name : "";
      },
    },
    {
      title: translation("endorsement.historical.attachedDocumentLabel"),
      dataIndex: "file",
      key: "file",
      className: "file-link",
      render: (file) =>
        file && file.link ? (
          <a href={file.link} target="_blank" rel="noopener noreferrer">
            {file.name}
          </a>
        ) : (
          ""
        ),
    },
  ];

  const getFormattedData = () => {
    return endorsements.map((endorsement) => {
      return {
        ...endorsement,
      };
    });
  };

  return (
    <Table
      className="gx-w-100 endorsement-historical"
      columns={columns}
      dataSource={getFormattedData()}
      pagination={pagination}
      size="middle"
    />
  );
};

Historical.propTypes = {
  policy: PropTypes.object,
  endorsements: PropTypes.array,
  users: PropTypes.array,
  pagination: PropTypes.object,
};

export default Historical;
