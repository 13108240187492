import React, { useEffect, useState } from 'react'
import { Select, Col, Row, Button, message, Modal } from 'antd'
import { LngInput, FormField } from 'components'
import selections from 'constants/selections'
import Translation from './translation'
import './styles.less'
import Axios from 'axios'
import { cloneObject } from '../../helpers'
import translation from './translation'

const WhatsAppSender = ({ code, phoneNumber }) => {
    const { Option } = Select
    const { whatsAppTemplateOptions } = selections

    const [selectedTemplateOption, setSelectedTemplateOption] = useState()
    const [templateMessage, setTemplateMessage] = useState(null)
    const [templateFieldState, setTemplateFieldState] = useState({})
    const [motiveFieldValue, setMotiveFieldValue] = useState('')
    
    const [fieldErrors, setFieldErrors] = useState([])
    const [loading, setLoading] = useState(false)

    const requestTemplateMessage = (event) => {
        setSelectedTemplateOption(event)
        setTemplateMessage(whatsAppTemplateOptions[event].message)
        setTemplateFieldState(whatsAppTemplateOptions[event].params)
        setFieldErrors([])
    }

    const onChangeMotiveField = (event) => {
        setMotiveFieldValue(event.target.value)
    }

    const changeTemplateParams = (fieldIndex, fieldValue) => {
        const paramToChange = document.querySelector(`#param_${fieldIndex}`)
        fieldValue ?
            paramToChange.innerHTML = fieldValue
            :
            paramToChange.innerHTML = `#${fieldIndex + 1}`
    }

    const onChangeParamField = (fieldIndex) => (event) => {
        const fieldValue = event.target.value
        let newFieldState = cloneObject(templateFieldState)
        newFieldState[fieldIndex].value = fieldValue
        setTemplateFieldState(newFieldState)
        changeTemplateParams(fieldIndex, fieldValue)
        setFieldErrors([])
    }

    const generateParamInputs = () => {
        return templateFieldState.map((stateObject, index) => {
            return (
                    <FormField key={index} error={fieldErrors[index]}>
                        <LngInput placeholder={`#${index + 1}`} onChange={onChangeParamField(index)}
                            value={stateObject.value} className="template-input" />
                    </FormField>
            )
        })
    }

    const verifyHasNonRequiredParam = () => {
        if (validateFields()) {
            let falseRequiredIndex = null
            templateFieldState.forEach((stateObject, index) => {
                if (!stateObject.value.length && stateObject.required === false) {
                    falseRequiredIndex = index + 1
                }
            })
            falseRequiredIndex ? Modal.confirm({
                title: <span>O parâmetro <span className="param_style">#{falseRequiredIndex}</span> não foi informado.</span>,
                cancelText: 'Voltar',
                content: (
                    <div className="warning-message">
                        <span style={{ fontSize: 15 }}>Tem certeza que quer enviar a mensagem sem um texto personalizado ?</span>
                    </div>
                ),
                onOk() {
                    sendWhatsAppMessage()
                },
                onCancel() {

                }
            }) : sendWhatsAppMessage()
        }
    }

    const validateFields = () => {
        let errors = {}
        templateFieldState.forEach((stateObject, index) => {
            if (!stateObject.value.length && stateObject.required) {
                errors[index] = `Preencha o parametro Nº ${index + 1}` //Se inserido pelo LngProvider (lib de tradução) o texto não é exibido
            }
        })
        if (Object.keys(errors).length) {
            setFieldErrors(errors)
            return false
        } else {
            return true
        }
    }

    const resetFields = () => {
        selectedTemplateOption &&
            setTemplateFieldState(whatsAppTemplateOptions[selectedTemplateOption].params)
            setMotiveFieldValue("")
    }

    const prepareToSendPayload = () => {
        let payload = {
            code: code,
            comment: motiveFieldValue,
            parameters: [],
            templateName: whatsAppTemplateOptions[selectedTemplateOption].value,
            to: `${phoneNumber}`
        }
        payload.parameters = templateFieldState.map((stateObject) => stateObject.value)
        return payload
    }

    const sendWhatsAppMessage = async () => {
        const payload = prepareToSendPayload()
        if (payload) {
            setLoading(true)
            await Axios.post(`${window._env_.API_GATEWAY_URL}/notification/v1/whatsapp/template/send`, payload)
                .then(() => {
                    message.success(translation.whatsAppSuccessSend)
                    resetFields()
                    setLoading(false)
                })
                .catch((error) => {
                    setLoading(false)
                    message.error(error.message)
                })
        }
    }

    useEffect(() => {
        resetFields()
    }, [templateMessage])

    return (
        <div className="chat-container">
            <Select style={{ width: '100%' }}
                onChange={requestTemplateMessage}
                placeholder={Translation.whatsAppTemplateOptionPlaceholder}
                value={selectedTemplateOption}
            >
                {
                    whatsAppTemplateOptions.map((option, index) => {
                        return <Option key={index} value={index}>{option.label}</Option>
                    })
                }
            </Select>
            {
                templateMessage &&
                <Row gutter={30}>
                    <Col md={24}>
                        <div className="template-message-card" dangerouslySetInnerHTML={{ __html: templateMessage }}></div>
                        <div className="input-area">
                            <span className="input-label">{Translation.whatsAppInputLabel}</span>
                            {
                                generateParamInputs()
                            }
                        </div>
                        <div className="input-area">
                            <span className="input-label">{Translation.whatsAppContactMotiveLabel}</span>
                            <LngInput maxLength={70} placeholder={Translation.whatsAppContactMotivePlaceholder}
                                onChange={onChangeMotiveField} value={motiveFieldValue} />
                        </div>
                        <div className="btn-send-area">
                            <Button loading={loading} className="gx-btn-outline-primary gx-mb-0" onClick={verifyHasNonRequiredParam} >ENVIAR WHATSAPP</Button>
                        </div>
                    </Col>
                </Row>
            }
        </div>
    )
}

export default WhatsAppSender