import React, { useRef } from "react";
//Custom Styles
import "./Styles.less";
//Components
import { Spin, Empty } from "antd";
import OrderItem from "./OrderItem";
import OrderPagination from "./OrderPagination";
//Constants
const imageStyle = { height: 120 };
//Functional Components
const NoOrders = () => {
  return (
    <div className="ordersList-emptyWrapper">
      <Empty
        imageStyle={imageStyle}
        description={<span>No orders found.</span>}
      />
    </div>
  );
};
const List = (props) => {
  const { data, onSelect, onCheck } = props;
  return data.map((order, index) => (
    <OrderItem
      key={index}
      index={index}
      order={order}
      onOrderSelect={onSelect}
      onOrderChecked={onCheck}
    />
  ));
};

const OrderList = (props) => {
  const {
    loadingOrders,
    orders,
    onOrderSelect,
    onOrderChecked,
    currentPage,
    fetchOrders,
  } = props;
  const myRef = useRef(null);

  function handlePageChange(page) {
    fetchOrders({ currentPage: page });
    scrollView();
  }

  const scrollView = () => {
    if (myRef)
      myRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
  };

  const ordersCheck = orders.length === 0 && !loadingOrders;

  return (
    <div className="ordersList-SpinWrapper">
      <section ref={myRef} />
      <Spin spinning={loadingOrders} tip="Loading Orders">
        <section className="ordersList-Wrapper">
          {ordersCheck ? (
            <NoOrders />
          ) : (
            <List
              data={orders}
              onCheck={onOrderChecked}
              onSelect={onOrderSelect}
            />
          )}
        </section>
      </Spin>
      <OrderPagination
        currentPage={currentPage}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default OrderList;
OrderList.defaultProps = {
  pageSize: 30,
};
