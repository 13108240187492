//Helper functions`
import { setInitialTranslation, prepareTranslations } from "helpers";
//Constants
const translation = setInitialTranslation("policy");

const keys = [
  {
    key: "downloadPolicyLabel",
    type: "label",
  },
  {
    key: "downloadSlipPaymentLabel",
    type: "label",
  },
  {
    key: "getPolicyLabel",
    type: "label",
  },
  {
    key: "paymentSlips",
    type: "label",
  },
  {
    key: "emission",
    type: "label",
  },
  {
    key: "emissionStatusProcessing",
    type: "label",
  },
  {
    key: "awsStatusProcessing",
    type: "label",
  },
  {
    key: "emissionStatusError",
    type: "label",
  },
  {
    key: "policy",
    type: "label",
  },
  {
    key: "policyFile",
    type: "label",
  },
  {
    key: "installmentLabel",
    type: "label",
  },
  {
    key: "dueDateLabel",
    type: "label",
  },
  {
    key: "amountLabel",
    type: "label",
  },
  {
    key: "policySendLabel",
    type: "label",
  },
  {
    key: "insurerLabel",
    type: "label",
  },
  {
    key: "SelectPlaceholder",
    type: "label",
  },
  {
    key: "slipSendLabel",
    type: "label",
  },
  {
    key: "slipValuePlaceholder",
    type: "label",
  },
  {
    key: "slipValueLabel",
    type: "label",
  },
  {
    key: "slipNumberPlaceholder",
    type: "label",
  },
  {
    key: "slipNumberLabel",
    type: "label",
  },
  {
    key: "slipDueDatePlaceholder",
    type: "label",
  },
  {
    key: "slipDueDateLabel",
    type: "label",
  },
];

export default prepareTranslations(keys, translation);
