// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const SIGN_IN_SHOW_LOADER = 'SIGN_IN_SHOW_LOADER';
export const SIGNIN_USER_FAILED = 'SIGNIN_USER_FAILED';

//Auth const
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//Users const
export const FETCH_USERS = 'FETCH_USERS';
export const FETCHING_USERS = 'FETCHING_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const SAVE_USER = 'SAVE_USER';
export const SAVING_USER = 'SAVING_USER';
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const SAVE_USER_FAILURE = 'SAVE_USER_FAILURE';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const SELECT_USER = 'SELECT_USER';
export const SELECT_USERS = 'SELECT_USERS';
export const FILTER_USERS = 'FILTER_USERS';
export const CLEAR_USER_SEARCH = 'CLEAR_USER_SEARCH';

//Common const
export const FETCH_ALL_PROFILES = 'FETCH_ALL_PROFILES';
export const FETCHING_ALL_PROFILES = 'FETCHING_ALL_PROFILES';
export const FETCH_ALL_PROFILES_SUCCESS = 'FETCH_ALL_PROFILES_SUCCESS';

/*CRM*/

//Orders const
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCHING_ORDERS = 'FETCHING_ORDERS';
export const FETCH_ORDERS_SUCCESS = 'FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_FAILURE = 'FETCH_ORDERS_FAILURE';
export const UPDATE_ORDERS_FILTER = 'UPDATE_ORDERS_FILTER';
export const FETCH_UPDATED_ORDERS_FILTER = 'FETCH_UPDATED_ORDERS_FILTER';

//Order const
export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCHING_ORDER = 'FETCHING_ORDER';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_FAILURE = 'FETCH_ORDER_FAILURE';
export const ASYNC_REMOVE_ERROR = 'ASYNC_REMOVE_ERROR';
export const SET_GLOBAL_LOADER = 'SET_GLOBAL_LOADER'

export const PREPARE_ORDER_STARTED = 'PREPARE_ORDER_STARTED';
export const PREPARE_ORDER_CONFIGURATIONS = 'PREPARE_ORDER_CONFIGURATIONS';
export const INITIALIZE_ORDERS = 'INITIALIZE_ORDERS';
export const INITIALIZE_ORDER_CONFIGURATIONS = 'INITIALIZE_ORDER_CONFIGURATIONS';
export const UPDATE_ORDER_CONFIGURATIONS = 'UPDATE_ORDER_CONFIGURATIONS';
export const CLEAR_ORDER_CONFIGURATIONS = 'CLEAR_ORDER_CONFIGURATIONS';
export const RESET_ORDER_CONFIGURATIONS = 'RESET_ORDER_CONFIGURATIONS';
export const RESET_ORDER_STARTED = 'RESET_ORDER_STARTED';
export const SAVE_ORDER = 'SAVE_ORDER';
export const SAVING_ORDER = 'SAVING_ORDER';
export const SAVE_ORDER_SUCCESS = 'SAVE_ORDER_SUCCESS';
export const SAVE_ORDER_FAILURE = 'SAVE_ORDER_FAILURE';
export const CHANGE_ORDER_CONFIGURATIONS = 'CHANGE_ORDER_CONFIGURATIONS';
export const SEARCH_COMPANY = 'SEARCH_COMPANY';
export const SEARCHING_COMPANY = 'SEARCHING_COMPANY';
export const SEARCH_COMPANY_FAILURE = 'SEARCH_COMPANY_FAILURE';
export const CHANGE_MINUTE_DATA = 'CHANGE_MINUTE_DATA';
export const REGISTER_EXPRESS_ERRORS = 'REGISTER_EXPRESS_ERRORS';

//Cargo Open
export const REQUEST_CARGO_QUOTATIONS = 'REQUEST_CARGO_QUOTATIONS';
export const REGISTER_CARGO_QUOTATIONS = 'REGISTER_CARGO_QUOTATIONS'
export const REGISTER_SELECTED_CARGO_QUOTATION = 'REGISTER_SELECTED_CARGO_QUOTATION'
export const REGISTER_SELECTED_INSURER = 'REGISTER_SELECTED_INSURER'

export const FETCH_COMPANY_DATA = 'FETCH_COMPANY_DATA';
export const SET_COMPANY_DATA = 'SET_COMPANY_DATA';

//Customers const
export const REGISTER_LIST = 'REGISTER_LIST'
export const REQUEST_CUSTOMERS_LIST = 'REQUEST_CUSTOMERS_LIST'
export const FETCH_CUSTOMER_DATA = 'FETCH_CUSTOMER_DATA'
export const FETCH_CUSTOMER_DATA_SUCCESS = 'FETCH_CUSTOMER_DATA_SUCCESS'
export const CHANGE_CUSTOMER_DATA = 'CHANGE_CUSTOMER_DATA'
export const SAVE_CUSTOMER_DATA = 'SAVE_CUSTOMER_DATA'
export const RESET_CUSTOMER_DATA = 'RESET_CUSTOMER_DATA'
export const CUSTOMER_DATA_LOADING = 'CUSTOMER_DATA_LOADING'
export const RESET_IS_CHANGED = 'RESET_IS_CHANGED'
export const IS_FETCHING_LIST = 'IS_FETCHING_LIST'
export const CHANGE_CUSTOMERS_TAB = 'CHANGE_CUSTOMERS_TAB'
export const REGISTER_PAGINATION_PARAM = 'REGISTER_PAGINATION_PARAM'
export const REQUEST_CUSTOMERS_CONTACTS = 'REQUEST_CUSTOMERS_CONTACTS'
export const REGISTER_CUSTOMERS_CONTACTS = 'REGISTER_CUSTOMERS_CONTACTS'
export const SAVE_CONTACTS_DATA = 'SAVE_CONTACTS_DATA'
export const REGISTER_CUSTOMERS_ERRORS  = 'REGISTER_CUSTOMERS_ERRORS'
export const DELETE_CUSTOMER_CONTACT = 'DELETE_CUSTOMER_CONTACT'
export const CREATE_CUSTOMER_CONTACT = 'CREATE_CUSTOMER_CONTACT'
export const REGISTER_CUSTOMER_CONTACTS_TAB = 'REGISTER_CUSTOMER_CONTACTS_TAB'
export const REQUEST_CUSTOMER_REGISTER_DATA = 'REQUEST_CUSTOMER_REGISTER_DATA'
export const SET_IS_CHANGED = 'SET_IS_CHANGED'
//currentPolicies
export const REQUEST_CUSTOMERS_POLICY_LIST = 'REQUEST_CUSTOMERS_POLICY_LIST'
export const REGISTER_CUSTOMERS_POLICY_LIST = 'REGISTER_CUSTOMERS_POLICY_LIST'
export const FETCH_CUSTOMER_POLICY = 'FETCH_CUSTOMER_POLICY'
export const FETCHING_CUSTOMER_POLICY = 'FETCHING_CUSTOMER_POLICY'
export const FETCH_CUSTOMER_POLICY_SUCCESS = 'FETCH_CUSTOMER_POLICY_SUCCESS'
export const FETCH_POLICY_TYPES = 'FETCH_POLICY_TYPES'
export const FETCHING_POLICY_TYPES = 'FETCHING_POLICY_TYPES'
export const FETCH_POLICY_TYPES_SUCCESS = 'FETCH_POLICY_TYPES_SUCCESS'
export const CHANGE_CUSTOMER_POLICY_DATA = 'CHANGE_CUSTOMER_POLICY_DATA'
export const RESET_POLICY_DATA_IS_CHANGED = 'RESET_POLICY_DATA_IS_CHANGED'
export const SAVE_CUSTOMER_POLICY_DATA = 'SAVE_CUSTOMER_POLICY_DATA'
export const CREATE_CUSTOMER_POLICY = 'CREATE_CUSTOMER_POLICY'
export const SAVING_CUSTOMER_POLICY_DATA = 'SAVING_CUSTOMER_POLICY_DATA'
export const CLEAR_CUSTOMER_POLICY_DATA = 'CLEAR_CUSTOMER_POLICY_DATA'
export const REGISTER_POLICY_DATA_ERRORS = 'REGISTER_POLICY_DATA_ERRORS'
export const REMOVE_POLICY_FILE = 'REMOVE_POLICY_FILE'
export const REMOVING_POLICY_FILE = 'REMOVING_POLICY_FILE'
export const SET_DATA_IS_SAVED = 'SET_DATA_IS_SAVED'
export const SET_CURRENT_POLICY_TAB = 'SET_CURRENT_POLICY_TAB'
export const SEND_POLICY_BY_EMAIL = 'SEND_POLICY_BY_EMAIL';
export const IS_SENDING_POLICY_BY_EMAIL = 'IS_SENDING_POLICY_BY_EMAIL';

export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';

export const CHANGE_TAB = "CHANGE_TAB"

//invoice
export const CREATE_NEW_INVOICE = "CREATE_NEW_INVOICE";
export const SAVING_INVOICE_DATA = 'SAVING_INVOICE_DATA';
export const CHANGE_INVOICE_DATA = 'CHANGE_INVOICE_DATA';
export const INVOICE_DATA_IS_CHANGED = 'INVOICE_DATA_IS_CHANGED';
export const REGISTER_INVOICE_ERRORS = 'REGISTER_INVOICE_ERRORS';
export const CLEAR_INVOICE_DATA = 'CLEAR_INVOICE_DATA';
export const UPDATE_INVOICE_DATA = 'UPDATE_INVOICE_DATA';
export const REMOVE_INVOICE_FILE = 'REMOVE_INVOICE_FILE';
export const SET_INVOICE_MODAL_VISIBLE = 'SET_INVOICE_MODAL_VISIBLE';
export const FETCH_INVOICE_DATA = 'FETCH_INVOICE_DATA';
export const SET_INVOICE_DATA = 'SET_INVOICE_DATA';
export const SEND_INVOICE_BY_EMAIL = 'SEND_INVOICE_BY_EMAIL';
export const SEND_INVOICE_MANUAL_EMAIL = 'SEND_INVOICE_MANUAL_EMAIL';
export const IS_SENDING_INVOICE_BY_EMAIL = 'IS_SENDING_INVOICE_BY_EMAIL';