import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { requestCustomersList } from 'appRedux/actionCreators'
import translation from './translation'
import NumberFormat from "react-number-format";
import PaginationComponent from './PaginationComponent'
export default () => {

    const { list, isFetching } = useSelector(state => state.crm.customers)
    const history = useHistory()
    const dispatch = useDispatch()

    const handleOpenCustomer = (id) => {
        history.push(`customers/${id}`)
    }
    useEffect(() => {
        dispatch(requestCustomersList())
    }, [])

    return (
        <PaginationComponent list={list} requestAction={requestCustomersList}>
            { list.length && !isFetching ?
                <Row type="flex" justify="center" className="table-wrapper">
                    <Col md={6}>
                        {translation.listHeaderDocumentLabel}
                    </Col>
                    <Col md={12}>
                        {translation.listHeaderNameLabel}
                    </Col>
                    <Col md={6}>
                        {translation.listHeaderActionLabel}
                    </Col>
                </Row>
                : null
            }
            {  list.length && !isFetching ? list.map((customer, index) => (
                <Row key={index} type="flex" justify="center"
                    className="table-wrapper table-content clickable-item"
                    onClick={() => handleOpenCustomer(customer.id)}
                >
                    <Col md={6}>
                        <NumberFormat
                            format="##.###.###/####-##"
                            displayType="text"
                            value={customer.documentNumber}
                        />
                    </Col>
                    <Col md={12}>
                        {customer.companyName}
                    </Col>
                    <Col md={6}>
                        {translation.listContentActionLabel}
                    </Col>
                </Row>))
                : !isFetching &&
                <div className="empty-list">{translation.emptyListLabel}</div>
            }
        </PaginationComponent>
    )
}