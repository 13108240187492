import enLang from "./entries/en-US";
import esLang from "./entries/es-ES";
import ptLang from "./entries/pt-BR";
import {addLocaleData} from "react-intl";

const AppLocale = {
  pt: ptLang,
  en: enLang,
  es: esLang,
};

addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.es.data);

export default AppLocale;
