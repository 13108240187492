/*QueryString*/
import * as  queryString from "query-string";
/*Redux Saga Effects*/
import { put, takeLatest, select, all, call } from "redux-saga/effects";
import { push } from 'connected-react-router';
/*Action Types*/
import * as types from '../../actionTypes';
/*Action Creators*/
import * as actions from '../../actionCreators';
//Selectors
import * as selectors from '../../selectors';
//Helpers
import { prepareOrderSave, getSearchValue } from './helpers';
import Request from 'helpers/Request';
import { validateResponse, displayError, displaySuccess, formatMaskInput, isEmptyObject, formatErrors } from 'helpers';
import { message } from "antd";
// moment
import moment from "moment";
//Constants
const queryStringConfig = { arrayFormat: 'comma' };
const pageSize = 30;


// Initialize  order's
export function* initializeOrders(action) {
  const { authUser } = action;
  try {
    const fetched = yield select(selectors.hasFetchedOrders);

    yield put(actions.initializeOrdersConfigurations(authUser));

    if (!fetched)
      yield put(actions.fetchOrders({ types: authUser.products }));

  } catch (error) {
    displayError("Please check your internet connection!");
  }
}

// Fetch  order's list
export function* fetchOrders(action) {

  yield put({ type: types.FETCHING_ORDERS });

  function prepareOrdersUrl(filters, currentPage) {
    const { stage, types, searches } = filters;

    function formatTypes(types) {
      let updatedTypes = [];
      for (let type in types) {
        if (types.hasOwnProperty(type)) {
          const filterValue = types[type];
          if (filterValue)
            updatedTypes.push(type);
        }
      }
      return updatedTypes;
    }

    const queryParams = {
      ...searches,
      status: stage,
      page: currentPage - 1,
      quantity: pageSize,
      types: formatTypes(types)
    };

    return `/insurance/v1?${queryString.stringify(queryParams, queryStringConfig)}`;
  }

  function hasArg(attr) {
    return config && config.hasOwnProperty(attr);
  }

  const { config } = action;


  try {
    let ordersCurrentPage, ordersFilters = yield select(selectors.extractOrdersFilter);

    if (hasArg("currentPage"))
      ordersCurrentPage = config.currentPage;
    else
      ordersCurrentPage = yield select(selectors.extractOrderListCurrentPage);

    if (hasArg("types")) {
      const orderTypes = ordersFilters.types;
      for (let product in orderTypes) {
        if (orderTypes.hasOwnProperty(product))
          orderTypes[product] = config.types.includes(product)
      }
    }

    const ordersUrl = prepareOrdersUrl(ordersFilters, ordersCurrentPage);

    const response = yield Request.get(ordersUrl);

    const { success, payloadData } = yield validateResponse({ response, displaySuccessMessage: false, displayErrorMessage: false });

    if (success) {
      yield put({
        type: types.FETCH_ORDERS_SUCCESS,
        payload: payloadData,
        currentPage: ordersCurrentPage
      });
    } else {
      yield put({ type: types.FETCH_ORDERS_FAILURE });
    }
  } catch (error) {
    yield put({ type: types.FETCH_ORDERS_FAILURE });
    displayError("Please check your internet connection!");
  }
}

//Fetch Updated OrdersC
export function* fetchUpdatedOrders(action) {
  const { filterType, value } = action;
  try {
    yield put({
      type: types.UPDATE_ORDERS_FILTER,
      filterType: filterType,
      value: value
    });
    const isFilterOrdersUpdated = yield select(selectors.isFilterOrdersUpdated);

    if (isFilterOrdersUpdated)
      yield put({
        type: types.FETCH_ORDERS
      })

  } catch (error) {
    displayError("Please check your internet connection!");
  }
}

//Fetch  order
export function* fetchOrder(action) {
  const { id } = action;
  try {
    yield put({ type: types.FETCHING_ORDER });
    const response = yield Request.get(`/insurance/api-wrapper/v1/${id}`);
    const { success, payloadData } = yield validateResponse({ response, displaySuccessMessage: false });
    if (success) {
      yield put(actions.prepareOrderStarted());
      yield put(actions.prepareOrderConfigurations(payloadData));
      yield put({
        id: id,
        payload: payloadData,
        type: types.FETCH_ORDER_SUCCESS
      });
    } else {
      yield put(push('/crm/orders'));
    }
  } catch (error) {
    yield put({ type: types.FETCH_ORDER_FAILURE });
    displayError("Ocorreu um erro não previsto (" + error + ")");
  }
}

//Search Company
export function* searchCompany(action) {
  const { entity } = action;
  const orderConfigurations = yield select(selectors.extractOrderConfigurations);
  try {
    yield put({ type: types.SEARCHING_COMPANY });
    const searchValue = getSearchValue(entity, orderConfigurations);
    const response = yield Request.get(`/person/v1/company?documentNumber=${formatMaskInput(searchValue)}`);
    const { success, payloadData } = yield validateResponse({ response, displaySuccessMessage: false });

    if (success) {
      yield put(actions.updateOrderConfigurations(entity, payloadData));
    } else {
      yield put({ type: types.SEARCH_COMPANY_FAILURE });
    }
  } catch (error) {
    yield put({ type: types.SEARCH_COMPANY_FAILURE });
    displayError("Ocorreu um erro não previsto (" + error + ")");
  }
}

//Save Order
export function* saveOrder(action) {
  const { config: { id, callback, externalChanges, selectedTab } } = action;

  yield put({ type: types.SAVING_ORDER });

  const orders = yield select(selectors.extractAvailableOrders);
  const configurations = yield select(selectors.extractOrderConfigurations);
  const order = orders[id];


  try {
    const { url, data } = prepareOrderSave(order, configurations, externalChanges, selectedTab);

    const response = yield Request.put(url, data);

    const { success, payloadData } = yield validateResponse({ response, successMessage: "Alterações salvas com sucesso" });

    if (success && payloadData) {
      yield put({
        id,
        type: types.SAVE_ORDER_SUCCESS,
        payload: payloadData
      });

      yield put(actions.prepareOrderStarted());

      yield put(actions.prepareOrderConfigurations(payloadData));

      if (callback)
        callback();
    }
  } catch (error) {
    yield put({ type: types.SAVE_ORDER_FAILURE });
    displayError("Please check your internet connection!");
  }
}

export function* removeError(params) {
  const { payload } = params
  const errors = JSON.parse(JSON.stringify(yield select(selectors.getOrderErrors)))
  if (Object.keys(errors).length > 0) {
    let filteredErrors = {}
    if (payload === 'all') {
      yield put({ type: types.SAVE_ORDER_FAILURE, payload: { errors: {} } });
    } else {
      Object.keys(errors).forEach(keys => {
        if (keys !== payload) {
          filteredErrors[keys] = errors[keys]
        }
      })
      yield put({ type: types.SAVE_ORDER_FAILURE, payload: { errors: filteredErrors } })
    }
  }
}

export function* requestCargoQuotations(payload) {
  yield put({
    type: types.REGISTER_CARGO_QUOTATIONS,
    payload: {
      bestQuotation: {},
      hasAvailable: false,
      loading: true,
      error: null,
      quotations: [],
      chosenInsurer: '',
    }
  })
  let responseBody = {}
  yield Request.get(`/cargo/v1/open-policy/${payload.code}/quotation`)
    .then((response) => response.json())
    .then((response) => responseBody = response)
  if (!isEmptyObject(responseBody)) {
    if (responseBody.hasOwnProperty('error')) {
      yield put({
        type: types.REGISTER_CARGO_QUOTATIONS,
        payload: {
          bestQuotation: {},
          hasAvailable: false,
          loading: false,
          chosenInsurer: '',
          quotations: [],
          error: responseBody
        }
      })
    } else {
      const bestChoice = responseBody.quotations.filter(quote => quote.bestChoice)[0]
      const acceptedInsurer = responseBody.quotations.filter(quote => quote.acceptedAt)[0]
      yield put({
        type: types.REGISTER_CARGO_QUOTATIONS,
        payload: {
          bestQuotation: bestChoice,
          hasAvailable: responseBody.hasAvailable,
          quotations: responseBody.quotations,
          loading: false,
          chosenInsurer: acceptedInsurer ? acceptedInsurer.insurer : ''
        }
      })
    }
  } else {
    yield put({
      type: types.REGISTER_CARGO_QUOTATIONS,
      payload: {
        bestQuotation: {},
        hasAvailable: false,
        loading: false,
        quotations: [],
        chosenInsurer: ''
      }
    })
  }
}

export function* registerSelectedCargoQuotation(actionData) {
  const { payload } = actionData

  yield put(actions.setGlobalLoader(true))
  const response = yield Request.put(`/cargo/v1/open-policy/${payload.code}/quotation-data`, { chosenInsurer: payload.insurer })
  if (response.status !== 200) {
    yield put(actions.setGlobalLoader(false))
    response.json()
      .then((res) => {
        displayError(res.message, 'Ocorreu um erro inesperado.')
      })
  } else {
    yield put(actions.setGlobalLoader(false))
    displaySuccess('', 'Cotação selecionada com sucesso', '')
  }
}

export function* fetchCompanyData(action) {
  const { payload: {documentNumber, id} } = action;

  yield put(actions.setGlobalLoader(true));

  const response = yield Request.get(
    `/person/v1/company?documentNumber=${formatMaskInput(documentNumber)}`
  )
    .then((response) => response.json())
    .catch((error) => {
      return { error };
    });
  if (response.error) {
    yield put(actions.setGlobalLoader(false));
    message.error(response.message);
  } else {
    yield put({
      type: types.SET_COMPANY_DATA,
      payload: response,
      id: id
    });
    yield put(actions.setGlobalLoader(false));
  }
}

// customer -----------------------------------------------------------------

// TODO - TODAS AS REQUISIÇÕES DE CUSTOMERS DEVEM SER REFATORADAS, SUBSTITUINDO A FUNÇÃO REQUEST PELO AXIOS
export function* requestCustomersList(payload) {
  const { params } = payload
  const queryString = new URLSearchParams({ ...params }).toString();

  yield put(actions.isFetchingList(true))

  const response = yield Request.get(`/person/v2/?${queryString}`)
    .then((responseData) => responseData.json())
    .catch((error) => {
      return { error }
    })
  if (response.error) {
    yield put(actions.isFetchingList(false))
    message.error(`Ocorreu um erro ao requisitar lista de clientes ${response.message || ''}`)
  } else {
    yield put(actions.isFetchingList(false))
    yield put(actions.registerList(response))
  }
}

export function* fetchCustomerData(action) {
  const { id } = action

  yield put(actions.customerDataLoading(true))
  yield put(actions.resetIsChanged())

  const response = yield Request.get(`/person/v2/${id}`)
    .then((responseData) => responseData.json())
    .catch((error) => {
      return { error }
    })
  if (response.error) {
    yield put(actions.customerDataLoading(false))
    message.error(response.message)
  } else {
    yield put(actions.customerDataLoading(false))
    yield put({
      type: types.FETCH_CUSTOMER_DATA_SUCCESS,
      payload: response
    })
  }
}

export function* saveCustomerData() {
  const registerData = yield select(selectors.getRegisterData)
  const { 
    id,
    documentNumber,
    additionalData,
    neighborhood,
    companyName,
    personType,
    address,
    zipCode,
    number,
    state,
    city,
  } = registerData

  yield put(actions.customerDataLoading(true))
  yield put(actions.resetIsChanged())

  const response = yield Request.put(`/person/v2/${id}`, {
    documentNumber,
    additionalData,
    neighborhood,
    companyName,
    personType,
    address,
    zipCode,
    number,
    state,
    city,
  }).catch((error) => {
    return { error }
  })
  if (response.status !== 200) {
    yield put(actions.customerDataLoading(false))
    if (response.status == 422) {
      let fieldErrors = []
      yield response.json()
        .then((res) => {
          if (Array.isArray(res.errors)) {
            fieldErrors = res.errors;
            message.error(res.message);
          } else {
            message.error(res.errors);
          }
        })
      yield put(actions.registerCustomersErros( formatErrors(fieldErrors) ))
    } else {
      response.json()
        .then((res) => {
          message.error(res.message)
        })
    }
  } else {
    yield put(actions.customerDataLoading(false))
    message.success("Dados salvos com sucesso!")
    return new Promise((resolve) => {
      resolve()
    })
  }
}

export function* requestCustomersPolicy( actionPayload ) {
  const { payload } = actionPayload
  const { code, ...params } = payload

  const queryString = new URLSearchParams({ ...params }).toString();

  yield put(actions.isFetchingList(true))

  const response = yield Request.get(`/policy/v1/${code}/?${queryString}` )
    .then((responseData) => responseData.json())
    .catch((error) => {
      return { error }
    })
  if (response.error) {
    yield put(actions.isFetchingList(false))
    message.error(response.message)
  }
  else {
    yield put(actions.isFetchingList(false))
    yield put(actions.registerCustomersPolicy(response))
  }

}

function* requestCustomersContacts(payload) {
  const { id } = payload
  yield put(actions.isFetchingList(true))
  yield put(actions.registerCustomersContacts({ data: { data:[] }, isChanged: false }))
  yield put(actions.registerCustomersErros( {errors:{}} ))

  const response = yield Request.get(`/person/v2/${id}/contact`)
    .then(responseData => responseData.json())
    .catch((error) => {
      return { error }
    })
  if (response.error) {
    yield put(actions.isFetchingList(false))
    message.error(response.message)
  }
  else {
    yield put(actions.isFetchingList(false))
    yield put(actions.registerCustomersContacts({ data: response, isChanged: false }))
  }
}

function* saveContactsData(actionPayload) {
  const { payload } = actionPayload
  yield put(actions.registerCustomersErros( {errors:{}} ))
  yield put(actions.isFetchingList(true))

  const response = yield Request.put(`/person/v2/${payload.id}/contact/${payload.email}`, payload).catch((error) => {
    return { error }
  })
  if (response.status !== 200) {
    yield put(actions.isFetchingList(false))

    if (response.status == 422) {
      let fieldErrors = []
      yield response.json()
        .then((res) => {
          if (Array.isArray(res.errors)) {
            fieldErrors = res.errors;
            message.error(res.message);
          } else {
            message.error(res.errors);
          }
        })
      yield put(actions.registerCustomersErros(formatErrors(fieldErrors)));
    } else {
      response.json()
        .then((res) => {
          message.error(res.message)
        })
    }
  } else {
    yield put(actions.isFetchingList(false))
    yield put(actions.resetIsChanged())
    message.success("Dados salvos com sucesso!")
  }
}

function* deleteCustomerContact(actionPayload) {
  const { payload } = actionPayload
  yield put(actions.isFetchingList(true))
  const response = yield Request.delete(`/person/v2/${payload.id}/contact/${payload.email}`).catch((error) => {
    return { error }
  })
  if (response.status !== 200) {
    yield put(actions.isFetchingList(false))
    response.json()
      .then((res) => {
        message.error(res.message)
      })
  } else {
    message.success("Contato excluído com sucesso!")
    yield put(actions.isFetchingList(false))
    yield put(actions.requestCustomersContacts(payload.id))
  }
}

function* createCustomerContact(actionPayload) {
  const { payload } = actionPayload

  yield put(actions.isFetchingList(true))

  const response = yield Request.post(`/person/v2/${payload.id}/contact`, payload).catch((error) => {
    return { error }
  })
  if (response.status !== 200) {
    yield put(actions.isFetchingList(false))

    if (response.status == 422) {
      let fieldErrors = []
      yield response.json()
        .then((res) => {
          if (Array.isArray(res.errors)) {
            fieldErrors = res.errors;
            message.error(res.message);
          } else {
            message.error(res.errors);
          }
        })
      yield put(actions.registerCustomersErros( formatErrors(fieldErrors) ))
    } else {
      response.json()
        .then((res) => {
          message.error(res.message)
        })
    }
  } else {
    yield put(actions.isFetchingList(false))
    yield put(actions.resetIsChanged())
    message.success("Contato registrado com sucesso!")
    yield put(actions.requestCustomersContacts(payload.id))
  }
}

export function* fetchCustomerPolicyData( action ) {
  const { id, policy } = action.payload

  yield put(actions.fetchingCustomerPolicyData(true))

  const response = yield Request.get(`/policy/v1/${id}/${policy}`)
    .then((responseData) => responseData.json())
    .catch((error) => {
      return { error }
    })
  if( response.error ){
    yield put(actions.fetchingCustomerPolicyData(false))
    message.error(response.error)
  } 
  else {
    yield put(actions.fetchingCustomerPolicyData(false))
    yield put(actions.fetchCustomerPolicyDataSuccess(response))
  }
}

export function* fetchPolicyTypes() {

  yield put(actions.fetchingPolicyTypes(true))

  const response = yield Request.get(`/policy/v1/policy-types`)
    .then((responseData) => responseData.json())
    .catch((error) => {
      return { error }
    })
  if( response.error ){
    yield put(actions.fetchingPolicyTypes(false))
    message.error(response.error)
  } 
  else {
    yield put(actions.fetchingPolicyTypes(false))
    yield put(actions.fetchPolicyTypesSuccess(response))
  }
}

export function* saveCustomerPolicyData( action ) {
  const { id, policy } = action.payload
  
  const policyData = yield select(selectors.getCustomerPolicyData)

  yield put(actions.savingCustomerPolicyData(true))
  
  const response = yield Request.put(`/policy/v1/${id}/${policy}`, policyData).catch((error) => {
    return { error }
  })
  
  if(response.status !== 200){
    yield put(actions.savingCustomerPolicyData(false))
    let error = yield call([response, 'json'])
    yield put(actions.registerPolicyDataErrors(error))
    message.error(error.message)
  }else{
    yield put(actions.savingCustomerPolicyData(false))
    yield put(actions.resetPolicyDataIsChanged())
    message.success("Dados salvos com sucesso!")
  }
}

export function* createCustomerPolicy( action ) {

  const { id } = action
  const policyData = yield select(selectors.getCustomerPolicyData)

  yield put(actions.savingCustomerPolicyData(true))
  
  const response = yield Request.post(`/policy/v1/${id}`, policyData).catch((error) => {
    return { error }
  })
  if(response.status !== 200){
    yield put(actions.savingCustomerPolicyData(false))
    let error = yield call([response, 'json'])
    yield put(actions.registerPolicyDataErrors(error))
    yield put(actions.setDataIsSaved(false))
    message.error(error.message)
  }else{
    yield put(actions.savingCustomerPolicyData(false))
    yield put(actions.resetPolicyDataIsChanged())
    yield put(actions.setDataIsSaved(true));
    message.success("Apólice criada com sucesso!")
  }
}

export function* removePolicyFile( action ) {

  const { params: {id, policy}, fileName } = action

  yield put(actions.removingPolicyFile(true))
  
  const response = yield Request.delete(`/policy/v1/${id}/${policy}/upload-file/${fileName}`).catch((error) => {
    return { error }
  })
  if(response.status !== 200){
    yield put(actions.removingPolicyFile(false))
    response.json().then((error) => message.error(error?.message) )
    
  }else{
    yield put(actions.changeCustomerPolicyData({
      name: "policyFile",
      value: null
    }))
    yield put(actions.removingPolicyFile(false))
    message.success("Arquivo de apólice removido com sucesso!")
  }
}

export function* sendPolicyByEmail({ payload }) {
  const { id, policy } = payload;

  yield put(actions.setIsSendingPolicyByEmail(true));

  const response = yield Request.put(
    `/policy/v1/${id}/${policy}/send-email`
  ).catch((error) => {
    return { error };
  });
  if (response.status !== 200) {
    yield put(actions.setIsSendingPolicyByEmail(false));
    response.json().then((error) => message.error(error.message));
  } else {
    yield put(actions.changeCustomerPolicyData({
      name: "emailStatus",
      value: "SENT"
    }))
    yield put(actions.changeCustomerPolicyData({
      name: "emailSentAt",
      value: moment().format()
    }))
    yield put(actions.setIsSendingPolicyByEmail(false));
    message.success("Apólice enviada com sucesso!");
  }
}

// invoice ---------------------------------------------------

export function* createNewInvoice(action) {
  const { policyId } = action;
  const { recurrenceMonth, dueDate, tax, premium, netPremium } = yield select(
    selectors.getInvoiceData
  );

  yield put(actions.savingInvoiceData(true));

  const response = yield Request.post(`/policy/v1/${policyId}/invoice`, {
    recurrenceMonth,
    dueDate,
    tax,
    premium,
    netPremium,
  }).catch((error) => {
    return { error };
  });
  if (response.status !== 200) {
    yield put(actions.savingInvoiceData(false));
    yield put(actions.setInvoiceModalVisible(true));
    let error = yield call([response, "json"]);
    if (error.errors) {
      yield put(actions.registerInvoiceErrors(error.errors));
    }else{
      message.error(error.message);
    }
  } else {
    yield put(actions.savingInvoiceData(false));
    yield put(actions.setInvoiceModalVisible(false));
    yield put(actions.invoiceDataIsChanged(false));
    message.success("Fatura criada com sucesso!");
  }
}

export function* updateInvoiceData(action) {
  const { policy, invoice } = action.payload;
  const { recurrenceMonth, dueDate, tax, premium, netPremium } = yield select(
    selectors.getInvoiceData
  );

  yield put(actions.savingInvoiceData(true));

  const response = yield Request.put(
    `/policy/v1/${policy}/invoice/${invoice}`,
    {
      recurrenceMonth,
      dueDate,
      tax,
      premium,
      netPremium,
    }
  ).catch((error) => {
    return { error };
  });
  if (response.status !== 200) {
    yield put(actions.savingInvoiceData(false));
    yield put(actions.setInvoiceModalVisible(true));
    let error = yield call([response, "json"]);
    if (error.errors) {
      yield put(actions.registerInvoiceErrors(error.errors));
    }else{
      message.error(error.message);
    }
  } else {
    yield put(actions.savingInvoiceData(false));
    yield put(actions.setInvoiceModalVisible(false));
    yield put(actions.invoiceDataIsChanged(false));
    message.success("Fatura atualizada com sucesso!");
  }
}

export function* removeInvoiceFile(action) {
  const { fileType, policy, recurrenceMonth } = action.payload;
  
  yield put(actions.savingInvoiceData(true));

  const response = yield Request.delete(
    `/policy/v1/${policy}/upload/${recurrenceMonth}/${fileType}`
  ).catch((error) => {
    return { error };
  });
  if (response.status !== 200) {
    yield put(actions.savingInvoiceData(false));
    message.error("Não foi possível remover o arquivo, tente novamente");
  } else {
    yield put(actions.savingInvoiceData(false));
    if(fileType === "invoice") {
      yield put(actions.changeInvoiceData({ hasInvoice: false }));
      message.success("Arquivo da fatura removido com sucesso!");
    }else{
      yield put(actions.changeInvoiceData({ hasPaymentSlip: false }));
      message.success("Arquivo do boleto removido com sucesso!");
    }
  }
}

export function* fetchInvoiceData(action) {
  const { policy, invoice } = action.payload;
  
  yield put(actions.savingInvoiceData(true));

  const response = yield Request.get(
    `/policy/v1/${policy}/invoice/${invoice}`
  ).catch((error) => {
    return { error };
  });

  if (response.status !== 200) {
    yield put(actions.savingInvoiceData(false));
    message.error("Não foi possível recuperar os dados da fatura, tente novamente");
  } else {
    yield put(actions.savingInvoiceData(false));
    let invoiceData = yield call([response, 'json'])
    if(invoiceData){
      yield put(actions.setInvoiceData(invoiceData));
    }
  }
}

export function* sendInvoiceByEmail({ payload }) {
  const { policy, invoiceId } = payload;

  yield put(actions.setIsSendingInvoiceByEmail(true));

  const response = yield Request.put(
    `/policy/v1/${policy}/invoice/${invoiceId}/send-email`
  ).catch((error) => {
    return { error };
  });
  if (response.status !== 200) {
    yield put(actions.setIsSendingInvoiceByEmail(false));
    response.json().then((error) => message.error(error.message));
  } else {
    yield put(actions.changeInvoiceData({
      emailStatus: "SENT"
    }))
    yield put(actions.changeInvoiceData({
      emailSentAt: moment().format()
    }))
    yield put(actions.setIsSendingInvoiceByEmail(false));
    message.success("Boleto enviado com sucesso!");
  }
}

// customer data --------------------------------------------------------

export function* requestCustomerRegisterData(action) {
  const { payload } = action;
  const registerData = yield select(selectors.getRegisterData);
  yield put(actions.customerDataLoading(true));

  const response = yield Request.get(
    `/person/v1/company?documentNumber=${formatMaskInput(payload)}`
  )
  .then((response) => response.json())
  .catch((error) => { return { error } })
  if (response.error) {
    yield put(actions.customerDataLoading(false));
    message.error(`Ocorreu um erro: ${response.message} `)
  } else {
    yield put({
      type: types.FETCH_CUSTOMER_DATA_SUCCESS, 
      payload: {
        ...registerData,
        documentNumber:response.documentNumber,
        name:response.name,
        address:response.address.address,
        number:response.address.addressNumber,
        additionalData:response.address.additionalData,
        neighborhood:response.address.neighborhood,
        zipCode: formatMaskInput(response.address.zipCode),
        state:response.address.state,
        city:response.address.city,
      }
    })
    yield put(actions.setIsChanged())
    yield put(actions.customerDataLoading(false));
  }
}
export default function* rootSaga() {
  yield all([
    takeLatest(types.INITIALIZE_ORDERS, initializeOrders),
    takeLatest(types.FETCH_ORDERS, fetchOrders),
    takeLatest(types.FETCH_UPDATED_ORDERS_FILTER, fetchUpdatedOrders),
    takeLatest(types.FETCH_ORDER, fetchOrder),
    takeLatest(types.SEARCH_COMPANY, searchCompany),
    takeLatest(types.SAVE_ORDER, saveOrder),
    takeLatest(types.ASYNC_REMOVE_ERROR, removeError),
    takeLatest(types.REQUEST_CARGO_QUOTATIONS, requestCargoQuotations),
    takeLatest(types.REGISTER_SELECTED_CARGO_QUOTATION, registerSelectedCargoQuotation),
    takeLatest(types.FETCH_COMPANY_DATA, fetchCompanyData),
    // customers
    takeLatest(types.REQUEST_CUSTOMERS_LIST, requestCustomersList),
    takeLatest(types.FETCH_CUSTOMER_DATA, fetchCustomerData),
    takeLatest(types.SAVE_CUSTOMER_DATA, saveCustomerData),
    takeLatest(types.RESET_CUSTOMER_DATA, fetchCustomerData),
    takeLatest(types.REQUEST_CUSTOMERS_POLICY_LIST, requestCustomersPolicy),
    takeLatest(types.REQUEST_CUSTOMERS_CONTACTS, requestCustomersContacts),
    takeLatest(types.SAVE_CONTACTS_DATA, saveContactsData),
    takeLatest(types.DELETE_CUSTOMER_CONTACT, deleteCustomerContact),
    takeLatest(types.CREATE_CUSTOMER_CONTACT,createCustomerContact),
    takeLatest(types.FETCH_CUSTOMER_POLICY, fetchCustomerPolicyData),
    takeLatest(types.FETCH_POLICY_TYPES, fetchPolicyTypes),
    takeLatest(types.SAVE_CUSTOMER_POLICY_DATA, saveCustomerPolicyData),
    takeLatest(types.CREATE_CUSTOMER_POLICY, createCustomerPolicy),
    takeLatest(types.REMOVE_POLICY_FILE, removePolicyFile),
    takeLatest(types.SEND_POLICY_BY_EMAIL, sendPolicyByEmail),
    //invoice
    takeLatest(types.CREATE_NEW_INVOICE, createNewInvoice),
    takeLatest(types.UPDATE_INVOICE_DATA, updateInvoiceData),
    takeLatest(types.REMOVE_INVOICE_FILE, removeInvoiceFile),
    takeLatest(types.FETCH_INVOICE_DATA, fetchInvoiceData),
    takeLatest(types.SEND_INVOICE_BY_EMAIL, sendInvoiceByEmail),
    //customer data
    takeLatest(types.REQUEST_CUSTOMER_REGISTER_DATA, requestCustomerRegisterData)
  ]);
}