//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("contacts");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    contactLabel: getTranslation("contactLabel"),

    contactMobilePhoneLabel: getTranslation("contactMobilePhoneLabel"),
    contactMobilePhonePlaceholder: getTranslation("contactMobilePhonePlaceholder"),
    contactMobilePhoneTooltipTitle: getTranslation("contactMobilePhoneTooltipTitle"),

    contactPhoneLabel: getTranslation("contactPhoneLabel"),
    contactPhonePlaceholder: getTranslation("contactPhonePlaceholder"),
    contactPhoneTooltipTitle: getTranslation("contactPhoneTooltipTitle"),

    contactWhatsappLabel: getTranslation("contactWhatsappLabel"),
    contactWhatsappPlaceholder: getTranslation("contactWhatsappPlaceholder"),
    contactWhatsappTooltipTitle: getTranslation("contactWhatsappTooltipTitle"),

    remove: getTranslation("remove", true),
    add: getTranslation("add", true),
    contactRemove: getTranslation("contactRemove"),

    contactAdd: getTranslation("contactAdd"),
    contactAddNumber: getTranslation("contactAddNumber"),

    contactSelectPhoneType: getTranslation("contactSelectPhoneType"),
    contactPhoneNumberPlaceholder: getTranslation("contactPhoneNumberPlaceholder", false, true),
    contactNameLabel: getTranslation("contactNameLabel"),
    contactNamePlaceholder: getTranslation("contactNamePlaceholder", false, true),
    contactEmailAddressLabel: getTranslation("contactEmailAddressLabel"),
    contactEmailAddressPlaceholder: getTranslation("contactEmailAddressPlaceholder", false, true),
    contactEmptyLabel: getTranslation("contactEmptyLabel")
}