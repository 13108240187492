import React from "react";
// antd
import { Col, Row } from "antd";
//translation
import translation from "./translation";
import { useTranslation } from "react-i18next";
// helpers
import {
  getFormattedDate,
  formatMoney,
  formatPercentage,
  getFormattedPhone,
} from "../../../../helpers";
// constants
import { orderProductTypes } from "../../../../constants";
import selections from "../../../../constants/selections";

const {
  orderProducts,
  bussinessPartners,
  registerApplicationTypes,
} = selections;

const PolicyStaticdata = ({ policy = {}, types, users = [] }) => {
  const { t: Translation } = useTranslation();

  const getProductType = () => {
    if (!(policy.type && policy.type.product)) return "";
    const productType = orderProducts.find(
      (item) => policy.type.product === item.value
    );
    return productType ? productType.label : "";
  };

  const getProductInsurer = () => {
    if (!(policy.type && policy.type.product)) return "";
    const productInsurer = selections.insurerByProduct[
      policy.type.product
    ].find((item) => policy.insurer === item.value);
    return productInsurer ? productInsurer.label : "";
  };

  const getProductPolicyType = () => {
    if (!(types.length > 0 && policy.type && policy.type.id)) return "";
    const productPolicyType = types.find((item) => policy.type.id === item.id);
    return productPolicyType ? productPolicyType.description : "";
  };

  const getPremiumLabel = (type) => {
    if (type) {
      return type.product === orderProductTypes.CARGO_OPEN_POLICY ||
        type.product === orderProductTypes.CARGO_SINGLE_BOARDING
        ? translation.minPremiumLabel
        : translation.policyGrossPremiumLabel;
    }
    return null;
  };

  const getSalesRepresentative = () => {
    const salesRepresentative = users.find(
      (item) => policy.salesRepresentativeId === item.id
    );
    return salesRepresentative ? salesRepresentative.name : "";
  };

  const getPreSalesRepresentative = () => {
    const preSalesRepresentativeId = users.find(
      (item) => policy.preSalesRepresentativeId === item.id
    );
    return preSalesRepresentativeId ? preSalesRepresentativeId.name : "";
  };

  const getBusinessPartner = () => {
    return bussinessPartners[policy.businessPartner]
      ? bussinessPartners[policy.businessPartner].label
      : "";
  };

  const getRegisterApplicationLabel = () => {
    const registerApplication = registerApplicationTypes.find(
      (item) => policy.registerApplication === item.value
    );
    return registerApplication ? registerApplication.label : "";
  };

  return (
    <>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-2 gx-mr-n2">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyProductLabel}
        </Col>
        <Col xs={24} md={20}>
          {getProductType()}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyInsurerLabel}
        </Col>
        <Col xs={24} md={20}>
          {getProductInsurer()}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {policy.type && policy.type.product === "SURETY"
            ? translation.suretyPolicyLabel
            : translation.policyTypeLabel}
        </Col>
        <Col xs={24} md={6}>
          {getProductPolicyType()}
        </Col>
        {policy.type && policy.type.product === "SURETY" && (
          <>
            <Col
              xs={24}
              md={6}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
            >
              {Translation("policy.emissionDateLabel")}
            </Col>
            <Col xs={24} md={6}>
              {getFormattedDate(policy.issuedAt)}
            </Col>
          </>
        )}
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyNumberLabel}
        </Col>
        <Col xs={24} md={6}>
          {policy.policyNumber}
        </Col>
        {policy.type && policy.type.product === "SURETY" && (
          <>
            <Col
              xs={24}
              md={6}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
            >
              {translation.policySuretyAmountLabel}
            </Col>
            <Col xs={24} md={6}>
              {policy.suretyAmount}
            </Col>
          </>
        )}
        {policy.type && policy.type.product === "CARGO_OPEN_POLICY" && (
          <>
            <Col
              xs={24}
              md={6}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
            >
              {translation.maximumGuaranteeLimitLabel}
            </Col>
            <Col xs={24} md={6}>
              {policy.maximumGuaranteeLimit}
            </Col>
          </>
        )}
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyValidityLabel}
        </Col>
        <Col xs={24} md={6}>
          {getFormattedDate(policy.initialTermAt)}
        </Col>
        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyExpirationLabel}
        </Col>
        <Col xs={24} md={6}>
          {getFormattedDate(policy.finalTermAt)}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyNetPremiumLabel}
        </Col>
        <Col xs={24} md={6}>
          {formatMoney(policy.netPremium)}
        </Col>
        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyIOFLabel}
        </Col>
        <Col xs={24} md={3}>
          {formatPercentage(policy.tax) || 0}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {getPremiumLabel(policy.type) || translation.policyGrossPremiumLabel}
        </Col>
        <Col xs={24} md={6}>
          {formatMoney(policy.premium)}
        </Col>

        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyInstallmentsLabel}
        </Col>
        <Col xs={24} md={6}>
          {policy.installmentsToBePaid}
        </Col>
      </Row>
      {policy.type && policy.type.product === "LIFE" ? (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col
            xs={24}
            md={4}
            className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
          >
            {translation.movimentLimitDayLabel}
          </Col>
          <Col xs={24} md={6}>
            {policy.movementLimitDay}
          </Col>
        </Row>
      ) : null}
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyRateLabel}
        </Col>
        <Col xs={24} md={6}>
          {formatPercentage(policy?.rate) || ""}
        </Col>
        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        ></Col>
        <Col xs={24} md={6}></Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policySalesLabel}
        </Col>
        <Col xs={24} md={6}>
          {getSalesRepresentative()}
        </Col>
        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyPreSalesLabel}
        </Col>
        <Col xs={24} md={6}>
          {getPreSalesRepresentative()}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyComissionLabel}
        </Col>
        <Col xs={24} md={6}>
          {formatPercentage(policy.commission) || 0}
        </Col>
        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.policyPartnerLabel}
        </Col>
        <Col xs={24} md={6}>
          {getBusinessPartner()}
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.contractNumber}
        </Col>
        <Col xs={24} md={6}>
          {policy.contractNumber}
        </Col>
        <Col
          xs={24}
          md={6}
          className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
        >
          {translation.obligeeDocumentNumber}
        </Col>
        <Col xs={24} md={6}>
          {policy.obligeeDocumentNumber}
        </Col>
      </Row>
      {policy.type.product === "CARGO_OPEN_POLICY" ||
      policy.type.product === "CARGO_SINGLE_BOARDING" ? (
        <>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
            <Col
              xs={24}
              md={4}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold gx-text-right"
            >
              {Translation("policy.staticData.registerName")}
            </Col>
            <Col xs={24} md={6}>
              {policy.registerName}
            </Col>
            <Col
              xs={24}
              md={6}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold gx-text-right"
            >
              {Translation("policy.staticData.registerEmail")}
            </Col>
            <Col xs={24} md={6}>
              {policy.registerEmail}
            </Col>
          </Row>
          <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
            <Col
              xs={24}
              md={4}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold gx-text-right"
            >
              {Translation("policy.staticData.registerPhoneNumber")}
            </Col>
            <Col xs={24} md={6}>
              {policy.registerPhoneNumber}
            </Col>
            <Col
              xs={24}
              md={6}
              className="gx-d-flex gx-justify-content-end gx-font-weight-bold gx-text-right"
            >
              {Translation("policy.staticData.registerApplication")}
            </Col>
            <Col xs={24} md={6}>
              {getRegisterApplicationLabel()}
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
};

export default PolicyStaticdata;
