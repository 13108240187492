import React from "react";
//Components
import { Form, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FormField = (props) => {
  const { error, label, children, htmlFor, tooltip, ...rest } = props;
  const hasError = Boolean(error);
  return (
    <Form.Item
      hasFeedback={hasError}
      validateStatus={hasError ? "error" : null}
      help={error}
      style={{ margin: 0 }}
      htmlFor={htmlFor ? htmlFor : children.props ? children.props.id : null}
      label={
        label ? (
          <span>
            {label}
            {tooltip ? (
              <Tooltip title={tooltip}>
                <FontAwesomeIcon icon={["far", "question-circle"]} />
              </Tooltip>
            ) : null}
          </span>
        ) : null
      }
      {...rest}
    >
      {children}
    </Form.Item>
  );
};

export default FormField;
