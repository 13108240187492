import React from "react";
// prop types
import PropTypes from "prop-types";
// translations
import { useTranslation } from "react-i18next";
// icons
import { AlertTriangle } from "react-feather";
//custom styles
import "./styles.less";

const UserStatusChip = ({ status }) => {
  const { t: translation } = useTranslation();
  const userStatus = {
    OK: (
      <div className="user-status-chip OK">
        {translation("clientAppUser.status.OK")}
      </div>
    ),
    NEED_UPDATE_PASSWORD: (
      <div className="user-status-chip NEED_UPDATE_PASSWORD">
        <AlertTriangle size={16} />
        {translation("clientAppUser.status.NEED_UPDATE_PASSWORD")}
      </div>
    ),
    BLOCKED: (
      <div className="user-status-chip BLOCKED">
        {translation("clientAppUser.status.BLOCKED")}
      </div>
    ),
    DELETED: (
      <div className="user-status-chip DELETED">
        {translation("clientAppUser.status.DELETED")}
      </div>
    ),
    PENDING_APPROVAL: (
      <div className="user-status-chip PENDING_APPROVAL">
        <AlertTriangle size={16} />
        {translation("clientAppUser.status.PENDING_APPROVAL")}
      </div>
    ),
  };
  if (status) {
    return <div display="flex">{userStatus[status]}</div>;
  }
  return null;
};

UserStatusChip.propTypes = {
  status: PropTypes.string,
  size: PropTypes.string,
};

export default UserStatusChip;
