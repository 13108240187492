export const formatPolicyNotifications = (data, hashType, type) => {
  let events = [];

  const newData = data?.forEach((element) => {
    const newElement = element?.events?.forEach((event) => {
      events = [
        ...events,
        {
          ...event,
          email: element?.email,
          hashType,
          type,
        },
      ];
    });
    return element;
  });

  return events;
};

export const formatPolicySMSNotifications = (data, hashType, type, event) => {
  let smsEvents = [];
  const newData = data?.forEach((element) => {
    smsEvents = [
      ...smsEvents,
      {
        ...element,
        phoneNumber: element?.recipient,
        occurredAt: element?.sentAt,
        event,
        hashType,
        type,
      },
    ];
  });

  return smsEvents;
};
