import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Table, Tag } from "antd";
// helpers
import { formatDateTime } from "../../../../helpers";
// cutoms styles
import "./styles.less";
// contants
import { emailEvents } from "../../../../constants";

const HistoryItem = ({ historyItem = {}, hasHeader, ...props }) => {
  const { t: translation } = useTranslation();

  const getEmailEventTag = (emailEvent) => {
    const tagData = emailEvents.find((event) => event.value === emailEvent);
    return tagData && (
      <Tag className="gx-m-0" color={tagData.color}>
        {tagData.label}
      </Tag>
    );
  };

  const getEmailEventDescription = (emailEvent) => {
    const emailItem = emailEvents.find((event) => event.value === emailEvent);
    return emailItem && emailItem.description;
  };

  const columns = [
    {
      title: translation("invoice.emailHistory.headerTable.contactLabel"),
      dataIndex: "contact",
      key: "contact",
      align: "center",
      width: "25%",
      render: (text, record) => text,
    },
    {
      title: translation("invoice.emailHistory.headerTable.statusLabel"),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "15%",
      render: (text, record) => getEmailEventTag(text),
    },
    {
      title: translation("invoice.emailHistory.headerTable.descriptionLabel"),
      dataIndex: "description",
      key: "description",
      width: "35%",
      render: (text, record) => getEmailEventDescription(text),
    },
    {
      title: translation("invoice.emailHistory.headerTable.dateLabel"),
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "25%",
      render: (text, record) => formatDateTime(text),
    },
  ];

  const formatDataSource = () => {
    const dataSource = [...historyItem.events] || [];

    dataSource.sort((a, b) => {
      return new Date(b.occurredAt) - new Date(a.occurredAt);
    });

    return dataSource.map((item) => {
      return {
        contact: historyItem.email,
        status: item.event,
        description: item.event,
        date: item.occurredAt,
      };
    });
  };

  return (
    <>
      {historyItem.events && historyItem.events.length > 0 ? (
        <Table
          bordered
          columns={columns}
          dataSource={formatDataSource()}
          pagination={false}
          className={`gx-table-responsive ${!hasHeader && "no-list-header"}`}
          {...props}
        />
      ) : (
        <Row type="flex" justify="center">
          <Tag color="blue">
            {translation("invoice.historyItem.emptyLabel")}
          </Tag>
        </Row>
      )}
    </>
  );
};

HistoryItem.propTypes = {
  historyItem: PropTypes.object,
};

export default HistoryItem;
