import React, { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Avatar, Button, Col, Modal, Row, Spin } from "antd";
// custom components
import { UserStatusChip } from "../../index.js";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  fetchClientAppUserData,
  fetchClientAppUsers,
  setUserData,
  submitAccessAprove,
  blockClientAppUser,
  unBlockClientAppUser,
} from "../../../store/slices/clientApp/manageUsersAccess";
// moment
import moment from "moment";
// styles
import "./styles.less";

const ManageUserAccessModal = () => {
  const [visible, setVisible] = useState(true);
  const { t: translation } = useTranslation();
  const {
    common: { isLoading },
  } = useSelector((state) => state);
  const {
    usersList,
    filtersData,
    userData,
    isSubmitingAccessAprove,
    isLoadingBlockUnblock,
  } = useSelector((state) => state.clientApp.manageUsersAccess);

  const { documentNumber, email, id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancel = () => {
    dispatch(setUserData([]));
    setVisible(false);
    history.push(`/crm/client-app`);

    filtersData?.documentNumber
      ? dispatch(
          fetchClientAppUsers({ documentNumber: filtersData.documentNumber })
        )
      : dispatch(
          fetchClientAppUsers({
            page: usersList?.number,
            size: usersList?.size,
          })
        );
  };

  const handleFilterUser = () =>
    userData.filter((user) => user?.id === Number(id));

  const handleLiberateAccess = () =>
    dispatch(submitAccessAprove({ documentNumber, email })).then(() => {
      handleCancel();
      dispatch(fetchClientAppUserData({ documentNumber, email }));
    });

  const handleBlockUser = (docNumber, userEmail) => {
    dispatch(
      blockClientAppUser({ documentNumber: docNumber, email: userEmail })
    ).then(() =>
      dispatch(
        fetchClientAppUserData({ documentNumber: docNumber, email: userEmail })
      )
    );
  };
  const handleUnBlockUser = (docNumber, userEmail) => {
    dispatch(
      unBlockClientAppUser({ documentNumber: docNumber, email: userEmail })
    ).then(() =>
      dispatch(
        fetchClientAppUserData({ documentNumber: docNumber, email: userEmail })
      )
    );
  };

  const stringAvatar = (name) => {
    let initials;
    if (name) {
      const first = name.split(" ")[0][0];
      if (name.split(" ")[1]) {
        initials = first + name.split(" ")[1][0];
      } else {
        initials = first;
      }
      return {
        children: initials.toUpperCase(),
      };
    }
    return "";
  };

  const formatDateHour = (str) => {
    const dateAndHour = str?.split("T");
    if (dateAndHour?.length) {
      return {
        lastAcessDate: moment(dateAndHour[0]).format("DD/MM/YYYY"),
        lastAcessHour: dateAndHour[1],
      };
    }
    return null;
  };

  const customActionButtonLabel = {
    PENDING_APPROVAL: translation(
      "clientApp.PENDING_APPROVAL.actionButton.label"
    ),
  };

  const customModalBodyContent = {
    PENDING_APPROVAL: translation(
      "clientApp.PENDING_APPROVAL.modalBody.content"
    )
      ?.split("\n")
      ?.map((c, index) => {
        return <p key={index}> {c} </p>;
      }),
    NEED_UPDATE_PASSWORD: translation(
      "clientApp.NEED_UPDATE_PASSWORD.modalBody.content"
    )
      ?.split("\n")
      ?.map((c, index) => {
        return <p key={index}> {c} </p>;
      }),
    DEFAULT: (
      <>
        <p className="gx-font-weight-bold">
          {translation("clientApp.manageAccessModal.lastAccessAt.label")}
        </p>
        <Row>
          <Col span={12}>
            <p className="gx-border-bottom">
              {translation("clientApp.date.label")}
            </p>
          </Col>
          <Col span={12}>
            <p className="gx-border-bottom">
              {translation("clientApp.hour.label")}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <p>
              {
                formatDateHour(handleFilterUser()[0]?.lastAccessAt)
                  ?.lastAcessDate
              }
            </p>
          </Col>
          <Col span={12}>
            <p>
              {
                formatDateHour(handleFilterUser()[0]?.lastAccessAt)
                  ?.lastAcessHour
              }
            </p>
          </Col>
        </Row>
      </>
    ),
  };

  useEffect(() => {
    if (documentNumber && email) {
      dispatch(fetchClientAppUserData({ documentNumber, email }));
    }
  }, []);

  return (
    <Modal
      width={420}
      visible={true}
      footer={null}
      centered={true}
      wrapClassName="manage-user-access-modal"
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading && !userData.length > 0}>
        <div className="manage-user-modal-wrapper">
          <div className="manage-user-modal-header">
            <Avatar
              size={42}
              style={{
                color: "#545454",
                backgroundColor: "#fff",
                border: "1px solid #545454",
              }}
            >
              {stringAvatar(handleFilterUser()[0]?.name)?.children}
            </Avatar>
            <div>
              <h5>{handleFilterUser()[0]?.name}</h5>
              <span>{handleFilterUser()[0]?.email}</span>
              <div className="gx-mt-2 status-wrapper">
                <UserStatusChip status={handleFilterUser()[0]?.status} />
                {handleFilterUser()[0]?.status === "BLOCKED" ? (
                  <Button
                    disabled={handleFilterUser()[0]?.status === "DELETED"}
                    type="primary"
                    ghost
                    loading={isLoadingBlockUnblock}
                    className="gx-mb-0"
                    htmlType="button"
                    onClick={() =>
                      handleUnBlockUser(
                        handleFilterUser()[0]?.documentNumber,
                        handleFilterUser()[0]?.email
                      )
                    }
                  >
                    {translation(
                      "clientApp.manageAccessModal.unBlockUser.button.label"
                    )}
                  </Button>
                ) : (
                  <Button
                    disabled={handleFilterUser()[0]?.status === "DELETED"}
                    type="danger"
                    ghost
                    loading={isLoadingBlockUnblock}
                    className="gx-mb-0"
                    htmlType="button"
                    onClick={() =>
                      handleBlockUser(
                        handleFilterUser()[0]?.documentNumber,
                        handleFilterUser()[0]?.email
                      )
                    }
                  >
                    {translation(
                      "clientApp.manageAccessModal.blockUser.button.label"
                    )}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="manage-user-modal-body">
            {customModalBodyContent[handleFilterUser()[0]?.status] ||
              customModalBodyContent.DEFAULT}
            <p>
              {translation(
                "clientApp.manageAccessModal.sendLiberation.description"
              )}
              &nbsp;<strong>{handleFilterUser()[0]?.email}</strong>
            </p>
            <Button
              type="primary"
              loading={isSubmitingAccessAprove}
              className="gx-mt-2 gx-mb-0"
              htmlType="button"
              block
              onClick={handleLiberateAccess}
            >
              {customActionButtonLabel[handleFilterUser()[0]?.status] ||
                translation("clientApp.DEFAULT.actionButton.label")}
            </Button>
          </div>
        </div>
      </Spin>
    </Modal>
  );
};

export default ManageUserAccessModal;
