export default {
  initial: {
    name: null,
    email: null,
    phoneNumber: null,
    phoneType: null,
    receiveWhatsAppMessage: null,
    contactType: null,
    documentNumber: null,
    hasDocumentNumber: null
  },
  general: {
    modals: [],
    companyActivity: null,
    insuredType:null,
    monthlyShipmentQuantity: null,
    averageAmountPerBoarding: null,
    maximumAmountPerBoarding: null,
    reason: null,
    startsAt: null,
    fleetSize: null
  },
  generalV2: {
    modals: [],
    companyActivity: null,
    insuredType:null,
    monthlyShipmentQuantity: null,
    averageAmountPerBoarding: null,
    maximumAmountPerBoarding: null,
    reason: null,
    startsAt: null,
    fleetSize: null
  },
  plan: {},
  routes: {
    routes: []
  },
  coverage: {
    theft: false,
    other: false,
    damage: false,
    accident: true,
    lifeInsurance: false,
    trackCleaning: false,
    environmentalLiabilityRisk: false,
    freight:false,
    loadingUnloading:false,
    loadingUnloadingLifting:false,
    fluvialRoute:false,
    otherDescription: null,
  },
  insured: {
    documentNumber: null,
    type: "COMPANY",
    name: null,
    zipCode: null,
    address: null,
    number: null,
    neighborhood: null,
    state: null,
    city: null,
    additionalData: null
  },
  goods: [],
  historic: {
    accidents: [],
    minimumPremium: null,
    contractedCoverages: null,
    hasPreviousInsurance: null,
    previousInsurer: null,
    maximumGuaranteeLimit: null,
    monthlyInvoiceAmount: null,
    rateOrDiscountApplied: null,
    additionalInformation: null
  },
  operational: {
    fleets: {},
    logistic: null,
    exemptionFromRegistration: null,
    hasExclusivity: false,
    exclusivityCompanies: null,
    hasDDR: false,
    shipper: null,
    registeredDrivers: false,
    riskManagementCompanies: null,
    oneResponsibleForStorage: false,
    transportDocuments: [],
    riskManagement: [],
    trackings: []
  },
  quotationData: {
    bestQuotation: {},
    chosenInsurer:'',
    hasAvailable: false,
    loading:false,
    quotations: []
  }
}