import React from 'react';
//Components
import { Row, Col } from "antd";
import { PaymentsRadio } from "./PaymentsRadio";

export const Payments = (props) => {
  const { payments, minute, handleChange, code } = props;

  return (
    <section>
      <Row type="flex" className="gx-flex-row mt-40">
        <Col xs={24} md={24}>
          <PaymentsRadio
            payments={payments.payment}
            messages={minute && minute.messages}
            handleChange={handleChange}
            code={code} />
        </Col>
      </Row>
    </section>
  );
};

export default Payments;