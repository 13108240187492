import React, { useState, useEffect } from "react";
import selections from "constants/selections";
import moment from "moment";
//Components
import axios from "axios";
import {
  Row,
  Col,
  Button,
  Upload,
  Select,
  Icon,
  Popconfirm,
  Alert,
  DatePicker,
} from "antd";
import {
  uploadConfig,
  displayError,
  displaySuccess,
  getFormattedDate,
  frontEndDate,
} from "helpers";
import { NumberInput, FormField, CurrencyInput } from "components";
//Translations
import Translation from "./translation";
//style
import "./style.less";
import { useDispatch, useSelector } from "react-redux";
import PolicyEmissionAlerts from "./PolicyEmissonAlerts";
import {
  fetchSuretyPolicyEmissionStatus,
  requestSuretyPolicyEmission,
  setSlipErrors,
  setPaymentSlipsData,
  setPolicyData,
  setPolicyInsurer,
  setSlipPayload,
} from "../../../../../../store/slices/emission/policy";
import { policyEmissionStatusEnum } from "../../../../../../constants";
// custom hooks
import usePullingEmissionStatusStrategy from "../../../../../../hooks/usePullingEmissionStatusStrategy";
import { RefreshCw } from "react-feather";
import { dateFormat } from "../../../../../../helpers/date";
//constants
const { Option } = Select;
const config = {
  insurers: Object.entries(selections.taxesInsurer),
};

const Policy = ({
  hasExpressEmission,
  policyData: policyDataInitialState,
  paymentSlipsData: paymentSlipsDataInitialState,
  code,
  documentNumber,
  type,
  superiorSelectedTab,
  ...props
}) => {
  const { pullingEmissionStatusHandler } = usePullingEmissionStatusStrategy();
  const {
    policyEmissionStatus,
    policyData,
    paymentSlipsData,
    isLoadingEmissionRequest,
    policyInsurer,
    slipPayload,
    slipErrors,
    emissionErrorDescription,
  } = useSelector((state) => state.emission.policyEmission);
  const dispatch = useDispatch();
  const [policyUploadData, setPolicyUploadData] = useState({});

  const isProcessingEmission = () => {
    if (
      policyEmissionStatus === policyEmissionStatusEnum.AWS_PROCESSING ||
      policyEmissionStatus === policyEmissionStatusEnum.SURETY_PROCESSING
    ) {
      return true;
    }
    return false;
  };

  const minute = props?.minute || null;
  const showPolicyUploadFields = !policyData?.url && !isProcessingEmission();

  const showPaymentSlipFields =
    !paymentSlipsData?.length && !isProcessingEmission();

  const validOnlineMinute = minute && minute.url && hasExpressEmission;
  const policyDownloadURL = policyData?.url || policyUploadData?.url;
  const minuteIsAccepted = minute?.proposalAccepted;

  const disableGeneratePolicyButton = () => {
    if (
      policyEmissionStatus === policyEmissionStatusEnum.AWS_PROCESSING ||
      policyEmissionStatus === policyEmissionStatusEnum.SURETY_PROCESSING ||
      !minuteIsAccepted
    ) {
      return true;
    }
    return false;
  };

  const requestPolicyEmission = () => {
    dispatch(requestSuretyPolicyEmission(code)).then(() => {
      dispatch(fetchSuretyPolicyEmissionStatus(code, documentNumber)).then(
        ({ data }) => {
          pullingEmissionStatusHandler(data?.status, code, documentNumber);
        }
      );
    });
  };

  const filteredSlipList = (fileName) => {
    let filteredList = paymentSlipsData;
    return filteredList.filter((item) => {
      return item.name !== fileName;
    });
  };

  const deleteFile = (fileName) => {
    const url = `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/delete-payment-slip/${fileName}`;
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .delete(url, config)
      .then((response) => {
        const filteredList = filteredSlipList(fileName);
        dispatch(setPaymentSlipsData(filteredList));
        displaySuccess(`${fileName}: Arquivo deletado com sucesso`);
      })
      .catch((error) => {
        displayError(error.response.data.message);
      });
  };

  const downloadFile = (fileURL, fileName) => {
    // for non-IE
    if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileURL;
      save.target = "_blank";
      var filename = fileURL.substring(fileURL.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
        navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
        navigator.userAgent.search("Chrome") < 0
      ) {
        document.location = save.href;
        // window event not working here
      } else {
        var evt = new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: false,
        });
        save.dispatchEvent(evt);
        (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
    }

    // for IE < 11
    else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileURL, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileURL);
      _window.close();
    }
  };

  const uploadApi = {
    SURETY: {
      policy: `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/policy-data`,
      slip: `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/payment-slip`,
    },
  };

  function handleSelectPolicyInsurer(value) {
    dispatch(setPolicyInsurer(value));
  }

  function handleSelectSlipInsurer(value) {
    dispatch(setSlipPayload({ ...slipPayload, insurer: value }));
  }

  const handleFileChange = ({ file, fileList }) => {
    const { status } = file;

    if (status === "done") {
      fileList = fileList.map((item, index) => {
        if (item.response) {
          return {
            ...item.response,
            uid: Math.random(),
          };
        }
        return item;
      });
    } else if (status === "error") {
      let errors = {};
      if (file?.error?.response?.data?.errors) {
        file.error.response.data.errors.forEach((err) => {
          errors[err.field] = err.message;
        });
        dispatch(setSlipErrors(errors));
      }
    }
    return { fileList: [...fileList] };
  };

  const policyFileChange = ({ file, fileList }) => {
    const list = handleFileChange({ file, fileList });
    const item = list?.fileList[0];

    setPolicyUploadData(item);
  };

  const slipFileChange = ({ file, fileList }) => {
    const list = handleFileChange({ file, fileList });
    const item = list?.fileList[0];
    item.origin = "UPLOAD";
    if (item?.url) {
      dispatch(
        setPaymentSlipsData([...paymentSlipsData, { ...item, ...slipPayload }])
      );
    }
  };

  const renderErrorMessage = () => {
    if (policyData?.messages) {
      return policyData.messages.map((msg, index) => {
        return (
          <Alert
            key={index}
            message={msg}
            style={{ marginBottom: 10 }}
            type="warning"
          />
        );
      });
    } else if (emissionErrorDescription) {
      return (
        <Alert
          message={emissionErrorDescription}
          style={{ marginBottom: 10 }}
          type="warning"
        />
      );
    }
  };

  const PaymentSlipsList = () => {
    if (paymentSlipsData?.length) {
      const Slips = () =>
        paymentSlipsData.map((slip) => {
          return (
            <Row type="flex" className="gx-flex-row" key={Math.random()}>
              <Col xs={24} md={4}>
                #{slip.installment}
              </Col>
              <Col xs={24} md={6}>
                R$ {slip.amount}
              </Col>
              <Col xs={24} md={6}>
                {getFormattedDate(slip.dueDate)}
              </Col>
              <Col xs={24} md={8}>
                <Button
                  type="link"
                  onClick={(e) => downloadFile(slip.url, slip.name)}
                >
                  <Icon type="vertical-align-bottom" />
                  {Translation.downloadSlipPaymentLabel}
                </Button>
                {slip?.origin === "UPLOAD" && (
                  <Button
                    type="link"
                    style={{ color: "red" }}
                    onClick={(e) => deleteFile(slip.name)}
                  >
                    <Icon type="delete" />
                  </Button>
                )}
              </Col>
            </Row>
          );
        });

      return (
        <Row type="flex" className="gx-flex-row">
          <Col xs={24} md={22}>
            <b className="label">{Translation.paymentSlips}:</b>
            <br />
            <br />
            <Row type="flex" className="gx-flex-row payment-slips">
              <Col xs={24} md={4}>
                {Translation.installmentLabel}
              </Col>
              <Col xs={24} md={6}>
                {Translation.amountLabel}
              </Col>
              <Col xs={24} md={6}>
                {Translation.dueDateLabel}
              </Col>
              <Col xs={24} md={8}></Col>
            </Row>
            <div className="payment-slips-content">
              <Slips />
            </div>
          </Col>
        </Row>
      );
    }

    return null;
  };

  const onChangeSlipParamField = (attr) => (event) => {
    let cleanedErrors = {};
    dispatch(setSlipErrors(cleanedErrors));
    if (attr === "amount") {
      dispatch(setSlipPayload({ [attr]: event.floatValue }));
    } else if (attr === "dueDate") {
      dispatch(setSlipPayload({ [attr]: moment(event).format(dateFormat) }));
    } else {
      dispatch(setSlipPayload({ [attr]: event }));
    }
    if (slipErrors) {
      Object.keys(slipErrors).filter((errorKey) => {
        if (errorKey !== attr) {
          cleanedErrors[errorKey] = slipErrors[errorKey];
        }
      });
    }
  };

  const formatedPayload = () => {
    return { ...slipPayload, dueDate: slipPayload.dueDate };
  };

  useEffect(() => {
    dispatch(setPolicyData(policyDataInitialState));
    dispatch(setPaymentSlipsData(paymentSlipsDataInitialState));
    dispatch(
      setSlipPayload({
        insurer: "",
        installment: null,
        amount: null,
        dueDate: "",
      })
    );
    if (superiorSelectedTab === "4" && !!code && !!documentNumber) {
      dispatch(fetchSuretyPolicyEmissionStatus(code, documentNumber)).then(
        ({ data }) => {
          pullingEmissionStatusHandler(data?.status, code, documentNumber);
        }
      );
    }
  }, [superiorSelectedTab]);

  return (
    <>
      <Row type="flex" className="gx-flex-row">
        <Col xs={24} md={24}>
          <h5>
            <b className="label">{Translation.emission}:</b>
          </h5>
          <br />
          <h5>
            <b className="label">{Translation.policy}:</b>
          </h5>
          <br />
          <PolicyEmissionAlerts status={policyEmissionStatus} />

          {validOnlineMinute && !policyDownloadURL ? (
            <Popconfirm
              title="Tem certeza que deseja emitir a apólice?"
              onConfirm={(e) => requestPolicyEmission()}
              okText="Sim"
              cancelText="Não"
              disabled={disableGeneratePolicyButton()}
            >
              <Button
                type="primary"
                disabled={disableGeneratePolicyButton()}
                style={{ display: "flex", alignItems: "center" }}
              >
                {isLoadingEmissionRequest ? (
                  <Icon type="loading" />
                ) : (
                  <RefreshCw
                    size={16}
                    color="#FFF"
                    style={{ marginRight: "8px" }}
                  />
                )}
                {Translation.getPolicyLabel}
              </Button>
            </Popconfirm>
          ) : (
            <>
              {policyDownloadURL ? (
                <Button
                  type="primary"
                  onClick={(e) =>
                    downloadFile(policyDownloadURL, `Apólice_${code}`)
                  }
                >
                  <Icon type="vertical-align-bottom" />
                  {Translation.downloadPolicyLabel}
                </Button>
              ) : null}
            </>
          )}
        </Col>
      </Row>
      <Row type="flex" className="gx-flex-row">
        {showPolicyUploadFields && (
          <Col xs={24} md={12}>
            <b className="label">{Translation.policySendLabel}: </b>
            <br />
            <br />
            <span className="label">{Translation.insurerLabel}: </span>
            <br />
            <Select
              placeholder={Translation.SelectPlaceholder}
              style={{ width: 220 }}
              onChange={handleSelectPolicyInsurer}
            >
              {config.insurers.map((option) => {
                return (
                  <Option key={option[0]} value={option[0]}>
                    {option[1].label}
                  </Option>
                );
              })}
            </Select>
            <br />
            <br />
            <Upload
              {...uploadConfig(policyFileChange, null, {
                insurer: policyInsurer,
              })}
              action={uploadApi[type].policy}
              showUploadList={false}
            >
              <Button disabled={!policyInsurer}>
                <Icon type="upload" /> {Translation.policySendLabel}
              </Button>
            </Upload>
          </Col>
        )}
        {showPaymentSlipFields && (
          <Col xs={24} md={12}>
            <b className="label">{Translation.slipSendLabel}: </b>
            <br />
            <br />
            <span className="label">{Translation.insurerLabel}: </span>
            <br />
            <Select
              placeholder={Translation.SelectPlaceholder}
              style={{ width: 220 }}
              onChange={handleSelectSlipInsurer}
            >
              {config.insurers.map((option) => {
                return (
                  <Option key={option[0]} value={option[0]}>
                    {option[1].label}
                  </Option>
                );
              })}
            </Select>

            {slipPayload?.insurer && (
              <>
                <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
                  <Col xs={24} md={18}>
                    <span className="label">{Translation.slipNumberLabel}</span>
                    <br />
                    <FormField error={slipErrors?.installment}>
                      <NumberInput
                        fullWidth
                        value={slipPayload?.installment}
                        placeholder={Translation.slipNumberPlaceholder.props}
                        style={{ width: 220 }}
                        onChange={onChangeSlipParamField("installment")}
                      />
                    </FormField>
                  </Col>
                </Row>

                <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
                  <Col xs={24} md={18}>
                    <span className="label">
                      {Translation.slipDueDateLabel}
                    </span>
                    <br />
                    <FormField error={slipErrors?.dueDate}>
                      <DatePicker
                        showToday={true}
                        className="gx-w-100"
                        fullWidth
                        value={
                          slipPayload?.dueDate && moment(slipPayload?.dueDate)
                        }
                        format={frontEndDate}
                        allowClear={false}
                        onChange={onChangeSlipParamField("dueDate")}
                      />
                    </FormField>
                  </Col>
                </Row>

                <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
                  <Col xs={24} md={18}>
                    <span className="label">{Translation.slipValueLabel}</span>
                    <br />
                    <FormField error={slipErrors?.amount}>
                      <CurrencyInput
                        style={{ width: 220 }}
                        value={slipPayload?.amount}
                        placeholder={Translation.slipValuePlaceholder.props}
                        onChange={onChangeSlipParamField("amount")}
                      />
                    </FormField>
                  </Col>
                </Row>
              </>
            )}

            <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
              <Col xs={24} md={24}>
                <Upload
                  {...uploadConfig(slipFileChange, null, formatedPayload())}
                  action={uploadApi[type].slip}
                  showUploadList={false}
                >
                  <Button disabled={!slipPayload.insurer}>
                    <Icon type="upload" /> {Translation.slipSendLabel}
                  </Button>
                </Upload>
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <hr />
      {minute &&
        minute?.url &&
        paymentSlipsData &&
        paymentSlipsData?.length > 0 && <PaymentSlipsList />}
    </>
  );
};

export default Policy;
