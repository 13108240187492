import React, { useEffect } from "react";
// props types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, DatePicker } from "antd";
// components
import FormField from "../../../FormField";
import CurrencyInput from "../../../CurrencyInput";
// helpers
import { frontEndDate, dateFormat, getErrorMessage } from "../../../../helpers";
// moment
import moment from "moment";
// custom styles
import "./styles.less";

const ExtensionEndorsement = ({
  data = {},
  policy = {},
  errors = [],
  onChange,
  setData,
}) => {
  const { t: translation } = useTranslation();
  const handleChange = (field, value) => onChange && onChange(field, value);

  const endorsementTerms = {
    initialTermAt: moment(policy.initialTermAt, dateFormat),
    finalTermAt: moment(policy.finalTermAt, dateFormat),
  };

  useEffect(() => {
    if (!data.initialTermAt || !data.finalTermAt) {
      setData(endorsementTerms);
    }
  }, []);

  return (
    <>
      <Row type="flex">
        <Col xs={24} md={8}>
          <FormField
            label={translation(
              "endorsement.createEndorsement.initialTermAtLabel"
            )}
            error={getErrorMessage("initialTermAt", errors)}
          >
            <DatePicker
              allowClear={false}
              className="gx-w-100"
              format={frontEndDate}
              value={
                data.initialTermAt || moment(policy.initialTermAt, dateFormat)
              }
              onChange={(e) => handleChange("initialTermAt", e)}
            />
          </FormField>
        </Col>
        <Col xs={24} md={16} style={{ display: "flex", alignItems: "end" }}>
          <Row type="flex" align="middle" className="gx-w-100">
            <Col xs={24} md={2}>
              {translation("endorsement.description.extensionLabel")}
            </Col>
            <Col xs={24} md={12}>
              <FormField
                error={getErrorMessage("finalTermAt", errors)}
                colon={false}
              >
                <DatePicker
                  allowClear={false}
                  className="gx-w-100"
                  format={frontEndDate}
                  value={
                    data.finalTermAt || moment(policy.finalTermAt, dateFormat)
                  }
                  onChange={(e) => handleChange("finalTermAt", e)}
                />
              </FormField>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" className="gx-mt-3 warning-text">
        <Col xs={24} md={24}>
          {translation(
            "endorsement.createEndorsement.extension.negativeValueLabel"
          )}
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} md={12}>
          <FormField
            label={translation(
              "endorsement.createEndorsement.extension.premiumLabel"
            )}
            error={getErrorMessage("netPremium", errors)}
            className={`${data.netPremium < 0 ? "negative-input-values" : ""}`}
          >
            <CurrencyInput
              allowNegative
              value={data.netPremium}
              placeholder={translation(
                "endorsement.createEndorsement.extension.premiumPlaceholder"
              )}
              onChange={(e) => handleChange("netPremium", e)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} md={12}>
          <FormField
            label={translation(
              "endorsement.createEndorsement.extension.commissionLabel"
            )}
            error={getErrorMessage("commission", errors)}
            className={`${data.commission < 0 ? "negative-input-values" : ""}`}
          >
            <CurrencyInput
              allowNegative
              value={data.commission}
              placeholder={translation(
                "endorsement.createEndorsement.extension.commissionPlaceholder"
              )}
              onChange={(e) => handleChange("commission", e)}
              suffix="%"
              prefix=""
            />
          </FormField>
        </Col>
      </Row>
    </>
  );
};

ExtensionEndorsement.propTypes = {
  data: PropTypes.object,
  policy: PropTypes.object,
  errors: PropTypes.array,
  onChange: PropTypes.func,
  setData: PropTypes.func,
};

export default ExtensionEndorsement;
