//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "employeesAmountLabel",
    "type": "label"
  },
  {
    "key": "employeesAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "capitalInsuredPersonLabel",
    "type": "label"
  },
  {
    "key": "capitalInsuredPersonPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "averageAgeEmployeesLabel",
    "type": "label"
  },
  {
    "key": "averageAgeEmployeesPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "reasonTakeInsuranceLabel",
    "type": "label"
  },
  {
    "key": "reasonTakeInsurancePlaceholder",
    "type": "label"
  },
  {
    "key": "hasBiddingWaiverLabel",
    "type": "label"
  },
];
export default prepareTranslations(keys, translation);