export function prepareSelectedUsers(data) {
    const selectedUsers = {};
    data.forEach(user => {
        selectedUsers[user.id] = false
    });
    return selectedUsers;
}

export function updateSelectedUsers(users, updatedUser) {
    users[updatedUser] = !users[updatedUser];
    return {...users};
}

export function updateAllUsers(users, attr) {
    let selectAll = (attr === "all");
    const selectedUsers = {};
    users.forEach(user => {
        selectedUsers[user.id] = selectAll;
    });
    return selectedUsers;
}

export function filterUsersByName(users, userName) {
    function filterByName({name}) {
        return name.toLowerCase().indexOf(userName.toLowerCase()) > -1;
    }

    if (userName === '') return users;
    return users.filter(filterByName)
}
