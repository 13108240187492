import { Icon } from "antd";
import React from "react";

export function getNumberOfSelectedUsers(users) {
  let selected = 0;
  for (let user in users) {
    if (users.hasOwnProperty(user)) {
      if (users[user]) {
        selected++;
      }
    }
  }
  return selected;
}

export function prepareUserCreate(data) {
  return {
    name: data.name,
    email: data.email,
    position: data.position,
    whatsapp: data.whatsapp,
    photo: data.photo,
    products: data.products,
    extensionNumber: data.extensionNumber,
    hasHubspotUser: data.hasHubspotUser || false,
    preSalesProfile: data.preSalesProfile || false,
    salesProfile: data.salesProfile || false,
    profile: {
      key: data.key,
    },
  };
}

export function isUpdateValid(previousValues, currentValues) {
  for (let entry in currentValues) {
    if (currentValues.hasOwnProperty(entry)) {
      if (entry === "key") {
        if (currentValues[entry] !== previousValues.profile[entry]) return true;
      } else {
        if (currentValues[entry] !== previousValues[entry]) return true;
      }
    }
  }
  return false;
}

export function getUsersFromSelected(users, selected) {
  const availableUsers = [];
  for (let userId in selected) {
    if (selected.hasOwnProperty(userId)) {
      if (selected[userId]) {
        availableUsers.push(parseInt(userId));
      }
    }
  }
  return users.filter((user) => availableUsers.includes(user.id));
}

export function prepareUserEdit(data) {
  return {
    name: data.name,
    email: data.email,
    position: data.position,
    whatsapp: data.whatsapp,
    photo: data.photo,
    products: data.products,
    extensionNumber: data.extensionNumber,
    hasHubspotUser: data.hasHubspotUser || false,
    preSalesProfile: data.preSalesProfile || false,
    salesProfile: data.salesProfile || false,
    profile: {
      key: data.key,
    },
  };
}

export function getIcon(icon) {
  return {
    prefix: <Icon type={icon} style={{ color: "rgba(0,0,0,.25)" }} />,
  };
}

export function getFirstLetter(str) {
  return str.charAt(0).toUpperCase();
}
