// redux
import { useDispatch } from "react-redux";
import { fetchCoverageTypes } from "../store/slices/proposal/cargo";

const useCoverages = () => {
  const dispatch = useDispatch();

  const getCoverageTypes = async(policyType) => {
    return await dispatch(fetchCoverageTypes(policyType)).then((response) => {
      return response.data;
    });
  };

  return getCoverageTypes;
};

export default useCoverages;
