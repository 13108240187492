// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";
// common
import { setIsLoading } from "../common";

export const endorsementSlice = createSlice({
  name: "endorsement",
  initialState: {
    endorsementList: [],
    endorsementData: {},
    changeType: "",
    changeCount: 0,
    errors: [],
    pagination: {},
  },
  reducers: {
    setEndorsements: (state, action) => {
      state.endorsementList = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    changeEndorsementData: (state, action) => {
      state.endorsementData = {
        ...state.endorsementData,
        ...action.payload,
      };
    },
    setEndorsementData: (state, action) => {
      state.endorsementData = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setChangeType: (state, action) => {
      state.changeType = action.payload;
    },
    setChangeCount: (state, action) => {
      state.changeCount = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const {
  setErrors,
  setEndorsements,
  changeEndorsementData,
  setEndorsementData,
  setPagination,
  setChangeType,
  setChangeCount,
} = endorsementSlice.actions;

export default endorsementSlice.reducer;

// -----------------------------------------------------------------

export const fetchEndorsements = (policyId, filter) => (dispatch) => {
  dispatch(setIsLoading(true));

  const queryString = filter ? new URLSearchParams(filter).toString() : "";

  return new Promise((resolve, reject) => {
    axios
      .get(`/policy/v1/${policyId}/endorsement?${queryString}`)
      .then((response) => {
        const { data, ...rest } = response.data;
        dispatch(setEndorsements(data));
        dispatch(setPagination(rest));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const createEndorsement = (policyId, formData) => (
  dispatch,
  getState
) => {
  const {
    endorsement: { endorsementData },
  } = getState();

  if (formData) {
    formData.delete("request");
    const json = JSON.stringify(endorsementData);
    const blob = new Blob([json], {
      type: "application/json",
    });
    formData.append("request", blob);
  }

  const headers = {
    "Content-type": "multipart/form-data",
  };

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/policy/v1/${policyId}/endorsement`, formData, {
        headers: headers,
      })
      .then((response) => {
        message.success(i18n.t("endorsement.createEndorsement.successMessage"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
          message.error(i18n.t("endosso.missingDataMessage"));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
