import React from "react";
// props types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, DatePicker } from "antd";
// components
import FormField from "../../../FormField";
import CurrencyInput from "../../../CurrencyInput";
// helpers
import { frontEndDate, getErrorMessage } from "../../../../helpers";
// custom styles
import "./styles.less";

const CancelEndorsement = ({ data = {}, errors = [], onChange }) => {
  const { t: translation } = useTranslation();
  const handleChange = (field, value) => onChange && onChange(field, value);
  return (
    <>
      <Row type="flex">
        <Col xs={24} md={12}>
          <FormField
            label={translation(
              "endorsement.createEndorsement.cancelledDateLabel"
            )}
            error={getErrorMessage("cancelledAt", errors)}
          >
            <DatePicker
              allowClear={false}
              className="gx-w-100"
              format={frontEndDate}
              value={data.cancelledAt}
              onChange={(e) => handleChange("cancelledAt", e)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} md={12}>
          <FormField
            label={translation(
              "endorsement.createEndorsement.premiumReturnedLabel"
            )}
            error={getErrorMessage("netPremium", errors)}
          >
            <CurrencyInput
              value={data.netPremium}
              placeholder={translation(
                "endorsement.createEndorsement.premiumReturnedPlaceholder"
              )}
              onChange={(e) => handleChange("netPremium", e)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex">
        <Col xs={24} md={12}>
          <FormField
            label={translation(
              "endorsement.createEndorsement.commissionReturnedLabel"
            )}
            error={getErrorMessage("commission", errors)}
          >
            <CurrencyInput
              value={data.commission}
              placeholder={translation(
                "endorsement.createEndorsement.commissionReturnedPlaceholder"
              )}
              onChange={(e) => handleChange("commission", e)}
              suffix="%"
              prefix=""
            />
          </FormField>
        </Col>
      </Row>
    </>
  );
};

CancelEndorsement.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
  onChange: PropTypes.func,
};

export default CancelEndorsement;
