//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";
//Constants
const translation = setInitialTranslation("customers");
const keys = [
  {
    key: "policyKeyTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyCompetenceTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyValidityTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyPremiumTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyPaymentTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyFileTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyInvoiceTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyChargeTableHeaderLabel",
    type: "label",
  },
  {
    key: "policyEndChargeButtonLabel",
    type: "label",
  },
  {
    key: "policySetDelayedButtonLabel",
    type: "label",
  },
  {
    key: "policyResendInvoiceButtonLabel",
    type: "label",
  },
];
export default prepareTranslations(keys, translation);