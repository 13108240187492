import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
//Selectors
import {extractAuthUser} from 'appRedux/selectors';
//Components
import NotFound from 'routes/customViews/errorPages/404';

/**
 * HOC that Handles whether or not the user is allowed to see the page.
 * @param {array} allowedRoles - user roles that are allowed to see the page.
 * @param type => authType - it provides
 * @returns {function(*)}
 */
export default function Authorization(allowedRoles, type = "component") {
    return WrappedComponent => {
        const WithAuthorization = (props) => {
            const {user} = props;
            const authorized = user && allowedRoles.includes(user.profile.key);
            switch (type) {
                case "component": {
                    if (authorized)
                        return <WrappedComponent {...props} />;
                    else
                        return <NotFound/>;
                }
                case "menu": {
                    return <WrappedComponent disabled={!authorized}/>;
                }
                default:
                    return null;
            }
        };
        return connect(state => {
            return {user: extractAuthUser(state)};
        })(WithAuthorization);
    };
}

//Default Props
Authorization.defaultProps = {
    type: "component"
};
//PropTypes
Authorization.propTypes = {
    allowedRoles: PropTypes.array,
    type: PropTypes.oneOf(['component', 'menu'])
};




