import React from 'react';
//Translation
import Translation from './translation';
//Custom Css
import "./Styles.less";
//Components
import {Icon} from "antd";
import PreserveRoute from "../../PreserveRoute";

const OrderPreserveRoute = (props) => {
    const {when, navigate, shouldBlockNavigation} = props;
    return (
        <PreserveRoute
            when={when}
            navigate={navigate}
            shouldBlockNavigation={shouldBlockNavigation}>
            <section className="orderPreserveRoute">
                <Icon type="warning" className="orderPreserveRoute-icon"/>
                <span className="orderPreserveRoute-text">{Translation.preserveWarningMessage} ?</span>
            </section>
        </PreserveRoute>
    );
};

export default OrderPreserveRoute;