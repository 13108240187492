import React from "react";
import {Button, Form, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
//Images
import luckyMascot from "assets/images/mutuus-mascote-computador.png";
import mutuusLogo from "assets/images/logo.png";
import {
    hideMessage,
    showAuthLoader,
    userSignIn
} from "appRedux/actionCreators/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.showAuthLoader();
                this.props.userSignIn(values);
            }
        });
    };

    componentDidUpdate() {
        if (this.props.showMessage) {
            setTimeout(() => {
                this.props.hideMessage();
            }, 100);
        }
        if (this.props.authUser !== null) {
            this.props.history.push('/');
        }
    }

    render() {
        const {getFieldDecorator} = this.props.form;
        const {showMessage, loader, alertMessage} = this.props;

        return (
            <div className="gx-app-login-wrap">
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-logo-content">
                            <div className="gx-app-logo-content-bg">
                                <img src={luckyMascot} alt='Mutuus Seguros mascote'/>
                            </div>
                            <div className="gx-app-logo responsive-logo">
                                <img alt="Logo Mutuus Seguros" src={mutuusLogo}/>
                            </div>
                        </div>
                        <div className="gx-app-login-content">
                            <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                                <FormItem>
                                    {getFieldDecorator('email', {
                                        // initialValue: "demo@example.com",
                                        rules: [{
                                            required: true, type: 'email', message: 'Por favor, informe seu endereço de e-mail',
                                        }],
                                    })(
                                        <Input placeholder="Informe seu login"/>
                                    )}
                                </FormItem>

                                <FormItem>
                                    {getFieldDecorator('password', {
                                        // initialValue: "demo#123",
                                        rules: [{required: true, message: 'Por favor, informe sua senha'}],
                                    })(
                                        <Input.Password type="password" placeholder="Informe sua senha"/>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                                        <IntlMessages id="app.userAuth.signIn"/>
                                    </Button>
                                    <span><IntlMessages id="app.userAuth.or"/></span> <Link to="/forgot-password"><IntlMessages
                                    id="app.userAuth.forgotPassword"/></Link>
                                </FormItem>
                            </Form>
                        </div>

                        {loader ?
                            <div className="gx-loader-view">
                                <CircularProgress/>
                            </div> : null}
                        {showMessage ?
                            message.error(alertMessage.toString()) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
    userSignIn,
    hideMessage,
    showAuthLoader
})(WrappedNormalLoginForm);
