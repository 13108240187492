//Helper functions`
import { setInitialTranslation } from "helpers";
import { prepareTranslations } from "../../../../../../helpers";
//Constants
const translation = setInitialTranslation("minute");

const keys = [
    {
        "key": "taxRateLabel",
        "type": "label"
    },
    {
        "key": "premiumLabel",
        "type": "label"
    },
    {
        "key": "downloadMinuteLabel",
        "type": "label"
    },
    {
        "key": "obligeeLabel",
        "type": "label"
    },
    {
        "key": "suretyAmountLabel",
        "type": "label"
    },
    {
        "key": "evidencesLabel",
        "type": "label"
    },
    {
        "key": "evidencesUploadLabel",
        "type": "label"
    },
    {
        "key": "minuteLabel",
        "type": "label"
    },
    {
        "key": "paymentLabel",
        "type": "label"
    },
    {
        "key": "minuteDataLabel",
        "type": "label"
    },
    {
        "key": "minuteUploadDateTimeLabel",
        "type": "label"
    },
    {
        "key": "minuteAcceptanceDateTimeLabel",
        "type": "label"
    },
    {
        "key": "minuteSelectPlaceholder",
        "type": "label"
    },
    {
        "key": "insurerLabel",
        "type": "label"
    },
    {
        "key": "minuteSendLabel",
        "type": "label"
    },
    {
        "key": "getProposalLabel",
        "type": "label"
    },
    {
        "key": "getMinuteLabel",
        "type": "label"
    },
    {
        "key": "downloadEvidenceLabel",
        "type": "label"
    },
    {
        "key": "reprocessMinute",
        "type": "label"
    },
    {
        "key": "minuteProcessedAt",
        "type": "label"
    },
    {
        "key": "priceLabel",
        "type": "label"
    },
    {
        "key": "quotationCaptionPrefix",
        "type": "label"
    },
    {
        "key": "quotationCaptionSuffix",
        "type": "label"
    },
    {
        "key": "expressEmissionLabel",
        "type": "label"
    },
    {
        "key": "onlineEmissionLabel",
        "type": "label"
    },
    {
        "key": "manualEmissionLabel",
        "type": "label"
    },
    {
        "key": "downloadMinutePFDButton",
        "type": "label"
    },
    {
        "key": "downloadEvidencePFDButton",
        "type": "label"
    },
    {
        "key": "sendminuteButton",
        "type": "label"
    },
    {
        "key": "minuteAcceptedAt",
        "type": "label"
    },
    {
        "key": "hasntAcceptedLabel",
        "type": "label"
    },
    {
        "key": "minuteDataFilled",
        "type": "label"
    },
    {
        "key": "hasntAcceptedTitle",
        "type": "label"
    },
    {
        "key": "uploadEvidenceLabel",
        "type": "label"
    },
    {
        "key": "uploadEvidencePlaceholder",
        "type": "label"
    },
    {
        "key": "uploadMinuteLabel",
        "type": "label"
    },
    {
        "key": "uploadMinutePlaceholder",
        "type": "label"
    },
    {
        "key": "uploadManualEvidenceLabel",
        "type": "label"
    },
    {
        "key": "statusLabel",
        "type": "label"
    },
    {
        "key": "minutePendingLabel",
        "type": "label"
    },
    {
        "key": "acceptPendingLabel",
        "type": "label"
    },
    {
        "key": "acceptedAtComplement",
        "type": "label"
    },
    {
        "key": "generateMinuteButton",
        "type": "label"
    }
];

export default prepareTranslations(keys, translation);