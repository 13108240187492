//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation("payment");

const keys = [ 
  {
    "key": "getPaymentLabel",
    "type": "label"
  },  
  {
    "key": "dueDateFirstInstallmentLabel",
    "type": "label"
  },  
  {
    "key": "installmentAmountLabel",
    "type": "label"
  },  
  {
    "key": "paymentLabel",
    "type": "label"
  },  
  {
    "key": "totalAmountLabel",
    "type": "label"
  },  
];

export default prepareTranslations(keys, translation);