import React from "react";
//Translation
import Translation from "./translation";
//Helpers
import { formatMaskInput } from "helpers";
//Components
import { Row, Col, Radio } from "antd";
//Custom Components
import {
  LngInput,
  PhoneContact,
  DocumentNumberForm,
  Switch,
  FormField,
} from "components";
import translation from "../../../OrderResume/Surety/translation";

const InitialContent = (props) => {
  const { handleChange, configurations, searchCompany, OrderErrors } = props;
  const onChange = (attr) => (event) => {
    let value = event;
    if (typeof event === "object") {
      if (event instanceof Array) value = event;
      else value = event.target.value;
    }
    if (attr === "documentNumber") {
      return handleChange({
        attr: attr,
        value: formatMaskInput(value),
      });
    } else {
      return handleChange({
        attr: attr,
        value: value,
      });
    }
  };

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity)) {
      return configurations[entity];
    }
    return undefined;
  };

  const toggleWhatsAppMessage = () => {
    handleChange({
      attr: "receiveWhatsAppMessage",
      value: !getValue("receiveWhatsAppMessage"),
    });
  };

  return (
    <section>
      <DocumentNumberForm
        disableType
        searchCompany={searchCompany}
        onTypeChange={onChange("contactType")}
        onDocumentChange={onChange("documentNumber")}
        type={getValue("contactType")}
        documentNumber={
          formatMaskInput(getValue("documentNumber")) || undefined
        }
        personTypeLabel={Translation.personTypeLabel}
        documentNumberLabel={Translation.documentNumberLabel}
        disableSearchCompany={true}
        errors={OrderErrors["initial.documentNumber"]}
      />

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.companyLabel}
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["initial.companyName"]}>
            <LngInput
              disabled={true}
              placeholder={Translation.companyPlaceholder}
              value={getValue("companyName")}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.companyTypeLabel}
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["initial.companyType"]}>
            <LngInput
              disabled={true}
              placeholder={Translation.companyTypePlaceholder}
              value={getValue("companyType")}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.companySizeLabel}
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["initial.companySize"]}>
            <LngInput
              disabled={true}
              placeholder={Translation.companySizePlaceholder}
              value={getValue("companySize")}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.contactNameLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["initial.name"]}>
            <LngInput
              disabled={false}
              placeholder={Translation.contactNamePlaceholder}
              onChange={onChange("name")}
              value={getValue("name")}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.emailLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["initial.email"]}>
            <LngInput
              disabled={false}
              placeholder={Translation.emailPlaceholder}
              onChange={onChange("email")}
              value={getValue("email")}
            />
          </FormField>
        </Col>
      </Row>

      {/* <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.phoneTypeLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors["initial.phoneType"]}>
          <PhoneContact.Select
            value={getValue("phoneType")}
            placeholder={Translation.phoneTypePlaceholder}
            onChange={onChange("phoneType")}/>
          </FormField>
        </Col>
      </Row> */}

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.phoneNumberLabel}:
        </Col>
        <Col xs={24} md={18}>
          <Row type="flex" gutter={8}>
            <Col xs={24} lg={14}>
              <FormField error={OrderErrors["initial.phoneNumber"]}>
                <PhoneContact.Input
                  type={configurations.phoneType || "WHATSAPP_PHONE_NUMBER"}
                  value={getValue("phoneNumber")}
                  onChange={onChange("phoneNumber")}
                  placeholder={Translation.phoneNumberPlaceholder}
                />
              </FormField>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={8} lg={8}>
          {Translation.receiveWhatsAppMessageLabel}:
        </Col>
        <Col xs={24} md={16} lg={16}>
          <Radio.Group
            onChange={toggleWhatsAppMessage}
            value={getValue("receiveWhatsAppMessage")}
          >
            <Radio value={true}>{Translation.yes}</Radio>
            <Radio value={false}>{Translation.no}</Radio>
          </Radio.Group>
        </Col>
      </Row>
    </section>
  );
};

export default InitialContent;
