//Helper functions`
import { setInitialTranslation } from "helpers";
//Constants
const translation = setInitialTranslation("editUser");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
  return translation.set(...arguments);
}

export default {
  title: getTranslation("title"),
  uploadImageTitle: getTranslation("uploadImageTitle"),
  nameEntity: getTranslation("nameEntity"),
  nameEntityValidation: getTranslation("nameEntityValidation"),
  positionEntity: getTranslation("positionEntity"),
  positionEntityValidation: getTranslation("positionEntityValidation"),
  profileEntity: getTranslation("profileEntity"),
  profileKeyEntityValidation: getTranslation("profileKeyEntityValidation"),
  profileKeyEntityPlaceholder: getTranslation("profileKeyEntityPlaceholder"),
  emailEntity: getTranslation("emailEntity"),
  emailEntityTypeValidation: getTranslation("emailEntityTypeValidation"),
  emailEntityReqValidation: getTranslation("emailEntityReqValidation"),
  whatsAppEntity: getTranslation("whatsAppEntity"),
  whatsAppTypeValidation: getTranslation("whatsAppEntityTypeValidation"),
  whatsAppEntityReqValidation: getTranslation("whatsAppEntityReqValidation"),
  orderProductsLabel: getTranslation("orderProductsLabel"),
  orderProductsPlaceholder: getTranslation("orderProductsPlaceholder"),
  orderProductsValidation: getTranslation("orderProductsValidation"),
  orderProductsReqValidation: getTranslation("orderProductsReqValidation"),
  extensionNumberLabel: getTranslation("extensionNumberLabel"),
  extensionNumberValidation: getTranslation("extensionNumberValidation"),
  noChangesLabel: getTranslation("noChangesLabel"),
  ok: getTranslation("ok", true),
  cancel: getTranslation("cancel", true),
  hasHubspotLabel: getTranslation("hasHubspotLabel"),
  hasHubspotYesLabel: getTranslation("hasHubspotYesLabel"),
  hasHubspotNoLabel: getTranslation("hasHubspotNoLabel"),
  phoneTypeLabel: getTranslation("phoneTypeLabel"),
  phoneTypeMobileLabel: getTranslation("phoneTypeMobileLabel"),
  phoneTypePhoneLabel: getTranslation("phoneTypePhoneLabel"),
};
