import React from "react";
// prop types
import PropoTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, DatePicker } from "antd";
// components
import FormField from "../../../../../FormField";
import CurrencyInput from "../../../../../CurrencyInput";
// helpers
import {
  dateFormat,
  frontEndDate,
  getErrorMessage,
} from "../../../../../../helpers";
import moment from "moment";

const InstallmentForm = ({
  installment = {},
  errors = [],
  installmentIndex,
  onChange,
}) => {
  const { t: translation } = useTranslation();

  const handleChange = (field, value) => onChange && onChange(field, value);

  return (
    <>
      <Row type="flex" align="middle" className="gx-mb-2">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.installmentAmountLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `installments[${installmentIndex}].amount`,
              errors
            )}
          >
            <CurrencyInput
              className="gx-w-100"
              value={installment.amount}
              onChange={(value) => handleChange("amount", value)}
              placeholder={translation(
                "proposal.surety.installmentAmountPlaceholder"
              )}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.installmentDueDateLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage(
              `installments[${installmentIndex}].dueDate`,
              errors
            )}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                installment.dueDate
                  ? moment(installment.dueDate, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("dueDate", value)}
            />
          </FormField>
        </Col>
      </Row>
    </>
  );
};

InstallmentForm.propTypes = {
  installment: PropoTypes.object,
  errors: PropoTypes.array,
  installmentIndex: PropoTypes.number,
  onChange: PropoTypes.func,
};

export default InstallmentForm;
