//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("order");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}


export default {
    emptyOpenPolicyQuotationsLabel: getTranslation("emptyOpenPolicyQuotationsLabel"),
    openPolicyErrorLabel: getTranslation("openPolicyErrorLabel"),
    otherQuotationLabel: getTranslation("otherQuotationLabel"),
    displayedQuotations: getTranslation("displayedQuotations"),
    ourIndicationLabel: getTranslation("ourIndicationLabel"),
    hideDetailsButtonLabel : getTranslation("hideDetailsButtonLabel"),
    seeDetailsButtonLabel : getTranslation("seeDetailsButtonLabel"),
    openPolicyTheftLabel : getTranslation("openPolicyTheftLabel"),
    openPolicyLifeLabel : getTranslation("openPolicyLifeLabel"),
    openPolicyAdditionalLabel : getTranslation("openPolicyAdditionalLabel"),
    openPolicyAccidentLabel : getTranslation("openPolicyAccidentLabel"),
    minimalInvoiceLabel : getTranslation("minimalInvoiceLabel"),
    monthlyMinimalInvoiceLabel : getTranslation("monthlyMinimalInvoiceLabel"),
    underConsultLabel : getTranslation("underConsultLabel"),
    estimatedInvoiceLabel : getTranslation("estimatedInvoiceLabel"),
    cargoQuotationTaxLabel : getTranslation("cargoQuotationTaxLabel"),
    
}

