//Combine Reducers
import { combineReducers } from "redux";
//Action Types
import * as types from "../../actionTypes/ActionTypes";
//Helpers
import {
  updateOrdersFilter,
  getInitialConfigurations,
  prepareOrderConfigurations,
} from "./helpers";
import { customerTabs } from "../../../constants";
//Other Libs
import produce from "immer";
/*Initial State*/
const initialState = {
  order: {
    details: {},
    configurations: getInitialConfigurations(),
    isFetching: false,
    isResetting: false,
    isPreparing: false,
    isSaving: false,
    orderErrors: {},
    selectedTab: "initial",
    expressErrors: [],
  },
  orders: {
    all: [],
    currentPage: 1,
    isFetching: false,
    filters: {
      stage: "CREATED",
      types: {
        CARGO_OPEN_POLICY: false,
        CARGO_SINGLE_BOARDING: false,
        LIFE: false,
        SURETY: false,
      },
      searches: {
        companyName: "",
        documentNumber: "",
        contactName: "",
        email: "",
        phoneNumber: "",
      },
    },
    initializedFilters: false,
    filtersUpdated: false,
  },
  customers: {
    list: [],
    pagination: {
      pageNumber: 0,
    },
    customerTabs: {
      registerData: {
        contacts: [],
      },
      actualTab: customerTabs.registerData,
      contactData: {
        contacts: {
          data: [],
        },
        selectedTab: "0",
      },
      suretyRequests: {},
      currentPolicy: {
        list: [],
      },
      overduePolicy: {},
      financial: {},
      claims: {},
    },
    isFetching: false,
    isLoading: false,
    isChanged: false,
    customersErrors: {
      errors: {},
    },
  },
  policy: {
    data: {},
    types: [],
    policyErrors: {},
    isFetching: false,
    isChanged: false,
    isSaving: false,
    isSending: false,
    isRemovingFile: false,
    dataSaved: false,
    currentTab: 2,
  },
  invoice: {
    data: {},
    errors: [],
    isChanged: false,
    isSaving: false,
    isSending: false,
    isModalVisible: true,
  },
};
/*Reducers*/

const ordersReducer = (state = initialState.orders, action) => {
  switch (action.type) {
    case types.INITIALIZE_ORDER_CONFIGURATIONS: {
      const { authUser } = action;
      const userProducts = authUser.products;
      const updatedProducts = { ...state.filters.types };
      for (let product in updatedProducts) {
        updatedProducts[product] = userProducts.includes(product);
      }
      state = {
        ...state,
        initializedFilters: true,
        filters: {
          ...state.filters,
          types: updatedProducts,
        },
      };
      break;
    }
    case types.FETCHING_ORDERS:
      state = {
        ...state,
        filtersUpdated: false,
        isFetching: true,
      };
      break;
    case types.FETCH_ORDERS_SUCCESS: {
      const { payload, currentPage } = action;
      state = {
        ...state,
        all: payload,
        isFetching: false,
        fetched: true,
        currentPage: currentPage ? currentPage : state.currentPage,
        search: "",
      };
      break;
    }
    case types.FETCH_ORDERS_FAILURE:
      state = {
        ...state,
        isFetching: false,
        fetched: false,
      };
      break;
    case types.UPDATE_ORDERS_FILTER: {
      const { filterType, value } = action;
      state = {
        ...state,
        filtersUpdated: true,
        filters: updateOrdersFilter(filterType, value, state.filters),
      };
      break;
    }
    default:
      break;
  }
  return state;
};

const orderReducer = produce((state, action) => {
  switch (action.type) {
    case types.REGISTER_EXPRESS_ERRORS: {
      state.expressErrors = action.expressErrors;
      break;
    }
    case types.CHANGE_TAB: {
      state.selectedTab = action.selectedTab;
      break;
    }
    case types.FETCHING_ORDER: {
      state.isFetching = true;
      return;
    }
    case types.RESET_ORDER_STARTED: {
      state.isResetting = true;
      return;
    }
    case types.FETCH_ORDER_SUCCESS: {
      const { id, payload } = action;
      state.details[id] = payload;
      state.isFetching = false;
      return;
    }
    case types.FETCH_ORDER_FAILURE: {
      state.isFetching = false;
      break;
    }
    case types.PREPARE_ORDER_STARTED: {
      state.isPreparing = true;
      break;
    }
    case types.PREPARE_ORDER_CONFIGURATIONS: {
      const { data } = action;
      const prevConfig = getInitialConfigurations(data.type);
      state.configurations = prepareOrderConfigurations(data, prevConfig);
      state.isPreparing = false;
      state.expressErrors = data.expressError ? [data.expressError] : [];
      break;
    }
    case types.SEARCHING_COMPANY: {
      state.isPreparing = true;
      break;
    }
    case types.CHANGE_MINUTE_DATA: {
      const configurations = state.configurations.minute;
      configurations.minute = action.payload;
    }
    case types.UPDATE_ORDER_CONFIGURATIONS: {
      const { entity, data } = action;

      const configurations = state.configurations[entity];
      switch (entity) {
        case "principal":
        case "insured":
          configurations.name = data.name;
          configurations.address = data.address.address;
          configurations.number = data.address.addressNumber;
          configurations.additionalData = data.address.additionalData;
          configurations.neighborhood = data.address.neighborhood;
          configurations.zipCode = data.address.zipCode;
          configurations.state = data.address.state;
          configurations.city = data.address.city;
          break;
        case "initial":
          configurations.name = data.name;
          configurations.phoneNumber = data.phoneNumber;
          configurations.email = data.email;
          break;
        case "obligee":
          configurations.name = data.name;
          configurations.address = data.address.address;
          configurations.number = data.address.addressNumber;
          configurations.additionalData = data.address.additionalData;
          configurations.neighborhood = data.address.neighborhood;
          configurations.zipCode = data.address.zipCode;
          configurations.city = data.address.city;
          configurations.state = data.address.state;
          break;
        case "customer":
          configurations.name = data.name;
          configurations.address = data.address.address;
          configurations.number = data.address.addressNumber;
          configurations.additionalData = data.address.additionalData;
          configurations.neighborhood = data.address.neighborhood;
          configurations.zipCode = data.address.zipCode;
          configurations.city = data.address.city;
          configurations.state = data.address.state;
          break;
        case "complainant":
          configurations.name = data.name;
          break;
        default:
          break;
      }
      state.isPreparing = false;
      break;
    }
    case types.SEARCH_COMPANY_FAILURE: {
      state.isPreparing = false;
      break;
    }
    case types.CLEAR_ORDER_CONFIGURATIONS: {
      state.configurations = {};
      state.details = {};
      break;
    }
    case types.RESET_ORDER_CONFIGURATIONS: {
      const { id } = action;
      const data = state.details[id];
      const configurations = getInitialConfigurations(data.type);
      state.configurations = prepareOrderConfigurations(data, configurations);
      state.isResetting = false;
      break;
    }
    case types.CHANGE_ORDER_CONFIGURATIONS: {
      const { type, changeType, changes } = action.config;
      if (changeType === "single") {
        const { attr, value } = changes;
        if (type === "quotations") {
          state.configurations[type] = value;
        } else {
          state.configurations[type][attr] = value;
        }
      } else {
        for (let change of changes) {
          const { attr, value } = change;
          state.configurations[type][attr] = value;
        }
      }
      return;
    }
    case types.SAVING_ORDER: {
      state.isSaving = true;
      return;
    }
    case types.FETCH_PAYMENTS_SUCCESS: {
      state.configurations["payments"]["payment"] = action.payload;
      return;
    }
    case types.SAVE_ORDER_FAILURE: {
      const { payload } = action;
      state.isSaving = false;
      if (payload.hasOwnProperty("errors")) {
        state.orderErrors = payload.errors;
      }
      return;
    }
    case types.SAVE_ORDER_SUCCESS: {
      const { id, payload } = action;
      state.details[id] = payload;
      state.isSaving = false;
      return;
    }
    //cargo Open
    case types.REGISTER_CARGO_QUOTATIONS: {
      const { payload } = action;
      state.configurations.quotationData = payload;
      return;
    }

    case types.REGISTER_SELECTED_CARGO_QUOTATION: {
      const { payload } = action;
      state.configurations.quotationData.chosenInsurer = payload.insurer;
      return;
    }

    case types.REGISTER_SELECTED_INSURER: {
      const { payload } = action;
      state.configurations.quotationData.chosenInsurer = payload;
      return;
    }

    case types.SET_GLOBAL_LOADER: {
      const { payload } = action;
      state.isFetching = payload;
      return;
    }

    case types.SET_COMPANY_DATA: {
      const { payload, id } = action;
      const { name } = payload;
      const initial = {
        ...payload,
        ...state.configurations["initial"],
        companyName: name,
      };
      state.configurations["initial"] = initial;
      state.details[id]["initial"] = initial;
      return;
    }

    default:
      break;
  }
}, initialState.order);

const customersReducer = produce((state, action) => {
  switch (action.type) {
    case types.REGISTER_LIST:
      const { data, ...params } = action.payload;
      state.list = data;
      state.pagination = params;
      break;
    case types.IS_FETCHING_LIST:
      state.isFetching = action.payload;
      break;
    case types.FETCH_CUSTOMER_DATA_SUCCESS:
      state.customerTabs.registerData = action.payload;
      break;
    case types.CHANGE_CUSTOMER_DATA:
      state.customerTabs.registerData[action.payload.name] =
        action.payload.value;
      state.isChanged = true;
      break;
    case types.CUSTOMER_DATA_LOADING:
      state.isLoading = action.payload;
      break;
    case types.RESET_IS_CHANGED:
      state.isChanged = false;
      break;
    case types.CHANGE_CUSTOMERS_TAB:
      state.customerTabs.actualTab = action.payload;
      break;
    case types.REGISTER_PAGINATION_PARAM:
      state.pagination[action.payload.name] = action.payload.value;
      break;
    case types.REGISTER_CUSTOMERS_CONTACTS:
      state.customerTabs.contactData.contacts = action.payload.data;
      state.isChanged = action.payload.isChanged;
      break;
    case types.REGISTER_CUSTOMERS_ERRORS:
      state.customersErrors = action.errors;
      state.isChanged = false;
      break;
    case types.REGISTER_CUSTOMER_CONTACTS_TAB:
      state.customerTabs.contactData.selectedTab = action.payload;
      break;
    case types.REGISTER_CUSTOMERS_POLICY_LIST:
      const policyData = action.payload.data;
      const { pageNumber, pageSize, pageTotal, sort, total } = action.payload;
      state.customerTabs.currentPolicy.list = policyData;
      state.pagination = { pageNumber, pageSize, pageTotal, sort, total };
      break;
    case types.SET_IS_CHANGED:
      state.isChanged = true;
      break;
  }
}, initialState.customers);
const policyReducer = produce((state, action) => {
  switch (action.type) {
    case types.FETCHING_POLICY_TYPES:
    case types.FETCHING_CUSTOMER_POLICY:
      state.isFetching = action.payload;
      break;
    case types.FETCH_CUSTOMER_POLICY_SUCCESS:
      state.data = action.payload;
      state.isChanged = false;
      break;
    case types.FETCH_POLICY_TYPES_SUCCESS:
      state.types = action.payload;
      state.isChanged = false;
      break;
    case types.CHANGE_CUSTOMER_POLICY_DATA:
      state.data[action.payload.name] = action.payload.value;
      state.isChanged = true;
      state.policyErrors = {};
      break;
    case types.RESET_POLICY_DATA_IS_CHANGED:
      state.isChanged = false;
      break;
    case types.SAVING_CUSTOMER_POLICY_DATA:
      state.isSaving = action.payload;
      break;
    case types.CLEAR_CUSTOMER_POLICY_DATA:
      state.data = {};
      break;
    case types.REGISTER_POLICY_DATA_ERRORS:
      state.policyErrors = action.payload;
      break;
    case types.REMOVING_POLICY_FILE:
      state.isRemovingFile = action.payload;
      break;
    case types.SET_DATA_IS_SAVED:
      state.dataSaved = action.payload;
      break;
    case types.SET_CURRENT_POLICY_TAB:
      state.currentTab = action.payload;
      break;
    case types.IS_SENDING_POLICY_BY_EMAIL:
      state.isSending = action.payload;
      break;
  }
}, initialState.policy);

const invoiceReducer = produce((state, action) => {
  switch (action.type) {
    case types.SAVING_INVOICE_DATA:
      state.isSaving = action.payload;
      break;
    case types.CHANGE_INVOICE_DATA:
      state.data = {
        ...state.data,
        ...action.payload,
      };
      break;
    case types.INVOICE_DATA_IS_CHANGED:
      state.isChanged = action.payload;
      break;
    case types.REGISTER_INVOICE_ERRORS:
      state.errors = action.payload;
      break;
    case types.CLEAR_INVOICE_DATA:
      state.data = {};
      break;
    case types.SET_INVOICE_MODAL_VISIBLE:
      state.isModalVisible = action.payload;
      break;
    case types.SET_INVOICE_DATA:
      state.data = action.payload;
      break;
    case types.IS_SENDING_INVOICE_BY_EMAIL:
      state.isSending = action.payload;
      break;
  }
}, initialState.invoice);

export default combineReducers({
  order: orderReducer,
  orders: ordersReducer,
  customers: customersReducer,
  policy: policyReducer,
  invoice: invoiceReducer,
});
