//PropTypes
import * as PropTypes from "prop-types";
/*config*/
import { fieldsList } from './config';

export const TermDataWrapper = (props) => {
    const {type, step, field, children} = props;
    let allowedJsonTerms = null;
    if (type)
        allowedJsonTerms = fieldsList[step][field];

    if (allowedJsonTerms) {
        const validJsonTerm = allowedJsonTerms.includes(type);
        if (validJsonTerm)
            return children;
        else
            return null
    } else {
        return null;
    }
};

TermDataWrapper.propTypes = {
    type: PropTypes.string,
    step: PropTypes.string,
    field: PropTypes.string,
};