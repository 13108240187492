// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../../util/axios";
// common
import { setIsLoading } from "../../common";

export const coverageSlice = createSlice({
  name: "coverages",
  initialState: {
    coverageData: {
      plans: [
        // {
        //   id: 1,
        //   order: 1,
        //   name: "MANDATORY",
        //   recommended: false,
        //   selected: false,
        //   basicCoverages: [
        //     {
        //       id: 1,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //   ],
        //   additionalCoverages: [
        //     {
        //       id: 1,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //   ],
        //   premium: 9000.0,
        // },
        // {
        //   id: 2,
        //   order: 2,
        //   name: "ECONOMIC",
        //   recommended: false,
        //   selected: false,
        //   basicCoverages: [
        //     {
        //       id: 2,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 5,
        //       type: "damage",
        //       description:
        //         "Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //       label: "Cobertura de avarias",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //   ],
        //   additionalCoverages: [
        //     {
        //       id: 2,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 5,
        //       type: "damage",
        //       description:
        //         "Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //       label: "Cobertura de avarias",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //   ],
        //   premium: 18000.0,
        // },
        // {
        //   id: 3,
        //   order: 3,
        //   name: "STANDARD",
        //   recommended: true,
        //   selected: false,
        //   basicCoverages: [
        //     {
        //       id: 3,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 6,
        //       type: "damage",
        //       description:
        //         "Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //       label: "Cobertura de avarias",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 8,
        //       type: "theft",
        //       description:
        //         "Cobertura de roubo durante o trânsito; Desaparecimento total da carga com o do veiculo durante o transporte.",
        //       label: "Roubo Apólice RCF-DC",
        //       premium: 0.0,
        //       selected: true,
        //     },
        //   ],
        //   additionalCoverages: [
        //     {
        //       id: 3,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 6,
        //       type: "damage",
        //       description:
        //         "Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //       label: "Cobertura de avarias",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 8,
        //       type: "theft",
        //       description:
        //         "Cobertura de roubo durante o trânsito; Desaparecimento total da carga com o do veiculo durante o transporte.",
        //       label: "Roubo Apólice RCF-DC",
        //       premium: 0.0,
        //       selected: true,
        //     },
        //   ],
        //   premium: 18000.0,
        // },
        // {
        //   id: 4,
        //   order: 4,
        //   name: "FULL",
        //   recommended: false,
        //   selected: true,
        //   basicCoverages: [
        //     {
        //       id: 4,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 7,
        //       type: "damage",
        //       description:
        //         "Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //       label: "Cobertura de avarias",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 9,
        //       type: "theft",
        //       description:
        //         "Cobertura de roubo durante o trânsito; Desaparecimento total da carga com o do veiculo durante o transporte.",
        //       label: "Roubo Apólice RCF-DC",
        //       premium: 0.0,
        //       selected: true,
        //     },
        //     {
        //       id: 10,
        //       type: "environmentalLiabilityRisk",
        //       description:
        //         "Apólice especifica para cobrir  danos que englobam a contaminação do meio ambiente. Coberturas como: investigação, remoção remediação e disposição final do reíduo.",
        //       label: "Risco Civil Ambiental",
        //       premium: 0.0,
        //       selected: true,
        //     },
        //   ],
        //   additionalCoverages: [
        //     {
        //       id: 4,
        //       type: "accident",
        //       description:
        //         "Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //       label: "Acidente Apólice RCTR-C",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 7,
        //       type: "damage",
        //       description:
        //         "Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //       label: "Cobertura de avarias",
        //       premium: 9000.0,
        //       selected: true,
        //     },
        //     {
        //       id: 9,
        //       type: "theft",
        //       description:
        //         "Cobertura de roubo durante o trânsito; Desaparecimento total da carga com o do veiculo durante o transporte.",
        //       label: "Roubo Apólice RCF-DC",
        //       premium: 0.0,
        //       selected: true,
        //     },
        //     {
        //       id: 10,
        //       type: "environmentalLiabilityRisk",
        //       description:
        //         "Apólice especifica para cobrir  danos que englobam a contaminação do meio ambiente. Coberturas como: investigação, remoção remediação e disposição final do reíduo.",
        //       label: "Risco Civil Ambiental",
        //       premium: 0.0,
        //       selected: true,
        //     },
        //   ],
        //   premium: 18000.0,
        // },
      ],
      allCoverages: [
        // {
        //    "type":"accident",
        //    "description":"Cobertura de colisão e/ou capotagem e/ou abalroamento e/ou tombamento do veículo transportador; incêndio ou explosão no veículo transportador.",
        //    "label":"Acidente Apólice RCTR-C",
        //    "premium":50,
        //    "selected":true
        // },
        // {
        //    "type":"damage",
        //    "description":"Cobertura de eventos não oriundos de acidente rodoviário, tais como: Quebra, Derrame, vazamento, arranhadura, amolgamento, amassamento, queda e outras.",
        //    "label":"Cobertura de avarias"
        // },
        // {
        //    "type":"theft",
        //    "description":"Cobertura de roubo durante o trânsito; Desaparecimento total da carga com o do veiculo durante o transporte.",
        //    "label":"Roubo Apólice RCF-DC"
        // },
        // {
        //    "type":"loadingUnloadingLifting",
        //    "description":"Cobertura de carga e descarga, com içamento efetuadas por maquinas adaptadas para tais operações.",
        //    "label":"Carga, Descarga e Içamento"
        // },
        // {
        //    "type":"fluvialRoute",
        //    "description":"Cobertura para viagem rodoviária com percurso complementar fluvial.",
        //    "label":"Percurso Fluvial"
        // },
        // {
        //    "type":"environmentalLiabilityRisk",
        //    "description":"Apólice especifica para cobrir  danos que englobam a contaminação do meio ambiente. Coberturas como: investigação, remoção remediação e disposição final do reíduo.",
        //    "label":"Risco Civil Ambiental"
        // },
        // {
        //    "type":"trackCleaning",
        //    "description":"Limpeza de pista e/ou acostamento; contenção e remoção da mercadoria e/ou seus residuos; Utilização de produtos para anulação dos efeitos causados pela mercadoria sinistrada; Limpeza e desinfecção do compartimento de carga; Tratamento e destinação dos residuos.",
        //    "label":"Limpeza da Pista"
        // },
        // {
        //    "type":"freight",
        //    "description":"Cobertura relacionada ao reembolso do frete em ocorrência de sinistro.",
        //    "label":"Frete"
        // },
        // {
        //    "type":"lifeInsurance",
        //    "description":"Apólice especifica para cobrir o seguro obrigatório para motoristas de caminhão e funcionários da empresa.",
        //    "label":"Seguro de vida para os motoristas"
        // },
        // {
        //    "type":"other",
        //    "description":"Informe outra cobertura desejada.",
        //    "label":"Outros"
        // }
     ],
    },
    errors: [],
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setPlanList: (state, action) => {
      state.coverageData.plans = action.payload;
    },
    setAllCoverages: (state, action) => {
      state.coverageData.allCoverages = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const {
  setErrors,
  setPlanList,
  setAllCoverages,
} = coverageSlice.actions;

export default coverageSlice.reducer;

// -----------------------------------------------------------------

export const fetchCoveragePlans = (code) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/cargo/v2/open-policy/${code}/plans`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const sendCoveragePlans = (code, data) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/cargo/v2/open-policy/${code}/plans`, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
