import React, { useState } from "react";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Modal, Input, Row, Button, Card, Spin } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import { shareOpenPolicyQuotation } from "../../../../store/slices/order";
// custom styles
import "./styles.less";

const ShareQuotationModal = ({ code, onCancel, onChange, onSubmit, ...props }) => {
  const [emails, setEmails] = useState("");

  const {
    common: { isLoading },
  } = useSelector((state) => state);

  const { t: translation } = useTranslation();
  const dispatch = useDispatch();

  const handleCancel = () => {
    return onCancel && onCancel();
  };

  const handleChange = (e) => {
    setEmails(e.target.value);
    return onChange && onChange(e.target.value);
  };

  const handleSubmit = () => {
    const emailList = [];
    emails.split(",").forEach((email) => {
      emailList.push(email.trim());
    });
    dispatch(shareOpenPolicyQuotation(code, emailList))
      .then(() => {
        handleCancel();
      })
      .catch(() => {});
    return onSubmit && onSubmit(emailList);
  };

  return (
    <Modal
      visible
      width={647}
      maskClosable
      footer={null}
      closable={false}
      className="shareQuotationModal"
      onCancel={handleCancel}
      {...props}
    >
      <Spin spinning={isLoading}>
        <Card className="gx-mb-0" bordered={false}>
          <h5 className="gx-mb-10">
            {translation("order.resume.shareQuotationModalTitle")}
          </h5>
          <Input
            className="gx-mb-30"
            onChange={handleChange}
            placeholder={translation(
              "order.resume.shareQuotationModalPlaceholder"
            )}
          />
          <Row type="flex" justify="center">
            <Button className="gx-mb-0" onClick={handleSubmit}>
              {translation("order.resume.shareQuotationModalButton")}
            </Button>
          </Row>
        </Card>
      </Spin>
    </Modal>
  );
};

export default ShareQuotationModal;
