//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation();

const keys = [
  {
    "key": "initialDateLabel",
    "type": "label"
  },
  {
    "key": "daysBetweenLabel",
    "type": "label"
  },  
  {
    "key": "daysBetweenPlaceholder",
    "type": "placeholder"
  },  
  {
    "key": "appealTypeLabel",
    "type": "label"
  },
  {
    "key": "appealTypePlaceholder",
    "type": "label"
  },  
  {
    "key": "finalDateLabel",
    "type": "label"
  },  
  {
    "key": "suretyAmountLabel",
    "type": "label"
  },  
  {
    "key": "suretyAmountPlaceholder",
    "type": "placeholder"
  },  
  {
    "key": "processAmountLabel",
    "type": "label"
  },  
  {
    "key": "processAmountPlaceholder",
    "type": "placeholder"
  },  
  {
    "key": "depositAppealLabel",
    "type": "label"
  },  
  {
    "key": "courtSuretyAmountLabel",
    "type": "label"
  },  
  {
    "key": "contractAmountLabel",
    "type": "label"
  },
  {
    "key": "contractTypeLabel",
    "type": "label"
  },  
  {
    "key": "contractAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "suretyPercentageLabel",
    "type": "label"
  },  
  {
    "key": "suretyPercentagePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "labourCoverageLabel",
    "type": "label"
  },  
  {
    "key": "penaltyCoverageLabel",
    "type": "label"
  },  
  {
    "key": "bondTypeLabel",
    "type": "label"
  },
  {
    "key": "bondTypePlaceholder",
    "type": "label"
  },
  {
    "key": "liabilityInsuranceLabel",
    "type": "label"
  },
  {
    "key": "engineeringRiskInsuranceLabel",
    "type": "label"
  },
  {
    "key": "groupLifeInsuranceLabel",
    "type": "label"
  },
];

export default prepareTranslations(keys, translation);