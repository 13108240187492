import React, { useState, useEffect } from "react";
// router
import { useHistory, useParams } from "react-router";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Modal, Spin, Card, Tag, Row } from "antd";
// redux
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerData } from "../../../../../store/slices/customer";
import { fetchPolicyData } from "../../../../../store/slices/policy";
import {
  fetchInvoice,
  fetchEmailHistory,
  clearEmailHistory
} from "../../../../../store/slices/invoice";
// components
import {
  HistoryHeader,
  HistoryList,
} from "../../../../../components/Customers/Policy/EmailHistory";
// styles
import "./styles.less";
// contants
import { emailHashTypes } from "../../../../../constants";

const EmailHistory = () => {
  const [visible, setVisible] = useState(true);
  const {
    common: { isLoading },
    invoice: {
      emailHistory = [],
      expirationEmailHistory = [],
      preExpirationEmailHistory = [],
    },
    policy: { policy: policyData },
    customer: { registerData },
  } = useSelector((state) => state);

  const { t: translation } = useTranslation();
  const history = useHistory();
  const { id, policy, invoice  } = useParams();
  const dispatch = useDispatch();

  const handleCancel = () => {
    setVisible(false);
    history.push(
      `/crm/customers/${id}/policy/${policy}/invoice/${invoice}`
    );
  };

  const fetchEmailEvents = (emailHash, hashType) => {
    return emailHash && dispatch(fetchEmailHistory(emailHash, hashType));
  };

  useEffect(() => {
    dispatch(fetchCustomerData(id));
    dispatch(fetchPolicyData(id, policy));
    dispatch(fetchInvoice(policy, invoice)).then((response) => {
      const {
        emailIdentification,
        preExpirationHashIdentification,
        expirationEmailHashIdentification,
      } = response.data;
      fetchEmailEvents(emailIdentification, emailHashTypes.emailHistory);
      fetchEmailEvents(
        preExpirationHashIdentification,
        emailHashTypes.expirationEmailHistory
      );
      fetchEmailEvents(
        expirationEmailHashIdentification,
        emailHashTypes.preExpirationEmailHistory
      );
    });
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearEmailHistory());
    }
  }, [])

  return (
    <Modal
      width={960}
      visible={visible}
      footer={null}
      centered={true}
      wrapClassName="history-modal"
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <Card className="gx-mt-5 gx-p-3">
          <HistoryHeader registerData={registerData} policy={policyData} />
          <Card className="gx-mt-3 gx-p-3">
            <h5 className="gx-mb-3">
              {translation("invoice.historyList.title")}
            </h5>
            {emailHistory && (
              <HistoryList
                label={translation("invoice.paymentSlipEmail")}
                emailHistory={emailHistory}
              />
            )}
            {preExpirationEmailHistory && (
              <HistoryList
                label={translation("invoice.preExpirationEmail")}
                emailHistory={preExpirationEmailHistory}
              />
            )}
            {expirationEmailHistory && (
              <HistoryList
                label={translation("invoice.expirationEmail")}
                emailHistory={expirationEmailHistory}
              />
            )}
            {emailHistory.length === 0 &&
              preExpirationEmailHistory.length === 0 &&
              expirationEmailHistory.length === 0 && (
                <Row type="flex" justify="center">
                  <Tag color="blue">
                    {translation("invoice.historyItem.emptyLabel")}
                  </Tag>
                </Row>
              )}
          </Card>
        </Card>
      </Spin>
    </Modal>
  );
};

export default EmailHistory;
