import React, { useState } from "react";
// lodash
import _ from "lodash";
// antd
import { Radio } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// custom components
import RadioGroupItem from "./RadioGroupItem";
// translation
import translation from "./translation";
// custom styles
import "./style.less";

const SuretyQuotationTable = ({
  items,
  defaultValue,
  bestChoice,
  onChange,
}) => {
  const [showAll, setShowAll] = useState(false);

  const handleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div
      className={
        items.length > 3 && showAll
          ? "gx-w-100 RadioGroupBox showAll"
          : "gx-w-100 RadioGroupBox"
      }
    >
      <div className="title-bar">
        <div className="logo gx-pl-3">{translation.insurerHeaderLabel}</div>
        <div className="issue gx-pr-3">{translation.typeHeaderLabel}</div>
        <div className="text">{translation.taxHeaderLabel}</div>
        <div className="content">{translation.priceHeaderLabel}</div>
      </div>
      <Radio.Group
        onChange={onChange}
        className="radio-group"
        value={defaultValue}
      >
        {items.map((item, index) => {
          if (item.insurer !== "SIMULATION") {
            return (
              <RadioGroupItem
                key={index}
                item={item}
                bestChoice={_.isEqual(bestChoice, item)}
              />
            );
          } else {
            return null;
          }
        })}
      </Radio.Group>
      {items.length > 3 ? (
        <div className="viewMoreLink" onClick={handleShowAll}>
          {showAll ? (
            <>
              <FontAwesomeIcon icon={["fas", "caret-up"]} />{" "}
              {translation.hideQuotationsLabel}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={["fas", "caret-down"]} />{" "}
              {translation.showQuotationsLabel}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default SuretyQuotationTable;
