//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("orderDetails");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    detailsMenuItemLabel: getTranslation("detailsMenuItemLabel"),
    archiveMenuItemLabel: getTranslation("archiveMenuItemLabel"),
    deleteMenuItemLabel: getTranslation("deleteMenuItemLabel"),

    contactNameLabel: getTranslation("contactNameLabel"),
    companyNameLabel: getTranslation("companyNameLabel"),
    emailLabel: getTranslation("emailLabel"),
    documentNumberLabel: getTranslation("documentNumberLabel"),
    contactPhoneLabel: getTranslation("contactPhoneLabel"),
    createdAtLabel: getTranslation("createdAtLabel"),
    updatedAtLabel: getTranslation("updatedAtLabel"),
    orderTypeLabel: getTranslation("orderTypeLabel"),
    bondTypeLabel: getTranslation("bondTypeLabel")
};

