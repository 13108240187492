// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message, notification } from "antd";
// common
import { setIsLoading } from "../common";
// moment
import moment from "moment";
// contants
import { emailHashTypes } from "../../../constants";

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState: {
    errors: [],
    invoice: {},
    emailHistory: [],
    expirationEmailHistory: [],
    preExpirationEmailHistory: [],
    invoiceDataIsChanged: false,
    enableChange: false,
  },
  reducers: {
    setInvoiceData: (state, action) => {
      state.invoice = action.payload;
    },
    setInvoiceMarkAsPaid: (state, action) => {
      state.invoice = { ...state.invoice, ...action.payload };
    },
    changeInvoiceData: (state, action) => {
      state.invoice = { ...state.invoice, ...action.payload };
      state.invoiceDataIsChanged = true;
    },
    setEmailHistory: (state, action) => {
      const { data, hashType } = action.payload;
      switch (hashType) {
        case emailHashTypes.emailHistory:
          state.emailHistory = data;
          break;
        case emailHashTypes.expirationEmailHistory:
          state.expirationEmailHistory = data;
          break;
        case emailHashTypes.preExpirationEmailHistory:
          state.preExpirationEmailHistory = data;
          break;
        default:
          break;
      }
    },
    clearEmailHistory: (state, action) => {
      state.emailHistory = [];
      state.expirationEmailHistory = [];
      state.preExpirationEmailHistory = [];
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setInvoiceDataIsChanged: (state, action) => {
      state.invoiceDataIsChanged = action.payload;
    },
    setEnableChange: (state, action) => {
      state.enableChange = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const {
  setErrors,
  setInvoiceData,
  setInvoiceMarkAsPaid,
  setEmailHistory,
  clearEmailHistory,
  changeInvoiceData,
  setInvoiceDataIsChanged,
  setEnableChange,
} = invoiceSlice.actions;

export default invoiceSlice.reducer;

// -----------------------------------------------------------------

export const fetchInvoice = (policy, invoice) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/policy/v1/${policy}/invoice/${invoice}`)
      .then((data) => {
        dispatch(setInvoiceData(data.data));
        resolve(data);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const fetchEmailHistory = (hash, hashType) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/notification/v1/email/${hash}`)
      .then((response) => {
        dispatch(
          setEmailHistory({
            data: response.data,
            hashType,
          })
        );
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const createNewInvoice = (policyId) => (dispatch, getState) => {
  const { invoice } = getState().invoice;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/policy/v1/${policyId}/invoice`, invoice)
      .then((response) => {
        message.success(i18n.t("invoice.createNewInvoiceSuccess"));
        resolve(response);
      })
      .catch((error) => {
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        } else {
          message.error(
            error.response
              ? error.response.data.message
              : i18n.t("unexpectedError")
          );
        }

        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const newInvoiceUploadFile = (
  path,
  file,
  onSuccess,
  onError,
  onProgress
) => (dispatch) => {
  dispatch(setIsLoading(true));

  const formData = new FormData();
  formData.append("file", file);

  const headers = {
    "Content-type": "multipart/form-data",
  };

  const config = {
    onUploadProgress: ({ total, loaded }) => {
      onProgress(
        { percent: Math.round((loaded / total) * 100).toFixed(2) },
        file
      );
    },
    headers: headers,
  };

  return new Promise((resolve, reject) => {
    axios
      .post(path, formData, config)
      .then(({ data: response }) => {
        notification.success({
          message: `'${file.name}' enviado com sucesso`,
        });
        resolve(response);
        return onSuccess(response, file);
      })
      .catch((err) => {
        notification.error({
          message: `Erro no envio de '${file.name}'`,
          description: err.response
            ? err.response.data.message
            : "Erro desconhecido",
        });
        return onError(err);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const updateInvoiceData = (policyId, month) => (dispatch, getState) => {
  const { invoice } = getState().invoice;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${policyId}/invoice/${month}`, invoice)
      .then((response) => {
        message.success(i18n.t("invoice.updateInvoiceSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const removeInvoiceFile = (fileType, policyId, invoiceId) => (
  dispatch
) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`/policy/v1/${policyId}/invoice/${invoiceId}/upload/${fileType}`)
      .then((response) => {
        if (fileType !== "payment-slip") {
          dispatch(changeInvoiceData({ hasInvoice: false }));
          message.success(i18n.t("invoice.removeInvoiceFileSuccess"));
        } else {
          dispatch(changeInvoiceData({ hasPaymentSlip: false }));
          message.success(i18n.t("invoice.removePaymentSlipFileSuccess"));
        }
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const sendInvoiceByEmail = (policyId, month) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${policyId}/invoice/${month}/send-email`)
      .then((response) => {
        message.success(i18n.t("invoice.sendInvoiceEmailSuccess"));
        dispatch(
          changeInvoiceData({
            emailStatus: "SENT",
            emailSentAt: moment().format(),
          })
        );
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const markAsDelayedInvoice = (policyId, invoiceId) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${policyId}/invoice/${invoiceId}/delayed`)
      .then((response) => {
        message.success(i18n.t("invoice.markAsDelayedSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const markAsPaidInvoice = (policyId, invoiceId, data) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${policyId}/invoice/${invoiceId}/paid`, data)
      .then((response) => {
        message.success(i18n.t("invoice.markAsPaidSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error?.response?.data
            ? error?.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const sendManualEmail = (policyId, invoiceId) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${policyId}/invoice/${invoiceId}/manually-sent-email`)
      .then((response) => {
        message.success(i18n.t("invoice.sendInvoiceManualEmailSuccess"));
        dispatch(
          changeInvoiceData({
            emailStatus: "MANUAL_SENT",
            emailSentAt: moment().format(),
          })
        );
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const deleteInvoice = (policyId, invoiceId) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`/policy/v1/${policyId}/invoice/${invoiceId}`)
      .then((response) => {
        message.success(i18n.t("invoice.deletedSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
