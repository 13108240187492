import React from 'react'
import { Col, Row, Pagination } from 'antd'
import { useSelector, useDispatch } from 'react-redux'

export default ({ children, list, requestAction,requestParams }) => {
    const { pagination, isFetching } = useSelector(state => state.crm.customers)
    const dispatch = useDispatch()

    const handleChangePage = (event) => {
        dispatch(
            requestAction({ ...requestParams, pageNumber: event - 1 })
        )
    }
    return (
        <Col md={24} className="gx-mt-3 ">
            { children}
            {
                list.length && !isFetching
                    ? <Row type="flex" justify="end" className="gx-mt-3 customersPagination">
                        <Pagination current={pagination.pageNumber + 1} onChange={handleChangePage} pageSize={pagination.pageSize} total={pagination.total} />
                    </Row>
                    : null
            }
        </Col>
    )
}