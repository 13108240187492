//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("printReport");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    yes: getTranslation("yes", true),
    no: getTranslation("no", true),
}

