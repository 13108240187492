import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import Authorization from "containers/Authorization";
import Users from 'containers/Users';

const Admin = Authorization(['ADMIN', 'TEAM_LEAD']);

const Management = ({match}) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/users`}/>
        <Route path={`${match.url}/users`} component={Admin(Users)}/>
    </Switch>
);

export default Management;
