import React from 'react';
//PropTypes
import PropTypes from 'prop-types';
//Custom Components
import {InputNumber} from 'antd';
//Others
import {injectIntl, intlShape} from 'react-intl';
//Constants
const inputConfig = {
    "amount": {
        formatter: function (value) {
            return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        },
        parser: function (value) {
            return value.replace(/\$\s?|(,*)/g, '');
        }
    },
    "number": {
        formatter: null,
        parser: null,
    },
    "percent": {
        formatter: function (value) {
            if (!value)
                return null;
            else
                return `${value}%`;
        },
        parser: function (value) {
            return value.replace('%', '');
        }
    },
};

const NumberInput = (props) => {
    const {type, value, min, max, onChange, intl, placeholder, styles, fullWidth, precision, ...otherProps} = props;
    let currentPlaceholder = placeholder;

    if (typeof currentPlaceholder === "object")
        currentPlaceholder = intl.formatMessage(placeholder);


    const config = inputConfig[type];
    let {formatter, parser} = config;

    let minValue, maxValue, valueFormatter, valueParser, currentStyle = {};
    if (type === "percent") {
        minValue = min;
        maxValue = max;
        valueFormatter = formatter;
        valueParser = parser;
    }
    if (styles)
        currentStyle = Object.assign({}, styles);

    if (fullWidth)
        currentStyle.width = "100%";

    return (
        <InputNumber
            {...otherProps}
            style={currentStyle}
            value={value}
            placeholder={currentPlaceholder}
            min={minValue}
            max={maxValue}
            formatter={valueFormatter}
            parser={valueParser}
            precision={precision}
            type={type}
            // decimalSeparator={','}
            onChange={onChange}/>
    );
};

NumberInput.defaultProps = {
    type: "number",
    min: 0,
    max: 100,
    fullWidth: false,
    precision: 0
};
NumberInput.propTypes = {
    intl: intlShape.isRequired,
    type: PropTypes.oneOf(["percent", "amount", "number"]),
    placeholder: PropTypes.object,
    value: PropTypes.number,
    precision: PropTypes.number,
    fullWidth: PropTypes.bool,
    styles: PropTypes.object
};
export default injectIntl(NumberInput);