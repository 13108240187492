import React, { useState } from "react";
// router
import { useHistory } from "react-router-dom";
// antd
import { Row, Col, Button } from "antd";
// custom components
import { LngInput, MaskedInput } from "components";
// redux
import { useDispatch } from "react-redux";
import { requestCustomersList } from "appRedux/actionCreators";
// helpers
import { inputMasks, formatMaskInput } from "helpers";
// new translation
import { useTranslation } from "react-i18next";

export default () => {
  const [filters, setFilters] = useState({
    contactName: "",
    contactEmail: "",
    documentNumber: "",
    companyName: "",
  });

  const dispatch = useDispatch();
  const history = useHistory();

  const { t: translation } = useTranslation();

  const handleRegisterField = (fieldName) => (event) => {
    const value =
      fieldName === "documentNumber"
        ? formatMaskInput(event.target.value)
        : event.target.value;
    setFilters({ ...filters, [fieldName]: value });
  };

  const handleClearFilter = () => {
    setFilters({
      contactName: "",
      contactEmail: "",
      documentNumber: "",
      companyName: "",
    });
    return dispatch(requestCustomersList());
  };

  const onEnterPress = 
    event => {
      if (event.key === 'Enter') {
        dispatch(requestCustomersList(filters))
      }
    }
  

  const handleNewCustomer = () => {
    history.push("/crm/customers/new");
  };

  return (
    <Col md={24}>
      <div className="content-box">
        <Row>
          <Col md={12}>
            <Row type="flex" align="middle">
              <Col xs={24} md={10} className="label-end">
                {translation("customer.companyNameFilterLabel")}
              </Col>
              <Col xs={24} md={14}>
                <LngInput
                  placeholder={translation(
                    "customer.companyNameFilterPlaceholder"
                  )}
                  onChange={handleRegisterField("companyName")}
                  onKeyPress={onEnterPress}
                  value={filters.companyName}
                ></LngInput>
              </Col>
            </Row>
            <Row type="flex" align="middle" className="gx-mt-2">
              <Col xs={24} md={10} className="label-end">
                {translation("customer.contactNameFilterLabel")}
              </Col>
              <Col xs={24} md={14}>
                <LngInput
                  placeholder={translation(
                    "customer.contactNameFilterPlaceholder"
                  )}
                  onKeyPress={onEnterPress}
                  onChange={handleRegisterField("contactName")}
                  value={filters.contactName}
                ></LngInput>
              </Col>
            </Row>
          </Col>
          <Col md={12}>
            <Row type="flex" align="middle">
              <Col xs={24} md={10} className="label-end">
                {translation("customer.contactEmailFilterLabel")}
              </Col>
              <Col xs={24} md={14}>
                <LngInput
                  placeholder={translation(
                    "customer.contactEmailFilterPlaceholder"
                  )}
                  onChange={handleRegisterField("contactEmail")}
                  onKeyPress={onEnterPress}
                  value={filters.contactEmail}
                ></LngInput>
              </Col>
            </Row>
            <Row type="flex" align="middle" className="gx-mt-2">
              <Col xs={24} md={10} className="label-end">
                {translation("customer.contactDocumentFilterLabel")}
              </Col>
              <Col xs={24} md={14}>
                <MaskedInput
                  onChange={handleRegisterField("documentNumber")}
                  onKeyPress={onEnterPress}
                  mask={inputMasks.COMPANY}
                  value={filters.documentNumber}
                ></MaskedInput>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="button-wrapper">
          <Button
            type="primary"
            ghost
            className="gx-m-0"
            onClick={handleNewCustomer}
          >
            {translation("customer.newCustomerButton")}
          </Button>
          <Button
            type="primary"
            ghost
            className="gx-m-0 gx-ml-2"
            onClick={() => dispatch(requestCustomersList(filters))}
          >
            {translation("customer.triggerFilterButton")}
          </Button>
          <Button
            ghost
            className="gx-m-0 gx-ml-2 gx-btn-outline-danger"
            onClick={() => handleClearFilter()}
          >
            {translation("customer.clearFilterButton")}
          </Button>
        </div>
      </div>
    </Col>
  );
};
