import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation("customers");

const keys = [
  {
    key: "policyProductLabel",
    type: "label",
  },
  {
    key: "policyProductPlaceholder",
    type: "label",
  },
  {
    key: "policyInsurerLabel",
    type: "label",
  },
  {
    key: "policyInsurerPlaceholder",
    type: "label",
  },
  {
    key: "policyTypeLabel",
    type: "label",
  },
  {
    key: "policyTypePlaceholder",
    type: "label",
  },
  {
    key: "policyNumberLabel",
    type: "label",
  },
  {
    key: "policyNumberPlaceholder",
    type: "placeholder",
  },
  {
    key: "policyValidityLabel",
    type: "label",
  },
  {
    key: "policyExpirationLabel",
    type: "label",
  },
  {
    key: "policyNetPremiumLabel",
    type: "label",
  },
  {
    key: "policyNetMinimumPremiumLabel",
    type: "label",
  },
  {
    key: "policyNetPremiumPlaceholder",
    type: "placeholder",
  },
  {
    key: "policyIOFLabel",
    type: "label",
  },
  {
    key: "policyGrossPremiumLabel",
    type: "label",
  },
  {
    key: "policyGrossPremiumPlaceholder",
    type: "placeholder",
  },
  {
    key: "policyInstallmentsLabel",
    type: "label",
  },
  {
    key: "policyInstallmentsPlaceholder",
    type: "placeholder",
  },
  {
    key: "policySalesLabel",
    type: "label",
  },
  {
    key: "policyPreSalesLabel",
    type: "label",
  },
  {
    key: "policySalesPlaceholder",
    type: "label",
  },
  {
    key: "policyPreSalesPlaceholder",
    type: "label",
  },
  {
    key: "policyPartnerLabel",
    type: "label",
  },
  {
    key: "policyPartnerPlaceholder",
    type: "label",
  },
  {
    key: "policyComissionLabel",
    type: "label",
  },
  {
    key: "policyRateLabel",
    type: "label",
  },
  {
    key: "policyComissionPlaceholder",
    type: "placeholder",
  },
  {
    key: "policyUploadDocumentLabel",
    type: "label",
  },
  {
    key: "policyUploadButtonLabel",
    type: "label",
  },
  {
    key: "policyRemoveButtonLabel",
    type: "label",
  },
  {
    key: "policySendButtonLabel",
    type: "label",
  },
  {
    key: "suspendNewInvoicesLabel",
    type: "label",
  },
  {
    key: "cancelSuspendNewInvoicesLabel",
    type: "label",
  },
  {
    key: "policySaveButtonLabel",
    type: "label",
  },
  {
    key: "policyDownloadButtonLabel",
    type: "label",
  },
  {
    key: "minPremiumLabel",
    type: "label",
  },
  {
    key: "movimentLimitDayLabel",
    type: "label",
  },
  {
    key: "movimentLimitDayPlaceholder",
    type: "placeholder",
  },
  {
    key: "policyEmailStatusPending",
    type: "label",
  },
  {
    key: "policyEmailStatusSent",
    type: "label",
  },
  {
    key: "policySuretyAmountPlaceholder",
    type: "placeholder",
  },
  {
    key: "policySuretyAmountLabel",
    type: "label",
  },
  {
    key: "suretyPolicyLabel",
    type: "label",
  },
  {
    key: "maximumGuaranteeLimitLabel",
    type: "label",
  },
  {
    key: "maximumGuaranteeLimitPlaceholder",
    type: "placeholder",
  },
  {
    key: "policyDeleteButtonLabel",
    type: "label",
  },
  {
    key: "policyDeleteConfirmLabel",
    type: "label",
  },
  {
    key: "policyDeleteCancelButtonLabel",
    type: "label",
  },
  {
    key: "policyDeleteConfirmButtonLabel",
    type: "label",
  },
  {
    key: "contractNumber",
    type: "label",
  },
  {
    key: "obligeeDocumentNumber",
    type: "label",
  },
];

export default prepareTranslations(keys, translation);
