import React, {Component} from 'react';
//Styled Components
import AppModuleHeader from "components/AppModuleHeader";
class SearchUser extends Component {
    onChange = (event) => {
        const search = event.target.value;
        this.props.filterUsers(search)
    };

    render() {
        const {placeholder, search} = this.props;
        return (
            <AppModuleHeader placeholder={placeholder}
                             notification={false}
                             apps={false}
                             showExtra={false}
                             onChange={this.onChange}
                             value={search}/>
        );
    }
}

export default SearchUser;