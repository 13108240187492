import React from "react";
// prop types
import PropTypes from "prop-types";
// antd
import { Button, Col, Row, Switch } from "antd";
// custom components
import { LngInput, MaskedInput, FormField } from "components";
import { CustomStateSelect } from "./CustomStateSelect/index";
//helpers
import { inputMasks, formatMaskInput, getErrorMessage } from "helpers";
// new translation
import { useTranslation } from "react-i18next";
import GroupButtons from "../../../GroupButtons";

const RegisterDataForm = ({
  registerData,
  errors,
  onChange,
  onCancel,
  onToggleEmissionPermission,
  onSave,
  isNewCustomer = false,
  onSearch,
}) => {
  const { t: translation } = useTranslation();

  const insuredTypes = [
    {
      value: "COMPANY",
      label: translation("proposal.surety.insuredType.company"),
    },
    {
      value: "INDIVIDUAL",
      label: translation("proposal.surety.insuredType.individual"),
    },
  ];

  const handleDocumentNumberChange = (event) => {
    onChange("documentNumber")(formatMaskInput(event.target.value));
  };

  const handleZipCodeChange = (event) => {
    onChange("zipCode")(formatMaskInput(event.target.value));
  };

  const handleToggleEmissionPermission = (e) =>
    onToggleEmissionPermission && onToggleEmissionPermission(e);

  return (
    <>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          style={{ textAlign: "right" }}
          className="gx-d-flex"
        >
          {translation("proposal.surety.insuredType")}
        </Col>
        <Col xs={24} md={12}>
          <GroupButtons
            className="gx-w-100"
            value={registerData?.personType}
            onChange={onChange("personType")}
            entries={insuredTypes}
          />
        </Col>
      </Row>
      {registerData?.personType === "INDIVIDUAL" ? (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={4} className="gx-d-flex">
            {translation("customer.individual.registerDocumentNumberLabel")}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("documentNumber", errors)}>
              <MaskedInput
                disabled={false}
                mask={inputMasks["INDIVIDUAL"]}
                onChange={handleDocumentNumberChange}
                value={registerData.documentNumber ?? ""}
              />
            </FormField>
          </Col>
        </Row>
      ) : (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={4} className="gx-d-flex">
            {translation("customer.registerDocumentNumberLabel")}
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("documentNumber", errors)}>
              <MaskedInput
                disabled={false}
                mask={inputMasks["COMPANY"]}
                onChange={handleDocumentNumberChange}
                value={registerData.documentNumber ?? ""}
              />
            </FormField>
          </Col>
          <Col md={8}>
            <Button
              className="gx-m-0"
              type="primary"
              disabled={!registerData.documentNumber}
              onClick={onSearch}
            >
              {translation("customer.getCustomersDataButton")}
            </Button>
          </Col>
        </Row>
      )}
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col
          xs={24}
          md={4}
          style={{ textAlign: "right" }}
          className="gx-d-flex"
        >
          {registerData?.personType === "INDIVIDUAL"
            ? translation("customer.individual.registerCompanyLabel")
            : translation("customer.registerCompanyLabel")}
        </Col>
        <Col xs={24} md={20}>
          <FormField error={getErrorMessage("companyName", errors)}>
            <LngInput
              value={registerData.companyName}
              onChange={onChange("companyName")}
              placeholder={
                registerData?.personType === "INDIVIDUAL"
                  ? translation(
                      "customer.individual.registerCompanyPlaceholder"
                    )
                  : translation("customer.registerCompanyPlaceholder")
              }
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={4} className="gx-d-flex">
          {translation("customer.registerFullAddressLabel")}
        </Col>
        <Col xs={24} md={20}>
          <FormField error={getErrorMessage("address", errors)}>
            <LngInput
              value={registerData.address}
              onChange={onChange("address")}
              placeholder={translation(
                "customer.registerFullAddressPlaceholder"
              )}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={4} className="gx-d-flex">
          {translation("customer.registerAddressNumberLabel")}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("number", errors)}>
            <LngInput
              value={registerData.number}
              onChange={onChange("number")}
              placeholder={translation(
                "customer.registerAddressNumberPlaceholder"
              )}
            />
          </FormField>
        </Col>
        <Col xs={24} md={4} className="gx-pl-4 gx-d-flex">
          {translation("customer.registerAddressAddDataLabel")}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("additionalData", errors)}>
            <LngInput
              placeholder={translation(
                "customer.registerAddressAddDataPlaceholder"
              )}
              value={registerData.additionalData}
              onChange={onChange("additionalData")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={4} className="gx-d-flex">
          {translation("customer.registerZipCodeLabel")}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("zipCode", errors)}>
            <MaskedInput
              mask="99.999-999"
              value={registerData.zipCode || ""}
              onChange={handleZipCodeChange}
            />
          </FormField>
        </Col>
        <Col xs={24} md={4} className="gx-pl-4 gx-d-flex">
          {translation("customer.registerNeighborhoodLabel")}
        </Col>
        <Col xs={24} md={8}>
          <FormField error={getErrorMessage("neighborhood", errors)}>
            <LngInput
              value={registerData.neighborhood}
              onChange={onChange("neighborhood")}
              placeholder={translation(
                "customer.registerNeighborhoodPlaceholder"
              )}
            />
          </FormField>
        </Col>
      </Row>
      <CustomStateSelect
        stateValue={registerData.state}
        onChangeState={onChange("state")}
        cityValue={registerData.city}
        onChangeCity={onChange("city")}
        errors={[]}
      />
      {!isNewCustomer ? (
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={4} className="gx-d-flex">
            <Switch
              name="emissionPermission"
              checked={registerData?.emissionPermission}
              onChange={handleToggleEmissionPermission}
            />
          </Col>
          <Col xs={24} md={8}>
            <FormField error={getErrorMessage("emissionPermission", errors)}>
              {translation("customer.emissionPermissionLabel")}
            </FormField>
          </Col>
        </Row>
      ) : null}
      <Row
        type="flex"
        align="middle"
        gutter={8}
        className="gx-mt-3 gx-mr-4 gx-justify-content-end"
      >
        <Button onClick={onCancel}>
          {translation("customer.registerCancelButton")}
        </Button>
        <Button
          // disabled={isChanged ? false : true}
          type="primary"
          onClick={onSave}
        >
          {translation("customer.registerSaveButton")}
        </Button>
      </Row>
    </>
  );
};

RegisterDataForm.propTypes = {
  registerData: PropTypes.object,
  errors: PropTypes.array,
  onChange: PropTypes.func,
};

export default RegisterDataForm;
