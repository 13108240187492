import React, { Component } from "react";
//Custom Styles
import "./Styles.less";
//Custom Components
import {
  Row,
  Col,
  Modal,
  Form,
  Avatar,
  Input,
  Popover,
  Select,
  Button,
  Radio,
  Checkbox,
} from "antd";
import { MultipleSelect, MaskedInput } from "components";
//Translation config
import Translation from "./translation";
//Custom Helpers
import { prepareUserEdit, isUpdateValid, getIcon } from "../helpers";
//Custom Images
import placeholderImage from "assets/images/placeholder.jpg";
//Others
import selections from "constants/selections";
const { Option } = Select;
const fullWidth = { width: "100%" };
const formStyle = { xs: { span: 24 } };
const modalBodyStyle = { padding: 0 };

class EditUser extends Component {
  componentDidMount() {
    this.setInitialState();
  }

  componentWillUnmount() {
    this.resetForm();
  }

  resetForm = () => {
    return this.props.form.resetFields();
  };

  setInitialState() {
    if (this.props.user.whatsapp && this.props.user.whatsapp.length > 18) {
      this.props.form.setFieldsValue({
        phoneType: "MOBILE_PHONE_NUMBER",
      });
    } else {
      this.props.form.setFieldsValue({
        phoneType: "PHONE_NUMBER",
      });
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const {
      form,
      user: { id },
      closeUpdateMode,
      updateUser,
    } = this.props;
    form.validateFieldsAndScroll((err, values) => {
      if (!err) updateUser(id, prepareUserEdit(values), closeUpdateMode);
    });
  };

  validateWhatsAppNumber = (rule, value, callback) => {
    if (
      (this.props.form.getFieldValue("phoneType") === "PHONE_NUMBER" &&
        value &&
        value.length !== 18) ||
      value.charAt(17) === "_"
    ) {
      return callback(Translation.whatsAppTypeValidation);
    } else if (
      (this.props.form.getFieldValue("phoneType") === "MOBILE_PHONE_NUMBER" &&
        value &&
        value.length !== 19) ||
      value.charAt(18) === "_"
    ) {
      return callback(Translation.whatsAppTypeValidation);
    } else return callback();
  };

  render() {
    const {
      form: { getFieldDecorator, getFieldsValue },
      closeUpdateMode,
      open,
      user,
      allProfiles,
      loadingAllProfiles,
    } = this.props;
    const {
      id,
      name,
      email,
      position,
      whatsapp,
      photo,
      profile,
      products,
      extensionNumber,
      hasHubspotUser,
      salesProfile,
      preSalesProfile,
    } = user;
    let thumb = photo;
    if (!thumb) thumb = placeholderImage;
    const formValues = getFieldsValue();

    const canUpdateUser = isUpdateValid(user, formValues);

    return (
      <Modal
        key={id}
        title={Translation.title}
        visible={open}
        closable={false}
        centered={true}
        footer={null}
        bodyStyle={modalBodyStyle}
        onCancel={closeUpdateMode}
      >
        <main className="editUser">
          <Form wrapperCol={formStyle} onSubmit={this.handleSubmit}>
            <section className="editUser-formSection">
              <section className="editUser-avatarSection">
                <input
                  accept="image/*"
                  className="editUser-avatarSection-input"
                  id="icon-button-file"
                  type="file"
                />
                <label htmlFor="icon-button-file">
                  <Avatar size={64} src={thumb} />
                  <small className="editUser-avatarSection-label">
                    {Translation.uploadImageTitle}
                  </small>
                </label>
              </section>
              <Form.Item
                label={Translation.nameEntity}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("name", {
                  initialValue: name,
                  rules: [
                    {
                      required: true,
                      message: Translation.nameEntityValidation,
                    },
                  ],
                })(<Input {...getIcon("user")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.positionEntity}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("position", {
                  initialValue: position,
                  rules: [
                    {
                      required: true,
                      message: Translation.positionEntityValidation,
                    },
                  ],
                })(<Input {...getIcon("form")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.profileEntity}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("key", {
                  initialValue: profile.key,
                  rules: [
                    {
                      required: true,
                      message: Translation.profileKeyEntityValidation,
                    },
                  ],
                })(
                  <Select
                    loading={loadingAllProfiles}
                    placeholder={Translation.profileKeyEntityPlaceholder}
                  >
                    {!loadingAllProfiles &&
                      allProfiles.map(({ key, description }) => (
                        <Option value={key} key={key}>
                          {description}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
              <Form.Item
                label={Translation.emailEntity}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("email", {
                  initialValue: email,
                  rules: [
                    {
                      type: "email",
                      message: Translation.emailEntityTypeValidation,
                    },
                    {
                      required: true,
                      message: Translation.emailEntityReqValidation,
                    },
                  ],
                })(<Input {...getIcon("form")} style={fullWidth} />)}
              </Form.Item>
              <Form.Item
                label={Translation.phoneTypeLabel}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator(
                  "phoneType",
                  {}
                )(
                  <Radio.Group name="phoneType">
                    <Radio value="PHONE_NUMBER">
                      {Translation.phoneTypePhoneLabel}
                    </Radio>
                    <Radio value="MOBILE_PHONE_NUMBER">
                      {Translation.phoneTypeMobileLabel}
                    </Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item
                label={Translation.whatsAppEntity}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("whatsapp", {
                  initialValue: whatsapp,
                  rules: [
                    {
                      required: true,
                      message: Translation.whatsAppEntityReqValidation,
                    },
                    { validator: this.validateWhatsAppNumber },
                  ],
                })(
                  <MaskedInput
                    mask={
                      this.props.form.getFieldValue("phoneType") ===
                      "MOBILE_PHONE_NUMBER"
                        ? "+99 (99) 99999-9999"
                        : "+99 (99) 9999-9999"
                    }
                  />
                )}
              </Form.Item>

              <Form.Item
                label={Translation.extensionNumberLabel}
                hasFeedback
                className="editUser-formItem"
              >
                {getFieldDecorator("extensionNumber", {
                  initialValue: extensionNumber,
                  rules: [
                    {
                      required: true,
                      message: Translation.extensionNumberValidation,
                    },
                  ],
                })(<Input {...getIcon("phone")} style={fullWidth} />)}
              </Form.Item>

              <Form.Item
                label={Translation.orderProductsLabel}
                className="addUser-formItem"
              >
                {getFieldDecorator("products", {
                  initialValue: products,
                  rules: [
                    {
                      required: true,
                      message: Translation.orderProductsValidation,
                    },
                  ],
                })(
                  <MultipleSelect
                    className="gx-w-100"
                    options={selections.orderProducts}
                    placeholder={Translation.orderProductsPlaceholder}
                  />
                )}
              </Form.Item>
              <Form.Item
                label={Translation.hasHubspotLabel}
                className="addUser-formItem gx-mt-3"
              >
                {getFieldDecorator("hasHubspotUser", {
                  initialValue: hasHubspotUser || false,
                })(
                  <Radio.Group>
                    <Radio value={true}>{Translation.hasHubspotYesLabel}</Radio>
                    <Radio value={false}>{Translation.hasHubspotNoLabel}</Radio>
                  </Radio.Group>
                )}
              </Form.Item>
              <Form.Item label="" className="addUser-formItem gx-mt-3">
                {getFieldDecorator("preSalesProfile", {
                  initialValue: preSalesProfile || false,
                })(
                  <Checkbox defaultChecked={preSalesProfile || false}>
                    Usuário é um pré-vendedor?
                  </Checkbox>
                )}
              </Form.Item>
              <Form.Item label="" className="addUser-formItem gx-mt-3">
                {getFieldDecorator("salesProfile", {
                  initialValue: salesProfile || false,
                })(
                  <Checkbox defaultChecked={salesProfile || false}>
                    O usuário é um vendedor?
                  </Checkbox>
                )}
              </Form.Item>
            </section>
            <Row
              type="flex"
              justify="end"
              gutter={8}
              className="editUser-actionSection"
            >
              <Col>
                <Button onClick={closeUpdateMode}>{Translation.cancel}</Button>
              </Col>
              <Col>
                <Form.Item className="editUser-formItem">
                  {canUpdateUser ? (
                    <Button type="primary" htmlType="submit">
                      {Translation.ok}
                    </Button>
                  ) : (
                    <Popover
                      placement="bottom"
                      content={
                        <span className="editUser-disabled">
                          {Translation.noChangesLabel}
                        </span>
                      }
                    >
                      <Button type="primary" htmlType="submit" disabled>
                        {Translation.ok}
                      </Button>
                    </Popover>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </main>
      </Modal>
    );
  }
}

const WrappedEditUserForm = Form.create({
  name: "edit_user",
})(EditUser);

export default WrappedEditUserForm;
