import React, { Component } from "react";
import { Tabs } from "antd";
import { Quotes } from "components";

import TermTab from "./tabs/Term";
import TermDetailTab from "./tabs/TermDetails";
import PaymentTab from "./tabs/Payment";
import OrderTaxes from "./tabs/Taxes";
import Minute from "./tabs/Minute/";
import Policy from "./tabs/Policy";
import CrossSell from "./tabs/CrossSell";
import { InitialTab, InsuredTab, UploadTab } from "../CommonTabs";
import Translation from "./translation";
import WhatsAppSender from "../../../WhatsAppTemplateComponents/WhatsAppSender";
import WhatsAppHistory from "../../../WhatsAppTemplateComponents/WhatsAppHistory";

// new redux store
import store from "../../../../store";
import { Provider } from "react-redux";
// proposal
import Proposal from "../../../../containers/Orders/Orders/Surety/Proposal";

// constants
import { courtTypes } from "../../../../constants";
import MuTabs from "../../../MuTabs";

const TabPane = Tabs.TabPane;

class SuretyTabs extends Component {
  resetTabs = () => {};

  notifyChanges = () => {
    const { modified, setModified } = this.props;
    if (!modified) setModified(true);
  };

  getChanges = () => {
    const changes = {};
    return changes;
  };

  render() {
    const {
      data,
      configurations,
      extraContent,
      handleCompanySearch,
      handleChange,
      errors,
      tabValue,
      superiorTabValue,
    } = this.props;

    const { documentNumber } = configurations.initial;
    const { daysBetweenTerm, suretyAmount } = configurations.term;
    const { proposalAccepted, receiveDraftContract } = configurations.detail;
    const { policy } = configurations.policy;
    const { paymentSlips } = configurations.paymentSlips;
    const { minute } = configurations.minute;

    const isCourtType = configurations.bond
      ? courtTypes.includes(configurations.bond.type)
      : false;

    return (
      <MuTabs
        product="SURETY"
        tabPosition="top"
        type="card"
        onChange={this.props.changeSuperiorTab}
        activeKey={superiorTabValue}
      >
        <TabPane tab={Translation.applicationDataTabPaneLabel} key="1">
          <Tabs
            tabPosition="left"
            activeKey={tabValue}
            onChange={this.props.actualTab}
          >
            <TabPane tab={Translation.initialTabLabel} key={"initial"}>
              <InitialTab
                type="single"
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("initial")}
                configurations={configurations.initial}
                searchCompany={handleCompanySearch("initial")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.termTabLabel} key={"term"}>
              <TermTab
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("term", "SURETY")}
                termType={configurations.bond && configurations.bond.type}
                configurations={configurations.term}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.termDetailTabLabel} key={"detail"}>
              <TermDetailTab
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("detail")}
                termType={configurations.bond && configurations.bond.type}
                configurations={configurations.detail}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.quotesTabLabel} key={"quotations"}>
              <Quotes
                proposalAccepted={proposalAccepted}
                receiveDraftContract={receiveDraftContract}
                simulations={configurations.simulations}
                quotations={configurations.quotations}
                chosenInsurer={minute && minute.insurer}
                handleChange={handleChange("quotations")}
                code={data.code}
                selectedTab={tabValue}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane
              tab={
                isCourtType
                  ? Translation.judicialTypeTabLabel
                  : Translation.insuredTypeTabLabel
              }
              key={"obligee"}
            >
              <InsuredTab
                notifyChanges={this.notifyChanges}
                handleChange={handleChange("obligee")}
                configurations={configurations.obligee}
                bond={configurations.bond}
                searchCompany={handleCompanySearch("obligee")}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.paymentsTabLabel} key={"payment"}>
              <PaymentTab
                handleChange={handleChange("payments")}
                code={data.code}
                payments={configurations.payments}
                minute={minute}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.uploadTabLabel} key="files">
              <UploadTab
                configurations={configurations.files}
                type={data.type}
                code={data.code}
                fileExtensions={
                  "pdf, jpg, png, bmp, tiff, xls, xlsx, doc, docx"
                }
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.crossSellTabLabel} key="crosssell">
              <CrossSell
                configurations={configurations.term}
                code={data.code}
                OrderErrors={errors}
              />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab={Translation.taxesTabLabel} key="2">
          <OrderTaxes
            daysBetweenTerm={daysBetweenTerm}
            suretyAmount={suretyAmount}
            documentNumber={documentNumber}
            OrderErrors={errors}
            quotations={configurations.quotations}
          />
        </TabPane>
        <TabPane tab={Translation.issueDataTabLabel} key="4">
          <Tabs tabPosition="left">
            <TabPane tab={Translation.minutesTabLabel} key={"minute"}>
              <Minute
                configurations={configurations.minute}
                hasExpressEmission={configurations.hasExpressEmission}
                type={data.type}
                quotations={configurations.quotations}
                fileExtensions={"pdf, jpg, png, bmp, tiff, pdf, doc, docx"}
                code={data.code}
                superiorSelectedTab={superiorTabValue}
                OrderErrors={errors}
              />
            </TabPane>
            <TabPane tab={Translation.policyTabLabel} key={"policy"}>
              <Provider store={store}>
                <Policy
                  policyData={policy}
                  hasExpressEmission={configurations.hasExpressEmission}
                  paymentSlipsData={paymentSlips}
                  minute={minute}
                  code={data.code}
                  documentNumber={documentNumber}
                  superiorSelectedTab={superiorTabValue}
                  type={data.type}
                  OrderErrors={errors}
                />
              </Provider>
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab={Translation.whatsAppLabel} key="5">
          <Tabs
            tabPosition="left"
            activeKey={tabValue}
            onChange={this.props.actualTab}
          >
            <TabPane tab={"chat"} key={"initial"}>
              <WhatsAppSender
                code={data.code}
                phoneNumber={configurations.initial.phoneNumber}
                activeKey={tabValue}
              />
            </TabPane>
            <TabPane tab={"Histórico de mensagens"} key={"history"}>
              <WhatsAppHistory code={data.code} activeKey={tabValue} />
            </TabPane>
          </Tabs>
        </TabPane>
        <TabPane tab="Proposta" key="6">
          <Provider store={store}>
            <Proposal code={data.code} />
          </Provider>
        </TabPane>
      </MuTabs>
    );
  }
}

export default SuretyTabs;
