import React from 'react';
//External Libs
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
//Custom Components
import LngInput from "../LngComponents/LngInput";
//Custom Styles
import './style.less';
//Constants
const autoCompletionRequest = {
  types: ['(regions)'],
};

const MuPlaces = (props) => {
  const { id, initialValue, placeholder, ...config } = props;
  const idPrefix = id ? id.split('-google-places-autocomplete-input')[0] : '';
  const finalValue = initialValue;

  return (
    <>
      <GooglePlacesAutocomplete
        {...config}
        idPrefix={idPrefix}
        initialValue={initialValue}
        autocompletionRequest={autoCompletionRequest}
        renderInput={(props) => {
          const { value, ...rest } = props;
          return (
            <LngInput
              {...rest}
              value={value}
              autoComplete="off"
              defaultValue={finalValue}
              placeholder={placeholder}
            />
          )
        }}
      />
    </>
  )
};

export default MuPlaces;