import React from "react";

const InsurerLogo = (props) => {
  const { insurer } = props;
  try {
    return (
      <div className="InsurerLogo">
        <img alt="" src={require(`../../assets/insurers/${insurer}.svg`)} />
      </div>
    );
  } catch (e) {
    return insurer;
  }
};

export default InsurerLogo;
