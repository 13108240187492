import React from 'react';
//Translation
import Translation from "./translation";
//Components
import { MultipleSelect } from 'components'
import { Row, Col } from "antd";
//Constants
import selections from "constants/selections";
//Helpers
import { formatSelectValue } from "../../../../../../helpers";

const Coverages = (props) => {
  const { configurations, handleChange, OrderErrors } = props;

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;

    if (!isDirect && typeof event === "object") {
      if (event instanceof Array)
        value = event;
      else
        value = event.target.value;
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.coveragesLabel}:
        </Col>
        <Col xs={24} md={18}>
          <MultipleSelect
            value={formatSelectValue(configurations.coverages)}
            className="gx-w-100"
            placeholder={Translation.coveragesPlaceholder}
            options={selections.coverages}
            onChange={onChange("coverages")}
            OrderErrors={OrderErrors}
            validationParam="coverages.coverages"
            >
          </MultipleSelect>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.assistancesLabel}:
        </Col>
        <Col xs={24} md={18}>
          <MultipleSelect
            value={formatSelectValue(configurations.assistances)}
            className="gx-w-100"
            placeholder={Translation.assistancesPlaceholder}
            options={selections.assistances}
            onChange={onChange("assistances")}
            OrderErrors={OrderErrors}
            validationParam="coverages.assistances">
          </MultipleSelect>
        </Col>
      </Row>
    </section>
  );
};

export default Coverages;