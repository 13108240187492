import React, {Component} from "react";
//Other Libs
import produce from "immer";
import moment from "moment";
//Translation
import Translation from './translation';
//Custom Css
import './Styles.less'
//Components
import {Tabs, Button, Card, Row, Col, DatePicker, Tooltip} from "antd";
import {LngInput, CurrencyInput, EmptyWrapper} from "components";
//Custom Helpers
import {frontEndDate} from "helpers";
//Constants
const {TabPane} = Tabs;

const HeaderContent = (props) => {
  const {isEmpty, onCreate, onRemove} = props;
  return (
    <section style={isEmpty ? {float: "right"} : {}}>
      {!isEmpty && <Tooltip placement="bottom" title={Translation.lossRemove}>
        <Button onClick={onRemove} icon="minus" className="gx-btn-outline-danger" htmlType="button">
          {Translation.remove}
        </Button>
      </Tooltip>}
      <Tooltip placement="bottom" title={Translation.lossAdd}>
        <Button icon="plus" onClick={onCreate} className="gx-btn-outline-primary" htmlType="button">
          {Translation.add}
        </Button>
      </Tooltip>
    </section>
  )
};

const LossContent = (props) => {
  const {index, onChange, data} = props;
  const {accidentDate, eventType, merchandiseType, lostValue} = data;
  return (
    <section key={index}>
      <Row type="flex" align="middle" gutter={8}>
        <Col xs={24} md={8}>{Translation.dateLabel} :</Col>
        <Col xs={24} md={16}>
          <DatePicker
            format={frontEndDate}
            disabledDate={function (current) {
              return current && current.valueOf() > Date.now();
            }}
            onChange={onChange("accidentDate", index, true)}
            value={accidentDate}/>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={8}>{Translation.eventTypeLabel}:</Col>
        <Col xs={24} md={16}>
          <LngInput
            value={eventType}
            placeholder={Translation.eventTypePlaceholder}
            onChange={onChange("eventType", index)}/>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={8}>{Translation.goodLabel}:</Col>
        <Col xs={24} md={16}>
          <LngInput
            value={merchandiseType}
            placeholder={Translation.goodPlaceholder}
            onChange={onChange("merchandiseType", index)}/>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={8}>{Translation.amountLabel}:</Col>
        <Col xs={24} md={16}>
          <CurrencyInput
            value={lostValue}
            placeholder={Translation.amountPlaceholder}
            onChange={onChange("lostValue", index, true)}/>
        </Col>
      </Row>
    </section>
  )
};


const LossesList = (props) => {
  const {header, data, onTabChange, onLossChange, activeTabKey} = props;
  return (
    <Tabs
      hideAdd
      type="card"
      onChange={onTabChange}
      activeKey={activeTabKey}
      tabBarExtraContent={header}>
      {data.map((entry, index) => {
        return (
          <TabPane tab={<p>{Translation.lossLabel} #{index + 1} </p>} key={`loss-${index}`}>
            <LossContent
              index={index}
              data={entry}
              onChange={onLossChange}/>
          </TabPane>
        )
      })}
    </Tabs>
  )
};

class Losses extends Component {

  constructor(props) {
    super(props);
    this.state = Losses.emptyState(props.data);
  }

  static emptyState(data) {
    const draft = data.map(Losses.formatLoss);
    return {
      activeTabKey: `loss-0`,
      data: draft,
    }
  }

  static formatLoss(loss) {
    return {
      accidentDate: moment(loss.accidentDate) || null,
      eventType: loss.eventType || null,
      merchandiseType: loss.merchandiseType || null,
      lostValue: loss.lostValue || null
    }
  }

  static emptyLoss() {
    return {
      accidentDate: null,
      eventType: null,
      merchandiseType: null,
      lostValue: null
    }
  }

  reset = () => {
    const {data} = this.props;
    this.setState(Losses.emptyState(data));
  };

  getChanges = () => {
    const {data} = this.state;

    function formatAmount(value) {
      if (value !== null && typeof value === "object")
        return value.floatValue;
      return value;
    }

    function formatDate(accidentDate) {
      if (accidentDate)
        return moment(accidentDate).format('YYYY-MM-DD');
      return null;
    }

    return data.map(entry => {
      return {
        accidentDate: formatDate(entry.accidentDate),
        eventType: entry.eventType,
        merchandiseType: entry.merchandiseType,
        lostValue: formatAmount(entry.lostValue)
      }
    });
  };

  createLoss = () => {
    this.setState(prevState => {
      const updatedData = [...prevState.data, Losses.emptyLoss()];
      return {
        data: updatedData,
        activeTabKey: `loss-${updatedData.length - 1}`,
      }
    }, this.notifyChanges);
  };

  onTabChange = (tabKey) => {
    this.setState({
      activeTabKey: tabKey
    });
  };

  handleLossRemove = () => {
    this.setState(prevState => {
      let removedIndex = parseInt(prevState.activeTabKey.split('-')[1]);
      const updatedData = prevState.data.filter((loss, index) => index !== removedIndex);
      if (removedIndex === 0) removedIndex = 1;
      const activeTabKey = `loss-${removedIndex - 1}`;
      return {
        data: updatedData,
        activeTabKey: activeTabKey
      }
    }, this.notifyChanges)
  };

  notifyChanges = () => {
    if (this.props.notifyChanges)
      return this.props.notifyChanges();
  };

  handleLossChange = (attr, index, isValue = false) => (event) => {
    let value;
    value = isValue ? event : event.target.value;

    this.setState(produce(draft => {
      draft.data[index][attr] = value;
    }), this.notifyChanges);
  };

  render() {
    const {data, activeTabKey} = this.state;
    const showEmptyLosses = data.length === 0;

    const header = (
      <HeaderContent
        isEmpty={showEmptyLosses}
        onRemove={this.handleLossRemove}
        onCreate={this.createLoss}/>
    );
    return (
      <section>
        {showEmptyLosses && <Card size="small">
          <EmptyWrapper
            header={header}
            height={200}
            description={Translation.lossEmptyLabel}
          />
        </Card>}
        {!showEmptyLosses && <LossesList
          header={header}
          data={data}
          onTabChange={this.onTabChange}
          onLossChange={this.handleLossChange}
          activeTabKey={activeTabKey}/>}
      </section>
    );
  }
}

export default Losses;