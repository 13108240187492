import React from 'react';
//External Libs
import PropTypes from "prop-types";
//Utils
import Request from "helpers/Request";
//Components
import {Row, Col, Divider, Icon, Skeleton, Card, Tag, Button, Radio, Modal} from "antd";
//Custom Components
import CurrencyInput from "../../../../../CurrencyInput";
//Custom Helpers
import {getFormattedDate} from "../../../../../../helpers";
//Translations
import Translation from './translation';
//Styles
import './Styles.less'

import selections from "constants/selections";
//Config
const config = {
    insurers: selections.taxesInsurer,
    statuses: selections.taxesStatus,
    validReprocessStatus: [
        "ERROR",
        "DESIGNATION_LETTER_NEEDED",
        "DOCUMENTATION_NEEDED",
        "EXPIRED"
    ],
    validBondTable: [
        "RESERVED",
        "EXPIRED"
    ]
};

const BondTable = (props) => {
    const [selectedLimit, setSelectedLimit] = React.useState(null);
    const {allowSelection, data, suretyAmount, daysBetweenTerm} = props;

    if (!data)
        return null;

    const handleLimitSelect = (index) => {

        const allowComputation = Boolean(suretyAmount) && Boolean(daysBetweenTerm);

        if (!allowComputation) {
            Modal.warning({
                centered: true,
                title: Translation.modalTitle,
                content: Translation.modalContent,
            });
        } else {
            setSelectedLimit(index);
        }
    };

    const tableContent = data.map((entry, index) => {
        const {modality, totalLimit, availableLimit, rate, isPublic} = entry;

        const onLimitSelect = () => {
            return handleLimitSelect(index);
        };

        const toModality = (modality, isPublic) => {
            var response = "";
            switch (modality) {
                case "CONSTRUCTION_BOND":
                    response =  "Construção";
                    break;
                case "BUSINESS_SERVICES_BOND":
                    response =  "Prestação de serviços";
                    break;
                case "SUPPLY_BOND":
                    response = "Fornecimento de bens e/ou materiais";
                    break;
                case "ADVANCED_PAYMENT_BOND":
                    response = "Adiantamento de pagamento";
                    break;
                case "BIDDING_BOND":
                    response = "Licitante";
                    break;
                case "TAX_CREDITS_BOND":
                    response = "Parcelamento Administrativo Fiscal";
                    break;
                case "DEALER_PERFORMER_BOND":
                    response = "Performance Bond";
                    break;
                case "WORK_COMPLETION_BOND":
                case "WORK_COMPLETATION_BOND":
                    response = "Construção";
                    break;
                case "CUSTOMS_BOND":
                    response = "Aduaneiro";
                    break;
                case "FINANCIAL_BOND":
                    response = "Financeira";
                    break;
                case "CORRECTIVE_MAINTENANCE_BOND":
                    response = "Manutenção corretiva";
                    break;
                case "PAYMENT_RETENTION_BOND":
                    response = "Retenção de pagamento";
                    break;
                case "REAL_ESTATE_BOND":
                case "REAL_STATE_BOND":
                    response = "Imobiliária";
                    break;
                case "ENERGY_SECTOR_BOND":
                    response = "Setor de energia - ANEEL";
                    break;
                case "NAVY_SECTOR_BOND":
                    response = "Setor naval";
                    break;
                case "OIL_GAS_SECTOR_BOND":
                    response = "Setor de óleo e gás natural";
                    break;
                case "ENVIRONMENTAL_BOND":
                    response = "TAC - Termo de Acerto de Conduta";
                    break;
                case "LABOUR_APPEAL_FILLING_BOND":
                    response = "Judicial Depósito recursal";
                    break;
                case "CIVIL_COURT_BOND":
                    response = "Judicial Cível";
                    break;
                case "TAX_ENFORCEMENT_BOND":
                    response = "Judicial Fiscal";
                    break;
                case "TAX_ADMINISTRATIVE_INSTALLMENT_BOND":
                    response = "Parcelamento Administrativo Fiscal";
                    break;
                case "COURT_OTHERS_BOND":
                    response = "Judicial Outros";
                    break;
                case "LABOUR_SURETY":
                    response = "Trabalhista";
                    break;
                default:
                    response = modality;
                    break;
            }

            response += " - " + (isPublic ? "Público" : "Privado");
            return response;
        }
    
        return (
            <tr onClick={onLimitSelect} key={Math.random()}>
                {allowSelection && <td>
                    <Radio checked={selectedLimit === index}/>
                </td>}
                <td>{toModality(modality, isPublic)}</td>
                <td className="gx-text-center">
                    {rate}
                </td>
                <td className="gx-text-center">
                    <CurrencyInput value={totalLimit} displayType="text"/>
                </td>
                <td className="gx-text-center">
                    <CurrencyInput value={availableLimit} displayType="text"/>
                </td>
            </tr>
        )
    });

    let premiumValue = null;
    let displayWarning = false;

    if (selectedLimit !== null) {
        const limitDetails = data[selectedLimit];
        premiumValue = suretyAmount * daysBetweenTerm * limitDetails.creditRate / 365;
        displayWarning = limitDetails.creditLimit < premiumValue
    }


    return (
        <Row type="flex" gutter={8} align="middle" justify="space-between">
            <Col xs={24} md={24} lg={premiumValue ? 14 : 24}>
                <section className="gx-taxes-table">
                    <table className="gx-w-100">
                        <thead>
                        <tr>
                            {allowSelection && <th/>}
                            <th>
                                {Translation.bondTypeLabel}
                            </th>
                            <th className="gx-text-center">
                                {Translation.taxRateLabel}
                            </th>
                            <th className="gx-text-center">
                                {Translation.totalLimitLabel}
                            </th>
                            <th className="gx-text-center">
                                {Translation.availableLimitLabel}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableContent}
                        </tbody>
                    </table>
                </section>
            </Col>
            {premiumValue && <Col xs={24} md={24} lg={10}>
                <Card className="gx-taxes-premium-content">
                    <Row type="flex" align="middle" className="gx-flex-column">
                        <Col>
                            <Row type="flex" gutter={8} align="bottom">
                                <Col>
                                    {Translation.premiumLabel}:
                                </Col>
                                {displayWarning && <Col>
                                    <Icon type="warning" className="gx-taxes-warning-icon"/>
                                </Col>}
                                <Col>
                                    <CurrencyInput value={premiumValue} displayType="text"/>
                                </Col>
                            </Row>
                        </Col>
                        {displayWarning && <Col className="gx-mt-2">
                            {Translation.warningMessageLabel}
                        </Col>}
                    </Row>
                </Card>
            </Col>}
        </Row>
    )
};

const ReserveCard = (props) => {
    const {
        loading, 
        suretyAmount, 
        daysBetweenTerm, 
        disabled, 
        handleProcess, 
        data
    } = props;

    const [collapsed, setCollapsed] = React.useState(false);

    const toggleCollapse = () => {
        setCollapsed(prevState => {
            return !prevState;
        })
    };

    const {
        insurer, status, expiryOn, reservedAt, processedAt,
        insurerStatus, limits
    } = data;

    const insurerConfig = config.insurers[insurer];
    const statusConfig = config.statuses[status];
    // const allowReprocess = config.validReprocessStatus.includes(status);
    const showBondTable = config.validBondTable.includes(status);


    return (
        <Card title={null} size="small">
            <Row type="flex" align="middle" justify="space-between" className="gx-mb-0">
                <Col onClick={toggleCollapse} className="gx-taxes-collapse">
                    <h2 className="h4 gx-text-dark gx-mb-0">
                        <Row type="flex" gutter={8}>
                            <Col>
                                <Icon type={collapsed ? "down" : "up"}/>
                            </Col>
                            <Col>
                                {insurerConfig ? insurerConfig.label : ""}
                            </Col>
                        </Row>
                    </h2>
                </Col>
                <Col md={12}>
                    <Tag color={statusConfig.color} className="tag-item gx-rounded-xs gx-text-uppercase gx-taxes-tag">
                        {data.messages && data.messages.length > 0 ? data.messages : statusConfig.label}
                    </Tag>
                </Col>
            </Row>
            {!collapsed && <section className="gx-taxes-collapse-content">
                <Divider/>
                <Row type="flex" className="gx-flex-column gx-pl-3">
                    {processedAt && <Col>
                        {Translation.processedAtLabel}: {getFormattedDate(processedAt, "DD/MM/YYYY hh:mm A")}
                    </Col>}
                    {reservedAt && <Col className="gx-mt-1">
                        {Translation.reservedAtLabel}: {getFormattedDate(reservedAt)}
                    </Col>}
                    {expiryOn && <Col className="gx-mt-1">
                        {Translation.expiryOnLabel}: <span className="gx-text-red">{getFormattedDate(expiryOn)}</span>
                    </Col>}
                </Row>
                <Row type="flex" className="gx-flex-column">
                    {showBondTable && <Col className="gx-mt-1">
                        <BondTable
                            key={Math.random()}
                            data={limits}
                            suretyAmount={suretyAmount}
                            daysBetweenTerm={daysBetweenTerm}
                            allowSelection={status === "RESERVED"}/>
                    </Col>}
                    {insurerStatus && <Col xs={24} md={18} lg={20} className="gx-mt-1">
                        <Row type="flex" gutter={8}>
                            <Col xs={24} lg={4}>
                                {Translation.errorMessage}:
                            </Col>
                            <Col xs={24} lg={20}>
                                {insurerStatus}
                            </Col>
                        </Row>
                    </Col>}
                </Row>
                <Row type="flex" justify="end" className="gx-mt-1">
                    <Col>
                        <Button
                            loading={loading}
                            disabled={disabled}
                            onClick={handleProcess}
                            className={`${!disabled && "gx-btn-outline-primary"} gx-primary-taxes-btn gx-text-uppercase`} htmlType="button">
                            {Translation.reprocessLabel}
                        </Button>
                    </Col>
                </Row>
            </section>}
        </Card>
    )
};

class Taxes extends React.Component {

    state = {
        loading: true,
        processed: [],
        processedLoading: null,
        data: null,
    };

    componentDidMount() {
        this.handleMultipleRequests();
    }

    

    handleMultipleRequests = () =>{
        const {quotations, documentNumber} = this.props
        let list = []

        const handleAddListItem = (item) => {
            list.push(item)
            this.setState({data:list})
        }
        
        const generateMockup = (quote, responseItem) => {
            return {
                documentNumber,
                insurer:quote.insurer,
                messages:[responseItem.message],
                status:'ERROR'
            }
        }

        if(quotations){
            quotations.forEach((quote)=>{
                Promise.all([this.requestData(quote.insurer)])
                    .then((response)=>{
                        response.forEach((responseItem)=>{
                            responseItem.hasOwnProperty('error') ?
                                handleAddListItem(generateMockup(quote,responseItem))
                            :
                                handleAddListItem(responseItem[0])
                        })
                    })
                    .catch((err)=>{
                        console.error("Ocorreu um erro:",err)
                    })
            })
        }
    }

    requestData = (insurer = null) => {
        const {loading} = this.state;
        const {documentNumber} = this.props;
        if (!loading) {
            this.setState({
                loading: true
            })
        }

        return Request.post(`/surety/v2/reserve`, {documentNumber,insurer, force:false})
            .then(response => {
                return response.json()
            })
            .catch((err)=>{
                console.error("Ocorreu um erro:",err)
            })
            .finally(() => {
                this.setState({
                    loading: false
                })
            })
    };

    populateReserves = (item,index) => { 
        Promise.resolve(item)
        .then(res=>{
            this.setState(prevState => {
                const updatedProcessed = [...prevState.processed, index];
                const updatedData = [...prevState.data];
                updatedData[index] = res[0]
                
                return {
                    processed: updatedProcessed,
                    data:updatedData
                }
            })
        })
    };

    setReserveLoading = (value) => {
        this.setState({
            processedLoading: value
        })
    };

    processReserve = (index,item) => (event) => {
        const {documentNumber} = this.props;
        const {insurer} = item
        this.setReserveLoading(index);

        Request.post(`/surety/v2/reserve`, {documentNumber,insurer, force:true})
            .then(response => {
                if (response.status === 200)
                    this.populateReserves(response.json(),index);
            })
            .finally(() => {
                this.setReserveLoading(null);
            })
    };

    render() {
        const {loading, processed, processedLoading, data} = this.state;
        const {daysBetweenTerm, suretyAmount} = this.props;

        if (!data || loading)
            return <Skeleton active/>;

        return (
            <Row type="flex" align="middle" className="gx-flex-column gx-taxes-list">
                {data.map((item, index) => {
                    return (
                        <Col xs={24} md={16} lg={20} key={index}>
                            <ReserveCard
                                key={Math.random()}
                                data={item}
                                suretyAmount={suretyAmount}
                                daysBetweenTerm={daysBetweenTerm}
                                loading={index === processedLoading}
                                disabled={processed.includes(index)}
                                handleProcess={this.processReserve(index,item)}
                            />
                        </Col>
                    )
                })}
            </Row>
        );
    }
};

export default Taxes;

Taxes.propTypes = {
    documentNumber: PropTypes.string.isRequired
};