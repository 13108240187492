import React, { Component } from "react";
//Translation
import Translation from './translation';
//Components
import {
  Tabs,
  Button,
  Empty,
  Row,
  Col,
  Select
} from "antd";
//Custom Components
import { MuPlaces,FormField } from "components";
//Other Libs
import produce from "immer";
//Constants
import selections from "constants/selections";
import { modals as transportModal } from "../../../../../../../constants";
//Helpers
import { prepareOptions } from "../../../../../../../helpers";
//Constants
const { TabPane } = Tabs;
//Custom Components

const HeaderContent = (props) => {
  const { handleCreate } = props;
  return (
    <Button
      icon="plus"
      onClick={handleCreate}
      className="gx-btn-outline-primary"
      htmlType="button">
      {Translation.add}
    </Button>
  )
};

const ListItem = (props) => {
  const { index, modals, config, handleChange, handleSelectChange,OrderErrors } = props;

  const selectPlace = (index, attr) => (place) => {
    const terms = () => {
      let termList = []
      place.terms.forEach(term => {
        termList.push({
          offset: term.offset,
          value: term.value
        })
      });

      return termList
    };

    const data = {
      description: place.description,
      placeId: place.id,
      terms: terms()
    };

    handleSelectChange(index, attr, data);
  };

  return (
    <Row type="flex" align="middle" gutter={8} key={index}>
      <Col xs={24} md={8}>
        <FormField error={OrderErrors[`routes.routes[${index}].origin`]}>
          <MuPlaces
            required
            initialValue={config.origin ? config.origin.description : null}
            onSelect={selectPlace(index, "origin")}
            placeholder={Translation.originPlaceholder}
            id="origin-google-places-autocomplete-input" />
        </FormField>
      </Col>
      <Col xs={24} md={8}>
        <FormField error={OrderErrors[`routes.routes[${index}].destination`]}>
          <MuPlaces
            required
            initialValue={config.destination ? config.destination.description : null}
            onSelect={selectPlace(index, "destination")}
            placeholder={Translation.destinationPlaceholder}
            id="destination-google-places-autocomplete-input" />
        </FormField>
      </Col>
      {modals.length !== 1 &&
        <Col xs={24} md={8}>
          <FormField error={OrderErrors[`routes.routes[${index}].modal`]}>
            <Select
              className="gx-w-100"
              value={config.modal}
              placeholder={Translation.modalPlaceholder}
              onChange={handleChange(index, "modal")}>
              {modals.length === 0
                ? transportModal.map((modalKey) => {
                    return (
                      <Select.Option key={modalKey.key} value={modalKey.key}>
                        {modalKey.value}
                      </Select.Option>
                    );
                  })
                : modals.map((modalKey) => {
                    const text = selections.meansOfTransportation.find(
                      (modal) => modal.value === modalKey
                    );
                    return (
                      <Select.Option key={modalKey} value={modalKey}>
                        {text.label}
                      </Select.Option>
                    );
                  })}
            </Select>
          </FormField>
        </Col>}
      <Col xs={24} md={6}>
      <FormField error={OrderErrors[`routes.routes[${index}].frequency`]}>
        <Select
          className="gx-w-100"
          value={config.frequency}
          placeholder={Translation.frequencyPlaceholder}
          onChange={handleChange(index, "frequency")}>
          {prepareOptions(selections.openPolicyFrequency)}
        </Select>
      </FormField>
      </Col>
    </Row>
  )
};

const List = (props) => {
  const { data, general, handleChange, handleRemove, handleSelectChange, OrderErrors } = props;
  const modals = general.modals;

  if (!data.length) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={Translation.empty} />
    )
  }

  return data.map((config, index) => {
    return (
      <Row type="flex" gutter={8} className="fadein gx-mt-2">
        <Col xs={24} lg={22}>
          <ListItem
            index={index}
            config={config}
            handleChange={handleChange}
            handleSelectChange={handleSelectChange}
            modals={modals}
            OrderErrors={OrderErrors}
          />
        </Col>
        <Col xs={24} lg={2}>
          <Button icon="delete"
            shape="circle-outline"
            onClick={handleRemove(index)}
            className={`gx-groups-content-delete-btn`} />
        </Col>
      </Row>
    )
  })
};

class Routes extends Component {

  constructor(props) {
    super(props);
    this.state = this.initializeState();
  }

  initializeState = () => {
    const { data, general } = this.props;
    return {
      data: data || [],
      general: general || [],
    }
  };

  reset = () => {
    const initialState = this.initializeState();
    this.setState(initialState);
  };

  getChanges = () => {
    return this.state.data;
  };

  notifyChanges = () => {
    if (this.props.notifyChanges)
      return this.props.notifyChanges();
  };

  handleChange = (index, attr, isDirect = false) => (event) => {
    const {RemoveError} = this.props

    RemoveError(`routes.routes[${index}].${attr}`)
    let updatedValue = event;
    if (!isDirect && typeof event === "object")
      updatedValue = event.target.value;

    this.setState(produce(draft => {
      draft.data[index][attr] = updatedValue;
    }), this.notifyChanges)
  };

  handleSelectChange = (index, attr, data) => {
    const {RemoveError} = this.props
    RemoveError(`routes.routes[${index}].${attr}`)
    this.setState(produce(draft => {
      draft.data[index][attr] = data;
    }), this.notifyChanges)
  };

  handleCreate = () => {
    this.setState(produce(draft => {
      draft.data.push({
        origin: null,
        destination: null,
        modal: null,
        distribution: null
      })
    }), this.afterCreate);
  };

  afterCreate = () => {
    this.notifyChanges();
  };

  handleRemove = (removalIndex) => () => {
    this.setState(produce(draft => {
      draft.data.splice(removalIndex, 1);
    }), this.notifyChanges)
  };

  render() {
    const { data, general } = this.state;
    const { OrderErrors } = this.props
    const emptyData = data.length === 0;
    const tabClassName = emptyData ? "gx-groups-empty" : "gx-groups-tabs";

    return (
      <section className="gx-mt-5">
        <Tabs
          hideAdd
          type="card"
          activeKey={"group"}
          className={tabClassName}
          tabBarExtraContent={
            <HeaderContent
              selected={data}
              handleCreate={this.handleCreate} />}>
          <TabPane
            key="group"
            className="gx-min-h-300"
            tab={Translation["openPolicy.routes.title"]}>
            <List
              data={data}
              general={general}
              handleChange={this.handleChange}
              handleSelectChange={this.handleSelectChange}
              handleRemove={this.handleRemove}
              OrderErrors={OrderErrors}
            />
          </TabPane>
        </Tabs>
      </section>
    );
  }
}

export default Routes;