import React from "react";
//Router Lib
import { Redirect, Route } from "react-router-dom";
import { VoipComponent } from "../../components";
const RestrictedRoute = ({ component: Component, authUser, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authUser ? (
          <>
            <Component {...props} />
            <VoipComponent />
          </>
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};
export default RestrictedRoute;
