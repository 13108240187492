import React, {Fragment} from "react";
//Antd Components
import {Button, Drawer, Icon} from "antd";
//Styled Components
import CustomScrollbars from "util/CustomScrollbars";
//Translation config
import Translation from './translation';

const UserSideBar = (props) => {
    const {visible, closeSidebar, createUser} = props;
    const children = <div className="gx-module-side">
        <div className="gx-module-side-header">
            <div className="gx-module-logo">
                <Icon type="team" className="gx-mr-2"/>
                <span>{Translation.title}</span>
            </div>
        </div>

        <div className="gx-module-side-content">
            <CustomScrollbars className="gx-module-side-scroll">
                <div className="gx-module-add-task">
                    <Button className="gx-btn-block ant-btn" type="primary" aria-label="add"
                            onClick={createUser}>
                        <i className="icon icon-add gx-mr-2"/>
                        <span>
                            {Translation.createUserButton}
                        </span>
                    </Button>
                </div>
            </CustomScrollbars>
        </div>
    </div>;
    return (
        <Fragment>
            <div className="gx-d-block gx-d-lg-none">
                <Drawer
                    placement="left"
                    closable={false}
                    visible={visible}
                    onClose={closeSidebar}>
                    {children}
                </Drawer>
            </div>
            <div className="gx-module-sidenav gx-d-none gx-d-lg-flex">
                {children}
            </div>
        </Fragment>
    )
};
export default UserSideBar;
