import React, { useState } from "react";
// prop types
import PropTypes from "prop-types";
// translations
import { useTranslation } from "react-i18next";
// validations
import * as Yup from "yup";
// redux
import { useDispatch } from "react-redux";
// antd
import { Row, Col, Button, Select } from "antd";
// components
import { LngInput, FormField, MaskedInput } from "../../../components";
// helpers
import { clearError, formatPhone, getErrorMessage } from "../../../helpers";
// constants
import {
  CNPJ_REGEXP,
  dealTypes,
  orderOrigin,
  PHONE_NUMBER_REGEXP,
} from "../../../constants";

const CreateOrderForm = ({ errors = [], users = [], onSubmit, setErrors }) => {
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();
  const [formData, setFormData] = useState({});

  const productOptions = [
    {
      label: translation("order.createOrder.openPolicyProduct"),
      value: "OPEN_POLICY",
    },
    {
      label: translation("order.createOrder.singleBoardingProduct"),
      value: "SINGLE_BOARDING",
    },
    {
      label: translation("order.createOrder.suretyProduct"),
      value: "SURETY",
    },
    {
      label: translation("order.createOrder.lifeProduct"),
      value: "LIFE",
    },
  ];

  const handleDisableSubmit = () => {
    return (
      !formData.type ||
      !formData.name ||
      !formData.email ||
      !formData.phoneNumber ||
      !formData.documentNumber ||
      !formData.origin ||
      !formData.salesRepresentativeId ||
      !formData.dealType
    );
  };

  const schema = Yup.object().shape({
    email: Yup.string().email(
      translation("order.createOrder.emailErrorMessage")
    ),
    phoneNumber: Yup.string().matches(PHONE_NUMBER_REGEXP, {
      message: translation("order.createOrder.phoneNumberErrorMessage"),
    }),
    documentNumber: Yup.string().matches(CNPJ_REGEXP, {
      message: translation("order.createOrder.documentNumberErrorMessage"),
    }),
  });

  const handleValidate = (field, value) => {
    schema
      .validate({ [field]: value })
      .then(() => {
        return dispatch(setErrors(clearError(`initialData.${field}`, errors)));
      })
      .catch((error) => {
        return dispatch(
          setErrors([
            ...errors,
            { field: `initialData.${field}`, message: error.message },
          ])
        );
      });
  };

  const handleChange = (field, value) => {
    handleValidate(field, value);
    setFormData({ ...formData, [field]: value });
  };

  const handleSubmit = () => {
    return onSubmit && onSubmit(formData);
  };

  return (
    <>
      <Row type="flex" align="middle">
        <Col className="gx-d-flex gx-m-0">
          <strong>
            {translation("order.createOrder.insuranceType.title")}
          </strong>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-3">
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`initialData.type`, errors)}
          >
            <Select
              className="gx-w-100"
              value={formData.type}
              placeholder={translation(
                "order.createOrder.insuranceType.placeholder"
              )}
              onChange={(value) => handleChange("type", value)}
            >
              {productOptions.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-3">
        <Col className="gx-d-flex gx-m-0 gx-w-100">
          <strong>{translation("order.createOrder.contactData.title")}</strong>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-3">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.nameLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField error={getErrorMessage("initialData.name", errors)}>
            <LngInput
              placeholder={translation("order.createOrder.namePlaceholder")}
              value={formData.name}
              onChange={(event) => handleChange("name", event.target.value)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-1">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.emailLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField error={getErrorMessage("initialData.email", errors)}>
            <LngInput
              placeholder={translation("order.createOrder.emailPlaceholder")}
              value={formData.email}
              onChange={(event) => handleChange("email", event.target.value)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-1">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.phoneNumberLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField error={getErrorMessage("initialData.phoneNumber", errors)}>
            <MaskedInput
              mask="(99) 99999-9999"
              value={formData.phoneNumber}
              onChange={(event) =>
                handleChange("phoneNumber", formatPhone(event.target.value))
              }
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-1">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.documentNumberLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField
            error={getErrorMessage("initialData.documentNumber", errors)}
          >
            <MaskedInput
              mask="99.999.999/9999-99"
              value={formData.documentNumber}
              onChange={(event) =>
                handleChange("documentNumber", event.target.value)
              }
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-1">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.originLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`origin`, errors)}
          >
            <Select
              className="gx-w-100"
              value={formData.origin}
              placeholder={translation("order.createOrder.originPlaceholder")}
              onChange={(value) => handleChange("origin", value)}
            >
              {orderOrigin.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-1">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.salesRepresentativeLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`salesRepresentativeId`, errors)}
          >
            <Select
              className="gx-w-100"
              value={formData.salesRepresentativeId}
              placeholder={translation(
                "order.createOrder.salesRepresentativePlaceholder"
              )}
              onChange={(value) => handleChange("salesRepresentativeId", value)}
            >
              {users.map((item, index) => (
                <Select.Option key={index} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mt-1">
        <Col className="gx-m-0 gx-w-100">
          {translation("order.createOrder.dealTypeLabel")}
        </Col>
        <Col sm={21} className="gx-m-0 gx-p-0">
          <FormField
            className="gx-m-0"
            error={getErrorMessage(`dealType`, errors)}
          >
            <Select
              className="gx-w-100"
              value={formData.dealType}
              placeholder={translation("order.createOrder.dealTypePlaceholder")}
              onChange={(value) => handleChange("dealType", value)}
            >
              {dealTypes.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" justify="end" className="gx-mt-4">
        <Button
          type="primary"
          className="gx-m-0"
          onClick={handleSubmit}
          disabled={handleDisableSubmit()}
        >
          {translation("order.createOrder.createButton")}
        </Button>
      </Row>
    </>
  );
};

CreateOrderForm.propTypes = {
  errors: PropTypes.array,
  users: PropTypes.array,
  onChange: PropTypes.func,
  onSubmit: PropTypes.func,
  setErrors: PropTypes.func,
};

export default CreateOrderForm;
