//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation();

const keys = [
  {
    "key": "tribunalLabel",
    "type": "label"
  }, 
  {
    "key": "tribunalPlaceholder",
    "type": "label"
  }, 
  {
    "key": "courtLabel",
    "type": "label"
  }, 
  {
    "key": "courtPlaceholder",
    "type": "label"
  }, 
];

export default prepareTranslations(keys, translation);