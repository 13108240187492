//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("userDetails");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    editUserLabel: getTranslation("editUserLabel"),
    deleteUserLabel: getTranslation("deleteUserLabel"),
}