import React from 'react';
//Translation
import Translation from "./translation";
import { useTranslation } from 'react-i18next';
//Components
import {Row, Col, Select, Checkbox} from "antd";
//Custom Components
import {Switch, MultipleSelect, LngInput} from "components";
import Fleets from "./Fleets";
//Constants
import selections from "constants/selections";
//Custom Helpers
import {prepareOptions, formatSelectValue} from "helpers";

const OperationalContent = (props) => {
  const {parentRef, configurations, notifyChanges, handleChange, OrderErrors, RemoveError} = props;

  const {t: translation} = useTranslation();

  const employeeNumberRange = [
    {
      label: translation("options.employeeNumberRange.1"),
      value: "RANGE_1"
    },
    {
      label: translation("options.employeeNumberRange.2"),
      value: "RANGE_2"
    },
    {
      label: translation("options.employeeNumberRange.3"),
      value: "RANGE_3"
    },
    {
      label: translation("options.employeeNumberRange.4"),
      value: "RANGE_4"
    }
  ];

  const fleetComposition = [
    {
      value: "HEAVY_VEHICLE",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.fleetComposition.selectItem1"
      )
    },
    {
      value: "MEDIUM_VEHICLE",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.fleetComposition.selectItem2"
      )
    },
    {
      value: "LIGHT_VEHICLE",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.fleetComposition.selectItem3"
      )
    },
    {
      value: "MOTORCYCLE",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.fleetComposition.selectItem4"
      )
    },
    {
      value: "OTHERS",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.fleetComposition.selectItem6"
      )
    }
  ];

  const bodyWorkTypes = [
    {
      value: "CLOSED",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.bodyWorkTypes.label1"
      )
    },
    {
      value: "SPECIAL",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.bodyWorkTypes.label2"
      )
    },
    {
      value: "OPENED",
      label: translation(
        "cargoOpenPolicy.fleetDataPage.fleetForm.bodyWorkTypes.label3"
      )
    }
  ];

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;
    if (typeof event === "object") {
      if (!isDirect) {
        if (event instanceof Array)
          value = event;
        else
          value = event.target.value;
      }
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
  };

  const toggleProperty = (attr) => () => {
    handleChange({
      attr: attr,
      value: !getValue(attr)
    });
  };

  return (
    <section>
      <Fleets
        ref={parentRef}
        notifyChanges={notifyChanges}
        data={configurations.fleets}
        OrderErrors={OrderErrors}
        RemoveError={RemoveError}/>
      <Row type="flex" align="middle" gutter={16} className="gx-mb-3 gx-mt-2">
        <Col>
          {Translation.logisticTypeLabel}:
        </Col>
        <Col>
          <Select
            value={getValue("logistic")}
            style={{minWidth: 160}}
            onChange={onChange("logistic")}
            placeholder={Translation.logisticTypePlaceholder}>
            {prepareOptions(selections.openPolicyLogisticTypes)}
          </Select>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
        <Col>
          <Switch
            checked={getValue("oneResponsibleForStorage")}
            onChange={toggleProperty("oneResponsibleForStorage")}/>
        </Col>
        <Col>
          {Translation.clientResponsibleLabel} ?
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
        <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Switch
                checked={getValue("hasExclusivity")}
                onChange={toggleProperty("hasExclusivity")}/>
            </Col>
            <Col>
              {Translation.exclusiveOperativeLabel}:
            </Col>
          </Row>
        </Col>
        <Col>
          <LngInput
            value={getValue("exclusivityCompanies")}
            onChange={onChange("exclusivityCompanies")}/>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
        <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Switch
                checked={getValue("hasDDR")}
                onChange={toggleProperty("hasDDR")}/>
            </Col>
            <Col>
              {Translation.wayverShipperLabel}
            </Col>
          </Row>
        </Col>
        <Col>
          <LngInput
            value={getValue("shipper")}
            onChange={onChange("shipper")}/>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
        <Col>
          <Row type="flex" gutter={16}>
            <Col>
              <Switch
                checked={getValue("registeredDrivers")}
                onChange={toggleProperty("registeredDrivers")}/>
            </Col>
            <Col>
              {Translation.registeredDriverLabel}
            </Col>
          </Row>
        </Col>
        <Col>
          <LngInput
            value={getValue("riskManagementCompanies")}
            onChange={onChange("riskManagementCompanies")}/>
        </Col>
      </Row>

      <section className="gx-mt-5 gx-mb-5">
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3 gx-mt-2">
          <Col xs={8}>
            {translation("cargoOpenPolicy.operationPage.employeeAmountLabel")}:
          </Col>
          <Col xs={16}>
            <Select
              className="gx-w-100"
              value={getValue("employeeNumber")}
              onChange={onChange("employeeNumber")}
              placeholder={translation("cargoOpenPolicy.operationPage.employeeAmountPlaceholder")}
            >
              {employeeNumberRange.map((item, index) => (
                <Select.Option key={index} value={item.value}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3 gx-mt-2">
          <Col xs={8}>
            {translation("cargoOpenPolicy.operationPage.systemNameLabel")}:
          </Col>
          <Col xs={16}>
          <LngInput
            className="gx-w-100"
            value={getValue("systemName")}
            onChange={onChange("systemName")}
            placeholder={translation("cargoOpenPolicy.operationPage.systemNamePlaceholder")}
          /> 
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
          <Col xs={8}>
            {Translation.shipmentDocumentsLabel}
          </Col>
          <Col xs={16}>
            <MultipleSelect
              className="gx-w-100"
              value={formatSelectValue(configurations.transportDocuments)}
              onChange={onChange("transportDocuments")}
              options={selections.transportDocuments}
              placeholder={"Choose all the documents that will be included"}/>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
          <Col xs={8}>
            {Translation.trackerLabel}
          </Col>
          <Col xs={16}>
            <MultipleSelect
              className="gx-w-100"
              value={formatSelectValue(configurations.trackings)}
              onChange={onChange("trackings")}
              options={selections.trackings}
              placeholder={"Choose all the trackings that will be included"}/>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3">
          <Col xs={8}>
            {Translation.riskTypesLabel}
          </Col>
          <Col xs={16}>
            <MultipleSelect
              className="gx-w-100"
              value={formatSelectValue(configurations.riskManagement)}
              onChange={onChange("riskManagement")}
              options={selections.openPolicyRiskMngm}
              placeholder={"Choose all the Risk Mngm that will be included"}/>
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3 gx-mt-2">
          <Col xs={8}>
            {translation("cargoOpenPolicy.operationPage.fleetCompositionLabel")}:
          </Col>
          <Col xs={16}>
            <Checkbox.Group 
              value={getValue("fleetComposition")} 
              onChange={onChange("fleetComposition")} 
              options={fleetComposition} 
            /> 
          </Col>
        </Row>
        <Row type="flex" align="middle" gutter={16} className="gx-mb-3 gx-mt-2">
          <Col xs={8}>
            {translation("cargoOpenPolicy.operationPage.bodyWorkTypesLabel")}:
          </Col>
          <Col xs={16}>
            <Checkbox.Group 
              value={getValue("bodyWorkTypes")} 
              onChange={onChange("bodyWorkTypes")} 
              options={bodyWorkTypes} 
            /> 
          </Col>
        </Row>
      </section>
    </section>

  );
};

export default OperationalContent;