//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "hasOwnFleetLabel",
    "type": "label"
  },
  {
    "key": "hasThirtyFleetLabel",
    "type": "label"
  },
  {
    "key": "hasIndependentFleetLabel",
    "type": "label"
  },
  {
    "key": "totalVehiclesLabel",
    "type": "label"
  },
  {
    "key": "totalVehiclesPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "averageAgeLabel",
    "type": "label"
  },
  {
    "key": "averageAgePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "maximumAgeLabel",
    "type": "label"
  },
  {
    "key": "averageAgePlaceholder",
    "type": "placeholder"
  }
];
export default prepareTranslations(keys, translation);