import React, { useEffect } from "react";
//Components
import { Button, Col, Row } from "antd";
//Custom Components
import GroupButtons from "../GroupButtons";
import MaskedInput from "../MaskedInput";
import FormField from "../FormField";
import { inputMasks } from "helpers";
//Translation
import Translation from "./translation";
//Config
const contactTypeEntries = [
  {
    value: "COMPANY",
    label: Translation.companyType,
  },
  {
    value: "INDIVIDUAL",
    label: Translation.personType,
  },
];

const Form = (props) => {
  const {
    disableType,
    type,
    documentNumber,
    onTypeChange,
    onDocumentChange,
    searchCompany,
    disableSearchCompany,
    personTypeLabel,
    documentNumberLabel,
    errors,
  } = props;

  // useEffect(() => {
  //   if (!type) {
  //     onTypeChange("COMPANY");
  //   }
  // }, [onTypeChange, type]);

  const renderPersonTypeInput = () => {
    const personTypeMask = inputMasks[type];
    switch (type) {
      case "COMPANY":
        return (
          <Row type="flex" gutter={8}>
            <Col xs={24} lg={14}>
              <FormField error={errors}>
                <MaskedInput
                  disabled={false}
                  mask={personTypeMask}
                  onChange={onDocumentChange}
                  defaultValue={documentNumber}
                />
              </FormField>
            </Col>
            {!disableSearchCompany && (
              <Col xs={24} lg={10}>
                <Button
                  icon="search"
                  className="gx-btn-outline-primary gx-mb-0"
                  htmlType="button"
                  onClick={searchCompany}
                >
                  {Translation.searchCompany}
                </Button>
              </Col>
            )}
          </Row>
        );
      case "INDIVIDUAL":
        return (
          <FormField error={errors}>
            <MaskedInput
              disabled={false}
              mask={personTypeMask}
              onChange={onDocumentChange}
              defaultValue={documentNumber}
            />
          </FormField>
        );
      default:
        return null;
    }
  };

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {personTypeLabel}:
        </Col>
        <Col xs={24} md={18}>
          <GroupButtons
            value={type}
            onChange={!disableType ? onTypeChange : undefined}
            entries={contactTypeEntries}
          />
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {documentNumberLabel}
        </Col>
        <Col xs={24} md={18}>
          {renderPersonTypeInput()}
        </Col>
      </Row>
    </section>
  );
};

export default Form;

Form.defaultProps = {
  disableType: false,
};
