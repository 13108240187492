//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "originLabel",
    "type": "label"
  },
  {
    "key": "destinationLabel",
    "type": "label"
  },
  {
    "key": "currencyLabel",
    "type": "label"
  },
  {
    "key": "currencyPlaceholder",
    "type": "label"
  },
  {
    "key": "totalAmountLabel",
    "type": "label"
  },
  {
    "key": "totalAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "travelAverageAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "travelAverageAmountLabel",
    "type": "label"
  },
  {
    "key": "companyActivityLabel",
    "type": "label"
  },
  {
    "key": "companyActivityPlaceholder",
    "type": "label"
  },
  {
    "key": "merchandiseOwnerLabel",
    "type": "label"
  },
  {
    "key": "anyUsedMerchandiseLabel",
    "type": "label"
  },
  {
    "key": "ownMerchandiseSinglePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "thirdyPartyMerchandiseSinglePlaceholder",
    "type": "placeholder"
  },
  
];
export default prepareTranslations(keys, translation);