import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col } from "antd";
// components
import LabelField from "../../../LabelField";
import FormattedValue from "../../../FormatedValue/FormattedValue";
// helpers
import { getFormattedDate } from "../../../../helpers";

const HistoryHeader = ({ registerData = {}, policy = {} }) => {
  const { t: translation } = useTranslation();
  const { companyName, documentNumber } = registerData;
  const { policyNumber, initialTermAt, finalTermAt, insurer } = policy;
  return (
    <>
      <Row>
        {companyName && (
          <Col xs={24} md={12}>
            <LabelField
              label={<strong>{translation("invoice.historyHeader.companyNameLabel")}</strong>}
            >
              <span>{companyName}</span>
            </LabelField>
          </Col>
        )}
        {documentNumber && (
          <Col xs={24} md={12}>
            <LabelField
              label={<strong>{translation("invoice.historyHeader.documentNumberLabel")}</strong>}
            >
              <FormattedValue
                mask="_"
                displayType="text"
                value={documentNumber}
                format={"COMPANY"}
              />
            </LabelField>
          </Col>
        )}
      </Row>
      <Row>
        {policyNumber && (
          <Col xs={24} md={12}>
            <LabelField
              label={<strong>{translation("invoice.historyHeader.policyNumberLabel")}</strong>}
            >
              <span>{policyNumber}</span>
            </LabelField>
          </Col>
        )}
        <Col xs={24} md={12}>
          <LabelField
            label={<strong>{translation("invoice.historyHeader.initialAtLabel")}</strong>}
          >
            <span>{getFormattedDate(initialTermAt)}</span>&nbsp;
            {translation("invoice.historyHeader.finalAtLabel")}&nbsp;
            <span>{getFormattedDate(finalTermAt)}</span>
          </LabelField>
        </Col>
      </Row>
      {insurer && (
        <Row>
          <Col xs={24} md={12}>
            <LabelField
              label={<strong>{translation("invoice.historyHeader.insurerLabel")}</strong>}
            >
              <span>{insurer}</span>
            </LabelField>
          </Col>
        </Row>
      )}
    </>
  );
};

HistoryHeader.propTypes = {
  registerData: PropTypes.object,
  policy: PropTypes.object,
};

export default HistoryHeader;
