//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "coverage.accident",
    "type": "label"
  },
  {
    "key": "coverage.theft",
    "type": "label"
  },
  {
    "key": "coverage.damage",
    "type": "label"
  },
  {
    "key": "coverage.trackCleaning",
    "type": "label"
  },
  {
    "key": "coverage.lifeInsurance",
    "type": "label"
  },
  {
    "key": "coverage.environmentalLiabilityRisk",
    "type": "label"
  },
  {
    "key": "coverage.loadingUnloading",
    "type": "label"
  },
  {
    "key": "coverage.loadingUnloadingLifting",
    "type": "label"
  },
  {
    "key": "coverage.fluvialRoute",
    "type": "label"
  },
  {
    "key": "coverage.freight",
    "type": "label"
  },
  
  {
    "key": "coverage.other",
    "type": "label"
  },
  {
    "key": "otherLabel",
    "type": "label"
  },
  {
    "key": "otherPlaceholder",
    "type": "placeholder"
  }
];
export default prepareTranslations(keys, translation);