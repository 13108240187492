export const getScenarioValues = (scenarios, attr) => {
	if(scenarios){
		let result = {
			BEST: null,
			WORST: null
		}
		scenarios.map((scenario) => {
			return result[scenario.type] = scenario[attr];
		})
		return result;
	}
	return null;
}