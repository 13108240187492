import { useTranslation } from "react-i18next";
import * as Yup from "yup";

export const useCustomersSearch = () => {
  const { t: translation } = useTranslation();

  const DEFAULT_VALUE = "DEFAULT_LABEL";

  const CustomersSearchFieldsEnum = {
    POLICY_NUMBER: "POLICY_NUMBER",
    CONTRACT_NUMBER: "CONTRACT_NUMBER",
    DOCUMENT_NUMBER: "DOCUMENT_NUMBER",
    COMPANY_NAME: "COMPANY_NAME",
    CONTACT_EMAIL: "CONTACT_EMAIL",
    CONTACT_NAME: "CONTACT_NAME",
    INSURED_NAME: "INSURED_NAME",
    INSURED_DOCUMENT_NUMBER: "INSURED_DOCUMENT_NUMBER",
    INDIVIDUAL_INSURED_NAME: "INDIVIDUAL_INSURED_NAME",
    INDIVIDUAL_INSURED_DOCUMENT_NUMBER: "INDIVIDUAL_INSURED_DOCUMENT_NUMBER",
    DEFAULT_LABEL: "DEFAULT_LABEL",
  };

  const searchFilterOptions = [
    {
      label: translation("customer.searchBar.selectFilter.label"),
      value: CustomersSearchFieldsEnum.DEFAULT_LABEL,
    },
    {
      label: translation("customer.searchBar.selectFilter.policyNumberLabel"), // "Número da apólice"
      value: CustomersSearchFieldsEnum.POLICY_NUMBER,
    },
    {
      label: translation("customer.searchBar.selectFilter.contractNumberLabel"), // "Número do contrato"
      value: CustomersSearchFieldsEnum.CONTRACT_NUMBER,
    },
    {
      label: translation("customer.searchBar.selectFilter.companyNameLabel"), // "Razão social do cliente"
      value: CustomersSearchFieldsEnum.COMPANY_NAME,
    },
    {
      label: translation("customer.searchBar.selectFilter.documentNumberLabel"), // CNPJ do cliente
      value: CustomersSearchFieldsEnum.DOCUMENT_NUMBER,
    },
    {
      label: translation("customer.searchBar.selectFilter.contactEmailLabel"), // "E-mail do contato"
      value: CustomersSearchFieldsEnum.CONTACT_EMAIL,
    },
    {
      label: translation("customer.searchBar.selectFilter.contactNameLabel"), // "Nome do contato"
      value: CustomersSearchFieldsEnum.CONTACT_NAME,
    },
    {
      label: translation("customer.searchBar.selectFilter.insuredNameLabel"), // "Nome do segurado"
      value: CustomersSearchFieldsEnum.INDIVIDUAL_INSURED_NAME,
    },
    {
      label: translation(
        "customer.searchBar.selectFilter.individualInsuredDocumentNumberLabel"
      ), // "CPF do segurado"
      value: CustomersSearchFieldsEnum.INDIVIDUAL_INSURED_DOCUMENT_NUMBER,
    },
    {
      label: translation("customer.searchBar.selectFilter.customerNameLabel"), // "RAZÃO SOCIAL DO SEGURADO"
      value: CustomersSearchFieldsEnum.INSURED_NAME,
    },
    {
      label: translation(
        "customer.searchBar.selectFilter.companyInsuredDocumentNumberLabel"
      ), // "CNPJ DO SEGURADO"
      value: CustomersSearchFieldsEnum.INSURED_DOCUMENT_NUMBER,
    },
  ];

  const resolvePolicyPath = (data) => {
    return `/crm/customers/${data.customerId}/policy/${data?.optionValue}`;
  };

  const resolveCustomerPath = (data) => {
    return data?.person
      ? `/crm/customers/${data?.person}`
      : `/crm/customers/${data?.id}`;
  };

  function searchRequestsFactory() {
    return {
      POLICY_NUMBER: {
        getEndpoint: (value) =>
          `policy/v1/search/?parameter=POLICY_NUMBER&searchedText=${value}`,
        getRedirectPath: (data) => resolvePolicyPath(data),
      },
      CONTRACT_NUMBER: {
        getEndpoint: (value) =>
          `policy/v1/search/?parameter=CONTRACT_NUMBER&searchedText=${value}`,
        getRedirectPath: (data) => resolvePolicyPath(data),
      },
      DOCUMENT_NUMBER: {
        getEndpoint: (value) =>
          `person/v2/person/?documentNumber=${value}&sort=documentNumber`,
        getRedirectPath: (data) => resolveCustomerPath(data),
      },
      COMPANY_NAME: {
        getEndpoint: (value) =>
          `person/v2/person/?companyName=${value}&sort=companyName`,
        getRedirectPath: (data) => resolveCustomerPath(data),
      },
      INDIVIDUAL_INSURED_DOCUMENT_NUMBER: {
        getEndpoint: (value) =>
          `policy/v1/search/?parameter=INSURED_DOCUMENT_NUMBER&searchedText=${value}`,
        getRedirectPath: (data) => resolvePolicyPath(data),
      },
      INSURED_DOCUMENT_NUMBER: {
        getEndpoint: (value) =>
          `policy/v1/search/?parameter=INSURED_DOCUMENT_NUMBER&searchedText=${value}`,
        getRedirectPath: (data) => resolvePolicyPath(data),
      },
      INDIVIDUAL_INSURED_NAME: {
        getEndpoint: (value) =>
          `policy/v1/search/?parameter=INSURED_NAME&searchedText=${value}`,
        getRedirectPath: (data) => resolvePolicyPath(data),
      },
      INSURED_NAME: {
        getEndpoint: (value) =>
          `policy/v1/search/?parameter=INSURED_NAME&searchedText=${value}`,
        getRedirectPath: (data) => resolvePolicyPath(data),
      },
      CONTACT_NAME: {
        getEndpoint: (value) =>
          `person/v2/contact/?parameter=CONTACT_NAME&searchedText=${value}`,
        getRedirectPath: (data) => resolveCustomerPath(data),
      },
      CONTACT_EMAIL: {
        getEndpoint: (value) =>
          `person/v2/contact/?parameter=CONTACT_EMAIL&searchedText=${value}`,
        getRedirectPath: (data) => resolveCustomerPath(data),
      },
    };
  }

  function getSearchRequest(searchFilter, value) {
    const searchRequests = searchRequestsFactory();

    return searchRequests[searchFilter]
      ? searchRequests[searchFilter].getEndpoint(value)
      : "";
  }

  function getRedirectPath(searchFilter, value) {
    const searchRequests = searchRequestsFactory();

    return searchRequests[searchFilter]
      ? searchRequests[searchFilter].getRedirectPath(value)
      : "";
  }

  const digitsOnlyRegex = /^(\d+|)$/;

  const customerSearchSchema = Yup.object({
    selectedFilter: Yup.string(),
    searchValue: Yup.string()
      .nullable()
      .when("selectedFilter", {
        is: (value) =>
          value === CustomersSearchFieldsEnum.DOCUMENT_NUMBER ||
          value === CustomersSearchFieldsEnum.INSURED_DOCUMENT_NUMBER ||
          value ===
            CustomersSearchFieldsEnum.INDIVIDUAL_INSURED_DOCUMENT_NUMBER,
        then: () =>
          Yup.string().matches(
            digitsOnlyRegex,
            translation("customer.searchBar.validation.digitsOnly")
          ),
      }),
  });

  return {
    searchFilterOptions,
    DEFAULT_VALUE,
    getSearchRequest,
    getRedirectPath,
    customerSearchSchema,
    CustomersSearchFieldsEnum,
  };
};
