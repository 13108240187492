//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "cityLabel",
    "type": "label"
  },
  {
    "key": "cityPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "stateLabel",
    "type": "label"
  },
  {
    "key": "statePlaceholder",
    "type": "placeholder"
  },

];
export default prepareTranslations(keys, translation);