import React, { Component } from "react";
/*Components*/
import { Checkbox } from "antd";
//Custom User Components
import {
  UserList,
  UserSidebar,
  CreateUser,
  DeleteUser,
  SearchUser,
} from "components";
//Others
import CustomScrollbars from "util/CustomScrollbars";
//Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//Selectors
import { extractAllUsers, isFetchingUsers } from "appRedux/selectors";
//Actions
import {
  fetchUsers,
  updateUser,
  createUser,
  selectUser,
  selectUsers,
  fetchAllProfiles,
  filterUsers,
} from "appRedux/actionCreators";
//Selectors
import {
  extractAllProfiles,
  extractSelectedUsers,
  isFetchingAllProfiles,
  extractUserSearch,
} from "../../appRedux/selectors";
//Helper functions`
import { setInitialTranslation } from "helpers";
//Custom User  Helpers
import {
  getNumberOfSelectedUsers,
  getUsersFromSelected,
} from "components/User/helpers";
//Constants
const translation = setInitialTranslation("users");

class User extends Component {
  state = {
    openCreateUser: false,
    openUserDrawer: false,
    openDeleteUsers: false,
  };

  componentDidMount() {
    const { fetchUsers, fetchAllProfiles } = this.props.actions;
    fetchUsers();
    fetchAllProfiles();
  }

  toggleModal = (modalName) => () => {
    const {
      actions: { selectUsers },
      selectedUsers,
    } = this.props;
    let isModalOpen;
    this.setState(
      (prevState) => {
        isModalOpen = !!prevState[`open${modalName}`];
        return {
          [`open${modalName}`]: !prevState[`open${modalName}`],
        };
      },
      () => {
        if (isModalOpen) {
          selectUsers("none");
        } else {
          if (modalName === "CreateUser")
            if (getNumberOfSelectedUsers(selectedUsers) > 0)
              selectUsers("none");
        }
      }
    );
  };

  onAllUserSelect = () => {
    const {
      users,
      selectedUsers,
      actions: { selectUsers },
    } = this.props;
    const selectAll = getNumberOfSelectedUsers(selectedUsers) < users.length;
    if (selectAll) selectUsers("all");
    else selectUsers("none");
  };

  render() {
    //Current  State
    const { openCreateUser, openDeleteUsers, openUserDrawer } = this.state;
    //Current Props
    const {
      actions,
      users,
      selectedUsers,
      loadingUsers,
      userSearch,
      allProfiles,
      loadingAllProfiles,
    } = this.props;
    //Current Actions
    const {
      updateUser,
      createUser,
      selectUser,
      selectUsers,
      filterUsers,
    } = actions;
    //Current Constants
    const numberOfSelectedUsers = getNumberOfSelectedUsers(selectedUsers);
    const numberOfAllUsers = users.length;

    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <UserSidebar
            visible={openUserDrawer}
            closeSidebar={this.toggleModal("UserDrawer")}
            createUser={this.toggleModal("CreateUser")}
          />
          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                <i
                  className="icon icon-menu gx-icon-btn"
                  aria-label="Menu"
                  onClick={this.toggleModal("UserDrawer")}
                />
              </span>
              <SearchUser
                filterUsers={filterUsers}
                search={userSearch}
                placeholder={translation.set("searchPlaceholder", false, true)}
              />
            </div>
            <div className="gx-module-box-content">
              <div className="gx-module-box-topbar">
                <Checkbox
                  color="primary"
                  className="gx-icon-btn"
                  indeterminate={
                    numberOfSelectedUsers > 0 &&
                    numberOfSelectedUsers < numberOfAllUsers
                  }
                  checked={numberOfSelectedUsers > 0}
                  onChange={this.onAllUserSelect}
                  value="SelectUser"
                />
                {numberOfSelectedUsers > 0 && (
                  <i
                    className="gx-icon-btn icon icon-trash"
                    onClick={this.toggleModal("DeleteUsers")}
                  />
                )}
                {openDeleteUsers && (
                  <DeleteUser
                    open={openDeleteUsers}
                    users={getUsersFromSelected(users, selectedUsers)}
                    selectUsers={selectUsers}
                    closeDeleteMode={this.toggleModal("DeleteUsers")}
                  />
                )}
              </div>
              <CustomScrollbars className="gx-module-content-scroll">
                <UserList
                  loading={loadingUsers}
                  users={users}
                  updateUser={updateUser}
                  selectUser={selectUser}
                  selectUsers={selectUsers}
                  selectedUsers={selectedUsers}
                  allProfiles={allProfiles}
                  loadingAllProfiles={loadingAllProfiles}
                />
                {openCreateUser && (
                  <CreateUser
                    open={openCreateUser}
                    closeCreateMode={this.toggleModal("CreateUser")}
                    createUser={createUser}
                    allProfiles={allProfiles}
                    loadingAllProfiles={loadingAllProfiles}
                  />
                )}
              </CustomScrollbars>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users: extractAllUsers(state),
    loadingUsers: isFetchingUsers(state),
    selectedUsers: extractSelectedUsers(state),
    userSearch: extractUserSearch(state),
    allProfiles: extractAllProfiles(state),
    loadingAllProfiles: isFetchingAllProfiles(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchUsers,
        updateUser,
        createUser,
        selectUser,
        selectUsers,
        fetchAllProfiles,
        filterUsers,
      },
      dispatch
    ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(User);
