export default {
    initial: {
        name: null,
        email: null,
        phoneNumber: null,
        phoneType: null,
        receiveWhatsAppMessage: false,
        contactType: null,
        documentNumber: null,
        hasDocumentNumber: null
    },
    general: {
        employeesAmount: null,
        averageAgeEmployees: null,
        capitalInsuredPerson: null,
        reasonTakeInsurance: null,
        isPublicCompany: false,
        hasBiddingWaiver: false
    },
    coverages: {
        coverages: [],
        assistances: []
    },
    customer: {
        documentNumber: null,
        type: null,
        name: null,
        zipCode: null,
        address: null,
        number: null,
        neighborhood: null,
        state: null,
        city: null,
        additionalData: null
    },
    details: {
        capitalType: null,
        insurableGroups: []
    },
    insured: {
        insuredLives: [],
        additionalData: null
    },
    files: []
}