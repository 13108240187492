import MaskService from "react-masked-text/src/mask-service";

export const currencies = {
  BRA: "R$",
  USD: "US$",
  EUR: "€",
};

export const inputMasks = {
  INDIVIDUAL: "999.999.999-99",
  COMPANY: "99.999.999/9999-99",
  zipCode: "99.999-999",
  date: "99/99/9999",
  mobileNumber: "(99) 99999-9999",
  phoneNumber: "(99) 9999-9999",
};

export function formatMaskInput(str) {
  if (str) {
    if (str.match(/[[\].!'@,><|://\\;&*()_+=-]/g)) {
      return str.replace(/[[\].!'@,><|://\\;&*()_+=-]/g, "");
    }
    return str.match(/[0-9]+/g).join("");
  } else return null;
}

export function formatPhone(phone) {
  return phone ? phone.replace(/[^0-9]/gi, "") : null;
}

export const formatPercentage = (value) => {
  if (value) {
    let replaced = JSON.stringify(value).replace(".", ",") + "%";
    return replaced;
  }
};

export function parseAmount(value) {
  let regex, result;
  regex = /[.$ \s]/g;
  result = value.replace(regex, "");
  regex = /,/g;
  result = result.replace(regex, ".");
  return parseFloat(result);
}

export function formatMoney(value, currency = "BRA") {
  if (value !== null) {
    value = MaskService.toMask("money", parseFloat(value).toFixed(2), {
      unit: "",
      separator: ",",
      delimiter: ".",
    });
  }
  return `${currencies[currency]} ${value} `;
}

export function formatAmountValue(value) {
  if (value && typeof value === "object") {
    return value.floatValue;
  } else {
    if (!value) return null;
    return value;
  }
}

export const normalizeCurrency = (num) => {
  if (num) {
    num = num.toString();
    const regex = /\d{1,3}(?:\.\d{3})*([,.]\d*|)/,
      numLength = num.length,
      hasSymbol = num.match(/[$€]/),
      hasDot = num.search("\\."),
      hasComma = num.search(",");
    if (hasSymbol) {
      num = num.match(regex) ? num.match(regex)[0] : num;
    }
    if (
      !(
        hasDot === numLength - 3 ||
        hasDot === numLength - 2 ||
        hasComma === -1
      ) ||
      hasSymbol
    ) {
      num = parseFloat(num.split(".").join("").replace(",", ".")).toFixed(2);
    } else {
      num = parseFloat(num).toFixed(2);
    }
    return num;
  }
};

export const sortSimulations = (simulations) => {
  if (simulations) {
    return [...simulations].sort((a, b) => {
      if (a.premium === b.premium) {
        if (a.rate < b.rate) {
          return -1;
        } else if (a.rate > b.rate) {
          return 1;
        } else {
          return 0;
        }
      } else if (a.premium < b.premium) {
        return -1;
      } else if (a.premium > b.premium) {
        return 1;
      } else if (a.rate === undefined) {
        return 1;
      } else if (b.rate === undefined) {
        return -1;
      } else {
        return 0;
      }
    });
  } else {
    return {};
  }
};
