import axios from 'axios';
import { notification } from "antd";

export const uploadConfig = (handleFileChange, handleProgress, extraParam, method = "POST") => {
  const setExtras = (formData) => {
    const json = JSON.stringify(extraParam)
    const blob = new Blob([json],{
      type: 'application/json'
    })
    return formData.append('request',blob)
  }
  return {
    name: 'file',
    multiple: true,
    headers: {
      'Content-type': 'multipart/form-data',
    },
    showUploadList: {
      showPreviewIcon: false, 
      showDownloadIcon: false, 
      showRemoveIcon: false
    },
    customRequest: option => {
      const { action, file, headers, onSuccess, onError, onProgress } = option;
      const formData = new FormData();
      formData.append('file', file);
      if(extraParam){
        setExtras(formData);
      }
      const config = {
        onUploadProgress: ({ total, loaded }) => {
          onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) }, file);
        },
        headers: headers,
      };
      if (method === "PUT") {
        axios
        .put(action, formData, config)
        .then(({ data: response }) => {
          notification.success({
            message: `'${file.name}' enviado com sucesso`
          });
          return onSuccess(response,file)
        })
        .catch(err => {
          notification.error({
            message: `Erro no envio de '${file.name}'`,
            description: err.response ? err.response.data.message : 'Erro desconhecido'
          });
          return onError(err)
        });
      } else {
        axios
        .post(action, formData, config)
        .then(({ data: response }) => {
          notification.success({
            message: `'${file.name}' enviado com sucesso`
          });
          return onSuccess(response,file)
        })
        .catch(err => {
          notification.error({
            message: `Erro no envio de '${file.name}'`,
            description: err.response ? err.response.data.message : 'Erro desconhecido'
          });
          return onError(err)
        });
      }
    },
    onChange: handleFileChange,
    onProgress: ({ percent },file) => {
      notification.info({
        key: 'progress',
        message: `Enviando '${file.name}'...`
      });
      handleProgress && handleProgress(percent, file);
    }
  }
};

export const downloadFile = (fileUrl, fileName) => {
  // for non-IE
  if (!window.ActiveXObject) {
      var save = document.createElement("a");
      save.href = fileUrl;
      save.target = "_blank";
      var filename = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
      save.download = fileName || filename;
      if (
          navigator.userAgent
              .toLowerCase()
              .match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search("Chrome") < 0
      ) {
          document.location = save.href;
          // window event not working here
      } else {
          var evt = new MouseEvent("click", {
              view: window,
              bubbles: true,
              cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
      }
  }

  // for IE < 11
  else if (!!window.ActiveXObject && document.execCommand) {
      var _window = window.open(fileUrl, "_blank");
      _window.document.close();
      _window.document.execCommand("SaveAs", true, fileName || fileUrl);
      _window.close();
  }
};