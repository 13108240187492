import React from "react";
import { Select } from "antd";

export function formatSelectValue(entry) {
  if (Array.isArray(entry)) {
    if (entry.length !== 0) {
      return entry;
    } else {
      return undefined;
    }
  }
  if (entry) return entry;
  else return undefined;
}

export function formatErrors(err) {
  let formatedErrors = {};
  err.forEach((item) => {
    formatedErrors[item.field] = item.message;
  }, {});
  return { errors: formatedErrors };
}

export function prepareOptions(options) {
  return options.map((option, index) => {
    return (
      <Select.Option value={option.value} key={index}>
        {option.label}
      </Select.Option>
    );
  });
}

export function filterSelectionOptions(input, option) {
  return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
}

export const getAcceptedInsurer = (quotations) => {
  let acceptedQuotation;
  quotations.forEach((quotation) => {
    if (quotation.accepted) {
      acceptedQuotation = quotation.insurer;
    }
  });

  return acceptedQuotation;
};
