import React from "react";
// prop types
import PropTypes from "prop-types";
//redux
import { useDispatch, useSelector } from "react-redux";
import { setInvoiceMarkAsPaid } from "../../../../../store/slices/invoice";
// translation
import { useTranslation } from "react-i18next";
// moment
import moment from "moment";
// components
import { Card, Modal, Row, Spin, DatePicker, Checkbox, Button } from "antd";
import { FormField, LabelField } from "components";

const InvoicePaymentModal = ({ visible, setModalVisible, onMarkAsPaid }) => {
  const {
    invoice: { invoice },
    common: { isLoading },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const confirmCloseModal = () => {
    setModalVisible(false);
  };

  const onCloseModal = () => {
    const modal = Modal.confirm({
      maskClosable: true,
      title: translation("invoice.markAsPaidModal.closeConfirmation.message"),
      onOk: confirmCloseModal,
      onCancel: () => modal.destroy(),
      okText: translation("customers.policyDeleteConfirmButtonLabel"),
      cancelText: translation("customers.policyDeleteCancelButtonLabel"),
    });
  };

  const handleDateChange = (date, dateString) => {
    const paidAt = moment(dateString).format("YYYY-MM-DD");
    dispatch(setInvoiceMarkAsPaid({ paidAt }));
  };

  const handleCheck = (event) => {
    dispatch(
      setInvoiceMarkAsPaid({
        recoveredByCollectionProcess: event.target.checked,
      })
    );
  };

  const data = {
    paidAt: invoice.paidAt,
    recoveredByCollectionProcess: invoice.recoveredByCollectionProcess,
  };

  const handleDisabledDate = (current) => {
    let endDate = moment();
    return current > endDate;
  };

  const handleConfirmInvoicePayment = () => {
    onMarkAsPaid(data);
    confirmCloseModal();
  };

  return (
    <Modal
      width={480}
      footer={null}
      centered={true}
      visible={visible}
      onCancel={onCloseModal}
      wrapClassName="invoice-payment-modal-container"
    >
      <Spin spinning={isLoading}>
        <Card style={{ marginTop: "32px" }}>
          <Row style={{ justifyContent: "center" }}>
            <h3>{translation("invoice.markAsPaidModal.title")}</h3>
          </Row>
          <Row>
            <LabelField
              label={translation(
                "invoice.markAsPaidModal.dueDateSelection.label"
              )}
            >
              <FormField>
                <DatePicker
                  onChange={handleDateChange}
                  disabledDate={handleDisabledDate}
                />
              </FormField>
            </LabelField>
          </Row>
          <Row>
            <LabelField
              label={translation(
                "invoice.markAsPaidModal.billingRoutine.label"
              )}
            >
              <FormField>
                <Checkbox
                  checked={invoice.recoveredByCollectionProcess || false}
                  onChange={(e) => handleCheck(e)}
                />
              </FormField>
            </LabelField>
          </Row>
          <Row style={{ marginTop: "16px", justifyContent: "center" }}>
            <Button onClick={onCloseModal}>
              {translation("invoice.markAsPaidModal.cancelButton.label")}
            </Button>
            <Button type="primary" onClick={handleConfirmInvoicePayment}>
              {translation("invoice.markAsPaidModal.confirmButton.label")}
            </Button>
          </Row>
        </Card>
      </Spin>
    </Modal>
  );
};

InvoicePaymentModal.propTypes = {
  visible: PropTypes.bool,
  setModalVisible: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
};

export default InvoicePaymentModal;
