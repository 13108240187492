import React, {Component} from 'react'
//Translation
import Translation from './translation'
//React Router Dom lib
import {Prompt} from 'react-router-dom'
//Components
import {Modal} from 'antd'

export class RouteLeavingGuard extends Component {
    state = {
        modalVisible: false,
        lastLocation: null,
        confirmedNavigation: false,
    };

    showModal = (location) => {
        this.setState({
            modalVisible: true,
            lastLocation: location,
        });
    };

    closeModal = (callback, confirmed = false) => {
        this.setState({
            modalVisible: false
        }, () => {
            if (confirmed)
                callback();
        });
    };

    handleBlockedNavigation = (nextLocation) => {
        const {confirmedNavigation} = this.state;
        const {shouldBlockNavigation} = this.props;
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            this.showModal(nextLocation);
            return false
        }
        return true
    };

    handleConfirmNavigationClick = () => {
        this.closeModal(
            () => {
                const {navigate} = this.props;
                const {lastLocation} = this.state;
                if (lastLocation) {
                    this.setState({
                        confirmedNavigation: true
                    }, () => {
                        // Navigate to the previous blocked location with your navigate function
                        navigate(lastLocation.pathname)
                    })
                }
            }, true);
    };

    render() {
        const {when, children} = this.props;
        const {modalVisible} = this.state;
        return (
            <>
                <Prompt
                    when={when}
                    message={this.handleBlockedNavigation}/>
                <Modal
                    visible={modalVisible}
                    centered={true}
                    closable={false}
                    title={null}
                    okText={Translation.confirm}
                    onCancel={this.closeModal}
                    onOk={this.handleConfirmNavigationClick}>
                    {children}
                </Modal>
            </>
        )
    }
}

export default RouteLeavingGuard