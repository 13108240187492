import React from "react";

import { useSelector } from "react-redux";
import { Row, Spin } from "antd";
import { CustomersList, CustomersFilter } from "components";
import translation from "./translation";

import "./styles.less";

const Customers = ({ children }) => {
  const { isFetching } = useSelector((state) => state.crm.customers);
  return (
    <div className="gx-main-content gx-mt-4">
      <div className="gx-app-module">
        <div className="customer-container">
          <div className="gx-module-box-content">
            {children || (
              <Spin
                spinning={isFetching}
                tip={translation.fetchingCustomersLabel}
                className="usersList-spinWrapper"
              >
                <Row type="flex" className="filter-row">
                  <CustomersFilter></CustomersFilter>
                  <CustomersList></CustomersList>
                </Row>
              </Spin>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
