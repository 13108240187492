import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import usersSagas from './Users';
import crmSaga from './Crm';
import commonSagas from './Common';

export default function* rootSaga(getState) {
    yield all([
        authSagas(),
        usersSagas(),
        commonSagas(),
        crmSaga()
    ]);
}
