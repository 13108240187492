//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("multipleSearch");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    contactNameLabel: getTranslation("contactNameLabel"),
    contactNamePlaceholder: getTranslation("contactNamePlaceholder", false, true),

    companyNameLabel: getTranslation("companyNameLabel"),
    companyNamePlaceholder: getTranslation("companyNamePlaceholder", false, true),

    documentNumberLabel: getTranslation("documentNumberLabel"),
    documentNumberPlaceholder: getTranslation("documentNumberPlaceholder", false, true),

    emailLabel: getTranslation("emailLabel"),
    emailPlaceholder: getTranslation("emailPlaceholder", false, true),

    phoneNumberLabel: getTranslation("phoneNumberLabel"),
    phoneNumberPlaceholder: getTranslation("phoneNumberPlaceholder", false, true)
}