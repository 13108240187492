import React, { useState, useEffect } from "react";
// router
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Modal, Spin, Card } from "antd";
// redux
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerData } from "../../../../../store/slices/customer";
import {
  fetchPolicyData,
  fetchPolicyExpirationData,
  fetchManyNotificationHistory,
  fetchSMSManyNotificationHistory,
  clearNotificationHistory
} from "../../../../../store/slices/policy";
// components
import { HistoryHeader, PolicyNotificationTable } from "../../../../../components/Customers/Policy/PolicyNotificationsHistory";
// styles
import "./styles.less";

const PolicyNotificationsHistoryModal = () => {
  const [visible, setVisible] = useState(true);
  const { common: { isLoading } } = useSelector((state) => state);
  const { policy: policyData, notificationHistory } = useSelector((state) => state.policy);
  const { registerData } = useSelector((state) => state.customer);
  const { t: translation } = useTranslation();
  const { id, policy: policyId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleCancel = () => {
    setVisible(false);
    history.push(
      `/crm/customers/${id}/policy/${policyId}`
    );
  };

  const getEmailHashList = (data) => {
    let hashList = [];
    const newArray = data?.map((item) => {
      if (item?.notificationType === "EMAIL") {
        hashList.push({
          hash: item?.emailIdentification,
          hashType: item?.template,
          type: item?.notificationType
        });
      }
    });
    return hashList;
  };

  const getSMSNotificationList = (data) => {
    let SMSNotifications = [];
    const newArray = data?.map((item) => {
      if (item?.notificationType === "SMS") {
        SMSNotifications.push({
          type: item?.notificationType,
          hashType: item?.template,
          hash: item?.emailIdentification,
          event: "PROCESSED",
        });
      }
    });
    return SMSNotifications;
  };

  useEffect(() => {
    dispatch(fetchCustomerData(id));
    dispatch(fetchPolicyData(id, policyId));
    dispatch(fetchPolicyExpirationData(`policyId=${policyId}`)).then(({ data }) => {
      dispatch(fetchSMSManyNotificationHistory(getSMSNotificationList(data?.content)));
      dispatch(fetchManyNotificationHistory(getEmailHashList(data?.content)));
    });
  }, []);
  
  useEffect(() => {
    return () => {
      dispatch(clearNotificationHistory());
    }
  }, [])

  return (
    <Modal
      width={960}
      visible={true}
      footer={null}
      centered={true}
      wrapClassName="history-modal"
      onCancel={handleCancel}
    >
      <Spin spinning={isLoading}>
        <Card className="gx-mt-5 gx-p-3">
          <HistoryHeader registerData={registerData} policy={policyData} />
          <Card className="gx-mt-3 gx-p-3">
            <h5 className="gx-mb-3">
              {translation("policy.notificationHistory.title")}
            </h5>
            {notificationHistory.length > 0 && (
              <h5 className="gx-mb-3">
                {translation("policy.notificationHistory.label")}
              </h5>
            )}
            <PolicyNotificationTable dataSource={notificationHistory} hasHeader={true} />
          </Card>
        </Card>
      </Spin>
    </Modal>
  );
};

export default PolicyNotificationsHistoryModal;
