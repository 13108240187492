import React, { useEffect } from "react";
// propo types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Modal, Row, Col, Select, Input, Upload, Button, Spin } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// components
import FormField from "../../../FormField";
import ExtensionEndorsement from "./ExtensionEndorsement";
import UpdateEndorsement from "./UpdateEndorsement";
import CancelEndorsement from "./CancelEndorsement";
// helpers
import { getErrorMessage } from "../../../../helpers";
// styles
import "./styles.less";
// contants
import {
  endorsementTypes,
  endorsementChangeTypes,
} from "../../../../constants";

const CreateEndorsemetModal = ({
  visible,
  data = {},
  policy = {},
  errors = [],
  hasFile,
  changeType = "",
  loading,
  onChange,
  onCancel,
  onSave,
  onCancelModal,
  uploadFileProps,
  onClear,
  setData
}) => {
  const { t: translation } = useTranslation();

  const handleChange = (field, e) => {
    return onChange && onChange(field, e);
  };

  const handleCancelModal = () => {
    return onCancelModal && onCancelModal();
  };

  const handleCancel = () => {
    return onCancel && onCancel();
  };

  const handleSave = () => {
    return onSave && onSave();
  };

  useEffect(() => {
    return () => onClear && onClear();
  }, []);

  return (
    <Modal
      closable
      maskClosable
      destroyOnClose
      width={800}
      footer={null}
      visible={visible}
      className="create-endorsement-modal"
      onCancel={handleCancelModal}
    >
      <Spin spinning={loading}>
        <Row type="flex">
          <Col sm={24} md={12}>
            <FormField
              label={translation("endorsement.createEndorsement.typeLabel")}
            >
              <Select
                className="gx-w-100"
                placeholder={translation(
                  "endorsement.createEndorsement.typePlaceholder"
                )}
                value={data.type}
                onChange={(e) => handleChange("type", e)}
              >
                {endorsementTypes.map((type) => (
                  <Select.Option key={type.value} value={type.value}>
                    {type.label}
                  </Select.Option>
                ))}
              </Select>
            </FormField>
          </Col>
        </Row>
        {data.type === endorsementTypes[0].value && (
          <CancelEndorsement data={data} errors={errors} onChange={onChange} />
        )}
        {data.type === endorsementTypes[1].value && (
          <UpdateEndorsement
            data={data}
            policy={policy}
            errors={errors}
            onChange={onChange}
          />
        )}
        {data.type === endorsementTypes[2].value && (
          <ExtensionEndorsement
            data={data}
            policy={policy}
            errors={errors}
            onChange={onChange}
            setData={setData}
          />
        )}
        {data.type === endorsementTypes[1].value &&
          changeType === endorsementChangeTypes.other && (
            <Row type="flex">
              <Col xs={24} md={24}>
                <FormField
                  label={translation(
                    "endorsement.createEndorsement.changeLabel"
                  )}
                  error={getErrorMessage("alteration", errors)}
                >
                  <Input
                    className="gx-w-100"
                    value={data.alteration}
                    placeholder={translation(
                      "endorsement.createEndorsement.changePlaceholder"
                    )}
                    onChange={(e) => onChange("alteration", e)}
                  />
                </FormField>
              </Col>
            </Row>
          )}
        {data.type && (
          <>
            <Row type="flex">
              <Col xs={24} md={24}>
                <FormField
                  label={translation(
                    "endorsement.createEndorsement.additionalDataLabel"
                  )}
                >
                  <Input.TextArea
                    className="gx-w-100"
                    value={data.observation}
                    placeholder={translation(
                      "endorsement.createEndorsement.additionalDataPlaceholder"
                    )}
                    onChange={(e) => onChange("observation", e)}
                  />
                </FormField>
              </Col>
            </Row>
            <Row type="flex" className="gx-mt-3">
              <Col xs={24} md={8}>
                <FormField>
                  <Upload {...uploadFileProps}>
                    <Button className="gx-m-0" disabled={hasFile}>
                      <UploadOutlined />{" "}
                      {translation(
                        "endorsement.createEndorsement.uploadButton"
                      )}
                    </Button>
                  </Upload>
                </FormField>
              </Col>
            </Row>
            <Row type="flex" justify="end" className="gx-mt-3">
              <Button
                ghost
                type="primary"
                className="gx-m-0 gx-mr-3"
                onClick={handleCancel}
              >
                {translation("endorsement.createEndorsement.cancelButton")}
              </Button>
              <Button
                type="primary"
                className="gx-m-0 gx-mr-3"
                onClick={handleSave}
              >
                {translation("endorsement.createEndorsement.saveButton")}
              </Button>
            </Row>
          </>
        )}
      </Spin>
    </Modal>
  );
};

CreateEndorsemetModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  errors: PropTypes.array,
  data: PropTypes.object,
  policy: PropTypes.object,
  changeType: PropTypes.string,
  hasFile: PropTypes.bool,
  loading: PropTypes.bool,
  uploadFileProps: PropTypes.object,
  onChange: PropTypes.func,
  onCancelModal: PropTypes.func,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onClear: PropTypes.func,
  setData: PropTypes.func
};

export default CreateEndorsemetModal;
