import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Table, Tag } from "antd";
// helpers
import { formatDateTime } from "../../../../helpers";
// cutoms styles
import "./styles.less";
// contants
import { emailEvents } from "../../../../constants";

const PolicyNotificationTable = ({ dataSource, hasHeader, ...props }) => {
  const { t: translation } = useTranslation();

  const getEmailEventTag = (emailEvent) => {
    const tagData = emailEvents.find((event) => event.value === emailEvent);
    return tagData && (
      <Tag className="gx-m-0" color={tagData.color}>
        {tagData.label}
      </Tag>
    );
  };

  const policyNotificationInterval = {
    EXPIRATION_30: translation("policy.notificationHistory.template30"),
    EXPIRATION_15: translation("policy.notificationHistory.template15"),
    EXPIRATION_7: translation("policy.notificationHistory.template7"),
    EXPIRATION_1: translation("policy.notificationHistory.template1"),
  }

  const columns = [
    {
      title: translation("policy.notificationHistory.tableType"),
      dataIndex: "type",
      key: "type",
      align: "center",
      width: "auto",
      render: (text, record) => text,
    },
    {
      title: translation("policy.notificationHistory.tableDescription"),
      dataIndex: "description",
      key: "description",
      align: "center",
      width: "auto",
      render: (text, record) => policyNotificationInterval[text],
    },
    {
      title: translation("policy.notificationHistory.tableContact"),
      dataIndex: "contact",
      key: "contact",
      width: "auto",
      render: (text, record) => text,
    },
    {
      title: translation("policy.notificationHistory.tableStatus"),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: "auto",
      render: (text, record) => getEmailEventTag(text),
    },
    {
      title: translation("policy.notificationHistory.tableDateAndTime"),
      dataIndex: "date",
      key: "date",
      align: "center",
      width: "25%",
      render: (text, record) => formatDateTime(text),
    },
  ];

  const formatDataSource = () => {
    const currentDataSource = [...dataSource] || [];
    let preFilterData = [];
    let newDataSource = [];
    preFilterData = currentDataSource?.sort((a, b) => {
      return new Date(b.occurredAt) - new Date(a.occurredAt);
    });

    newDataSource = currentDataSource?.map((item) => {
      return {
        type: item?.type,
        description: item?.hashType,
        contact: item?.email || item?.phoneNumber,
        status: item?.event,
        date: item?.occurredAt,
      };
    });

    return newDataSource;
  };

  return (
    <>
      {formatDataSource()?.length > 0 ? (
        <Table
          bordered
          columns={columns}
          dataSource={formatDataSource()}
          pagination={false}
          className={`gx-table-responsive ${!hasHeader && "no-list-header"}`}
          {...props}
        />
      ) : (
        <Row type="flex" justify="center">
          <Tag color="blue">
            {translation("invoice.historyItem.emptyLabel")}
          </Tag>
        </Row>
      )}
    </>
  );
};

PolicyNotificationTable.propTypes = {
  dataSource: PropTypes.array,
  hasHeader: PropTypes.bool
};

export default PolicyNotificationTable;
