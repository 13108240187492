import React from 'react';
// import { Modal, Radio } from "antd";
//Custom helpers
import { 
  normalizeCurrency,
  formatMoney
} from "helpers";
import "./style.less"

// import { DetailsTable } from "./DetailsTable"
import { getScenarioValues } from "./helpers"

export const CargoOpenPolicySimulation = (data) => {
  	// const [visible, setvisible] = React.useState(false);
	// const [scenario, setScenario] = useState("BEST");

	const { simulationData, monthlyMovement } = data;
	if(simulationData){
		const { scenarios } = simulationData;
		const minimum = getScenarioValues(scenarios, 'minimum');
		const additionalMinimumPremium = getScenarioValues(scenarios, 'additionalMinimumPremium');
		const prices = getScenarioValues(scenarios, 'premium');
		// const showModal = (modals) => {
		// 	if(modals){
		// 		const content = modals.map(modal => {
		// 			const { type, routes, scenarios } = modal;
		// 			if(type === "NATIONAL_CARRYING_ROAD") {
		// 				if(routes[0].origin && routes[0].destination){
		// 					return true;
		// 				}
		// 			} else {
		// 				if(scenarios) {
		// 					return true;
		// 				}
		// 			}
		// 			return false;
		// 		});
		// 		return content.includes(true);
		// 	}
		// 	return false;
		// }
		return (
			<>
				{prices ? 
					prices.BEST ? 
						<>
							<div className="socketSimulation">
								<span className="disclaimer">Os valores apresentados são uma faixa de preço e consideram apenas uma taxa média e coberturas obrigatórias.</span>
								<div className="row">
									{ simulationData.minimumPremiumScenario && 
										<div className="column">
											<div className="title">Fatura estimada</div>
											<div className="cardValues">
												<div className="values bestValue">{formatMoney(normalizeCurrency(prices.BEST))} <span className="byMonth">/mês</span></div>
												<div className="sign">a</div>
												<div className="values worstValue">{formatMoney(normalizeCurrency(prices.WORST))} <span className="byMonth">/mês</span></div>
												<div className="desc">
													Você paga a fatura mínima se movimentar até <span className="value">{formatMoney(normalizeCurrency(simulationData.maxAmountMinimumPremium))}</span> no mês
												</div>
											</div>
										</div>
									}

									{!simulationData.minimumPremiumScenario ? 
										<>
											<div className="column">
												<div className="title">Prêmio mínimo</div>
												<div className="cardValues">
													<div className="values bestValue">{formatMoney(normalizeCurrency(minimum.BEST))} <span className="byMonth">/mês</span></div>
													<div className="sign">a</div>
													<div className="values worstValue">{formatMoney(normalizeCurrency(minimum.WORST))} <span className="byMonth">/mês</span></div>
													<div className="desc">
														Você paga a fatura mínima se movimentar até <span className="value">{formatMoney(normalizeCurrency(simulationData.maxAmountMinimumPremium))}</span> no mês
													</div>
												</div>
											</div>
											<div className="signCol">
												<div className="sign">+</div>
											</div>
											<div className="column">
												<div className="title">Movimentação adicional</div>
												<div className="cardValues">
													<div className="values bestValue">{formatMoney(normalizeCurrency(additionalMinimumPremium.BEST))} <span className="byMonth">/mês</span></div>
													<div className="sign">a</div>
													<div className="values worstValue">{formatMoney(normalizeCurrency(additionalMinimumPremium.WORST))} <span className="byMonth">/mês</span></div>
													<div className="desc">Movimentação estimada em <span className="value">{formatMoney(normalizeCurrency(monthlyMovement))}</span> no mês</div>
												</div>
											</div>
											<div className="signCol">
												<div className="sign">=</div>
											</div>
											<div className="column final">
												<div className="title">Fatura estimada</div>
												<div className="cardValues">
													<div className="values bestValue">{formatMoney(normalizeCurrency(prices.BEST))} <span className="byMonth">/mês</span></div>
													<div className="sign">a</div>
													<div className="values worstValue">{formatMoney(normalizeCurrency(prices.WORST))} <span className="byMonth">/mês</span></div>
													<div className="desc">Sua fatura para uma movimentação de <span className="value">{formatMoney(normalizeCurrency(monthlyMovement))}</span> no mês</div>
												</div>
											</div>
										</>
										:
										<div className="lowerThanMinimun">
											<div className="title">
												Dica para maximizar os ganhos de sua empresa
											</div>
											<div className="content">
												Baseado na sua movimentação de <span className="value">{formatMoney(normalizeCurrency(monthlyMovement))}</span>/mês, você ainda pode transportar mais <span className="value">{formatMoney(normalizeCurrency(simulationData.overbalanceAmountMinimumPremium))}</span>/mês sem aumentar seu custo de seguro*
											</div>
										</div>
									}
								</div>
							</div>
							{/* {	modals && 
								showModal(modals) &&
								<>
									<div className="details d-only" onClick={() => setvisible(true)}>Veja mais detalhes</div>
									<div className="details m-only">Para ver mais detalhes, acesse a versão desktop</div>
									<Modal
								        visible={visible}
								        width="70%"
								        footer={null}
								        onCancel={() => setvisible(false)}
								        className="simulation-modal"
								    >
										<div className="title center">Clique nos cenários abaixo para entender como a estimativa foi calculado</div>
										<div className="btn-group">
						                  <Radio.Group
						                    buttonStyle="solid"
						                    defaultValue={scenario}
						                    onChange={(e) => {
						                    	setScenario(e.target.value);
						                    }}
						                    >
						                    <Radio.Button value="BEST">Melhor cenário</Radio.Button>
						                    <Radio.Button value="WORST">Pior cenário</Radio.Button>
						                  </Radio.Group>
										</div>
										<DetailsTable 
											modals={modals}
											scenario={scenario}
											/>
								    </Modal>
							    </>
							} */}
						</>
					: null
				: null
				}
			</>
		)
	}
	return null;
}