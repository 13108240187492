import React from "react";
import {Scrollbars} from "react-custom-scrollbars";

const CustomScrollbars = (props) => <Scrollbars autoHide={props.autoHide}
                                                {...props}
                                                renderTrackHorizontal={props => <div {...props} className="track-horizontal"/>}/>;

export default CustomScrollbars;
CustomScrollbars.defaultProps = {
    autoHide: true
};