import React, {Component} from "react";
import config from './config';
//Translation 
import Translation from './translation';
//Custom Css
import './Styles.less'
//Components
import {Tabs, Button, Card, Empty, Row, Col, Select, Tooltip} from "antd";
import {LngInput, MaskedInput} from "components";
import {cloneArray, formatMaskInput} from "../../helpers";
import produce from "immer";
//Constants
const {TabPane} = Tabs;
const {Option} = Select;

const HeaderContent = (props) => {
    const {activeContact, onCreate, onRemove} = props;
    const validRemove = !(activeContact === "0-currentContacts");

    return (
        <section>
            {validRemove ?
                <Tooltip placement="bottom" title={Translation.contactRemove}>
                    <Button onClick={onRemove} icon="minus" className="gx-btn-outline-danger" htmlType="button">
                        {Translation.remove}
                    </Button>
                </Tooltip> :
                <Button disabled icon="minus" htmlType="button">
                    {Translation.remove}
                </Button>
            }
            <Tooltip placement="bottom" title={Translation.contactAdd}>
                <Button icon="plus" onClick={onCreate} className="gx-btn-outline-primary" htmlType="button">
                    {Translation.add}
                </Button>
            </Tooltip>
        </section>
    )
};

const PhoneAction = (props) => {
    const {phones, phoneIndex, type, phonesRef, showDeleteAction, onChange} = props;
    let action, onPhoneClick, title;

    function onRemove() {
        const updatedPhones = phones.filter((phone, index) => index !== phoneIndex);
        onChange(updatedPhones);
    }

    function scrollToBottom() {
        if (phonesRef && phonesRef.current)
            phonesRef.current.scrollTop = phonesRef.current.scrollHeight;
    }

    function onAdd() {
        const updatedPhones = [...phones];
        updatedPhones.push(Contacts.emptyPhone());
        onChange(updatedPhones, scrollToBottom);
    }

    if (showDeleteAction) {
        onPhoneClick = onRemove;
        action = "delete";
        title = config[type] && config[type].tooltipTitle;
    } else {
        onPhoneClick = onAdd;
        action = "plus";
        title = Translation.contactAddNumber
    }

    return (
        <Tooltip placement="right" title={title}>
            <Button onClick={onPhoneClick}
                    shape="circle-outline"
                    className={`phone-items-${action}-btn`}
                    icon={action} htmlType="button"/>
        </Tooltip>
    )
};

const PhoneContent = (props) => {
    const {type, value, phones, phoneIndex, onChange} = props;
    const mask = config[type] && config[type].mask;

    const updatePhone = (attr) => (event) => {
        let value = event;
        if (typeof event === "object")
            value = value.target.value;
        const updatedPhones = [...phones];
        const phone = updatedPhones[phoneIndex];
        phone[attr] = value;
        onChange(updatedPhones)
    };

    return (
        <Row type="flex" gutter={8}>
            <Col xs={24} md={8}>
                <Select placeholder={Translation.contactSelectPhoneType} value={type} className="gx-w-100" onChange={updatePhone("type")}>
                    {Object.values(config).map(entry => {
                        const {type, label} = entry;
                        return (<Option key={type} value={type}>{label}</Option>)
                    })}
                </Select>
            </Col>
            <Col xs={24} md={16}>
                <MaskedInput value={value}
                             disabled={!type}
                             placeholder={Translation.contactPhoneNumberPlaceholder}
                             onChange={updatePhone("phoneNumber")}
                             mask={mask}/>
            </Col>
        </Row>
    )
};

const ContactContent = (props) => {
    const {onChange, contact, phonesRef} = props;
    const {name, email, phones} = contact;
    return (
        <section>
            <Row type="flex" align="middle" gutter={8}>
                <Col xs={24} md={5}>{Translation.contactNameLabel}:</Col>
                <Col xs={24} md={19}>
                    <LngInput value={name}
                              onChange={onChange("name")}
                              placeholder={Translation.contactNamePlaceholder}/>
                </Col>
            </Row>
            <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
                <Col xs={24} md={5}>{Translation.contactEmailAddressLabel}:</Col>
                <Col xs={24} md={19}>
                    <LngInput value={email}
                              onChange={onChange("email")}
                              placeholder={Translation.contactEmailAddressPlaceholder}
                              type="email"/>
                </Col>
            </Row>
            <div ref={phonesRef} className="phone-items">
                {phones.map((phoneContact, phoneIndex) => {
                    const {type, phoneNumber} = phoneContact;
                    const showDeleteAction = phoneIndex !== 0;

                    function getRowClassName() {
                        let rowClassName;
                        if (showDeleteAction) {
                            rowClassName = "gx-mt-3";
                        } else {
                            if (phones.length > 4)
                                rowClassName = "phone-items-sticky"
                        }
                        return rowClassName;
                    }

                    return (
                        <Row key={phoneIndex} type="flex" gutter={8} className={getRowClassName()}>
                            <Col xs={24} lg={22}>
                                <PhoneContent phones={phones}
                                              phoneIndex={phoneIndex}
                                              onChange={onChange("phones")}
                                              value={phoneNumber}
                                              type={type}/>
                            </Col>
                            <Col xs={24} lg={2}>
                                <PhoneAction showDeleteAction={showDeleteAction}
                                             phones={phones}
                                             phoneIndex={phoneIndex}
                                             phonesRef={phonesRef}
                                             value={phoneNumber}
                                             type={type}
                                             onChange={onChange("phones")}/>
                            </Col>
                        </Row>
                    )
                })}
            </div>
        </section>
    )
};

class Contacts extends Component {
    constructor(props) {
        super(props);
        const {principalContact, additionalContacts} = props;
        this.phonesRef = React.createRef();
        this.state = Contacts.emptyState(principalContact, additionalContacts);
    }

    static emptyState(principalContact, additionalContacts) {
        let formattedContact = Contacts.formatContact(principalContact);
        let updatedCurrentContacts = [formattedContact];
        if (additionalContacts)
            additionalContacts.forEach(additionalContact => {
                formattedContact = Contacts.formatContact(additionalContact);
                updatedCurrentContacts.push(formattedContact)
            });
        return {
            activeContact: "0-currentContacts",
            currentContacts: updatedCurrentContacts,
            newContacts: []
        }
    }

    static formatContact(contact) {
        function getPhones() {
            if (contact.phones)
                return cloneArray(contact.phones, "object");
            else
                return [Contacts.emptyContact()];
        }

        return {
            name: contact.name || '',
            email: contact.email || '',
            phones: getPhones()
        }
    }

    static emptyContact() {
        return {
            name: '',
            email: '',
            phones: [Contacts.emptyPhone()]
        }
    }

    static emptyPhone() {
        return {
            type: undefined,
            phoneNumber: '',
            created: true
        }
    }

    static parseContact(contact) {
        return produce(contact, draft => {
            if (draft.hasOwnProperty("phones")) {
                draft.phones.forEach(phone => {
                    phone.phoneNumber = formatMaskInput(phone.phoneNumber);
                })
            }
        });
    }

    static parseContacts(contacts) {
        return contacts.map(Contacts.parseContact);
    }

    reset = () => {
        const {principalContact, additionalContacts} = this.props;
        const updatedState = Contacts.emptyState(principalContact, additionalContacts);
        this.setState(updatedState);
    };


    getChanges = () => {
        const {currentContacts, newContacts} = this.state;
        const [principalContact, ...others] = currentContacts;
        const parsedOtherContacts = Contacts.parseContacts(others);
        const parsedNewContacts = Contacts.parseContacts(newContacts);
        return {
            principalContact: Contacts.parseContact(principalContact),
            additionalContacts: [...parsedOtherContacts, ...parsedNewContacts]
        }
    };

    createContact = () => {
        this.setState(prevState => {
            const previousContacts = prevState.newContacts;
            const updatedActiveContact = `${previousContacts.length}-newContacts`;
            const updatedContacts = [...previousContacts];
            updatedContacts.push(Contacts.emptyContact());
            return {
                newContacts: updatedContacts,
                activeContact: updatedActiveContact
            }
        }, this.notifyChanges);
    };

    onChange = activeContact => {
        if (typeof activeContact !== "string")
            activeContact = activeContact.toString();
        this.setState({activeContact});
    };

    onEdit = (targetKey, action) => {
        this[action](targetKey);
    };

    handleContactRemove = () => {
        let updatedState = {};
        let isUpdateValid;

        function isRemovalValid(removedIndex, contactType) {
            let validRemoval = true;
            if (contactType === "currentContacts" && removedIndex === 0)
                validRemoval = false;
            return validRemoval;
        }

        this.setState(prevState => {
            let previousContacts, otherContacts;
            const activeContact = prevState.activeContact;
            let [removedIndex, contactType] = activeContact.split('-');
            removedIndex = parseInt(removedIndex);
            isUpdateValid = isRemovalValid(removedIndex, contactType);
            if (isUpdateValid) {
                const currentContacts = prevState.currentContacts;
                const newContacts = prevState.newContacts;

                if (contactType === "currentContacts") {
                    previousContacts = currentContacts;
                    otherContacts = newContacts;
                } else {
                    previousContacts = newContacts;
                    otherContacts = currentContacts;
                }

                const updatedContacts = previousContacts.filter((contact, contactIndex) => contactIndex !== removedIndex);
                updatedState[contactType] = updatedContacts;
                const updatedContactsLength = updatedContacts.length;
                const previousContactsLength = previousContacts.length;

                if (updatedContactsLength > 0) {
                    if (removedIndex === previousContactsLength - 1) {
                        updatedState.activeContact = `${removedIndex - 1}-${contactType}`;
                    } else {
                        updatedState.activeContact = `${removedIndex}-${contactType}`;
                    }
                } else {
                    if (contactType === "newContacts") {
                        updatedState.activeContact = `${otherContacts.length - 1}-currentContacts`;
                    } else {
                        updatedState.activeContact = `${0}-newContacts`;
                    }
                }
                return updatedState;
            }
        }, () => {
            if (isUpdateValid)
                this.notifyChanges();
        })
    };

    notifyChanges = () => {
        return this.props.notifyChanges();
    };

    handleContactChange = (activeContact) => (attr) => (event, callback) => {
        let updatedValue;
        if (Array.isArray(event))
            updatedValue = event;
        else
            updatedValue = event.target.value;

        this.setState(prevState => {
            let [updatedIndex, contactType] = activeContact.split('-');
            updatedIndex = parseInt(updatedIndex);
            const previousContacts = prevState[contactType];
            const updatedContacts = [...previousContacts];
            const updatedContact = updatedContacts.find((contact, contactIndex) => contactIndex === updatedIndex);
            updatedContact[attr] = updatedValue;
            return {[contactType]: updatedContacts}
        }, () => {
            callback && callback();
            this.notifyChanges();
        })
    };

    render() {
        const {activeContact, currentContacts, newContacts} = this.state;
        const showEmptyContacts = currentContacts.length === 0 && newContacts.length === 0;
        return (
            <Card size="small" className="contacts">
                <Tabs
                    type="card"
                    hideAdd
                    onEdit={this.onEdit}
                    onChange={this.onChange}
                    className={showEmptyContacts ? "contacts-empty" : "contacts-tabs"}
                    activeKey={showEmptyContacts ? "empty" : activeContact}
                    tabBarExtraContent={<HeaderContent activeContact={activeContact} onRemove={this.handleContactRemove} onCreate={this.createContact}/>}>
                    {showEmptyContacts && <TabPane tab={""} key={"empty"}>
                        <Empty description={Translation.contactEmptyLabel} className="contacts-empty-Wrapper"/>
                    </TabPane>}
                    {!showEmptyContacts && currentContacts.map((contact, index) => {
                        const contactKey = `${index}-currentContacts`;
                        return (
                            <TabPane tab={<p>{Translation.contactLabel} #{index + 1} </p>} key={contactKey}>
                                <ContactContent
                                    contact={contact}
                                    phonesRef={this.phonesRef}
                                    onChange={this.handleContactChange(contactKey)}/>
                            </TabPane>
                        )
                    })}
                    {!showEmptyContacts && newContacts.map((contact, index) => {
                        const contactsLength = currentContacts.length;
                        const contactKey = `${index}-newContacts`;
                        return (
                            <TabPane tab={<p>{Translation.contactLabel} #{contactsLength + index + 1} </p>} key={contactKey}>
                                <ContactContent
                                    contact={contact}
                                    onChange={this.handleContactChange(contactKey)}/>
                            </TabPane>
                        )
                    })}
                </Tabs>
            </Card>
        );
    }
}

export default Contacts;