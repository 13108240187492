import React from 'react';
//React Router Dom
import {Route} from "react-router-dom";

export const CombinedRouter = ({component: Component, layout: Layout, config, ...rest}) => (
    <Route {...rest} render={props => (
        <Layout {...config.layout} >
            <Component {...config.component} {...props} />
        </Layout>
    )}/>
);

