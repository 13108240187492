import React, { useState } from "react";
//Translations
import Translation from "./translation";
import { Row, Col } from "antd";
//Custom Components
import InsurerLogo from "../../../../../InsurerLogo";
import { formatMoney } from "helpers";

export const QuotationData = ({ minutePremium, chosenInsurer, insurerData }) => {
  if (insurerData) {
    const hasChanges = minutePremium
      ? minutePremium !== insurerData.premium
      : false;
    const premium = minutePremium ? minutePremium : insurerData.premium;

    return (
      <>
        <div className="insurerBox minuteInsurerBox">
          <Row className="gx-align-items-center">
            <Col xs={24} md={6} className="gx-mb-2">
              <div className="insurerLogo">
                <InsurerLogo insurer={chosenInsurer} />
              </div>
            </Col>
            <Col xs={24} md={18}>
              <Row>
                <Col xs={24} md={12} className="gx-mb-2">
                  {Translation.taxRateLabel}&nbsp;
                  {insurerData.rate}%
                </Col>
                <Col xs={24} md={12} className="gx-mb-2">
                  {Translation.priceLabel}
                  {hasChanges && "*"}&nbsp;
                  {formatMoney(premium)}
                </Col>
              </Row>
              <Row className="gx-fs-xs">
                <Col xs={24} md={24}>
                  *{Translation.quotationCaptionPrefix}&nbsp;
                  {formatMoney(insurerData.premium)}
                  &nbsp;{Translation.quotationCaptionSuffix}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  return null;
};
