import React from "react";
//React Router Lib
import {NavLink} from "react-router-dom";
//Styles
import './Styles.less';

export default (props) => {
    const {children, ...others} = props;
    return (
        <div className="routeRedirect">
            <NavLink className="routeRedirect-link" {...others}>
                {children}
            </NavLink>
        </div>
    );
}

