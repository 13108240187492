//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("operationalData");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
  return translation.set(...arguments)
}

export default {
  logisticTypeLabel: getTranslation("logisticTypeLabel"),
  logisticTypePlaceholder: getTranslation("logisticTypePlaceholder"),
  exclusiveOperativeLabel: getTranslation("exclusiveOperativeLabel"),
  exclusivityCompanyPlaceholder: getTranslation("exclusivityCompanyPlaceholder", false, true),
  wayverShipperLabel: getTranslation("wayverShipperLabel"),
  wayverShipperPlaceholder: getTranslation("wayverShipperPlaceholder", false, true),
  registeredDriverLabel: getTranslation("registeredDriverLabel"),
  registeredDriverPlaceholder: getTranslation("registeredDriverPlaceholder", false, true),
  shipmentDocumentsLabel: getTranslation("shipmentDocumentsLabel"),
  shipmentDocumentsPlaceholder: getTranslation("shipmentDocumentsPlaceholder"),
  riskTypesLabel: getTranslation("riskTypesLabel"),
  riskTypesPlaceholder: getTranslation("riskTypesPlaceholder"),
  trackerLabel: getTranslation("trackerLabel"),
  trackerPlaceholder: getTranslation("trackerPlaceholder"),
  clientResponsibleLabel: getTranslation("clientResponsibleLabel"),
}