//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation('customers');

const keys = [
  {
    "key": "listHeaderDocumentLabel",
    "type": "label"
  },
  {
    "key": "listHeaderNameLabel",
    "type": "label"
  },
  {
    "key": "listHeaderActionLabel",
    "type": "label"
  },
  {
    "key": "emptyListLabel",
    "type": "label"
  },
  {
    "key": "listContentActionLabel",
    "type": "label"
  },

  {
    "key": "listContentActionLabel",
    "type": "label"
  },

  {
    "key": "companyNameFilterLabel",
    "type": "label"
  },
  {
    "key": "contactNameFilterLabel",
    "type": "label"
  },
  {
    "key": "contactEmailFilterLabel",
    "type": "label"
  },
  {
    "key": "contactDocumentFilterLabel",
    "type": "label"
  },

  {
    "key": "companyNameFilterPlaceholder",
    "type": "placeholder"
  },

  {
    "key": "contactNameFilterPlaceholder",
    "type": "placeholder"
  },

  {
    "key": "contactEmailFilterPlaceholder",
    "type": "placeholder"
  },

  {
    "key": "contactDocumentFilterPlaceholder",
    "type": "placeholder"
  },

  {
    "key": "clearFilterLabel",
    "type": "label"
  },

  {
    "key": "triggerFilterLabel",
    "type": "label"
  }
];

export default prepareTranslations(keys, translation);