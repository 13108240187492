//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "transportMeansLabel",
    "type": "label"
  },
  {
    "key": "incoTermLabel",
    "type": "label"
  },
  {
    "key": "incoTermPlaceholder",
    "type": "label"
  },
  {
    "key": "boardingDateLabel",
    "type": "label"
  },
  {
    "key": "boardingDatePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "acceptRiskMngmLabel",
    "type": "label"
  },
  {
    "key": "acceptPolicyPrivacyLabel",
    "type": "label"
  }
];
export default prepareTranslations(keys, translation);