// new translations
import i18n from "../translation/i18n";

export * from "./selections";
export const modals = [
  {
    key: "ROAD_MODAL",
    value: "Rodoviário",
  },
  {
    key: "MARINE_MODAL",
    value: "Marítimo",
  },
  {
    key: "AIR_MODAL",
    value: "Aéreo",
  },
  {
    key: "RAIL_MODAL",
    value: "Ferroviário",
  },
];

export const CNPJ_REGEXP = /^[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}-?[0-9]{2}$/;
export const PHONE_NUMBER_REGEXP = /\(?[1-9]{2}\)?(?:[2-8]|9[1-9])[0-9]{3}-?[0-9]{4}/;

export const companyActivitiesList = {
  SHIPPING_COMPANY: "Transportadora",
  LOADING_AGENT: "Agente de carga",
  AGRIBUSINESS: "Agronegócio",
  TRUCK_DRIVER: "Caminhoneiro",
  COMMERCE: "Comércio",
  FORWARDING: "Despachante aduaneiro",
  SHIPPER: "Embarcadora",
  EXPORTER: "Exportadora",
  IMPORTER: "Importadora",
  INDUSTRY: "Indústria",
  OPERATOR: "Operador logístico",
  TRADING: "Trading",
  OTHERS: "Outros",
};

export const coverageItems = {
  ACCIDENT: "Acidente (Cobertura básica obrigatória)",
  THEFT: "Roubo",
  DAMAGE: "Avaria",
  TRACK_CLEANING: "Limpeza de Pista",
  ENVIRONMENTAL_LIABILITY_RISK: "Responsabilidade Civil Ambiental",
  LOADING_UNLOADING: "Carga e Descarga",
  LOADING_UNLOADING_LIFTING: "Carga, Descarga e Içamento",
  FREIGHT: "Frete",
  LIFE_INSURANCE: "Seguro de vida para os motoristas",
  FLUVIAL_ROUTE: "Percurso Fluvial",
  OTHER: "Outros",
};

export const coverageStatus = {
  NOT_SELECTED: "Não solicitado",
  UPON_REQUEST: "Sob consulta",
  NOT_AVAILABLE: "Em análise",
  AVAILABLE: "Disponível",
};

export const insuredTypes = {
  OWN_MERCHANDISE: "OWN_MERCHANDISE",
  THIRDY_PARTY_MERCHANDISE: "THIRDY_PARTY_MERCHANDISE",
};

export const modalLabels = {
  NATIONALROAD: "Nacional rodoviário",
  NATIONALRAIL: "Nacional ferroviário",
  NATIONALAIR: "Nacional aéreo",
  NATIONALMARINE: "Nacional marítimo",
  INTERNATIONALROAD: "Internacional rodoviário",
  INTERNATIONALRAIL: "Internacional ferroviário",
  INTERNATIONALAIR: "Internacional aéreo",
  INTERNATIONALMARINE: "Internacional marítimo",
};

export const customerTabs = {
  registerData: "1",
  contactData: "2",
  suretyRequests: "3",
  currentPolicy: "4",
  overduePolicy: "5",
  financial: "6",
  claims: "7",
  limits: "8",
};

export const policyTabs = {
  policyStaticData: 1,
  policyData: 2,
  financial: 3,
  claims: 4,
  endorsements: 5,
};

export const policyTableColumns = {
  key: "key",
  recurrenceMonth: "recurrenceMonth",
  dueDate: "dueDate",
  premium: "premium",
  payment: "payment",
  file: "file",
  invoice: "invoice",
  charge: "charge",
};

export const tax = 7.38;

export const paymentStatus = {
  TO_BE_CONFIRMED: {
    label: "Processando",
    style: "",
  },
  PAID: {
    label: "Pago",
    style: "success",
  },
  DELAYED: {
    label: "Atrasado",
    style: "danger",
  },
  COLLECTION_PROCESS_STARTED: {
    label: "Pago",
    style: "success",
  },
};

export const filesStatus = {
  onlyInvoice: {
    label: "Fat",
    style: "",
  },
  onlyPaymentSlip: {
    label: "Bol",
    style: "",
  },
  hasBoth: {
    label: "Fat/Bol",
    style: "",
  },
  noFiles: {
    label: "Pendente",
    style: "warning",
  },
};

export const suretyPercentageValue = 0.3;

export const orderProductTypes = {
  CARGO_SINGLE_BOARDING: "CARGO_SINGLE_BOARDING",
  CARGO_OPEN_POLICY: "CARGO_OPEN_POLICY",
  SURETY: "SURETY",
  LIFE: "LIFE",
};

export const taxByProduct = {
  CARGO_SINGLE_BOARDING: 7.38,
  CARGO_OPEN_POLICY: 7.38,
  CARGO_INTERNATIONAL: 7.38,
  SURETY: 0,
  LIFE: 0.38,
  ENGINEERING_ALL_RISKS: 7.38,
  EQUIPMENT: 7.38,
  ENVIRONMENTAL_CIVIL_LIABITY: 7.38,
  PROFESSIONAL_CIVIL_LIABITY: 7.38,
  PORT_OPERATOR_LIABILITY: 7.38,
  CORPORATE_HEALTH: 7.38,
  CONDO: 7.38,
  CYBER_RISK: 7.38,
  CORPORATE: 7.38,
  MARITIME_CHARTERER_CIVIL_LIABILITY: 7.38,
  EXECUTIVE_CIVIL_LIABILITY: 7.38,
  CONSTRUCTION_CIVIL_LIABILITY: 7.38,
  LAWYER_CIVIL_LIABILITY: 7.38,
  ARCHITECT_CIVIL_LIABILITY: 7.38,
  DENTIST_CIVIL_LIABILITY: 7.38,
  ACCOUNTANT_CIVIL_LIABILITY: 7.38,
  TECH_COMPANY_CIVIL_LIABILITY: 7.38,
  ENGINEER_CIVIL_LIABILITY: 7.38,
  DOCTOR_CIVIL_LIABILITY: 7.38,
  ANOTHER_ACTIVITIES_CIVIL_LIABILITY: 7.38,
  RENTAL_BOND: 7.38,
  FINANCIAL_INSTITUTION: 7.38,
  LGPD: 7.38,
  RETA_DRONE: 7.38,
  RETA_AIR_TRANSPORT: 7.38,
  NAMED_RISK: 7.38,
  IPO: 7.38,
  BUS: 7.38,
  EVENT_LIABILITY: 7.38,
  GENERAL_LIABILITY: 7.38,
  SERVICES_PRIVISION_LIABILITY: 7.38,
  OPERATIONS_LIABILITY: 7.38,
  RETA: 7.38,
  AUTO_INSURANCE: 7.38,
  CREDIT: 7.38,
};

export const quotationStatus = {
  DOCUMENTATION_NEEDED: "DOCUMENTATION_NEEDED",
  DESIGNATION_LETTER_NEEDED: "DESIGNATION_LETTER_NEEDED",
  PENDING_ANALYSIS: "PENDING_ANALYSIS",
  REFUSED: "REFUSED",
  CCG_PENDING: "CCG_PENDING",
  INSURER_REGISTER_NEEDED: "INSURER_REGISTER_NEEDED",
  BLOCKED: "BLOCKED",
};

export const courtTypes = [
  "CIVIL_COURT_BOND",
  "LABOUR_APPEAL_FILLING_BOND",
  "TAX_CREDITS_BOND",
  "TAX_ENFORCEMENT_BOND",
  "COURT_OTHERS_BOND",
  "LABOUR_SURETY",
];

const CONTRACT_MODALITIES = [
  "CONSTRUCTION_BOND",
  "BUSINESS_SERVICES_BOND",
  "SUPPLY_BOND",
  "DEALER_PERFORMER_BOND",
  "ENERGY_SECTOR_BOND",
  "WORK_COMPLETION_BOND",
  "OIL_GAS_SECTOR_BOND",
  "NAVY_SECTOR_BOND",
  "CORRECTIVE_MAINTENANCE_BOND",
  "FINANCIAL_BOND",
  "ENVIRONMENTAL_BOND",
  "REAL_ESTATE_BOND",
  "CUSTOMS_BOND",
  "MAINTENANCE_BOND",
];

const GENERAL_MODAL = ["ADVANCED_PAYMENT_BOND", "BIDDING_BOND"];

const RENT_GUARANTEE_MODAL = ["RENT_GUARANTEE"];

const PAYMENT_RETENTION_MODAL = ["PAYMENT_RETENTION_BOND"];

const LABOUR_APPEAL_DEPOSIT_MODAL = ["LABOUR_APPEAL_FILLING_BOND"];

const LEGAL_MODAL = [
  "CIVIL_COURT_BOND",
  "TAX_CREDITS_BOND",
  "TAX_ENFORCEMENT_BOND",
  "COURT_OTHERS_BOND",
  "LABOUR_SURETY",
];

const LABOUR_SURETY_MODAL = ["LABOUR_SURETY"];

const ALL_MODAL = GENERAL_MODAL.concat(CONTRACT_MODALITIES)
  .concat(RENT_GUARANTEE_MODAL)
  .concat(PAYMENT_RETENTION_MODAL)
  .concat(LABOUR_APPEAL_DEPOSIT_MODAL)
  .concat(LEGAL_MODAL)
  .concat(LABOUR_SURETY_MODAL);

export const MODALITIES = {
  CONTRACT: CONTRACT_MODALITIES,
  GENERAL: GENERAL_MODAL,
  RENT_GUARANTEE: RENT_GUARANTEE_MODAL,
  PAYMENT_RETENTION: PAYMENT_RETENTION_MODAL,
  LABOUR_APPEAL: LABOUR_APPEAL_DEPOSIT_MODAL,
  LEGAL: LEGAL_MODAL,
  LABOUR_SURETY: LABOUR_SURETY_MODAL,
  ALL: ALL_MODAL,
};

export const valueByAppealType = {
  INTERLOCUTORY_APPEAL_ORDINARY: 6566.73, // Agravo de instrumento - recurso ordinário
  INTERLOCUTORY_APPEAL_REVIEW: 13133.46, // Agravo de instrumento - recurso de revista
  ORDINARY_APPEAL: 13133.46, // Recurso ordinário
  EXTRAORDINARY_APPEAL: 26266.92, // Recurso extraordinário
  EMBARGOES: 26266.92, // Embargos no TST
  APPEAL: 26266.92, // Recurso de revista
  RESCISORY_APPEAL: 26266.92, // Recurso em ação rescisória
};

export const limitInsurers = [
  "TOKIO_MARINE_SEGUROS",
  "BERKLEY_SEGUROS",
  "BMG_SEGUROS",
  "JUNTOS_SEGUROS",
  "ESSOR_SEGUROS",
  "EXCELSIOR",
];

export const bondTypes = {
  CONSTRUCTION_BOND: i18n.t("bond.constructionBond"),
  BUSINESS_SERVICES_BOND: i18n.t("bond.businessServiceBond"),
  SUPPLY_BOND: i18n.t("bond.supplyBond"),
  ADVANCED_PAYMENT_BOND: i18n.t("bond.advancedPaymentBond"),
  BIDDING_BOND: i18n.t("bond.biddingBond"),
  TAX_CREDITS_BOND: i18n.t("bond.taxCreditsBond"),
  DEALER_PERFORMER_BOND: i18n.t("bond.dealerPerformerBond"),
  WORK_COMPLETION_BOND: i18n.t("bond.workCompletationBond"),
  WORK_COMPLETATION_BOND: i18n.t("bond.workCompletationBond"),
  CUSTOMS_BOND: i18n.t("bond.customsBond"),
  FINANCIAL_BOND: i18n.t("bond.financialBond"),
  CORRECTIVE_MAINTENANCE_BOND: i18n.t("bond.correctiveMaintenanceBond"),
  PAYMENT_RETENTION_BOND: i18n.t("bond.paymentRetentionBond"),
  REAL_ESTATE_BOND: i18n.t("bond.realStateBond"),
  REAL_STATE_BOND: i18n.t("bond.realStateBond"),
  ENERGY_SECTOR_BOND: i18n.t("bond.energySectorBond"),
  NAVY_SECTOR_BOND: i18n.t("bond.navySectorBond"),
  RENT_GUARANTEE: i18n.t("bond.rentGuaranteeBond"),
  OIL_GAS_SECTOR_BOND: i18n.t("bond.oilGasSectorBond"),
  ENVIRONMENTAL_BOND: i18n.t("bond.environmentBond"),
  LABOUR_APPEAL_FILLING_BOND: i18n.t("bond.labourAppealFillingBond"),
  CIVIL_COURT_BOND: i18n.t("bond.civilCourtBond"),
  TAX_ENFORCEMENT_BOND: i18n.t("bond.taxEnforcementBond"),
  COURT_OTHERS_BOND: i18n.t("bond.courtOthersBond"),
  LABOUR_SURETY: i18n.t("bond.labourSurety"),
  TAX_ADMINISTRATIVE_INSTALLMENT_BOND: i18n.t(
    "bond.taxAdministrativeInstallmentBond"
  ),
};

export const emailEvents = [
  {
    value: "PROCESSED",
    label: i18n.t("constants.emailEvents.processed"),
    description: i18n.t("constants.emailEvents.processedDescription"),
    color: "blue",
  },
  {
    value: "DROPPED",
    label: i18n.t("constants.emailEvents.dropped"),
    description: i18n.t("constants.emailEvents.droppedDescription"),
    color: "red",
  },
  {
    value: "DELIVERED",
    label: i18n.t("constants.emailEvents.delivered"),
    description: i18n.t("constants.emailEvents.deliveredDescription"),
    color: "green",
  },
  {
    value: "DEFERRED",
    label: i18n.t("constants.emailEvents.deferred"),
    description: i18n.t("constants.emailEvents.deferredDescription"),
    color: "gold",
  },
  {
    value: "BOUNCE",
    label: i18n.t("constants.emailEvents.bounce"),
    description: i18n.t("constants.emailEvents.bounceDescription"),
    color: "red",
  },
  {
    value: "BLOCKED",
    label: i18n.t("constants.emailEvents.blocked"),
    description: i18n.t("constants.emailEvents.blockedDescription"),
    color: "orange",
  },
  {
    value: "OPENED",
    label: i18n.t("constants.emailEvents.opened"),
    description: i18n.t("constants.emailEvents.openedDescription"),
    color: "green",
  },
  {
    value: "CLICKED",
    label: i18n.t("constants.emailEvents.clicked"),
    description: i18n.t("constants.emailEvents.clickedDescription"),
    color: "green",
  },
  {
    value: "REPORTED_SPAM",
    label: i18n.t("constants.emailEvents.reportedSpan"),
    description: i18n.t("constants.emailEvents.reportedSpanDescription"),
    color: "red",
  },
  {
    value: "UNSUBSCRIBED",
    label: i18n.t("constants.emailEvents.unsubscribed"),
    description: i18n.t("constants.emailEvents.unsubscribedDescription"),
    color: "red",
  },
  {
    value: "GROUP_UNSUBSCRIPTION",
    label: i18n.t("constants.emailEvents.groupUnsubscription"),
    description: i18n.t("constants.emailEvents.groupUnsubscriptionDescription"),
    color: "red",
  },
  {
    value: "GROUP_RESUBSCRIPTION",
    label: i18n.t("constants.emailEvents.groupResubscription"),
    description: i18n.t("constants.emailEvents.groupResubscriptionDescription"),
    color: "red",
  },
  {
    value: "ERROR",
    label: i18n.t("constants.emailEvents.error"),
    description: i18n.t("constants.emailEvents.errorDescription"),
    color: "red",
  },
];

export const emailHashTypes = {
  emailHistory: "emailHistory",
  expirationEmailHistory: "expirationEmailHistory",
  preExpirationEmailHistory: "preExpirationEmailHistory",
};

export const endorsementTypes = [
  {
    value: "CANCEL",
    label: i18n.t("endorsement.types.cancel"),
  },
  {
    value: "UPDATE",
    label: i18n.t("endorsement.types.change"),
  },
  {
    value: "EXTENSION",
    label: i18n.t("endorsement.types.extension"),
  },
];

export const endorsementChangeTypes = {
  other: "other",
  netPremium: "netPremium",
  initialTermAt: "initialTermAt",
  finalTermAt: "finalTermAt",
  maximumGuaranteeLimit: "maximumGuaranteeLimit",
};

export const dealTypes = [
  {
    label: i18n.t("order.createOrder.newDealTypeLabel"),
    value: "NEW",
  },
  {
    label: i18n.t("order.createOrder.renewDealTypeLabel"),
    value: "RENEW",
  },
  {
    label: i18n.t("order.createOrder.endorsementDealTypeLabel"),
    value: "ENDORSEMENT",
  },
];

export const orderOrigin = [
  {
    label: i18n.t("order.createOrder.origin.customerReferral"),
    value: i18n.t("order.createOrder.origin.customerReferral"),
  },
  {
    label: i18n.t("order.createOrder.origin.prospection"),
    value: i18n.t("order.createOrder.origin.prospection"),
  },
  {
    label: i18n.t("order.createOrder.origin.socialNetworks"),
    value: i18n.t("order.createOrder.origin.socialNetworks"),
  },
  {
    label: i18n.t("order.createOrder.origin.whatsapp"),
    value: i18n.t("order.createOrder.origin.whatsapp"),
  },
  {
    label: i18n.t("order.createOrder.origin.ura"),
    value: i18n.t("order.createOrder.origin.ura"),
  },
  {
    label: i18n.t("order.createOrder.origin.isCustomer"),
    value: i18n.t("order.createOrder.origin.isCustomer"),
  },
  {
    label: i18n.t("order.createOrder.origin.others"),
    value: i18n.t("order.createOrder.origin.others"),
  },
];

export const policyEmissionStatusEnum = {
  SURETY_PROCESSING: "SURETY_PROCESSING",
  AWS_PROCESSING: "AWS_PROCESSING",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};
