import {
    FETCH_ORDERS,
    FETCH_ORDER,
    FETCH_UPDATED_ORDERS_FILTER,
    CHANGE_ORDER_CONFIGURATIONS,
    PREPARE_ORDER_STARTED,
    PREPARE_ORDER_CONFIGURATIONS,
    CLEAR_ORDER_CONFIGURATIONS,
    RESET_ORDER_CONFIGURATIONS,
    RESET_ORDER_STARTED,
    SAVE_ORDER,
    SEARCH_COMPANY,
    INITIALIZE_ORDERS,
    INITIALIZE_ORDER_CONFIGURATIONS,
    UPDATE_ORDER_CONFIGURATIONS,
    CHANGE_TAB,
    ASYNC_REMOVE_ERROR,
    REGISTER_EXPRESS_ERRORS,
    REQUEST_CARGO_QUOTATIONS,
    REGISTER_CARGO_QUOTATIONS,
    REGISTER_SELECTED_CARGO_QUOTATION,
    SET_GLOBAL_LOADER,
    REGISTER_SELECTED_INSURER,
    FETCH_COMPANY_DATA,
    
    //customer
    REGISTER_LIST,
    REQUEST_CUSTOMERS_LIST,
    REGISTER_FILTER_FIELD,
    HANDLE_CLEAR_FILTER,
    FETCH_CUSTOMER_DATA,
    CHANGE_CUSTOMER_DATA,
    SAVE_CUSTOMER_DATA,
    RESET_CUSTOMER_DATA,
    CUSTOMER_DATA_LOADING,
    RESET_IS_CHANGED,
    IS_FETCHING_LIST,
    REQUEST_CUSTOMERS_POLICY_LIST,
    REGISTER_CUSTOMERS_POLICY_LIST,
    CHANGE_CUSTOMERS_TAB,
    REGISTER_PAGINATION_PARAM,
    REQUEST_CUSTOMERS_CONTACTS,
    REGISTER_CUSTOMERS_CONTACTS,
    SAVE_CONTACTS_DATA,
    REGISTER_CUSTOMERS_ERRORS,
    DELETE_CUSTOMER_CONTACT,
    CREATE_CUSTOMER_CONTACT,
    REGISTER_CUSTOMER_CONTACTS_TAB,
    FETCH_CUSTOMER_POLICY,
    FETCHING_CUSTOMER_POLICY,
    FETCH_CUSTOMER_POLICY_SUCCESS,
    FETCH_POLICY_TYPES,
    FETCHING_POLICY_TYPES,
    FETCH_POLICY_TYPES_SUCCESS,
    CHANGE_CUSTOMER_POLICY_DATA,
    RESET_POLICY_DATA_IS_CHANGED,
    SAVE_CUSTOMER_POLICY_DATA,
    CREATE_CUSTOMER_POLICY,
    SAVING_CUSTOMER_POLICY_DATA,
    CLEAR_CUSTOMER_POLICY_DATA,
    REGISTER_POLICY_DATA_ERRORS,
    REMOVE_POLICY_FILE,
    REMOVING_POLICY_FILE,
    SET_DATA_IS_SAVED,
    SET_CURRENT_POLICY_TAB,
    SEND_POLICY_BY_EMAIL,
    IS_SENDING_POLICY_BY_EMAIL,
    FETCH_CUSTOMER_DATA_SUCCESS,

    //invoice
    CREATE_NEW_INVOICE,
    CHANGE_INVOICE_DATA,
    INVOICE_DATA_IS_CHANGED,
    REGISTER_INVOICE_ERRORS,
    SAVING_INVOICE_DATA,
    CLEAR_INVOICE_DATA,
    UPDATE_INVOICE_DATA,
    REMOVE_INVOICE_FILE,
    SET_INVOICE_MODAL_VISIBLE,
    FETCH_INVOICE_DATA,
    SET_INVOICE_DATA,
    SEND_INVOICE_BY_EMAIL,
    SEND_INVOICE_MANUAL_EMAIL,
    IS_SENDING_INVOICE_BY_EMAIL,

    REQUEST_CUSTOMER_REGISTER_DATA,
    SET_IS_CHANGED
} from '../actionTypes';

/*Orders Actions*/
export const fetchOrders = (config) => ({
    type: FETCH_ORDERS,
    config: config
});

export const updateOrdersFilter = (filterType, value) => {
    return ({
    type: FETCH_UPDATED_ORDERS_FILTER,
    filterType: filterType,
    value: value
})};


/*Order Actions*/
export const fetchOrder = (id) => ({
    type: FETCH_ORDER,
    id: id
});

export const changeTab = (selectedTab) => ({
    type: CHANGE_TAB,
    selectedTab
});

export const registerExpressErrors = (expressErrors) => ({
    type: REGISTER_EXPRESS_ERRORS,
    expressErrors
});

export const handleOrderChange = (config) => ({
    type: CHANGE_ORDER_CONFIGURATIONS,
    config: config
});


export const prepareOrderStarted = () => ({
    type: PREPARE_ORDER_STARTED
});


export const prepareOrderConfigurations = (data) => ({
    type: PREPARE_ORDER_CONFIGURATIONS,
    data: data
});


export const updateOrderConfigurations = (entity, data) => ({
    type: UPDATE_ORDER_CONFIGURATIONS,
    entity: entity,
    data: data
});


export const clearOrderConfigurations = () => ({
    type: CLEAR_ORDER_CONFIGURATIONS
});


export const resetOrderConfigurations = (id) => ({
    id: id,
    type: RESET_ORDER_CONFIGURATIONS
});

export const resetOrderStarted = () => ({
    type: RESET_ORDER_STARTED
});


export const saveOrderConfigurations = (config) => ({
    type: SAVE_ORDER,
    config: config
});

export const asyncRemoveError = (paramName) => ({
    type: ASYNC_REMOVE_ERROR,
    payload:paramName
});

export const searchCompany = (entity) => ({
    type: SEARCH_COMPANY,
    entity: entity
});

export const initializeOrders = (authUser) => ({
    type: INITIALIZE_ORDERS,
    authUser: authUser
});

export const initializeOrdersConfigurations = (authUser) => ({
    type: INITIALIZE_ORDER_CONFIGURATIONS,
    authUser: authUser
});

export const requestCargoQuotations = (code) => ({
    type: REQUEST_CARGO_QUOTATIONS,
    code: code
});

export const registerCargoQuotations = (payload) => ({
    type: REGISTER_CARGO_QUOTATIONS,
    payload
});

export const registerSelectedCargoQuotation = (payload) => ({
    type: REGISTER_SELECTED_CARGO_QUOTATION,
    payload
});

export const registerSelectedInsurer = (payload) => ({
    type: REGISTER_SELECTED_INSURER,
    payload
});

export const setGlobalLoader = (payload) => ({
    type: SET_GLOBAL_LOADER,
    payload
})

export const fetchCompanyData = (payload) => ({
    type: FETCH_COMPANY_DATA,
    payload
})

//Customers
export const registerList = (payload) => ({
    type: REGISTER_LIST,
    payload
})

export const requestCustomersList = (payload) => ({
    type: REQUEST_CUSTOMERS_LIST,
    params: payload
})

export const isFetchingList = (payload) => ({
    type: IS_FETCHING_LIST,
    payload
})

export const fetchCustomerData = (id) => ({
    type: FETCH_CUSTOMER_DATA,
    id
})

export const changeCustomerData = (payload) => ({
    type: CHANGE_CUSTOMER_DATA,
    payload
})

export const saveCustomerData = () => ({
    type: SAVE_CUSTOMER_DATA
})

export const resetCustomerData = () => ({
    type: RESET_CUSTOMER_DATA
})

export const customerDataLoading = (payload) => ({
    type: CUSTOMER_DATA_LOADING,
    payload
})

export const resetIsChanged = () => ({
    type: RESET_IS_CHANGED
})

export const setIsChanged = () => ({
    type: SET_IS_CHANGED
})

export const changeCustomersTab = (payload) => ({
    type: CHANGE_CUSTOMERS_TAB,
    payload
})

export const requestCustomersPolicy = ( payload ) => ({
    type: REQUEST_CUSTOMERS_POLICY_LIST,
    payload
})

export const registerCustomersPolicy = ( payload ) => ({
    type: REGISTER_CUSTOMERS_POLICY_LIST,
    payload
})

export const registerPaginationParam = ( payload ) => ({
    type: REGISTER_PAGINATION_PARAM,
    payload
})

export const requestCustomersContacts = ( payload ) => ({
    type: REQUEST_CUSTOMERS_CONTACTS,
    id: payload
})

export const registerCustomersContacts = ( payload ) => ({
    type: REGISTER_CUSTOMERS_CONTACTS,
    payload
})

export const saveContactsData = ( payload ) => ({
    type: SAVE_CONTACTS_DATA,
    payload
})

export const registerCustomersErros = ( payload ) => ({
    type: REGISTER_CUSTOMERS_ERRORS,
    errors: payload
})

export const deleteCustomerContact = ( payload ) => ({
    type: DELETE_CUSTOMER_CONTACT,
    payload
})

export const createCustomerContact = ( payload ) => ({
    type: CREATE_CUSTOMER_CONTACT,
    payload
})

export const registerCustomersContactsTab = ( payload ) => ({
    type: REGISTER_CUSTOMER_CONTACTS_TAB,
    payload
})

export const fetchCustomerDataSuccess = (payload) => {
    return ({
        type: FETCH_CUSTOMER_DATA_SUCCESS,
        payload
    })
} 

// policy ----------------------------------------------------

export const fetchCustomerPolicyData = ( payload ) => ({
    type: FETCH_CUSTOMER_POLICY,
    payload
})

export const fetchingCustomerPolicyData = ( payload ) => ({
    type: FETCHING_CUSTOMER_POLICY,
    payload
})

export const fetchCustomerPolicyDataSuccess = ( payload ) => ({
    type: FETCH_CUSTOMER_POLICY_SUCCESS,
    payload
})

export const fetchPolicyTypes = () => ({
    type: FETCH_POLICY_TYPES
})

export const fetchingPolicyTypes = ( payload ) => ({
    type: FETCHING_POLICY_TYPES,
    payload
})

export const fetchPolicyTypesSuccess = ( payload ) => ({
    type: FETCH_POLICY_TYPES_SUCCESS,
    payload
})

export const changeCustomerPolicyData = ( payload ) => ({
    type: CHANGE_CUSTOMER_POLICY_DATA,
    payload
})

export const resetPolicyDataIsChanged = ( payload ) => ({
    type: RESET_POLICY_DATA_IS_CHANGED,
    payload
})

export const saveCustomerPolicyData = ( payload ) => ({
    type: SAVE_CUSTOMER_POLICY_DATA,
    payload
})

export const createCustomerPolicy = ( id ) => ({
    type: CREATE_CUSTOMER_POLICY,
    id
})

export const savingCustomerPolicyData = ( payload ) => ({
    type: SAVING_CUSTOMER_POLICY_DATA,
    payload
})

export const clearCustomerPolicyData = () => ({
    type: CLEAR_CUSTOMER_POLICY_DATA
})

export const registerPolicyDataErrors = ( payload ) => ({
    type: REGISTER_POLICY_DATA_ERRORS,
    payload
})

export const removePolicyFile = ( params, fileName ) => ({
    type: REMOVE_POLICY_FILE,
    params,
    fileName
})

export const removingPolicyFile = ( payload ) => ({
    type: REMOVING_POLICY_FILE,
    payload
})

export const setDataIsSaved = ( payload ) => ({
    type: SET_DATA_IS_SAVED,
    payload
})

export const setCurrentPolicyTab = ( payload ) => ({
    type: SET_CURRENT_POLICY_TAB,
    payload
})

export const sendPolicyByEmail = ( payload ) => ({
    type: SEND_POLICY_BY_EMAIL,
    payload
});

export const setIsSendingPolicyByEmail = ( payload ) => ({
    type: IS_SENDING_POLICY_BY_EMAIL,
    payload
});

// invoice -----------------------------------------
export const createNewInvoice = ( policyId ) => ({
    type: CREATE_NEW_INVOICE,
    policyId
});

export const changeInvoiceData = ( payload ) => ({
    type: CHANGE_INVOICE_DATA,
    payload
});

export const invoiceDataIsChanged = ( payload ) => ({
    type: INVOICE_DATA_IS_CHANGED,
    payload
});

export const registerInvoiceErrors = ( payload ) => ({
    type: REGISTER_INVOICE_ERRORS,
    payload
})

export const savingInvoiceData = ( payload ) => ({
    type: SAVING_INVOICE_DATA,
    payload
});

export const clearInvoiceData = () => ({
    type: CLEAR_INVOICE_DATA,
})

export const updateInvoiceData = ( payload ) => ({
    type: UPDATE_INVOICE_DATA,
    payload
});

export const removeInvoiceFile = ( payload ) => ({
    type: REMOVE_INVOICE_FILE,
    payload
});

export const setInvoiceModalVisible = ( payload ) => ({
    type: SET_INVOICE_MODAL_VISIBLE,
    payload
});

export const fetchInvoiceData = ( payload ) => ({
    type: FETCH_INVOICE_DATA,
    payload
})

export const setInvoiceData = ( payload ) => ({
    type: SET_INVOICE_DATA,
    payload
})

export const sendInvoiceByEmail = ( payload ) => ({
    type: SEND_INVOICE_BY_EMAIL,
    payload
})

export const sendInvoiceManualEmail = ( payload ) => ({
    type: SEND_INVOICE_MANUAL_EMAIL,
    payload
})

export const setIsSendingInvoiceByEmail = ( payload ) => ({
    type: IS_SENDING_INVOICE_BY_EMAIL,
    payload
})

//customer data ---------------------------------------------

export const requestCustomerRegisterData = ( payload ) => ({
    type: REQUEST_CUSTOMER_REGISTER_DATA,
    payload
})
