//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("losses");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    lossLabel: getTranslation("lossLabel"),
    insurerLabel: getTranslation("insurerLabel"),
    add: getTranslation("add", true),
    remove: getTranslation("remove", true),
    lossRemove: getTranslation("lossRemove"),
    lossAdd: getTranslation("lossAdd"),
    lossEmptyLabel: getTranslation("lossEmptyLabel"),
    dateLabel: getTranslation("dateLabel"),
    eventTypeLabel: getTranslation("eventTypeLabel"),
    eventTypePlaceholder: getTranslation("eventTypePlaceholder", false, true),
    goodLabel: getTranslation("goodLabel"),
    goodPlaceholder: getTranslation("goodPlaceholder", false, true),
    amountLabel: getTranslation("amountLabel"),
    amountPlaceholder: getTranslation("amountPlaceholder", false, true),
}