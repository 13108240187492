import React, { useState } from "react";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Tag, message, Button } from "antd";
import {
  PaperClipOutlined,
  PrinterOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
// custom components
import { WhatsAppRedirect, FormattedValue, PrintReport } from "components";
import ShareQuotationModal from "../../../containers/Orders/Orders/ShareQuotationModal";
// redux
import { useSelector, Provider } from "react-redux";
// config
import config from "config";
// copy to clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// custom styles
import "./styles.less";
// new redux state
import store from "../../../store";
// constants
import selections from "../../../constants/selections";
import { orderProductTypes } from "../../../constants";
//Configs
import Config from "../Config";
const { typeEntriesColors, orderTypeTranslation } = Config;

const OrderResume = ({ id, data }) => {
  const [visible, setVisible] = useState(false);

  const getValue = (source, property) => {
    return source ? source[property] : null;
  };

  const orderTypeColor = typeEntriesColors[getValue(data, "type")];

  const formPathByProduct = {
    CARGO_OPEN_POLICY: "seguro-de-carga/multiplo",
    CARGO_SINGLE_BOARDING: "seguro-de-carga/unico",
    SURETY: "seguro-garantia",
    LIFE: "seguro-de-vida",
  };

  const formUrl = data
    ? `${config.formsUrl}/${formPathByProduct[data.type]}/dados-iniciais/${
        data.code
      }`
    : "";

  const fileUrl = data
    ? `${config.apiUrl}/report/v1/surety/quotation/${data.code}`
    : "";

  const openPolicyQuotationUrl = data
    ? `${config.apiUrl}/report/v1/open-policy/quotation/${data.code}`
    : "";

  const expressErrorsData = useSelector(
    (store) => store.crm.order.expressErrors
  );

  const { t: translation } = useTranslation();

  const getProductLabel = () => {
    return orderTypeTranslation[getValue(data, "type")];
  };

  const getEmissionLabel = () => {
    return getValue(data, "hasExpressEmission")
      ? translation("order.resume.expressEmissionLabel")
      : translation("order.resume.manualEmissionLabel");
  };

  const handleCopyToClipboard = () => {
    message.success(translation("order.resume.copyToClipboardMessage"));
  };

  const ResumeDataItem = ({ children, label }) => {
    return (
      <div className="resumeDataItem">
        <h5 className="label">{label}</h5>
        <div className="text">{children}</div>
      </div>
    );
  };

  return data ? (
    <div className="resumeSection">
      {visible && (
        <Provider store={store}>
          <ShareQuotationModal
            code={data.code}
            visible={visible}
            onCancel={() => setVisible(false)}
          />
        </Provider>
      )}
      <Row type="flex" justify="space-between" align="middle">
        <div
          className="resumeHeader"
          style={{ backgroundColor: `${orderTypeColor}` }}
        >
          <div className="orderInfoWrapper">
            <p>{getProductLabel()}</p>
            {data && "hasExpressEmission" in data && (
              <span className="emissionTypeBadge">{getEmissionLabel()}</span>
            )}
          </div>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="formLink"
            href={formUrl}
          >
            {getValue(data, "code")}
          </a>
        </div>
      </Row>
      <Row type="flex" align="top">
        <Col sm={24} md={8} className="gx-align-self-start">
          <ResumeDataItem label={translation("order.resume.companyNameLabel")}>
            {getValue(getValue(data, "initial"), "companyName")}
          </ResumeDataItem>
          <ResumeDataItem label={translation("order.resume.contactNameLabel")}>
            {getValue(getValue(data, "initial"), "name")}
          </ResumeDataItem>
          <ResumeDataItem label={translation("order.resume.email")}>
            {getValue(getValue(data, "initial"), "email")}
          </ResumeDataItem>
        </Col>

        <Col sm={24} md={8} className="gx-align-self-start">
          <ResumeDataItem
            label={translation("order.resume.documentNumberLabel")}
          >
            <FormattedValue
              mask="_"
              displayType="text"
              format={getValue(getValue(data, "initial"), "contactType")}
              value={getValue(getValue(data, "initial"), "documentNumber")}
            />
          </ResumeDataItem>
          <ResumeDataItem label={translation("order.resume.phoneNumberLabel")}>
            <WhatsAppRedirect
              value={getValue(getValue(data, "initial"), "phoneNumber")}
              canReceive={getValue(
                getValue(data, "initial"),
                "receiveWhatsAppMessage"
              )}
            >
              <FormattedValue
                mask="_"
                displayType="text"
                format="contactPhone"
                value={getValue(getValue(data, "initial"), "phoneNumber")}
              />
            </WhatsAppRedirect>
          </ResumeDataItem>

          <ResumeDataItem
            label={translation("order.resume.salesRepresentativeLabel")}
          >
            {getValue(getValue(data, "salesRepresentative"), "name")}
          </ResumeDataItem>
        </Col>
        <Col sm={24} md={8}>
          {(getValue(data, "type") === orderProductTypes.CARGO_OPEN_POLICY ||
            getValue(data, "type") ===
              orderProductTypes.CARGO_SINGLE_BOARDING) && (
            <>
              <div className="resumeDownloadButton">
                <PrintReport id={id} buttonProps={{ className: "gx-w-100" }} />
                <PrintReport
                  id={id}
                  buttonProps={{ className: "gx-w-100" }}
                  reportUrl={openPolicyQuotationUrl}
                  buttonChildren={translation(
                    "order.resume.downloadQuotationButton"
                  )}
                />
                <Button
                  ghost
                  type="primary"
                  className="gx-w-100"
                  onClick={() => setVisible(true)}
                >
                  <ShareAltOutlined />
                  {translation("order.resume.shareQuotationButton")}
                </Button>
              </div>
            </>
          )}
          {getValue(data, "type") === orderProductTypes.SURETY && (
            <div className="resumeDownloadButton">
              <PrintReport
                id={id}
                buttonProps={{ className: "gx-w-100" }}
                reportUrl={fileUrl}
                buttonChildren={translation("order.resume.downloadPDF")}
              />
            </div>
          )}
        </Col>
      </Row>
      {getValue(data, "type") === orderProductTypes.CARGO_OPEN_POLICY && (
        <Row type="flex" className="gx-mt-1">
          <Col sm={24} md={8} className="gx-align-self-start">
            <ResumeDataItem
              label={translation("order.resume.monthlyShipmentQuantityLabel")}
            >
              {getValue(getValue(data, "general"), "monthlyShipmentQuantity")}
            </ResumeDataItem>
          </Col>
          <Col sm={24} md={8} className="gx-align-self-start">
            <ResumeDataItem
              label={translation("order.resume.maximumAmountPerBoardingLabel")}
            >
              <FormattedValue
                type="currency"
                value={getValue(
                  getValue(data, "general"),
                  "maximumAmountPerBoarding"
                )}
              />
            </ResumeDataItem>
          </Col>
        </Row>
      )}
      {expressErrorsData && expressErrorsData.length > 0 && (
        <Row type="flex" justify="center">
          <Col xs={24} md={12}>
            <ResumeDataItem label={translation("order.resume.expressError")}>
              {expressErrorsData.map((error) => {
                return <span className="expressError-tag">{error}</span>;
              })}
            </ResumeDataItem>
          </Col>
        </Row>
      )}
    </div>
  ) : null;
};

export default OrderResume;
