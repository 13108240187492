import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { history } from "../history"
/*Reducers*/
import Settings from "./Settings";
import Users from "./Users";
import Crm from './Crm';
import Common from "./Common";
import Auth from "./Auth";

const reducers = combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    users: Users,
    crm: Crm,
    common: Common,
});

export default reducers;
