//Helper functions`
import { setInitialTranslation } from "helpers";
import { prepareTranslations } from "../../../../helpers";
//Constants
const translation = setInitialTranslation("order");

const keys = [
    {
        "key": "applicationDataTabPaneLabel",
        "type": "label"
    }, {
        "key": "orderLabel",
        "type": "label"
    },
    {
        "key": "applicationDataTabPaneLabel",
        "type": "label"
    },
    {
        "key": "insuredTabLabel",
        "type": "label"
    },
    {
        "key": "initialTabLabel",
        "type": "label"
    },
    {
        "key": "coveragesTabLabel",
        "type": "label"
    },
    {
        "key": "generalTabLabel",
        "type": "label"
    },
    {
        "key": "coveragesDetailsTabLabel",
        "type": "label"
    },
    {
        "key": "customerTabLabel",
        "type": "label"
    },
    {
        "key": "insuredLivesTabLabel",
        "type": "label"
    },
    {
        "key": "simulationsTabLabel",
        "type": "label"
    },
    {
        "key": "uploadTabLabel",
        "type": "label"
    },
    {
        "key": "whatsAppLabel",
        "type": "label"
    },
    {
        "key": "lifeProposalTabLabel",
        "type": "label"
    },
];

export default prepareTranslations(keys, translation);