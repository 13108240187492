//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("userList");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    spinLabel: getTranslation("spinLabel"),
    emptyLabel: getTranslation("emptyLabel"),
}