import React from 'react';
//Translation
import Translation from "./translation";
//Custom Css
import './Styles.less';
//Components
import { Row, Col, Icon, Tooltip} from "antd";
//Custom Components
import {Redirect} from "components";

const OrderHeader = (props) => {
    const {showDetails, fetchOrders, loadingOrders} = props;
    function requestOrders() {
        if (!loadingOrders)
            fetchOrders();
    }
    let content;

    if (showDetails)
        content = <Redirect to="/crm/orders">
            <Tooltip title={Translation.backLabel} placement="right">
                <Icon type="arrow-left" className="OrderHeader-icon"/>
            </Tooltip>
        </Redirect>;
    else
        content = <Row type="flex" gutter={8} align="middle">           
            <Col onClick={requestOrders}>
                <Tooltip title={Translation.reloadOrdersLabel} placement="right">
                    <Icon type={loadingOrders ? "sync" : "reload"} spin={loadingOrders} className="OrderHeader-icon"/>
                </Tooltip>
            </Col>
        </Row>;
    return (
        <div className="gx-module-box-topbar OrderHeader">
            {content}
        </div>
    );
};

export default OrderHeader;