import React from 'react';
//Components
import {Row, Col} from 'antd';
//Custom Helpers
import {formatMaskInput} from "../../helpers";
//Images
import whatsAppImage from './images/whats_app.png';
//Constants
const initialUrl = "whatsapp://send?phone=55";

const WhatsAppRedirect = (props) => {
    const {value, children, canReceive} = props;
    const formattedNumber = formatMaskInput(value);
    let redirectUrl;

    if (formattedNumber && canReceive)
        redirectUrl = `${initialUrl}${formattedNumber}`;

    if (redirectUrl) {
        return (
            <a href={redirectUrl} className="gx-link" target="_blank" rel="noopener noreferrer">
                <Row type="flex" gutter={8} align="bottom">
                    <Col>
                        {children}
                    </Col>
                    <Col>
                        <img
                            width={18}
                            height={18}
                            alt="WhatsApp"
                            src={whatsAppImage}/>
                    </Col>
                </Row>
            </a>
        )
    }
    return children
};

export default WhatsAppRedirect;