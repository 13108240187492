export default function (serverData, prevConfig) {

  function updateInitial() {
    const data = serverData.initial;
    const prevState = prevConfig.initial;
    return {
      name: data.name || prevState.name,
      email: data.email || prevState.email,
      phoneNumber: data.phoneNumber || prevState.phoneNumber,
      phoneType: data.phoneType || prevState.phoneType,
      receiveWhatsAppMessage: data.receiveWhatsAppMessage || prevState.receiveWhatsAppMessage,
      contactType: data.contactType || prevState.contactType,
      documentNumber: data.documentNumber || prevState.documentNumber,
      hasDocumentNumber: data.hasDocumentNumber || prevState.hasDocumentNumber
    }
  }

  function updateGeneral() {
    const prevState = prevConfig.general;
    const data = serverData.general;
    return {
      modals: data.modals || prevState.modals,
      companyActivity: data.companyActivity || prevState.companyActivity,
      insuredType: data.insuredType || prevState.insuredType,
      monthlyShipmentQuantity: data.monthlyShipmentQuantity || prevState.monthlyShipmentQuantity,
      averageAmountPerBoarding: data.averageAmountPerBoarding || prevState.averageAmountPerBoarding,
      maximumAmountPerBoarding: data.maximumAmountPerBoarding || prevState.maximumAmountPerBoarding,
      reason: data.reason || prevState.reason,
      startsAt: data.startsAt || prevState.startsAt,
      fleetSize: data.fleetSize || prevState.fleetSize
    }
  }

  function updateGeneralV2() {
    const prevState = prevConfig.generalV2;
    const data = serverData.generalV2;
    return {
      modals: data.modals || prevState.modals,
      companyActivity: data.companyActivity || prevState.companyActivity,
      insuredType: data.insuredType || prevState.insuredType,
      monthlyShipmentQuantity: data.monthlyShipmentQuantity || prevState.monthlyShipmentQuantity,
      averageAmountPerBoarding: data.averageAmountPerBoarding || prevState.averageAmountPerBoarding,
      maximumAmountPerBoarding: data.maximumAmountPerBoarding || prevState.maximumAmountPerBoarding,
      reason: data.reason || prevState.reason,
      startsAt: data.startsAt || prevState.startsAt,
      fleetSize: data.fleetSize || prevState.fleetSize
    }
  }

  function updatePlan() {
    const prevState = prevConfig.plan;
    const data = serverData.plan;
    return {
      ...prevState,
      ...data
    }
  }

  function updateRoutes() {
    const prevState = prevConfig.routes;
    const data = serverData.routes;
    return {
      routes: data.routes || prevState.routes
    }
  }

  function updateCoverage() {
    const prevState = prevConfig.coverage;
    const data = serverData.coverage;
    return {
      theft: data.theft || prevState.theft,
      other: data.other || prevState.other,
      damage: data.damage || prevState.damage,
      accident: true,
      lifeInsurance: data.lifeInsurance || prevState.lifeInsurance,
      trackCleaning: data.trackCleaning || prevState.trackCleaning,
      environmentalLiabilityRisk: data.environmentalLiabilityRisk || prevState.environmentalLiabilityRisk,
      otherDescription: data.otherDescription || prevState.otherDescription,
      loadingUnloading: data.loadingUnloading || prevState.loadingUnloading,
      loadingUnloadingLifting: data.loadingUnloadingLifting || prevState.loadingUnloadingLifting,
      freight: data.freight || prevState.freight,
      fluvialRoute: data.fluvialRoute || prevState.fluvialRoute,
    }
  }


  function updateInsured() {
    const data = serverData.insured;
    const prevState = prevConfig.insured;
    return {
      documentNumber: data.documentNumber || prevState.documentNumber,
      type: data.type || prevState.type,
      name: data.name || prevState.name,
      zipCode: data.zipCode || prevState.zipCode,
      address: data.address || prevState.address,
      number: data.number || prevState.number,
      neighborhood: data.neighborhood || prevState.neighborhood,
      state: data.state || prevState.state,
      city: data.city || prevState.city,
      additionalData: data.additionalData || prevState.additionalData,
    }
  }


  function updateGoods() {
    const prevState = prevConfig.goods;
    const data = serverData.goods;
    return data.goods || prevState
  }


  function updateHistoric() {
    const data = serverData.historic;
    const prevState = prevConfig.historic;
    return {
      accidents: data.accidents || prevState.accidents,
      hasPreviousInsurance: data.hasPreviousInsurance || prevState.hasPreviousInsurance,
      previousInsurer: data.previousInsurer || prevState.previousInsurer,
      minimumPremium: data.minimumPremium || prevState.minimumPremium,
      contractedCoverages: data.contractedCoverages || prevState.contractedCoverages,
      maximumGuaranteeLimit: data.maximumGuaranteeLimit || prevState.maximumGuaranteeLimit,
      monthlyInvoiceAmount: data.monthlyInvoiceAmount || prevState.monthlyInvoiceAmount,
      rateOrDiscountApplied: data.rateOrDiscountApplied || prevState.rateOrDiscountApplied,
      additionalInformation: data.additionalInformation || prevState.additionalInformation,
    };
  }


  function updateOperational() {
    const data = serverData.operational;
    const prevState = prevConfig.operational;
    return {
      fleets: data.fleets || prevState.fleets,
      hasExclusivity: data.hasExclusivity || prevState.hasExclusivity,
      exclusivityCompanies: data.exclusivityCompanies || prevState.exclusivityCompanies,
      hasDDR: data.hasDDR || prevState.hasDDR,
      shipper: data.shipper || prevState.shipper,
      registeredDrivers: data.registeredDrivers || prevState.registeredDrivers,
      riskManagementCompanies: data.riskManagementCompanies || prevState.riskManagementCompanies,
      logistic: data.logistic || prevState.logistic,
      exemptionFromRegistration: data.exemptionFromRegistration || prevState.exemptionFromRegistration,
      oneResponsibleForStorage: data.oneResponsibleForStorage || prevState.oneResponsibleForStorage,
      transportDocuments: data.transportDocuments || prevState.transportDocuments,
      riskManagement: data.riskManagement || prevState.riskManagement,
      trackings: data.trackings || prevState.trackings
    }
  }

  function updateQuotations() {
    const data = serverData;
    const prevState = prevConfig;
    return data.quotationData ? data.quotationData : prevState.quotationData
  }


  return {
    initial: updateInitial(),
    general: updateGeneral(),
    generalV2: updateGeneralV2(),
    plan: updatePlan(),
    routes: updateRoutes(),
    coverage: updateCoverage(),
    insured: updateInsured(),
    goods: updateGoods(),
    historic: updateHistoric(),
    operational: updateOperational(),
    quotationData: updateQuotations(),
  };
}
