import React from 'react';
import Routes from './Routes';

const RoutesContent = (props) => {
  const { parentRef, configurations, notifyChanges,OrderErrors,RemoveError} = props;
  return (
    <section>
      <Routes
        ref={parentRef}
        data={configurations.routes.routes}
        general={configurations.general}
        notifyChanges={notifyChanges}
        OrderErrors={OrderErrors}
        RemoveError={RemoveError} />
    </section>
  );

};


export default RoutesContent