import React, { useState, useEffect } from 'react'
import { Row, Col, Icon, message } from 'antd'
import Moment from 'moment'
import Axios from 'axios'
import translation from './translation'
const WhatsAppHistory = ({ code, activeKey }) => {

    const [historyData, setHistoryData] = useState([])
    const [loading, setLoading] = useState(false)

    const requestHistoryData = () => {
        setLoading(true)
        return Axios.get(`${window._env_.API_GATEWAY_URL}/notification/v1/whatsapp/template/historic/${code}`)
            .then(response => { 
                setHistoryData(response.data) 
                setLoading(false)
            })
            .catch((error)=> {
                message.error(error.message)
                setLoading(false)
            })
    }

    useEffect(() => {
        if(activeKey === 'history'){
            requestHistoryData()
        }
    }, [activeKey])
    return (
        loading ? 
            <>
                <div className="loading-history">
                    <Icon type="loading" className="loader" spin></Icon>
                    <span>{translation.whatsAppHistoryLoadingLabel}</span>
                </div>
            </>
        :
            historyData.length ?
                <>
                    <Row gutter={8}>
                        <Col md={7}>
                            <div className="history-col-item">
                                <span className="col-title">{translation.whatsAppHistoryColumnDateLabel}</span>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="history-col-item">
                                <span className="col-title">{translation.whatsAppHistoryColumnTemplateLabel}</span>
                            </div>
                        </Col>
                        <Col md={7}>
                            <div className="history-col-item">
                                <span className="col-title">{translation.whatsAppHistoryColumnMotiveLabel}</span>
                            </div>
                        </Col>
                        <Col md={5}>
                            <div className="history-col-item">
                                <span className="col-title">{translation.whatsAppHistoryColumnSellerLabel}</span>
                            </div>
                        </Col>
                    </Row>
                    {
                        historyData.map((item, index) => {
                            return (
                                <Row key={index} gutter={8} className="history-content-row">
                                    <Col md={7}>
                                        <div className="history-col-item">
                                            <span className="col-content">
                                                {Moment(item.sentAt).format('DD/MM/YYYY h:mm:ss')}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="history-col-item">
                                            <span className="col-content">
                                                {item.templateName}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={7}>
                                        <div className="history-col-item">
                                            <span className="col-content">
                                                {item.comment}
                                            </span>
                                        </div>
                                    </Col>
                                    <Col md={5}>
                                        <div className="history-col-item">
                                            <span className="col-content">
                                                {item.user}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            )
                        })
                    }

                </>
                :
                <>
                    <div className="empty-history">
                        <img src={require('assets/images/no-history-icon.png')} alt="" />
                        <span>{translation.whatsAppHistoryEmptyMessage}</span>
                    </div>
                </>
    )
}

export default WhatsAppHistory