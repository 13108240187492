import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation('customers');

const keys = [
    {
        "key":"policyStaticDataTab",
        "type": "label"
    },
    {
        "key":"policyDataTab",
        "type": "label"
    },
    {
        "key":"policyFinancialTab",
        "type": "label"
    },
    {
        "key":"policySinisterTab",
        "type": "label"
    },
    {
        "key":"policyEndorsementTab",
        "type": "label"
    },
    {
        "key":"policyProductLabel",
        "type": "label"
    },
    {
        "key":"policyInsurerLabel",
        "type": "label"
    },
    {
        "key":"policyTypeLabel",
        "type": "label"
    },
    {
        "key":"policyNumberLabel",
        "type": "label"
    },
    {
        "key":"policyValidityLabel",
        "type": "label"
    },
    {
        "key":"policyExpirationLabel",
        "type": "label"
    },
    {
        "key":"customerHeaderLabel",
        "type": "label"
    },
    {
        "key":"documentNumberHeaderLabel",
        "type": "label"
    },
    {
        "key":"policyBackButtonLabel",
        "type": "label"
    },
    {
        "key":"newPolicyButtonLabel",
        "type": "label"
    },
    {
        "key": "newInvoiceLabel",
        "type": "label"
    },
    {
        "key":"noData",
        "type": "label"
    },
    {
        "key":"updateInvoiceListButton",
        "type": "label"
    },
]


export default prepareTranslations(keys, translation);