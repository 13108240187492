import React from 'react';
import {Input} from "antd";
import * as PropTypes from 'prop-types';
//Custom Styles
import './Styles.less'
// Others
import NumberFormat from 'react-number-format';
import {injectIntl, intlShape} from 'react-intl';

function CurrencyInput(props) {
  const {
    allowNegative, displayType, readOnly, prefix, customInput,
    decimalSeparator, decimalScale, fixedDecimalScale, thousandSeparator, onChange,
    value, intl, placeholder, suffix, ...otherProps
  } = props;

  let currentPlaceholder = placeholder;

  if (typeof placeholder === 'object')
    currentPlaceholder = intl.formatMessage(placeholder);

  function handleValueChange(values) {
    return onChange && onChange(values)
  }

  function getValue() {
    if (displayType === "input") {
      if (typeof value === "object")
        return value && value.formattedValue;
      else
        return value;
    }
    return value;
  }

  const updatedValue = getValue();

  return (
    <NumberFormat{...otherProps}
                 value={updatedValue}
                 prefix={`${prefix} `}
                 suffix={suffix}
                 displayType={displayType}
                 readOnly={readOnly}
                 customInput={customInput}
                 allowNegative={allowNegative}
                 decimalSeparator={decimalSeparator}
                 thousandSeparator={thousandSeparator}
                 decimalScale={decimalScale}
                 fixedDecimalScale={fixedDecimalScale}
                 placeholder={currentPlaceholder}
                 onValueChange={handleValueChange}
    />
  );
}

CurrencyInput.propTypes = {
  displayType: PropTypes.string,
  mask: PropTypes.string,
  prefix: PropTypes.string,
  fixedDecimalScale: PropTypes.bool,
  thousandSeparator: PropTypes.string,
  decimalSeparator: PropTypes.string,
  allowNegative: PropTypes.bool,
  readOnly: PropTypes.bool,
  intl: intlShape.isRequired,
  placeholder: PropTypes.object,
};

CurrencyInput.defaultProps = {
  displayType: 'input',
  prefix: 'R$',
  customInput: Input,
  thousandSeparator: '.',
  decimalScale: 2,
  fixedDecimalScale: true,
  decimalSeparator: ',',
  allowNegative: false,
  readOnly: false,
  thousandsGroupStyle:"thousand",
  type:"text",
  allowLeadingZeros:true
};

export default injectIntl(CurrencyInput);
