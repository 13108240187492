import React from "react";
//componets
import { Button, Upload } from "antd";
import { Download, Upload as UploadIcon, Trash2 } from "react-feather";

//custom styles
import "./styles.less";

const UploadFile = ({
  uploadText,
  downloadText,
  hasFile,
  disabledUpload,
  disabledRemove,
  uploadProps,
  onRemove,
  onDownload,
  downloadButtonProps,
}) => {
  return (
    <div className="uploadFile">
      <div className="uploadWrapper">
        <div className="buttonWrapper">
          {hasFile ? (
            <div className="downloadWrapper">
              <Button
                ghost
                className="gx-m-0"
                type="primary"
                onClick={onDownload}
                {...downloadButtonProps}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Download size={18} />
                </div>
                {downloadText}
              </Button>
              <Button
                disabled={disabledRemove}
                onClick={onRemove}
                className="uploadTrash gx-m-0 gx-p-0"
              >
                <Trash2 />
              </Button>
            </div>
          ) : (
            <Upload {...uploadProps} disabled={disabledUpload}>
              <Button
                disabled={disabledUpload}
                className="gx-btn-outline-info gx-w-100 gx-m-0"
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <UploadIcon size={18} />
                </div>
                {uploadText}
              </Button>
            </Upload>
          )}
        </div>
      </div>
    </div>
  );
};

export default UploadFile;
