import {all, fork, put, takeEvery} from "redux-saga/effects";
import {
    SIGNIN_USER,
    SIGNOUT_USER,
    SIGNIN_USER_FAILED
} from "../../actionTypes";
//Actions
import {
    showAuthMessage,
    userSignInSuccess,
    userSignOutSuccess
} from "../../actionCreators/Auth";
import { notification } from "antd";
//Helpers
import Request from 'helpers/Request';
import {validateResponse} from 'helpers/response';

function* signInUserWithEmailPassword(action) {
    const {payload} = action;
    try {
        const response = yield Request.post(`/user/v1/login`, payload);
        const {payloadData, success} = yield  validateResponse({response, displaySuccessMessage: false});
        if (success) {
            yield put(userSignInSuccess(payloadData));
        } else {
            notification.error({ message: payloadData.message });
            yield put({type: SIGNIN_USER_FAILED})
        }
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* signOut() {
    try {
        yield put(userSignOutSuccess(signOutUser));
    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(signOutUser)
    ]);
}
