//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation('customers');

const keys = [
  {
    "key": "fetchingCustomersLabel",
    "type": "label"
  },
  {
    key: "backButtonLabel",
    type: "label",
  },
];

export default prepareTranslations(keys, translation);