import React from 'react';
//Translation
import Translation from "./translation";
//Components
import {Row, Col, Select} from "antd";
//Custom Components
import {MuPlaces, CurrencyInput, Switch,NumberInput, FormField} from "components";
//Constants
import selections from "constants/selections";
//Helpers
import {prepareOptions} from "../../../../../../helpers";

const General = (props) => {
  const {handleChange, configurations, OrderErrors} = props;

  const {origin, destination, cargoTotalAmount,travelAverageAmount, currency, companyActivity,merchandiseOwner, anyUsedMerchandise} = configurations;

  const selectPlace = (attr) => (place) => {
    const terms = () => {
      let termList = []
      place.terms.forEach(term => {
        termList.push({
          offset: term.offset,
          value: term.value
        })
      });

      return termList
    };

    const data = {
      description: place.description,
      placeId: place.id,
      terms: terms()
    };

    return handleChange({
      attr: attr, 
      value: data
    });
  };

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;

    if (!isDirect && typeof event === "object") {
      if (event instanceof Array)
        value = event;
      else
        value = event.target.value;
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  const toggleValue = (attr) => (event) => {
    return handleChange({
      attr: attr,
      value: !configurations[attr]
    });
  };

  let currencyPrefix;

  if (currency)
    currencyPrefix = selections.currencies.find(entry => entry.value === currency).symbol;


  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.merchandiseOwnerLabel}:
        </Col>
        <Col xs={24} md={18}>
        <NumberInput
              placeholder={merchandiseOwner === "OWN_MERCHANDISE" ? Translation.ownMerchandiseSinglePlaceholder : Translation.thirdyPartyMerchandiseSinglePlaceholder }
              disabled
              className="gx-w-100"
              />
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.companyActivityLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors['general.companyActivity']}>
            <Select
              value={companyActivity}
              className="gx-w-100"
              placeholder={Translation.companyActivityPlaceholder}
              onChange={onChange("companyActivity")}>
              {prepareOptions(selections.companyActivity)}
            </Select>
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.currencyLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors['general.currency']}>
          <Select
            value={currency}
            className="gx-w-100"
            placeholder={Translation.currencyPlaceholder}
            onChange={onChange("currency")}>
            {prepareOptions(selections.currencies)}
          </Select>
        </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.totalAmountLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors['general.cargoTotalAmount']}>
          <CurrencyInput
            value={cargoTotalAmount}
            prefix={currencyPrefix}
            onChange={onChange("cargoTotalAmount", true)}
            placeholder={Translation.totalAmountPlaceholder}/>
        </FormField>
        </Col>
      </Row>
      
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.travelAverageAmountLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors['general.travelAverageAmount']}>
          <NumberInput
            className="gx-w-100"
            value={travelAverageAmount}
            onChange={onChange("travelAverageAmount", true)}
            placeholder={Translation.travelAverageAmountPlaceholder}/>
        </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.originLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors['general.origin']}>
            <MuPlaces
              required
              initialValue={origin && origin.description }
              onSelect={selectPlace("origin")}
              id="origin-google-places-autocomplete-input"
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.destinationLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors['general.destination']}>
          <MuPlaces
            required
            initialValue={destination && destination.description }
            onSelect={selectPlace("destination")}
            id="destination-google-places-autocomplete-input"
          />
        </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.anyUsedMerchandiseLabel}:
        </Col>
        <Col xs={24} md={18}>
          <Switch
            onChange={toggleValue("anyUsedMerchandise")}
            checked={anyUsedMerchandise}/>
        </Col>
      </Row>
    </section>
  );
};

export default General;