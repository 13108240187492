export default {
    initial: {},
    obligee: {},
    term: {
        "contractAmount": ["GENERAL", "CONTRACT", "BIDDING", "ADVANCE_PAYMENT"],
        "suretyPercentage": ["GENERAL", "CONTRACT", "BIDDING", "ADVANCE_PAYMENT"],
        "bidNumber": ["BIDDING"],
        "bidDate": ["BIDDING"],
        "obligation": ["BIDDING", "ADVANCED_PAYMENT", "CONTRACT", "GENERAL"],
        "contractDate": ["CONTRACT", "BUSINESS_SERVICES_BOND", "SUPPLY_BOND"],
        "contractNumber": ["BIDDING", "ADVANCED_PAYMENT", "CONTRACT", "GENERAL"],
        "labourCoverage": ["CONTRACT", "ADVANCED_PAYMENT", "CONTRACT", "GENERAL"],
        "depositAppeal": ["LABOUR_DEPOSIT_APPEAL"],
        "appealType": ["LABOUR_DEPOSIT_APPEAL"],
        "penaltyCoverage": ["CONTRACT", "GENERAL"],
        "installment": ["ADVANCED_PAYMENT"],
        "paymentAdvancePercentage": ["ADVANCED_PAYMENT"]
    },
    detail: {},
    payment: {},
};