import React, {Component} from "react";
//Translation
import Translation from './translation';
//Custom Css
import './Styles.less'
//Components
import {Tabs, Button, Card, Empty, Row, Col, Select, Tooltip} from "antd";
import {CurrencyInput} from "components";
import produce from "immer";
import {cloneArray, formatSelectValue} from "../../helpers";
//Constants
const {TabPane} = Tabs;
const {Option} = Select;
const groupTypeOptions = [
    {
        value: "PARTNERS",
        label: Translation.partnerOptionLabel
    },
    {
        value: "EMPLOYEES",
        label: Translation.employeeOptionLabel
    },
    {
        value: "INTERNS",
        label: Translation.internsOptionLabel
    },
    {
        value: "SERVICE_PROVIDERS",
        label: Translation.serviceProvidersOptionLabel
    },
    {
        value: "ASSOCIATES",
        label: Translation.associatesOptionLabel
    },
    {
        value: "MEMBERS",
        label: Translation.membersOptionLabel
    },
    {
        value: "STUDENTS",
        label: Translation.studentsOptionLabel
    },
    {
        value: "OTHERS",
        label: Translation.othersOptionLabel

    }
];

const HeaderContent = (props) => {
    const {handleCreate, selected} = props;
    const disabled = selected.length === groupTypeOptions.length;
    return (
        <section>
            <Tooltip placement="bottom" title={disabled ? Translation.noGroup : Translation.groupAdd}>
                {disabled ? <Button disabled={true} icon="plus">{Translation.add}</Button> :
                    <Button
                        icon="plus"
                        onClick={handleCreate}
                        className="gx-btn-outline-primary"
                        htmlType="button">
                        {Translation.add}
                    </Button>
                }
            </Tooltip>
        </section>
    )
};

const GroupContent = (props) => {
    const {group, options, selected, coverageAmount, groupIndex, handleChange} = props;
    return (
        <Row type="flex" align="middle" gutter={8} key={groupIndex}>
            <Col xs={24} md={8}>
                <Select
                    value={formatSelectValue(group)}
                    placeholder={Translation.groupPlaceholder}
                    onChange={handleChange(groupIndex, "group")}
                    // getPopupContainer={trigger => trigger.parentNode}
                    className="gx-w-100">
                    {options.map(option => {
                            const isDisabled = selected.find(entry => entry.group === option.value);
                            return (
                                <Option style={isDisabled && {display: 'none'}} key={option.value} value={option.value}>{option.label}</Option>
                            )
                        }
                    )}
                </Select>
            </Col>
            <Col xs={24} md={16}>
                <CurrencyInput
                    value={coverageAmount}
                    disabled={!group}
                    onChange={handleChange(groupIndex, "coverageAmount")}
                    placeholder={Translation.amountPlaceholder}/>
            </Col>
        </Row>
    )
};

class Groups extends Component {
    constructor(props) {
        super(props);
        this.groupsRef = React.createRef();
        this.state = Groups.emptyState(props.data);
    }

    static emptyState(insurableGroups) {
        const draft = cloneArray(insurableGroups, "object");
        return {
            insurableGroups: insurableGroups,
            draft: draft
        }
    }

    static emptyGroup() {
        return {
            group: null,
            coverageAmount: null
        }
    }

    static parseGroups(data) {
        return data.map(entry => {
            let updatedAmount;
            if (entry.hasOwnProperty("coverageAmount")) {
                if (typeof entry.coverageAmount === "object")
                    updatedAmount = entry.coverageAmount.floatValue;
                else
                    updatedAmount = entry.coverageAmount;
            }
            return {
                ...entry,
                coverageAmount: updatedAmount
            }
        })
    };


    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     if (prevState.draft !== this.props.data) {
    //         this.reformatOrderGroups(this.props.data);
    //     }
    // }

    reformatOrderGroups = (newData) => {
        const newState = Groups.emptyState(newData);
        this.setState(newState);
    };

    reset = () => {
        this.setState(prevState => {
            return {
                insurableGroups: prevState.draft
            }
        })
    };


    getChanges = () => {
        const insurableGroups = this.state.insurableGroups;
        return {
            insurableGroups: Groups.parseGroups(insurableGroups)
        }
    };

    scrollToBottom = () => {
        if (this.groupsRef && this.groupsRef.current)
            this.groupsRef.current.scrollTop = this.groupsRef.current.scrollHeight;
    };

    notifyChanges = () => {
        return this.props.notifyChanges();
    };

    handleGroupChange = (index, attr) => (event) => {
        this.setState((state) => {
            return produce(state, draft => {
                draft.insurableGroups[index][attr] = event;
            })
        }, this.notifyChanges)
    };

    handleGroupCreate = () => {
        this.setState((state) => {
            return produce(state, draft => {
                draft.insurableGroups.push(Groups.emptyGroup())
            })
        }, () => {
            this.scrollToBottom();
            this.notifyChanges();
        })
    };

    handleGroupRemove = (removalIndex) => () => {
        this.setState((state) => {
            return produce(state, draft => {
                draft.insurableGroups.splice(removalIndex, 1);
            })
        }, this.notifyChanges)
    };

    render() {
        const {insurableGroups} = this.state;
        const groupsLengthCheck = insurableGroups.length === 0;

        return (
            <Card size="small" className="groups">
                <Tabs
                    type="card"
                    hideAdd
                    activeKey={"group"}
                    className={groupsLengthCheck ? "groups-empty" : "groups-tabs"}
                    tabBarExtraContent={<HeaderContent selected={insurableGroups} handleCreate={this.handleGroupCreate}/>}>
                    <TabPane tab={Translation.groupLabel} key="group">
                        {groupsLengthCheck ?
                            <Empty description={Translation.groupEmptyLabel}
                                   className="groups-empty-Wrapper"/> :
                            <section>
                                <div ref={this.groupsRef} className="phone-items">
                                    {insurableGroups.map((entry, groupIndex) => {
                                        const {group, coverageAmount} = entry;
                                        const rowClassName = groupIndex === 0 ? "phone-items-sticky" : "gx-mt-3";
                                        return (
                                            <Row key={groupIndex} type="flex" gutter={8} className={rowClassName}>
                                                <Col xs={24} lg={22}>
                                                    <GroupContent
                                                        group={group}
                                                        groupIndex={groupIndex}
                                                        options={groupTypeOptions}
                                                        selected={insurableGroups}
                                                        coverageAmount={coverageAmount}
                                                        handleChange={this.handleGroupChange}/>
                                                </Col>
                                                <Col xs={24} lg={2}>
                                                    <Button onClick={this.handleGroupRemove(groupIndex)}
                                                            shape="circle-outline"
                                                            className={`phone-items-delete-btn`}
                                                            icon="delete"
                                                            htmlType="button"/>
                                                </Col>
                                            </Row>
                                        )
                                    })}
                                </div>
                            </section>
                        }
                    </TabPane>
                </Tabs>
            </Card>
        );
    }
}

export default Groups;