import React, { useState, useEffect } from "react";
//router
import { useHistory, useParams } from "react-router-dom";
// new redux
import { useSelector, useDispatch } from "react-redux";
import { fetchCustomerData } from "../../../../store/slices/customer";
import {
  setErrors,
  updatePolicy,
  setIsChanged,
  createPolicy,
  setPolicyData,
  setCurrentTab,
  fetchPolicyData,
  fetchPolicyList,
  fetchPolicyTypes,
  changePolicyData,
  removePolicyFile,
  sendPolicyByEmail,
  sendSuspendInvoice,
  setEnableChange,
  sendCancelSuspendInvoice,
} from "../../../../store/slices/policy";
import { fetchUsers } from "../../../../store/slices/user";
//components
import { Col, Row, Tabs, Modal, Card, Button, Spin } from "antd";
import { FormattedValue } from "components";
import PolicyStaticData from "../../../../components/Customers/Policy/PolicyStaticData";
import PolicyData from "../../../../components/Customers/Policy/PolicyData";
import Financial from "../../../../components/Customers/Policy/Financial";
import Endorsement from "./Endorsement";
//translation
import translation from "./translation";
import { useTranslation } from "react-i18next";
// helpers
import { clearError, formatMaskInput, formatPhone } from "../../../../helpers";
// moment
import moment from "moment";
//custom style
import "./styles.less";
//constants
import { policyTabs, taxByProduct, customerTabs } from "../../../../constants";
import { MuTabs } from "../../../../components";

const { TabPane } = Tabs;
const Policies = ({ children, redirectToCustomerTab }) => {
  //state
  const [isModalVisible, setIsModalVisible] = useState(true);
  const [newEndorsementModal, setNewEndorsementModal] = useState(false);

  //selector and dispatch
  const {
    user: { userList },
    common: { isLoading },
    customer: { registerData },
    policy: {
      policy,
      errors,
      policyTypes,
      currentTab,
      isChanged,
      enableChange,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const { t: Translation } = useTranslation();

  //router
  const history = useHistory();
  const params = useParams();

  //functions
  const redirectToPolicies = () => {
    setIsModalVisible(false);
    dispatch(setIsChanged(false));
    dispatch(fetchPolicyList({ code: params.id, pageSize: 15 }));
    history.push(`/crm/customers/${params.id}`);
  };

  const confirmModal = () => {
    Modal.confirm({
      title: "Tem certeza que deseja sair sem salvar os dados?",
      onOk() {
        redirectToPolicies();
      },
      onCancel() {
        return false;
      },
      okText: "Confirmar",
      cancelText: "Cancelar",
    });
  };

  const onCloseModal = () => {
    if (isChanged) {
      confirmModal();
    } else {
      redirectToPolicies();
    }
  };

  const getValue = (entity, property) => {
    if (entity && entity.hasOwnProperty(property)) return entity[property];
    return null;
  };

  const getSalesRepresentative = () =>
    userList
      .filter((item) => item.salesProfile)
      .sort((a, b) => a.name.localeCompare(b.name));

  const getPreSalesRepresentative = () =>
    userList
      .filter((item) => item.preSalesProfile)
      .sort((a, b) => a.name.localeCompare(b.name));

  const getUserById = (id) => {
    const user = userList.find((item) => item.id === id);
    return user ? user.name : "";
  };

  const getTaxValue = (type) => {
    return type ? taxByProduct[type.product] : 0;
  };

  const calculateMinPremium = (value) => {
    return value ? value + (value * getTaxValue(policy.type)) / 100 : 0;
  };

  const setPremiumWithTax = (value) => {
    dispatch(changePolicyData({ premium: calculateMinPremium(value) }));
    dispatch(changePolicyData({ tax: getTaxValue(policy.type) }));
  };

  const savePolicyData = () => {
    if (params.policy !== "newpolicy") {
      dispatch(updatePolicy(params.id, params.policy)).then(() => {
        dispatch(setEnableChange(false));
        dispatch(fetchPolicyData(params.id, params.policy));
      });
    } else {
      dispatch(createPolicy(params.id)).then(() => {
        redirectToPolicies();
        redirectToCustomerTab(customerTabs.currentPolicy);
      });
    }
    dispatch(setIsChanged(false));
  };

  const handleChangeTab = (e) => {
    dispatch(setCurrentTab(e));
  };

  const handleOpenInvoice = () => {
    history.push(`${history.location.pathname}/invoice/new`);
  };

  const handleUpdateInvoiceList = () => {
    dispatch(fetchPolicyData(params.id, params.policy));
  };

  const handlePolicyChange = (name) => (event) => {
    let value;
    if (event) {
      event.hasOwnProperty("target")
        ? (value = event.target.value)
        : (value = event);
    } else {
      value = event;
    }

    dispatch(setIsChanged(true));
    dispatch(setErrors(clearError(name, errors)));

    if (name === "type") {
      let temp = policyTypes.filter((item) => item.id === value);
      value = {
        product: getValue(policy.type, "product"),
        id: value,
        description: temp[0] ? temp[0].description : "",
      };
    }

    if (name === "product") {
      dispatch(changePolicyData({ insurer: null }));
      if (value === "LIFE") dispatch(changePolicyData({ agencyRate: 0 }));
      value = {
        [name]: value,
      };
      name = "type";
    }

    if (name === "netPremium") {
      value = value.floatValue;
      setPremiumWithTax(value);
      dispatch(setErrors(clearError("premium", errors)));
    }

    if (
      name === "suretyAmount" ||
      name === "maximumGuaranteeLimit" ||
      name === "commission" ||
      name === "businessPartnerCommission" ||
      name === "crossSellingCommission" ||
      name === "rate"
    ) {
      value = value.floatValue;
    }

    if (name === "hasCrossSellingSales" && !value) {
      dispatch(
        changePolicyData({
          crossSellingRepresentativeId: null,
          crossSellingCommission: null,
        })
      );
    }

    if (name === "insuredDocumentNumber") {
      value = formatMaskInput(value);
      dispatch(setErrors(clearError("insuredDocumentNumber", errors)));
    }

    if (name === "initialTermAt") {
      dispatch(
        changePolicyData({
          finalTermAt: moment(value).add(1, "year"),
        })
      );
      dispatch(setErrors(clearError("finalTermAt", errors)));
    }

    if (name === "registerPhoneNumber") {
      value = formatPhone(value);
      dispatch(setErrors(clearError("registerPhoneNumber", errors)));
    }

    dispatch(changePolicyData({ [name]: value }));
  };

  const handleSendByEmail = () => {
    dispatch(sendPolicyByEmail(params.id, params.policy));
  };

  const handleSuspendInvoices = () => {
    dispatch(sendSuspendInvoice(params.id, params.policy)).then(() => {
      dispatch(fetchPolicyData(params.id, params.policy));
    });
  };

  const handleCancelSuspendInvoices = () => {
    dispatch(sendCancelSuspendInvoice(params.id, params.policy)).then(() => {
      dispatch(fetchPolicyData(params.id, params.policy));
    });
  };

  const handleUploadFile = (file) => {
    dispatch(changePolicyData({ policyFile: file.response }));
    dispatch(fetchPolicyData(params.id, params.policy));
  };

  const handleRemoveFile = (file) => {
    const modal = Modal.confirm({
      maskClosable: true,
      className: "remove-policy-comfirm",
      content: (
        <>
          <Row className="gx-mb-3">
            Tem certeza que deseja remover o documento atual da apólice?
          </Row>
          <Row type="flex" justify="end">
            <Button onClick={() => modal.destroy()}>Cancelar</Button>
            <Button onClick={onOk} type="primary">
              Confirmar
            </Button>
          </Row>
        </>
      ),
    });
    function onOk() {
      modal.destroy();
      dispatch(
        removePolicyFile(params.id, params.policy, policy.policyFile.name)
      );
    }
  };

  const handleEnableChange = (value) => {
    dispatch(setEnableChange(value));
  };

  const getTabsExtraContent = () => {
    if (params.policy === "newpolicy") {
      return null;
    }
    if (currentTab == 3) {
      return (
        <>
          <Button onClick={handleUpdateInvoiceList}>
            {translation.updateInvoiceListButton}
          </Button>
          <Button onClick={handleOpenInvoice}>
            {translation.newInvoiceLabel}
          </Button>
        </>
      );
    }
    if (currentTab == 5) {
      return (
        <Button
          ghost
          type="primary"
          onClick={() => setNewEndorsementModal(true)}
        >
          {Translation("endorsement.header.createEndorsementButton")}
        </Button>
      );
    }
  };

  useEffect(() => {
    if (params.policy !== "newpolicy") {
      dispatch(fetchPolicyData(params.id, params.policy));
    }
    dispatch(fetchCustomerData(params.id));
    dispatch(setIsChanged(false));
    dispatch(fetchPolicyTypes());
    dispatch(fetchUsers());
  }, []);

  useEffect(() => {
    setPremiumWithTax(policy.netPremium);
  }, [policy.type]);

  useEffect(() => {
    return () => {
      dispatch(setErrors([]));
      dispatch(setPolicyData({}));
      dispatch(setEnableChange(false));
      dispatch(setCurrentTab(2));
    };
  }, []);

  useEffect(() => {
    if (params.policy === "newpolicy") {
      dispatch(setCurrentTab(2));
    }
  }, []);

  return (
    <>
      <Modal
        width="80%"
        destroyOnClose
        visible={isModalVisible}
        footer={null}
        onCancel={onCloseModal}
        centered={true}
        wrapClassName="policy-modal-container"
      >
        <Spin spinning={isLoading}>
          <Row type="flex" className="gx-mt-3 gx-px-3">
            <Col xs={24} md={12} className="gx-m-0 gx-p-0">
              <Row type="flex" justify={"start"} className="gx-mt-2">
                {translation.customerHeaderLabel}:
                <span className="gx-ml-2">
                  {getValue(registerData, "companyName") || translation.noData}
                </span>
              </Row>
            </Col>
            <Col xs={24} md={12} className="gx-m-0 gx-p-0">
              <Row type="flex" justify={"end"} className="gx-mt-2">
                {translation.documentNumberHeaderLabel}:
                <span className="gx-ml-2">
                  <FormattedValue
                    mask="_"
                    displayType="text"
                    value={getValue(registerData, "documentNumber")}
                    format={"COMPANY"}
                  />
                </span>
              </Row>
            </Col>
          </Row>
          <Row type="flex" className="gx-mt-3 gx-px-3">
            <Col md={24} className="gx-m-0 gx-p-0">
              <div className="gx-m-0">
                <MuTabs
                  activeKey={currentTab.toString()}
                  tabBarExtraContent={getTabsExtraContent()}
                  onChange={handleChangeTab}
                  className="gx-mb-30"
                  product={getValue(policy?.type, "product") || ""}
                >
                  <TabPane
                    tab={translation.policyDataTab}
                    key={policyTabs.policyData}
                  >
                    <PolicyData
                      policy={policy}
                      getValue={getValue}
                      savePolicyData={savePolicyData}
                      params={params}
                      errors={errors}
                      types={policyTypes}
                      sales={getSalesRepresentative()}
                      presales={getPreSalesRepresentative()}
                      updatedBy={getUserById(policy.updatedBy)}
                      enableChange={enableChange}
                      setEnableChange={handleEnableChange}
                      onChange={handlePolicyChange}
                      onSendByEmail={handleSendByEmail}
                      onSuspendInvoice={handleSuspendInvoices}
                      onCancelSuspendInvoice={handleCancelSuspendInvoices}
                      onUploadFile={handleUploadFile}
                      onRemoveFile={handleRemoveFile}
                    />
                  </TabPane>
                  <TabPane
                    disabled={params.policy === "newpolicy"}
                    tab={translation.policyFinancialTab}
                    key={policyTabs.financial}
                  >
                    <Financial policy={policy} getValue={getValue} />
                  </TabPane>
                  <TabPane
                    disabled={params.policy === "newpolicy"}
                    tab={translation.policySinisterTab}
                    key={policyTabs.claims}
                  >
                    Sinistros
                  </TabPane>
                  <TabPane
                    disabled={params.policy === "newpolicy"}
                    tab={translation.policyEndorsementTab}
                    key={policyTabs.endorsements}
                  >
                    <Endorsement
                      showModal={newEndorsementModal}
                      setShowModal={setNewEndorsementModal}
                    />
                  </TabPane>

                  <TabPane
                    tab={translation.policyStaticDataTab}
                    key={policyTabs.policyStaticData}
                    disabled={params.policy === "newpolicy"}
                  >
                    <PolicyStaticData
                      policy={policy.staticData}
                      types={policyTypes}
                      users={getSalesRepresentative()}
                    />
                  </TabPane>
                </MuTabs>
              </div>
            </Col>
          </Row>
        </Spin>
      </Modal>
      {children}
    </>
  );
};

export default Policies;
