import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation('invoice');

const keys = [
    {
        "key":"customerLabel",
        "type": "label"
    },
    {
        "key":"documentNumberLabel",
        "type": "label"
    },
    {
        "key":"policyNumberLabel",
        "type": "label"
    },
    {
        "key":"validityLabel",
        "type": "label"
    },
    {
        "key":"insurerLabel",
        "type": "label"
    },
]


export default prepareTranslations(keys, translation);