//Helper functions`
import { setInitialTranslation } from "helpers";
import { prepareTranslations } from "../../../../helpers";
//Constants
const translation = setInitialTranslation("order");

const keys = [
    {
        "key": "applicationDataTabPaneLabel",
        "type": "label"
    }, {
        "key": "orderLabel",
        "type": "label"
    },
    {
        "key": "applicationDataTabPaneLabel",
        "type": "label"
    },
    {
        "key": "insuredTabLabel",
        "type": "label"
    },
    {
        "key": "initialTabLabel",
        "type": "label"
    },
    {
        "key": "obligeeTabLabel",
        "type": "label"
    },
    {
        "key": "termTabLabel",
        "type": "label"
    },
    {
        "key": "bondTabLabel",
        "type": "label"
    },
    {
        "key": "termDetailTabLabel",
        "type": "label"
    },
    {
        "key": "taxesTabLabel",
        "type": "label"
    },
    {
        "key": "quotesTabLabel",
        "type": "label"
    },
    {
        "key": "paymentsTabLabel",
        "type": "label"
    },
    {
        "key": "minutesTabLabel",
        "type": "label"
    },
    {
        "key": "uploadTabLabel",
        "type": "label"
    },
    {
        "key": "issueDataTabLabel",
        "type": "label"
    },
    {
        "key": "policyTabLabel",
        "type": "label"
    },
    {
      "key": "crossSellTabLabel",
      "type": "label"
    },
    {
        "key": "whatsAppLabel",
        "type": "label"
    },
    {
        "key": "insuredTypeTabLabel",
        "type": "label"
    }, 
    {
        "key": "judicialTypeTabLabel",
        "type": "label"
    }, 
];

export default prepareTranslations(keys, translation);