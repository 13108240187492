import React from 'react';
//PropTypes
import PropTypes from 'prop-types';
//Custom Css
import './Styles.less';
//Components
import {Pagination,} from 'antd';

const OrderPagination = (props) => {
    const {currentPage, pageSize, totalOrders, onPageChange} = props;
    return (
        <section className="ordersPagination">
            <Pagination
                current={currentPage}
                onChange={onPageChange}
                pageSize={pageSize}
                total={totalOrders}/>
        </section>
    );
};

export default OrderPagination;
OrderPagination.defaultProps = {
    pageSize: 30,
    totalOrders: 999999
};
OrderPagination.propTypes = {
    pageSize: PropTypes.number
};