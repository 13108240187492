import { displayError, displaySuccess, displayWarning } from "./notifications";
import { put } from "redux-saga/effects";
import * as types from '../appRedux/actionTypes';

export function prepareInvalidDataErrors(data) {
  let errors = "";
  if (Array.isArray(data))
    data.forEach(entry => errors += `${typeof entry === "object" ? entry.message : entry}\n`);
  else if (typeof data === 'object')
    Object.entries(data).forEach(([attr, message]) => errors += `${(attr)} - ${message}\n`);
  return errors
}

function formatWarning(payload) {
  if (payload.hasOwnProperty("message"))
    return payload.message;
  if (payload.hasOwnProperty("errors")) {
    let errors = "";
    if (Array.isArray(payload.errors))
      payload.errors.forEach(entry => errors += `${typeof entry === "object" ? entry.message : entry}\n`);
    return errors;
  }

}


const defaultProps = {
  successMessage: "Saved Successfully",
  displaySuccessMessage: true,
  errorMessage: "An error happened. Please Try Again!",
  displayErrorMessage: true,
  invalidDataMessage: "Invalid Data"
};

export function* validateResponse(props) {
  const {
    response,
    successMessage = defaultProps.successMessage,
    displaySuccessMessage = defaultProps.displaySuccessMessage,
    errorMessage = defaultProps.errorMessage,
    displayErrorMessage = defaultProps.displayErrorMessage,
    invalidDataMessage = defaultProps.invalidDataMessage
  } = props;

  // Server Error
  if (response.status === 500) {
    displayErrorMessage && displayError(errorMessage);
    return { success: false, payloadData: null };
  }

  const payloadData = yield response.json();

    /*TODO:
    *  Take into consideration the incoming arr of elements as errors messages*/
    const formatErrors = (err) => {
      let formatedErrors = {}
      err.forEach((item) => {
        formatedErrors[item.field] = item.message
      }, {})
      return { errors: formatedErrors }
    }

    if (!response.ok) {
      // Bad request
      if (response.status === 400)
        displayWarning(prepareInvalidDataErrors(payloadData), invalidDataMessage);
      // Any other client error
      else if (response.status === 422) {
        if (payloadData.hasOwnProperty('errors')) {
          yield put({ type: types.SAVE_ORDER_FAILURE, payload: formatErrors(payloadData.errors) });
        } else {
          displayWarning(formatWarning(payloadData))
          yield put({ type: types.SAVE_ORDER_FAILURE, payload: payloadData })
        }
      }
      else
        displayError(errorMessage);

      return {
        success: false,
        payloadData: payloadData
      };
    }

    if (displaySuccessMessage)
      displaySuccess(successMessage);


    return { success: true, payloadData };
}

