import {FETCH_USERS, UPDATE_USER, CREATE_USER, SELECT_USER, SELECT_USERS, FILTER_USERS} from '../actionTypes';

export const fetchUsers = () => ({
    type: FETCH_USERS,
});

export const updateUser = (userId, data, callback) => ({
    type: UPDATE_USER,
    userId: userId,
    data,
    callback
});

export const createUser = (data, callback) => ({
    type: CREATE_USER,
    data,
    callback
});

export const selectUser = (userId) => ({
    type: SELECT_USER,
    userId: userId
});

export const selectUsers = (attr) => ({
    type: SELECT_USERS,
    attr: attr
});

export const filterUsers = (search) => ({
    type: FILTER_USERS,
    search: search
});
