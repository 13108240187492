import React, { useEffect, useState } from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Button, Spin, Modal, Tabs } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setErrors,
  setProposal,
  changeProposal,
  fetchSuretyProposal,
  saveSuretyProposal,
  acceptSuretyProposal,
  removeSuretyProposalPolicy,
  removeSuretyProposalPaymentSlip,
  getCompanyData,
} from "../../../../../store/slices/proposal/surety";
import { fetchUsers } from "../../../../../store/slices/user";
// components
import {
  ProposalForm,
  InstallmentForm,
} from "../../../../../components/Order/OrderTabs/SuretyTabs/tabs/Proposal";
import { MuTabs, UploadFile } from "../../../../../components";
// helpers
import {
  uploadConfig,
  downloadFile,
  clearError,
  formatMaskInput,
} from "../../../../../helpers";

const { TabPane } = Tabs;

const Proposal = ({ code }) => {
  const {
    user: { userList },
    common: { isLoading },
    proposal: {
      suretyProposal: {
        proposal,
        proposal: { installments = [] },
        errors,
      },
    },
  } = useSelector((state) => state);

  const [tabIndex, setTabIndex] = useState(0);
  const [isProposalSaved, setProposalSaved] = useState(false);

  const uploadPolicyFileUrl = code
    ? `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/proposal/policy-file`
    : "";

  const uploadPaymentSlipFileUrl = code
    ? `${window._env_.API_GATEWAY_URL}/surety/v2/${code}/proposal/payment-slip`
    : "";

  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const getSalesRepresentative = () => {
    const salesRepresentativeList = userList
      .filter((item) => item.salesProfile)
      .sort((a, b) => a.name.localeCompare(b.name));

    const isProposalInPreSales =
      salesRepresentativeList.filter(
        (item) => item.id === proposal?.salesRepresentativeId
      ).length === 0;

    if (isProposalInPreSales) {
      salesRepresentativeList.push({
        id: proposal?.salesRepresentativeId,
        name: "Em pré-qualificação",
      });
    }
    return salesRepresentativeList;
  };

  const getPreSalesRepresentative = () =>
    userList
      .filter((item) => item.preSalesProfile)
      .sort((a, b) => a.name.localeCompare(b.name));

  const sortInstallments = () => {
    const sortedInstalments = [...installments];
    return sortedInstalments.sort((a, b) => a.installment - b.installment);
  };

  const formatChangedValue = (value, field) => {
    if (field && field === "obligeeDocumentNumber") {
      return formatMaskInput(value.target.value);
    }

    if (value && (value.formattedValue || value.formattedValue === "")) {
      return value.floatValue;
    }

    if (value && value.target) {
      return value.target.value;
    }

    return value;
  };

  const showRemoveFileModal = (type) => {
    const modal = Modal.confirm({
      maskClosable: true,
      title: translation("proposal.surety.removeFileQuestion"),
      onCancel: () => modal.destroy(),
      onOk: () => {
        if (type === "policy") {
          dispatch(removeSuretyProposalPolicy(code)).then(() =>
            dispatch(fetchSuretyProposal(code))
          );
        } else {
          dispatch(removeSuretyProposalPaymentSlip(code)).then(() =>
            dispatch(fetchSuretyProposal(code))
          );
        }
        modal.destroy();
      },
    });
  };

  const handleProposalChange = (field, value) => {
    value = formatChangedValue(value, field);
    dispatch(changeProposal({ [field]: value }));
    dispatch(setErrors(clearError(field, errors)));
  };

  const handleInstallmentChange = (field, value, index) => {
    value = formatChangedValue(value);
    const newInstallmentList = [...installments];
    newInstallmentList[index] = {
      installment: index + 1,
      ...newInstallmentList[index],
      [field]: value,
    };
    dispatch(
      changeProposal({
        installments: newInstallmentList,
      })
    );
    dispatch(setErrors(clearError(`installments[${index}].${field}`, errors)));
  };

  const handleAddInstallment = () => {
    const newInstallmentList = [...installments];
    newInstallmentList.push({
      installment: installments.length + 1,
    });
    dispatch(
      changeProposal({
        installments: newInstallmentList,
      })
    );
    setTabIndex(installments.length);
  };

  const handleDeleteInstallment = (index) => {
    const newInstallmentList = [...installments];
    newInstallmentList.splice(index, 1);
    dispatch(
      changeProposal({
        installments: newInstallmentList,
      })
    );
    setTabIndex(tabIndex - 1);
  };

  const handleSaveProposal = () => {
    return (
      code &&
      dispatch(saveSuretyProposal(code)).then(() => {
        setProposalSaved(true);
      })
    );
  };

  const handleAcceptProposal = () => {
    return code && dispatch(acceptSuretyProposal(code));
  };

  const handleUploadPolicySuccess = () => {
    return code && dispatch(fetchSuretyProposal(code));
  };

  const handleRemovePolicyFile = () => {
    return code && showRemoveFileModal("policy");
  };

  const handleUploadPaymentSlipSuccess = () => {
    return code && dispatch(fetchSuretyProposal(code));
  };

  const handleRemovePaymentSlipFile = () => {
    return code && showRemoveFileModal("payment-slip");
  };

  const handleBeforeUpload = (proposalIsSaved) => {
    handleSaveProposal();
  };

  const handleSearchCompany = (documentNumber) => {
    dispatch(getCompanyData(documentNumber));
  };

  useEffect(() => {
    if (code) {
      dispatch(fetchSuretyProposal(code)).then(({ data }) => {
        if (Object.keys(data).length > 1) {
          setProposalSaved(true);
        }
      });
    }
    dispatch(fetchUsers());
  }, [dispatch]);

  return (
    <Spin spinning={isLoading}>
      <Col sm={24}>
        <Row
          type="flex"
          justify="space-between"
          className="gx-mt-3 gx-mb-1  gx-pr-3"
        >
          <h5>{translation("proposal.registerProposalTitle")}</h5>
        </Row>
        <ProposalForm
          errors={errors}
          proposal={proposal}
          sales={getSalesRepresentative()}
          preSales={getPreSalesRepresentative()}
          onChange={handleProposalChange}
          onSearchCompany={handleSearchCompany}
        />

        <Row
          type="flex"
          justify="space-between"
          align="middle"
          style={{ marginTop: "40px", marginBottom: "24px" }}
          className="gx-pr-3"
        >
          <h5 className="gx-m-0">{translation("Parcelas")}</h5>
          <Button
            type="primary"
            shape="circle"
            className="gx-m-0 "
            onClick={handleAddInstallment}
          >
            <PlusOutlined />
          </Button>
        </Row>
        <div style={{ marginBottom: "40px" }}>
          {installments.length > 0 && (
            <MuTabs
              product="SURETY"
              isSmall={true}
              activeKey={tabIndex.toString()}
              onChange={(e) => setTabIndex(e)}
              onEdit={(e) => handleDeleteInstallment(e)}
            >
              {sortInstallments().map((installment, index) => (
                <TabPane
                  tab={`${index + 1}${translation(
                    "proposal.surety.installmentCountLabel"
                  )}`}
                  key={index}
                >
                  <InstallmentForm
                    errors={errors}
                    installmentIndex={index}
                    installment={installment}
                    onChange={(field, value) =>
                      handleInstallmentChange(field, value, index)
                    }
                  />
                </TabPane>
              ))}
            </MuTabs>
          )}
        </div>
        <Row
          type="flex"
          align="middle"
          style={{ gap: "16px", marginBottom: "80px" }}
        >
          <UploadFile
            uploadText={translation("proposal.surety.policyUploadButton")}
            downloadText={translation("proposal.surety.policyDownloadButton")}
            hasFile={proposal.policyFile}
            uploadProps={{
              ...uploadConfig(),
              showUploadList: false,
              multiple: false,
              action: uploadPolicyFileUrl,
              onSuccess: handleUploadPolicySuccess,
              beforeUpload: () => handleBeforeUpload(),
            }}
            onRemove={handleRemovePolicyFile}
            onDownload={() =>
              downloadFile(proposal.policyFile.link, `policy-${code}`)
            }
          />
          <UploadFile
            uploadText={translation("proposal.surety.paymentSlipUploadButton")}
            downloadText={translation(
              "proposal.surety.paymentSlipDownloadButton"
            )}
            hasFile={proposal.paymentSlipFile}
            uploadProps={{
              ...uploadConfig(),
              showUploadList: false,
              multiple: true,
              action: uploadPaymentSlipFileUrl,
              onSuccess: handleUploadPaymentSlipSuccess,
              beforeUpload: () => handleBeforeUpload(),
            }}
            onRemove={handleRemovePaymentSlipFile}
            onDownload={() =>
              downloadFile(proposal.paymentSlipFile.link, `policy-${code}`)
            }
          />
        </Row>
        <Row type="flex" justify="end" className="gx-mt-3">
          <Button
            ghost
            type="primary"
            className="gx-mr-2"
            onClick={handleAcceptProposal}
          >
            {translation("proposal.surety.acceptProposalButton")}
          </Button>
          <Button type="primary" onClick={handleSaveProposal}>
            {translation("proposal.surety.saveProposalButton")}
          </Button>
        </Row>
      </Col>
    </Spin>
  );
};

Proposal.propTypes = {
  code: PropTypes.string,
};

export default Proposal;
