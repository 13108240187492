import React from 'react';
//Translation
import Translation from "./translation";
import { useTranslation } from 'react-i18next';
//Components
import { Row, Col, Select, Input, DatePicker } from "antd";
//Custom Components
import { CurrencyInput, NumberInput, MultipleSelect, FormField } from "components";
//Constants
import selections from "constants/selections";
import { prepareOptions, frontEndDate, dateFormat } from "../../../../../../helpers";
// moment
import moment from "moment";

const GeneralContent = (props) => {

  const { configurations, handleChange, OrderErrors } = props;

  const { t: translation } = useTranslation("translations");

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;
    if (typeof event === "object" && event) {
      if (!isDirect) {
        if (event instanceof Array)
          value = event;
        else
          value = event.target.value;
      }
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
  };

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.modalsLabel}:
        </Col>
        <Col xs={24} md={18}>
            <MultipleSelect
              className="gx-w-100"
              value={getValue("modals")}
              onChange={onChange("modals")}
              options={selections.meansOfTransportation}
              placeholder="Selecione os modais que o cliente opera"
              OrderErrors={OrderErrors}
              validationParam="general.modals"
            />
        </Col>
      </Row>
  
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.insuredTypeLabel}:
        </Col>
          <Col xs={24} md={18}>
            <NumberInput
              placeholder={configurations.insuredType === "OWN_MERCHANDISE" ? Translation.ownMerchandiseOpenPlaceholder : Translation.thirdyPartyMerchandiseOpenPlaceholder}
              disabled
              className="gx-w-100"
              />
          </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.companyActivityLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["companyActivity"]}>
            <Select
              className="gx-w-100"
              value={configurations.companyActivity}
              placeholder={Translation.companyActivityPlaceholder}
              onChange={onChange("companyActivity")}>
              {prepareOptions(selections.companyActivity)}
            </Select>
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.monthlyTotalTripsLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["monthlyShipmentQuantity"]}>
            <NumberInput
              fullWidth
              type="number"
              placeholder={Translation.monthlyTotalTripsPlaceholder}
              onChange={onChange("monthlyShipmentQuantity")}
              value={getValue("monthlyShipmentQuantity")} />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.averageAmountLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["averageAmountPerBoarding"]}>
            <CurrencyInput
              placeholder={Translation.averageAmountPlaceholder}
              onChange={onChange("averageAmountPerBoarding", true)}
              value={getValue("averageAmountPerBoarding")} />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.maxAmountLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["maximumAmountPerBoarding"]}>
            <CurrencyInput
              placeholder={Translation.maxAmountPlaceholder}
              onChange={onChange("maximumAmountPerBoarding", true)}
              value={getValue("maximumAmountPerBoarding")} />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {translation("order.cargoOpenPolicy.general.reasonLabel")}:
        </Col>
        <Col xs={24} md={9}>
          <FormField error={OrderErrors["reason"]}>
            <Input
              placeholder={translation("order.cargoOpenPolicy.general.reasonPlaceholder")}
              onChange={onChange("reason")}
              value={getValue("reason")} />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {translation("order.cargoOpenPolicy.general.startAtLabel")}:
        </Col>
        <Col xs={24} md={9}>
          <FormField error={OrderErrors["startsAt"]}>
            <DatePicker
              className="gx-w-100"
              placeholder={translation("order.cargoOpenPolicy.general.startAtPlaceholder")}
              format={frontEndDate}
              onChange={onChange("startsAt", true)}
              value={
                getValue("startsAt")
                  ? moment(getValue("startsAt"), dateFormat)
                  : undefined
              }
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {translation("order.cargoOpenPolicy.general.fleetSizeLabel")}:
        </Col>
        <Col xs={24} md={9}>
          <FormField error={OrderErrors["fleetSize"]}>
            <Input
              type="number"
              placeholder={translation("order.cargoOpenPolicy.general.fleetSizePlaceholder")}
              onChange={onChange("fleetSize")}
              value={getValue("fleetSize")} />
          </FormField>
        </Col>
      </Row>
    </section>
  );
};

export default GeneralContent;