//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("deleteUser");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    singularTitle: getTranslation("singularTitle"),
    pluralTitle: getTranslation("pluralTitle"),
    singularEntity: getTranslation("singularEntity"),
    pluralEntity: getTranslation("pluralEntity"),
    singularWarning: getTranslation("singularWarning"),
    pluralWarning: getTranslation("pluralWarning"),
    ok: getTranslation('ok', true),
    cancel: getTranslation('cancel', true),
}