// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// loading
import { setIsLoading } from "../common";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    errors: []
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    }
  },
});

export const { setErrors } = authSlice.actions;

export default authSlice.reducer;

// ------------------------------------------------------------------

export const forgotPassword = (email) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`user/v1/${email}/forgot-password`, { email })
      .then((response) => {
        message.success(i18n.t("auth.forgotPassword.successMessage"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const changePassword = (userId, data) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`user/v1/${userId}/change-password`, data)
      .then((response) => {
        message.success(i18n.t("auth.changePassword.successMessage"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};
