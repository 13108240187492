import {put, takeLatest, all, select} from "redux-saga/effects";
import * as types from '../../actionTypes/ActionTypes';
//Helpers
import {validateResponse, displayError} from 'helpers';
//Request Helpers
import Request from 'helpers/Request';
//Action Creators
import {userSignInSuccess} from "../../actionCreators";
import * as selectors from "../../selectors";

// fetch the user's list
export function* fetchUsers() {
    yield put({type: types.FETCHING_USERS});
    try {
        const response = yield Request.get(`/user/v1`);
        const {success, payloadData} = yield  validateResponse({response, displaySuccessMessage: false});
        if (success) {
            yield  put({type: types.CLEAR_USER_SEARCH});
            yield put({type: types.FETCH_USERS_SUCCESS, payload: payloadData});
        }
    } catch (error) {
        displayError("Please check your internet connection!");
    }
}

export function* updateUser(action) {
    const {userId, data, callback} = action;
    try {
        const response = yield Request.put(`/user/v1/${userId}`, data);
        const {success, payloadData} = yield validateResponse({response, successMessage: `User ${data.name} has been updated`});
        const authUser = yield select(selectors.extractAuthUser);
        if (success) {
            callback();
            yield put({type: types.FETCH_USERS});
            if (authUser.id === payloadData.id)
                yield put(userSignInSuccess(payloadData));
        }
    } catch (error) {
        displayError("Please check your internet connection!");
    }
}

export function* createUser(action) {
    const {data, callback} = action;
    try {
        const response = yield Request.post(`/user/v1`, data);
        const {success} = yield validateResponse({response, successMessage: "User has been created"});
        if (success) {
            callback();
            yield put({type: types.FETCH_USERS});
        }
    } catch (error) {
        displayError("Please check your internet connection!");
    }
}

export default function* rootSaga() {
    yield all([
        takeLatest(types.FETCH_USERS, fetchUsers),
        takeLatest(types.UPDATE_USER, updateUser),
        takeLatest(types.CREATE_USER, createUser)
    ]);
}