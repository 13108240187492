// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../../util/axios";
// translation
import i18n from "../../../../translation/i18n";
// antd
import { message } from "antd";
// common
import { setIsLoading } from "../../common";

export const suretyProposalSlice = createSlice({
  name: "suretyProposal",
  initialState: {
    proposal: {
      installments: [{}],
    },
    errors: [],
    isLoading: false,
  },
  reducers: {
    setPolicyList: (state, action) => {
      state.proposal.policies = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setProposal: (state, action) => {
      state.proposal = action.payload;
    },
    changeProposal: (state, action) => {
      state.proposal = {
        ...state.proposal,
        ...action.payload,
      };
    },
  },
});

// ------------------------------------------------------------------

export const {
  setPolicyList,
  setErrors,
  setProposal,
  changeProposal,
} = suretyProposalSlice.actions;

export default suretyProposalSlice.reducer;

// -----------------------------------------------------------------

export const fetchSuretyProposal = (code) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`surety/v2/${code}/proposal`)
      .then((response) => {
        if (
          response.data?.installments &&
          response.data.installments.length > 0
        ) {
          dispatch(setProposal({ ...response.data }));
        } else {
          dispatch(setProposal({ ...response.data, installments: [{}] }));
        }
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const saveSuretyProposal = (code) => (dispatch, getState) => {
  const {
    suretyProposal: { proposal },
  } = getState().proposal;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`surety/v2/${code}/proposal`, proposal)
      .then((response) => {
        message.success(i18n.t("proposal.saveProposalSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
          //          message.error(i18n.t("proposal.emptyFieldErrorMessage"));
        }
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const acceptSuretyProposal = (code) => (dispatch, getState) => {
  const {
    suretyProposal: { proposal },
  } = getState().proposal;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`surety/v2/${code}/proposal`, {
        ...proposal,
        accepted: true,
      })
      .then((response) => {
        message.success(i18n.t("proposal.accepteProposalSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
          message.error(i18n.t("proposal.emptyFieldErrorMessage"));
        }
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const removeSuretyProposalPolicy = (code) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`surety/v2/${code}/proposal/policy-file`)
      .then((response) => {
        message.success(i18n.t("proposal.surety.removePolicyFileSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const removeSuretyProposalPaymentSlip = (code) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`surety/v2/${code}/proposal/payment-slip`)
      .then((response) => {
        message.success(i18n.t("proposal.surety.removePaymentSlipFileSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const getCompanyData = (documentNumber) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/person/v1/company?documentNumber=${documentNumber}`)
      .then((response) => {
        const { name } = response?.data;
        dispatch(changeProposal({ obligeeCompanyName: name }));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};
