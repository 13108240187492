// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    errors: [],
    isLoading: false,
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const { setErrors, setIsLoading } = orderSlice.actions;

export default orderSlice.reducer;

// -----------------------------------------------------------------

const configCreateOrderEndpoint = {
  surety: "/surety/v2/backoffice",
  cargo: "/cargo/v1/backoffice",
  life: "/life/v1/backoffice",
};

export const createOrder = (data, productType) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`${configCreateOrderEndpoint[productType]}`, {
        type: data?.type,
        origin: data?.origin,
        salesRepresentativeId: data?.salesRepresentativeId,
        dealType: data?.dealType,
        initialData: {
          name: data?.name,
          email: data?.email,
          phoneNumber: data?.phoneNumber,
          phoneType: "WHATSAPP_PHONE_NUMBER",
          receiveWhatsAppMessage: true,
          contactType: "COMPANY",
          hasDocumentNumber: true,
          documentNumber: data?.documentNumber,
        },
      })
      .then((response) => {
        message.success(i18n.t("order.createOrder.successMessage"));
        return resolve(response);
      })
      .catch((error) => {
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        }
        return reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

// ------------------------------------------------------------------

export const shareOpenPolicyQuotation = (code, emails) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/cargo/v1/open-policy/${code}/send-document`, { emails })
      .then((response) => {
        message.success(i18n.t("order.resume.shareQuotationSuccess"));
        return resolve(response);
      })
      .catch((error) => {
        if (
          error.response.data.errors &&
          Array.isArray(error.response.data.errors)
        ) {
          dispatch(setErrors(error.response.data.errors));
        }
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        return reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const sendSingleBoardingDealToHubspot = (code, email) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/cargo/v1/single-boarding/${code}/email`, { email })
      .then((response) => {
        return resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        return reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};
