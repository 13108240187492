import React from 'react';
//Translation
import Translation from "./translation";
//Components
import { Row, Col, Select } from "antd";
//Custom Components
import { CurrencyInput, NumberInput, Switch, FormField } from "components";
//Constants
import selections from "constants/selections";
//Helpers
import { prepareOptions } from "../../../../../../helpers";

const General = (props) => {
  const { handleChange, configurations,OrderErrors } = props;
  const { employeesAmount, averageAgeEmployees, capitalInsuredPerson, isPublicCompany } = configurations;

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
  };

  const toggleProperty = (attr) => () => {
    handleChange({
      attr: attr,
      value: !getValue(attr)
    });
  };

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;

    if (event && !isDirect && typeof event === "object") {
      if (event instanceof Array)
        value = event;
      else
        value = event.target.value;
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  const onChangeCurrency = (attr) => (event) =>{
    let value = event.floatValue
    return handleChange({
      attr: attr,
      value
    });
  }

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.employeesAmountLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors["general.employeesAmount"]}>
            <NumberInput
              fullWidth
              value={employeesAmount}
              onChange={onChange("employeesAmount")}
              placeholder={Translation.amountPlaceholder}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.capitalInsuredPersonLabel}:
        </Col>
        <Col xs={24} md={18}>
            <FormField error={OrderErrors["general.capitalInsuredPerson"]}>
              <CurrencyInput
                value={capitalInsuredPerson}
                prefix={"R$"}
                onChange={onChangeCurrency("capitalInsuredPerson")}
                placeholder={Translation.capitalInsuredPersonPlaceholder}
              />
            </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.averageAgeEmployeesLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors["general.averageAgeEmployees"]}>
          <NumberInput
            fullWidth
            value={averageAgeEmployees}
            onChange={onChange("averageAgeEmployees")}
            placeholder={Translation.amountPlaceholder}
          />
        </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.reasonTakeInsuranceLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors["general.reasonTakeInsurance"]}>
          <Select
            value={getValue("reasonTakeInsurance")}
            className="gx-w-100"
            placeholder={Translation.reasonTakeInsurancePlaceholder}
            onChange={onChange("reasonTakeInsurance")}>
            {prepareOptions(selections.reasonTakeInsurance)}
          </Select>
        </FormField>
        </Col>
      </Row>

      {isPublicCompany &&
        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.hasBiddingWaiverLabel}:
          </Col>
          <Col xs={24} md={18}>
            <Switch
                checked={getValue("hasBiddingWaiver")}
                onChange={toggleProperty("hasBiddingWaiver")} />
          </Col>
        </Row>
      }

    </section>
  );
};

export default General;