//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("order");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    orderLabel: getTranslation("orderLabel"),
    saveChanges: getTranslation("saveChanges", true),
    resetChanges: getTranslation("resetChanges", true),
    applicationDataTabPaneLabel: getTranslation("applicationDataTabPaneLabel"),
    resumeTabLabel: getTranslation("resumeTabLabel"),
    principalTabLabel: getTranslation("principalTabLabel"),
    obligeeTabLabel: getTranslation("obligeeTabLabel"),
    termTabLabel: getTranslation("termTabLabel"),
    additionalTabLabel: getTranslation("additionalTabLabel"),
    legalOpinionTabLabel: getTranslation("legalOpinionTabLabel"),
    complainantTabLabel: getTranslation("complainantTabLabel"),

    preparingOrderLabel: getTranslation("preparingOrderLabel"),
    savingOrderLabel: getTranslation("savingOrderLabel"),
    resettingOrderLabel: getTranslation("resettingOrderLabel"),
    loadingOrderLabel: getTranslation("loadingOrderLabel"),
}