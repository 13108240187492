// redux
import { combineReducers } from 'redux'
// proposal slices
import suretyProposal from "./surety";
import cargoProposal from "./cargo";
import lifeProposal from "./life";

export default combineReducers({
  suretyProposal: suretyProposal,
  cargoProposal: cargoProposal,
  lifeProposal: lifeProposal,
});
