import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Radio } from "antd";
import { CheckOutlined, MinusOutlined } from "@ant-design/icons";
// helpers
import { formatMoney } from "../../../../../../helpers";
// styles
import "./style.less";

const PlanTable = ({ plans = [], onSelect }) => {
  const { t: translation } = useTranslation();

  const cargoBasicPlanCoverages = [
    {
      type: "accident",
      label: translation("cargoOpenPolicy.coverageItems.accidentLabel"),
    },
    {
      type: "damage",
      label: translation("cargoOpenPolicy.coverageItems.damageLabel"),
    },
    {
      type: "theft",
      label: translation("cargoOpenPolicy.coverageItems.theftLabel"),
    },
    {
      type: "environmentalLiabilityRisk",
      label: translation(
        "cargoOpenPolicy.coverageItems.environmentalLiabilityRiskLabel"
      ),
    },
  ];

  const getPlanName = (name) =>
    name ? translation(`cargoOpenPolicy.coveragePage.plans.${name}`) : "";

  const handleSelect = (plan, index) => onSelect && onSelect(plan, index);

  return (
    <div className="coverage-plan-table">
      <div className="head">
        <div className="row gx-d-flex">
          <div className="cell" style={{ minWidth: "150px" }}></div>
          {plans.map((plan, index) => (
            <div key={index} className="cell bordered gx-text-center">
              <Radio
                checked={plan.selected}
                onChange={() => handleSelect(plan, index)}
              />
              <div className="gx-w-100">{getPlanName(plan.name)}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="body">
        {cargoBasicPlanCoverages.map((item, index) => (
          <div key={index} className="row gx-d-flex">
            <div className="cell" style={{ minWidth: "150px" }}>
              {item.label}
            </div>
            {plans.map((plan, index2) => {
              const hasThisCoverage = plan.basicCoverages.find(
                (coverage) => coverage.type === item.type
              );
              return hasThisCoverage ? (
                <div key={index2} className="cell centered">
                  <CheckOutlined />
                </div>
              ) : (
                <div key={index2} className="cell centered">
                  <MinusOutlined />
                </div>
              );
            })}
          </div>
        ))}
      </div>
      <div className="foot">
        <div className="row gx-d-flex">
          <div className="cell" style={{ minWidth: "150px" }}></div>
          {plans.map((plan, index) => (
            <div key={index} className="cell bordered gx-text-center">
              <div className="gx-w-100">{formatMoney(plan.premium)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

PlanTable.propTypes = {
  plans: PropTypes.array,
  onSelect: PropTypes.func,
};

export default PlanTable;
