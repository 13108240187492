import React, {Component} from "react";
//Translation
import Translation from './translation';
//Custom Css
import './Styles.less'
//Components
import {Tabs, Button, Card, Empty, Row, Col, Select, Tooltip} from "antd";
import {CurrencyInput} from "components";
import produce from "immer";
import {cloneArray, prepareOptions} from "../../helpers";
import {LngInput} from "../LngComponents";
//Constants
const {TabPane} = Tabs;

const constants = {
  groupOptions: [
    {
      "value": "PRODUCTS",
      "label": "Produtos em geral"
    },
    {
      "value": "FOODS",
      "label": "Alimentos em geral"
    },
    {
      "value": "BEVERAGES",
      "label": "Bebidas"
    },
    {
      "value": "MEETS",
      "label": "Carnas"
    },
    {
      "value": "GRAINS_AND_SEEDS",
      "label": "Grãos e sementes"
    },
    {
      "value": "MACHINES",
      "label": "Máquinas"
    },
    {
      "value": "TEXTILE_MATERIALS",
      "label": "Materiais têxteis"
    },
    {
      "value": "METALS",
      "label": "Metais"
    },
    {
      "value": "CHEMICAL_PRODUCTS",
      "label": "Produtos químicos"
    },
    {
      "value": "ALIVE_ANIMALS",
      "label": "Animais vivos"
    },
    {
      "value": "WEAPONS_AND_MUNITIONS",
      "label": "Armas e munições"
    },
    {
      "value": "LEATHER_GOODS",
      "label": "Artigos de peles e couros"
    },
    {
      "value": "GRAPHICS_GOODS",
      "label": "Artigos gráficos"
    },
    {
      "value": "CERAMIC",
      "label": "Cerâmica"
    },
    {
      "value": "INSTRUMENTS",
      "label": "Instrumentos"
    },
    {
      "value": "WOOD",
      "label": "Madeira"
    },
    {
      "value": "RUBBER_MATERIALS",
      "label": "Materiais de borracha"
    },
    {
      "value": "RADIOACTIVE_MATERIALS",
      "label": "Materiais radioativos"
    },
    {
      "value": "MEDICINES",
      "label": "Medicamentos"
    },
    {
      "value": "MINERALS",
      "label": "Minerais"
    },
    {
      "value": "FURNITURE",
      "label": "Móveis"
    },
    {
      "value": "ART_OBJECTS",
      "label": "Objetos de arte"
    },
    {
      "value": "PAPER",
      "label": "Papéis"
    },
    {
      "value": "PLANS",
      "label": "Plantas"
    },
    {
      "value": "PLASTICS",
      "label": "Plásticos"
    },
    {
      "value": "VEHICLES",
      "label": "Veículos"
    },
    {
      "value": "CLOTHING",
      "label": "Vestuário"
    },
    {
      "value": "GLASS",
      "label": "Vidro"
    },
    {
      "value": "OTHERS",
      "label": "Outros"
    }
  ],
  conditionOptions: [
    {
      value: "NEW",
      label: "Novo"
    },
    {
      value: "USED",
      label: "Usado"
    }
  ]
};


const HeaderContent = (props) => {
  const {handleCreate} = props;
  return (
    <section>
      <Tooltip placement="bottom" title={Translation.groupAdd}>
        <Button
          icon="plus"
          onClick={handleCreate}
          className="gx-btn-outline-primary"
          htmlType="button">
          {Translation.add}
        </Button>
      </Tooltip>
    </section>
  )
};

const GroupContent = (props) => {
  const {content, groupIndex, handleChange} = props;
  const {group, description, unitCost, condition} = content;
  return (
    <Row type="flex" align="middle" gutter={8} key={groupIndex}>
      <Col xs={24} md={6}>
        <Select
          value={group || undefined}
          // placeholder={Translation.groupPlaceholder}
          placeholder={"Fill in the product"}
          onChange={handleChange(groupIndex, "group")}
          // getPopupContainer={trigger => trigger.parentNode}
          className="gx-w-100">
          {prepareOptions(constants.groupOptions)}
        </Select>
      </Col>
      <Col xs={24} md={6}>
        <LngInput
          value={description}
          onChange={handleChange(groupIndex, "description")}
          placeholder={"Fill in the description"}
          // placeholder={Translation.amountPlaceholder}
        />
      </Col>
      <Col xs={24} md={6}>
        <CurrencyInput
          value={unitCost}
          onChange={handleChange(groupIndex, "unitCost")}
          placeholder={"Fill in the unitCost"}
          // placeholder={Translation.amountPlaceholder}
        />
      </Col>
      <Col xs={24} md={6}>
        <Select
          value={condition || undefined}
          // placeholder={Translation.groupPlaceholder}
          placeholder={"Fill in the condition"}
          // getPopupContainer={trigger => trigger.parentNode}
          onChange={handleChange(groupIndex, "condition")}
          className="gx-w-100">
          {prepareOptions(constants.conditionOptions)}
        </Select>
      </Col>
    </Row>
  )
};

class CargoEntries extends Component {
  constructor(props) {
    super(props);
    this.groupsRef = React.createRef();
    this.state = CargoEntries.emptyState(props.data);
  }

  static emptyState(cargoGroups) {
    const draft = cloneArray(cargoGroups, "object");
    return {
      cargoGroups: cargoGroups,
      draft: draft
    }
  }

  static emptyGroup() {
    return {
      group: null,
      description: null,
      unitCost: null,
      condition: null
    }
  }

  static parseGroups(data) {
    return data.map(entry => {
      let updatedAmount;
      if (entry.hasOwnProperty("unitCost")) {
        if (typeof entry.unitCost === "object")
          updatedAmount = entry.unitCost.floatValue;
        else
          updatedAmount = entry.unitCost;
      }
      return {
        ...entry,
        unitCost: updatedAmount
      }
    })
  };


  reformatOrderGroups = (newData) => {
    const newState = CargoEntries.emptyState(newData);
    this.setState(newState);
  };

  reset = () => {
    this.setState(prevState => {
      return {
        cargoGroups: prevState.draft
      }
    })
  };


  getChanges = () => {
    const cargoGroups = this.state.cargoGroups;
    return {
      cargoGroups: CargoEntries.parseGroups(cargoGroups)
    }
  };

  scrollToBottom = () => {
    if (this.groupsRef && this.groupsRef.current)
      this.groupsRef.current.scrollTop = this.groupsRef.current.scrollHeight;
  };

  notifyChanges = () => {
    return this.props.notifyChanges();
  };

  handleGroupChange = (index, attr) => (event) => {
    let value = event;
    if (typeof event === "object") {
      if (attr !== "unitCost")
        value = event.target.value;
    }

    this.setState((state) => {
      return produce(state, draft => {
        draft.cargoGroups[index][attr] = value;
      })
    }, this.notifyChanges)
  };

  handleGroupCreate = () => {
    this.setState((state) => {
      return produce(state, draft => {
        draft.cargoGroups.push(CargoEntries.emptyGroup())
      })
    }, () => {
      this.scrollToBottom();
      this.notifyChanges();
    })
  };

  handleGroupRemove = (removalIndex) => () => {
    this.setState((state) => {
      return produce(state, draft => {
        draft.cargoGroups.splice(removalIndex, 1);
      })
    }, this.notifyChanges)
  };

  render() {
    const {cargoGroups} = this.state;

    return (
      <Card size="small" className="groups">
        <Tabs
          type="card"
          hideAdd
          activeKey={"group"}
          className={cargoGroups.length ? "groups-empty" : "groups-tabs"}
          tabBarExtraContent={
            <HeaderContent handleCreate={this.handleGroupCreate}/>}>
          <TabPane tab={Translation.groupLabel} key="group">
            {cargoGroups.length ?
              <div ref={this.groupsRef} className="group-items">
                {cargoGroups.map((group, groupIndex) => {
                  const rowClassName = groupIndex === 0 ? "group-items-sticky" : "gx-mt-3";
                  return (
                    <Row key={groupIndex} type="flex" gutter={8} className={rowClassName}>
                      <Col xs={24} lg={22}>
                        <GroupContent
                          content={group}
                          groupIndex={groupIndex}
                          handleChange={this.handleGroupChange}/>
                      </Col>
                      <Col xs={24} lg={2}>
                        <Button onClick={this.handleGroupRemove(groupIndex)}
                                shape="circle-outline"
                                className={`group-items-delete-btn`}
                                icon="delete"
                                htmlType="button"/>
                      </Col>
                    </Row>
                  )
                })}
              </div>
              : <Empty
                className="groups-empty-Wrapper"
                description={Translation.groupEmptyLabel}/>
            }
          </TabPane>
        </Tabs>
      </Card>
    );
  }
}

export default CargoEntries;