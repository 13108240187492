import * as types from '../../actionTypes';
//Helpers
import {cloneArray} from "../../../helpers";
import {prepareSelectedUsers, updateSelectedUsers, updateAllUsers, filterUsersByName} from './helpers';
/*Initial State*/
const initialState = {
    all: [],
    immutableAll: [],
    isFetching: false,
    search: '',
    selected: {}
};
/*Reducers*/
const usersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCHING_USERS:
            state = Object.assign({}, state, {all: [], isFetching: true});
            break;
        case types.FETCH_USERS_SUCCESS:
            state = Object.assign({}, state, {
                all: action.payload,
                immutableAll: cloneArray(action.payload),
                isFetching: false,
                search: '',
                selected: prepareSelectedUsers(action.payload)
            });
            break;
        case types.SELECT_USER:
            const {userId} = action;
            state = {...state, selected: updateSelectedUsers(state.selected, userId)};
            break;
        case types.SELECT_USERS:
            const {attr} = action;
            state = {...state, selected: updateAllUsers(state.all, attr)};
            break;
        case types.FILTER_USERS:
            const {search} = action;
            state = {
                ...state,
                selected: updateAllUsers(state.all, 'none'),
                search: search,
                all: filterUsersByName(state.immutableAll, search)
            };
            break;
        case types.CLEAR_USER_SEARCH:
            state = {...state, search: ''};
            break;
        default:
            break;
    }
    return state;
};
export default usersReducer;