import { notification } from "antd";
//Custom config for notification
notification.config({
  duration: 4,
  placement: "topRight",
});
// API URL
export default {
  apiUrl: window._env_.API_GATEWAY_URL,
  formsUrl: window._env_.FORMS_URL,
  clientAppUrl: window._env_.CLIENT_APP_URL,
};
