export default {
  initial: {
    name: null,
    email: null,
    phoneNumber: null,
    phoneType: null,
    receiveWhatsAppMessage: false,
    contactType: null,
    documentNumber: null,
    hasDocumentNumber: null
  },
  insured: {
    documentNumber: null,
    type: null,
    name: null,
    zipCode: null,
    address: null,
    number: null,
    neighborhood: null,
    state: null,
    city: null,
    additionalData: null
  },
  goods: [],
  general: {
    merchandiseOwner:null,
    companyActivity: null,
    origin: null,
    destination: null,
    currency: "BRA",
    cargoTotalAmount: null,
    travelAverageAmount: null,
    anyUsedMerchandise: false
  },
  details: {
    modals: [],
    incoTerm: null,
    boardingDate: null,
    acceptRiskManagement: null,
    acceptPolicyAndPrivacy: null
  },
  files: []
}