import React from "react";
// config
import config from "config";
// translations
import { useTranslation } from "react-i18next";
// antd
import { Button, Drawer, Row } from "antd";
import { PlusOutlined } from "@ant-design/icons";
// icons
import { Users } from "react-feather";

//Custom Css
import "./styles.less";

const ClientAppSidebar = () => {
  const { t: translation } = useTranslation();

  const handleCreateClientAppUser = () =>
    window.open(`${config.clientAppUrl}/criar-conta`, "_blank");

  return (
    <div className="gx-module-side client-app-sidebar-content">
      <div className="gx-module-side-header">
        <div className="gx-module-logo">
          <Users className="gx-mr-3" />
          {translation("clientApp.container.title")}
        </div>
      </div>
      <div className="gx-module-side-content ">
        <Row type="flex" justify="center" className="gx-mt-3">
          <Button type="primary" onClick={handleCreateClientAppUser}>
            <PlusOutlined /> {translation("clientApp.registry")}
          </Button>
        </Row>
      </div>
    </div>
  );
};

ClientAppSidebar.propTypes = {};

export default ClientAppSidebar;
