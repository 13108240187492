import React, { useEffect, useState } from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Button, message, Spin } from "antd";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  setPlanList,
  setAllCoverages,
  sendCoveragePlans,
  fetchCoveragePlans,
} from "../../../../../../store/slices/cargoOpenPolicy/coverages";
// components
import PlanTable from "./PlanTable";
import AdditionalCoverageList from "./AdditionalCoverageList";

const CoveragePlans = ({ code, plan = {} }) => {
  const [fetchPlansHasError, setFetchPlansHasError] = useState(false);

  const {
    coverageData: { plans, allCoverages },
  } = useSelector((state) => state.cargoOpenPolicy.coverages);
  const { isLoading } = useSelector((state) => state.common);

  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const setCoverageFromPlan = (thisPlan = {}, coverages = []) => {
    const updatedCoverages = [];
    if (thisPlan.name === "CUSTOMIZED" && coverages) {
      coverages.forEach((coverage) => {
        updatedCoverages.push({
          ...coverage,
          selected: Boolean(
            thisPlan?.additionalCoverages?.find(
              (item) => item.type === coverage.type
            )
          ),
        });
      });
    } else {
      coverages.forEach((coverage) => {
        updatedCoverages.push({
          ...coverage,
          selected: Boolean(
            thisPlan.basicCoverages?.find((item) => item.type === coverage.type)
          ),
        });
      });
    }
    dispatch(setAllCoverages(updatedCoverages));
  };

  const clearSelectedPlan = () => {
    const updatedPlans = [...plans];
    for (let i = 0; i < updatedPlans.length; i += 1) {
      updatedPlans[i] = { ...updatedPlans[i], selected: false };
    }
    dispatch(setPlanList(updatedPlans));
  };

  const handleSelectPlan = (plan, index) => {
    const updatedPlans = [...plans];
    for (let i = 0; i < updatedPlans.length; i += 1) {
      updatedPlans[i] = { ...updatedPlans[i], selected: false };
    }
    updatedPlans[index] = {
      ...updatedPlans[index],
      selected: true,
    };
    if (plan.basicCoverages) {
      setCoverageFromPlan(plan, allCoverages);
    }
    // setHasPlanSelected(true);
    dispatch(setPlanList(updatedPlans));
  };

  const updateCoverages = (option, event, updateType) => {
    clearSelectedPlan();
    const updatedCoverages = [...allCoverages];
    let coverageIndex;
    updatedCoverages.forEach((coverage, index) => {
      if (coverage.type === option.type) {
        coverageIndex = index;
        return true;
      }
      return false;
    });

    if (updateType === "field") {
      updatedCoverages[coverageIndex] = {
        ...updatedCoverages[coverageIndex],
        additionalData: event.target.value,
      };
    } else {
      updatedCoverages[coverageIndex] = {
        ...updatedCoverages[coverageIndex],
        selected: event.target ? event.target.checked : event,
      };
    }

    dispatch(setAllCoverages(updatedCoverages));
  };

  const handleCoverageOptionChange = (option, event) => {
    updateCoverages(option, event);
  };
  const handleOtherCoverageFieldChange = (event) => {
    updateCoverages({ type: "other" }, event, "field");
  };

  const handleSubmit = () => {
    if (!code) return;
    dispatch(
      sendCoveragePlans(code, {
        plans,
        allCoverages,
      })
    )
      .then(() => {
        message.success(
          translation("cargoOpenPolicy.coveragePage.successOnSaveData")
        );
      })
      .catch((error) => {
        message.error(error.response && error.response.data.message);
      });
  };

  const setInitialPlans = (initialPlans = []) => {
    const updatedPlans = [...initialPlans];
    updatedPlans.forEach((thisPlan) => {
      if (thisPlan.name === plan.name) {
        thisPlan.selected = true;
      }
    });
    dispatch(setPlanList(updatedPlans));
  };

  useEffect(() => {
    dispatch(fetchCoveragePlans(code))
      .then((response) => {
        setFetchPlansHasError(false);
        setInitialPlans(response.data.plans);
        setCoverageFromPlan(plan, response.data.allCoverages);
      })
      .catch(() => {
        setFetchPlansHasError(true);
      });
  }, [dispatch]);

  return (
    <Spin
      spinning={isLoading}
      tip={translation("cargoOpenPolicy.coveragePage.spinnerText")}
    >
      {fetchPlansHasError && plans.length < 1 ? (
        <p className="gx-mt-5 gx-mb-3 gx-text-center">
          {translation("cargoOpenPolicy.coveragePage.errorOnFetchPlans")}
        </p>
      ) : (
        <>
          <h4 className="gx-mt-3 gx-mb-3">
            {translation("cargoOpenPolicy.coveragePage.planSubtitle")}
          </h4>
          <PlanTable plans={plans} onSelect={handleSelectPlan} />
          <h4 className="gx-mt-3 gx-mb-3">
            {translation("cargoOpenPolicy.coveragePage.coverageSubtitle")}
          </h4>
          <AdditionalCoverageList
            allCoverages={allCoverages}
            onOptionChange={handleCoverageOptionChange}
            onChangeOtherField={handleOtherCoverageFieldChange}
          />
          <div className="gx-mt-5 gx-mb-2 gx-d-flex gx-justify-content-end">
            <Button type="primary" onClick={handleSubmit}>
              {translation("cargoOpenPolicy.coveragePage.saveButton")}
            </Button>
          </div>
        </>
      )}
    </Spin>
  );
};

CoveragePlans.propTypes = {
  code: PropTypes.string.isRequired,
};

export default CoveragePlans;
