import React, {Component, Fragment} from 'react';
//Components
import {Row, Col, Input, Checkbox, Icon, message} from 'antd';
//Custom Components
import MaskedInput from "../MaskedInput";
import CustomScrollbars from "util/CustomScrollbars";
//Custom Css
import './Styles.less';
//External Css
import 'react-datasheet/lib/react-datasheet.css';
//Translations
import Translation from "./Translation";
//Helper Functions
import {formatMaskInput, formatMoney} from "../../helpers";
//Libs
import ReactDataSheet from 'react-datasheet';
import produce from "immer";
import moment from "moment";
import { conformToMask } from "react-text-mask";

moment.locale('pt-br');
//Constants
const dateApiFormat = "YYYY-MM-DD";
const dateFormat = "DD/MM/YYYY";
const cpfMask = [/[0-9]/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

const tableEntries = [
    {
        row: "group",
        column: Translation.group
    },
    {
        row: "name",
        column: Translation.name
    },
    {
        row: "birthDate",
        column: Translation.birthDate
    },
    {
        row: "documentNumber",
        column: Translation.documentNumber
    },
    {
        row: "salary",
        column: Translation.salary
    }
];

function checkDate(value) {
    value = moment(value, "DD/MM/YYYY", true);
    if (value.isValid())
        return value.isBefore();
    return false;
}

//Helper functions
function getSelected(selectedRows) {
    let selected = 0;
    for (let selection in selectedRows) {
        if (selectedRows.hasOwnProperty(selection)) {
            if (!!selectedRows[selection])
                selected += 1;
        }
    }
    return selected
}

function formatSelected(selected, entries) {
    if (selected === 0)
        return "Nenhum selecionado";
    else if (selected === entries)
        return "Todos selecionados";
    else
        return `${selected} / ${entries} selected`
}

function formatUpdatedValue(value, identifier) {
    let valid;
    switch (identifier) {
        case 2: {
            valid = checkDate(value);
            if (valid){
                value = moment(value, dateFormat).format(dateApiFormat);
            } else {
                message.warning('Por favor, informe uma data de nascimento válida:  '+ value);
            }
            break;
        }
        case 3:
        case 4: {
            value = value = formatMaskInput(value);
            break;
        }
        default: 
            break
    }
    return value;
}

const ValueViewer = (props) => {
    const {value} = props;
    let formattedValue = value;
    return (
        <span className='value-viewer'>
        {formattedValue}
      </span>
    )
};

class InputEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            updatedValue: props.value
        }
    }

    componentWillUnmount() {
        const {onCommit} = this.props;
        const {updatedValue} = this.state;
        onCommit(updatedValue);
    }

    handleChange = (event) => {
        this.setState({
            updatedValue: event.target.value
        })
    };

    render() {
        const {onKeyDown} = this.props;
        const {updatedValue} = this.state;
        return (
            <Input
                autoFocus
                value={updatedValue}
                onChange={this.handleChange}
                onKeyDown={onKeyDown}
            />
        )
    }
}

class MaskEditor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            updatedValue: props.value
        }
    }

    componentWillUnmount() {
        const {onCommit} = this.props;
        const {updatedValue} = this.state;
        onCommit(updatedValue);
    }

    handleChange = (event) => {
        this.setState({
            updatedValue: event.target.value
        })
    };

    render() {
        const {mask, onKeyDown} = this.props;
        const {updatedValue} = this.state;
        return (
            <MaskedInput autoFocus
                         mask={mask}
                         value={updatedValue}
                         onChange={this.handleChange}
                         onKeyDown={onKeyDown}/>
        )
    }

};

class DataSheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            draft: props.data,
            grid: DataSheet.prepareGrid(props.data),
            selectedRows: {}
        }
    }

    static getEmptyRow() {
        return [
            {value: null, identifier: 0},
            {value: null, identifier: 1},
            {value: null, identifier: 2},
            {value: null, identifier: 3},
            {value: null, identifier: 4}
        ]
    }

    static formatGridRow(data = {}, group) {
        const gridRow = DataSheet.getEmptyRow();

        tableEntries.forEach((entry, index) => {
            const entity = entry.row;
            gridRow[index].value = data[entity] || null;
        });

        return gridRow;
    }

    static prepareGrid(data) {
        const updatedData = [];

        data.forEach(entry => {
            updatedData.push(DataSheet.formatGridRow(entry))
        });

        return updatedData;
    }

    static parseGrid(data) {
        const updatedData = [];

        data.forEach(row => {
            const [group, name, birthDate, documentNumber, salary] = row;
            updatedData.push({
                group: group.value,
                name: name.value,
                birthDate: birthDate.value,
                documentNumber: documentNumber.value,
                salary: salary.value
            });
        });

        return updatedData;
    }

    reset = () => {
        this.setState(prevState => {
            return {
                grid: DataSheet.prepareGrid(prevState.draft),
                selectedRows: {}
            }
        })
    };

    getChanges = () => {
        const {grid} = this.state;
        return {
            insuredLives: DataSheet.parseGrid(grid)
        }
    };

    notifyChanges = () => {
        return this.props.notifyChanges();
    };

    reformatOrderGrid = (newData) => {
        this.setState({
            draft: newData,
            grid: DataSheet.prepareGrid(newData),
            selectedRows: {}
        })
    };

    getDataEditor = (props) => {
        const {col} = props;
        switch (col) {
            case 2:
                return <MaskEditor mask="99/99/9999" {...props} />;
            case 3:
                return <MaskEditor mask="999.999.999-99" {...props} />;
            default:
                return <InputEditor {...props} />;
        }
    };

    handleChanges = (changes) => {
        const {grid} = this.state;
        const updatedGrid = produce(grid, draft => {
            changes.forEach(change => {
                const {cell: {identifier}, row, col, value} = change;
                if (draft[row] && draft[row][col])
                    draft[row][col].value = formatUpdatedValue(value, identifier);
            });
        });
        this.props.handleChange({
            attr: 'insuredLives',
            value: DataSheet.parseGrid(updatedGrid)
        })

        this.setState({grid: updatedGrid}, this.notifyChanges);
    };

    resetSelection = () => {
        this.setState({
            selectedRows: {}
        }, this.notifyChanges)
    };

    handleRowSelect = (event) => {
        const index = event.target.rowIndex;
        this.setState(produce(this.state, draft => {
            draft.selectedRows[index] = !draft.selectedRows[index];
        }))
    };

    handleRowsRemoval = () => {
        this.setState(produce(this.state, draft => {
            const selectedRows = draft.selectedRows;
            const validRemoval = [];
            for (let selected in selectedRows) {
                if (selectedRows.hasOwnProperty(selected) && selectedRows[selected])
                    validRemoval.push(parseInt(selected));
            }
            draft.grid = draft.grid.filter((row, index) => !validRemoval.includes(index))
            this.props.handleChange({
                attr: 'insuredLives',
                value: DataSheet.parseGrid(draft.grid)
            })
        }), this.resetSelection)
    };

    handleRowCreate = () => {
        this.setState(produce(this.state, draft => {
            draft.grid.push(DataSheet.getEmptyRow())
        }))
    };

    handleSelectAllRows = (event) => {
        const selected = event.target.selected;
        this.setState(produce(this.state, draft => {
            let selectedRows = {};
            if ((selected !== draft.grid.length) || (selected === 0)) {
                draft.grid.forEach((row, index) => {
                    selectedRows[index] = true;
                })
            }
            draft.selectedRows = selectedRows;
        }));
    };

    handleRowRenderer = (props) => {
        const {selectedRows} = this.state;
        const {row, children} = props;
        return (
            <tr>
                <td>
                    <Checkbox
                        indeterminate={false}
                        rowIndex={row}
                        onChange={this.handleRowSelect}
                        checked={selectedRows[row]}/>
                </td>
                {children}
            </tr>
        )
    };

    handleValueRenderer = (cell) => {
        let { value, identifier } = cell;

        switch (identifier) {
            case 2: {
                value = value ? moment(value, dateApiFormat).format(dateFormat) : null;
                break;
            }
            case 3: {
                value = value ? conformToMask(value, cpfMask, { guide: false }) : null;
                value = value ? value.conformedValue : null;
                break;
            }
            case 4: {
                value = value ? formatMoney(value) : null;
                break;
            }
            default:
                break;
        }
        return value;
    };

    render() {
        const {grid, selectedRows} = this.state;
        const selected = getSelected(selectedRows);

        const handleSheetRenderer = (props) => {
            return (
                <Fragment>
                    <section className="data-grid-action">
                        <Row type="flex" justify="space-between" align="middle">
                            <Col className="selected-text">
                                {formatSelected(selected, grid.length)}
                            </Col>
                            <Col>
                                <Icon type="user-add"
                                      className="add-icon"
                                      onClick={this.handleRowCreate}/>

                                <Icon type="delete"
                                      className="remove-icon"
                                      onClick={this.handleRowsRemoval}/>
                            </Col>
                        </Row>
                    </section>
                    <section className="data-grid-table-container">
                        <table className={props.className}>
                            <thead>
                            <tr>
                                {grid.length > 0 && <td>
                                    <Checkbox
                                        indeterminate={selected !== 0 && selected !== grid.length}
                                        checked={selected === grid.length}
                                        selected={selected}
                                        onChange={this.handleSelectAllRows}
                                    />
                                </td>}
                                {tableEntries.map((entry, index) => (<th key={index} className="cellHead">{entry.column}</th>))}
                            </tr>
                            </thead>
                            < tbody>
                            {props.children}
                            </tbody>
                        </table>
                    </section>
                </Fragment>
            )
        };

        return (
            <CustomScrollbars className="gx-module-side-scroll">
                <section className="customTable">
                    <ReactDataSheet
                        data={grid}
                        valueRenderer={this.handleValueRenderer}
                        sheetRenderer={handleSheetRenderer}
                        rowRenderer={this.handleRowRenderer}
                        onCellsChanged={this.handleChanges}
                        valueViewer={ValueViewer}
                        dataEditor={this.getDataEditor}
                    />
                </section>
            </CustomScrollbars>
        )
    }
}

export default DataSheet;
DataSheet.defaultProps = {};
DataSheet.propTypes = {};