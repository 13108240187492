import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col } from "antd";
// components
import LabelField from "../../../LabelField";
import FormattedValue from "../../../FormatedValue/FormattedValue";
// helpers
import { getFormattedDate } from "../../../../helpers";

const HistoryHeader = ({ registerData = {}, policy = {} }) => {
  const { t: translation } = useTranslation();
  return (
    <>
      <Row>
        <Col xs={24} md={12}>
          <LabelField
            label={translation("invoice.historyHeader.companyNameLabel")}
          >
            <span>{registerData.companyName}</span>
          </LabelField>
        </Col>
        <Col xs={24} md={12}>
          <LabelField
            label={translation("invoice.historyHeader.documentNumberLabel")}
          >
            <FormattedValue
              mask="_"
              displayType="text"
              value={registerData.documentNumber}
              format={"COMPANY"}
            />
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>
          <LabelField
            label={translation("invoice.historyHeader.policyNumberLabel")}
          >
            <span>{policy.policyNumber}</span>
          </LabelField>
        </Col>
        <Col xs={24} md={12}>
          <LabelField
            label={translation("invoice.historyHeader.initialAtLabel")}
          >
            <span>{getFormattedDate(policy.initialTermAt)}</span>&nbsp;
            {translation("invoice.historyHeader.finalAtLabel")}&nbsp;
            <span>{getFormattedDate(policy.finalTermAt)}</span>
          </LabelField>
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={12}>
          <LabelField label={translation("invoice.historyHeader.insurerLabel")}>
            <span>{policy.insurer}</span>
          </LabelField>
        </Col>
      </Row>
    </>
  );
};

HistoryHeader.propTypes = {
  registerData: PropTypes.object,
  policy: PropTypes.object,
};

export default HistoryHeader;
