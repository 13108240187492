import React, { useState, useEffect } from "react";
// router
import { useParams } from "react-router-dom";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Spin, notification } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPolicyData,
  setCurrentTab,
  setIsChanged,
} from "../../../../../store/slices/policy";
import { fetchUsers } from "../../../../../store/slices/user";
import {
  fetchEndorsements,
  changeEndorsementData,
  setEndorsementData,
  createEndorsement,
  setErrors,
  setChangeType,
  setChangeCount,
} from "../../../../../store/slices/endorsement";
// components
import {
  Header,
  Historical,
  CreateEndorsementModal,
} from "../../../../../components/Customers/Policy/Endorsement";
// helper
import { clearError } from "../../../../../helpers";
// constants
import {
  endorsementTypes,
  endorsementChangeTypes,
} from "../../../../../constants";

const Endorsement = ({ showModal, setShowModal }) => {
  const [formData, setFormData] = useState(null);

  const {
    policy: { policy, currentTab },
    common: { isLoading },
    user: { userList },
    endorsement: {
      endorsementList,
      endorsementData,
      pagination,
      errors,
      changeType,
    },
  } = useSelector((state) => state);

  const dispatch = useDispatch();
  const params = useParams();
  const { t: translation } = useTranslation();

  const handleChange = (field, value) => {
    if (value && value.target) {
      value = value.target.value;
    }

    if (value && (value.formattedValue || value.formattedValue === "")) {
      value = value.floatValue;
    }

    if (field === "type") {
      clearEndorsement();
      setFormData(null);
    }

    dispatch(changeEndorsementData({ [field]: value }));
    dispatch(setErrors(clearError(field, errors)));
  };

  const tablePagination = {
    current: pagination.pageNumber + 1,
    total: pagination.total,
    pageSize: pagination.pageSize,
    onChange: (page) => {
      dispatch(
        fetchEndorsements(params.policy, {
          pageNumber: page - 1,
          pageSize: pagination.pageSize,
        })
      );
    },
  };

  const redirectToPolicy = () => {
    dispatch(fetchPolicyData(params.id, params.policy));
    dispatch(setCurrentTab(1));
    setShowModal(false);
  };

  const clearEndorsement = () => {
    dispatch(setEndorsementData({}));
    dispatch(setErrors([]));
    dispatch(setChangeType(""));
    dispatch(setChangeCount(0));
    dispatch(setIsChanged(false));
  };

  const handleCancelCreateEndorsement = () => {
    setShowModal(false);
    setFormData(null);
    clearEndorsement();
    dispatch(fetchEndorsements(params.policy));
  };

  const handleSave = () => {
    if (!(formData && formData.has("file"))) {
      return notification.error({
        message: translation("endorsement.createEndorsement.fileRequiredError"),
      });
    }
    dispatch(createEndorsement(params.policy, formData)).then(() => {
      setShowModal(false);
      setFormData(null);
      dispatch(setEndorsementData({}));
      dispatch(fetchPolicyData(params.id, params.policy));
      dispatch(fetchEndorsements(params.policy));
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    const newFormData = new FormData();
    newFormData.append("file", file);
    setFormData(newFormData);
    setTimeout(() => {
      onSuccess(file);
    }, 0);
  };

  const handleFileChange = () => {};

  const handleRemoveFile = () => {
    setFormData(null);
  };

  const uploadFileProps = {
    multiple: false,
    customRequest: dummyRequest,
    onChange: handleFileChange,
    onRemove: handleRemoveFile,
  };

  const handleSetEndorsementData = (data) => {
    dispatch(changeEndorsementData(data));
  };

  useEffect(() => {
    if (currentTab === "5") {
      dispatch(fetchPolicyData(params.id, params.policy));
      dispatch(fetchEndorsements(params.policy));
      dispatch(fetchUsers());
    }
  }, [currentTab]);

  return (
    <Spin spinning={isLoading}>
      <CreateEndorsementModal
        errors={errors}
        loading={isLoading}
        visible={showModal}
        changeType={changeType}
        data={endorsementData}
        policy={policy}
        uploadFileProps={uploadFileProps}
        hasFile={formData && formData.has("file")}
        onChange={handleChange}
        onCancelModal={handleCancelCreateEndorsement}
        onCancel={handleCancelCreateEndorsement}
        onSave={handleSave}
        setData={handleSetEndorsementData}
      />
      <Header data={policy} />
      <Row type="flex" className="gx-mb-2 gx-mt-3">
        <Historical
          policy={policy}
          endorsements={endorsementList}
          users={userList}
          pagination={tablePagination}
        />
      </Row>
    </Spin>
  );
};

export default Endorsement;
