import React, { useEffect } from "react";
// router
import { useParams, useHistory } from "react-router-dom";
// prop types
import PropTypes from "prop-types";
// antd
import { Spin } from "antd";
// components
import RegisterDataForm from "../../../../components/Customers/CustomerTabs/RegisterDataForm";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  setErrors,
  saveCustomerData,
  fetchCustomerData,
  createNewCustomer,
  changeCustomerData,
  requestCustomerRegisterData,
  cleanRegisterData,
  addPolicyEmissionPermission,
  removePolicyEmissionPermission,
  getPolicyEmissionPermission,
} from "../../../../store/slices/customer";
//helpers
import { clearError } from "helpers";
// constants
import { customerTabs } from "../../../../constants";

const RegisterData = ({ actualTab, setCustomerDataInOldStore }) => {
  const { isLoading, registerData, errors } = useSelector(
    (state) => state.customer
  );

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();

  const isNewCustomer = () => {
    return params.id === "new";
  };

  const onChange = (name) => (event) => {
    let value;
    if (event) {
      event.hasOwnProperty("target")
        ? (value = event.target.value)
        : (value = event);
    } else {
      value = event;
    }
    dispatch(setErrors(clearError(name, errors)));
    dispatch(changeCustomerData({ [name]: value }));
  };

  const onSave = () => {
    if (isNewCustomer()) {
      return dispatch(createNewCustomer()).then(() => {
        history.push("/crm/customers");
      });
    }
    dispatch(saveCustomerData(params.id));
  };

  const onCancel = () => {
    if (isNewCustomer()) {
      return dispatch(cleanRegisterData());
    }
    dispatch(fetchCustomerData(params.id));
  };

  const requestDataByDocument = () => {
    dispatch(requestCustomerRegisterData(registerData.documentNumber));
  };

  const handleToggleEmissionPermission = (event) => {
    if (!!event && event !== false) {
      dispatch(addPolicyEmissionPermission()).then(() => {
        dispatch(setErrors(clearError("emissionPermission", errors)));
        dispatch(changeCustomerData({ emissionPermission: event }));
      });
    } else {
      dispatch(removePolicyEmissionPermission()).then(() => {
        dispatch(setErrors(clearError("emissionPermission", errors)));
        dispatch(changeCustomerData({ emissionPermission: event }));
      });
    }
  };

  useEffect(() => {
    if (actualTab === customerTabs.registerData && !isNewCustomer()) {
      dispatch(fetchCustomerData(params.id)).then((response) => {
        setCustomerDataInOldStore(response.data);
        dispatch(getPolicyEmissionPermission()).then((response) => {
          dispatch(changeCustomerData({ emissionPermission: response?.data }));
        });
      });
    }
  }, [actualTab]);

  useEffect(() => {
    return () => {
      dispatch(cleanRegisterData());
      dispatch(setErrors([]));
    };
  }, []);

  return (
    <Spin spinning={isLoading}>
      <div style={{ padding: "0 32px" }}>
        <RegisterDataForm
          errors={errors}
          registerData={registerData}
          onChange={onChange}
          onCancel={onCancel}
          onSave={onSave}
          onSearch={requestDataByDocument}
          onToggleEmissionPermission={handleToggleEmissionPermission}
          isNewCustomer={isNewCustomer()}
        />
      </div>
    </Spin>
  );
};

RegisterData.propTypes = {
  actualTab: PropTypes.number,
};

export default RegisterData;
