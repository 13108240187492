//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("orderActions");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    saveChanges: getTranslation("saveChanges", true),
    resetChanges: getTranslation("resetChanges", true),
}