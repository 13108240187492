import React from 'react';
//Translation
import Translation from "../Contacts/translation";
//Config
import config from "../Contacts/config";
//Components
import {Select} from "antd";
//Constants
const Option = Select.Option;

const SelectPhone = (props) => {
  const {value,onChange} = props;
  return (
    <Select
      value={value}
      onChange={onChange}
      className="gx-w-100"
      placeholder={Translation.contactSelectPhoneType}
    >
      {Object.values(config).map(entry => {
        const {type, label} = entry;
        return (
          <Option key={type} value={type}>
            {label}
          </Option>)
      })}
    </Select>
  );
};

export default SelectPhone;