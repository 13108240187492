import React, { useState, useEffect } from "react";
// antd
import { Row, Col, Spin, Card } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLimitList,
  fetchLimit,
  setLimitList,
} from "../../../../store/slices/limits";
// components
import LimitCard from "../../../../components/Customers/CustomerTabs/Limits";
// styles
import "./styles.less";
// contants
import { limitInsurers, customerTabs } from "../../../../constants";

const Limits = ({ documentNumber, actualTab }) => {
  const { list, isLoading, isProcessing } = useSelector(
    (state) => state.limits
  );

  const [selectedInsurer, setSelectedInsurer] = useState(null);

  const dispatch = useDispatch();

  const handleProcess = (insurer) => {
    setSelectedInsurer(insurer);
    dispatch(fetchLimit(insurer, documentNumber)).then((response) => {
      if (list && response.insurer) {
        let limits = [];
        limits = list.filter((limit) => limit.insurer !== response.insurer);
        return limits && setLimitList(limits.push(response));
      }
    });
  };

  useEffect(() => {
    if (actualTab === customerTabs.limits) {
      dispatch(fetchLimitList(limitInsurers, documentNumber));
    }
  }, [actualTab]);

  return (
    <Spin spinning={isLoading}>
      <Row className="limitSection">
        {list.map((limit, index) => (
          <Col sm={24} md={24} lg={24} key={index}>
            <LimitCard
              limit={limit}
              isProcessing={selectedInsurer === limit.insurer && isProcessing}
              handleProcess={handleProcess}
            />
          </Col>
        ))}
      </Row>
    </Spin>
  );
};

export default Limits;
