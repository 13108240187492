import React from "react";
// prop types
import PropTypes from "prop-types";
// antd
import { Row, Col } from "antd";
// components
import HistoryItem from "./HistoryItem";

const HistoryList = ({ emailHistory = [], label }) => {
  return (
    <>
      {emailHistory.map((item, index) => {
        return (
          <Row className="gx-mb-3">
            <Col sm={24} md={24} className="gx-mb-2">
              {index === 0 && label}
            </Col>
            <Col sm={24} md={24}>
              <HistoryItem key={index} historyItem={item} hasHeader />
            </Col>
          </Row>
        );
      })}
    </>
  );
};

HistoryList.propTypes = {
  emailHistory: PropTypes.array,
};

export default HistoryList;
