import React from 'react';
//Translation
import Translation from './translation';
//Components
import {Switch} from 'antd';

const CustomSwitch = (props) => {
    const {checked, onChange} = props;
    return (
        <Switch
            checked={checked}
            onChange={onChange}
            checkedChildren={Translation.yes}
            unCheckedChildren={Translation.no}
            {...props}/>
    );
};

export default CustomSwitch;