export default function (serverData, prevConfig) {

    function updateInitial() {
        const data = serverData.initial;
        const prevState = prevConfig.initial;
        return {
            name: data.name || prevState.name,
            email: data.email || prevState.email,
            phoneNumber: data.phoneNumber || prevState.phoneNumber,
            phoneType: data.phoneType || prevState.phoneType,
            receiveWhatsAppMessage: data.receiveWhatsAppMessage || prevState.receiveWhatsAppMessage,
            contactType: data.contactType || prevState.contactType,
            documentNumber: data.documentNumber || prevState.documentNumber,
            hasDocumentNumber: data.hasDocumentNumber || prevState.hasDocumentNumber,
        }
    }

    function updateCustomer() {
        const data = serverData.customer;
        const prevState = prevConfig.customer;
        return {
            documentNumber: data.documentNumber || prevState.documentNumber,
            type: data.type || prevState.type,
            name: data.name || prevState.name,
            zipCode: data.zipCode || prevState.zipCode,
            address: data.address || prevState.address,
            number: data.number || prevState.number,
            neighborhood: data.neighborhood || prevState.neighborhood,
            state: data.state || prevState.state,
            city: data.city || prevState.city,
            additionalData: data.additionalData || prevState.additionalData,
        }
    }

    function updateGeneral() {
        const data = serverData.general;
        const prevState = prevConfig.general;
        return {
            employeesAmount: data.employeesAmount || prevState.employeesAmount,
            averageAgeEmployees: data.averageAgeEmployees || prevState.averageAgeEmployees,
            capitalInsuredPerson: data.capitalInsuredPerson || prevState.capitalInsuredPerson,
            reasonTakeInsurance: data.reasonTakeInsurance || prevState.reasonTakeInsurance,
            hasBiddingWaiver: data.hasBiddingWaiver || prevState.hasBiddingWaiver,
            isPublicCompany: data.isPublicCompany || prevState.isPublicCompany
        }
    }

    function updateCoverages() {
        const data = serverData.coverages;
        const prevState = prevConfig.coverages;
        return {
            coverages: data.coverages || prevState.coverages,
            assistances: data.assistances || prevState.assistances
        }
    }

    function updateDetails() {
        const data = serverData.details;
        const prevState = prevConfig.details;
        return {
            capitalType: data.capitalType || prevState.capitalType,
            insurableGroups: data.insurableGroups || prevState.insurableGroups
        }
    }

    function updateInsuredLives() {
        const data = serverData.insured;
        const prevState = prevConfig.insured;
        return {
            insuredLives: data.insuredLives || prevState.insuredLives,
            additionalData: data.additionalData || prevState.additionalData
        }
    }

    function filesDetails() {
        const data = serverData.files;
        const prevState = prevConfig.files;
        return {
            files: data || prevState,
        }
    }

    return {
        initial: updateInitial(),
        general: updateGeneral(),
        coverages: updateCoverages(),
        details: updateDetails(),
        insured: updateInsuredLives(),
        customer: updateCustomer(),
        files: filesDetails()
    };
}