import React from 'react';
//Translation
import Translation from "./translation";
import {Row, Col, DatePicker, Select} from "antd";
//Custom Components
import {Switch, MultipleSelect, FormField} from "components";
//Constants
import selections from "constants/selections";
import {frontEndDate, prepareOptions} from "../../../../../../helpers";

const Details = (props) => {
  const {handleChange, configurations, OrderErrors} = props;

  const getValue = (entity, callbackUi = null) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
    return callbackUi;
  };

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;
    if (!isDirect && typeof event === "object") {
      if (event instanceof Array)
        value = event;
      else
        value = event.target.value;
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  const toggleValue = (attr) => (event) => {
    return handleChange({
      attr: attr,
      value: !getValue(attr)
    });
  };


  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.transportMeansLabel}:
        </Col>
        <Col xs={24} md={18}>
            <MultipleSelect
              className="gx-w-100"
              value={getValue("modals")}
              onChange={onChange("modals")}
              options={selections.meansOfTransportation}
              placeholder="Selecione os modais de transporte do pedido de seguro"
              OrderErrors={OrderErrors}
              validationParam="details.modals"
            />
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.incoTermLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField error={OrderErrors['details.incoTerm']}>
            <Select
              className="gx-w-100"
              value={getValue("incoTerm")}
              onChange={onChange("incoTerm")}
              placeholder={Translation.incoTermPlaceholder}>
              {prepareOptions(selections.incoTerms)}
            </Select>
          </FormField>

        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.boardingDateLabel}:
        </Col>
        <Col xs={24} md={18}>
        <FormField error={OrderErrors['details.boardingDate']}>
          <DatePicker showToday={true}
                      allowClear={false}
                      format={frontEndDate}
                      value={getValue("boardingDate")}
                      onChange={onChange("boardingDate", true)}/>
        </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.acceptRiskMngmLabel}:
        </Col>
        <Col xs={24} md={18}>
          <Switch
            onChange={toggleValue("acceptRiskManagement")}
            checked={getValue("acceptRiskManagement")}/>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.acceptPolicyPrivacyLabel}:
        </Col>
        <Col xs={24} md={18}>
          <Switch
            onChange={toggleValue("acceptPolicyAndPrivacy")}
            checked={getValue("acceptPolicyAndPrivacy")}/>
        </Col>
      </Row>
    </section>
  );
};


export default Details;