// Translation
import {
  suretyBondTranslation,
  stageTranslation,
  typeTranslation,
} from "./traslation";

const stagesEntries = [
  {
    title: stageTranslation.stageCreatedLabel,
    type: "CREATED",
    icon: "star",
  },
  {
    title: stageTranslation.stageAnalysisLabel,
    type: "ANALYSIS",
    icon: "important",
  },
  {
    title: stageTranslation.stagePendingLabel,
    type: "PENDING",
    icon: "schedule",
  },
  {
    title: stageTranslation.stageProposalsLabel,
    type: "PROPOSALS",
    icon: "calendar",
  },
  {
    title: stageTranslation.acceptedLabel,
    type: "APPROVED",
    icon: "check-circle-o",
  },
  {
    title: stageTranslation.archivedLabel,
    type: "ARCHIVED",
    icon: "trash",
  },
];

const typeEntries = [
  {
    id: 1,
    type: "CARGO_SINGLE_BOARDING",
    title: typeTranslation.cargoSingleBoardingLabel,
    color: "teal",
  },
  {
    id: 2,
    type: "CARGO_OPEN_POLICY",
    title: typeTranslation.cargoOpenPolicyLabel,
    color: "indigo",
  },
  {
    id: 3,
    type: "SURETY",
    title: typeTranslation.suretyLabel,
    color: "green",
  },
  {
    id: 4,
    type: "LIFE",
    title: typeTranslation.lifeLabel,
    color: "info",
  },
];

const typeEntriesColors = {
  CARGO_SINGLE_BOARDING: "#20c997",
  CARGO_OPEN_POLICY: "#3c1991",
  SURETY: "#91bf1d",
  LIFE: "#1890ff",
};

const orderTypeTranslation = {
  CARGO_SINGLE_BOARDING: typeTranslation.cargoSingleBoardingLabel,
  CARGO_OPEN_POLICY: typeTranslation.cargoOpenPolicyLabel,
  SURETY: typeTranslation.suretyLabel,
  LIFE: typeTranslation.lifeLabel,
};

const bondTypes = {
  SURETY: {
    CONSTRUCTION_BOND: suretyBondTranslation.constructionBondLabel,
    BUSINESS_SERVICES_BOND: suretyBondTranslation.businessServicesBondLabel,
    SUPPLY_BOND: suretyBondTranslation.supplyBondLabel,
    ADVANCED_PAYMENT_BOND: suretyBondTranslation.advancedPaymentBondLabel,
    BIDDING_BOND: suretyBondTranslation.biddingBondLabel,
    TAX_CREDITS_BOND: suretyBondTranslation.taxCreditsBondLabel,
    DEALER_PERFORMER_BOND: suretyBondTranslation.dealerPerformerBondLabel,
    WORK_COMPLETION_BOND: suretyBondTranslation.workCompletionBondLabel,
    CUSTOMS_BOND: suretyBondTranslation.customsLabel,
    FINANCIAL: suretyBondTranslation.financialLabel,
    REAL_ESTATE: suretyBondTranslation.realEstateLabel,
    CORRECTIVE_MAINTENANCE_BOND:
      suretyBondTranslation.correctiveMaintenanceBondLabel,
    PAYMENT_RETENTION_BOND: suretyBondTranslation.paymentRetentionBondLabel,
    REAL_STATE_BOND: suretyBondTranslation.realEstateBondLabel,
    ENERGY_SECTOR_BOND: suretyBondTranslation.energySectorBondLabel,
    NAVY_SECTOR_BOND: suretyBondTranslation.navySectorBondLabel,
    OIL_GAS_SECTOR_BOND: suretyBondTranslation.oilGasSectorBondLabel,
    ENVIRONMENTAL_BOND: suretyBondTranslation.environmentalBondLabel,
    LABOUR_APPEAL_FILLING_BOND:
      suretyBondTranslation.labourAppealFilingBondLabel,
    CIVIL_COURT_BOND: suretyBondTranslation.civilCourtBondLabel,
    TAX_ENFORCEMENT_BOND: suretyBondTranslation.taxEnforcementBondLabel,
    TAX_ADMINISTRATIVE_INSTALLMENT_BOND:
      suretyBondTranslation.taxAdministrativeInstallmentBondLabel,
    COURT_OTHERS_BOND: suretyBondTranslation.courtOthersBondLabel,
  },
};

export default {
  stagesEntries,
  typeEntries,
  typeEntriesColors,
  bondTypes,
  orderTypeTranslation,
};
