// axios
import axios from "axios";
// config
import config from "config";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL = config.apiUrl;

axiosInstance.interceptors.request.use(
  (config) => {
    let userId = localStorage.getItem("user_id");
    userId = userId ? JSON.parse(userId).id : "";
    config.headers = {
      "mutuus-user": userId,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
