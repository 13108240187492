//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("orderConfig");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export const stageTranslation = {
    stageCreatedLabel: getTranslation("stageCreatedLabel"),
    stageAnalysisLabel: getTranslation("stageAnalysisLabel"),
    stagePendingLabel: getTranslation("stagePendingLabel"),
    stageProposalsLabel: getTranslation("stageProposalsLabel"),
    acceptedLabel: getTranslation("acceptedLabel"),
    archivedLabel: getTranslation("archivedLabel")
};

export const typeTranslation = {
    cargoSingleBoardingLabel: getTranslation("cargoSingleBoardingLabel"),
    cargoOpenPolicyLabel: getTranslation("cargoOpenPolicyLabel"),
    suretyLabel: getTranslation("suretyLabel"),
    lifeLabel: getTranslation("lifeLabel")
};

export const suretyBondTranslation = {
    constructionBondLabel: getTranslation("constructionBondLabel"),
    businessServicesBondLabel: getTranslation("businessServicesBondLabel"),
    supplyBondLabel: getTranslation("supplyBondLabel"),
    advancedPaymentBondLabel: getTranslation("advancedPaymentBondLabel"),
    biddingBondLabel: getTranslation("biddingBondLabel"),
    taxCreditsBondLabel: getTranslation("taxCreditsBondLabel"),
    dealerPerformerBondLabel: getTranslation("dealerPerformerBondLabel"),
    workCompletionBondLabel: getTranslation("workCompletionBondLabel"),
    customsLabel: getTranslation("customsLabel"),
    financialLabel: getTranslation("financialLabel"),
    realEstateLabel: getTranslation("realEstateLabel"),
    correctiveMaintenanceBondLabel: getTranslation("correctiveMaintenanceBondLabel"),
    paymentRetentionBondLabel: getTranslation("paymentRetentionBondLabel"),
    realEstateBondLabel: getTranslation("realEstateBondLabel"),
    energySectorBondLabel: getTranslation("energySectorBondLabel"),
    navySectorBondLabel: getTranslation("navySectorBondLabel"),
    oilGasSectorBondLabel: getTranslation("oilGasSectorBondLabel"),
    environmentalBondLabel: getTranslation("environmentalBondLabel"),
    labourAppealFilingBondLabel: getTranslation("labourAppealFilingBondLabel"),
    civilCourtBondLabel: getTranslation("civilCourtBondLabel"),
    taxEnforcementBondLabel: getTranslation("taxEnforcementBondLabel"),
    taxAdministrativeInstallmentBondLabel: getTranslation("taxAdministrativeInstallmentBondLabel"),
    courtOthersBondLabel: getTranslation("courtOthersBondLabel")
};

