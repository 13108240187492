import React from 'react';
//Proptypes
import PropTypes from 'prop-types';
//Components
import {Input} from 'antd';
//Others
import {injectIntl, intlShape} from 'react-intl';
//Constants
const TextArea = Input.TextArea;
const LngTextArea = (props) => {
    const {intl, placeholder, inputType, ...others} = props;
    let currentPlaceholder = placeholder;
    if (typeof currentPlaceholder === "object")
        currentPlaceholder = intl.formatMessage(placeholder);
    /*
    *   TODO - Add a way so that Lng Input can be a wrapper and render input as children(LngInput => LngProvider HOC)
    *   @author - E Metaliaj
    *   @date - 8/26/2019
    *   @time - 8:39 PM
    * */
    switch (inputType) {
        case "html":
            return <textarea {...others} placeholder={currentPlaceholder}/>;
        case "antd":
            return <TextArea {...others} placeholder={currentPlaceholder}/>;
        default:
            return null;
    }
};
LngTextArea.defaultProps = {
    inputType: "antd"
};
LngTextArea.propTypes = {
    intl: intlShape.isRequired,
    inputType: PropTypes.oneOf(["html", "antd"]),
    placeholder: PropTypes.object
};
export default injectIntl(LngTextArea);