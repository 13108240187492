import React, {Component} from "react";
import {Avatar, Checkbox, Dropdown, Menu} from "antd";
//Custom Components
import EditUser from "../EditUser";
import DeleteUser from '../DeleteUser';
//Translation config
import Translation from './translation';
//Custom Helpers
import {getNumberOfSelectedUsers, getFirstLetter} from "../helpers";

class UserDetails extends Component {
    state = {
        openUpdateUser: false,
        openDeleteUser: false
    };

    static renderMenu(callback) {
        return (
            <Menu onClick={callback}>
                <Menu.Item>
                    {Translation.editUserLabel}
                </Menu.Item>
                <Menu.Item>
                    {Translation.deleteUserLabel}
                </Menu.Item>
            </Menu>
        )
    }

    selectCurrentUser = () => {
        const {selectedUsers, selectUser, selectUsers, user} = this.props;
        if (selectedUsers[user.id]) {
            if (getNumberOfSelectedUsers(selectedUsers) > 1) {
                selectUsers('none');
                selectUser(user.id);
            }
        } else {
            if (getNumberOfSelectedUsers(selectedUsers) > 0)
                selectUsers('none');
            selectUser(user.id);
        }
    };

    deselectAllUsers = () => {
        this.props.selectUsers("none");
    };


    toggleModal = (modalName) => () => {
        let isModalOpen;
        this.setState(prevState => {
            isModalOpen = !!prevState[`open${modalName}`];
            return {
                [`open${modalName}`]: !prevState[`open${modalName}`]
            }
        }, () => {
            if (isModalOpen) {
                this.deselectAllUsers();
            } else {
                this.selectCurrentUser();
            }
        });
    };

    onDeleteUser = (user) => {
        this.setState({addUserState: false});
        this.props.onDeleteUser(user);
    };

    onUserSelect = () => {
        const {user: {id}, selectUser} = this.props;
        selectUser(id);
    };


    onMenuClick = (e) => {
        if (e.key === "item_0")
            this.toggleModal("UpdateUser")();
        else
            this.toggleModal("DeleteUser")();
    };


    render() {
        const {user, selectUser, selectedUsers, updateUser, allProfiles, loadingAllProfiles} = this.props;
        const {id, name, photo, email, whatsapp, profile: {description}} = user;
        const {openUpdateUser, openDeleteUser} = this.state;

        return (
            <div className="gx-contact-item" style={{cursor: 'pointer'}} key={id}>
                <div className="gx-module-list-icon">
                    <Checkbox className="gx-icon-btn"
                              checked={selectedUsers[id]}
                              value="checkedF"
                              onClick={this.onUserSelect}/>
                    <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
                        {(photo === null || photo === '') ?
                            <Avatar size="large">
                                {getFirstLetter(name)}
                            </Avatar>
                            :
                            <Avatar size="large" alt={name} src={photo}/>
                        }
                    </div>
                </div>
                <div className="gx-module-list-info gx-contact-list-info">
                    <div className="gx-module-contact-content" onClick={this.toggleModal("UpdateUser")}>
                        <p className="gx-mb-1">
                            <span className="gx-text-truncate gx-contact-name"> {name} </span>
                            <span className="gx-toolbar-separator">&nbsp;</span>
                            <span className="gx-text-truncate gx-job-title">{description}</span>
                        </p>
                        <div className="gx-text-muted">
            <span className="gx-email gx-d-inline-block gx-mr-2">
                {email}
            </span>
                            <span className="gx-whatsapp gx-d-inline-block">{whatsapp}</span>
                        </div>
                    </div>

                    <div className="gx-module-contact-right">
                        <Dropdown overlay={UserDetails.renderMenu(this.onMenuClick)} placement="bottomRight" trigger={['click']}>
                            <i className="gx-icon-btn icon icon-ellipse-v"/>
                        </Dropdown>

                        {openDeleteUser && <DeleteUser open={openDeleteUser}
                                                       user={user}
                                                       selectedUsers={selectedUsers}
                                                       selectUser={selectUser}
                                                       closeDeleteMode={this.toggleModal("DeleteUser")}/>}
                        {openUpdateUser &&
                        <EditUser
                            user={user}
                            open={openUpdateUser}
                            closeUpdateMode={this.toggleModal("UpdateUser")}
                            updateUser={updateUser}
                            onDeleteUser={this.onDeleteUser}
                            allProfiles={allProfiles}
                            loadingAllProfiles={loadingAllProfiles}/>}
                    </div>
                </div>
            </div>
        )
    }
}

export default UserDetails;
