import * as types from '../../actionTypes/ActionTypes';

const initialState = {
    allProfiles: {
        all: [],
        isFetching: false
    },
};
const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCHING_ALL_PROFILES:
            state = {...state, allProfiles: {...state.allProfiles, isFetching: true}};
            break;
        case types.FETCH_ALL_PROFILES_SUCCESS:
            state = {...state, allProfiles: {...state.allProfiles, all: action.payload, isFetching: false}};
            break;

        default:
            break;
    }
    return state;
};
export default commonReducer;