import React, {Component} from "react";
//PropTypes
import PropTypes from "prop-types";
//Translation
import Translation from './translation';
//Components
import {Button, Card, Col, Empty, Row, Select, Skeleton, Tabs} from "antd";
//Custom Components
import {CurrencyInput, LngInput, Switch,FormField} from "components";
//Other Libs
import produce from "immer";
//Custom Helpers
import {formatSelectValue} from "helpers";
import Request from "../../../../../helpers/Request";
import {filterSelectionOptions, prepareOptions} from "../../../../../helpers";
//Constants
import selections from "constants/selections";
const {TabPane} = Tabs;
//Custom Helpers
const sortAlphabetically = (data) => {
  return data.sort((a, b) => a.value.localeCompare(b.value))
};


const renderOptions = (data, key = "key") => {
  const optionData = [...data];
  return sortAlphabetically(optionData).map(option => {
    return (
      <Select.Option key={option[key]}>
        {option.value}
      </Select.Option>
    )
  })
};

const HeaderContent = (props) => {
  const {handleCreate} = props;
  const disabled = false;
  return (
    disabled ?
      <Button disabled={true} icon="plus">{Translation.add}</Button> :
      <Button
        icon="plus"
        onClick={handleCreate}
        className="gx-btn-outline-primary"
        htmlType="button">
        {Translation.add}
      </Button>

  )
};

const GroupCard = (props) => {

  const {index, data, categories, type, handleRemove, handleChange, OrderErrors} = props;

  const {category, subCategory, product, amount, productOther, frequency, usedMerchandise} = data;

  const categoryDetails = categories.find(category => category.key === data.category);

  const subCategoryDetails = categoryDetails && categoryDetails.subcategories &&
    categoryDetails.subcategories.find(subCategory =>
      subCategory.key === data.subCategory);

  const multipleMode = type === "multiple";

  let subCategoryList = [];
  let productList = [];

  if (category) {
    subCategoryList = categoryDetails.subcategories;
  }

  if (subCategory) {
    productList = subCategoryDetails.products;
  }

  const hasProductOther = () => {
    let value;
    if (
      category === "20" ||
      subCategory === 'other' ||
      product === 'other'
    ) {
      value = true;
    }
    return value
  };

  return (
    <Card size="small" className="fadein">
      <Row type="flex" className="gx-flex-column">
        <Col className="gx-mt-3">
          <Row type="flex" align="middle" gutter={8}>
            <Col xs={24} md={8}>
              {Translation.productCategoryLabel}
            </Col>
            <Col xs={24} md={16}>
              <FormField error={OrderErrors[`goods.goods[${index}]`]}>
                <Select
                  showSearch
                  className="gx-w-100"
                  value={formatSelectValue(category)}
                  placeholder={Translation.productCategoryPlaceholder}
                  filterOption={filterSelectionOptions}
                  onChange={handleChange(index, "category")}>
                  {renderOptions(categories)}
                </Select>
              </FormField>
            </Col>
          </Row>
        </Col>
        {subCategoryList &&
        <Col className="gx-mt-3">
          <Row type="flex" align="middle" gutter={8}>
            <Col xs={24} md={8}>
              {Translation.productSubCategoryLabel}
            </Col>
            <Col xs={24} md={16}>
            <FormField error={OrderErrors[`goods.goods[${index}]`]}>
              <Select
                showSearch
                className="gx-w-100"
                disabled={!category}
                value={formatSelectValue(subCategory)}
                placeholder={Translation.productSubCategoryPlaceholder}
                filterOption={filterSelectionOptions}
                onChange={handleChange(index, "subCategory")}>
                {category && categoryDetails.subcategories && categoryDetails.subcategories ? renderOptions(categoryDetails.subcategories) : []}
              </Select>
            </FormField>
            </Col>
          </Row>
        </Col>}
        {(subCategoryList && productList) && <Col className="gx-mt-4">
          <Row type="flex" align="middle" gutter={8}>
            <Col xs={24} md={8}>
              {Translation.productLabel}
            </Col>
            <Col xs={24} md={16}>
              <FormField error={OrderErrors[`goods.goods[${index}]`]}>
                <Select
                  showSearch
                  className="gx-w-100"
                  disabled={!subCategory}
                  value={formatSelectValue(product)}
                  placeholder={Translation.productPlaceholder}
                  filterOption={filterSelectionOptions}
                  onChange={handleChange(index, "product")}>
                  {subCategory && subCategoryDetails.products ? renderOptions(subCategoryDetails.products) : []}
                </Select>
              </FormField>
            </Col>
          </Row>
        </Col>}
        {hasProductOther() && <Col className="gx-mt-4">
          <Row type="flex" align="middle" gutter={8}>
            <Col xs={24} md={8}>
              {Translation.productOtherLabel}
            </Col>
            <Col xs={24} md={16}>
            <FormField error={OrderErrors[`goods.goods[${index}]`]}>
              <LngInput
                className="gx-w-100"
                value={productOther}
                onChange={handleChange(index, "productOther", true)}
                placeholder={Translation.productOtherPlaceholder}
              />
            </FormField>
            </Col>
          </Row>
        </Col>}
        {multipleMode && <Col className="gx-mt-3">
          <Row type="flex" align="middle" gutter={8}>
            <Col xs={24} md={8}>
              {Translation.frequencyLabel}
            </Col>
            <Col xs={24} md={16}>
            <FormField error={OrderErrors[`goods.goods[${index}].frequency`]}>
              <Select
                showSearch
                className="gx-w-100"
                value={formatSelectValue(frequency)}
                filterOption={filterSelectionOptions}
                onChange={handleChange(index, "frequency")}
                placeholder={Translation.frequencyPlaceholder}>
                {prepareOptions(selections.openPolicyFrequency)}
              </Select>
            </FormField>
            </Col>
          </Row>
        </Col>}
        <Col className="gx-mt-3">
          <Row type="flex" align="middle" gutter={8}>
            <Col xs={24} md={8}>
              {Translation.amountLabel}:
            </Col>
            <Col xs={24} md={16}>
              <FormField error={OrderErrors[`goods.goods[${index}].amount`]}>
                <CurrencyInput
                  value={amount}
                  onChange={handleChange(index, "amount")}
                  placeholder={Translation.amountPlaceholder}
                />
              </FormField>
            </Col>
          </Row>
        </Col>
        { type !== 'multiple' && <Col className="gx-mt-3">
          <Row type="flex" align="middle" gutter={8}>
            <Col>
              {Translation.usedMerchandiseLabel}
            </Col>
            <Col>
              <Switch
                checked={usedMerchandise}
                onChange={handleChange(index, "usedMerchandise")}
              />
            </Col>
          </Row>
        </Col>}
        <Col className="gx-mt-1">
          <Row type="flex" justify="end" gutter={8}>
            <Col>
              <Button
                icon="delete"
                shape="circle-outline"
                onClick={handleRemove(index)}
                className={`gx-groups-content-delete-btn`}/>
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  )
};

class Goods extends Component {

  constructor(props) {
    super(props);
    this.groupsRef = React.createRef();
    this.state = Goods.initializeState(props);
  }

  static initializeState(props) {
    let data = props.data || [];
    return {
      data: data,
      categories: null,
    }
  }

  static emptyGroup() {
    return {
      amount: null,
      product: null,
      category: null,
      subCategory: null,
      usedMerchandise: false
    }
  }

  componentDidMount() {
    this.requestCategories();
  }

  requestCategories = () => {
    Request.get(`/cargo/v1/goods/categories`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          categories: response
        })
      })
  };

  reset = () => {
    const previousData = this.props.data;
    this.setState({
      data: previousData || []
    })
  };

  scrollToBottom = () => {
    if (this.groupsRef && this.groupsRef.current)
      this.groupsRef.current.scrollTop = this.groupsRef.current.scrollHeight;
  };

  notifyChanges = () => {
    if (this.props.notifyChanges)
      return this.props.notifyChanges();
  };

  getChanges = () => {
    function formatAmount(value) {
      if(value){
        return typeof value === "object" ?
          value.floatValue : value;
      }
    }

    return this.state.data.map((product) => ({
      ...product,
      amount: formatAmount(product.amount)
    }))
  };

  onChange = (index, attr, input = false) => (event) => {
    if (input)
      event.persist();


    this.setState(produce(draft => {
      const entry = draft.data[index];

      if (attr === "category")
        entry.subCategory = null;

      if (attr === "subCategory")
        entry.product = null;

      if (attr === "usedMerchandise") {
        entry.usedMerchandise = !entry.usedMerchandise;
      } else {
        entry[attr] = input && event.target ? event.target.value : event;
      }

    }), this.notifyChanges);
  };

  handleGroupCreate = () => {
    const {type} = this.props;

    const emptyGroup = Goods.emptyGroup();

    if (type === "multiple")
      emptyGroup.frequency = null;

    this.setState(
      produce(draft => {
        draft.data.push(emptyGroup)
      }), () => {
        this.scrollToBottom();
        this.notifyChanges();
      }
    )
  };

  handleGroupRemove = (removalIndex) => () => {
    this.setState((state) => {
      return produce(state, draft => {
        draft.data.splice(removalIndex, 1);
      })
    }, this.notifyChanges)
  };

  render() {
    const {data, categories} = this.state;

    const {type,OrderErrors} = this.props;

    if (!categories)
      return <Skeleton active/>;

    const groupsLengthCheck = data.length === 0;

    return (
      <Tabs
        type="card"
        hideAdd
        activeKey={"group"}
        className={groupsLengthCheck ? "gx-groups-empty" : "gx-groups-tabs"}
        tabBarExtraContent={
          <HeaderContent
            selected={data}
            handleCreate={this.handleGroupCreate}/>}>
        <TabPane tab={Translation["singleBoarding.goods.title"]} key="group">
          {groupsLengthCheck ?
            <Empty
              className="gx-groups-empty-Wrapper"
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={Translation.empty}/> :
            <Row type="flex" gutter={8}>
              {data.map((entry, index) => {
                return (
                  <Col xs={24} md={12}>
                    <GroupCard
                      type={type}
                      data={entry}
                      index={index}
                      categories={categories}
                      handleChange={this.onChange}
                      handleRemove={this.handleGroupRemove}
                      OrderErrors={OrderErrors}
                    />
                  </Col>
                )
              })}
            </Row>
          }
        </TabPane>
      </Tabs>

    );
  }
}

export default Goods;

Goods.propTypes = {
  type: PropTypes.oneOf(["single", "multiple"])
};