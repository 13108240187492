import React from "react";
import {Redirect, Route, Switch} from "react-router-dom";
import TimeLine from "./timeLine";

const ExtraComponents = ({match}) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/editors`}/>
        <Route path={`${match.url}/time-line`} component={TimeLine}/>
    </Switch>
);

export default ExtraComponents;
