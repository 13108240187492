import React from "react";
//Components
import { Button, Tooltip } from "antd";
//Translation
import Translation from "./translation";
//Custom Css
import "./Styles.less";

const OrderActions = (props) => {
  const { fetched, modified, onReset, onSave } = props;
  if (fetched) {
    return (
      <section className="orderActions">
        {modified ? (
          <Tooltip title={Translation.resetChanges} placement="bottom">
            <Button
              onClick={onReset}
              className="gx-btn-outline-danger"
              htmlType="button"
            >
              {Translation.resetChanges}
            </Button>
          </Tooltip>
        ) : (
          <Button
            disabled={true}
            htmlType="button"
            className="orderActions-mr15"
          >
            {Translation.resetChanges}
          </Button>
        )}
        {modified ? (
          <Tooltip title={Translation.saveChanges} placement="bottom">
            <Button
              onClick={onSave}
              className=" gx-btn-outline-success"
              htmlType="button"
            >
              {Translation.saveChanges}
            </Button>
          </Tooltip>
        ) : (
          <Button disabled htmlType="button">
            {Translation.saveChanges}
          </Button>
        )}
      </section>
    );
  } else {
    return null;
  }
};

export default OrderActions;
