//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation();

const keys = [
  {
    "key": "groupLifeInsuranceLabel",
    "type": "label"
  },
  {
    "key": "liabilityInsuranceLabel",
    "type": "label"
  },  
  {
    "key": "engineeringRiskInsuranceLabel",
    "type": "label"
  },
];

export default prepareTranslations(keys, translation);