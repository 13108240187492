import React from "react";

import "./styles.less";
import { Tabs } from "antd";

const MuTabs = ({ children, product, className, isSmall, ...props }) => {
  const getClassNameByProduct = () => {
    switch (product) {
      case "CARGO_OPEN_POLICY":
        return "mu-tabs-cargo-open-policy";
      case "CARGO_SINGLE_BOARDING":
        return "mu-tabs-cargo-single-boarding";
      case "SURETY":
        return "mu-tabs-surety";
      case "LIFE":
        return "mu-tabs-life";
      default:
        return "";
    }
  };
  return (
    <Tabs
      hideAdd
      className={`mu-tabs ${className || ""}}`}
      type="card"
      {...(isSmall
        ? {
            tabPosition: "top",
            type: "editable-card",
            className: "mu-tabs-sm",
          }
        : {})}
      renderTabBar={(tabBarProps, DefaultTabBar) => (
        <DefaultTabBar {...tabBarProps}>
          {(node) => (
            <div
              {...node.props}
              key={node.key}
              className={`${
                node.props.className
              } mu-tabs-tab ${getClassNameByProduct()}`}
            />
          )}
        </DefaultTabBar>
      )}
      {...props}
    >
      {children}
    </Tabs>
  );
};

export default MuTabs;
