//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "documentNumberLabel",
    "type": "label"
  },
  {
    "key": "fullAddressLabel",
    "type": "label"
  },
  {
    "key": "fullAddressPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "addressNumberLabel",
    "type": "label"
  },
  {
    "key": "addressNumberPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "addressAddDataLabel",
    "type": "label"
  },
  {
    "key": "addressAddDataPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "neighborhoodLabel",
    "type": "label"
  },
  {
    "key": "neighborhoodPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "cityLabel",
    "type": "label"
  },
  {
    "key": "cityPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "stateLabel",
    "type": "label"
  },
  {
    "key": "statePlaceholder",
    "type": "label"
  },
  {
    "key": "companyType",
    "type": "label"
  },
  {
    "key": "personType",
    "type": "label"
  },
  {
    "key": "personTypeLabel",
    "type": "label"
  },
  {
    "key": "personLabel",
    "type": "label"
  },
  {
    "key": "personPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "companyLabel",
    "type": "label"
  },
  {
    "key": "companyPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "zipCodeLabel",
    "type": "label"
  },
  {
    "key": "searchCompany",
    "type": "label"
  },
  {
    "key": "insuredPersonTypeLabel",
    "type": "label"
  },
  {
    "key": "insuredCompanyDocumentLabel",
    "type": "label"
  },
  {
    "key": "insuredCompanyDocumentPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "insuredIndividualDocumentLabel",
    "type": "label"
  },
  {
    "key": "insuredIndividualDocumentPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "insuredNameLabel",
    "type": "label"
  },
  {
    "key": "insuredNamePlaceholder",
    "type": "placeholder"
  },
  {
    "key": "judicialPersonTypeLabel",
    "type": "label"
  },
  {
    "key": "judicialCompanyDocumentLabel",
    "type": "label"
  },
  {
    "key": "judicialCompanyDocumentPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "judicialIndividualDocumentLabel",
    "type": "label"
  },
  {
    "key": "judicialIndividualDocumentPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "judicialNameLabel",
    "type": "label"
  },
  {
    "key": "judicialNamePlaceholder",
    "type": "placeholder"
  },
];
export default prepareTranslations(keys, translation);