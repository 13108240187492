//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("resume");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    principalNameLabel: getTranslation("principalNameLabel"),
    documentNumberLabel: getTranslation("documentNumberLabel"),
    principalContactEmailLabel: getTranslation("principalContactEmailLabel"),
    principalContactNameLabel: getTranslation("principalContactNameLabel"),
    principalContactPhoneLabel: getTranslation("principalContactPhoneLabel"),
    salesRepresentativeLabel: getTranslation("salesRepresentativeLabel"),
    expressError: getTranslation("expressError"),
    amountLabel: getTranslation("amountLabel"),
    initialDateLabel: getTranslation("initialDateLabel"),
    finalDateLabel: getTranslation("finalDateLabel"),
    daysBetweenLabel: getTranslation("daysBetweenLabel"),
    bondNameLabel: getTranslation("bondNameLabel"),
    noData: getTranslation("noData", true),
    ok: getTranslation('ok', true),
    cancel: getTranslation('cancel', true)
}