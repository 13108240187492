import React from "react";
import { formatDateTime } from "../../../../helpers";
//Translation
import Translation from "./translation";
//Components
import { Tag, Row, Col, Menu, Dropdown } from "antd";
import { Redirect, FormattedValue } from "components";
//Configs
import Config from "../../Config";
//Styles
import "./Styles.less";
//Constants
const { typeEntriesColors, orderTypeTranslation, bondTypes } = Config;
const typesAvailable = ["GENERAL"];

//Helpers
function renderMenu(callback) {
  return (
    <Menu onClick={callback}>
      <Menu.Item>{Translation.detailsMenuItemLabel}</Menu.Item>
      <Menu.Item>{Translation.archivedLabel}</Menu.Item>
      <Menu.Item>{Translation.deleteMenuItemLabel}</Menu.Item>
    </Menu>
  );
}

const OrderItem = (props) => {
  const { order } = props;
  const orderTypeColor = typeEntriesColors[order.type];
  const {
    id,
    companyName,
    documentNumber,
    contactName,
    email,
    phoneNumber,
    createdAt,
    updatedAt,
    type,
    bondType,
    code,
  } = order;

  let bondTypeIdentifier;

  function getOrderType() {
    if (typesAvailable.includes(type) && bondType) {
      const types = bondTypes[type];
      if (types.hasOwnProperty(bondType)) {
        return types[bondType];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  const verifyDocumentType = (docNumber) => {
    if (docNumber && docNumber.length < 14) {
      return "INDIVIDUAL";
    } else {
      return "COMPANY";
    }
  };

  bondTypeIdentifier = getOrderType();

  return (
    <div className="OrderItem">
      <Row type="flex" justify="space-between" align="middle">
        <div
          className="OrderItem-tag"
          style={{ backgroundColor: `${orderTypeColor}` }}
        >
          <p>{type && orderTypeTranslation[type]}</p>
        </div>
      </Row>

      <Redirect
        to={{ pathname: `orders/${id}`, order: `${companyName}` }}
        key={id}
      >
        <div className="gx-module-list-item OrderItem-content gx-px-0">
          <div className="gx-module-list-content">
            <Row type="flex">
              <Col xs={24} lg={12}>
                {companyName ? (
                  <p className="gx-subject">
                    {Translation.companyNameLabel}:
                    <span className="value-text">&nbsp;{companyName}</span>
                  </p>
                ) : null}

                {contactName ? (
                  <p className="gx-subject">
                    {Translation.contactNameLabel}:
                    <span className="value-text">&nbsp;{contactName}</span>
                  </p>
                ) : null}

                {email ? (
                  <p className="gx-subject">
                    {Translation.emailLabel}:
                    <span className="value-text">&nbsp;{email}</span>
                  </p>
                ) : null}

                {createdAt ? (
                  <p className="gx-subject">
                    {Translation.createdAtLabel}:
                    <span className="value-text">
                      &nbsp;{formatDateTime(createdAt)}
                    </span>
                  </p>
                ) : null}
              </Col>
              <Col xs={24} lg={12}>
                {documentNumber ? (
                  <p className="gx-subject">
                    {Translation.documentNumberLabel}:&nbsp;
                    <span className="value-text">
                      <FormattedValue
                        mask="_"
                        displayType="text"
                        value={documentNumber}
                        format={verifyDocumentType(documentNumber)}
                      />
                    </span>
                  </p>
                ) : null}

                {phoneNumber ? (
                  <p className="gx-subject">
                    {Translation.contactPhoneLabel}:&nbsp;
                    <span className="value-text">
                      <FormattedValue
                        mask="_"
                        displayType="text"
                        value={phoneNumber}
                        format={"contactPhone"}
                      />
                    </span>
                  </p>
                ) : null}

                {updatedAt ? (
                  <p className="gx-subject">
                    {Translation.updatedAtLabel}:&nbsp;
                    <span className="value-text">
                      {formatDateTime(updatedAt)}
                    </span>
                  </p>
                ) : null}
              </Col>
            </Row>
          </div>
          <div className="gx-module-contact-right">
            <Dropdown
              overlay={renderMenu()}
              placement="bottomRight"
              trigger={["click"]}
            >
              <i className="gx-icon-btn icon icon-ellipse-v" />
            </Dropdown>
          </div>
        </div>
      </Redirect>
    </div>
  );
};

export default OrderItem;
