//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("orderQuotes");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    firstQuestion: getTranslation("firstQuestion"),
    secondQuestion: getTranslation("secondQuestion"),
    acceptanceDate: getTranslation("acceptanceDate"),
    simulationScenario: getTranslation("simulationScenario"),
    quotationScenario: getTranslation("quotationScenario"),
    designationLetterNeededStatus: getTranslation("designationLetterNeededStatus"),
    documentationNeededStatus: getTranslation("documentationNeededStatus"),
    errorStatus: getTranslation("errorStatus"),
    refusedStatus: getTranslation("refusedStatus"),
    ccgPendingStatus: getTranslation("ccgPendingStatus"),
    processingStatus: getTranslation("processingStatus"),
    quoteRequestError: getTranslation("quoteRequestError"),
    insurerRegisterNeeded: getTranslation("insurerRegisterNeeded"),
    noAcceptedProposals: getTranslation("noAcceptedProposals"),
    caption: getTranslation("caption"),
    pendingStatus: getTranslation("pendingStatus")
}