import React from "react";
import "./style.less";

const LoadQuotations = ({ active }) => {
  return (
    <>
      {active ? (
        <div className="loading-quotation-item">
          <div className="tax gradient-animation"></div>
          <div className="number gradient-animation"></div>
        </div>
      ) : null}
    </>
  );
};

export default LoadQuotations;
