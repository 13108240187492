import React from "react";
import "./styles.less";
const LabelField = ({ children, label, vertical = false, className }) => {
  return (
    <div
      className={`labelWrapper ${
        vertical ? "vertical-labelWrapper" : ""
      } ${className}`}
    >
      <div className="labelItem">
        <span>{label}</span>
      </div>
      <div className="fieldWrapper">{children}</div>
    </div>
  );
};

export default LabelField;
