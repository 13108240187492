import React from 'react'
import './styles.less'
import { coverageItems, modalLabels, coverageStatus } from '../../../../../constants'
import { formatMoney, formatPercentage, cloneObject } from 'helpers'
const CoveragesTable = ({ accident }) => {
    let dataCopy = cloneObject(accident)
    const sortCoverages = (coverages) =>{
        return coverages.sort(function (a, b) {
            return (coverageItems[a.type] > coverageItems[b.nome]) ? 1 : ((coverageItems[b.type] > coverageItems[a.type]) ? -1 : 0);
        })
    }
    return (
        <>
            <div className="coverages-wrapper">
                <span className="table-title">Condições das coberturas adicionais</span>
                <table className="table">
                    <thead>
                        <tr>
                            <th className="coverage">Cobertura</th>
                            <th className="rate">Taxa</th>
                            <th className="maxCoverage">Cobertura máxima</th>
                        </tr>
                    </thead>
                    <tbody>
                        {accident.nationalRoad && sortCoverages(dataCopy.nationalRoad.coverages).map((coverage, index) => {
                            return (
                                <tr key={index}>
                                    <td className="coverage bold">{coverageItems[coverage.type]}</td>
                                    <td className="rate bold">{formatPercentage(coverage.rate)}</td>
                                    <td className="maxCoverage">
                                        {
                                            coverage.status === "SELECTED" ?
                                                <span className="bold">
                                                    {formatMoney(coverage.coverage)}
                                                </span >
                                                :
                                                <span className="italic">
                                                    {coverageStatus[coverage.status]}
                                                </span>
                                        }
                                    </td>
                                </tr>
                            )
                        })
                        }
                        {Object.keys(accident).map((key, index) => {
                            if (accident[key].hasOwnProperty('type')) {
                                if (key !== 'nationalRoad' && key !== 'internationalRoad' ) {
                                    return (
                                        <tr key={index}>
                                            <td className="coverage bold">{modalLabels[key.toUpperCase()]}</td>
                                            <td className="rate bold"></td>
                                            <td className="maxCoverage">
                                                <span className="italic">
                                                    Sob consulta
                                                    </span>
                                            </td>
                                        </tr>
                                    )
                                }
                            }
                        }
                        )}
                    </tbody>
                </table>
            </div>
            <span className="monthly-minimum">* Valor mínimo mensal</span>
        </>
    )
}

export default CoveragesTable
