import React from 'react';
//Translation
import Translation from "./translation";
//Components
import { Row, Col } from "antd";
//Custom Components
import { GroupButtons } from "components";
//Constants
import selections from "constants/selections";
import InsurableGroups from './InsurableGroups';

const Details = (props) => {
  const { handleChange, configurations, notifyChanges, parentRef, OrderErrors } = props;
  const { capitalType } = configurations;

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
  };

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;

    if (!isDirect && typeof event === "object") {
      if (event instanceof Array)
        value = event;
      else
        value = event.target.value;
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  return (
    <section>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>{Translation.capitalTypeLabel}:</Col>
        <Col xs={24} md={18}>
          <GroupButtons
            value={capitalType}
            onChange={onChange("capitalType")}
            entries={selections.capitalTypeEntries} />
        </Col>
      </Row>

      <InsurableGroups
        ref={parentRef}
        notifyChanges={notifyChanges}
        handleChange={handleChange}
        data={getValue("insurableGroups")}
        OrderErrors={OrderErrors}/>

    </section>
  );
};

export default Details;