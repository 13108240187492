import React from "react";
//Custom Styles
import './Styles.less';
//Custom Components
import {Button, Row, Col, Icon, Modal, Tooltip} from 'antd';
//Translation config
import Translation from './translation';
//Constants
const modalBodyStyle = {padding: 0};

function DeleteUser(props) {
    const {closeDeleteMode, open, user, users} = props;

    function onModalClose() {
        return closeDeleteMode();
    }

    return (
        <Modal
            title={user ? Translation.singularTitle : Translation.pluralTitle}
            visible={open}
            closable={false}
            centered={true}
            footer={null}
            bodyStyle={modalBodyStyle}
            onCancel={onModalClose}>
            <main className="deleteUser">
                <Row type="flex" align="middle" gutter={16}>
                    <Col>
                        <Icon type="user-delete" className="deleteUser-icon"/>
                    </Col>
                    <Col>
                        {user ? <div className="deleteUser-label">
                            {Translation.singularWarning}
                            <Tooltip title={user.name} placement="bottom">
                                <strong className="deleteUser-name"> {Translation.singularEntity} </strong>
                            </Tooltip>
                            ?
                        </div> : <div className="deleteUser-label">
                            {Translation.pluralWarning}
                            <Tooltip title={users.map(user => user.name).join(',')} placement="bottom">
                                <strong className="deleteUser-name"> {Translation.pluralEntity} </strong>
                            </Tooltip>
                            ?
                        </div>}
                    </Col>
                </Row>
                <p className="deleteUser-info">

                </p>
                <Row type="flex" gutter={8} justify="end" className="deleteUser-action">
                    <Col>
                        <Button onClick={onModalClose}>{Translation.cancel}</Button>
                    </Col>
                    <Col>
                        <Button autoFocus type="danger" onClick={onModalClose}>{Translation.ok}</Button>
                    </Col>
                </Row>
            </main>
        </Modal>
    );
}

export default DeleteUser;

