import React from "react";
// propTypes
import PropTypes from "prop-types";
// react-router
import { useHistory } from "react-router-dom";
// translations
import { useTranslation } from "react-i18next";
// ant components
import { Button, Table } from "antd";
// custom components
import NumberFormat from "react-number-format";
import UserStatusChip from "../UserStatusChip";
// custom styles
import "./styles.less";

const ManageUsersAccessTable = ({ data = [], pagination = {} }) => {
  const { t: translation } = useTranslation();
  const history = useHistory();

  const handleOpenModal = (path) => history.push(path);

  const columns = [
    {
      title: translation("clientAppUser.manageUsersTable.documentNumber.label"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      render: (type, record) => (
        <NumberFormat
          format="##.###.###/####-##"
          displayType="text"
          value={record?.documentNumber}
        />
      ),
    },
    {
      title: translation("clientAppUser.manageUsersTable.email.label"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: translation("clientAppUser.manageUsersTable.name.label"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: translation("clientAppUser.manageUsersTable.status.label"),
      key: "status",
      render: (values) => <UserStatusChip status={values.status} />,
    },
    {
      title: translation("clientAppUser.manageUsersTable.actions.label"),
      key: "actions",
      render: (values) => (
        <Button
          type="primary"
          ghost
          className="gx-mb-0"
          htmlType="button"
          onClick={() =>
            handleOpenModal(
              `/crm/client-app/${values?.documentNumber}/manage-user-access/${values?.email}/${values?.id}`
            )
          }
        >
          {translation("clientAppUser.manageUsersTable.actionButton.label")}
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Table
        rowKey={(record) => record.id}
        className="gx-table-responsive"
        columns={columns}
        dataSource={data}
        pagination={pagination}
      />
    </React.Fragment>
  );
};

ManageUsersAccessTable.propTypes = {
  data: PropTypes.array,
  pagination: PropTypes.object,
};

export default ManageUsersAccessTable;
