// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../../util/axios";
// translation
import i18n from "../../../../translation/i18n";
// antd
import { message } from "antd";
// common
import { setIsLoading } from "../../common";
// constants
import { taxByProduct } from "../../../../constants";

export const lifeProposalSlice = createSlice({
  name: "lifeProposal",
  initialState: {
    proposal: {
      iof: taxByProduct.LIFE,
    },
    errors: [],
    isLoading: false,
  },
  reducers: {
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    setProposal: (state, action) => {
      state.proposal = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const { setErrors, setProposal } = lifeProposalSlice.actions;

export default lifeProposalSlice.reducer;

// -----------------------------------------------------------------

export const fetchLifeProposal = (code) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`life/v2/${code}/proposal`)
      .then((response) => {
        dispatch(
          setProposal({
            ...response.data,
            preSalesRepresentativeId: response.data?.preSalesRepresentative?.id,
            salesRepresentativeId: response.data?.salesRepresentative?.id,
            iof: taxByProduct.LIFE,
          })
        );
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const saveLifeProposal = (code) => (dispatch, getState) => {
  const {
    lifeProposal: { proposal },
  } = getState().proposal;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`life/v2/${code}/proposal`, proposal)
      .then((response) => {
        message.success(i18n.t("life.proposal.saveProposalSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors?.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const acceptLifeProposal = (code) => (dispatch, getState) => {
  const {
    suretyProposal: { proposal },
  } = getState().proposal;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`life/v2/${code}/proposal/accepted`)
      .then((response) => {
        message.success(i18n.t("life.proposal.acceptProposalSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors?.length) {
          dispatch(setErrors(error.response.data.errors));
          message.error(i18n.t("proposal.emptyFieldErrorMessage"));
        }
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};

export const removeLifeProposalFile = (code) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`life/v2/${code}/proposal/upload`)
      .then((response) => {
        message.success(i18n.t("life.proposal.removeFileSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => dispatch(setIsLoading(false)));
  });
};
