import React from 'react';
//Translations
import Translation from "./translation";
//Components
import { Row, Col } from "antd";
import { CurrencyInput, LngInput, NumberInput, Switch } from "components";
//Custom Components
import Losses from "./Losses";

const Historic = (props) => {
  const { parentRef, configurations, notifyChanges, handleChange } = props;

  const onChange = (attr, isDirect = false) => (event) => {
    let value = event;
    if (event !== null && typeof event === "object") {
      if (!isDirect) {
        if (event instanceof Array)
          value = event;
        else
          value = event.target.value;
      }
    }

    return handleChange({
      attr: attr,
      value: value
    });
  };

  const toggleProperty = (attr) => () => {
    handleChange({
      attr: attr,
      value: !getValue(attr)
    });
  };

  const getValue = (entity) => {
    if (configurations.hasOwnProperty(entity))
      return configurations[entity];
  };

  return (
    <section>
      <Losses
        ref={parentRef}
        notifyChanges={notifyChanges}
        data={getValue("accidents")} />
      <section className="gx-mt-5">

        <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
          <Col xs={24} md={6}>
            {Translation.hasPreviousInsuranceLabel}
          </Col>
          <Col xs={24} md={18}>
            <Switch
              checked={getValue("hasPreviousInsurance")}
              onChange={toggleProperty("hasPreviousInsurance")} />
          </Col>
        </Row>

        {getValue("hasPreviousInsurance") &&
          <>
            <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.previousInsurerLabel}
              </Col>
              <Col xs={24} lg={18}>
                <LngInput
                  value={getValue("previousInsurer")}
                  onChange={onChange("previousInsurer")}
                  placeholder={Translation.previousInsurerPlaceholder} />
              </Col>
            </Row>

            <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.previousCoveragesLabel}
              </Col>
              <Col xs={24} lg={18}>
                <LngInput
                  value={getValue("contractedCoverages")}
                  onChange={onChange("contractedCoverages")}
                  placeholder={Translation.previousCoveragesPlaceholder} />
              </Col>
            </Row>

            <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.maxAmountLabel}
              </Col>
              <Col xs={24} lg={18}>
                <CurrencyInput
                  value={getValue("maximumGuaranteeLimit")}
                  onChange={onChange("maximumGuaranteeLimit", true)}
                  placeholder={Translation.maxAmountPlaceholder} />
              </Col>
            </Row>

            <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.minPremiumLabel}
              </Col>
              <Col xs={24} lg={18}>
                <CurrencyInput
                  value={getValue("minimumPremium")}
                  onChange={onChange("minimumPremium", true)}
                  placeholder={Translation.minPremiumPlaceholder} />
              </Col>
            </Row>

            {/* <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.monthlyBillLabel}
              </Col>
              <Col xs={24} lg={18}>
                <CurrencyInput
                  value={getValue("monthlyInvoiceAmount")}
                  onChange={onChange("monthlyInvoiceAmount", true)}
                  placeholder={Translation.monthlyBillPlaceholder} />
              </Col>
            </Row>

            <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.taxRateLabel}
              </Col>
              <Col xs={24} lg={18}>
                <NumberInput
                  fullWidth
                  type="number"
                  value={getValue("rateOrDiscountApplied")}
                  onChange={onChange("rateOrDiscountApplied")}
                  placeholder={Translation.taxRatePlaceholder} />
              </Col>
            </Row> */}

            <Row type="flex" gutter={8} className="gx-mt-3" align="middle">
              <Col xs={24} lg={6}>
                {Translation.additionalDataLabel}
              </Col>
              <Col xs={24} lg={18}>
                <LngInput
                  value={getValue("additionalInformation")}
                  onChange={onChange("additionalInformation")}
                  placeholder={Translation.additionalDataPlaceholder} />
              </Col>
            </Row>
          </>
        }
      </section>
    </section>
  );
};

export default Historic;