import React, { useEffect } from "react";
// router
import { useHistory, useParams } from "react-router-dom";
// prop types
import PropTypes from "prop-types";
// antd
import { Col, Row, Modal, Button, Tabs, Icon } from "antd";
// custom components
import RegisterData from "./RegisterData";
import CurrentPolicies from "components/Customers/CustomerTabs/CurrentPolicies";
import ContactData from "components/Customers/CustomerTabs/ContactData";
import Limits from "./Limits";
// redux
import {
  changeCustomersTab,
  saveCustomerData,
  fetchCustomerDataSuccess,
} from "appRedux/actionCreators";
import { useDispatch, useSelector, Provider } from "react-redux";
// custom styles
import "./index.less";
// new store
import store from "../../../store";
// new translation
import { useTranslation } from "react-i18next";
// contants
import { customerTabs } from "../../../constants";

const { confirm } = Modal;
const { TabPane } = Tabs;

const Customer = ({ children }) => {
  const {
    isChanged,
    customerTabs: { actualTab, registerData },
  } = useSelector((state) => state.crm.customers);

  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const { t: translation } = useTranslation();

  const isNewCustomer = () => {
    return params.id === "new";
  };

  const handleChangeTab = (tab) => {
    if (isChanged) {
      confirm({
        title: "Deseja salvar suas alterações?",
        icon: <Icon type="question-circle" />,
        content: "Confirme para salvar os dados alterados",
        okText: "Sim",
        cancelText: "Não",
        onOk() {
          dispatch(saveCustomerData());
        },
        onCancel() {
          return null;
        },
      });
    } else {
      dispatch(changeCustomersTab(tab));
    }
  };

  const handleSetCustomerData = (data) => {
    dispatch(fetchCustomerDataSuccess(data));
  };

  useEffect(() => {
    return () => {
      dispatch(changeCustomersTab(customerTabs.registerData));
    };
  }, []);

  return (
    <>
      {children}
      <Button
        onClick={() => history.push("/crm/customers")}
        className="back-btn"
      >
        {translation("customer.backToCustomerListButton")}
      </Button>
      <Row type="flex" className="gx-mt-3">
        <Col md={24} className="gx-m-0 gx-p-0 customerTabs">
          <Tabs
            tabPosition={"left"}
            onChange={handleChangeTab}
            activeKey={actualTab}
          >
            <TabPane
              tab={translation("customer.tabs.registerDataLabel")}
              key={customerTabs.registerData}
            >
              <Provider store={store}>
                <RegisterData
                  actualTab={actualTab}
                  setCustomerDataInOldStore={handleSetCustomerData}
                />
              </Provider>
            </TabPane>
            <TabPane
              tab={translation("customer.tabs.contactDataLabel")}
              key={customerTabs.contactData}
              disabled={isNewCustomer()}
            >
              <ContactData />
            </TabPane>
            <TabPane
              tab={translation("customer.tabs.suretyRequestLabel")}
              key={customerTabs.suretyRequests}
              disabled={isNewCustomer()}
            >
              Pedidos de seguro
            </TabPane>
            <TabPane
              tab={translation("customer.tabs.policyLabel")}
              key={customerTabs.currentPolicy}
              disabled={isNewCustomer()}
            >
              <Provider store={store}>
                <CurrentPolicies
                  actualTab={actualTab}
                  registerData={registerData}
                />
              </Provider>
            </TabPane>
            <TabPane
              tab={translation("customer.tabs.financialLabel")}
              key={customerTabs.financial}
              disabled={isNewCustomer()}
            >
              Financeiro
            </TabPane>
            <TabPane
              tab={translation("customer.tabs.claimsLabel")}
              key={customerTabs.claims}
              disabled={isNewCustomer()}
            >
              Sinistros
            </TabPane>
            <TabPane
              tab={translation("customer.tabs.limitsLabel")}
              key={customerTabs.limits}
              disabled={isNewCustomer()}
            >
              <Provider store={store}>
                <Limits
                  documentNumber={registerData.documentNumber}
                  actualTab={actualTab}
                />
              </Provider>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </>
  );
};

Customer.propTypes = {
  children: PropTypes.node,
};

export default Customer;
