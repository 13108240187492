import React from 'react';
//Proptypes
import PropTypes from 'prop-types';
//Components
import {Input} from 'antd';
//Others
import {injectIntl, intlShape} from 'react-intl';
//Constants
const {Search} = Input;
const LngInput = (props) => {
    const {intl, placeholder, inputType, ...others} = props;
    let currentPlaceholder = placeholder;
    if (typeof currentPlaceholder === "object")
        currentPlaceholder = intl.formatMessage(placeholder);
    switch (inputType) {
        case "html":
            return <input {...others} placeholder={currentPlaceholder}/>;
        case "antd":
            return <Input {...others} placeholder={currentPlaceholder}/>;
        case "search":
            return <Search {...others} placeholder={currentPlaceholder}/>;
        default:
            return null;
    }
};
LngInput.defaultProps = {
    inputType: "antd"
};
LngInput.propTypes = {
    intl: intlShape.isRequired,
    inputType: PropTypes.oneOf(["html", "antd", "search"]),
    placeholder: PropTypes.object
};
export default injectIntl(LngInput);