import React from "react";
// prop types
import PropTypes from "prop-types";
// translations
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Divider, Switch, Input } from "antd";
// helpers
import { formatMoney, formatPercentage } from "../../../../../../helpers";

const AdditionalCoverageList = ({
  allCoverages = [],
  onChangeOtherField,
  onOptionChange,
}) => {
  const { t: translation } = useTranslation();

  const renderPremiumValue = (premium, tax) => {
    if (premium) return `+ ${formatMoney(premium)}`;
    if (tax)
      return `+ ${formatPercentage(tax)} ${translation(
        "cargoOpenPolicy.additionalCoverage.withTaxLabel"
      )}`;
    return translation("cargoOpenPolicy.additionalCoverage.valueOnRequest");
  };

  const handleChangeOtherField = (e) =>
    onChangeOtherField && onChangeOtherField(e);
  const handleChangeOption = (item, e) =>
    onOptionChange && onOptionChange(item, e);
  return (
    <>
      {allCoverages.map((item, index) => (
        <>
          <Row gutter={[16, 16]} type="flex" align="middle">
            <Col md={6}>{item.label}</Col>
            <Col md={12}>
              {item.type === "other" ? (
                <Input
                  placeholder="Informe outro tipo de cobertura"
                  disabled={!item.selected}
                  onChange={handleChangeOtherField}
                />
              ) : (
                item.description
              )}
            </Col>
            <Col md={6} className="gx-d-flex">
              {item.type === "other" ? (
                <div className="gx-flex-1"></div>
              ) : (
                <p className="gx-w-100">
                  {renderPremiumValue(item.premium, item.tax)}
                </p>
              )}
              <Switch
                checked={item.selected}
                disabled={item.type === "accident"}
                onChange={(event) => handleChangeOption(item, event)}
              />
            </Col>
          </Row>
          <Divider />
        </>
      ))}
    </>
  );
};

AdditionalCoverageList.propTypes = {
  allCoverages: PropTypes.array,
};

export default AdditionalCoverageList;
