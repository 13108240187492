import axios from "axios";

export const interceptor = (maxRetry, delay) => {
  let userId = localStorage.getItem("user_id");
  userId = userId ? JSON.parse(userId).id : "";
  
  axios.interceptors.request.use(async (config) => {
    config.headers.common['mutuus-user'] = userId;
    return config;
  }, (error) => {
    return Promise.reject(error);
  });
};
