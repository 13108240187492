import React from "react";
//redux
import { useDispatch, useSelector } from "react-redux";
// translation
import { useTranslation } from "react-i18next";
//router
import { useHistory, useParams } from "react-router-dom";
//actions
import {
  changeInvoiceData,
  setInvoiceModalVisible,
} from "appRedux/actionCreators";
//components
import { Button, Row, Col, Table } from "antd";
//helpers
import {
  getFormattedDate,
  frontEndMonth,
  convertToIsoFormat,
  formatMoney,
} from "helpers";
//translation
import translation from "./translation";
//custom styles
import "./styles.less";
//constants
import {
  policyTableColumns,
  paymentStatus,
  filesStatus,
} from "../../../../constants";

export default (props) => {
  const {
    policy: { invoices },
    getValue,
  } = props;

  const { t: Translation } = useTranslation();

  const dispatch = useDispatch();

  const history = useHistory();
  const params = useParams();

  const handleOpenInvoice = (record) => {
    history.push(`${history.location.pathname}/invoice/${record.id}`);
    dispatch(setInvoiceModalVisible(true));
  };

  const getPaymentStatus = (data) => {
    return paymentStatus.hasOwnProperty(data) ? paymentStatus[data] : null;
  };

  const getFileStatus = (hasInvoice, hasPaymentSlip) => {
    if (hasInvoice && hasPaymentSlip) {
      return filesStatus.hasBoth;
    } else if (hasInvoice && !hasPaymentSlip) {
      return filesStatus.onlyInvoice;
    } else if (!hasInvoice && hasPaymentSlip) {
      return filesStatus.onlyPaymentSlip;
    } else {
      return filesStatus.noFiles;
    }
  };

  const renderCell = ({ label, style }) => {
    return {
      props: {
        className: `financialTableCell ${style}`,
      },
      children: <span>{label}</span>,
    };
  };

  const columns = [
    {
      title: translation.policyKeyTableHeaderLabel,
      dataIndex: policyTableColumns.key,
      align: "center",
      render: (key, record) =>
        `${key} ${
          record.endorsement
            ? `(${Translation("invoice.invoiceList.endorsementLabel")})`
            : ""
        }`,
    },
    {
      title: translation.policyCompetenceTableHeaderLabel,
      dataIndex: policyTableColumns.recurrenceMonth,
      align: "center",
    },
    {
      title: translation.policyValidityTableHeaderLabel,
      dataIndex: policyTableColumns.dueDate,
      align: "center",
    },
    {
      title: translation.policyPremiumTableHeaderLabel,
      dataIndex: policyTableColumns.premium,
      align: "center",
    },
    {
      title: translation.policyPaymentTableHeaderLabel,
      dataIndex: policyTableColumns.payment,
      align: "center",
      render: (data) => renderCell(data),
    },
    {
      title: translation.policyFileTableHeaderLabel,
      dataIndex: policyTableColumns.file,
      align: "center",
      render: (data) => renderCell(data),
    },
    // {
    //   title: translation.policyInvoiceTableHeaderLabel,
    //   dataIndex: policyTableColumns.invoice,
    //   align: "center",
    // },
    // {
    //   title: translation.policyChargeTableHeaderLabel,
    //   dataIndex: policyTableColumns.charge,
    //   align: "center",
    // },
  ];

  const getSource = () => {
    const items = [];

    if (!(invoices && invoices.length)) return items;

    const source = [...invoices];
    source.sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate));

    if (source) {
      for (let i = 0; i < source.length; i++) {
        items.push({
          key: i + 1,
          ...source[i],
          recurrenceMonth: getFormattedDate(
            source[i].recurrenceMonth,
            frontEndMonth
          ),
          dueDate: getFormattedDate(source[i].dueDate),
          premium:
            source[i].premium < 0
              ? `-${formatMoney(source[i].premium)}`
              : formatMoney(source[i].premium),
          payment: getPaymentStatus(source[i].status),
          file: getFileStatus(source[i].hasInvoice, source[i].hasPaymentSlip),
        });
      }
    }
    return items;
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      //...
    },
  };

  return (
    <section>
      <Table
        columns={columns}
        dataSource={getSource()}
        pagination={{ defaultPageSize: 8 }}
        onRow={(record) => {
          return {
            className: "clickable-row",
            onClick: () => handleOpenInvoice(record),
          };
        }}
      />
      {/* TODO: estes botões serão implementados eventualmente */}
      {/* <Row
        type="flex"
        align="middle"
        gutter={8}
        className="gx-mt-2 gx-mr-0 gx-justify-content-end"
      >
        <Button>{translation.policyEndChargeButtonLabel}</Button>
        <Button>{translation.policySetDelayedButtonLabel}</Button>
        <Button>{translation.policyResendInvoiceButtonLabel}</Button>
      </Row> */}
    </section>
  );
};
