import React from 'react';
//Styles
import "./Styles.less";
//Components
import {Col, Empty, Row,} from "antd";
//Translation
import Translation from "./translation";
//Components
import {Switch} from "components";


const Simulations = (props) => {
  let {data} = props;
  const {accident, theft, damage} = data;
  let finalData = null;

  if (accident && !theft && !damage)
    finalData = data.accidentScenario;
  else if (accident && theft && !damage)
    finalData = data.theftScenario;
  else if (accident && !theft && damage)
    finalData = data.theftPlusDamageScenario;

  if (!finalData)
    return <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={"No Data"}/>;

  return (
    <section className="fadein">
      <div className="price-quotation">
        <div className="price-quotation-item">
          <div className="logo optmist">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="173px"
              height="173px"
              viewBox="0 0 173 173"
              style={{enableBackground: "new 0 0 173 173"}}
            >
              <path
                d="M86.5,0C38.7,0,0,38.7,0,86.5C0,134.2,38.7,173,86.5,173c47.8,0,86.5-38.7,86.5-86.5C173,38.7,134.2,0,86.5,0z M113.5,38.7 c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7C102.8,43.4,107.6,38.7,113.5,38.7z M59.5,38.7 c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7C48.8,43.4,53.6,38.7,59.5,38.7z M86.5,150 c-32.6,0-59.5-24.6-63.1-56.2c-0.4-3.9,2.6-7.3,6.5-7.3h113.2c3.9,0,6.9,3.4,6.5,7.3C145.9,125.4,119.1,150,86.5,150z"/>
            </svg>
            {Translation.bestScenarioLabel}
          </div>
          <div className="price">{finalData.best}</div>
          <div className="text">{Translation.valueInCurrencyLabel}</div>
        </div>
        <div className="price-quotation-item">
          <div className="logo neutral">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="173px"
              height="173px"
              viewBox="0 0 173 173"
              style={{enableBackground: "new 0 0 173 173"}}
            >
              <path
                d="M86.5,0C38.7,0,0,38.7,0,86.5C0,134.2,38.7,173,86.5,173S173,134.2,173,86.5C173,38.7,134.2,0,86.5,0z M113.5,51.2
                            c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7C102.8,56,107.6,51.2,113.5,51.2z M59.5,51.2
                            c5.9,0,10.7,4.8,10.7,10.7c0,5.9-4.8,10.7-10.7,10.7c-5.9,0-10.7-4.8-10.7-10.7C48.8,56,53.6,51.2,59.5,51.2z M86.5,131.3
                            c-23.5,0-43.1-10.9-53.6-21.2c-3.1-3-3.1-7.9-0.1-11c3-3.1,7.9-3.1,11-0.1c7.9,7.7,23.9,8.8,42.7,8.8c18.8,0,34.8-9,42.7-8.8
                            c3.1-3,8-2.9,11,0.1c3,3.1,2.9,8-0.1,11C129.6,120.4,110,131.3,86.5,131.3z"
              />
            </svg>
            {Translation.neutralScenarioLabel}
          </div>
          <div className="price">{finalData.neutral}</div>
          <div className="text">{Translation.valueInCurrencyLabel}</div>
        </div>
        <div className="price-quotation-item">
          <div className="logo pessimist">
            <svg
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="173px"
              height="173px"
              viewBox="0 0 173 173"
              style={{enableBackground: "new 0 0 173 173"}}
            >
              <path
                d="M86.5,0C38.7,0,0,38.7,0,86.5C0,134.2,38.7,173,86.5,173S173,134.2,173,86.5C173,38.7,134.2,0,86.5,0z M113.5,49.3
                            c5.9,0,10.7,4.8,10.7,10.7s-4.8,10.7-10.7,10.7s-10.7-4.8-10.7-10.7S107.6,49.3,113.5,49.3z M59.5,49.3c5.9,0,10.7,4.8,10.7,10.7
                            s-4.8,10.7-10.7,10.7S48.8,65.9,48.8,60S53.6,49.3,59.5,49.3z M134.1,119.2H40.8c-5,0-9-4-9-9s4-9,9-9h93.3c5,0,9,4,9,9
                            S139.1,119.2,134.1,119.2z"
              />
            </svg>
            {Translation.worstScenarioLabel}
          </div>
          <div className="price">{finalData.worst}</div>
          <div className="text">{Translation.valueInCurrencyLabel}</div>
        </div>
      </div>
      <h5>{Translation["simulation.description"]}</h5>
      <Row type="flex" className="gx-flex-column">
        <Col className="gx-mt-2">
          <Row type="flex" gutter={8}>
            <Col>
              <Switch checked={accident}/>
            </Col>
            <Col>
              {Translation.accidentLabel}
            </Col>
          </Row>
        </Col>
        <Col className="gx-mt-2">
          <Row type="flex" gutter={8}>
            <Col>
              <Switch checked={theft}/>
            </Col>
            <Col>
              {Translation.theftLabel}
            </Col>
          </Row>
        </Col>
        <Col className="gx-mt-2">
          <Row type="flex" gutter={8}>
            <Col>
              <Switch checked={damage}/>
            </Col>
            <Col>
              {Translation.theftAndDamageLabel}
            </Col>
          </Row>
        </Col>
      </Row>
    </section>
  );
};

export default Simulations;