import React,{useState,useEffect} from 'react'
import {Select, Col} from 'antd'
import Request from "helpers/Request";
import FormField from "components/FormField"
import Translation from "./translation";
import './Styles.less'
const {Option} = Select
export const StateSelect = (props) =>{
    const {cityValue, stateValue, errors, onBlur, onChangeCity,onChangeState} = props;
    const [cityOptions, setCityOptions] = useState([])

    const requestCityOptions = (state) =>{
            setCityOptions([])
            if(state){
                Request.get(`/util/v1/city?state=${state}`)
                    .then((res)=>{
                        Promise.resolve(res.json())
                            .then((response)=>{
                                setCityOptions(response.cities)
                            })
                    })
                    .catch((err)=>{
                        console.error('ocorreu um erro', err)
                    })
            }
    }
    
    useEffect(()=>{
        if(stateValue){
            requestCityOptions(stateValue)
        }
    },[])

    return(
        <>
            <Col xs={24} md={6}>{Translation.stateLabel}:</Col>
            <Col sm={24} md={18} className="gx-mb-3">
                <FormField
                        error={errors["obligee.state"] || errors["insured.state"] || errors["customer.state"]}
                        >
                    <Select value={stateValue} 
                        onBlur={onBlur} 
                        style={stateValue === "" ? {color:'rgb(155 161 158)'} : null } 
                        className="fullWidth"
                        onChange={(state)=>{
                            onChangeState(state)
                            requestCityOptions(state)
                            onChangeCity(null)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }>
                            <Option value={null}>Selecione o Estado</Option>
                            <Option value="AC">Acre</Option>
                            <Option value="AL">Alagoas</Option>
                            <Option value="AP">Amapá</Option>
                            <Option value="AM">Amazonas</Option>
                            <Option value="BA">Bahia</Option>
                            <Option value="CE">Ceará</Option>
                            <Option value="DF">Distrito Federal</Option>
                            <Option value="ES">Espírito Santo</Option>
                            <Option value="GO">Goiás</Option>
                            <Option value="MA">Maranhão</Option>
                            <Option value="MT">Mato Grosso</Option>
                            <Option value="MS">Mato Grosso do Sul</Option>
                            <Option value="MG">Minas Gerais</Option>
                            <Option value="PA">Pará</Option>
                            <Option value="PB">Paraíba</Option>
                            <Option value="PR">Paraná</Option>
                            <Option value="PE">Pernambuco</Option>
                            <Option value="PI">Piauí</Option>
                            <Option value="RJ">Rio de Janeiro</Option>
                            <Option value="RN">Rio Grande do Norte</Option>
                            <Option value="RS">Rio Grande do Sul</Option>
                            <Option value="RO">Rondônia</Option>
                            <Option value="RR">Roraima</Option>
                            <Option value="SC">Santa Catarina</Option>
                            <Option value="SP">São Paulo</Option>
                            <Option value="SE">Sergipe</Option>
                            <Option value="TO">Tocantins</Option>
                    </Select>
                </FormField>
            </Col>
            <Col xs={24} md={6}>{Translation.cityLabel}:</Col>
            <Col sm={24} md={18}>
                <FormField
                        error={errors["obligee.city"] || errors["insured.city"] || errors["customer.city"] }
                        >
                    <Select 
                        value={cityValue} 
                        disabled={cityOptions.length === 0} 
                        style={cityValue === null ? {color:'rgb(155 161 158)'} : null } 
                        className="fullWidth" 
                        onChange={onChangeCity}
                        showSearch
                        onBlur={onBlur}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                    >
                        <Option value={null}>Selecione a cidade</Option>
                        {
                            cityOptions.length > 0 ?  cityOptions.map((option,index)=>{
                                return <Option key={index} value={option.name}>{option.name}</Option>
                            }) : false
                        }
                    </Select>
                </FormField>
            </Col>
        </>
    )
}

export default StateSelect