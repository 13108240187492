export default {
    initial: {
        name: null,
        email: null,
        phoneNumber: null,
        phoneType: null,
        receiveWhatsAppMessage: false,
        contactType: null,
        documentNumber: null,
        hasDocumentNumber: null
    },
    bond: {
        type: null,
        jsonTerm: null
    },
    term: {
        initialTermAt: null,
        finalTermAt: null,
        daysBetweenTerm: null,
        processAmount: null,
        suretyAmount: null,
        depositAppeal: false,
        contractType: null,
        contractAmount: null,
        labourCoverage: null,
        penaltyCoverage: null,
        suretyPercentage: null,
        appealType: null,
        liabilityInsurance : null,    
        engineeringRiskInsurance: null,
        groupLifeInsurance: null
    },
    obligee: {
        documentNumber: null,
        type: "COMPANY",
        name: null,
        zipCode: null,
        address: null,
        number: null,
        neighborhood: null,
        state: null,
        city: null,
        additionalData: null
    },
    detail: {
        additionalData: null,
        contractDate: null,
        contractNumber: null,
        installment: null,
        obligation: null,
        bidNumber: null,
        bidDate: null,
        processNumber: null,
        tribunal: null,
        court: null,
        actionType: null,
        proposalAccepted: false,
        receiveDraftContract: false
    },
    payments: {
        payment: null,
    },
    files: [],
    simulations: [],
    hasExpressEmission: null,
    chosenInsurer: null,
    quotations: [],
    policy: null,
    paymentSlips: null
}