import React from "react";
//Other Libs
import CurrencyInput from "../../../CurrencyInput";
import { sortSimulations } from "helpers";
// custom styles
import "./styles.less";

//Constants
const colors = {
  0: "green",
  1: "yellow",
  2: "red",
};
const customStyles = {
  leftRadius: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  rightRadius: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
};

const Section = (props) => {
  const { index, total, color, quote } = props;
  const { rate, amount } = quote;
  let styles = {};
  if (index === 0) styles = customStyles.leftRadius;
  else if (index === total - 1) styles = customStyles.rightRadius;

  return (
    <div
      style={styles}
      className={`simulationCardItem ${index === 0 ? "bestPrice" : ""}`}
    >
      <div className="gx-text-center">
        <CurrencyInput value={amount} displayType="text" />
        <CurrencyInput
          displayType="text"
          suffix={"%"}
          prefix={""}
          value={rate}
          className="gx-mt-3"
        />
      </div>
    </div>
  );
};

const SimulationQuotes = (props) => {
  const { data } = props;
  let updatedData;
  if (data) updatedData = sortSimulations(data);

  if (updatedData) {
    return (
      <div
        className="simulationCardGroup"
        style={{ display: "flex", flexWrap: "wrap", width: "100%" }}
      >
        {updatedData.map((quote, index) => (
          <Section
            key={index}
            index={index}
            total={updatedData.length}
            quote={quote}
            color={colors[index]}
          />
        ))}
      </div>
    );
  }

  return null;
};

export default SimulationQuotes;
