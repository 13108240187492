import React, {useEffect} from "react";
//Components
import {
  MultipleSearch,
  OrderDrawer,
  OrderHeader,
  OrderList
} from "components";
//Redux Bindings
import {connect} from 'react-redux';
import {bindActionCreators} from "redux";
//Selectors
import {
  extractAuthUser,
  isFetchingOrders,
  extractAllOrders,
  extractOrdersFilter,
  areFiltersInitialized,
  extractOrderListCurrentPage
} from 'appRedux/selectors';
//Actions
import {
  fetchOrders,
  initializeOrders,
  updateOrdersFilter
} from "appRedux/actionCreators";

const Orders = (props) => {
    const {fetchOnMount, authUser, children, loadingOrders, actions, allOrders, filters, filtersInitialized, currentPage} = props;

    const {initializeOrders, updateOrdersFilter, fetchOrders} = actions;

    useEffect(() => {
        if (!children) {
          if (!filtersInitialized)
            initializeOrders(authUser);
          else if (fetchOnMount)
            fetchOrders();
        }
      }, [
            children,
            filtersInitialized,
            initializeOrders,
            authUser,
            fetchOnMount,
            fetchOrders
          ]
    );

    return (
      <div className="gx-main-content">
        <div className="gx-app-module">
          <OrderDrawer
            authUser={authUser}
            loadingOrders={loadingOrders}
            disableView={!!children}
            stage={filters.stage}
            types={filters.types}
            updateData={updateOrdersFilter}/>
          <div className="gx-module-box">
            <div className="gx-module-box-header">
              <MultipleSearch
                placeholder="Search orders"
                loadingOrders={loadingOrders}
                disableView={!!children}
                searches={filters.searches}
                updateData={updateOrdersFilter}/>
            </div>
            <div className="gx-module-box-content">
              <OrderHeader
                showDetails={!!children}
                loadingOrders={loadingOrders}
                fetchOrders={fetchOrders}/>
              {children || <OrderList
                orders={allOrders}
                currentPage={currentPage}
                loadingOrders={loadingOrders}
                fetchOrders={fetchOrders}/>}
            </div>
          </div>
        </div>
      </div>
    )
  }
;

function mapStateToProps(state) {
  return {
    authUser: extractAuthUser(state),
    allOrders: extractAllOrders(state),
    loadingOrders: isFetchingOrders(state),
    filters: extractOrdersFilter(state),
    filtersInitialized: areFiltersInitialized(state),
    currentPage: extractOrderListCurrentPage(state)
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchOrders,
        initializeOrders,
        updateOrdersFilter
      }, dispatch)
  };
}

Orders.defaultProps = {
  fetchOnMount: true
};

export default connect(mapStateToProps, mapDispatchToProps)(Orders);