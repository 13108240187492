import React from "react";
//Custom Css
import "./Styles.less";
//Components
import { Radio } from "antd";

const { Button, Group } = Radio;
const GroupButtons = (props) => {
  const { onChange, disabled, entries, value } = props;
  return (
    <section className="GroupButtons">
      <Group disabled={disabled} value={value} onChange={onChange}>
        {entries.map((entry, index) => {
          const { label, value } = entry;
          return (
            <Button key={index} value={value}>
              {label}
            </Button>
          );
        })}
      </Group>
    </section>
  );
};
export default GroupButtons;
