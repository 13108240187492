//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("principal");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
  return translation.set(...arguments)
}

export default {
  documentNumberLabel: getTranslation("documentNumberLabel"),


  companyType: getTranslation("companyType"),
  personType: getTranslation("personType"),

  personTypeLabel: getTranslation("personTypeLabel"),

  personLabel: getTranslation("personLabel"),
  personPlaceholder: getTranslation("personPlaceholder", false, true),

  companyLabel: getTranslation("companyLabel"),
  companyPlaceholder: getTranslation("companyPlaceholder", false, true),

  zipCodeLabel: getTranslation("zipCodeLabel"),

  searchCompany: getTranslation("searchCompany")

}