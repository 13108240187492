//Helper functions`
import {setInitialTranslation} from "helpers";
import {prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation("app");

const keys = [
    {
        "key": "noExtensionNumberLabel",
        "type": "label"
    },
    {
        "key": "openWebPhoneLabel",
        "type": "label"
    },
];

export default prepareTranslations(keys, translation);