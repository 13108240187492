import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  requestCustomersContacts,
  registerCustomersContacts,
  saveContactsData,
  deleteCustomerContact,
  registerCustomersErros,
  createCustomerContact,
  registerCustomersContactsTab,
} from "appRedux/actionCreators";
import { useParams } from "react-router-dom";
import { Col, Row, Spin, Button, Tabs, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import { LngInput, MaskedInput, FormField } from "components";
import AllowsAccessClientPortalModal from "./AllowsAccessClientPortalModal";
import RefuseAccessClientPortalModal from "./RefuseAccessClientPortalModal";
import { customerTabs } from "../../../../constants";
import { inputMasks, formatMaskInput } from "helpers";
import "./styles.less";
import Translation from "./translation";
const { TabPane } = Tabs;

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const {
    customerTabs: { actualTab, contactData },
    isFetching,
    isChanged,
    customersErrors: { errors },
  } = useSelector((state) => state.crm.customers);
  const { contacts } = contactData;
  const [newContactEmail, setNewContactEmail] = useState(null);
  const [allowsPortalModalVisible, setAllowsPortalModalVisible] = useState(
    false
  );
  const [refusePortalModalVisible, setRefusePortalModalVisible] = useState(
    false
  );
  const [currentModIndex, setCurrentModIndex] = useState(null);

  const fieldNames = {
    name: "name",
    businessPhoneNumber: "businessPhoneNumber",
    mobilePhoneNumber: "mobilePhoneNumber",
    email: "email",
    allowsChargingMessage: "allowsChargingMessage",
    allowsGeneralMessage: "allowsGeneralMessage",
    allowsBillingMessage: "allowsBillingMessage",
    allowsWhatsAppMessage: "allowsWhatsAppMessage",
    allowsRegisterData: "allowsRegisterData",
    allowsIndicativeQuotation: "allowsIndicativeQuotation",
    allowsAccessClientPortal: "allowsAccessClientPortal",
  };

  const getCheckboxValue = (name, source) => {
    return source && (source.hasOwnProperty(name) ? source[name] : false);
  };

  const onSave = async (contactItem) => {
    contactItem = {
      ...contactItem,
      allowsChargingMessage: getCheckboxValue(
        "allowsChargingMessage",
        contactItem
      ),
      allowsGeneralMessage: getCheckboxValue(
        "allowsGeneralMessage",
        contactItem
      ),
      allowsBillingMessage: getCheckboxValue(
        "allowsBillingMessage",
        contactItem
      ),
      allowsWhatsAppMessage: getCheckboxValue(
        "allowsWhatsAppMessage",
        contactItem
      ),
      allowsRegisterData: getCheckboxValue("allowsRegisterData", contactItem),
      allowsIndicativeQuotation: getCheckboxValue(
        "allowsIndicativeQuotation",
        contactItem
      ),
      allowsAccessClientPortal: getCheckboxValue(
        "allowsAccessClientPortal",
        contactItem
      ),
    };
    if (contactItem.isNewContact) {
      await dispatch(createCustomerContact({ ...contactItem, id }));
      setNewContactEmail(contactItem.email);
    } else {
      dispatch(saveContactsData({ ...contactItem, id }));
    }
  };

  const onCancel = (contactItem, contactIndex) => {
    contactItem.isNewContact
      ? handleRemoveTab(contactIndex)
      : dispatch(requestCustomersContacts(id));
  };

  const onChangeContactInput = (index, name) => (event) => {
    let contactsCopy = JSON.parse(JSON.stringify(contacts.data));
    event.target.hasOwnProperty("checked")
      ? (contactsCopy[index][name] = event.target.checked)
      : (contactsCopy[index][name] =
          name === fieldNames.mobilePhoneNumber ||
          name === fieldNames.businessPhoneNumber
            ? formatMaskInput(event.target.value)
            : event.target.value);

    const toSendData = { ...contacts, data: contactsCopy };
    dispatch(registerCustomersContacts({ data: toSendData, isChanged: true }));
  };

  const handleDeleteContact = (email) => {
    const previousTab = JSON.stringify(contacts.data.length - 2);
    dispatch(deleteCustomerContact({ email, id }));
    dispatch(registerCustomersContactsTab(previousTab));
  };

  const handleChangeContactTab = (event) => {
    dispatch(registerCustomersContactsTab(event));
    dispatch(registerCustomersErros({ errors: {} }));
  };

  const handleNewContact = () => {
    const newContactIndex = JSON.stringify(contacts.data.length);
    let contactsCopy = JSON.parse(JSON.stringify(contacts.data));

    contactsCopy.push({
      name: "",
      email: "",
      mobilePhoneNumber: "",
      businessPhoneNumber: "",
      allowsIndicativeQuotation: true,
      allowsWhatsAppMessage: true,
      allowsBillingMessage: true,
      allowsGeneralMessage: true,
      allowsChargingMessage: true,
      allowsRegisterData: false,
      allowsAccessClientPortal: false,
      isNewContact: true,
    });

    const toSendData = { ...contacts, data: contactsCopy };
    dispatch(registerCustomersContacts({ data: toSendData, isChanged: true }));
    dispatch(registerCustomersContactsTab(newContactIndex));
  };

  const handleRemoveTab = (targetKey) => {
    let contactsCopy = JSON.parse(JSON.stringify(contacts.data));

    contactsCopy.splice(targetKey);

    const toSendData = { ...contacts, data: contactsCopy };
    const newContactIndex = JSON.stringify(contactsCopy.length - 1);
    dispatch(registerCustomersContacts({ data: toSendData, isChanged: false }));
    dispatch(registerCustomersContactsTab(newContactIndex));
  };

  const getNewContactIndex = () => {
    if (newContactEmail) {
      let newIndex;

      contacts.data.forEach((item, index) => {
        if (item.email === newContactEmail) {
          newIndex = index;
        }
      });
      return JSON.stringify(newIndex);
    }
  };

  const handleConfirmAllowsAccessClientPortal = (index) => {
    onChangeContactInput(
      index,
      fieldNames.allowsAccessClientPortal
    )({ target: { checked: true, name: fieldNames.allowsAccessClientPortal } });
    setCurrentModIndex(null);
  };

  const handleRefuseAllowsAccessClientPortal = (index) => {
    onChangeContactInput(
      index,
      fieldNames.allowsAccessClientPortal
    )({
      target: { checked: false, name: fieldNames.allowsAccessClientPortal },
    });
    setCurrentModIndex(null);
  };

  const handleManagePortalAccessModals = (index) => {
    setCurrentModIndex(index);
    if (!contacts.data[index].allowsAccessClientPortal) {
      setAllowsPortalModalVisible(true);
    }
    if (contacts.data[index].allowsAccessClientPortal) {
      setRefusePortalModalVisible(true);
    }
  };

  const handleCloseAllowsAccessClientPortalModal = () => {
    setCurrentModIndex(null);
    setAllowsPortalModalVisible(false);
  };

  const handleCloseRefuseAccessClientPortalModal = () => {
    setCurrentModIndex(null);
    setRefusePortalModalVisible(false);
  };

  useEffect(() => {
    if (actualTab === customerTabs.contactData) {
      dispatch(requestCustomersContacts(id));
    }
  }, [actualTab]);

  useEffect(() => {
    if (getNewContactIndex()) {
      dispatch(registerCustomersContactsTab(getNewContactIndex()));
    }
  }, [contacts.data]);

  useEffect(() => {
    setNewContactEmail(null);
  }, [contactData.selectedTab]);

  return (
    <Spin spinning={isFetching} tip={Translation.customerContactFetchingLabel}>
      <Row type="flex" justify="end">
        <Button onClick={handleNewContact} className="gx-mr-4">
          Novo contato
        </Button>
      </Row>
      <Col md={24}>
        <Row>
          <Col md={24}>
            {contacts.data.length && !isFetching ? (
              <Tabs
                onChange={handleChangeContactTab}
                activeKey={contactData.selectedTab}
                defaultActiveKey={contactData.selectedTab}
              >
                {contacts.data.map((contact, index) => (
                  <TabPane
                    tab={`Contato${index + 1}`}
                    key={index}
                    className="tab-pane-class"
                  >
                    <Row type="flex" align="middle">
                      <Col
                        xs={24}
                        md={6}
                        className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
                      >
                        {Translation.customerContactNameLabel}
                      </Col>
                      <Col xs={24} md={14}>
                        <FormField error={errors.name}>
                          <LngInput
                            placeholder={
                              Translation.customerContactNamePlaceholder
                            }
                            value={contact.name}
                            onChange={onChangeContactInput(
                              index,
                              fieldNames.name
                            )}
                          ></LngInput>
                        </FormField>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle" className="gx-mt-2">
                      <Col
                        xs={24}
                        md={6}
                        className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
                      >
                        {Translation.customerContactPhoneLabel}
                      </Col>
                      <Col xs={24} md={14}>
                        <FormField error={errors.businessPhoneNumber}>
                          <MaskedInput
                            onChange={onChangeContactInput(
                              index,
                              fieldNames.businessPhoneNumber
                            )}
                            mask={inputMasks.phoneNumber}
                            value={contact.businessPhoneNumber}
                          ></MaskedInput>
                        </FormField>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle" className="gx-mt-2">
                      <Col
                        xs={24}
                        md={6}
                        className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
                      >
                        {Translation.customerContactMobileLabel}
                      </Col>
                      <Col xs={24} md={14}>
                        <FormField error={errors.mobilePhoneNumber}>
                          <MaskedInput
                            onChange={onChangeContactInput(
                              index,
                              fieldNames.mobilePhoneNumber
                            )}
                            mask={inputMasks.mobileNumber}
                            value={contact.mobilePhoneNumber}
                          ></MaskedInput>
                        </FormField>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle" className="gx-mt-2">
                      <Col
                        xs={24}
                        md={6}
                        className="gx-d-flex gx-justify-content-end gx-font-weight-bold"
                      >
                        {Translation.customerContactEmailLabel}
                      </Col>
                      <Col xs={24} md={14}>
                        <FormField error={errors.email}>
                          <LngInput
                            placeholder={
                              Translation.customerContactEmailPlaceholder
                            }
                            value={contact.email}
                            onChange={onChangeContactInput(
                              index,
                              fieldNames.email
                            )}
                          ></LngInput>
                        </FormField>
                      </Col>
                    </Row>
                    <Row type="flex" align="middle" className="gx-mt-2">
                      <Col xs={24} md={14} offset={6}>
                        <FormField error={errors.allowsIndicativeQuotation}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={onChangeContactInput(
                                index,
                                fieldNames.allowsIndicativeQuotation
                              )}
                              checked={contact.allowsIndicativeQuotation}
                            >
                              {
                                Translation.customerContactAllowsIndicativeQuotationLabel
                              }
                            </Checkbox>
                          </Row>
                        </FormField>
                        <FormField error={errors.allowsWhatsAppMessage}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={onChangeContactInput(
                                index,
                                fieldNames.allowsWhatsAppMessage
                              )}
                              checked={contact.allowsWhatsAppMessage}
                            >
                              {Translation.customerallowsWhatsAppMessageLabel}
                            </Checkbox>
                          </Row>
                        </FormField>

                        <FormField error={errors.allowsBillingMessage}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={onChangeContactInput(
                                index,
                                fieldNames.allowsBillingMessage
                              )}
                              checked={contact.allowsBillingMessage}
                            >
                              {Translation.customerContactBillingContactLabel}
                            </Checkbox>
                          </Row>
                        </FormField>

                        <FormField error={errors.allowsGeneralMessage}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={onChangeContactInput(
                                index,
                                fieldNames.allowsGeneralMessage
                              )}
                              checked={contact.allowsGeneralMessage}
                            >
                              {Translation.customerContactRequestContactLabel}
                            </Checkbox>
                          </Row>
                        </FormField>

                        <FormField error={errors.allowsChargingMessage}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={onChangeContactInput(
                                index,
                                fieldNames.allowsChargingMessage
                              )}
                              checked={contact.allowsChargingMessage}
                            >
                              {
                                Translation.customerContactCollectionContactLabel
                              }
                            </Checkbox>
                          </Row>
                        </FormField>

                        <FormField error={errors.allowsRegisterData}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={onChangeContactInput(
                                index,
                                fieldNames.allowsRegisterData
                              )}
                              checked={contact.allowsRegisterData}
                            >
                              {
                                Translation.customerContactAllowsRegisterDataContactLabel
                              }
                            </Checkbox>
                          </Row>
                        </FormField>

                        <FormField error={errors.allowsAccessClientPortal}>
                          <Row type="flex">
                            <Checkbox
                              className="gx-mt-2"
                              onChange={() =>
                                handleManagePortalAccessModals(index)
                              }
                              checked={contact.allowsAccessClientPortal}
                            >
                              {
                                Translation.customerContactAllowsPortalAccessContactLabel
                              }
                            </Checkbox>
                          </Row>
                        </FormField>
                      </Col>
                    </Row>
                    <Row
                      type="flex"
                      align="middle"
                      gutter={8}
                      className="gx-mt-3 gx-mr-4 gx-justify-content-end"
                    >
                      {!contact.isNewContact ? (
                        <Button
                          ghost
                          type="danger"
                          onClick={() => handleDeleteContact(contact.email)}
                        >
                          {Translation.customerContactDeleteContactLabel}
                        </Button>
                      ) : null}
                      <Button
                        disabled={isChanged ? false : true}
                        onClick={() => onCancel(contact, index)}
                      >
                        {Translation.registerCancelButtonLabel}
                      </Button>
                      <Button
                        disabled={isChanged ? false : true}
                        type="primary"
                        onClick={() => onSave(contact)}
                      >
                        {Translation.registerSaveButtonLabel}
                      </Button>
                    </Row>
                  </TabPane>
                ))}
              </Tabs>
            ) : !isFetching ? (
              <Col md={24}>
                <Row type="flex" justify="center">
                  {Translation.customerContactEmptyContacts}
                </Row>
              </Col>
            ) : null}
            <AllowsAccessClientPortalModal
              visible={allowsPortalModalVisible}
              index={currentModIndex}
              onClose={handleCloseAllowsAccessClientPortalModal}
              onConfirmAllowsAccessClientPortal={
                handleConfirmAllowsAccessClientPortal
              }
            />
            <RefuseAccessClientPortalModal
              visible={refusePortalModalVisible}
              index={currentModIndex}
              onClose={handleCloseRefuseAccessClientPortalModal}
              onConfirmAllowsAccessClientPortal={
                handleRefuseAllowsAccessClientPortal
              }
            />
          </Col>
        </Row>
      </Col>
    </Spin>
  );
};
