import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col, Row, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { AlertTriangle, RefreshCw } from "react-feather";
import Translation from "./translation";

const CountDownComponent = ({ time, label }) => {
  const [countdown, setCountdown] = useState(time);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1000);
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatTime = (time) => {
    if (time <= 0) {
      return "0:00";
    }
    const minutes = Math.floor(time / 60000);
    const seconds = Math.floor((time % 60000) / 1000);
    return `${minutes}:${seconds.toString().padStart(2, "0")}`;
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Spin
            style={{
              marginRight: 0,
              marginBottom: 16,
            }}
            indicator={
              <LoadingOutlined
                style={{
                  fontSize: 24,
                }}
                spin
              />
            }
          />
        </Col>
      </Row>
      <Row>
        <div
          span={24}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          <strong>{formatTime(countdown)}</strong>
          <p>{label}</p>
        </div>
      </Row>
    </>
  );
};

const PolicyEmissionAlerts = ({ status }) => {
  const timeCountdown = 1000 * 60 * 5; // 5 minutes
  const alertsConfig = {
    SURETY_PROCESSING: (
      <>
        <RefreshCw color="#E9DC21" />
        <p>{Translation.emissionStatusProcessing}</p>
      </>
    ),
    AWS_PROCESSING: (
      <CountDownComponent
        time={timeCountdown}
        label={Translation.awsStatusProcessing}
      />
    ),
    ERROR: (
      <>
        <AlertTriangle color="#EB6662" />
        <p>{Translation.emissionStatusError}</p>
      </>
    ),
    DEFAULT: null,
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "16px",
      }}
    >
      {alertsConfig[status] ? alertsConfig[status] : alertsConfig.DEFAULT}
    </div>
  );
};

PolicyEmissionAlerts.propTypes = {
  status: PropTypes.string.isRequired,
};

export default PolicyEmissionAlerts;
