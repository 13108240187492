// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";

export const limitSlice = createSlice({
  name: "limits",
  initialState: {
    list: [],
    isLoading: false,
    isProcessing: false,
  },
  reducers: {
    setLimitList: (state, action) => {
      state.list = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProcessing: (state, action) => {
      state.isProcessing = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const { setLimitList, setLoading, setProcessing } = limitSlice.actions;

export default limitSlice.reducer;

// -----------------------------------------------------------------

export const fetchLimit = (insurer, documentNumber) => (dispatch) => {
  dispatch(setProcessing(true));
  return new Promise((resolve, reject) => {
    axios
      .post("/surety/v2/reserve", {
        documentNumber,
        insurer,
        force: false,
      })
      .then((response) => {
        dispatch(setProcessing(false));
        resolve(response.data[0]);
      })
      .catch((error) => {
        dispatch(setProcessing(false));
        message.error(error.data ? error.data.message : error.message);
        reject(error);
      })
  });
};

export const fetchLimitList = (list, documentNumber) => (dispatch) => {
  dispatch(setLoading(true));
  Promise.all(
    list.map((item) => {
      return dispatch(fetchLimit(item, documentNumber));
    })
  )
    .then((response) => {
      dispatch(setLimitList(response));
    })
    .catch(() => {
      message.error(i18n.t("unexpectedError"));
    })
    .finally(() => {
      dispatch(setLoading(false));
    });
};
