import React, { useState, useEffect } from "react";
// props types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, DatePicker, Radio, Input } from "antd";
// components
import FormField from "../../../FormField";
import CurrencyInput from "../../../CurrencyInput";
// helpers
import { frontEndDate, dateFormat, getErrorMessage } from "../../../../helpers";
// moment
import moment from "moment";
// custom styles
import "./styles.less";

const UpdateEndorsement = ({
  data = {},
  policy = {},
  errors = [],
  onChange,
}) => {
  const [hasFinancialMovement, setHasFinancialMovement] = useState("no");
  const [hasValidityMovement, setHasValidityMovement] = useState("no");
  const [hasPremiumMovement, setHasPremiumMovement] = useState("no");

  const { t: translation } = useTranslation();

  const movementOptions = [
    {
      value: "yes",
      label: translation(
        "endorsement.createEndorsement.update.hasMovementLabel"
      ),
    },
    {
      value: "no",
      label: translation(
        "endorsement.createEndorsement.update.hasNoMovementLabel"
      ),
    },
  ];

  const handleChange = (field, value) => onChange && onChange(field, value);

  useEffect(() => {
    if (
      getErrorMessage("netPremium", errors) ||
      getErrorMessage("commission", errors)
    ) {
      setHasFinancialMovement("yes");
    }
    if (
      getErrorMessage("initialTermAt", errors) ||
      getErrorMessage("finalTermAt", errors)
    ) {
      setHasValidityMovement("yes");
    }
    if (
      getErrorMessage("premium", errors) ||
      getErrorMessage("maximumGuaranteeLimit", errors)
    ) {
      setHasPremiumMovement("yes");
    }
  }, [errors]);

  return (
    <>
      <Row type="flex" className="gx-mt-3">
        <Col xs={24} md={24} className="gx-mb-2">
          {translation(
            "endorsement.createEndorsement.update.financialMovementLabel"
          )}
        </Col>
        <Col xs={24} md={24}>
          <Radio.Group
            options={movementOptions}
            value={hasFinancialMovement}
            onChange={(e) => setHasFinancialMovement(e.target.value)}
          />
        </Col>
      </Row>
      {hasFinancialMovement === movementOptions[0].value && (
        <>
          <Row type="flex" className="gx-mt-3 warning-text">
            <Col xs={24} md={24}>
              {translation(
                "endorsement.createEndorsement.extension.negativeValueLabel"
              )}
            </Col>
          </Row>
          <Row type="flex">
            <Col xs={24} md={12}>
              <FormField
                label={translation(
                  "endorsement.createEndorsement.extension.premiumLabel"
                )}
                error={getErrorMessage("netPremium", errors)}
                className={`${
                  data.netPremium < 0 ? "negative-input-values" : ""
                }`}
              >
                <CurrencyInput
                  allowNegative
                  value={data.netPremium}
                  placeholder={translation(
                    "endorsement.createEndorsement.extension.premiumPlaceholder"
                  )}
                  onChange={(e) => handleChange("netPremium", e)}
                />
              </FormField>
            </Col>
            <Col xs={24} md={12}>
              <FormField
                label={translation(
                  "endorsement.createEndorsement.extension.commissionLabel"
                )}
                error={getErrorMessage("commission", errors)}
                className={`${
                  data.commission < 0 ? "negative-input-values" : ""
                }`}
              >
                <CurrencyInput
                  allowNegative
                  value={data.commission}
                  placeholder={translation(
                    "endorsement.createEndorsement.extension.commissionPlaceholder"
                  )}
                  onChange={(e) => handleChange("commission", e)}
                  suffix="%"
                  prefix=""
                />
              </FormField>
            </Col>
          </Row>
        </>
      )}

      <Row type="flex" className="gx-mt-3">
        <Col xs={24} md={24} className="gx-mb-2">
          {translation(
            "endorsement.createEndorsement.update.validityMovementLabel"
          )}
        </Col>
        <Col xs={24} md={24}>
          <Radio.Group
            options={movementOptions}
            value={hasValidityMovement}
            onChange={(e) => setHasValidityMovement(e.target.value)}
          />
        </Col>
      </Row>
      {hasValidityMovement === movementOptions[0].value && (
        <Row type="flex">
          <Col xs={24} md={8}>
            <FormField
              label={translation(
                "endorsement.createEndorsement.initialTermAtLabel"
              )}
              error={getErrorMessage("initialTermAt", errors)}
            >
              <DatePicker
                allowClear={false}
                className="gx-w-100"
                format={frontEndDate}
                value={
                  data.initialTermAt || moment(policy.initialTermAt, dateFormat)
                }
                onChange={(e) => handleChange("initialTermAt", e)}
              />
            </FormField>
          </Col>
          <Col xs={24} md={16} style={{ display: "flex", alignItems: "end" }}>
            <Row type="flex" align="middle" className="gx-w-100">
              <Col xs={24} md={2}>
                {translation("endorsement.description.extensionLabel")}
              </Col>
              <Col xs={24} md={12}>
                <FormField
                  error={getErrorMessage("finalTermAt", errors)}
                  colon={false}
                >
                  <DatePicker
                    allowClear={false}
                    className="gx-w-100"
                    format={frontEndDate}
                    value={
                      data.finalTermAt || moment(policy.finalTermAt, dateFormat)
                    }
                    onChange={(e) => handleChange("finalTermAt", e)}
                  />
                </FormField>
              </Col>
            </Row>
          </Col>
        </Row>
      )}

      <Row type="flex" className="gx-mt-3">
        <Col xs={24} md={24} className="gx-mb-2">
          {translation(
            `${
              policy.type && policy.type.product === "SURETY"
                ? "endosso.createEndorsement.update.suretyAmountMovementLabel"
                : "endorsement.createEndorsement.update.premiumMovementLabel"
            }`
          )}
        </Col>
        <Col xs={24} md={24}>
          <Radio.Group
            options={movementOptions}
            value={hasPremiumMovement}
            onChange={(e) => setHasPremiumMovement(e.target.value)}
          />
        </Col>
      </Row>
      {hasPremiumMovement === movementOptions[0].value && (
        <Row type="flex">
          <Col xs={24} md={12}>
            <FormField
              label={translation(
                `${
                  policy.type && policy.type.product === "SURETY"
                    ? "endosso.createEndorsement.update.suretyAmountLabel"
                    : "endorsement.createEndorsement.maximumGuaranteeLimitLabel"
                }`
              )}
              error={getErrorMessage("maximumGuaranteeLimit", errors)}
            >
              <CurrencyInput
                value={data.maximumGuaranteeLimit}
                onChange={(e) => handleChange("maximumGuaranteeLimit", e)}
                placeholder={translation(
                  `${
                    policy.type && policy.type.product === "SURETY"
                      ? "endosso.createEndorsement.update.suretyAmountPlaceholder"
                      : "endorsement.createEndorsement.maximumGuaranteeLimitPlaceholder"
                  }`
                )}
              />
            </FormField>
          </Col>
          <Col xs={24} md={12}>
            <FormField
              label={translation("endorsement.createEndorsement.premiumLabel")}
              error={getErrorMessage("premium", errors)}
            >
              <CurrencyInput
                value={data.premium}
                onChange={(e) => handleChange("premium", e)}
                placeholder={translation(
                  "endorsement.createEndorsement.premiumPlaceholder"
                )}
              />
            </FormField>
          </Col>
        </Row>
      )}
      <Row type="flex">
        <Col xs={24} md={24}>
          <FormField
            label={translation("endorsement.createEndorsement.alterationLabel")}
            error={getErrorMessage("alteration", errors)}
          >
            <Input.TextArea
              className="gx-w-100"
              value={data.alteration}
              placeholder={translation(
                "endorsement.createEndorsement.alterationPlaceholder"
              )}
              onChange={(e) => onChange("alteration", e)}
            />
          </FormField>
        </Col>
      </Row>
    </>
  );
};

UpdateEndorsement.propTypes = {
  data: PropTypes.object,
  policy: PropTypes.object,
  errors: PropTypes.array,
  onChange: PropTypes.func,
};

export default UpdateEndorsement;
