//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "modalsLabel",
    "type": "label"
  },
  {
    "key": "companyActivityLabel",
    "type": "label"
  },
  {
    "key": "companyActivityPlaceholder",
    "type": "label"
  },
  {
    "key": "insuredTypeLabel",
    "type": "label"
  },
  {
    "key": "modalsPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "monthlyTotalTripsLabel",
    "type": "label"
  },
  {
    "key": "monthlyTotalTripsPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "averageAmountLabel",
    "type": "label"
  },
  {
    "key": "averageAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "maxAmountLabel",
    "type": "label"
  },
  {
    "key": "maxAmountPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "ownMerchandiseOpenPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "thirdyPartyMerchandiseOpenPlaceholder",
    "type": "placeholder"
  },

];
export default prepareTranslations(keys, translation);