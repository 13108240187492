import React from "react";
//Translation
import Translation from "./translation";
//Custom Css
import './Styles.less';
//Components
import {Row, Col, Select} from 'antd';
import {LngInput} from "components";
import MaskedInput from "../MaskedInput";
//Constants
const {Option} = Select;
const searchesOption = {
    'companyName': {
        label: Translation.companyNameLabel,
        placeholder: Translation.companyNamePlaceholder
    },
    'contactName': {
        label: Translation.contactNameLabel,
        placeholder: Translation.contactNamePlaceholder
    },
    'documentNumber': {
        label: Translation.documentNumberLabel,
        placeholder: Translation.documentNumberPlaceholder,
    },
    'email': {
        label: Translation.emailLabel,
        placeholder: Translation.emailPlaceholder,
    },
    'phoneNumber': {
        label: Translation.phoneNumberLabel,
        placeholder: Translation.phoneNumberPlaceholder
    }
};
const searchOptionEntries = Object.entries(searchesOption);


class MultipleSearchHeader extends React.Component {
    state = {
        searchType: "companyName",
    };

    setSearch = (event) => {
        this.setState(({
            searchType: event
        }))
    };

    onSearchInputChange = (type, number) => (event) => {
        const {searches, updateData} = this.props;
        let searchValue = event.target.value;
        
        if(number && searchValue) {
            searchValue = searchValue.match(/[0-9]/g);
            searchValue = searchValue ? searchValue.join('') : '';
        }
        const updatedSearches = {...searches, [type]: searchValue};
        updateData("searches", updatedSearches)
    };

    render() {
        const {disableView, searches} = this.props;
        const {searchType} = this.state;

        const inputProps = {
            inputType:"search",
            type:"searchType",
            placeholder:searchesOption[searchType].placeholder,
            style:{width: '100%'},
            disabled:disableView,
            value:searches[searchType]
        }

        return (
            <Row type="flex" gutter={8} justify="space-between">
                <Col xs={24} lg={18} style={{height: '32px'}}>
                { searchType === 'documentNumber' ?             
                     <MaskedInput {...inputProps}
                         onChange={this.onSearchInputChange(searchType,true)}
                        mask="99.999.999/9999-99"/>
                    : <LngInput {...inputProps}
                        onChange={this.onSearchInputChange(searchType)}/>
           }
                </Col>
                <Col xs={24} lg={6}>
                    <Select disabled={disableView}
                            value={searchType}
                            onChange={this.setSearch}
                            style={{width: '100%'}}>
                        {searchOptionEntries.map(([searchIdentifier, search]) =>
                            <Option key={searchIdentifier} value={searchIdentifier}>{search.label}</Option>)}
                    </Select>
                </Col>
            </Row>
        )
    }
}

export default MultipleSearchHeader;

MultipleSearchHeader.defaultProps = {
    styleName: '',
    value: '',
    showExtra: true,
};
