import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CoveragesTable from './components/CoveragesTable'
// import { useMediaQuery } from 'react-responsive'
import { formatMoney, formatPercentage } from 'helpers'
import { coverageStatus, companyActivitiesList, insuredTypes } from '../../../constants'

import './styles.less'
import translation from '../OtherQuotations/translation'
const DetailQuotations = ({ tableOpen, isMobile, hideIcons, quoteValue }) => {
    const [isTableOpen, setIsTableOpen] = useState(true)
    const { insuredType, companyActivity } = useSelector(state => state.crm.order.configurations.general);

    const isInternational = quoteValue.accident.internationalRoad;

    useEffect(() => {
        if (!tableOpen) {
            setIsTableOpen(false)
        }
    }, [tableOpen])

    const generateButtonLabel = () => {
        if (isTableOpen && !tableOpen) {
            return translation.hideDetailsButtonLabel
        }
        else if (!isTableOpen && !tableOpen) {
            return translation.seeDetailsButtonLabel
        }

    }
    const compareInsuredType = (type) => {
        return insuredType === type ? true : false
    }

    const isRoadModal = (accident) => {
        if (accident.nationalRoad) {
            if (coverageStatus[accident.nationalRoad.status] === coverageStatus.AVAILABLE) {
                return (
                    <div className="info-wrapper-tax">
                        <span className="policy-value-label">{translation.cargoQuotationTaxLabel}</span>
                        <span className={`policy-value ${compareInsuredType(insuredTypes.OWN_MERCHANDISE) ? '' : 'accident-style'} `}>
                            {
                                quoteValue.accident.nationalRoad.rate == 0 ?
                                    `Tabela RCTR-C sem desconto`
                                    :
                                    compareInsuredType(insuredTypes.OWN_MERCHANDISE) ?
                                        formatPercentage(quoteValue.accident.nationalRoad.rate)
                                        :
                                        `Tabela RCTR-C com ${quoteValue.accident.nationalRoad.rate}% de desconto`
                            }
                        </span>
                        {quoteValue.accident.internationalRoad &&
                            <>
                                <span className="policy-value ">
                                    +
                                    </span>
                                <span className="policy-value accident-style">
                                    Rotas internacionais
                                    </span>
                            </>
                        }
                    </div>
                )
            } else {
                return (
                    <div className="info-error-wrapper">
                        <span className="policy-error">
                            {
                                coverageStatus[accident.nationalRoad.status]
                            }
                        </span>
                    </div>
                )
            }
        }
        else {
            return (<div className="info-error-wrapper">
                <span className="policy-error">
                    {
                        translation.underConsultLabel
                    }
                </span>
            </div>)
        }
    }

    return (
        <div className={`details-area ${hideIcons && 'hideIcons'}`}>
            <div className={`policy-area ${isMobile && 'isMobile'}`}>

                <div className={`policy-item ${isInternational ? "isInternational" : ""}`} >
                    {!hideIcons &&
                        <span className="policy-name">
                            {translation.openPolicyAccidentLabel}
                        </span>
                    }
                    {
                        isRoadModal(quoteValue.accident)
                    }

                    {!isMobile && <div className="policy-divider">
                        <hr className="line"></hr>
                    </div>}

                    <div className="info-wrapper">
                        {
                            quoteValue.accident.nationalRoad &&
                                quoteValue.accident.nationalRoad.premium && quoteValue.accident.nationalRoad.premium !== 0 ?
                                <>
                                    <span className="invoice-label">{translation.minimalInvoiceLabel}</span>
                                    <span className="invoice-value">

                                        {formatMoney(quoteValue.accident.nationalRoad.premium) + "*"}
                                    </span>
                                </>
                                :
                                null
                        }
                    </div>
                </div>
                {companyActivitiesList[companyActivity] !== companyActivitiesList.SHIPPER &&
                    <>
                        <div className={`policy-item ${isInternational ? "isInternational" : ""}`}>
                            {!hideIcons &&
                                <span className="policy-name">
                                    {translation.openPolicyTheftLabel}
                                </span>
                            }
                            {
                                quoteValue.theft.rate && quoteValue.theft.rate !== 0 ?
                                    <div className="info-wrapper-tax">
                                        <>
                                            <span className="policy-value-label">{translation.cargoQuotationTaxLabel}</span>
                                            <span className="policy-value">
                                                {formatPercentage(quoteValue.theft.rate)}
                                            </span>
                                            <span className="sub-label" ></span>
                                        </>
                                    </div>
                                    :
                                    <div className="info-error-wrapper">
                                        <span className="policy-error">
                                            {
                                                coverageStatus[quoteValue.theft.status]
                                            }
                                        </span>
                                    </div>
                            }
                            {!isMobile && <div className="policy-divider">
                                <hr className="line"></hr>
                            </div>}
                            <div className="info-wrapper">
                                {
                                    quoteValue.theft.premium && quoteValue.theft.premium !== 0 ?
                                        <>
                                            <span className="invoice-label">{translation.minimalInvoiceLabel}</span>
                                            <span className="invoice-value">
                                                {formatMoney(quoteValue.theft.premium) + "*"}
                                            </span>
                                        </>
                                        :
                                        null
                                }
                            </div>
                        </div>

                    </>
                }

                <div className={`policy-item ${isInternational ? "isInternational" : ""}`}>
                    {!hideIcons &&
                        <span className="policy-name">
                            {translation.openPolicyLifeLabel}
                        </span>
                    }
                    {
                        quoteValue.life && quoteValue.life.rate && quoteValue.life.rate !== 0 ?
                            <div className="info-wrapper-tax">
                                <>
                                    <span className="policy-value-label">{translation.cargoQuotationTaxLabel}</span>
                                    <span className="policy-value">{formatMoney(quoteValue.life.rate)}</span>
                                    <span className="sub-label" >por embarque</span>
                                </>
                            </div>
                            :
                            <div className="info-error-wrapper">
                                <span className="policy-error">
                                    {
                                        coverageStatus[quoteValue.life.status]
                                    }
                                </span>
                            </div>
                    }
                    {!isMobile && <div className="policy-divider">
                        <hr className="line"></hr>
                    </div>}
                    <div className="info-wrapper">
                        {
                            quoteValue.life && quoteValue.life.premium && quoteValue.life.premium !== 0 ?
                                <>
                                    <span className="invoice-label">{translation.estimatedInvoiceLabel}</span>
                                    <span className="invoice-value">
                                        {formatMoney(quoteValue.life.premium)}
                                    </span>
                                </>
                                :
                                null
                        }
                    </div>
                </div>


                <div className={`policy-item ${isInternational ? "isInternational" : ""}`}>
                    {!hideIcons &&
                        <span className="policy-name">
                            {translation.openPolicyAdditionalLabel}
                        </span>
                    }
                    {
                        quoteValue.additional && quoteValue.additional.rate && quoteValue.additional.rate !== 0 ?
                            <div className="info-wrapper-tax">
                                <>
                                    <span className="policy-value-label">Taxas totais</span>
                                    <span className="policy-value">{formatPercentage(quoteValue.additional.rate)}</span>
                                    <span className="sub-label">por valor embarcado</span>
                                </>
                            </div>
                            :
                            <div className="info-error-wrapper">
                                <span className="policy-error">
                                    {
                                        coverageStatus[quoteValue.additional.status]
                                    }
                                </span>
                            </div>
                    }
                    {!isMobile && <div className="policy-divider">
                        <hr className="line"></hr>
                    </div>}
                    <div className="close-wrapper">
                        <button className="close-coverages" disabled={tableOpen} onClick={() => setIsTableOpen(!isTableOpen)}>{generateButtonLabel()}</button>
                    </div>
                </div>
            </div>

            {
                isTableOpen &&
                <div className="coverages-area">
                    <CoveragesTable accident={quoteValue.accident} />
                </div>
            }
        </div>

    )
}

export default DetailQuotations