//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("orderHeader");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    backLabel: getTranslation("back", true),
    reloadOrdersLabel: getTranslation("reloadOrdersLabel"),
}