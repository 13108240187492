//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("error404");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    errorMessage: getTranslation("errorMessage"),
    returnHomeMessage: getTranslation("goHomeMessage")
}