import React,{useState,useEffect} from 'react'
import {Select, Col} from 'antd'
import Request from "helpers/Request";
import FormField from "components/FormField/index"
import Translation from "./translation";

const {Option} = Select
export const CourtSelect = (props) =>{
    const {courtValue, tribunalValue, errors, onBlur, onChangeCourt,onChangeTribunal} = props;
    const [tribunalOptions, setTribunalOptions] = useState([])
    const [courtOptions, setCourtOptions] = useState([])

    const requestTribunalOptions = () =>{
        Request.get('/surety/v2/util/tribunal')
            .then((res)=>{
                Promise.resolve(res.json())
                    .then((response)=>{
                        setTribunalOptions(response)
                    })
            })
            .catch((err)=>{})
    }

    const handleSelectTribunal = (event) =>{
        let tempCourtOptions = []
        tribunalOptions.filter((option)=>{
            if(option.code === event){
                tempCourtOptions = option.courts
            }
        })
        setCourtOptions(tempCourtOptions)
    }

    useEffect(()=>{
        requestTribunalOptions()
    },[])
    
    useEffect(()=>{
        if(tribunalValue){
            handleSelectTribunal(tribunalValue)
        }
    },[tribunalOptions])

    return(
        <>
            <Col xs={24} md={6}>{Translation.tribunalLabel}:</Col>
            <Col sm={24} md={18} className="gx-mb-3">
                <FormField
                        error={errors["detail.tribunal"]}
                        >
                    <Select value={tribunalValue} disabled={tribunalOptions.length === 0} style={{width:'100%'}} onBlur={onBlur} onChange={(event)=>{
                        handleSelectTribunal(event)
                        onChangeTribunal(event)
                        onChangeCourt(null)
                        }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }>
                            <Option value={null}>{Translation.tribunalPlaceholder}</Option>
                        {
                            tribunalOptions.length > 0 ? tribunalOptions.map((option)=>{
                                return <Option key={option.code} value={option.code}>{option.name}</Option>
                            }) : false
                        }
                    </Select>
                </FormField>
            </Col>
            <Col xs={24} md={6}>{Translation.courtLabel}:</Col>
            <Col sm={24} md={18} className="gx-mb-3">
                <FormField
                        error={errors["detail.court"]}
                        >
                    <Select value={courtValue} disabled={courtOptions.length === 0} style={{width:'100%'}} onChange={onChangeCourt}
                        showSearch
                        onBlur={onBlur}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                        option.props.children.toUpperCase().indexOf(input.toUpperCase()) >= 0
                        }
                    >
                        <Option value={null}>{Translation.courtPlaceholder}</Option>
                        {
                            courtOptions.length > 0 ?  courtOptions.map((option)=>{
                                return <Option key={option.code} value={option.code}>{option.name}</Option>
                            }) : false
                        }
                    </Select>
                </FormField>
            </Col>
        </>
    )
}

export default CourtSelect