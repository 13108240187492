import React, { useEffect, useState, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Tooltip } from 'antd'
import translation from './translation'
import './styles.less'
//web notification
import Notification from 'react-web-notification'
//audio
import telefoneRing from '../../assets/audios/telephone-ring.wav'

const VoipComponent = () => {
    const [voipOpen, setVoipOpen] = useState(false)
    const { voipURL } = useSelector(state => state.auth.authUser)
    const [ignore, setIgnore] = useState(true)

    let timeoutId, timeoutId2, oldTitle = document.title;
    let telephoneRingAudio = new Audio(telefoneRing)

    // const callNumber = (number) => {
    //     const iFrame = document.querySelector('#totalvoice')
    //     iFrame.contentWindow.postMessage({
    //         message: 'chamaNumero',
    //         'numero': number
    //     }, '*');
    // }

    const notificationProps = {
        tag: Date.now(),
        body: 'Webphone',
        icon: null,
        lang: 'pt',
        dir: 'auto',
    }

    const showNotification = () => {
        setIgnore(false)
    }

    const handleNotificationOnShow = () => {
        telephoneRingAudio.play()
    }

    const handleNotificationOnClose = () => {
        setIgnore(true)
    }

    const blinkTab = (alertMessage) => {
        if (!timeoutId) {
          timeoutId = setInterval(() => {
            document.title = document.title == alertMessage ? oldTitle : alertMessage;
          }, 1000);
        }
    };

    const injectVoipFunctions = () => {
        window.onmessage = function (e) {
            if (e.data.message == 'chegandoChamada') {
                if (!voipOpen) {
                    setVoipOpen(true)
                }
                showNotification()
                blinkTab("ring! ring!...")
            }
        }

        window.onmousemove = function() {                                                               /* function to set title back to original */
            clearInterval(timeoutId);
            document.title = oldTitle;
            timeoutId = null;
        }
    }

    useEffect(() => {
        injectVoipFunctions()
    }, [])


    // reload page after laptop sleep to reconnect voip
    // useEffect(() => {
    //     var SAMPLE_RATE = 2000;
    //     var lastSample = Date.now();
    //     function sample() {
    //         if (Date.now() - lastSample >= SAMPLE_RATE * 2) {
    //             window.location.reload();
    //         }
    //         lastSample = Date.now();
    //         timeoutId2 = setTimeout(sample, SAMPLE_RATE);
    //     }

    //     sample();

    //     return () => {
    //         clearTimeout(timeoutId2);
    //     }
    // })

    return (
        <>
            { !voipOpen &&
            <Tooltip title={!voipURL ? translation.noExtensionNumberLabel : translation.openWebPhoneLabel} placement="left" style={{borderRadius:'50%'}}>
                <div className="toggle-btn-area">
                    <button className={`toggle-btn ${!voipURL ? 'disabled' : ''}`} disabled={!voipURL} onClick={() => setVoipOpen(!voipOpen)}>
                        <img src={require("assets/images/phone-icon.svg")} alt="phone icon" />
                    </button>
                </div>
            </Tooltip>
            }
            <div className={`voip-area ${voipOpen ? 'voip-opened' : ''}`}>
                <div className="voip-header">
                    <span onClick={() => setVoipOpen(!voipOpen)}>
                        <img className="close-voip" src={require("assets/images/hide-icon.svg")} alt="esconder voip" />
                    </span>
                </div>
                <iframe id="totalvoice" width="100%" height="320px" allow="microphone" src={voipURL} frameBorder="0"></iframe>
            </div>
            <Notification
                ignore={ignore}
                onClose={handleNotificationOnClose}
                onShow={handleNotificationOnShow}
                title={'Nova ligação de cliente na URA!'}
                options={notificationProps}
            />
        </>
    )
}

export default VoipComponent