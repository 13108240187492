import React, { useState } from "react";
// router
import { useHistory } from "react-router";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Form, Input, Button, Row } from "antd";
// redux
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword, setErrors } from "../../store/slices/auth";
// components
import CircularProgress from "../../components/CircularProgress";
// helpers
import { getErrorProps, clearError } from "../../helpers/errors";
// logo
import mutuusLogo from "assets/images/logo.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const {
    common: { isLoading },
    auth: { errors },
  } = useSelector((state) => state);

  const { t: translation } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    setEmail(e.target.value);
    dispatch(setErrors(clearError("email", errors)));
  };

  const handleSendEmail = () => {
    if (!email) {
      return dispatch(
        setErrors([
          {
            field: "email",
            message: translation("auth.forgotPassword.emailPlaceholder"),
          },
        ])
      );
    }
    dispatch(forgotPassword(email)).then(() => {
      history.push("/signin");
    });
  };

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="gx-login-container">
          <div className="gx-login-content">
            <div className="gx-login-header">
              <img alt="Logo Mutuus Seguros" src={mutuusLogo} />
            </div>
            <div className="gx-mb-3">
              <h2 className="gx-text-center gx-mb-3">
                {translation("auth.forgotPassword.title")}
              </h2>
              <h4>{translation("auth.forgotPassword.emailLabel")}</h4>
            </div>
            <div className="gx-px-3">
              <Form.Item
                className="gx-mb-5"
                {...getErrorProps("email", errors)}
              >
                <Input
                  type="email"
                  placeholder={translation(
                    "auth.forgotPassword.emailPlaceholder"
                  )}
                  onChange={handleChange}
                />
              </Form.Item>
              <Row type="flex" justify="center">
                <Button
                  ghost
                  type="primary"
                  htmlType="submit"
                  onClick={() => history.push("/signin")}
                >
                  {translation("auth.forgotPassword.backButton")}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={handleSendEmail}
                >
                  {translation("auth.forgotPassword.sendButton")}
                </Button>
              </Row>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
