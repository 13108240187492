import moment from "moment";

function formatDate(date) {
  return date ? moment(date) : null;
}

export default function (serverData, prevConfig) {

  function updateInitial() {
    const data = serverData.initial;
    const prevState = prevConfig.initial;
    return {
      name: data.name || prevState.name,
      email: data.email || prevState.email,
      phoneNumber: data.phoneNumber || prevState.phoneNumber,
      phoneType: data.phoneType || prevState.phoneType,
      receiveWhatsAppMessage: data.receiveWhatsAppMessage || prevState.receiveWhatsAppMessage,
      contactType: data.contactType || prevState.contactType,
      documentNumber: data.documentNumber || prevState.documentNumber,
      hasDocumentNumber: data.hasDocumentNumber || prevState.hasDocumentNumber,
    }
  }

  function updateInsured() {
    const data = serverData.insured;
    const prevState = prevConfig.insured;
    return {
      documentNumber: data.documentNumber || prevState.documentNumber,
      type: data.type || prevState.type,
      name: data.name || prevState.name,
      zipCode: data.zipCode || prevState.zipCode,
      address: data.address || prevState.address,
      number: data.number || prevState.number,
      neighborhood: data.neighborhood || prevState.neighborhood,
      state: data.state || prevState.state,
      city: data.city || prevState.city,
      additionalData: data.additionalData || prevState.additionalData,
    }
  }

  function updateGoods() {
    const prevState = prevConfig.goods;
    const data = serverData.goods;
    return data.goods || prevState
  }

  function updateGeneral() {
    const data = serverData.general;
    const prevState = prevConfig.general;
    return {
      companyActivity: data.companyActivity || prevState.companyActivity,
      merchandiseOwner: data.merchandiseOwner || prevState.merchandiseOwner,
      origin: data.origin || prevState.origin,
      destination: data.destination || prevState.destination,
      currency: data.currency || prevState.currency,
      cargoTotalAmount: data.cargoTotalAmount || prevState.cargoTotalAmount,
      travelAverageAmount: data.travelAverageAmount || prevState.travelAverageAmount,
      anyUsedMerchandise: data.anyUsedMerchandise || prevState.anyUsedMerchandise
    }
  }

  function updateDetails() {
    const data = serverData.details;
    const prevState = prevConfig.details;
    return {
      modals: data.modals || prevState.modals,
      incoTerm: data.incoTerm || prevState.incoTerm,
      boardingDate: formatDate(data.boardingDate),
      acceptRiskManagement: data.acceptRiskManagement || prevState.acceptRiskManagement,
      acceptPolicyAndPrivacy: data.acceptPolicyAndPrivacy || prevState.acceptPolicyAndPrivacy
    }
  }

  function updateFiles() {
    const data = serverData.files;
    const prevState = prevConfig.files;
    return {
      files: data || prevState
    }
  }

  return {
    initial: updateInitial(),
    insured: updateInsured(),
    goods: updateGoods(),
    general: updateGeneral(),
    details: updateDetails(),
    files: updateFiles(),
  };
}
