import React from "react";
//External Libraries
import NumberFormat from "react-number-format";
//Custom Components
import CurrencyInput from "../CurrencyInput";
//Translation
import translation from "../Order/OrderResume/translation";

const FormattedValue = (props) => {
  const {value, formatter, direct, type, isModal, format, ...otherConfig} = props;

  const inputMasks = {
    'INDIVIDUAL': '###.###.###-##',
    'COMPANY': '##.###.###/####-##',
    'zipCode': '##.###-###',
    'date': '##/##/####',
    'contactPhone': '(##) #####-####'
  };
  const processMaskedValues = () => {
    if(format === 'contactPhone'){
      if(finalValue.length < 11){
        inputMasks.contactPhone = '(##) ####-####'
      }else{
        inputMasks.contactPhone = '(##) #####-####'
      }
    }
  }

  const processModalValues = (TransportMeansValue) => {
    let translatedTransports = []
    TransportMeansValue.forEach((transport) => {
      switch (transport) {
        case 'ROAD_MODAL':
          translatedTransports.push("Rodoviário")
          break;
        case 'RAIL_MODAL':
          translatedTransports.push("Ferroviário")
          break;
        case 'MARINE_MODAL':
          translatedTransports.push("Marítimo")
          break;
        case 'AIR_MODAL':
          translatedTransports.push("Aéreo")
          break;
        default:
          break;
      }
    })
    finalValue = translatedTransports.join(', ')
  }
  
  let finalValue = value, maskedInput = false;

  if (finalValue === null || finalValue === undefined)
    return translation.noData;

  if (formatter)
    finalValue = formatter(value);

  if ("mask" in otherConfig || format)
    maskedInput = true;
    processMaskedValues()

  if(isModal){
    processModalValues(finalValue)
  }
  

  if (type === "string")
    return finalValue;
  else if (type === "currency")
    return <CurrencyInput value={finalValue} displayType="text"/>;
  else if (maskedInput)
    return <NumberFormat value={finalValue} format={inputMasks[format]} formatter={formatter} displayType="text" {...otherConfig}/>;
  return null;
};
export default FormattedValue;