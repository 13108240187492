import config from "config";

const customHeader = (method) => {
  let userId = localStorage.getItem("user_id");
  userId = userId ? JSON.parse(userId).id : "";

  return {
    "mutuus-user": userId,
    "Content-Type": "application/json",
  };
};

const base = (method, url, data = null) => {
  return fetch(`${config.apiUrl}${url}`, {
    method,
    headers: customHeader(method),
    body: data ? JSON.stringify(data) : undefined,
  });
};

const Request = {};
["get", "post", "put", "delete"].forEach((method) => {
  Request[method] = base.bind(null, method);
});

export default Request;
