//Helper functions`
import {setInitialTranslation} from "helpers";
//Constants
const translation = setInitialTranslation("order");

// Custom Helpers
function getTranslation(title, defaultConfig, stringFormat) {
    return translation.set(...arguments)
}

export default {
    orderLabel: getTranslation("orderLabel"),
    noData: getTranslation("noData", true),
    expressEmission: getTranslation("expressEmission"),
    manualEmission: getTranslation("manualEmission"),
    onlineEmission: getTranslation("onlineEmission"),
    userFormLink: getTranslation("userFormLink"),
    copyToClipboardLabel: getTranslation("copyToClipboardLabel"),
    copyToClipboardMessage: getTranslation("copyToClipboardMessage", false, true),
    downloadFileButton: getTranslation("downloadFileButton")
}