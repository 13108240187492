// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";
// helpers
import { formatMaskInput } from "helpers";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    registerData: {
      personType: "COMPANY",
    },
    searchData: {
      selectedFilter: "DEFAULT_LABEL",
      searchValue: "",
      requestUrl: "",
      results: [],
      errors: [],
      isSearching: false,
    },
    isLoading: false,
    errors: [],
  },
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setRegisterData: (state, action) => {
      state.registerData = { ...state.registerData, ...action.payload };
    },
    cleanRegisterData: (state, action) => {
      state.registerData = { personType: "COMPANY" };
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    changeCustomerData: (state, action) => {
      state.registerData = {
        ...state.registerData,
        ...action.payload,
      };
    },
    setSelectedFilter: (state, action) => {
      state.searchData.selectedFilter = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchData.searchValue = action.payload;
    },
    setRequestUrl: (state, action) => {
      state.searchData.requestUrl = action.payload;
    },
    setResults: (state, action) => {
      state.searchData.results = action.payload;
    },
    setSearching: (state, action) => {
      state.searchData.isSearching = action.payload;
    },
    setSearchErrors: (state, action) => {
      state.searchData.errors = action.payload;
    },
  },
});

// ------------------------------------------------------------------

export const {
  setErrors,
  setLoading,
  setRegisterData,
  cleanRegisterData,
  changeCustomerData,
  setSelectedFilter,
  setSearchValue,
  setRequestUrl,
  setResults,
  setSearching,
  setSearchErrors,
} = customerSlice.actions;

export default customerSlice.reducer;

// -----------------------------------------------------------------

export const fetchCustomerData = (id) => (dispatch) => {
  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/person/v2/${id}`)
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setErrors([]));
        dispatch(setRegisterData(response.data));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        message.error(error.message);
        reject(error);
      });
  });
};

export const saveCustomerData = (id) => (dispatch, getState) => {
  const {
    customer: {
      registerData: {
        id,
        documentNumber,
        additionalData,
        neighborhood,
        companyName,
        personType,
        address,
        zipCode,
        number,
        state,
        city,
      },
    },
  } = getState();

  dispatch(setLoading(true));

  axios
    .put(`/person/v2/${id}`, {
      id,
      documentNumber,
      additionalData,
      neighborhood,
      companyName,
      personType,
      address,
      zipCode,
      number,
      state,
      city,
    })
    .then((response) => {
      dispatch(setLoading(false));
      dispatch(setErrors([]));
      message.success(i18n.t("customer.updateCustomerDataSuccess"));
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setErrors(error.response.data.errors));
      message.error(error.response.data.message);
    });
};

export const createNewCustomer = () => (dispatch, getState) => {
  const {
    customer: {
      registerData: {
        id,
        documentNumber,
        additionalData,
        neighborhood,
        companyName,
        personType,
        address,
        zipCode,
        number,
        state,
        city,
      },
    },
  } = getState();

  dispatch(setLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/person/v2`, {
        id,
        documentNumber,
        additionalData,
        neighborhood,
        companyName,
        personType,
        address,
        zipCode,
        number,
        state,
        city,
        personType,
      })
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setErrors([]));
        message.success(i18n.t("customer.createNewCustomerSuccess"));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setErrors(error.response.data.errors));
        message.error(error.response.data.message);
        reject(error);
      });
  });
};

export const requestCustomerRegisterData = (documentNumber) => (
  dispatch,
  getState
) => {
  const {
    customer: { registerData },
  } = getState();

  dispatch(setLoading(true));

  axios
    .get(
      `/person/v1/company?documentNumber=${formatMaskInput(
        documentNumber
      )}&cache=false`
    )
    .then((response) => {
      dispatch(setLoading(false));
      dispatch(setErrors([]));
      dispatch(
        setRegisterData({
          ...registerData,
          ...response.data,
          ...response.data.address,
          companyName: response.data.name,
          number: response.data.address.addressNumber,
        })
      );
    })
    .catch((error) => {
      dispatch(setLoading(false));
      dispatch(setErrors(error.errors));
      message.error(error.message);
    });
};

export const addPolicyEmissionPermission = () => (dispatch, getState) => {
  const {
    customer: {
      registerData: { documentNumber },
    },
  } = getState();

  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .post(`/surety/v2/policy-emission-permission`, {
        documentNumber,
      })
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setErrors([]));
        message.success(i18n.t("customer.addPolicyEmissionPermissionSuccess"));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setErrors(error.response?.data?.errors));
        message.error(error.response?.data?.message);
        reject(error);
      });
  });
};

export const removePolicyEmissionPermission = () => (dispatch, getState) => {
  const {
    customer: {
      registerData: { documentNumber },
    },
  } = getState();

  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .delete(`/surety/v2/policy-emission-permission/${documentNumber}`)
      .then((response) => {
        dispatch(setLoading(false));
        dispatch(setErrors([]));
        message.success(
          i18n.t("customer.removePolicyEmissionPermissionSuccess")
        );
        resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        dispatch(setErrors(error.response?.data?.errors));
        message.error(error.response?.data?.message);
        reject(error);
      });
  });
};

export const getPolicyEmissionPermission = () => (dispatch, getState) => {
  const {
    customer: {
      registerData: { documentNumber },
    },
  } = getState();
  dispatch(setLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .get(`/surety/v2/policy-emission-permission/${documentNumber}`)
      .then((response) => {
        dispatch(setLoading(false));
        resolve(response);
      })
      .catch((error) => {
        dispatch(setLoading(false));
        message.error(error.response?.data?.message);
        reject(error);
      });
  });
};

export const getSearchData = () => (dispatch, getState) => {
  const {
    customer: {
      searchData: { requestUrl },
    },
  } = getState();
  dispatch(setSearching(true));
  return new Promise((resolve, reject) => {
    axios
      .get(`${requestUrl}`)
      .then((response) => {
        dispatch(setSearching(false));
        if (
          response?.data?.length === 0 ||
          response?.data?.data?.length === 0
        ) {
          dispatch(
            setResults([
              {
                description: "NO_RESULTS_FOUND",
              },
            ])
          );
        }
        if (Array.isArray(response?.data) && response?.data?.length > 0) {
          dispatch(setResults(response?.data));
        } else if (
          Array.isArray(response?.data?.data) &&
          response?.data?.data?.length > 0
        ) {
          dispatch(setResults(response?.data?.data));
        }
        resolve(response);
      })
      .catch((error) => {
        dispatch(setSearching(false));
        message.error(error.response?.data?.message);
        reject(error);
      });
  });
};
