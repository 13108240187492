//Helper functions
import { setInitialTranslation, prepareTranslations } from "helpers";

//Constants
const translation = setInitialTranslation();

const keys = [
  {
    "key": "proposalAcceptedLabel",
    "type": "label"
  },
  {
    "key": "receiveDraftContractLabel",
    "type": "label"
  },  
  {
    "key": "contractDateLabel",
    "type": "label"
  },  
  {
    "key": "contractNumberLabel",
    "type": "label"
  },  
  {
    "key": "contractNumberPlaceholder",
    "type": "placeholder"
  },  
  {
    "key": "additionalDataLabel",
    "type": "label"
  },  
  {
    "key": "additionalDataPlaceholder",
    "type": "placeholder"
  },  
  {
    "key": "obligationLabel",
    "type": "label"
  },  
  {
    "key": "obligationPlaceholder",
    "type": "placeholder"
  }, 
  {
    "key": "installmentLabel",
    "type": "label"
  },  
  {
    "key": "installmentPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "bidNumberLabel",
    "type": "label"
  },  
  {
    "key": "bidNumberPlaceholder",
    "type": "placeholder"
  },
  {
    "key": "bidDateLabel",
    "type": "label"
  },  
  {
    "key": "bidDatePlaceholder",
    "type": "placeholder"
  },  
  {
    "key": "processNumberLabel",
    "type": "label"
  },  
  {
    "key": "courtProceedingPlaceLabel",
    "type": "label"
  },  
  {
    "key": "courtProceedingPlacePlaceholder",
    "type": "placeholder"
  },    
  {
    "key": "courtProceedingCityLabel",
    "type": "label"
  },  
  {
    "key": "courtProceedingCityPlaceholder",
    "type": "placeholder"
  },    
  {
    "key": "courtProceedingStateLabel",
    "type": "label"
  },  
  {
    "key": "courtProceedingStatePlaceholder",
    "type": "label"
  },    
  {
    "key": "complainantTypeLabel",
    "type": "label"
  },  
  {
    "key": "complainantDocumentNumberLabel",
    "type": "label"
  },  
  {
    "key": "complainantNameLabel",
    "type": "label"
  },  
  {
    "key": "complainantNamePlaceholder",
    "type": "placeholder"
  },   
  {
    "key": "actionTypeLabel",
    "type": "label"
  },   
  {
    "key": "actionTypePlaceholder",
    "type": "placeholder"
  },  
];

export default prepareTranslations(keys, translation);