//Helper functions
import {setInitialTranslation, prepareTranslations} from "helpers";
//Constants
const translation = setInitialTranslation();
const keys = [
  {
    "key": "bestScenarioLabel",
    "type": "label"
  },
  {
    "key": "neutralScenarioLabel",
    "type": "label"
  },
  {
    "key": "worstScenarioLabel",
    "type": "label"
  },
  {
    "key": "simulation.description",
    "type": "label"
  },
  {
    "key": "accidentLabel",
    "type": "label"
  },
  {
    "key": "theftLabel",
    "type": "label"
  },
  {
    "key": "theftAndDamageLabel",
    "type": "label"
  },
  {
    "key": "valueInCurrencyLabel",
    "type": "label"
  }
];
export default prepareTranslations(keys, translation);