import React from "react";
//Translations
import Translation from "./translation";
//Components
import { Row, Col } from "antd";
//Custom Components
import {
  LngInput,
  MaskedInput,
  DocumentNumberForm,
  FormField,
  StateSelect,
} from "components";
// constants
import { courtTypes } from "../../../../../constants";

const Insured = (props) => {
  const {
    handleChange,
    configurations,
    searchCompany,
    OrderErrors,
    bond,
  } = props;

  const isCourtType = bond ? courtTypes.includes(bond.type) : false;

  const onChange = (attr, type = "event") => (event) => {
    let value;
    if (event) {
      event.hasOwnProperty("target")
        ? (value = event.target.value)
        : (value = event);
    } else {
      value = event;
    }
    return handleChange({
      attr: attr,
      value: value,
    });
  };

  const getValue = (entity, callbackUi = null) => {
    if (configurations.hasOwnProperty(entity)) return configurations[entity];
    return callbackUi;
  };

  const getDocumentNumberLabel = (personType, courtType) => {
    if (courtType) {
      return personType === "COMPANY"
        ? Translation.judicialCompanyDocumentLabel
        : Translation.judicialIndividualDocumentLabel;
    } else {
      return personType === "COMPANY"
        ? Translation.insuredCompanyDocumentLabel
        : Translation.insuredIndividualDocumentLabel;
    }
  };

  return (
    <section>
      <DocumentNumberForm
        searchCompany={searchCompany}
        type={getValue("type") || "COMPANY"}
        onTypeChange={onChange("type")}
        documentNumber={getValue("documentNumber")}
        onDocumentChange={onChange("documentNumber")}
        personTypeLabel={
          isCourtType
            ? Translation.judicialPersonTypeLabel
            : Translation.insuredPersonTypeLabel
        }
        documentNumberLabel={getDocumentNumberLabel(
          getValue("type") || "COMPANY",
          isCourtType
        )}
        errors={
          OrderErrors["obligee.documentNumber"] ||
          OrderErrors["insured.documentNumber"] ||
          OrderErrors["customer.documentNumber"]
        }
      />
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {isCourtType
            ? Translation.judicialNameLabel
            : Translation.insuredNameLabel}
          :
        </Col>
        <Col xs={24} md={18}>
          <FormField
            error={
              OrderErrors["obligee.name"] ||
              OrderErrors["insured.name"] ||
              OrderErrors["customer.name"]
            }
          >
            <LngInput
              value={getValue("name")}
              onChange={onChange("name")}
              placeholder={
                isCourtType
                  ? Translation.judicialNamePlaceholder
                  : Translation.insuredNamePlaceholder
              }
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.fullAddressLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField
            error={
              OrderErrors["obligee.address"] ||
              OrderErrors["insured.address"] ||
              OrderErrors["customer.address"]
            }
          >
            <LngInput
              value={getValue("address")}
              onChange={onChange("address")}
              placeholder={Translation.fullAddressPlaceholder}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.addressNumberLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField
            error={
              OrderErrors["obligee.number"] ||
              OrderErrors["insured.number"] ||
              OrderErrors["customer.number"]
            }
          >
            <LngInput
              value={getValue("number")}
              onChange={onChange("number")}
              placeholder={Translation.addressNumberPlaceholder}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.addressAddDataLabel}
        </Col>
        <Col xs={24} md={18}>
          <FormField
            error={
              OrderErrors["obligee.additionalData"] ||
              OrderErrors["insured.additionalData"] ||
              OrderErrors["customer.additionalData"]
            }
          >
            <LngInput
              placeholder={Translation.addressAddDataPlaceholder}
              value={getValue("additionalData")}
              onChange={onChange("additionalData")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.neighborhoodLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField
            error={
              OrderErrors["obligee.neighborhood"] ||
              OrderErrors["insured.neighborhood"] ||
              OrderErrors["customer.neighborhood"]
            }
          >
            <LngInput
              value={getValue("neighborhood")}
              onChange={onChange("neighborhood")}
              placeholder={Translation.neighborhoodPlaceholder}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <Col xs={24} md={6}>
          {Translation.zipCodeLabel}:
        </Col>
        <Col xs={24} md={18}>
          <FormField
            error={
              OrderErrors["obligee.zipCode"] ||
              OrderErrors["insured.zipCode"] ||
              OrderErrors["customer.zipCode"]
            }
          >
            <MaskedInput
              mask="99.999-999"
              value={getValue("zipCode") || ""}
              onChange={onChange("zipCode")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" gutter={8} className="gx-mt-3">
        <StateSelect
          stateValue={getValue("state", undefined)}
          onChangeState={onChange("state", "value")}
          cityValue={getValue("city")}
          onChangeCity={onChange("city")}
          errors={OrderErrors}
        />
      </Row>
    </section>
  );
};

export default Insured;
