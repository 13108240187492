// redux toolkit
import { createSlice } from "@reduxjs/toolkit";
// request
import axios from "../../../util/axios";
// translation
import i18n from "../../../translation/i18n";
// antd
import { message } from "antd";
// moment
import moment from "moment";
// common
import { setIsLoading } from "../common";
import {
  formatPolicyNotifications,
  formatPolicySMSNotifications,
} from "./utils";

export const policySlice = createSlice({
  name: "policy",
  initialState: {
    list: [],
    errors: [],
    policy: {},
    policyTypes: [],
    notificationHistory: [],
    policyExpirationData: {},
    currentTab: 2,
    isChanged: false,
    enableChange: false,
    paginationParameters: {
      pageSize: 15,
    },
  },
  reducers: {
    setPolicyList: (state, action) => {
      state.list = action.payload;
    },
    setPolicyData: (state, action) => {
      state.policy = action.payload;
    },
    setPolicyTypes: (state, action) => {
      state.policyTypes = action.payload;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
    },
    changePolicyData: (state, action) => {
      state.policy = { ...state.policy, ...action.payload };
    },
    setCurrentTab: (state, action) => {
      state.currentTab = action.payload;
    },
    setIsChanged: (state, action) => {
      state.isChanged = action.payload;
    },
    setEnableChange: (state, action) => {
      state.enableChange = action.payload;
    },
    setPaginationParameters: (state, action) => {
      state.paginationParameters = {
        ...state.paginationParameters,
        ...action.payload,
      };
    },
    setNotificationHistory: (state, action) => {
      state.notificationHistory = [
        ...state.notificationHistory,
        ...action.payload,
      ];
    },
    setPolicyExpirationData: (state, action) => {
      state.policyExpirationData = action.payload;
    },
    clearNotificationHistory: (state, action) => {
      state.notificationHistory = [];
      state.policyExpirationData = {};
    },
  },
});

// ------------------------------------------------------------------

export const {
  setErrors,
  setIsChanged,
  setCurrentTab,
  setPolicyList,
  setPolicyData,
  setPolicyTypes,
  changePolicyData,
  setEnableChange,
  setPaginationParameters,
  setNotificationHistory,
  setPolicyExpirationData,
  clearNotificationHistory,
} = policySlice.actions;

export default policySlice.reducer;

// -----------------------------------------------------------------

export const fetchPolicyList = (data) => (dispatch) => {
  const { code, ...params } = data;
  const queryString = new URLSearchParams({ ...params }).toString();

  dispatch(setIsLoading(true));

  axios
    .get(`/policy/v1/${code}/?${queryString}`)
    .then((data) => {
      dispatch(setPolicyList(data.data.data));
      dispatch(setPaginationParameters(data.data));
    })
    .catch((error) => {
      message.error(
        error.response ? error.response.data.message : i18n.t("unexpectedError")
      );
    })
    .finally(() => {
      dispatch(setIsLoading(false));
    });
};

export const fetchPolicyData = (id, policy) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/policy/v1/${id}/${policy}`)
      .then((data) => {
        dispatch(setPolicyData(data.data));
        dispatch(setIsChanged(false));
        resolve(data);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const fetchPolicyExpirationData = (queryString) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/policy/v1/reports/POLICY_EXPIRATION/?${queryString}`)
      .then((response) => {
        dispatch(setPolicyExpirationData(response?.data));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const fetchNotificationHistory = ({ hash, hashType, type }) => (
  dispatch
) => {
  dispatch(setIsLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .get(`/notification/v1/email/${hash}`)
      .then((response) => {
        dispatch(
          setNotificationHistory(
            formatPolicyNotifications(response?.data, hashType, type)
          )
        );
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const fetchManyNotificationHistory = (list) => (dispatch) => {
  dispatch(setIsLoading(true));
  return Promise.all(
    list.map((item) => item && dispatch(fetchNotificationHistory(item)))
  );
};

export const fetchSMSNotificationHistory = ({
  hash,
  hashType,
  type,
  event,
}) => (dispatch) => {
  dispatch(setIsLoading(true));
  return new Promise((resolve, reject) => {
    axios
      .get(`/notification/sms/${hash}`)
      .then((response) => {
        dispatch(
          setNotificationHistory(
            formatPolicySMSNotifications(response?.data, hashType, type, event)
          )
        );
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const fetchSMSManyNotificationHistory = (list) => (dispatch) => {
  dispatch(setIsLoading(true));
  return Promise.all(
    list.map((item) => item && dispatch(fetchSMSNotificationHistory(item)))
  );
};

export const fetchPolicyTypes = () => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .get(`/policy/v1/policy-types`)
      .then((response) => {
        dispatch(setPolicyTypes(response.data));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const updatePolicy = (id, policyId) => (dispatch, getState) => {
  const { policy } = getState().policy;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${id}/${policyId}`, policy)
      .then((response) => {
        message.success(i18n.t("policy.saveSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const createPolicy = (id) => (dispatch, getState) => {
  const { policy } = getState().policy;

  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/policy/v1/${id}`, policy)
      .then((response) => {
        message.success(i18n.t("policy.createSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const removePolicyFile = (id, policy, fileName) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`/policy/v1/${id}/${policy}/upload-file/${fileName}`)
      .then((response) => {
        dispatch(changePolicyData({ policyFile: null }));
        message.success(i18n.t("policy.removeSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        if (error.response.data.errors && error.response.data.errors.length) {
          dispatch(setErrors(error.response.data.errors));
        }
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const sendPolicyByEmail = (id, policy) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .put(`/policy/v1/${id}/${policy}/send-email`)
      .then((response) => {
        dispatch(
          changePolicyData({
            emailStatus: "SENT",
            emailSentAt: moment().format(),
          })
        );
        message.success(i18n.t("policy.sendSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const sendSuspendInvoice = (id, policy) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/policy/v1/${id}/${policy}/suspend`)
      .then((response) => {
        message.success(i18n.t("policy.suspendSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const sendCancelSuspendInvoice = (id, policy) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .post(`/policy/v1/${id}/${policy}/cancel-suspend`)
      .then((response) => {
        message.success(i18n.t("policy.cancelSuspendSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response?.data?.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  });
};

export const deletePolicy = (id, policy) => (dispatch) => {
  dispatch(setIsLoading(true));

  return new Promise((resolve, reject) => {
    axios
      .delete(`policy/v1/${id}/${policy}/`)
      .then((response) => {
        message.success(i18n.t("policy.deletedSuccess"));
        resolve(response);
      })
      .catch((error) => {
        message.error(
          error.response
            ? error.response.data.message
            : i18n.t("unexpectedError")
        );
        reject(error);
      })
      .finally(dispatch(setIsLoading(false)));
  });
};
