import React, { useEffect, useState } from "react";
import { Col, Row, Button, Spin, Table, Tag } from "antd";
import "./styles.less";
import translation from "./translation";
import { useTranslation } from "react-i18next";
import { formatMoney, formatDate, formatPercentage } from "helpers";
import { customerTabs } from "../../../../constants";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { FormattedValue } from "components";
import {
  CheckCircleFilled,
  WarningFilled,
  CloseCircleFilled,
  MinusCircleFilled,
  ExclamationCircleFilled,
  PauseCircleFilled,
} from "@ant-design/icons";
// components
import StatusLabels from "./StatusLabels";
// moment
import moment from "moment";

import {
  fetchPolicyList,
  setPaginationParameters,
} from "../../../../store/slices/policy";

export default ({ actualTab, registerData }) => {
  const [policyItems, setPolicyItems] = useState([]);

  const {
    policy: { list, paginationParameters },
    common: { isLoading },
  } = useSelector((state) => state);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: Translation } = useTranslation();

  useEffect(() => {
    if (actualTab === customerTabs.currentPolicy) {
      dispatch(fetchPolicyList({ code: id, pageSize: 15 }));
    }
  }, [actualTab]);

  const handleOpenPolicy = (policyID) => {
    history.push(`${history.location.pathname}/policy/${policyID}`);
  };

  const handleNewPolicy = () => {
    history.push(`${history.location.pathname}/policy/newpolicy`);
  };

  const getValue = (entity, property) => {
    if (entity.hasOwnProperty(property)) return entity[property];
    return null;
  };

  const handleUpdateList = () => {
    dispatch(fetchPolicyList({ code: id, pageSize: 15 }));
  };

  const renderValidityCell = (data) => {
    return (
      <>
        <div className="cellNoWrap">{formatDate(data.initialTermAt)}</div>
        <div>{Translation("policy.list.validityLabel")}</div>
        <div className="cellNoWrap" style={{ whiteSpace: "nowrap" }}>
          {formatDate(data.finalTermAt)}
        </div>
      </>
    );
  };

  const getStatusIcon = ({
    finalTermAt,
    cancelledAt,
    invoicesSuspendedAt,
    invoices,
  }) => {
    if (cancelledAt) {
      // cancelled
      return <MinusCircleFilled style={{ color: "#EB6662" }} />;
    }
    if (invoicesSuspendedAt) {
      // suspended
      return <PauseCircleFilled style={{ color: "#E9DC21" }} />;
    }
    if (invoices && invoices.length > 0) {
      // invoice opened
      const openInvoices = invoices.filter(
        (invoice) => invoice.status === "DELAYED"
      );
      if (openInvoices && openInvoices.length > 0) {
        return <CloseCircleFilled style={{ color: "#EB6662" }} />;
      }
    }
    if (moment(finalTermAt) - moment() < 0) {
      // overdue
      return <ExclamationCircleFilled style={{ color: "#EB6662" }} />;
    }
    if (moment(finalTermAt).diff(moment(), "days") <= 30) {
      // 30 days to expire
      return <WarningFilled style={{ color: "#E9DC21" }} />;
    }
    if (moment(finalTermAt) - moment() >= 0) {
      // active
      return <CheckCircleFilled style={{ color: "#91BF1D" }} />;
    }
    return null;
  };

  const columns = [
    {
      dataIndex: "cancelledAt",
      align: "center",
      render: (data, record) => getStatusIcon(record),
      width: "5%",
    },
    {
      title: translation.productTableHeaderLabel,
      dataIndex: "product",
      align: "center",
    },
    {
      title: translation.policyTableHeaderLabel,
      dataIndex: "policy",
      align: "center",
    },
    {
      title: translation.policyNumberTableHeaderLabel,
      dataIndex: "policyNumber",
      align: "center",
    },
    {
      title: translation.validityTableHeaderLabel,
      dataIndex: "validity",
      align: "center",
      render: (data) => renderValidityCell(data),
      width: "20%",
    },
    {
      title: translation.premiumTableHeaderLabel,
      dataIndex: "premium",
      align: "center",
      render: (data) => <span className="cellNoWrap">{data}</span>,
    },
    {
      title: translation.comissionTableHeaderLabel,
      dataIndex: "commission",
      align: "center",
    },
  ];

  const prepareDataSource = (policies) => {
    let items = [];
    if (policies) {
      for (let i = 0; i < policies.length; i++) {
        items.push({
          ...policies[i],
          product: Translation(
            `customers.products.${policies[i].type?.product}`
          ),
          policy: policies[i].type?.description,
          premium: formatMoney(policies[i].premium),
          commission: formatPercentage(policies[i].commission),
          validity: {
            initialTermAt: policies[i].initialTermAt,
            finalTermAt: policies[i].finalTermAt,
          },
        });
      }
    }
    return items;
  };

  const handlePaginationChange = (page, pageSize) => {
    dispatch(setPaginationParameters({ page: page - 1, pageSize }));
    dispatch(fetchPolicyList({ code: id, pageNumber: page - 1, pageSize }));
  };

  useEffect(() => {
    setPolicyItems(prepareDataSource(list));
  }, [list]);

  return (
    <Spin spinning={isLoading} tip={translation.fetchingPoliciesLabel}>
      <Row type="flex" className="gx-m-0 gx-p-0 currentPolicies">
        <Col md={24}>
          <Row type="flex" justify="end">
            {/* <Button onClick={handleUpdateList}>
              {translation.updatePolicyListButton}
            </Button> */}
            <Button onClick={handleNewPolicy} type="primary">
              {translation.newPolicyButtonLabel}
            </Button>
          </Row>
          <Row>
            <Col md={14}>
              <Row type="flex" justify="start" className="gx-m-1 gx-mb-3">
                <span className="gx-mr-1">
                  {translation.customerHeaderLabel}:{" "}
                </span>
                {getValue(registerData, "companyName") || translation.noData}
              </Row>
            </Col>
            <Col md={10}>
              <Row type="flex" justify="end" className="gx-m-1 gx-mb-3">
                <span className="gx-mr-1">
                  {translation.documentNumberHeaderLabel}:
                </span>
                <FormattedValue
                  mask="_"
                  displayType="text"
                  value={getValue(registerData, "documentNumber")}
                  format={"COMPANY"}
                />
              </Row>
            </Col>
          </Row>
        </Col>
        <Col md={24}>
          <Table
            columns={columns}
            dataSource={policyItems}
            pagination={{
              defaultPageSize: 15,
              total: paginationParameters.total || 0,
              pageSize: paginationParameters.pageSize || 0,
              current: paginationParameters.pageNumber + 1,
              onChange: (page, pageSize) =>
                handlePaginationChange(page, pageSize),
            }}
            onRow={(record) => {
              return {
                className: "clickable-row",
                onClick: () => handleOpenPolicy(record.id),
              };
            }}
            size="small"
            tableLayout="fixed"
            className="gx-table-responsive"
            rowKey={(record) => record.id}
          />
        </Col>
        <Col md={24}>
          <h4>{Translation("policy.statusLabel.title")}</h4>
          <StatusLabels />
        </Col>
      </Row>
    </Spin>
  );
};
