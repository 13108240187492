import React from "react";
import {Route, Switch} from "react-router-dom";

import Charts from "./charts/index";

const Extensions = ({match}) => (
    <Switch>
        <Route path={`${match.url}/chart`} component={Charts}/>
    </Switch>
);

export default Extensions;
