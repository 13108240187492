import React from "react";
// prop types
import PropTypes from "prop-types";
// translation
import { useTranslation } from "react-i18next";
// antd
import { Row, Col, Select, DatePicker, Radio, Button } from "antd";
// components
import FormField from "../../../../../FormField";
import CurrencyInput from "../../../../../CurrencyInput";
import LngInput from "../../../../../LngComponents/LngInput";
// helpers
import {
  dateFormat,
  frontEndDate,
  getErrorMessage,
  inputMasks,
} from "../../../../../../helpers";
import moment from "moment";
// constants
import selections from "../../../../../../constants/selections";
import MaskedInput from "../../../../../MaskedInput";

const { insurerByProduct, bondTypes, bussinessPartners } = selections;
const partners = Object.entries(bussinessPartners);

const { Option } = Select;

const ProposalForm = ({
  proposal = {},
  errors = [],
  sales = [],
  preSales = [],
  onSearchCompany,
  onChange,
}) => {
  const { t: translation } = useTranslation();

  const insuredTypes = [
    {
      value: "COMPANY",
      label: translation("proposal.surety.insuredType.company"),
    },
    {
      value: "INDIVIDUAL",
      label: translation("proposal.surety.insuredType.individual"),
    },
  ];

  const handleChange = (field, value) => onChange && onChange(field, value);
  const handleSearchCompany = (documentNumber) =>
    onSearchCompany && onSearchCompany(documentNumber);

  return (
    <>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.susepNumberLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("susepNumber", errors)}
          >
            <LngInput
              className="gx-w-100"
              value={proposal.susepNumber}
              onChange={(value) => handleChange("susepNumber", value)}
              placeholder={translation(
                "proposal.surety.susepNumberPlaceholder"
              )}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.issuedAtLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("issuedAt", errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                proposal.issuedAt
                  ? moment(proposal.issuedAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("issuedAt", value)}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.insurerLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("insurer", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.insurer}
              placeholder={translation("proposal.surety.insurerPlaceholder")}
              onChange={(value) => handleChange("insurer", value)}
            >
              {insurerByProduct.SURETY.sort((a, b) =>
                a.label.localeCompare(b.label)
              ).map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.modalityLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("modality", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.modality}
              placeholder={translation("proposal.surety.modalityPlaceholder")}
              onChange={(value) => handleChange("modality", value)}
            >
              {bondTypes.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.initialTermAtLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("initialTermAt", errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                proposal.initialTermAt
                  ? moment(proposal.initialTermAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("initialTermAt", value)}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.finalTermAtLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("finalTermAt", errors)}
          >
            <DatePicker
              className="gx-w-100"
              allowClear={false}
              format={frontEndDate}
              value={
                proposal.finalTermAt
                  ? moment(proposal.finalTermAt, dateFormat)
                  : undefined
              }
              onChange={(value) => handleChange("finalTermAt", value)}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.premiumLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("premium", errors)}
          >
            <CurrencyInput
              className="gx-w-100"
              value={proposal.premium}
              onChange={(value) => handleChange("premium", value)}
              placeholder={translation("proposal.surety.premiumPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>

      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0 ">
            {translation("proposal.surety.suretyAmountLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("suretyAmount", errors)}
          >
            <CurrencyInput
              className="gx-w-100"
              value={proposal.suretyAmount}
              onChange={(value) => handleChange("suretyAmount", value)}
              placeholder={translation(
                "proposal.surety.suretyAmountPlaceholder"
              )}
            />
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.rateLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField className="gx-m-0" error={getErrorMessage("rate", errors)}>
            <CurrencyInput
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={proposal.rate}
              onChange={(value) => handleChange("rate", value)}
              placeholder={translation("proposal.surety.ratePlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0 ">
            {translation("proposal.surety.commissionLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("commission", errors)}
          >
            <CurrencyInput
              suffix="%"
              prefix=""
              className="gx-w-100"
              value={proposal.commission}
              onChange={(value) => handleChange("commission", value)}
              placeholder={translation("proposal.surety.commissionPlaceholder")}
            />
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0 ">
            {translation("proposal.surety.salesRepresentativeLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("salesRepresentativeId", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.salesRepresentativeId}
              placeholder={translation(
                "proposal.surety.salesRepresentativePlaceholder"
              )}
              onChange={(value) => handleChange("salesRepresentativeId", value)}
            >
              {sales.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0 ">
            {translation("proposal.surety.preSalesRepresentativeLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("preSalesRepresentativeId", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.preSalesRepresentativeId}
              placeholder={translation(
                "proposal.surety.preSalesRepresentativePlaceholder"
              )}
              onChange={(value) =>
                handleChange("preSalesRepresentativeId", value)
              }
            >
              {preSales.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </FormField>
        </Col>
      </Row>
      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.partnerLabel")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("partner", errors)}
          >
            <Select
              className="gx-w-100"
              value={proposal.partner}
              placeholder={translation("proposal.surety.partnerPlaceholder")}
              onChange={(value) => handleChange("partner", value)}
            >
              {partners
                .sort((a, b) => a[1].label.localeCompare(b[1].label))
                .map((item, index) => (
                  <Option key={index} value={item[0]}>
                    {item[1].label}
                  </Option>
                ))}
            </Select>
          </FormField>
        </Col>
      </Row>

      <Row
        type="flex"
        align="middle"
        className="gx-mb-10"
        style={{ height: "40px" }}
      >
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0 ">
            {translation("proposal.surety.insuredType")}
          </span>
        </Col>
        <Col xs={24} md={12}>
          <Radio.Group
            autoFocus="true"
            value={
              proposal.insuredType ||
              (proposal?.obligeeDocumentNumber?.length > 11 &&
              !proposal.insuredType
                ? "COMPANY"
                : "INDIVIDUAL")
            }
            onChange={(value) => handleChange("insuredType", value)}
            className="radio-group-full-width"
          >
            {insuredTypes.map((entry, index) => {
              const { label, value } = entry;
              return (
                <Radio key={index} value={value}>
                  {label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Col>
      </Row>

      {proposal.insuredType === "COMPANY" ||
      (proposal?.obligeeDocumentNumber?.length > 11 &&
        !proposal.insuredType) ? (
        <>
          <Row type="flex" align="middle" className="gx-mb-10">
            <Col sm={24} md={4} className="gx-p-0">
              <span className="gx-m-0 ">
                {translation(`proposal.surety.obligeeDocumentNumber.COMPANY`)}
              </span>
            </Col>
            <Col sm={24} md={8}>
              <FormField
                className="gx-m-0 "
                style={{ display: "flex" }}
                error={getErrorMessage("obligeeDocumentNumber", errors)}
              >
                <MaskedInput
                  className="gx-w-100"
                  mask={inputMasks.COMPANY}
                  value={proposal.obligeeDocumentNumber}
                  onChange={(value) =>
                    handleChange("obligeeDocumentNumber", value)
                  }
                  placeholder={translation(
                    "proposal.surety.obligeeDocumentNumber"
                  )}
                />
              </FormField>
            </Col>
            <Col xs={24} lg={10} className="gx-p-0">
              <Button
                icon="search"
                className="gx-btn-outline-primary gx-mb-0"
                htmlType="button"
                onClick={() =>
                  handleSearchCompany(proposal.obligeeDocumentNumber)
                }
              >
                {translation(`proposal.surety.searchCompanyButton.label`)}
              </Button>
            </Col>
          </Row>
          <Row type="flex" align="middle" className="gx-mb-10">
            <Col sm={24} md={4} className="gx-p-0">
              <span className="gx-m-0 ">
                {translation(`proposal.surety.obligeeName.COMPANY`)}
              </span>
            </Col>
            <Col sm={24} md={20}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("obligeeCompanyName", errors)}
              >
                <LngInput
                  value={proposal?.obligeeCompanyName || ""}
                  onChange={(value) =>
                    handleChange("obligeeCompanyName", value)
                  }
                  placeholder={translation(
                    "proposal.surety.obligeeName.placeholder"
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row type="flex" align="middle" className="gx-mb-10">
            <Col sm={24} md={4} className="gx-p-0">
              <span className="gx-m-0 ">
                {translation(
                  `proposal.surety.obligeeDocumentNumber.INDIVIDUAL`
                )}
              </span>
            </Col>
            <Col sm={24} md={8}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("obligeeDocumentNumber", errors)}
              >
                <MaskedInput
                  className="gx-w-100"
                  mask={inputMasks.INDIVIDUAL}
                  value={proposal.obligeeDocumentNumber}
                  onChange={(value) =>
                    handleChange("obligeeDocumentNumber", value)
                  }
                  placeholder={translation(
                    "proposal.surety.obligeeDocumentNumber"
                  )}
                />
              </FormField>
            </Col>
          </Row>
          <Row type="flex" align="middle" className="gx-mb-10">
            <Col sm={24} md={4} className="gx-p-0">
              <span className="gx-m-0 ">
                {translation(`proposal.surety.obligeeName.INDIVIDUAL`)}
              </span>
            </Col>
            <Col sm={24} md={20}>
              <FormField
                className="gx-m-0"
                error={getErrorMessage("obligeeCompanyName", errors)}
              >
                <LngInput
                  value={proposal?.obligeeCompanyName || ""}
                  onChange={(value) =>
                    handleChange("obligeeCompanyName", value)
                  }
                  placeholder={translation(
                    "proposal.surety.obligeeName.placeholder"
                  )}
                />
              </FormField>
            </Col>
          </Row>
        </>
      )}

      <Row type="flex" align="middle" className="gx-mb-10">
        <Col sm={24} md={4} className="gx-p-0">
          <span className="gx-m-0">
            {translation("proposal.surety.contractNumber")}
          </span>
        </Col>
        <Col sm={24} md={8}>
          <FormField
            className="gx-m-0"
            error={getErrorMessage("contractNumber", errors)}
          >
            <LngInput
              className="gx-w-100"
              value={proposal.contractNumber}
              onChange={(value) => handleChange("contractNumber", value)}
              placeholder={translation("proposal.surety.contractNumber")}
            />
          </FormField>
        </Col>
      </Row>
    </>
  );
};

ProposalForm.propTypes = {
  proposal: PropTypes.object,
  errors: PropTypes.array,
  sales: PropTypes.array,
  preSales: PropTypes.array,
  handleChange: PropTypes.func,
  onSearchCompany: PropTypes.func,
};

export default ProposalForm;
